import { Compact } from 'vue-color'
import Vue from 'vue/dist/vue.esm'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
const Uppy = () => import('../components/uppy.vue')
import vSelect from 'vue-select'
const Customers = () => import('../components/customers.vue')
import SpeedPopover from '../components/SpeedPopover.vue'
import CaptionsPopover from '../components/CaptionsPopover.vue'
import DetectRTC from 'detectrtc'
import "regenerator-runtime"
import screenfull from 'screenfull'
import { VLazyImagePlugin } from "v-lazy-image"
import VTooltip from 'v-tooltip'
import VueClipboard from 'vue-clipboard2'
import 'vue-select/dist/vue-select.css'
import Toasted from 'vue-toasted'
import * as Sentry from "@sentry/vue"
import { Sketch } from "vue-color"
import TextareaAutosize from 'vue-textarea-autosize'
// import VuePapaParse from 'vue-papa-parse'
import Analytics from 'analytics'
import customerIOPlugin from '@analytics/customerio'
const Admin = () => import('../components/admin.vue');
const Advocates = () => import('../components/advocates.vue');
const AnnotationReply = () => import('../components/annotation-reply.vue');
const Assets = () => import('../components/assets.vue');
const HeyGen = () => import('../components/heygen.vue')
// const List = () => import('../components/contact-list.vue');
// const Download = () => import('../components/download.vue');
const WYG = () => import('../components/wyg.vue');
const Genie = () => import('../components/genie.vue');
const Filters = () => import('../components/filters.vue');
const GroupList = () => import('../components/group-list.vue');
const InteractionCorrelations = () => import('../components/interaction-correlations.vue');
const InteractionSummary = () => import('../components/interaction-summary.vue');
const Manage = () => import('../components/manage.vue');
const MinimalEmbeddedAnnotation = () => import('../components/minimal-embedded-annotation.vue');
const Personalize = () => import('../components/personalize.vue');
const Plink = () => import('../components/plink.vue');
const RAD = () => import('../components/rad.vue');
const ResponseSummary = () => import('../components/response-summary.vue');
const Sandbox = () => import('../components/sandbox.vue');
const Scorm = () => import('../components/scorm.vue');
const SeriesForm = () => import('../components/series-form.vue');
const SignInForm = () => import('../components/sign-in-form.vue');
const SignUpForm = () => import('../components/sign-up-form.vue');
const Templates = () => import('../components/templates.vue');
const Tip = () => import('../components/tip.vue');
const Transcript = () => import('../components/transcript.vue');
const UploadAssets = () => import('../components/upload-assets.vue');
const Variable = () => import('../components/variable.vue');
const VideoList = () => import('../components/video-list.vue');
const VideoReport = () => import('../components/video-report.vue');
const ViewtimeGraph = () => import('../components/viewtime-graph.vue');
const Welcome = () => import('../components/welcome.vue');
const QA = () => import('../components/qa.vue');
const FinalList = () => import('../components/finals-list.vue');
const Billing = () => import('../components/billing.vue');
const Cancel = () => import('../components/cancel.vue');
const rvd = () => import('../components/rvd.vue');
const Settings = () => import('../components/settings.vue');
const Group = () => import('../components/group.vue');
const Organization = () => import('../components/organization.vue');
const Design = () => import('../components/alldesign.vue');
const Series = () => import('../components/series.vue');
const Tpicker = () => import('../components/tpicker.vue');
const Insights = () => import('../components/insights.vue');
const ContactList = () => import('../components/contact-list.vue');
const Backups = () => import('../components/backups.vue');
// const Branching = () => import('../components/branching.vue');
const NewBranching = () => import('../components/NewBranching.vue')
const BulkVideos = () => import('../components/bulk-videos.vue');
const Captcha = () => import('../components/captcha.vue');
const CopyTray = () => import('../components/copy-tray.vue');
const Dash = () => import("../components/dash.vue");
const DefaultVariables = () => import('../components/default-variables.vue');
const Export = () => import("../components/export.vue");
const Gateway = () => import('../components/gateway.vue');
const Gradients = () => import("../components/gradients.vue");
const HTMLDialog = () => import('../components/html-dialog.vue');
const InfoCapture = () => import("../components/info-capture.vue");
const Monetization = () => import('../components/monetization.vue');
const PayperDash = () => import('../components/payper-dash.vue');
const Reporting = () => import("../components/reporting.vue");
const SiteScraper = () => import('../components/site-scraper.vue');
const Stats = () => import("../components/stats.vue");
const VideoInfo = () => import('../components/video-info.vue');
const ViewReport = () => import('../components/view-report.vue');
const QBuilder = () => import('../components/qbuilder.vue');
const Help = () => import('../components/help.vue');
const Shoppable = () => import('../components/shoppable.vue');
const Candy = () => import('../components/candy.vue');
const Card = () => import('../components/card.vue');

var components = {
  'captionsPopover': CaptionsPopover,
  'speedPopover': SpeedPopover,
  'wyg': WYG,
  "material-picker": Sketch,
  'organization': Organization,
  'captcha': Captcha,
  'uppy': Uppy,
  'customers': Customers,
  'advocates': Advocates,
  'tip': Tip,
  'seriesForm': SeriesForm,
  'series': Series,
  'rad': RAD,
  'admin': Admin,
  'qa': QA,
  'variable': Variable,
  'group': Group,
  'design': Design,
  'newSelect': vSelect,
  'minimalEmbeddedAnnotation': MinimalEmbeddedAnnotation,
  'responseSummary': ResponseSummary,
  'viewtimeGraph': ViewtimeGraph,
  'interactionSummary': InteractionSummary,
  'interactionCorrelations': InteractionCorrelations,
  'billing': Billing,
  'cancel': Cancel,
  'heygen': HeyGen,
  'rvd': rvd,
  'sandbox': Sandbox,
  'tpicker': Tpicker,
  'insights': Insights,
  'annotationReply': AnnotationReply,
  'vueDropzone': vue2Dropzone,
  'signInForm': SignInForm,
  'compactPicker': Compact,
  'signUpForm': SignUpForm,
  'welcome': Welcome,
  'manage': Manage,
  'templates': Templates,
  'contactList': ContactList,
  'card': Card,
  'finalList': FinalList,
  'videoList': VideoList,
  'videoReport': VideoReport,
  'groupList': GroupList,
  'settings': Settings,
  'personalize': Personalize,
  'transcript': Transcript,
  'assets': Assets,
  'uploadAssets': UploadAssets,
  'dash': Dash,
  'export': Export,
  'gradients': Gradients,
  'infoCapture': InfoCapture,
  "stats": Stats,
  "reporting": Reporting,
  'newBranching': NewBranching,
  "viewReport": ViewReport, 
  "help": Help, 
  "defaultVariables": DefaultVariables, 
  "backups": Backups,
  "copyTray": CopyTray,
  "siteScraper": SiteScraper,
  "shoppable" : Shoppable,
  "qbuilder" : QBuilder,
  "bulkVideos" : BulkVideos,
  "htmlDialog": HTMLDialog,
  "videoInfo": VideoInfo,
  "scorm": Scorm,
  "plink": Plink,
  "filters": Filters,
  "gateway": Gateway,
  "monetization": Monetization,
  "payperDash": PayperDash,
  "candy": Candy,
  "genie": Genie,
}


const analytics = Analytics({
  app: 'mindstamp',
  plugins: [
    customerIOPlugin({
      siteId: gon.isProduction ? "1c91034fb46dfedf897c" : "fa9dadc51b46283bf546"
    })
  ]
})
window.analytics = analytics
Vue.config.ignoredElements = ["zapier-full-experience"];


// Vue.use(VuePapaParse)
Vue.use(TextareaAutosize)
require('vue2-animate/dist/vue2-animate.min.css')
const options = {
	name: '_blank',
	specs: [
		'fullscreen=yes',
		'titlebar=yes',
		'scrollbars=yes'
	],
	styles: [
		'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
	]
}

if (gon.current_user && gon.isProduction && !window.location.href.includes('stella.mindstamp.com')) {
	Sentry.init({
		Vue,
		dsn: "https://762f121d1140421d8fff423aca8036a7@o140452.ingest.sentry.io/651398",
		beforeSend: (event, hint) => {
			console.error(hint.originalException || hint.syntheticException);
			return event; // this drops the event and nothing will be sent to sentry
		 },
		tracesSampleRate: 1.0,
	});

}

Vue.mixin({
	data: function () {
    return {
      months: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
      pastels: ['#fcf4dd', '#adf7b6', '#fce1e4', '#fdffb6','#ddedea','#e8dff5', '#a5ffd6','#7ec4cf','#fff1e6', '#fffbff','' ],
      strings_csv_url: "https://mindstamp-resources.s3.us-west-2.amazonaws.com/assets/strings_9_6_24.csv",
      regexHelpSite: 'https://www.autoregex.xyz/',
      feeStatement: '',
      activeFilter:0,
      hideMindstampBranding: !window.location.href.includes("mindstamp") && !window.location.href.includes("localhost"),
      mindstampImage: gon.current_user ? "https://resource-cdn.mindstamp.com/logos/v2/full/white.png" : "https://resource-cdn.mindstamp.com/logos/v2/full/black.png",
      iconOptions: [
        { label: 'Mouse', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/mouse.png'},
        { label: 'Question', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/questions.svg'},
        { label: 'Star', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/branding.svg'},
        { label: 'Cart', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/cart@3x.png'},
        { label: 'Money', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/dollar_sign.svg'},
        { label: 'Smile', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/smiley_face.svg'},
        { label: 'Frown', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/frowny_face.svg'},
        { label: 'Plus', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/plus_sign.svg'},
        { label: 'Thumbs Up', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/thumbs_up.svg'},
        { label: 'Thumbs Down', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/thumbs_down.svg'},
        { label: 'Phone', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/start_call_from_video.svg'},
        { label: 'Image', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/images.svg'},
        { label: 'Link', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/open_link.svg'},
        { label: 'Book', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/chapters.svg'},
        { label: 'Message', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/show_ message.svg'},
        { label: 'Blank Message', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/collect_feedback.svg'},
        { label: 'Document', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/transcripts.svg'},
        { label: 'Blocks', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/video_series.svg'},
        { label: 'Search', value: 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/White/search.svg'},
      ],
      trayImageColors: [ 'White', 'Black', 'Blue', 'Mindstamp Blue', 'Green', 'Grey', 'Orange', 'Purple', 'Red', 'Yellow',],
      spoptions: [{text: 'Disabled', value: false}, {text: 'Enabled', value: true}],
      colorDialog: false,
      shoppableToken: gon.shoppableToken,
      questionTypes: [
        { label: 'Free Response', value: 'free' },
        { label: 'Multiple Choice', value: 'multiple' },
        { label: 'Choose All That Apply', value: 'all' },
        { label: 'Drawing Response', value: 'draw' },
        { label: 'Number Response', value: 'number' },
        { label: 'Date Response', value: 'date' },
        { label: 'Video Response', value: 'video' },
        { label: 'Voice Response', value: 'voice' },
        { label: 'Image Response', value: 'image' },
        { label: 'Rating (1-5)', value: 'rating' },
        { label: 'Dropdown Select', value: 'select' },
        { label: 'Likert Scale', value: 'likert' },
        { label: 'Display Poll', value: 'poll'},
      ],
      timeItems: [],
      isDebug: window.location.search.indexOf('doot') > -1 || window.location.search.indexOf('gostamp') > -1,
      questionDebug: window.location.search.indexOf('qdebug') > -1 || window.location.search.indexOf('qstamp') > -1,
      timeDebug: window.location.search.indexOf("timedebug") > -1,
      pickerColors: ['#D0021B','#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE','#4A90E2', '#50E3C2', '#593F62', '#4E598C', '#B8E986', '#000000', '#4A4A4A'],
      currentUser: gon.current_user,
      videos: gon.videos,
      vid_drop: gon.vid_drop,
      all_vid_drop: gon.all_vid_drop,
      vidDropDownSearch: '',
			vidDropTimer: null,
			searchingVideoDrop: false,
			collection_drop: gon.collection_drop,
			all_collection_drop: [],
			collectionDropDownSearch: '',
			collectionDropTimer: null,
			searchingCollectionDrop: false,
      user_videos: gon.user_videos,
      org_videos: gon.org_videos,
      favorite_videos: gon.favorite_videos,
      roundingOptions: [
        { text: '0px', value: 0},
        { text: '1px', value: 1},
        { text: '2px', value: 2},
        { text: '3px', value: 3},
        { text: '4px', value: 4},
        { text: '5px', value: 5},
        { text: '6px', value: 6},
        { text: '7px', value: 7},
        { text: '8px', value: 8},
        { text: '9px', value: 9},
        { text: '10px', value: 10},
        { text: '11px', value: 11},
        { text: '12px', value: 12},
        { text: '13px', value: 13},
        { text: '14px', value: 14},
        { text: '15px', value: 15},
        { text: '16px', value: 16},
        { text: '17px', value: 17},
        { text: '18px', value: 18},
        { text: '19px', value: 19},
        { text: '20px', value: 20},
      ],
      languageItems: [
        { text: 'English', value: 'en'},
        { text: 'Spanish', value: 'es'},
        { text: 'French', value: 'fr'},
        { text: 'Turkish', value: 'tr'},
        { text: 'Portuguese', value: 'pt'},
        { text: 'Korean', value: 'ko'},
        { text: 'Hindi', value: 'hi'},
        { text: 'Arabic', value: 'ar'},
        { text: 'Russian', value: 'ru'},
        { text: 'German', value: 'de'},
        { text: 'Italian', value: 'it'},
        { text: 'Dutch', value: 'nl'},
        { text: 'Chinese (Simplified)', value: 'zh'},
        { text: 'Chinese (Simplified, PRC)', value: 'zh-cn'},
        { text: 'Chinese (Simplified, SNG)', value: 'zh-sg'},
        { text: 'Chinese (Traditional, HK)', value: 'zh-cn'},
        { text: 'Chinese (Cantonese)', value: 'zh-yue'},
        { text: 'Chinese (Mandarin)', value: 'zh-cmn'},
        { text: 'Japanese', value: 'ja'},
        { text: 'Swedish', value: 'sw'},
        { text: 'Indonesian', value: 'id'},
        { text: 'Bengali', value: 'bn'},
        { text: 'Punjabi', value: 'pa'},
        { text: 'Urdu', value: 'ur'},
        { text: 'Telugu', value: 'ta'},
        { text: 'Norwegian', value: 'no'},
        { text: 'Irish', value: 'ga'},
      ],
      subtitles: false,
      hasSubtitles: gon.has_subtitles,
      hasTranscript: gon.has_transcript,
      transitionItems: [
				{ text: 'Zoom', value: 'zoom'},
				{ text: 'Zoom Down', value: 'zoomDown'},
				{ text: 'Zoom Left', value: 'zoomLeft'},
				{ text: 'Zoom Right', value: 'zoomRight'},
				{ text: 'Zoom Up', value: 'zoomUp'},
				{ text: 'Fade', value: 'fade'},
				{ text: 'Fade Down', value: 'fadeDown'},
				{ text: 'Fade Left', value: 'fadeLeft'},
				{ text: 'Fade Right', value: 'fadeRight'},
				{ text: 'Fade Up', value: 'fadeUp'},
				{ text: 'Bounce Down', value: 'bounceDown'},
				{ text: 'Bounce Left', value: 'bounceLeft'},
				{ text: 'Bounce Right', value: 'bounceRight'},
				{ text: 'Bounce Up', value: 'bounceUp'},
				{ text: 'Rotate', value: 'rotate'},
				{ text: 'None', value: 'none'},
			],
		}
	},
	// mixins
	methods: {
    getFlags(country) {
      var flags = {
        "Afghanistan":"🇦🇫",
        "Åland":"🇦🇽",
        "Albania":"🇦🇱",
        "Algeria":"🇩🇿",
        "American Samoa":"🇦🇸",
        "Andorra":"🇦🇩",
        "Angola":"🇦🇴",
        "Anguilla":"🇦🇮",
        "Antarctica":"🇦🇶",
        "Antigua and Barb.":"🇦🇬",
        "Argentina":"🇦🇷",
        "Armenia":"🇦🇲",
        "Aruba":"🇦🇼",
        "Australia":"🇦🇺",
        "Austria":"🇦🇹",
        "Azerbaijan":"🇦🇿",
        "Bahamas":"🇧🇸",
        "Bahrain":"🇧🇭",
        "Bangladesh":"🇧🇩",
        "Barbados":"🇧🇧",
        "Belarus":"🇧🇾",
        "Belgium":"🇧🇪",
        "Belize":"🇧🇿",
        "Benin":"🇧🇯",
        "Bermuda":"🇧🇲",
        "Bhutan":"🇧🇹",
        "Bolivia":"🇧🇴",
        "Netherlands":"🇳🇱",
        "Bosnia and Herz.":"🇧🇦",
        "Botswana":"🇧🇼",
        "Norway":"🇧🇻",
        "Brazil":"🇧🇷",
        "Br. Indian Ocean Ter.":"🇮🇴",
        "Brunei":"🇧🇳",
        "Bulgaria":"🇧🇬",
        "Burkina Faso":"🇧🇫",
        "Burundi":"🇧🇮",
        "Cambodia":"🇰🇭",
        "Cameroon":"🇨🇲",
        "Canada":"🇨🇦",
        "Cabo Verde":"🇨🇻",
        "Cayman Is.":"🇰🇾",
        "Central African Rep.":"🇨🇫",
        "Chad":"🇹🇩",
        "Chile":"🇨🇱",
        "China":"🇨🇳",
        "Australia":"🇨🇽",
        "Australia":"🇨🇨",
        "Colombia":"🇨🇴",
        "Comoros":"🇰🇲",
        "Congo":"🇨🇬",
        "Dem. Rep. Congo":"🇨🇩",
        "Cook Is.":"🇨🇰",
        "Costa Rica":"🇨🇷",
        "Côte d'Ivoire":"🇨🇮",
        "Croatia":"🇭🇷",
        "Cuba":"🇨🇺",
        "Curaçao":"🇨🇼",
        "Cyprus":"🇨🇾",
        "Czechia":"🇨🇿",
        "Denmark":"🇩🇰",
        "Djibouti":"🇩🇯",
        "Dominica":"🇩🇲",
        "Dominican Rep.":"🇩🇴",
        "Ecuador":"🇪🇨",
        "Egypt":"🇪🇬",
        "El Salvador":"🇸🇻",
        "Eq. Guinea":"🇬🇶",
        "Eritrea":"🇪🇷",
        "Estonia":"🇪🇪",
        "Ethiopia":"🇪🇹",
        "Falkland Is.":"🇫🇰",
        "Denmark":"🇫🇴",
        "Fiji":"🇫🇯",
        "Finland":"🇫🇮",
        "France":"🇫🇷",
        "France":"🇬🇫",
        "Fr. Polynesia":"🇵🇫",
        "Fr. S. Antarctic Lands":"🇹🇫",
        "Gabon":"🇬🇦",
        "Gambia":"🇬🇲",
        "Georgia":"🇬🇪",
        "Germany":"🇩🇪",
        "Ghana":"🇬🇭",
        "United Kingdom":"🇬🇮",
        "Greece":"🇬🇷",
        "Greenland":"🇬🇱",
        "Grenada":"🇬🇩",
        "France":"🇬🇵",
        "Guam":"🇬🇺",
        "Guatemala":"🇬🇹",
        "Guernsey":"🇬🇬",
        "Guinea":"🇬🇳",
        "Guinea-Bissau":"🇬🇼",
        "Guyana":"🇬🇾",
        "Haiti":"🇭🇹",
        "Heard I. and McDonald Is.":"🇭🇲",
        "Vatican":"🇻🇦",
        "Honduras":"🇭🇳",
        "Hong Kong":"🇭🇰",
        "Hungary":"🇭🇺",
        "Iceland":"🇮🇸",
        "India":"🇮🇳",
        "Indonesia":"🇮🇩",
        "Iran":"🇮🇷",
        "Iraq":"🇮🇶",
        "Ireland":"🇮🇪",
        "Isle of Man":"🇮🇲",
        "Israel":"🇮🇱",
        "Italy":"🇮🇹",
        "Jamaica":"🇯🇲",
        "Japan":"🇯🇵",
        "Jersey":"🇯🇪",
        "Jordan":"🇯🇴",
        "Kazakhstan":"🇰🇿",
        "Kenya":"🇰🇪",
        "Kiribati":"🇰🇮",
        "North Korea":"🇰🇵",
        "South Korea":"🇰🇷",
        "Kuwait":"🇰🇼",
        "Kyrgyzstan":"🇰🇬",
        "Laos":"🇱🇦",
        "Latvia":"🇱🇻",
        "Lebanon":"🇱🇧",
        "Lesotho":"🇱🇸",
        "Liberia":"🇱🇷",
        "Libya":"🇱🇾",
        "Liechtenstein":"🇱🇮",
        "Lithuania":"🇱🇹",
        "Luxembourg":"🇱🇺",
        "Macao":"🇲🇴",
        "Macedonia":"🇲🇰",
        "Madagascar":"🇲🇬",
        "Malawi":"🇲🇼",
        "Malaysia":"🇲🇾",
        "Maldives":"🇲🇻",
        "Mali":"🇲🇱",
        "Malta":"🇲🇹",
        "Marshall Is.":"🇲🇭",
        "France":"🇲🇶",
        "Mauritania":"🇲🇷",
        "Mauritius":"🇲🇺",
        "France":"🇾🇹",
        "Mexico":"🇲🇽",
        "Micronesia":"🇫🇲",
        "Moldova":"🇲🇩",
        "Monaco":"🇲🇨",
        "Mongolia":"🇲🇳",
        "Montenegro":"🇲🇪",
        "Montserrat":"🇲🇸",
        "Morocco":"🇲🇦",
        "Mozambique":"🇲🇿",
        "Myanmar":"🇲🇲",
        "Namibia":"🇳🇦",
        "Nauru":"🇳🇷",
        "Nepal":"🇳🇵",
        "Netherlands":"🇳🇱",
        "New Caledonia":"🇳🇨",
        "New Zealand":"🇳🇿",
        "Nicaragua":"🇳🇮",
        "Niger":"🇳🇪",
        "Nigeria":"🇳🇬",
        "Niue":"🇳🇺",
        "Norfolk Island":"🇳🇫",
        "N. Mariana Is.":"🇲🇵",
        "Norway":"🇳🇴",
        "Oman":"🇴🇲",
        "Pakistan":"🇵🇰",
        "Palau":"🇵🇼",
        "Palestine":"🇵🇸",
        "Panama":"🇵🇦",
        "Papua New Guinea":"🇵🇬",
        "Paraguay":"🇵🇾",
        "Peru":"🇵🇪",
        "Philippines":"🇵🇭",
        "Pitcairn Is.":"🇵🇳",
        "Poland":"🇵🇱",
        "Portugal":"🇵🇹",
        "Puerto Rico":"🇵🇷",
        "Qatar":"🇶🇦",
        "France":"🇷🇪",
        "Romania":"🇷🇴",
        "Russia":"🇷🇺",
        "Rwanda":"🇷🇼",
        "St-Barthélemy":"🇧🇱",
        "Saint Helena":"🇸🇭",
        "St. Kitts and Nevis":"🇰🇳",
        "Saint Lucia":"🇱🇨",
        "St-Martin":"🇲🇫",
        "St. Pierre and Miquelon":"🇵🇲",
        "St. Kitts and Nevis":"🇻🇨",
        "Samoa":"🇼🇸",
        "San Marino":"🇸🇲",
        "São Tomé and Principe":"🇸🇹",
        "Saudi Arabia":"🇸🇦",
        "Senegal":"🇸🇳",
        "Serbia":"🇷🇸",
        "Seychelles":"🇸🇨",
        "Sierra Leone":"🇸🇱",
        "Singapore":"🇸🇬",
        "Netherlands":"🇳🇱",
        "Slovakia":"🇸🇰",
        "Slovenia":"🇸🇮",
        "Solomon Is.":"🇸🇧",
        "Somalia":"🇸🇴",
        "South Africa":"🇿🇦",
        "United Kingdom":"🇬🇧",
        "S. Sudan":"🇸🇸",
        "Spain":"🇪🇸",
        "Sri Lanka":"🇱🇰",
        "Sudan":"🇸🇩",
        "Suriname":"🇸🇷",
        "Norway":"🇳🇴",
        "eSwatini":"🇸🇿",
        "Sweden":"🇸🇪",
        "Switzerland":"🇨🇭",
        "Syria":"🇸🇾",
        "Taiwan":"🇹🇼",
        "Tajikistan":"🇹🇯",
        "Tanzania":"🇹🇿",
        "Thailand":"🇹🇭",
        "Timor-Leste":"🇹🇱",
        "Togo":"🇹🇬",
        "New Zealand":"🇹🇰",
        "Tonga":"🇹🇴",
        "Trinidad and Tobago":"🇹🇹",
        "Tunisia":"🇹🇳",
        "Turkey":"🇹🇷",
        "Turkmenistan":"🇹🇲",
        "Turks and Caicos Is.":"🇹🇨",
        "United Kingdom":"🇹🇻",
        "Uganda":"🇺🇬",
        "Ukraine":"🇺🇦",
        "United Arab Emirates":"🇦🇪",
        "United Kingdom":"🇬🇧",
        "United States":"🇺🇸",
        "United States of America":"🇺🇸",
        "Uruguay":"🇺🇾",
        "Uzbekistan":"🇺🇿",
        "Vanuatu":"🇻🇺",
        "Venezuela":"🇻🇪",
        "Vietnam":"🇻🇳",
        "British Virgin Is.":"🇻🇬",
        "U.S. Virgin Is.":"🇻🇮",
        "Wallis and Futuna Is.":"🇼🇫",
        "W. Sahara":"🇪🇭",
        "Yemen":"🇾🇪",
        "Zambia":"🇿🇲",
        "Zimbabwe":"🇿🇼",
      };
      return flags[country] || ""
  },
    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
      }
      return str.join(' ');
    },
    isNav(type) {
      return ['pause','redirect','jump','switch','back','variable','resourceTray','show_card','reset_viewer','get','post','chatGPT'].includes(type)
    },
    makeHtmlSafe(str) {
      if (!str) {
        return ''
      }
      var x = str || ''
      x = x.replace(/<script>/ig, '')
      x = x.replace(/<\/script>/ig, '')
      x = x.replace(/<link>/ig, '')
      x = x.replace(/<input>/ig, '')
      x = x.replace(/onabort/ig, 'z')
      x = x.replace(/onblur/ig, 'z')
      x = x.replace(/onchange/ig, 'z')
      x = x.replace(/onclick/ig, 'z')
      x = x.replace(/ondblclick/ig, 'z')
      x = x.replace(/ondragdrop/ig, 'z')
      x = x.replace(/onerror/ig, 'z')
      x = x.replace(/onfocus/ig, 'z')
      x = x.replace(/onkeydown/ig, 'z')
      x = x.replace(/onkeypress/ig, 'z')
      x = x.replace(/onkeyup/ig, 'z')
      x = x.replace(/onload/ig, 'z')
      x = x.replace(/onmousedown/ig, 'z')
      x = x.replace(/onmousemove/ig, 'z')
      x = x.replace(/onmouseout/ig, 'z')
      x = x.replace(/onmouseover/ig, 'z')
      x = x.replace(/onmouseup/ig, 'z')
      x = x.replace(/onmove/ig, 'z')
      x = x.replace(/onreset/ig, 'z')
      x = x.replace(/onresize/ig, 'z')
      x = x.replace(/onselect/ig, 'z')
      x = x.replace(/onsubmit/ig, 'z')
      x = x.replace(/onunload/ig, 'z')
      return x
    },
    sanitizeString: function (str) {
			var x = this.replaceBadWords(str)
      if (gon.current_user && gon.current_user.plan != 'free') {
        x = str
      }
      x = this.makeHtmlSafe(x)
      return x.trim(" ")
		},
    d2d(date) {
      return new Date(date).toLocaleDateString()
    },
    d2l(date) {
      return new Date(date).toLocaleString()
    },
    d2t(date) {
      return new Date(date).toLocaleTimeString()
    },
    printChart(id, title) {
      this.sayDev("PrintChart: " + id)
      var element = document.getElementById(id)
      if (element) {
        var opt = {
          margin: [15, 15, 15, 15],
          filename:     (title || id) + '.pdf',
          image:        { type: 'jpeg', quality: 1 },
          jsPDF:        { orientation: 'landscape' },
          pagebreak:    { mode: ['avoid-all']},
        };
        try {
          this.sayDev("Printing....")
          html2pdf().from(element).set(opt).save()
        } catch(errorr){
          this.sayDev("PDF print error" + e)
        }
      } else {
        this.sayDev("Could not find element....")
      }
    },
    togglePause() {
      console.log("Toggle Pause")
      Rails.ajax({
				url: '/billing/pause',
				type: "POST",
				success: (data) => {
          console.log(data)
          console.log("Successful Pause")
          if (data.paused == true) {
            this.$toasted.show("Subscription paused!")
            window.location.href = '/billing'
          } else {
            this.$toasted.show("Subscription resumed!")
            window.location.href = '/billing'
          }
				},
				error: (data) => {
				}
			});
    },
    toggleCancel(reasons={}) {
      console.log("TC", reasons)
      
      var data = new FormData()
      data.append("reasons", JSON.stringify(reasons))
      Rails.ajax({
				url: '/billing/cancel',
				type: "POST",
        data: data,
				dataType: "JSON",
				success: (data) => {
          console.log("Successful Cancel")
          if (data.canceled == true) {
            this.$toasted.show("Subscription successfully canceled")
            window.location.href = '/billing'
          } else {
            this.$toasted.show("Subscription resumed!")
            window.location.href = '/billing'
          }
				},
				error: (data) => {
				}
			});
    },
    getYMD(d) {
      var year = d.getFullYear() 
      var month = d.getMonth() + 1
      if (month > 12) {
        month = 0
      } else if (month < 10) {
        month = "0"+month
      }
      var day = d.getDate()
      if (day < 10) {
        day = "0"+day
      }
      var z = year + "-" +month + "-"+day
      // console.log("Return " + z)
      return z
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    loadCrispChat() {
      window.$crisp=[];
      window.CRISP_WEBSITE_ID="463145de-6683-4413-8ff5-9c4bb915a33b";
      (function(){var s =document.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;document.getElementsByTagName("head")[0].appendChild(s);})();
    },
    getWelcomeMessage(){
      const currentTime = new Date().getHours();
      let greetingText = "";

      if (currentTime < 12) {
        greetingText = "Good Morning"; 
      } else if (currentTime < 18) {
        greetingText = "Good Afternoon"; 
      } else {
        greetingText = "Good Evening"; 
      }
      var options = [greetingText, greetingText, greetingText, greetingText, greetingText, greetingText, greetingText, greetingText,greetingText,greetingText,greetingText,greetingText,greetingText]
      options.push("Howdy", "Hello", "Hey There", "Greetings", "Welcome Back", "Great to see you")
      
      return options.sort(() => 0.5 - Math.random())[0] +', ' + this.currentUser.first_name+"!"
    },
    getTableTime(started) {
      var date = new Date(started)
      var d = "Today"
      if (!this.isToday(started)) {
        d = (date.getMonth() + 1)+"-"+date.getDate() + "-"+date.getFullYear().toString().substring(2,4)
      }
      var h = date.getHours()
      if (h == 0) {
        h = 12
      } else if (h > 12) {
        h = h-12
      }
      var m = date.getMinutes()
      if (m.toString().length == 1) {
        m = "0"+m.toString()
      }
      var p = d + " @ " + h+":"+m+" "+(date.toLocaleTimeString().split(" ")[1] || "")
      return p
      // var t = 
      // return this.isToday(started) ? "Today " + new Date(started).toLocaleTimeString()  : new Date(started).toLocaleString()
    },
    refreshPage() {
      window.location.reload();
    },
		isRegex(v){
			if (v && v[0] == '/' && v[v.length-1] == '/'){
				return true
			}
			return false
		},
		makeRegex(v){
			return v.substring(1,v.length-1)
		},
    isToday(arg) {
      var someDate = new Date(arg)
      const today = new Date()
      return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    },
    getAvatarUrl(inputString) {
      // return 'https://avatar.oxro.io/avatar.svg?name= ' + inputString.replace("-","")
      var c = inputString.split("-")[1] || inputString
      return 'https://avatar.tobi.sh/' + c[0] + inputString
    },
		checkDimension(val){
      return val == 0 || val
    },
		submitPayeeAuthToken(id, authToken){
			var data = new FormData()
			data.append("authToken", authToken)
			data.append("id", id)
			Rails.ajax({
				url: '/payments/auth',
				type: 'POST',
				data: data,
				dataType: 'JSON',
        success: (data) => {
					window.location.href = data.url + '&t=' + this.currentTime
				},
				error: (data) =>{
					if (!this.strings){
						this.$toasted.show("Try Again")
					} else {
						this.$toasted.show(this.strings.try_again)
					}
					this.payeeAuthToken = ''
					this.showpayeeAuthToken = false
				}
			})
		},
		getStripeFeeStatement(amt, currency){
			var stripe, mindstamp, tot
			if (currency == 'USD'){
				stripe = amt * 0.029 + 0.3
				mindstamp = Math.max(amt * 0.05, 1)
				tot = amt - stripe - mindstamp
				this.feeStatement = `After estimated Stripe (${stripe.toFixed(2)} ${currency}) and Mindstamp (${mindstamp.toFixed(2)} ${currency}) fees, your approximate payment will be ${tot.toFixed(2)}  ${currency}.<br><div style="font-size:smaller">See <a href="https://stripe.com/pricing/local-payment-methods">Stripe</a> for a complete fee breakdown.</div>`
			} else {
				var data = new FormData()
				data.append("amount", amt)
				data.append("currency", currency)
				Rails.ajax({
					url: "/payments/fees",
					type: "POST",
					data: data,
					dataType: "JSON",
					success: (data) => {
						mindstamp = data.mindstamp
						if (data.stripe){
							stripe = data.stripe
							this.monetizationFee = stripe + mindstamp
							tot = amt - this.monetizationFee
							this.feeStatement = `After estimate Stripe (${stripe.toFixed(2)} ${currency}) and Mindstamp (${mindstamp.toFixed(2)} ${currency}) fees, your approximate payment will be ${tot.toFixed(2)}  ${currency}.`
						} else {
							this.monetizationFee = mindstamp + amt * .03
							tot = amt - mindstamp
							this.feeStatement = `After estimate Mindstamp fees (${mindstamp.toFixed(2)} ${currency}), your approximate payment will be ${tot.toFixed(2)} ${currency} less Stripe fees.`
						}
						this.feeStatement += '<br><div style="font-size:smaller">See <a href="https://stripe.com/pricing/local-payment-methods">Stripe</a> for a complete fee breakdown. Non USD currency values are subject to exchange rate fluctuations.</div>'
					},
					error: (e) => {
					},
				});
			}
		},
    lessThanTwoMinutes(finished) {
      if (!finished) {
        return true;
      }
      var twomins = 2 * 60 * 1000;
      return new Date() - new Date(finished) < twomins;
    },
		getLandscapeEmbed(){
      return `<div id='ms_frame_container' style='position: relative; overflow: hidden; padding-top: 56.25%;'>
  <iframe 
    id='ms_frame' 
    loading='lazy' 
    style='position: absolute;top: 0;left: 0; width: 100%; height: 100%; min-height:unset; min-width: unset; border: 0; max-height: 100vh;' 
    src='{{URL}}' 
    allowFullscreen 
    allow='encrypted-media; microphone; camera; geolocation' 
    scrolling='no'
    referrerpolicy='no-referrer-when-downgrade'>
  </iframe>
</div>`
    },
    getPortraitEmbed(){
    	return `<div id='ms_frame_container' style='position: relative; overflow: hidden; padding-top: 178%;width: 100%; margin: 0 auto; max-height: 100vh;'>
  <iframe 
    id='ms_frame' 
    loading='lazy' 
    style='position: absolute;top: 0;left: 0; width: 100%; height: 100%; min-height:unset; min-width: unset; border: 0;'
    src='{{URL}}' 
    allowFullscreen 
    allow='encrypted-media; microphone; camera; geolocation' 
    scrolling='no'
    referrerpolicy='no-referrer-when-downgrade'>
  </iframe>
</div>`
    },
    getDynamicEmbed(){
      return `<div id='ms_frame_container' class="landscape">
  <iframe
    id='ms_frame' 
    loading='lazy' 
    src='{{URL}}' 
    style="position: absolute;top: 0;left: 0; width: 100%; height: 100%; min-height:unset; min-width: unset; border: 0"
    allowFullscreen 
    allow='encrypted-media; microphone; camera; geolocation' 
    scrolling='no'
    referrerpolicy='no-referrer-when-downgrade'>
  </iframe>
</div>
<xscript>
  window.addEventListener("load", (event) => {
    const container = document.getElementById('ms_frame_container');
    if (window.innerWidth < (window.innerHeight / 2)){
      container.classList.add("portrait");
      container.classList.remove("landscape");
      console.log('Portrait');
    } else {
      console.log('Landscape');
    }
  })
</xscript>
<style>
  .portrait, .landscape{
    position: relative; 
    overflow: hidden; 
  }
  .portrait{
    padding-top: 178%;
    width: 100%; 
    margin: 0 auto;
  }
  .landscape{
    padding-top: 56.25%;
  }
</style>`.replaceAll('xscript','script')
		},
		getOEmbedCode(){
			return "https://app.mindstamp.com/oembed/" + this.share.token + '?src={{URL}}'
		},
    getIframeEmbedCode(){
      return '<iframe src="https://embed.mindstamp.com/e/'+this.share.token+'" allowfullscreen allow="encrypted-media; microphone; camera; geolocation" scrolling="no"></iframe>'
		},
		paymentHelp(id){
			if(this.isEditing) return
			Rails.ajax({
				url: '/payments/help?id=' + id,
				type: 'GET',
				success: (data) => {
					window.location.href = 'mailto:' + data.email + '?subject=Payment for ' + data.title
				},
				error: (response) => {
				},
			})	
		},
    downloadImage(url, name){
      fetch(url+"?t="+new Date().toString())
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => { 
          alert('An error sorry'); 
          console.log("Error", error)
        });
    },
    downloadURI(uri, name) { 
			var link = document.createElement("a");
			link.download = name;
			link.href = uri;
			link.click();
		},
    toDataURL(url, callback) {
			var xhr = new XMLHttpRequest();
			xhr.onload = function() {
				var reader = new FileReader();
				reader.onloadend = function() {
					callback(reader.result);
				}
				reader.readAsDataURL(xhr.response);
			};
			xhr.open('GET', url);
			xhr.responseType = 'blob';
			xhr.send();
			xhr.addEventListener('error', (e)=> {
				console.log("Got error....")
				setTimeout(()=> {
					this.printSummary()
				})
			});
		},
    downloadImage(url, name){
      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('An error sorry'));
    },
    downloadURI(uri, name) { 
			var link = document.createElement("a");
			link.download = name;
			link.href = uri;
			link.click();
		},
    finishDownloadFromUrl(url, name, id){
      if (this.ownsPost) {
        this.$toasted.show("Use preview mode to see file download happen!")
        this.isDownloading = false
        return
      }
			fetch(url)
				.catch(err => {
					this.downloadURI(url, name)
					return
				})
				.then(response => response.blob())
				.then(blob => {
					const link = document.createElement("a");
					link.href = URL.createObjectURL(blob);
					link.download = name;
					link.click();
					setTimeout(() => {
						this.isDownloading = false
					},1000) 
				})	
		},
    toDataURL(url, callback) {
			var xhr = new XMLHttpRequest();
			xhr.onload = function() {
				var reader = new FileReader();
				reader.onloadend = function() {
					callback(reader.result);
				}
				reader.readAsDataURL(xhr.response);
			};
			xhr.open('GET', url);
			xhr.responseType = 'blob';
			xhr.send();
			xhr.addEventListener('error', (e)=> {
				console.log("Got error....")
				setTimeout(()=> {
					this.printSummary()
				})
			});
		},
		getTrayStyle(obj){
			var t, l, h, w
			if (this.isMobile){
				t = l = 0
				h = w = 100
			} else if(this.showingCheckout || this.showingVariants){
				t = 0
				l = 50
				w = 50
				h = 100
			} else {
				t = obj.top
				l = obj.left
				w = obj.width
				h = obj.height
			}
			return `position:absolute;
								z-index: 1;
								border-radius: ${this.getBorderRadius(l,w)};
								top:${t}%;
								left: ${l}%;
								width: ${w}%;
								height: ${h}%;
								background: ${obj.color};`
		},
		getBorderRadius(l, w){
      return '0px'
			l = parseInt(l)
			w = parseInt(w)  
			if (w == 100){
				return '0px 0px 0px 0px'
			} else if (l == 0){
				return '0px 10px 10px 0px'
			} else if (l + w == 100){
				return '10px 0px 0px 10px'
			}
			return '10px 10px 10px 10px'
		},
		isJSON(str) {
			try {
				JSON.parse(str);
			} catch (e) {
				return false;
			}
			return true;
		},
    forceUpdate(args) {
      // this.sayDev("\n\n 🥶🥶🥶🥶🥶🥶 FORCE UPDATE FROM " + args + " 🥶🥶🥶🥶🥶🥶")
			this.$forceUpdate()
		},
		fetchSubtitles(fetchAll=false, shares=[gon.share]) {
			if (this.halt) {
				return
			}
			if (!this.hasSubtitles && !fetchAll) {
				return
			}
      this.sayDev("Fetching subtitles...")
			var url, language
			var i = 0
			if (!fetchAll){
				if (this.activeTextTrack && this.activeTextTrack != 'Off'){
					for(; i < shares[0].captions.length; i++){
						if (shares[0].captions[i].label == this.activeTextTrack){
							url = shares[0].captions[i].url
							language = shares[0].captions[i].label
							break
						}
					}
				} else {
					url = shares[0].captions[0].url
					language = shares[0].captions[0].label
				}
			}
			var subs = []
			shares.forEach(share =>{
				var start = fetchAll ? 0 : i
				var end = fetchAll ? share.captions.length : i + 1
				for(var x = start; x < end; x++) {
          if (share.captions[x]) {
            $.get(share.captions[x].url, function(data) {
              var ignore = true
              var items = data.split('\n\r\n');
              var regex = /[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3} --> [0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}/
              $.each(items, function(index, value) {
                var item = items[index].split('\n');
                item.forEach(sub => {
                  if (sub.length > 1 && isNaN(sub)) {
                    if (sub.match(regex)){
                      ignore = false
                      subs.push({
                        start: (parseInt(sub.split(' --> ')[0].split(":")[0]) * 60 * 60 + parseInt(sub.split(' --> ')[0].split(":")[1]) * 60 + parseFloat(sub.split(' --> ')[0].split(":")[2])) * 1000,
                        text: '',
                        token: share.token,
                        title: share.title,
                        type_of: 'transcript',
                      })
                    } else if (subs.length > 0 && sub && !ignore){
                      subs[subs.length-1].text += sub + '\n' 
                    }
                  }
                })
              })
            })
          }
				}
			})
			setTimeout(()=>{
				if (this.searchString){
					this.subtitles = {
						captions: subs.filter(x => x.text.toLowerCase().includes(this.searchString.toLowerCase())),
						url: url,
						language: language,
					} 
				} else {
					this.subtitles = {
						captions: subs,
						url: url,
						language: language,
					} 
				}
			},1000)
		},
    elapsedToString(seconds) {
      this.sayDev("Elapsed: " + seconds)
      var count = seconds
      var term = 'second'
      if (seconds < 60 ) {   return seconds + " seconds";  }
      if (seconds < 3600 ) { 
        this.sayDev("1")
        count = Math.floor(seconds/60)
        term = "minute" 
      } else if (seconds < 216000 ) { 
        this.sayDev("2")
        count = Math.floor(seconds/3600)
        term = "hour"; 
      } else if (seconds < 5184000 ) { 
        this.sayDev("3")
        count = Math.floor(seconds/216000)
        term = "day"; 
      } else if (seconds < 155520000 ) { 
        this.sayDev("4")
        count = Math.floor(seconds/5184000)
        term = "month"; 
      }
      return count+" "+term + (count > 1 ? "s" : "")
    },
    tls(date) {
      if (!date) {
        return ''
      }
      return new Date(date).toLocaleString()
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumberString;
    },
    removeHTML(str) {
			if (str && typeof str == 'string') {
				var x = str.replace(/(<([^>]+)>)/gi, "")
				if (x) {
					return x.replace(/ {1,}/g," ").trim()
				}
				return str
			} else {
				return ""
			}
		},
    stringToColor(str) {

      var stringInput = str
      if (str) { 
				stringInput = str.slice(-4) 
			} else {
				return 'hsl(240, 49%, 31%)';
			}

      let stringUniqueHash = [...stringInput].reduce((acc, char) => {
          return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
      return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
    },
    toSingleFloat(t) {
      var x = Number(t).toFixed(1).toString()
      return x.split(".")[0] + '.'+(x.split(".")[1]||"0")
    },
		setLocalStorageItem(name, value) {
			// this.sayDev("SetLocalStorageItem: " + name + " --> " + value )
			if (this.lsTest() && value && value != "undefined" && value != "null") {
				localStorage.setItem(name, value)
			}
		},
		getLocalStorageItem(name) {
			// this.sayDev("GetLocalStorageItem: " + name )
			if (this.lsTest()) {
				return localStorage.getItem(name)
			}
		},
		getFavoriteVideos() {
      Rails.ajax({
        url: "/users/fav_videos",
        type: "GET",
        success: (data) => {
          return data
        },
      });
    },
		lsTest: function () {
			var test = 'test';
			try {
				localStorage.setItem(test, test);
				localStorage.removeItem(test);
				return true;
			} catch (e) {
				this.sayDev("🔴 No local storage acess")
				return false;
			}
		},
		getUrlParam: function (str) {
			var url = new URL(window.location.href)
			return url.searchParams.get(str) || ''
		},
    paramsToObject: function(str) {
      // console.log("Input: " + str)
      var n = str.replaceAll("?", "").split("&")
      var obj = {}
      n.forEach((e)=> {
        if (e) {
          obj[e.split("=")[0]] = e.split("=")[1]
        }
      })
      // console.log("output: ", obj)
      return obj

    },
		animateNumber(id, start, end, duration) {
			if (start == end) {
				return
			}
			// this.sayDev("animateNumber: " + id + " from " + start + " to " + end + " with duration " + duration )
			let obj = document.getElementById(id)
			if (obj) {
				let current = start,
				range = end - start,
				increment = range / 100,
				step = 10,
				timer = setInterval(() => {
					current += increment;
					obj.innerHTML = (Math.min(Math.floor(current), end)).toLocaleString();
					if ((end >= 0 && current >= end) || (end < 0 && end >= current)) {
            // this.sayDev("Done animating " + id)
						clearInterval(timer);
					}
				}, step);	
			} else {
        this.sayDev("Can't find " + id)
      }
		},
		animateSeconds(id, start, end, duration) {
			this.sayDev("animateSeconds: " + id + " from " + start + " to " + end + " with duration " + duration )
			let obj = document.getElementById(id)
			if (obj) {
				let current = start,
				range = end - start,
				increment = range / 100,
				step = 10,
				timer = setInterval(() => {
					current += increment;
					obj.innerHTML = this.formattedSeconds(current);
					if (current >= end) {
						clearInterval(timer);
					}
				}, step);	
			}
		},
		hideCursor() {
			if (!this.currentUser && !this.dialogShowing) {
				document.getElementById('videoWrap').style.cursor = 'none';
			}
		},
		showCursor() {
			if (document.getElementById('videoWrap')){
				document.getElementById('videoWrap').style.cursor = 'auto';
			}
		},
    getFaInteractionIcon(t, color) {
			var url = 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/' + this.capitalizeFirstLetter(color)
			if (t == 'audio') { return  'microphone'}
			if (t == 'button') { return 'hand-back-point-up'}
			if (t == 'hotspot' || t == "grid") { return 'sun-bright'}
			if (t == 'video' || t == 'clip')  { return  'video'}
			if (t == 'drawing')  { return 'pen-swirl'}
			if (t == 'image')  { return  'image'}
			if (t == 'question')  { return  'question-circle'}
			if (t == 'text') { return  'font'}
			if (t == 'comment') { return 'comment'}
			if (t == 'navigation') { return 'arrows-repeat'}
			if (t == 'switch' || t == 'back') { return'split'}
			if (t == 'jump' || t == 'pause') { return 'arrows-repeat'}
			if (t == 'redirect') { return 'link'}
			if (t == 'reset_viewer') { return 'user'}
			if (t == 'variable') { return  'sparkles'}
			if (t == 'resourceTray') { return 'square-list'}
			if (t == 'card') { return  url +  'card-diamond'}
			if (t == 'post') { return  url +  'code'}
			if (t == 'get') { return  url +  'code'}
			if (t == 'chatGPT') { return  url +  'robot'}
			return t
		},
		getInteractionIcon(t, color) {
			var url = 'https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/' + this.capitalizeFirstLetter(color)
			if (t == 'audio') { return  url +  '/voice_clip.svg'}
			if (t == 'button') { return  url +  '/hand.svg'}
			if (t == 'hotspot' || t == "grid") { return  url +  '/hotspots.svg'}
			if (t == 'video' || t == 'clip')  { return  url +  '/video_clip.svg'}
			if (t == 'drawing')  { return  url +  '/drawings.svg'}
			if (t == 'image')  { return  url +  '/images.svg'}
			if (t == 'question')  { return  url +  '/questions.svg'}
			if (t == 'audio')  { return  url +  '/audio.svg'}
			if (t == 'text') { return  url +  '/text.svg'}
			if (t == 'comment') { return  url +  '/collect_feedback.svg'}
			if (t == 'navigation') { return  url +  '/navigation.svg'}
			if (t == 'jump' || t == 'pause') { return  url +  '/change_video_time.svg'}
			if (t == 'switch' || t == 'back') { return  url +  '/branching_video.svg'}
			if (t == 'redirect') { return  url +  '/open_link.svg'}
			if (t == 'reset_viewer') { return  url +  '/ms_logo.svg'}
			if (t == 'variable') { return  url +  '/variable_replacement.svg'}
			if (t == 'resourceTray') { return  url +  '/transcripts.svg'}
			if (t == 'card' || t == 'show_card') {return 'fa-light fa-address-card'}
			if (t == 'post') { return  url +  '/html_embed.svg'}
			if (t == 'get') { return  url +  '/html_embed.svg'}
			if (t == 'chatGPT') { return  url +  '/personalization.svg'}
			return url + '/' + t + '.svg'
		},
		getInteractionImage(type, red){
			var url = "https://resource-cdn.mindstamp.com/assets/images/icons-jun_14_22/"
			url += red ? 'Red/' : 'Green/'
			switch (type){
				case 'click':
				case 'button':
					return url + 'buttons.png'
				case 'card':
					return 'fa-regular fa-address-card ' + (red ? 'red' : 'green') + '--text'
				case 'show_card':
					return 'fa-regular fa-address-card ' + (red ? 'red' : 'green') + '--text'
				case 'hotspot':
					return url + 'hotspots.png'
				case 'video':
					return url + 'video_clip.png'
				case 'audio':
					return url + 'multimedia.png'
				case 'comment':
					return url + 'show_message.png'
				case 'switch':
				case 'back':
					return url + 'branching_video.png'
				case 'jump':
					return url + 'change_video_time.png'
				case 'post':
				case 'get':
					return url + 'html_embed.png'
				case 'chatGPT':
					return url + 'personalization.png'
				case 'text':
					return url + 'text.png'
				case 'question':
					return url + 'questions.png'
				case 'drawing':
					return url + 'drawings.png'
				case 'image':
					return url + 'images.png'
				case 'resourceTray':
					return url + 'transcripts.png'
			}
    },
		openLink(str) {
			window.location.href = str
		},
		po: function (str, obj) {
			if (str) {
				this.sayDev(str)
			} else {
        this.sayDev(JSON.stringify(obj, null, 4))
      }
		},
		replaceBadWords: function (str) {
			if (!str) {
				return ""
			}
			var badWords = ['fuck', 'pussy', 'whore', 'jews', 'chink', 'cocksucker', 'c0cksucker', 'slut', 'kunt', 'nigger', 'cunt', 
      'faggot', 'kike', 'faggit', 'n1gga', 'n1gger', 'nazi', 'nigg3r', 'nigg4h', 'nigga', 'niggah', 'niggas', 'niggaz', 'nigger', 'niggers']
			var x = str
			badWords.forEach((w) => {
        var reg = new RegExp('('+w+')', 'gi');
        if (reg.test(str)) {
          x = x.replace(reg, '');
          this.sayDev("Match: " + w)
        }
			})
      // this.sayDev("RBW: " + str + " --> " + x)
			return x
		},
		isValidUrl(value) {
			if (!value) {
				return false
			}
      if (value.includes("localhost:")) {
        return true
      }
      value = value.replaceAll(" ", "%20")
			// var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
			// 	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			// 	'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			// 	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			// 	'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			// 	'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

			var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[()-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d()%_.~+=-]*)?' + // query string
				'(\\#[()-a-z\\d_]*)?$', 'i'); // fragment locator

			this.sayDev("Valid URL: " + !!pattern.test(value.trim()))
			// pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
			return !!pattern.test(value.trim()) || value.includes("}}");
			// return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value.trim());
		},
    secondsToWords: function(seconds) {
      if (seconds < 0 || seconds == undefined) {
				return ''
			}
      var arg = seconds
      // this.sayDev("🐐 STW: " + seconds)
      var results = {
        short: '',
        long: '',
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      if (seconds > 86400) {
        results.days = Math.floor(seconds / 86400)
        arg = seconds % 86400
      }
			var date = new Date(arg * 1000);
			var hh = date.getUTCHours();
			var mm = date.getUTCMinutes();
			var ss = date.getSeconds();
      if (results.days > 0 || hh > 0) {
        results.short = hh+'h'+mm+'m'
        results.long = hh + " hours and " + mm + ' minutes'
        if (results.days > 0) {
          results.short =results.days+'d'+(hh%24)+'h'
          results.long =results.days+ (results.days == 1 ? ' day ' : ' days, ') +  ' and ' + (hh%24)+' hours'
        }
      } else if (mm > 0) {
        results.short = mm+'m'+ss+'s'
        results.minutes = mm
        results.long = mm + " minutes and " + ss + ' seconds'
      } else {
        results.short = ss +'s'
        results.long = ss+' seconds'
      }
      // this.sayDev(results)
      return results
    },
		formattedSeconds: function (seconds) {
			if (seconds < 0 || seconds == undefined) {
				return ''
			}
			var date = new Date(seconds * 1000);
			var hh = date.getUTCHours();
			var mm = date.getUTCMinutes();
			var ss = date.getSeconds();
      var ms = date.getMilliseconds();

			if (hh > 0) {
				hh = hh + ":";
			} else {
				hh = "";
			}
			if (mm < 10 && mm != 0) {
				if (hh == "") {
					mm = mm + ":";
				} else {
					mm = "0" + mm + ":"
				}

			} else if (mm > 10) {
				mm = mm + ":";
			} else if (mm == 0 || mm == 10) {
				mm = mm + ":";
			}
			if (ss < 10) { ss = "0" + ss; }
			// This formats your string to HH:MM:SS
      if (ms == 0) {
        ms = ''
      } else {
        ms = '.'+ms.toString().substring(0,1)
      }
      // This formats your string to HH:MM:SS
      return hh + mm + ss + ms
		},
		sayDev: function (str, line) {
      if (!str) {
        return
      }
			if (!this.isProduction || this.variables.noview || this.variables.dologs || this.variables.doot || this.isDebug || this.currentUser && this.currentUser.email == 'brett@mindstamp.io') {
				if (line) {
					console.log(str, line)
				} else {
					console.log(str)
				}
			}
		},
		flashFocusField: function (id, duration=1500, skipFlash, loud) {
			// this.sayDev("👅 FFF: " + id)
			setTimeout(() => {
				var x = document.getElementById(id)
				if (!x) {
					// this.sayDev("Can't focus on " + id)
					return
				}
        if (!loud) {
          x.focus();
        }
				if (!skipFlash) {
					var old = x.style.border
					x.style.border = '2px solid ' + this.color
          var oldbg = x.style.background
          if (loud) {
            // x.style.transform = "scale(2)"
            // x.style.background = 'yellow'
            x.classList.add("breathingBig")
            x.classList.add("rainbowBorder")
          }
					setTimeout(() => {
            // this.sayDev("FFDONE")
						x.style.border = old
            x.style.background = oldbg
            // x.style.transform = ''
            x.classList.remove("breathingBig")
            x.classList.remove("rainbowBorder")
					}, duration);
				}
			}, 100)
		},
		pluralize(count, single, plural) {
			if (count == 1) {
				return single
			}
			return plural
		},
		capitalizeFirstLetter: (str) => {
			if (!str) {
				return ""
			}
			return str.toString().charAt(0).toUpperCase() + str.slice(1)
		},
		isValidEmail: function (email) {
			var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
			return re.test(email)
		},
		isValidPhone(p) {
      // Grabs text input
      var inputText = p
      var inputStripped = inputText.replace(/\D/g,'');;
      // RegEx Stuff
      var phoneReg = /^[0-9()-.\s]+$/
      return phoneReg.test(inputText) && inputStripped.length >= 10
      
			return p.length > 5
			var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
			var digits = p.replace(/\D/g, "");
			return phoneRe.test(digits);

      
		},
		getPricingURL(from){
			var plan = this.currentUser.organization.plan
			var cp;
			if (plan == 'free'){
				cp = 0;
			} else if (plan == 'starter'){
				cp = 1;
			}else if(plan == 'core'){
				cp = 2;
			}else if(plan == 'pro'){
				cp = 3;
			}
			this.tryTrack('View Account Upgrade', {
				URL: from
			})
			window.open("https://mindstamp.io/pricing" + (cp ? "?cp=" + cp : ''));
		},
		tryTrack: function (title, obj) {
			// this.sayDev("Try Track!")
			if (window.analytics && this.currentUser) {
				window.analytics.track(title, obj)
			}
		},
		getResponses(ctx, parent, answer = '', color='') {
			this.sayDev("Getting Responses for: ", parent)
			Rails.ajax({
				url: `/responses/?token=` +parent.token,
				type: "GET",
				dataType: "JSON",
				success: (data) => {
					var responses = JSON.parse(parent.content.responses);
					if (!responses) {
						responses = {}
					}
					var prompts = {}
					var answers = JSON.parse(parent.content.answers)
					if (answers){
						answers = answers.map(x=>x.text)
					}

					JSON.parse(parent.content.answers).reduce((map,obj) =>{
						if (answers.includes(obj.text)){
							if(responses[obj.text]){
								prompts[obj.text] = responses[obj.text];
							} else {
								prompts[obj.text] = 0;
							}
						}
					},{});
		
					if(answer) {
						prompts[answer] = prompts[answer] ? prompts[answer] + 1 : 1;
						parent.content.responses = JSON.stringify(prompts);
					}
					if(data[parent.content.id]){
						for (var [key, val] of Object.entries(data[parent.content.id])){
							if(answers.includes(key)){
								if (prompts[key]){
									prompts[key] += val
								} else {
									prompts[key] = val
								}
							}
						}
					}
					this.getPollResultsChart(ctx, prompts, answer, color)
				}, error: (e) => {
					console.log("Error", e)
					this.$toasted.show("Could not get responses. Please contact support.")
				}
			})
		},
		getPollResultsChart: function (ctx, prompts, correct_answer='', color='') {
			var sum = Object.values(prompts).reduce((curr, v)=> curr + v,0);
			var data;
			if(sum == 0){
				data = Object.values(prompts).map(x => 0);
			} else {
				data = Object.values(prompts).map(x => Math.round(x / sum * 100));
			}
			// var data = Object.values(prompts);
			var toggle = 0; // 0 = vert, 1 = horizontal
			var yAxis = 'y';
			var xAxis = 'x';
			if (toggle > 0){
				yAxis = 'x';
				xAxis = 'y';
			}
			var borderColors = [], labelColors = [], colors = '';
			if (this.color){
				borderColors.push(this.color);
				colors = this.color;
				labelColors.push('rgba(256,256,256,1)');
			} else {
				Object.keys(prompts).forEach(key => {
					colors = color;
					if (key == correct_answer){
						labelColors.push("rgba(17,119,29, 1)")
						// borderColors.push("rgba(17,119,29, 1)");
					}
					else {
						labelColors.push("rgba(0,0,0,1)")
						// borderColors.push(');
					}
				});
			}
			var myChart = new Chart(ctx, {
				responsive:true,
				maintainAspectRatio: false,
				type: 'bar',
				data: {
					labels: Object.keys(prompts),
					datasets: [{
						// borderColor: borderColors,
						label: 'Results',
						data: data,
						backgroundColor: colors,
						borderRadius: 10,
						// borderWidth: 5,
						datalabels: {
							anchor: 'end',
							align: (context) => {
								var x = context.dataset.data[context.dataIndex]
								return (x > 20 ? 'start' : 'end');
							},
							color: (context) => {
								var x = context.dataset.data[context.dataIndex]
								if (this.color) return 'rgba(256,256,256,1)'
								return (x > 20 ? 'rgba(256,256,256,1)' : 'rgba(0,0,0,1)');
							},
							offset: '10',
							font: {
								weight: 'bold'
							},
							formatter: (value, context) => {
								return value + "%";
								// return value;
							}
						}
					}],	
				},
				plugins: [ChartDataLabels],
					options: {
					},
				options: {
					indexAxis: xAxis,
					legend: {
						labels: {
								fontColor: "white",
						}
					},
					scales: {
						[yAxis]: {
							beginAtZero: true,
							max: 100,
							grid: {
								display: false,
							},
							ticks: {
								callback: (value, index, ticks) => {
										return value + "%";
										// return value;
								}, 
							}
						},
						[xAxis]: {
							grid: {
								display: false,
							},
							ticks: {
								color: labelColors,
								font: {
									weight: 'bold',
								}
							},
						},
					},
					plugins: {
						tooltip: {
								enabled: false,
							},
						legend: { display: false }
					},
				},
			})
		},
		isAdmin() {
      return this.currentUser && this.currentUser.isAdmin
    },
		refreshHubspotFields(){
			Rails.ajax({
				url: '/users/refresh_hubspot',
				type: "POST",
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("✅ Updated Hubspot Properties")
					setTimeout(()=> {
						window.location.reload();
					}, 500)
				}, error: (e) => {
					this.$toasted.show("Could not Update Properties")
				}
			})
		},
		getHubspotLabel(hsField) {
			if (gon.hubspotAllFields)
				for (var hs of gon.hubspotAllFields)
					if (hs.prop_name == hsField)
						return hs.prop_label;	
			return hsField;
		},
		refreshLists(provider, tab=null){
			Rails.ajax({
				url: `/users/lists/get_lists?provider=${provider}`,
				type: "POST",
				dataType: "JSON",
				success: (data) => {
					if (provider == 'cc'){
						this.$toasted.show("✅ Updated Constant Contact Lists")
					} else if (provider == 'hubspot'){
						this.$toasted.show("✅ Updated Hubspot Lists")
					}
					setTimeout(()=> {
						window.location.reload();
					}, 500)
				}, error: (e) => {
					this.$toasted.show("Could not Update Properties")
				}
			})
		},
		openModalVideo(token){
			lity('/w/' + token + '?editing=1')
		},
		testWebhook(type, url) {
			var data = new FormData
			data.append("type", type)
			data.append("url", url)
			if (this.currentShare) {
				data.append("token", this.currentShare.token)
			}
			Rails.ajax({
				url: `/test_webhook`,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Success!")
					var d = document.getElementById(type + '_webhook')
					// d.innerHTML = "<br><span style='color: green'>Test webhook Sent!<span>"
				}, error: (e) => {
					console.log("Error", e)
					this.$toasted.show("Webhook not successful! Please check your address.")
				}
			})
		},
		formatTime(seconds, showSeconds = true) {
			if(seconds == "" || !seconds) return;
			var hh = (showSeconds ? Math.floor(seconds / 3600) : Math.round(seconds / 3600))+ ":"
			var mm = showSeconds ? Math.floor(seconds / 60) % 60 : Math.round(seconds / 60) % 60
			var ss = seconds % 60 

			if (mm < 10) {
				mm = "0" + mm
			}
			if (showSeconds) {
				mm += ":"
					if (ss < 10) { 
				ss = "0" + ss; 
				}
			} else {
				ss = ''
			}
			return hh + mm + ss;
    },
		goToVideos(s){
			if (s=='Favorite'){
				window.location = '/videos?activeTab=2'
			} else {
				window.location = '/videos?search=' + s
			}
		}
	},
	// Mixins
	computed: {
    canAccess() {
      const u = this.currentUser
      if (!u) {
        return {}
      }
      return {
        lists: this.proUpUser || (u && u.organization.can_access_lists),
        precision: this.freeUser || this.coreUpUser || (u.organization.can_use_precise),
        groups: this.coreUpUser || (u.organization.can_access_groups),
        series: this.freeUser || this.coreUpUser || (u.organization.can_access_series),
        files: this.freeUser || this.proUpUser || (u.organization.can_file_upload),
        conditional: this.freeUser || this.proUpUser || (u.organization.conditional_access),
        variables: this.freeUser || this.coreUpUser || (u.organization.variables_access),
        scorm: this.freeUser || this.coreUpUser || (u.organization.can_scorm),
        location: this.freeUser || this.coreUpUser || (u.organization.can_location),
        verify: this.freeUser || this.coreUpUser || (u.organization.can_verify),
        resume: this.freeUser || this.coreUpUser || (u.organization.can_resume),
        api: this.proUpUser || (u && u.organization.api_access),
      }
    },
    fontSizeOptions() {
      var x = []
      var i = 0
      while (i < 300) {
        x.push({ text: i+'px', value: i })
        i = i+2
      }
      return x
    },
		getCurrencies(){
			return ["USD", "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", 
			"AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BHD", "BIF", "BMD", "BND",
			"BOB", "BRL", "BSD", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP",
			"CNY", "COP", "CRC", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP", 
			"ETB", "EUR", "FJD", "FKP", "GBP", "GEL", "GIP", "GMD", "GNF", "GTQ", 
			"GYD", "HKD", "HNL", "HTG", "HUF", "IDR", "ILS", "INR", "ISK", "JMD", 
			"JOD", "JPY", "KES", "KGS", "KHR", "KMF", "KRW", "KWD", "KYD", "KZT",
			"LAK", "LBP", "LKR", "LRD", "LSL", "MAD", "MDL", "MGA", "MKD", "MMK",
			"MNT", "MOP", "MRO", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN", "NAD",
			"NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN", "PGK", "PHP",
			"PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SBD",
			"SCR", "SEK", "SGD", "SHP", "SLE", "SOS", "SRD", "STD", "SZL", "THB",
			"TJS", "TND", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "UYU",
			"UZS", "VND", "VUV", "WST", "XAF", "XCD", "XOF", "XPF", "YER", "ZAR",
			"ZMW"]

		},
    getSeekbarStyle() {
      var x = '0px'
      // if (this.isEmbed) {
      //   return x
      // }
      // if ((this.isIpad || this.isIOS) && !this.currentUser) {
      //   if (this.isLandscape && this.isChrome) {
      //     x = '40px'
      //   } else if (this.isChrome) {
      //     x = '120px'
      //   } else if (this.isSafari && !this.isLandscape) {
      //     x = '80px'
      //   } else if (this.isSafari && this.isLandscape) {
      //     x = '0px'
      //   }
      // }
      return { bottom: x }
    },
    isMobileOrIsIpad() {
      return this.isMobile || this.isIpad
    },
		moveCopyBulkOptions() {
			var options = [
				{ title: "Another Video", value: 'video'},
			]
			if(this.showingAnyTrays){
				options.push({ title: "Standard Interactions", value: 'standard'})
			}
			if(this.showMenu || !this.showingAnyTrays){
				options.push({ title: "End Call-To-Action Interactions", value: 'cta'})
			} 
			if(this.showCTA || !this.showingAnyTrays){
				options.push({ title: "Magic Menu Interactions", value: 'resource'})
			}
			return options
		},
    isDesktopSafari() {
			return this.isSafari && !this.isIOS
		},
		canRecordAudio(){
			return !!this.browserInfo && this.browserInfo.isWebRTCSupported && !this.isIpad
		},
    canRecordVideo() {
			return !!this.browserInfo && this.browserInfo.isWebRTCSupported
		},
    isSafariOrIOSorIpad() {
      return this.isSafari || this.isIOS || this.isIpad
    },
		isIOS() {
			return this.browserInfo && this.browserInfo.osName == 'iOS'
		},
		isIpad() {
			return((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad')
		},
		canFullscreen() {
			return this.isDebug || (screenfull && screenfull.isEnabled && !(this.isIpad && this.isEmbed && this.hasFreeResponseQuestions))
		},
		isAndroid() {
			return this.browserInfo && this.browserInfo.osName == 'Android'
		},
		isSafari() {
			return this.browserInfo && this.browserInfo.browser.name === 'Safari'
		},
    isChrome() {
			return this.browserInfo && this.browserInfo.browser.name === 'Chrome'
		},
		pauseTimes() {
			var x = []
      if (this.annotations) {
        this.annotations.forEach((a)=> {
          if (!a.hidden && (a.pause || (['question', 'drawing', 'video', 'audio', 'resourceTray'].includes(a.type_of) && this.shouldShowConditional(a)))) {
            x.push(Number(a.exact_time))
          }
        })
      }
      // this.sayDev("pauseTimes", x)
			return x
		},
    jumpTimes() {
      var z = this.visibleAnnotations
      var x = {}
      if (this.annotations) {
        this.orderedAnnotations.forEach((a)=> {
          if (a.type_of == 'jump') {
            var tt = (a.exact_time).toString()
            var z = x[tt] || []
            z.push(a)
            x[tt] = z
          }
        })
      }
      // this.sayDev("pauseTimes", x)
			return x
    },
		pColor() {
			return this.$vuetify.theme.primary
		},
		cannotClickSeekbar() {
			var x = !this.ownsPost && this.shareDesign && (!this.shareDesign.seekbar_active || !this.shareDesign.show_seekbar || this.shareDesign.hide_controls)
			// this.sayDev("CCS: " + x)
			return x
		},
		getIntroLayerStyle() {
			return {
				position: "absolute",
				background: "none",
				left: "0px",
				opacity: "1.0",
				overflow: "none",
				top: "0px",
				width: "100%",
				height:"100%",
        maxHeight: this.windowInner - 10 + 'px',
				zIndex: "5",
				color: "white"
			}
		},
		canResetState() {
			if (!this.annotations || !this.annotations.length) {
				return false
			}
			var can = false
			this.annotations.forEach((a)=> {
				if (a.disabled || a.invisible) { can = true}
				return true
			})
			return can
		},
		currentUserDomain() {
			if (this.currentUser && this.currentUser.organization.domain_is_active) {
				return this.currentUser.organization.custom_domain
			}
			// if (!this.isProduction) {
			// 	return window.location.origin
			// }
			if (this.currentShare && this.currentShare.capture_payment_point > -1){
				return 'https://go.payper.video'
			}
			if (window.location.href.includes("stella.mindstamp")) {
				return 'https://stella.mindstamp.com'
			}
			return 'https://share.mindstamp.com'
		}, 
		currentUserEmbedDomain() {
			if (this.currentUser && this.currentUser.organization.domain_is_active) {
				return this.currentUser.organization.custom_domain
			} 
			if (this.currentShare && this.currentShare.capture_payment_point > -1){
				return 'https://go.payper.video'
			}
      if (window.location.href.includes("localhost")) {
        return 'http://localhost:3000'
      }
			return 'https://embed.mindstamp.com'
		},
		freeUser() {
			return gon.isDemo || this.currentUser && (this.currentUser.plan == 'free')
		},
		starterUser() {
			return this.currentUser && (this.currentUser.plan == 'starter')
		},
		singleUser() {
			return this.currentUser && (this.currentUser.plan == 'single')
		},
		educationUser() {
			return this.currentUser && (this.currentUser.plan == 'educator')
		},
		coreUpUser() {
			return this.currentUser && (this.currentUser.plan == 'core' || this.currentUser.plan == 'pro' || this.currentUser.plan == 'enterprise')
		},
		coreUser() {
			return this.currentUser && (this.currentUser.plan == 'core') || this.singleUser()
		},
		proUpUser() {
			return this.currentUser && (this.currentUser.plan == 'pro' || this.currentUser.plan == 'enterprise')
		},
	}
})


Vue.use(Toasted, {
	position: 'top-center',
	containerClass: 'customToast',
	singleton: true,
	duration: 1500,
})

Vue.use(Vuetify, {
	theme: { primary: gon.color || '#282876' },
  iconfont: 'fa',
})

// Old purple: #121A40
Vue.use(vSelect)
Vue.config.productionTip = false
Vue.config.silent = true
Vue.use(VLazyImagePlugin)
Vue.use(VueClipboard)
VTooltip.enabled = window.innerWidth > 540
Vue.use(VTooltip)


var player;

new Vue({
	el: '#app',
	components: components,

	// ddddd
	data: function () {
		return {
      hidePayperManagement: gon.hidePayperManagement,
      currentUrl: window.location.href,
      skipAmounts: [
        { text: '5 seconds', value: 5},
        { text: '10 seconds', value: 10},
        { text: '15 seconds', value: 15},
        { text: '20 seconds', value: 20},
        { text: '25 seconds', value: 25},
        { text: '30 seconds', value: 30},
        { text: '60 seconds', value: 60},
      ],
      allowConditional: gon.allowConditional,
      gaProgressLog: [],
      switchVideoTransition: gon.shareDesign ? gon.shareDesign.switch_transition : '',
      ownerFreeOrExpired: gon.ownerFreeOrExpired,
      fetchingResumable: false,
      articles: gon.articles,
      articleDialog: false,
      activeArticle: null,
      editArticle: null,
      editArticleDialog: false,
      showToken: false, // new video password / api token viewer
      afkTimer: null,
      afkOptions: [
        { text: '30 seconds', value: 0.5},
        { text: '1 minute', value: 1},
        { text: '2 minutes', value: 2},
        { text: '3 minutes', value: 3},
        { text: '4 minutes', value: 4},
        { text: '5 minutes', value: 5},
        { text: '6 minutes', value: 6},
        { text: '7 minutes', value: 7},
        { text: '8 minutes', value: 8},
        { text: '9 minutes', value: 9},
        { text: '10 minutes', value: 10},
      ],
      afkDialog: false,
      showingZapier: false,
      importChaptersDialog: false,
      minAnswerWidth: 70,
      minAnswerHeight: 40,
      isProduction: gon.isProduction,
      forceAuthInteraction: false,
      loadingTranscript: false,
      selectedGptQuestions: [],
      questionGptDialog: false,
      questionGptCount: 5,
      errorCount: 0,
      postsNewVars: {
        synToken: '',
        synMissing: false,
        heyToken: '', 
        heyMissing: false
      },
			questionGptText: 'Anaylzing Transcript...',
			gettingGptQuestions: false,
			gptQuestion: -1,
      gptQuestions: null,
			testGptQuestions: [
        {
            "id" : 1,
            "prompt": "What is the name of Apple's first ever spatial operating system?",
            "answers": [
                "Visionos",
                "Vision Pro",
                "Spatial OS",
                "Visualize"
            ],
            "correct": "Visionos",
            "time": 35
        },
        {
            "id" : 2,
            "prompt": "What does Apple Vision Pro use to navigate and interact with the system?",
            "answers": [
                "Voice commands",
                "Touch gestures",
                "Hand motions",
                "Facial recognition"
            ],
            "correct": "Touch gestures",
            "time": 56
        },
        {
          "id" : 4,
          "prompt": "What does Apple Vision Pro use to navigate and interact with the system?",
          "answers": [
              "Voice commands",
              "Touch gestures",
              "Hand motions",
              "Facial recognition"
          ],
          "correct": "Touch gestures",
          "time": 56
      },
      {
        "id" : 6,
        "prompt": "What does Apple Vision Pro use to navigate and interact with the system?",
        "answers": [
            "Voice commands",
            "Touch gestures",
            "Hand motions",
            "Facial recognition"
        ],
        "correct": "Touch gestures",
        "time": 56
    },
    {
      "id" : 7,
      "prompt": "What does Apple Vision Pro use to navigate and interact with the system?",
      "answers": [
          "Voice commands",
          "Touch gestures",
          "Hand motions",
          "Facial recognition"
      ],
      "correct": "Touch gestures",
      "time": 56
  },
  {
    "id" : 8,
    "prompt": "What does Apple Vision Pro use to navigate and interact with the system?",
    "answers": [
        "Voice commands",
        "Touch gestures",
        "Hand motions",
        "Facial recognition"
    ],
    "correct": "Touch gestures",
    "time": 56
},
        {
            "id" : 3,
            "prompt": "What feature allows you to see other people and be seen by them while using Apple Vision Pro?",
            "answers": [
                "Infrared scanner",
                "FaceTime",
                "Spatial Audio",
                "Virtual Environment"
            ],
            "correct": "FaceTime",
            "time": 88
        },
        {
            "id" : 4,
            "prompt": "How does Apple Vision Pro transform your living room into a gallery?",
            "answers": [
                "By projecting virtual images",
                "By expanding in three dimensions",
                "By filling the space with spatial audio",
                "By bringing in a beautiful environment"
            ],
            "correct": "By bringing in a beautiful environment",
            "time": 113
        },
        {
            "id" : 5,
            "prompt": "What technology does Apple Vision Pro use to represent the user realistically in FaceTime?",
            "answers": [
                "3D modeling",
                "Machine learning",
                "Augmented reality",
                "Holographic projection"
            ],
            "correct": "Machine learning",
            "time": 410
        }
    ],
      progressInterval: 30,
      seconds: [0],
      waitingToRecordAudio: false, // used to record audio immediately after drawing
      previewNum: 0,
      previewOptions: [
        { text: 'Desktop', value: 0},
        { text: 'Mobile', value: 1},
        // { text: 'Mobile (Landscape)', value: 2},
      ],
			noGraphData: false,
      sidebarTimer: null,
      blockAutoSave: false,
      saveTimer: null,
			showSidebarTitles: false,
      doingSave: false,
      showingSave: false,
      freshFont: gon.fontInfo && gon.fontInfo.use_custom_font && gon.fontInfo.font_family || gon.fontInfo && gon.fontInfo.selected_font,
      activeSideItem: (this.getUrlParam('vtab') || 'interactions').toLowerCase(),
      activeSideIcon: 'bolt',
      sideMenuItems:[
        {
          title: 'Interactions',
          id: 'interactions_button',
          icon: 'bolt',
          tooltip: "View, add, and edit interactions on this video"
        },
        {
          title: 'Design',
          id: 'design_button',
          icon: 'palette',
          tooltip: "Edit the look, feel, and branding of the video"
        },
        {
          title: 'Lead Capture',
          id: 'leads_button',
          icon: 'address-card',
          tooltip: 'Manage lead capture settings'
        },
        {
          title: 'Magic Menu',
          id: 'resource_button',
          icon: 'list-dropdown',
          tooltip: 'Create or edit the in-video menu'
        },
        {
          title: 'End Screen',
          id: 'cta_button',
          icon: 'check',
          tooltip: 'Set what happens or shows when the video ends'
        },
        {
          title: 'Settings',
          id: 'settings_button',
          icon: 'gear',
          tooltip: 'Edit settings for this video such as privacy, paywall, and more'
        },
        {
          title: 'Transcript',
          id: 'transcript_button',
          icon: 'scroll',
          tooltip: 'View or edit the transcript of this video'
        },
        {
          title: 'Chapters',
          id: 'chapters_button',
          icon: 'list-timeline',
          tooltip: 'Add or edit video chapters'
        },
        {
          title: 'Captions',
          id: 'captions_button',
          icon: 'closed-captioning',
          tooltip: 'Add or edit closed captions'
        },
        {
          title: 'Analytics',
          id: 'report_button',
          icon: 'signal-bars',
          tooltip: 'See activity and analytics for this video'
        },
        {
          title: 'Paywall',
          id: 'paywall_button',
          icon: 'circle-dollar',
          tooltip: 'Sell this video by adding a paywall'
        },
        {
          title: 'Integrations',
          id: 'integrations_button',
          icon: 'plug',
          tooltip: 'Configure integratons for this video'
        },
        {
          title: 'Preview',
          id: 'previewButton',
          icon: 'play',
          tooltip: 'Preview your video to see what it looks like to viewers'
        },
        {
          title: 'Share',
          id: 'shareButton',
          icon: 'share-nodes',
          tooltip: 'Share your video via link or embed'
        },
        {
          title: 'Genie AI',
          id: 'genie_button',
          icon: 'microchip-ai',
          tooltip: 'Custom trained AI Chatbot based on your video transcript'
        },
        // {
        //   title: 'Duplicate',
        //   id: 'duplicate_button',
        //   icon: 'clone',
        //   tooltip: 'Create a duplicate of this video'
        // },{
        //   title: 'Replace',
        //   id: 'replace_button',
        //   icon: 'repeat-1',
        //   tooltip: 'Replace the underlying video file while keeping your interactions'
        // },
        // {
        //   title: 'Variables',
        //   id: 'variables_button',
        //   icon: 'gem',
        //   tooltip: 'Manage variables within this video'
        // },
        // {
        //   title: 'Backups',
        //   id: 'backups_button',
        //   icon: 'cloud-plus',
        //   tooltip: 'Create a backup of the current state'
        // },
        // {
        //   title: 'Branching',
        //   id: 'branching_button',
        //   icon: 'code-branch',
        //   tooltip: 'Visualize the branching for this video'
        // },
        {
          title: 'More',
          id: 'manage_button',
          icon: 'globe',
          tooltip: 'Duplicate or replace video, create backups, delete interactions, and more'
        },
      ],
			startNavEdit: false,
			cardSelectStep: -1,
			cardTitleExpanded: false,
			cardTextExpanded: false,
			cardButtonExpanded: false,
			selectedCard: null,
			activeCard: null,
			cardUpdated: 0,
			cardComponent: 0,
      cardSearch: '',
      cardPage: 1,
			createNewCardDialog: false,
			suppressCardWatcher: false,
      adShareDialog: false,
      gptAnswer: '',
      gptJumps: [],
      gptDialog: false,
			startNavEdit: false,
			generatedContentResultsIndex: 0,
			generatedContentResults: null,
			generateContentPrompt: '',
			generateContentTones: [],
			gettingGeneratedContent: false,
			generatedValueResultsIndex: 0,
			generatedValueResults: null,
			generatedPromptResultsIndex: 0,
			generatedPromptResults: null,
			generateValuePrompt: '',
			generateValueTones: [],
			gettingGeneratedValue: false,
      imagePage: 1,
      imageSearch: '',
			selectedStockType: gon.isProduction ? 0 : 8,
      viewerShareInfo: {
        subject: 'Check out this video!',
        body: '',
        email: '',
        showPeople: false
      },
			imageSearch: '',
			imagePage: 1,
			selectedImageType: 0,
			meaLoaded: false,
			savedReports: gon.saved_reports,
      earlyClips: {},
      earlySwitches: {},
			httpResponseData: null,
			httpFields: [],
			updatedConnector: 0,
			invalidChromeSrc: false,
      seeking: false,
			tooLong: false,
			storedCurrency: this.getLocalStorageItem("MS_CURRENCY"),
			freshBtn: false,
			addToCard: false,
			showCardAnns: null,
			monetizationFee: 0,
      introClipUrl: gon.intro_clip_url,
      introClipDialog: !!gon.intro_clip_url,
      introClipStarted: false,
			showPayeeAuthTokenPrompt: false,
			payeeAuthToken: '',
			showpayeeAuthToken: false,
			stats: {
				views: 0,
        viewers:0,
        watch: 0,
        engage: 0,
        interactions: 0,
			},
			askingOpenAi: false,
			openAiResponse: '',
			showingCheckout: false,
			showingVariants: false,
			copyEmbedDialog: false,
      helpDialog: false,
			newLeadsEmail: '',
			newVideoPassword: '',
      scriptsLoaded: {
        html: false,
        jszip: false,
        chart: false,
				shopify: false,
      },
			isDownloading: false,
			needsToPay: gon.needsToPay,
			payments: gon.payments,
			payees: gon.payees,
			refunds: gon.refunds,
			content: gon.content,
			paymentStats: gon.payment_stats, 
			invalidPayee: gon.invalidPayee,
			expiredPayee: gon.expiredPayee,
      useIntroPlayer: gon.domoving || gon.intro_clip_url,
			openPreviewDialog: false, 
      mouseInSeekbar: false,
			// sampleVideoUrl: 'https://resource-cdn.mindstamp.com/assets/clips/surfa_720.mp4',
      sampleVideoUrl: 'https://vz-68eed7d6-ede.b-cdn.net/47fee66d-d5bf-403f-a7b4-4c65b6cdcd90/original',
      heygenVideos: null,
      heygenVideo: null,
      getMoreHeygenVideos: true,
			synthesiaVideos: null,
			synthesiaVideo: null,
			synthesiaHeaders: [
				{ text: 'Video Title', value: 'title' },
				{ text: 'Date', value: 'date' },
				{ text: 'Status', value: 'status' },
			],
			getMoreSynthesiaVideos: true,
			synthesiaSearch: '',
			stockAssets: null,
			stockAsset: null,
			playingSampleAsset: null,
			stockHeaders: [
				{ text: 'Video Title', value: 'title' },
				{ text: 'Date', value: 'date' },
				{ text: 'Status', value: 'status' },
			],
			getMoreStockAssets: true,
			stockSearch: null,
			searchingStock: false,
			editingCta: false,
      featureMoreInfo: false,
      pendingAnn: null,
      deleteAnnDialog: false,
			deleteCardChildren: false,
      placingInteraction: false,
      coords: {
        left: null,
        top: null,
        width: null,
        height: null
      },
			currentAnswer: 0,
			assetDownload: Array(5).fill({
				url: '',
				downloadName: '',
				id: '',
				type: '',
			}),
			orgVariables: gon.orgVariables,
			doTranscribe: false,
			transcriptionUrl: '',
      blockEnter: false,
			donkeyAnythingSearch: '',
			donkeyAnythingResults: null,
			htmlEditorContent: false,
			htmlEditorValue: false,
			bulkVideos: gon.bulk_videos,
      blockToggle: false,
      debugDialog: false,
      debugDialog: false,
      hotspotRingWidth: 33,
      bulkOption: '',
      bulkOption2: '',
			bulkOptions: [
        { title: "Update Start Time", value: 'start'},
				{ title: "Update End Time", value: 'end'},
				{ title: "Undo", value: 'undo'},
				{ title: "Redo", value: 'redo'},
				{ title: "Set Position", value: 'coords'},
				{ title: "Shift Time", value: 'shift'},
				{ title: "Tidy", value: 'tidy'},
				{ title: "Place Interactions", value: 'place'},
				{ title: "Shift Positions", value: 'shiftCoords'},
				{ title: "Center Horizontally", value: 'center_h'},
				{ title: "Center Vertically", value: 'center_v'},
				{ title: "Align Horizontally", value: 'align_h'},
				{ title: "Align Vertically", value: 'align_v'},
				{ title: "Copy to...", value: 'copyTo'},
				{ title: "Move to...", value: 'moveTo'},
				{ title: "Toggle Visibility", value: 'hidden'},
        { title: "Delete Interactions", value: 'delete'},
				{ title: "Duplicate Interactions", value: 'copy'},
				{ title: "Update Text Color", value: 'text_color'},
				{ title: "Update Background Color", value: 'background_color'},
				{ title: "Update Border Color", value: 'border_color'},
				{ title: "Update Rounding", value: 'border_radius'},
				{ title: "Update Custom Transition", value: 'transition'},
				{ title: "Update Custom Animation", value: 'animation'},
				{ title: "Update Font Size", value: 'fontSize'},
				{ title: "Update Post Click State", value: 'post_click_state'},
				{ title: "Update Require Click", value: 'must_click'},
			],
      bulkValue: null,
      multiDialog: false,
      selectedAnnotations: [],
      msBlue: '#282876',
      testingVar: 2.2, 
      activeEditPanel: gon.isProduction ? 0 : 5,
      darkMode: gon.darkMode,
      showAdminButtons: !!localStorage.getItem("doadmin"),
      dactions: {},
			shoppableSrc: gon.shoppableSrc,
			shopifyUrl: gon.shopifyUrl,
			shopifyConnected: gon.shopifyConnected,
			hintMessage: '',
			translatedStrings: {},
			strings: {},
			previewPoints: false,
			featureDialog: false,
			featureID: '',
			featureTitle: '',
			featureTitle: '',
			featureDescription: '',
			featureLink: '',
			featureImage: '',
			newFeature: true,
			featureTime: gon.latest_feature_time,
			latestFeatures: gon.latest_features,
			lastAnimationPoint: 0,
			deciTime: 0.0,
			exactTime: 0,
			timeHandler: null,
			leaderLines: [],
			activePoint: 0,
			moveDialog: false,
      pausedAccounts: gon.paused_accounts || [],
			movePoints: [],
			hotspotAlignments: [
				{ label: 'Left', value: 0},
				{ label: 'Center', value: 1},
				{ label: 'Right', value: 2},
			],
			latitude: null,
			longitude: null,
			marketingConsentDialog: false,
			isChoosingAnswer: false,
			preventNavs: false,
			preventNavsTimer: null,
			latestPoint: 0,
			titleDialog: false,
			designTab: this.getUrlParam("dtab") || 8,
			userTextTrack: false,
			vimeoWarnDialog: false,
			vimeoWarnKey: '',
			advancedEditing: 0,
			activeDivStyleHold: {
				background_color: null,
				text_color: null,
				border_color: null,
				hold_background_color: null,
				hold_text_color: null,
				hold_border_color: null,
			},
			doingTransitionPreview: false,
			animationItems: [
				{ text: 'None', value: ''},
				{ text: 'Gelatine', value: 'gelatine_v1'},
				// { text: 'Spin', value: 'spin'},
				// { text: 'Elastic Spin', value: 'elastic-spin'},
				{ text: 'Pulse', value: 'pulse_v1'},
				{ text: 'Hi There!', value: 'hithere_v1'},
				{ text: 'Grow', value: 'grow_v1'},
				{ text: 'Bounce', value: 'bounce_v1'},
				{ text: 'Flip', value: 'flip_v1'},
				// { text: 'Shake', value: 'shake_v1'},
				{ text: 'Swing', value: 'swing_v1'},
				{ text: 'Wobble', value: 'wobble_v1'},
				{ text: 'Flash', value: 'flash_v1'},
				{ text: 'Jumbotron', value: 'jumbotron_v1'},
			],
			tutorials: [
				{ name: 'Creating a Video', duration: '0:46', token: 'DTWewHDUoMfC', image: 'https://resource-cdn.mindstamp.com/assets/streamline-icons/hand-grey.png'},
				{ name: 'Editor Overview', duration: '4:13', token: 'VTQBaLLiruCm', image: 'https://resource-cdn.mindstamp.com/assets/streamline-icons/question-grey.png'},
				{ name: 'Basic Interactions', duration: '6:43', token: 'VOfooWXUmwJl', image: 'https://resource-cdn.mindstamp.com/assets/streamline-icons/photo-grey.png'},
				{ name: 'Questions', duration: '3:46', token: 'gMZJwBBmYRZU', image: 'https://resource-cdn.mindstamp.com/assets/streamline-icons/sun-grey.png'},
				{ name: 'Design & Branding', duration: '5:09', token: 'TiQEywEgAVfp', image: 'https://resource-cdn.mindstamp.com/assets/streamline-icons/sun-grey.png'},
				{ name: 'Navigations, Drawing, Media Clips', duration: '1:28', token: 'rcBfJYLWPIGW', image: 'https://resource-cdn.mindstamp.com/assets/streamline-icons/sun-grey.png'},
				// { name: 'Full Mindstamp Walkthrough', duration: '46:45', token: 'DPNisGuUNeuM', image: 'https://resource-cdn.mindstamp.com/assets/images/icons/loop-blue.png'},
			],
			tutorialDialog: false,
			isPrinting: false,
			actionData: gon.actionData,
			activeType: window.location.href.includes('chrome_src') ? window.location.href.includes('wistia') ? 3 : 1 : gon.current_user && gon.current_user.organization.can_upload ? 0 : 1,
			parentDomain: window.location.href,
			doIndividualVariables: false,
			hubInteractions: [],
			showSearch: false,
			skipVars: ['plink_id', 'viewer_id', 'doot', 'noview', 'gostamp', 'qstamp', 'qdebug', 'timedebug'],
			qrCode: null,
			continueDialog: false,
			replacingVideo: window.location.href.includes("replace"),
			alignItems: [
				{ text: 'Indicator: Left', value: 0 },
				{ text: 'Indicator: Center', value: 1 },
				{ text: 'Indicator: Right', value: 2 },
			],
			drawWarning: false,
			viewerID: gon.contactID,
			viewer_stats: gon.viewer_stats,
			lastPlay: gon.lastPlay,
			lastPlayAnnotations: gon.lastPlayAnnotations,
			referVideo: gon.referVideo || false,
			referTime: gon.referTime || 0,
			referVideoStack: this.isJSON(gon.referVideoStack) ? JSON.parse(gon.referVideoStack) : [],
			referTimeStack: this.isJSON(gon.referTimeStack) ? JSON.parse(gon.referTimeStack) : [],
			advancedControls: false,
			editTranscriptDialog: false,
			vimeoID: "",
			vimeoHLSDialog: false,
			timeDialog: false,
			pauseAnnotationID: null,
			isDraggingMarker: false,
			isInline: false,
			dialogVideoReady: false,
			videoAnnotation: null,
			audioAnnotation: null,
			lastProgressEvent: -1,
			progressPoints: {
				'10': false, '25': false, '50': false, '75': false, '90': false, '100': false
			},
			videoCover: true,
			interact: null,
			support: {
				link: '',
				browser: '',
				description: ''
			},
			supportDialog: false,
			showVariables: false,
			showHubspot: false,
			adjustingVolume: false,
			dynamicCount: 0,
			recentInteractions: [],
			recentDialog: false,
			rowColItems: [2, 3, 4, 5, 6, 7, 8, 9, 10],
			editGrid: {
				snap: false,
				show: false,
				rows: "5",
				cols: "5"
			},
			showResponseTable: false,
			responseParent: null,
			activeSpeech: null,
			importDetails: {
				from_id: null,
				to_id: null,
				children: false
			},
			importDialog: false,
			backupDialog: false,
			copyTrayDialog: false,
			copyTrayTo: false,
			siteScraperDialog: false,
			urlForImages: this.getLocalStorageItem('MS_SITE_URL') || '',
			siteImages: null,
			siteImageParents: null,
			loadingSiteImages: false,
			branchingDialog: window.location.href.includes("branching=1"),
			variableDialog: false,
			productsDialog: false,
			products: [],
			variants: {},
			shopifySession: null,
			checkoutSession: null,
			checkoutUrl: '',
			shoppingCart: [],
			shoppingCartCount: 0,
			selectedProduct: '',
			selectedVariant: [],
			productSearch: '',
			dataEditing: false,
			editTitle: false,
			betaFlag: window.location.href.includes("beta=1"),
			confirmLink: null,
			nextMedias: {
				video: {},
				audio: {}
			},
			startTime: gon.startTime || 0,
			loadingMedia: false,
			checkedItems: [],
			blockSaveChunks: false,
			browser: '',
			navDialog: false,
			activeNav: {},
			pausedUntilClick: false,
			warnedDynamic: false,
			playerError: false,
			lastPauseTime: -1,
			windowOriginalSearch: window.location.search,
			visibleAnnotations: gon.annotations,
			conditionalItems: {
				shows: [
					{ text: 'Show if...', value: true },
					{ text: 'Hide if...', value: false },
				],
				equals: [
					{ text: 'Equals', value: 'equals' },
					{ text: 'Does not equal', value: 'notequals' },
					{ text: 'Exists', value: 'exists' },
					{ text: 'Does not exist', value: 'notexists' },
					{ text: 'Number less than', value: 'lessthan' },
					{ text: 'Number greater than', value: 'greaterthan' },
					{ text: 'Contains', value: 'contains' },
					{ text: 'Does not contain', value: 'notcontains' },
					{ text: 'Matches Regex', value: 'regex' },
				]
			},
			designDialog: window.location.href.includes("?design=1"),
			scormDialog: false,
			plinkDialog: false,
			showMenu: false,
			showCtaTrayNext: false,
			userDesign: gon.userDesign || {},
			groupDesign: gon.groupDesign || {},
			shareDesign: gon.shareDesign || {},
			seekbarPercentage: 0,
			interactionParams: window.location.search.replace("?", "&"),
			switchingVideo: false,
			hideLoader: false,
			collectPrompt: null,
			correctCount: 0,
			incorrectCount: 0,
			multiCorrect: false,
			interactionsError: false,
			chapterObject: {
				id: '',
				title: '',
				description: '',
				start: 1,
				finish: 10,
				source_id: gon.share ? gon.share.source_id : ''

			},
			chapters: [],
			filteredChapters: [],
			chaptersDialog: false,
			firstVideoDialog: false,
			lastQuestionID: null,
			speedPopup: false,
			captionsPopup: false,
			uploadCaption: {
				label: null,
				url: null
			},
			captionsDialog: false,
			activeTextTrack:  gon.default_captions || "Off",
			textTracks: ["Off"],
			dialogDebug: window.location.href.includes("dialogs=1"),
			addAnother: false,
			activeDivFontSize: 20,
			activeDivBorderRadius: 0,
			imageSelectDialog: false,
			cardSelectDialog: false,
			makeCardCopy: false,
			assetSelectDialog: false,
			appendImages: false,
			currentImageIndex: 0,
			divDialog: false,
			divsActive: [],
			activeDivTransform: {
				x: 0,
				y: 0,
				angle: 0
			},
			activeDiv: {
				type_of: 'button',
				content: '',
        time: 0, 
        exact_time: 0.0,
				extra: "",
				action: null,
				pause: false,
				value: null,
				is_conditional: false,
				conditional_show: true,
				conditional_variable: '',
				conditional_assertion: 'equals',
				conditional_value: '',
				is_conditional_2: false,
				conditional_variable_2: '',
				conditional_assertion_2: 'equals',
				conditional_value_2: '',
				is_hubspot: false,
				transition: null,
				animation: null,
				text_color: null,
				border_color: null,
				background_color: null,
				borderRadius: "",
				hubspotField: null,
				hubspotValue: null,
				customStyle: {
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					width: 0,
					height: 0,
					angle: 0,
				},
				post_click_state: '',
				post_click_color: '',
				post_click_text_color: '',
				background_image: '',
				must_click: false,
				points: [],
        internal_label: null,
        do_spotlight: false,
        force_lead_capture: null,
        hide_desktop: false,
        hide_mobile: false,
			},
			timeDrag: false,
			userInteractions: [],
			variables: {

			},
			stylesModal: false,
			shareVariables: {
				title: null,
				image: null,
			},
			busy: false,
			waitingAction: null,
			questionAnswer: null,
			addingModalDialog: false,
			showMessageDialog: false,
			showCardDialog: false,
			showPollDialog: false,
			showPollDialogNext: false,
			messageToShow: '',
			loadingReplies: false,
			audioTitle: '',
			touchPlayDialog: false,
			userStyles: {
				clearBackground: gon.clearBackground
			},
			phoneDialog: false,
			phoneVal: '',
			embeds: gon.embeds,
			showExamples: false,
			assets: false,
			isTouchDevice: false,
			browserInfo: false,
			publishDialog: false,
			group_id: null,
			password: '',
			email: '',
			passcode: '',
			submittingPassword: false,
			submittingEmail: false,
			submittingPasscode: false,
			halt: gon.halt,
			showBack: false,
			videoTrueWidth: 0,
			videoTrueHeight: 0,
			youtubeWarnDialog: false,
			showReplies: [],
			searchTimer: null,
			searchString: '',
			adblockerDialog: false,
			gettingInteractions: true,
			fullscreenBusy: false,
			activeNotes: [],
			chosenAnswer: false,
			responseForRepeat: false,
			forceCorrectDone: false,
			showingRepeatResult: false,
			startShare: gon.shareInfo,
			startLogin: false,
			startSignup: false,
			contact: gon.contact,

			// Posts new
			e1: -1,
			video_type: null,
			blockUploadConfirm: gon.blockUploadConfirm,
			blockUploadTime: gon.blockUploadTime,
			blockUploadCount: gon.blockUploadCount,
			limits: gon.limits,
			blockUpload: gon.blockUpload,
			firstVideo: gon.firstVideo,
			optimizeDialog: false,
			videoCount: gon.videoCount,
			blockNew: gon.blockNew,
			pasting: false,
			pasteLink: this.getUrlParam('chrome_src') || '',
			chromeApprove: false,
			fromChrome: window.location.href.includes('chrome_src='),
			uploadProgress: 0,
			video_name: '',
			video_size: '',
			video_key: '',
			videoId: '',
      uploadVideos: [],
			queueCompleted: false,
			uploadFinished: false,
			wistiaKey: null,
			wistiaEmbedUrl: '',
			youtubeKey: null,
			ytEmbedUrl: '',
			vimeoKey: null,
			playDialog: true,

			// Shares
      dupShare: gon.share,
			shareDialog: false,
			dupDialog: false,
			dupTitle: '',
			dupNotes: false,
			shares: gon.shares || [],
			participate: gon.canParticipate,
			uploading: window.location.href.includes("isuploading") ? 1 : 0,
			currentShare: gon.share,
			currentThumbnail: gon.thumbnail,
			logo: gon.logo,
			style: gon.style,
			canDraw: !window.location.href.includes("draw=0"),
			groups: gon.groups,
			orgGroups: gon.orgGroups,
			groupDialog: window.location.href.indexOf("addGroup=1") > -1,
			tags: gon.tags,
			accessLists: gon.accessLists,
			overGroupLimit: gon.overGroupLimit,
			groupName: '',
			groupDesc: '',
      groupAllUsers: false,
			
			series: gon.series,
			orgSeries: gon.orgSeries,
			seriesDialog: window.location.href.indexOf("addSeries=1") > -1,
			overSeriesLimit: gon.overSeriesLimit,
			seriesName: '',
			seriesDescription: '',

			// Layout and general
			baseUrl: gon.baseUrl,
			shareUrl: gon.shareUrl,
			previewUrl: gon.previewUrl,
			width: "0",
			isXL: false,
			isMini: false,
			showScroll: true,
			footer: gon.footer,
			reportColor: '#04AA6D',
			showToolbar: gon.showToolbar,
			showLoginModal: false,
			currentUser: gon.current_user,
			currentUserId: null,
			introDialog: gon.intro && !window.location.href.includes("minimal"),
			showCTA: window.location.href.includes("end=1"),
			screenHeight: 1080,
			screenWidth: 1920,
			mobilePreview: false,
			isMobile: window.innerWidth < 540 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.location.href.includes("mobile=1"),
			isEmbed: gon.isEmbed,
			isPopout: false,
			progress: gon.progress,
			endOfActivities: gon.done,
			percentage: 0,
			users: gon.users,
			plans: gon.plans,
			plays: gon.dailyPlays || gon.plays,
			daily: gon.daily,
			videos: gon.videos,
			videoSearch: '',
			filteredVideos: gon.videos,
			dashData: gon.dashData,
			ajaxingActivities: false,
			ajaxHold: false,
			action: gon.action,
			isOffline: window.location.href.includes("offline"),
			isPreview: window.location.origin.includes("preview."),
			debugMessages: [],
			hideControls: !gon.ownsPost && window.location.search.indexOf('controls=0') > -1,
			isMinimal: !gon.ownsPost && window.location.search.indexOf('minimal=1') > -1,
			isSmooth: !gon.ownsPost && window.location.search.indexOf('smooth=1') > -1,
			isExperimental: window.location.search.indexOf('exp=1') > -1,
			isDemo: gon.isDemo,
			isTour: gon.isTour,
			showSeekbar: false,
			showUnmute: gon.showUnmute,
			isFullscreen: false,
			drawingDialog: false,
			isDrawing: false,
			isColoring: false,
			waitingObject: false,
			editObject: false,
			canvas: null,
			canvasReady: false,
			canvasDirty: false,
			canvasSettings: {
				background: true,
				addText: false
			},
      drawer: false,
			fillColor: { hex: gon.color && gon.color != '#000000' ? gon.color : '#ec5072' },  // ff0202
			activeObject: null,
			buttonAnnotation: null,
			imageAnnotation: null,
			drawingAnnotations: null,
			imageDialog: false,
			viewImageDialog: false,
      viewImageExit: false,
			videoWidth: window.innerWidth,
			videoHeight: document.documentElement.clientHeight,
      windowInner: document.documentElement.clientHeight,


			// tour
			welcome: false || gon.welcome,
			started: false,

			// Billing
			isSubscribed: gon.isSubscribed,
			plan: gon.plan,
			cancellingSubscription: gon.cancellingSubscription,
			nextBilling: gon.nextBilling,
			lastBilling: gon.lastBilling,
			uploadList: gon.uploadList,
			monthlyUploads: gon.monthlyUploads,
			monthlyMinutes: gon.monthlyMinutes,
			billingAmount: gon.billingAmount,
			card_last_4: gon.card_last_4,
			card_exp: gon.card_exp,
			card_brand: gon.card_brand,


			// Post
			player: null,
			student: gon.student,
			showBrandingImage: gon.branding,
			showMessage: gon.showMessage,
			captureEmail: gon.captureEmail,
			capturePhone: gon.capturePhone,
			captureName: gon.captureName,
			allCustomVIC: gon.allCustomVIC,
			captureCustomFields: gon.captureCustomFields || [],
			captureID: gon.captureID,
			captureCustom: null, // TODO
			captureCustomCount: 0, // TODO
			fastPlayback: gon.fastPlayback,
			isVimeo: gon.sourceType == "vimeo",
			isWistia: gon.sourceType == "wistia",
			isYoutube: gon.sourceType == "youtube",
			views: gon.views,
			brandingImage: gon.brandingImage,
			showMindstampImage: gon.showMindstampImage,
			ownsPost: gon.ownsPost,
			isVideoOwner: gon.isVideoOwner,
			navControls: gon.navControls || window.location.href.includes("nav=1"),
			showNotes: window.location.href.includes("notes=1") || gon.ownsPost || !gon.isPresentation,
			showMoreInfo: !gon.isProduction,
			mindstampImagePurple: "https://resource-cdn.mindstamp.com/logos/v2/full/color.png",
			mindstampImageWhite: "https://resource-cdn.mindstamp.com/logos/v2/full/white.png",
			played: false,
			showTooltip: false,
			tooltipText: "",
			playId: null,
			playCreated: false,
			chunkStart: gon.startTime || 0,
			chunksWatched: [],
			chunksHashed: {},
			pauses: [],
			seeks: [],
			hover: false,
			hoverTimer: null,
			markers: [],
			times: [],
			duration: 0,
			preCtaTime: 0,
      playerDuration: 0,
			videoItems: gon.sourceItems || [],
			hubspotTextFields: gon.hubspotTextFields || [],
			hubspotNumFields: gon.hubspotNumFields || [],
			hubspotDateFields: gon.hubspotDateFields || [],
			hubspotAllFields: gon.hubspotAllFields || [],
			hubspotConnected: gon.hubspotConnected,
			hubspotLists: gon.hubspotLists || [],
			constantContactConnected: gon.constantContactConnected || false,
			constantContactLists: gon.constantContactLists || [],
			shopifyStores: gon.shopifyStores || [],
			externalTokens: gon.externalTokens || [],
			post_id: null,
			videoDialog: false,
			recordVideoDialog: false,
			dialogVideo: null,
			dialogAudio: null,
			shareToken: gon.token,
			sharePrivacy: gon.sharePrivacy,
			outputAlign: gon.outputAlign || "bottom",
			isPresentation: gon.isPresentation,
			audioDialog: false,
			recordAudioDialog: false,
			isRecordingAudio: false,
			audioResult: false,
			audioDialog: false,
			audioDialogName: "Somebody",
			audioDialogTime: "0:00",
			resumeDialog: false,
			skipAuth: false,
			endButton: null,
			resourceTray: gon.shareDesign ? gon.shareDesign.resourceTray : null,
			ctaTray: gon.shareDesign ? gon.shareDesign.ctaTray : null,
			dupResourceTray: gon.shareDesign ? Object.assign({}, gon.shareDesign.resourceTray) : null,
			activeHotspot: true,
			guestName: gon.guestName,
			guestEmail: gon.guestEmail,
			guestPhone: gon.guestPhone,
			guestID: gon.guestID,
			viewerVariables: gon.viewerVariables,
			authReturn: null,
			authDialog: false,
			payReturn: null,
			payDialog: false,
			refreshDialog: false,
			guestAuth: false,
			currentUserAvatar: gon.avatar,
			guestAvatar: 'https://resource-cdn.mindstamp.com/assets/color_whitebg.jpeg',
			authStep: gon.captureName ? 0 : 1,
			surfer: null,
			isMuted: false,
			soundButton: !(window.location.search.indexOf('minimal=1') > -1) && (gon.autoplay || gon.startTime),
			transcript: false,
			listIndex: 0, // interactions
			annotations: gon.annotations,
			interactionsByTime: {
				notes: {},
				buttons: {},
				drawings: {},
				questions: {},
				videos: {},
				audios: {},
				replies: {},
				images: {},
				hotspots: {},
				grids: {},
				navigations: {}
			},
			interactionsByType: {
				notes: [],
				buttons: [],
				drawings: [],
				questions: [],
				videos: [],
				audios: [],
				replies: [],
				images: [],
				hotspots: [],
				grids: [],
				navigations: []
			},
			annotationReplies: gon.replies,
			filteredAnnotations: gon.annotations,
			filterKey: '',
			source: gon.videoSource,
      backupSource: gon.backupSource,
			message: '',
			currentTime: 0,
			timer: null,
			playerPaused: true,
			playerWaiting: false,
			post: gon.post,
			share: gon.share,
			editingItem: false || gon.editingItem,
			currentItem: null,
			videoNeedsTouchPlay: false,
			audioNeedsTouchPlay: false,
			autoplay: gon.autoplay,

			isSharing: window.location.href.includes("sharing=1"),
			isEditing: false,
			loadTpicker: 0,
			isChangingThumbnail: false,
			isPeople: false,
			shareEmail: '',
			// Embeds
			theAudioID: '',

			// Posts list
			posts: gon.posts,

			// Post annotations
			replyDialog: false,
			replyText: "",

			replyAnnotation: { "content": "" },
			replyAnnotationID: null,
			replyVariable: null,
			replyVariableValue: null,
			replyMessage: "",
			plainDate: "",
			replyDate: null,
			replyContentText: "",
			notesDialog: false,
			notesTab: 0,
			addNoteDialog: false,
			addingDialog: false,
			signUpDialog: false,
      extra: gon.raw_views || gon.raw_plays || "",
			newVideo: {
				title: '',
				message: '',
				transcode: false,
				transcribe: false,
        size: 0,
        duration: 0
			},
			popoverBusy: false,
			caption: '',

			playbackSpeed: 1,
			playbackSpeeds: [0.75, 1, 1.25, 1.5, 2],
			volume: 1,
			volumeOptions: ["Mute", 0.25, 0.50, 0.75, 1],

			//report
			reportData: gon.reportData,
			viewTabLoaded: false,
			interactionTabLoaded: false, 
			viewersTabLoaded: false, 
			variableTabLoaded: false, 
			videoTabLoaded: false,
      viewerTabLoaded: false,
			insightTabLoaded: !gon.isProduction,
			questionTabLoaded: !gon.isProduction,
			exportTabLoaded: false,
			filterType: 0,
			reportingTabMobile: "Views",
			videoTabLoaded: true,
			organizationTabLoaded: false,
			favoriteTabLoaded: false,
			type: 0, 
			mobileTab: window.location.href.includes('reporting') ? "Views" : "My Videos",
			// edit annotations
			editingDialog: false,
			editingAnnotation: { "content": "" },
			// editingQuestion: {},
			editingMessage: "",
			editingTime: 0,
			questionCreationDialog: false,
			editingQuestion: {},
			
			limitTypes: [
				{ label: '2 Seconds', value: '2' },
				{ label: '3 Seconds', value: '3' },
				{ label: '4 Seconds', value: '4' },
				{ label: '5 Seconds', value: '5' },
				{ label: '6 Seconds', value: '6' },
				{ label: '7 Seconds', value: '7' },
				{ label: '8 Seconds', value: '8' },
				{ label: '9 Seconds', value: '9' },
				{ label: '10 Seconds', value: '10' },
				{ label: '15 Seconds', value: '15' },
				{ label: '20 Seconds', value: '20' },
				{ label: '25 Seconds', value: '25' },
				{ label: '30 Seconds', value: '30' },
				{ label: '35 Seconds', value: '35' },
				{ label: '40 Seconds', value: '40' },
				{ label: '45 Seconds', value: '45' },
				{ label: '50 Seconds', value: '50' },
				{ label: '55 Seconds', value: '55' },
				{ label: '60 Seconds', value: '60' },
				{ label: '90 Seconds', value: '90' },
			],
			navTypes: [
				{ label: 'Pause Video', value: 'pause' },
				{ label: 'Redirect Link', value: 'redirect' },
				{ label: 'Change Time', value: 'jump' },
				{ label: 'Switch Video', value: 'switch' },
				{ label: 'Switch to Previous Video', value: 'back' },
				{ label: 'Set Variable', value: 'variable' },
				{ label: 'Open Magic Menu', value: 'resourceTray' },
				// { label: 'Show Card', value: 'show_card' },
				{ label: 'Reset Viewer State', value: 'reset_viewer' },
				{ label: 'Connector (Get Request)', value: 'get' },
				{ label: 'Connector (Post Request)', value: 'post' },
				{ label: 'Ask ChatGPT', value: 'chatGPT' },
			],
			actionTypes: [
				{ label: 'Continue', value: 'reply' },
				{ label: 'Show Message', value: 'message' },
				{ label: 'Change Time', value: 'jump' },
				{ label: 'Open Link', value: 'link' },
				{ label: 'Switch Video', value: 'video' },
				{ label: 'Switch to Previous Video', value: 'back' },
				{ label: 'Start Email', value: 'email' },
				{ label: 'Start Call', value: 'phone' },
				{ label: 'Play Video Clip', value: 'clip' },
				{ label: 'Play Audio Clip', value: 'audio' },
				{ label: 'Download File', value: 'download' },
			],
			actionTypesNoClick: [
				{ label: 'Continue', value: 'track' },
				{ label: 'Open Link', value: 'link' },
				{ label: 'Change Time', value: 'jump' },
				{ label: 'Switch Video', value: 'video' },
				{ label: 'Switch to Previous Video', value: 'back' },
				{ label: 'Show Message', value: 'message' },
        { label: 'Show Article', value: 'article' },
				{ label: 'Show Image', value: 'view' },
				{ label: 'Open Genie AI', value: 'genie' },
				{ label: 'Add To Cart', value: 'cart' },
				{ label: 'Ask ChatGPT', value: 'openai' },
				{ label: 'Play Video Clip', value: 'clip' },
				{ label: 'Play Audio Clip', value: 'audio' },
				{ label: 'Start Email', value: 'email' },
				{ label: 'Start Call', value: 'phone' },
				{ label: 'Open Link (Modal)', value: 'modal' },
				{ label: 'Collect Response', value: 'collect' },
				{ label: 'Download File', value: 'download' },
				{ label: 'Open Magic Menu', value: 'resourceTray' },
				// { label: 'Show Card', value: 'show_card' },
				{ label: 'Connector (Get Request)', value: 'get' },
				{ label: 'Connector (Post Request)', value: 'post' },
        { label: 'Copy Text', value: 'copy'},
				{ label: 'None', value: 'none' },
			],
			actionTypesNoClickWithoutNone: [
				{ label: 'Continue', value: 'track' },
				{ label: 'Open Link', value: 'link' },
				{ label: 'Change Time', value: 'jump' },
				{ label: 'Switch Video', value: 'video' },
				{ label: 'Switch to Previous Video', value: 'back' },
				{ label: 'Show Message', value: 'message' },
				{ label: 'Show Image', value: 'view' },
				{ label: 'Play Video Clip', value: 'clip' },
				{ label: 'Play Audio Clip', value: 'audio' },
				{ label: 'Start Email', value: 'email' },
				{ label: 'Start Call', value: 'phone' },
				{ label: 'Open Link (Modal)', value: 'modal' },
				{ label: 'Collect Response', value: 'collect' },
				{ label: 'Download File', value: 'download' },
				{ label: 'Open Magic Menu', value: 'resourceTray' },
				{ label: 'HTTP Get Request', value: 'get' },
				{ label: 'HTTP Post Request', value: 'post' },
        { label: 'Copy Text', value: 'copy'},
			],
			endActionTypes: [
				{ label: 'None', value: 'none' },
				{ label: 'Show Customized Screen', value: 'custom' },
				{ label: 'Switch Video (Automatic)', value: 'video_auto' },
				{ label: 'Switch Video (Button)', value: 'video' },
				{ label: 'Switch to Previous Video (Auto)', value: 'back_auto' },
				{ label: 'Switch to Previous Video (Button)', value: 'back' },
				{ label: 'Open Link (Button)', value: 'link' },
				{ label: 'Redirect to Link (Automatic)', value: 'redirect' },
				{ label: 'Change Video Time', value: 'jump' },
				{ label: 'Start Email', value: 'email' },
				{ label: 'Start Call', value: 'phone' },
				{ label: 'Show Viewer Results', value: 'results' },
				{ label: 'Show Message', value: 'message' },
				{ label: 'Download File', value: 'download' },
			],
      imageActionTypes: [
				{ label: 'None', value: 'none' },
				{ label: 'Open Link', value: 'link' },
				{ label: 'Change Time', value: 'jump' },
				{ label: 'Switch Video', value: 'video' },
				{ label: 'Download File', value: 'download' },
				{ label: 'Add To Cart', value: 'cart' },
      ],
			timerChoices: [
        { text: 'Until Viewer Click', value: -1 },
        { text: '2 seconds', value: 2 },
				{ text: '5 seconds', value: 5 },
				{ text: '10 seconds', value: 10 },
				{ text: '20 seconds', value: 20 },
				{ text: '30 seconds', value: 30 },
				{ text: '45 seconds', value: 45 },
				{ text: '60 seconds', value: 60 },
			],
			aiTones:[
				'Concise',
				'Curious',
				'Expert',
				'Formal',
				'Friendly',
				'Humorous',
				'Instructional',
				'Playful',
			],
			questionDialog: false,
			questionAnnotation: null,
			currentQuestionIndex: 0,
			questionSet: null,
			questionSetIndex: 0,
			questionMinimized: false,
			playQuestionMusic: gon.playQuestionMusic,
			questionChoices: [],
			questionTimer: null,
			questionTimerAmount: 0,
			artTimer: null,
			artTimerAmount: 50,
			artTimeroriginal: 0,
			artTimerLast: 0,
			answered: false,
			correctAnswer: [{value: ''}],
			minCorrect: null,
			maxCorrect: null,

			// User editing
			first: gon.first,
			last: gon.last,
			blurb: gon.blurb,
			changingAvatar: false,

			// User contacts
			contacts: gon.contacts,


			// Landing about
			signUp: false,
			emailRules: [
				(v) => !!v || 'E-mail is required',
				(v) => /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(v) || 'E-mail must be valid'
			],
			validEmail: false,

			contactFormDone: false,
			contactEmail: "",
			contactName: "",
			contactMessage: "",

			volumeTimer: null,
			volumeLevel: 1,
			activeTab: this.getUrlParam('activeTab') || '0',
			tab: 0,
			random: {
				understood: false,
			}

		}
	},
	watch: {
    'playbackSpeed': function(val) {
      this.speedPopup = false
    },
    'activeDiv.must_click': function(val) {
      if (val) {
        this.activeDiv.pause = false
      }
    },
    'activeDiv.second_action': function(newVal, oldVal) {
      this.sayDev("Action changed from " + oldVal + " to " + newVal)
      if (oldVal) {
        this.activeDiv.second_value = ''
        if (newVal == 'download') {
          this.assetSelectDialog = true
        }
      }
    },
    'activeDiv.extra' : function() {
      this.setDivDimensions()
    },
    share:{
			handler(newVal, oldVal) {
				this.sayDev("💦 Share Changed")
        this.sayDev(this.share)
      },
			immediate: false
		},
    'branchingDialog': function(val) {
      if (!val) {
        var lines = document.getElementsByClassName('leader-line')
        this.sayDev("Lines length: " + lines.length)
        if (lines && lines.length > 0) {
          var i = 0
          while (i < lines.length) {
            if (lines[i]) {
              lines[i].remove()
            }
          }
        }
      }
    },
		vidDropDownSearch:{
			handler(newVal, oldVal) {
				this.searchingVideoDrop = true
				if (this.vidDropTimer) {
					clearTimeout(this.vidDropTimer)
					this.vidDropTimer = null
				}
				this.vidDropTimer = setTimeout(()=> {
					this.searchVideoDrop()
				}, 1000)
      },
			immediate: false
		},
		collectionDropDownSearch: {
			handler(newVal, oldVal) {
				this.searchingCollectionDrop = true
				if (this.collectionDropTimer) {
					clearTimeout(this.collectionDropTimer)
					this.collectionDropTimer = null
				}
				this.collectionDropTimer = setTimeout(()=> {
					this.searchCollectionDrop()
				}, 1000)
      },
			immediate: false
		},
    videoObject: {
      handler(newVal, oldVal){  // here having access to the new and old value
        if (this.blockAutoSave) {
          return false
        }
        ("VideoObject has changed")
        this.sayDev(newVal)
        this.sayDev(oldVal)
        if (this.ownsPost && this.currentShare) {
          this.doAutoSave()
        }
      },
      deep: true,
      immediate: false    
   },
    'checkedItems' : function(val) {
      this.doCataStyles()
    },
    'gptDialog': function(val) {
      if (val) {
        this.flashFocusField('gptInput', false)
      } else {
        this.gptAnswer = ''
        this.busy = false
      }
    },
    'bulkOption' : function(val) {
			if (val == 'fontSize'){
				this.bulkValue = 8
			} else if (['transition', 'animation', 'post_click_state', 'border_radius'].includes(val)){
				this.bulkValue = null
			} else if (val.includes("color")){
				this.bulkValue = "white"
			} else if (val == 'must_click'){
				this.bulkValue = false
			} else if (['copyTo', 'moveTo'].includes(val) && !this.assets){
				this.refreshAssets() 
			} else if(['copyTo', 'moveTo', 'video', 'place'].includes(val)){
				this.bulkValue = ''
			} else {
				this.bulkValue = 1
			}
    },
		'share' : function(val){
			this.setCurrentShare()
		},
		'switchingVideo' : function(val) {
			this.sayDev("⭐️ SwitchingVideo: " + val)
			if (!this.switchingVideo) {
				setTimeout(()=> {
					this.hideLoader = false
				}, 2000)
			} else {
				this.hideLoader = true
			}
		},
		'currentShare.capture_auth_point': function(val) {
			this.sayDev("Auth point now: " + val)
			if (val == null){
				this.currentShare.capture_auth_point = 0
			}
			this.currentShare.capture_auth_value = null
		},
		'currentShare.capture_payment_point': function(val) {
			this.sayDev("Payment point now: " + val)
			this.currentShare.capture_payment_value = null
		},
		'volumeLevel': function () {
			if (this.player) {
				// this.sayDev("🔊 New VL: " + this.volumeLevel)
				this.player.volume(this.volumeLevel)
				if (this.volumeLevel > 0 && this.isMuted) {
					this.toggleMute()
				} else if (this.volumeLevel == 0 && !this.isMuted) {
					this.toggleMute()
				}
			}
		},
		'annotations': function () {
      // this.sayDev("👾 Annotations Changed ")
			this.setVisibleAnnotations("AnnsWatcher")
			this.filteredAnnotations = this.visibleAnnotations
		},
		'replyDate': function (val) {
			if (val) {
				this.plainDate = val
				this.replyMessage = this.getFormattedDate(val)
			}
		},
		'color': function (val) {
			// this.$vuetify.theme.primary = val
			this.setColor(val)
		},
		'resumeDialog': function (val) {
			this.sayDev("🏄‍♂️ Resume: "  + val, this.questionSet)
			if (val && this.questionSet && this.questionSet.length > 1) {
				this.resumeDialog = false
			}
		},
		'captionsDialog': function () {
			this.uploadCaption = {
				label: null,
				url: null
			}
		},
		'activeTextTrack': function () {
			this.updateCaptions()
		},
		'divDialog': function (val) {
      if (!val) {
        this.stopPlacingInteraction()
      }
      this.featureMoreInfo = false
			this.previewPoints = false
      this.resetTimer()
			this.clearLines()
			if (!this.divDialog) {
				this.setActiveDiv()
				interact('drag-resize').off()
        this.closePickers()
        this.editingAnnotation = {}
			}
		},
		'activeDivFontSize': function () {
			this.setDivDimensions()
		},
		'activeDiv.action': function (newVal, oldVal) {
			if(this.selectedCard || this.suppressCardWatcher){
        this.sayDev("Returning cuz selected card....")
				return
			}
      if (oldVal && this.activeDiv.action == 'none' && this.activeDiv.pause && this.activeDiv.timer == -1) {
        alert("Heads up: You using the 'None' click action on a Pause Until Click interaction. This interaction will not be clickable. If you don't have other clickable interactions on screen, the viewer will get stuck and unable to proceed! Use 'Track and Continue' if you don't have other interactions.")
      }
			if (oldVal) {
				this.activeDiv.value = ''
				if (this.activeDiv.action == 'view' && this.activeDiv.type_of != 'image') {
					this.imageSelectDialog = true
				}
				if (this.activeDiv.action == 'show_card') {
					this.cardSelectDialog = true
				}
				if (this.activeDiv.action == 'download' && this.proUpUser) {
					this.assetSelectDialog = true
				}
				if (this.activeDiv.action == 'cart' && this.currentUser.shopify_connected) {
					this.getProducts()
				}
				if (['get','post'].includes(this.activeDiv.action)){
					if (!this.scriptsLoaded.html) {
						this.loadHtmlScripts() 
					}
				}
			}
		},
		'activeCard.click_action': function (newVal, oldVal) {
			if(this.selectedCard || this.suppressCardWatcher || this.showCardDialog){
        this.sayDev("Returning cuz selected card....")
				return
			}
			if (oldVal != newVal) {
				if (!this.activeCard){
					return
				}
				this.activeCard.click_value = ''
				if (this.activeCard.click_action == 'view') {
					this.imageSelectDialog = true
				}
				if (this.activeCard.click_action == 'download' && this.proUpUser) {
					this.assetSelectDialog = true
				}
				if (['get','post'].includes(this.activeCard.click_action)){
					if (!this.scriptsLoaded.html) {
						this.loadHtmlScripts() 
					}
				}
			}
		},
		'activeNav.action': function(newVal, oldVal){
			if (['get','post'].includes(this.activeNav.action)){
				if (!this.scriptsLoaded.html) {
					this.loadHtmlScripts() 
				}
			}
			if (this.activeNav.action == 'show_card' && !this.startNavEdit) {
				this.cardSelectDialog = true
			} else if(this.activeNav.action != 'show_card'){
				this.hideCardDialog()
			}
		},
		'imageDialog': function (val) {
			if (!val) {
				this.theImage = {
					content: '',
					action: 'view',
					extra: ''
				}
				this.clearReplyAnnotation()
        this.getMoreStockAssets = false
			}
		},
		'endButton.action': function (val) {
			if (val == 'jump') {
				this.endButton.value = '0'
			} else if (val == 'custom' && this.played){
        this.sayDev("❌ Showing ENDCTA from endButton.action")
				this.showCTA = true
			} else {
				this.endButton.value = ''
			}
		},
		'questionCreationDialog': function (newVal, oldVal) {
			if (newVal) {
				setTimeout(() => {
					document.getElementById('promptBox').focus()
				}, 250);
        this.resetTimer()
			} else {
				this.resetEditingQuestion()
				this.editingAnnotation = {}
				if (this.currentTime == 0) {
					this.introDialog = true
				}
				this.questionDialog = false

			}
		},
		'theButton.action': function () {
			this.theButton.extra = this.theButton.action == 'email' ? this.currentUser.email : this.theButton.extra
		},
		'videoSearch': function () {
			if (!this.videoSearch) {
				this.filteredVideos = this.videos
				return
			}
			this.filteredVideos = this.videos.filter(obj => {
				return obj.title && ((obj.title || '') + (obj.group_name || '')).toLowerCase().includes(this.videoSearch.toLowerCase())
			})
		},
		'drawingDialog': function () {
			if (!this.drawingDialog) {
				this.destroyCanvas()
			}
		},
		'viewImageDialog': function (val) {
			this.sayDev("VID: " + val)
      if (val) {
        setTimeout(()=> {
          var img = document.querySelector('#theImage')
          if (img.complete) {
            this.sayDev("Complete, show!")
            this.setImageCloseCoords()
            this.viewImageExit = true
          } else {
            img.addEventListener('load', ()=> {
              this.sayDev("Loaded, show!")
              this.setImageCloseCoords()
              this.viewImageExit = true
            })
            img.addEventListener('error', function() {
                alert('error')
            })
          }
        }, 20)
      } else {
        this.viewImageExit = false
				// this.imageAnnotation = null
				this.lastPauseTime = -1
				if (this.isProduction) {
					setTimeout(() => {
						this.hideAllDialogsAndPlay("VID")
					}, 10);
				}
			}
		},
		'currentShare.showBranding': function () {
			if (this.currentShare) {
				this.showBrandingImage = this.currentShare.showBranding
			}
		},
		'authDialog': function () {
			this.resetTimer()
			setTimeout(() => {
				$('#authName').focus()
			}, 250);
		},
		'fillColor': function () {
			if (this.activeObject) {
				this.activeObject.stroke = this.fillColor.hex
				if (this.activeObject.fill) {
					this.activeObject.fill = this.fillColor.hex
				}
				this.activeObject.dirty = true
				this.canvas.renderAll()
			}
		},
		'isDrawing': function () {
			if (this.canvas) {
				this.canvas.isDrawingMode = this.isDrawing
			}
		},
		'isFullscreen': function () {
			setTimeout(() => {
				this.drawSeekBounding("isFullscreen")
			}, 200);
		},
		'currentShare.align': function () {
			this.outputAlign = this.currentShare ? this.currentShare.align : this.share.align
		},
		'dialogShowing': function (val) {
			if (val) {
				this.timeDrag = false
				this.hideSeekbarImmediately()
				this.showCursor()
			} 
			if (val && this.ownsPost) {
				// this.sayDev("Load assets first time from DS")
				if (!this.assets || (this.assets && !this.assets.images)){
					this.refreshAssets(false)
				}
			}
			if (val && this.ownsPost && !this.timeItems.length) {
				this.duration = this.share.duration
			}
			this.hideSeekbarImmediately()
			if (val || this.drawingAnnotations || this.introDialog) {
				if (this.currentTime == 0 || !this.played) {
					return
				}
				if (this.notesDialog) {
					this.toggleListDialog()
				}
				this.pausePlayer("DIALOGSHOWING");
				setTimeout(() => {

					var layer = document.getElementById('layer')
					if (layer) {
						// layer.style.zIndex = 1
						$('#layer').fadeIn(650)
					}

				}, 50);
			} else {
			}
		},
		'played': function () {
			if (this.isRobot) {
				this.sayDev("🚩 Robot", window.navigator.userAgent)
			}
      this.playerDuration = this.player.duration().toFixed(1)
      this.duration = Math.floor(this.player.duration())
      this.resetMarkers();

      this.progressInterval = Math.round(Math.max(5,  Math.min(this.playerDuration / 10, 30)))
      this.sayDev("❤️‍🔥 Started! Interval: " + this.progressInterval)

			this.resumeDialog = false
			var x = document.getElementsByClassName('vjs-loading-spinner')
			if (this.isInline) {
				this.sayDev("inline, hiding spinner")
				if (x[0]) {
					x[0].style.display = 'none'
				}
				if (x[1]) {
					x[1].style.display = 'none'
				}
			}
			if (this.shouldAuth && !this.skipAuth && (this.share.capture_auth_point == 0 || this.captureAuthStart)) {
				this.sayDev("Should capture Auth beginning")
				this.introDialog = false
				this.authDialog = true
				this.pausePlayer("Played1")
				return
			}
			if (this.shouldPay && (this.share.capture_payment_point == 0)) {
				this.sayDev("Should capture Payment beginning")
				this.introDialog = false
				this.showPayDialog()
				this.pausePlayer("Played2")
				return
			}
			if (this.currentUser) {
				this.showSeekbar = true
			}
			this.resetMarkers()
			// setTimeout(() => {
			// 	this.drawSeekBounding("played");
			// }, 500)
			var b = document.getElementsByClassName('vjs-big-play-button')
			if (b[0]) {
				b[0].style.display = 'none'
			}
			setTimeout(() => {
				if (this.isVimeo && this.isPlayerPaused() && !this.dialogShowing) {
					this.playPlayer("played");
				}
				if (!this.player) {
					return
				}
				let x = this.player.duration()
				if (isNaN(x)) {
					return
				}
				this.duration = Math.floor(this.player.duration())
				if ((this.duration > 0 && Math.ceil(this.duration) != this.share.duration) || !this.share.height || !this.share.width) {
					// this.sayDev("Update Duration!")
					var data = new FormData()
          if (this.duration) {
            data.append("duration", Math.ceil(this.duration))
          }
          if (this.videoTrueWidth) {
            data.append("width", this.videoTrueWidth)
          }
          if (this.videoTrueHeight) {
            data.append("height", this.videoTrueHeight)
          }
					Rails.ajax({
						url: '/duration/update/' + this.share.id,
						type: "POST",
						dataType: "JSON",
						data: data,
						success: (mData) => {
							this.resetMarkers()
						}, error: (e) => {
								console.log("Error duration")
						}
					})
				} else {
				}
        this.preloadSwitches()
				// this.tryTrack("Started Video", { title: this.share.title, token: this.share.token, url: window.location.href })
			}, 5000);
			setTimeout(() => {
				this.interactionsByType.images.forEach((a) => {
					this.preloadAsset(a.content)
				})
        if (this.share.use_afk && !this.ownsPost) {
          this.startAfkTimer()
          var vid = document.getElementById('video-1_html5_api')
          if (vid) {
            vid.onmousemove = null
            vid.onmousemove = ()=> {
              this.startAfkTimer()
            }
          }
        }
			}, 1000)
			this.updateCaptions()
			this.trackGtagEvent('Started Video', this.share.title, 'Video Player Events');
      if (this.ownsPost) {
        this.orderedAnnotations.forEach((a)=> {
          // this.sayDev(this.formattedSeconds(a.time) + " -- " + a.type_of + ' -- ' + a.content)
        })
      }
			
		},
		'duration': function () {
      this.sayDev("Duration is now " + this.duration)
      this.resetMarkers()
			setTimeout(() => { // wait for fetch interactions in order to get chapters
				this.getTimeItems();
			},200)
		},
		'notesDialog': function () {
			setTimeout(() => {
				this.drawSeekBounding("notesDialog")
			}, 250);
			if (this.notesDialog) {
				this.pausePlayer("Notes")
				setTimeout(() => {
					// document.getElementById('notesDialogWrapper').style.zIndex = 10
					// $('#notesDialogWrapper').show()
					// $('#notesDialogWrapper').fadeIn(1)
					$('#notesDialogWrapper').animate({
						width: this.isMobile ? Math.min(this.canvasWidth, this.videoWidth) + 5 + 'px' : Math.max(500, this.videoWidth / 3) + 'px',
						padding: this.isMobile && this.isFullscreen ? '17vh 2vh' : '2% 3%',
					}, 500);

					if (!this.isMobile && document.getElementById('searchStringInput')) {
						setTimeout(() => {
							document.getElementById('searchStringInput').focus()
						}, 1000);
					}
				}, 10);
			} else {
				// $('#notesDialogWrapper').fadeTo(300, 0.0)
				// document.getElementById('notesDialogWrapper').style.opacity = 0
				// document.getElementById('notesDialogWrapper').style.zIndex = -10
				// $('#notesDialogWrapper').hide()
				// $('#notesDialogWrapper').fadeOut(500)
				$('#notesDialogWrapper').animate({
					width: "0px",
					padding: '0%',

				}, 250);
				// if (this.isMobile) {
				//   this.playPlayer()
				// }
				this.searchString = ''
				this.playPlayer("ND")
			}
		},
		'filterKey': function () {
			if (this.filterKey == "") {
				this.filteredAnnotations = this.visibleAnnotations
			} else {
				this.filteredAnnotations = this.visibleAnnotations.filter(obj => {
					return obj.saidName === this.filterKey
				})
			}
			this.resetMarkers()
		},
		'searchString': function () {
			if (this.searchTimer) {
				return
			}
			this.subtitles = false
			if (!this.searchString || this.searchString == "") {
				this.filteredAnnotations = this.visibleAnnotations
				this.filteredChapters = this.chapters
				this.resetMarkers()
				if (this.hasSubtitles){
					this.fetchSubtitles()
				}
			} else {
				this.searchTimer = setTimeout(() => {
					this.filteredAnnotations = this.visibleAnnotations.filter(obj => {
						return obj.search && obj.search.includes(this.searchString.toLowerCase()) || this.replyString(obj).includes(this.searchString.toLowerCase())
					})
					this.filteredChapters = this.chapters.filter(obj => {
						return obj.title && obj.title.toLowerCase().includes(this.searchString.toLowerCase()) || obj.description && obj.description.toLowerCase().includes(this.searchString.toLowerCase())
					})
					if (this.hasSubtitles){
						this.fetchSubtitles()
					}
					this.searchTimer = null
					this.resetMarkers()
				}, 1500);
			}
		},
		'signUpDialog': function () {
			if (this.signUpDialog) {
				this.pausePlayer();
			} else {
				this.playPlayer("SUD");
			}
		},
		'addingDialog': function () {
			if (this.addingDialog) {
				this.pausePlayer();
			} else {
				this.playPlayer("AD");
			}
		},
		'playbackSpeed': function () {
			if (this.player.playbackRate) {
				this.player.playbackRate(this.playbackSpeed)
			}
		},
		'editingDialog': function () {
			if (this.editingDialog) {
        this.activeEditPanel = 0
				this.playPlayer("ED");
				setTimeout(() => {
					this.pausePlayer();
				}, 250);
				if (this.notesDialog) {
					this.toggleListDialog()
				}
			} else {
        this.activeEditPanel = 0
				this.clearScreenInteractions()
				// this.playPlayer("ED2");
				this.hideAllDialogsDoSeekbar()
			}
		},
		'signUp': function () {
			if (this.signUp) {
				$('#drift-widget-container').hide();
				this.pausePlayer();
				var page = location.pathname + window.location.hash
				this.$ga.page(page)

			} else {
				$('#drift-widget-container').show();
			}
		},
		'isMuted': function (val) {
			if (this.isMuted) {
				this.player.muted(true)
			} else {
				this.player.muted(false)
			}
		},
		'filteredAnnotations': function (val, oldVal) {
			this.setInteractionsByTime();
			if (this.played) {
				this.resetMarkers()
			}
		},

		'first': function () {
			$('#user_first').val(this.first)
		},

		'last': function () {
			$('#user_last').val(this.last)

		},
		'showBrandingImage': function () {
			$('#setting_show_branding').val(this.showBrandingImage)
		},
		'sendNotifications': function () {
			$('#setting_send_notifications').val(this.sendNotifications)
		},
		'sendWebhooks': function () {
			$('#setting_webhooks').val(this.sendWebhooks)
		},
		'leadWebhookUrl': function () {
			$('#setting_lead_webhook_url').val(this.leadWebhookUrl)
		},
		'notificationFrequency': function () {
			$('#setting_notification_frequency').val(this.notificationFrequency)
		},
		'shareDialog': function () {
			if (this.shareDialog) {
				this.pausePlayer("SD");
			}
		},
		'questionDialog': function () {
			// this.sayDev("👽 QD: " + this.questionDialog)
			if (this.questionDialog) {
				this.pausePlayer();
				this.setReplyAnnotation(this.questionAnnotation)
				this.correctAnswer = ''
				this.chosenAnswer = ''
				if (!this.isMobile) {
					setTimeout(() => {
						$('#reply_message_input').focus()
					}, 250);
				}
				if (this.playQuestionMusic) {
					document.getElementById("pond5").volume = 0.2
					document.getElementById("pond5").play();
				}
			} else {
				this.clearQuestionSetup()
				if (!this.drawingAnnotations) {
					// this.playPlayer("QD");
				}
				if (this.playQuestionMusic) {
					document.getElementById("pond5").pause();
				}
			}
			if (this.dialogVideo) {
				if (!this.isIOS) {
					this.dialogVideo.reset()
				}
				this.dialogVideo.src()
			}
		},

		'replyDialog': function () {
			if (this.replyDialog) {

				this.pausePlayer();
				if (this.notesDialog) {
					this.toggleListDialog()
				}
				setTimeout(function () {
					$('#replyTextField').focus();
				}, 250);
			} else {
				this.playPlayer("RD");
			}
		},

		'video_type': function (val) {
			if (val == "upload") {
				this.step_one = "Upload Video"
			} else if (val == "youtube") {
				this.step_one = "Paste Link"
			} else {
				this.step_one = "Choose Video"
			}
		},

		'vimeoKey': function (val) {
			var vimeoId = this.vimeoVidId(val)
			if (vimeoId) {
				if (!val.includes("player.vimeo.com/external/")) {
					this.vimeoKey = this.vimeoVidId(val)
				}
			}
		},
		'wistiaKey': function (val) {
			var wistiaId = this.wistiaVidId(val)
			if (wistiaId) {
				this.wistiaKey = wistiaId
				this.wistiaEmbedUrl = "https://fast.wistia.com/embed/iframe/" + wistiaId
				this.video_name = "Wistia video " + wistiaId
				this.video_key = wistiaId
				this.uploadProgress = 100
				// this.queueComplete()
			}
		},
		'youtubeKey': function (val) {
			var ytId = this.ytVidId(val)
			var isLiveVideo = false
			if (ytId) {
				this.video_key = ytId
				var that = this
				$.ajax({
					url: 'https://www.googleapis.com/youtube/v3/videos?id=' + ytId + '&key=AIzaSyDC3uEQ11n83ssk3I2_7YX9OQZ0MzB1Vbs&part=snippet,contentDetails,statistics',
					success: function (data) {
						if (data && data.items) {
							isLiveVideo = data.items[0]["snippet"]["liveBroadcastContent"] == "live"
						}
						if (isLiveVideo) {
							alert("No live videos 😕");
							$("#youtube_input").val("");
							isLiveVideo = false;
							return;
						} else {
							that.video_name = data.items[0]["snippet"]["title"].replace(/^(.{40}[^\s]*).*/, "$1");
							that.ytEmbedUrl = "https://youtube.com/embed/" + ytId
							// that.queueComplete();
						}
					}
				})
			}
		},
		'message': function (val, oldVal) {
			var trimmedValue = this.sanitizedMessage;
			if (trimmedValue.length > 0) {
				this.pausePlayer();
			} else {
				// this.playPlayer("MSG");
				this.message = ''
			}
		},

		'currentTime': function (val) {
      if (this.duration >= 120) {  this.newSetSeekbar()  }
      this.forceAuthInteraction = false
      if (!this.ownsPost && !this.timeDrag) {
        this.seconds.push(val)
        if (val % this.progressInterval == 0) {
          this.saveProgress(val)
        }
      }
      if (this.dialogShowing) {
        this.pausePlayer("CT DS");
      } else if (this.ownsPost && this.activeSideItem != 'interactions') {
        this.pausePlayer("CT I");
      }
      this.previewPoints = false
      this.blockToggle = false
			this.emitTimeMessage()
      if (this.share.force_question_completion && !this.ownsPost && this.mustAnswerQuestions.length) {
        var found = false
        this.mustAnswerQuestions.forEach((q)=> {
          if (!found) {
            if (q.time < val && q.time > val - 30) {
              if (!this.userInteractionParents.includes(this.mustAnswerQuestions[0].id)) {
                found = true
                this.sayDev("🔴 Must go back for question at " + this.mustAnswerQuestions[0].time)
                console.log(this.mustAnswerQuestions[0].id, this.userInteractionParents)
                this.showSpecificQuestion(this.mustAnswerQuestions[0])
              }
            }
          }
        })
      }
			this.doGtagPercentage()
      var fetchCheck = Math.max(this.duration / 5, 10)
      if (this.interactionsError || (val % fetchCheck == 0 && this.visibleAnnotations.length == 0)) {
        this.sayDev("No interactions, so re-fetch them...")
        this.fetchInteractions()
      }
			if (val == this.duration && this.variables.loop == '1') {
				this.sendChunk(this.currentTime.valueOf());
				this.saveChunks(false);
				if (!this.showCTA){
					setTimeout(() => {
						this.changeTimeAndPlay(0);
					}, 400)
				}
				return
			}
			if (val > 0 && this.lastPauseTime > 0 && val != this.lastPauseTime && val != this.currentTime) {
				this.sayDev("Reset LPT: " + val + " , " + this.lastPauseTime + " , " + this.currentTime)
				var str = val + " ..... " + this.lastPauseTime + " ...... " + (val == this.lastPauseTime)
				setTimeout(() => {
					this.lastPauseTime = -1
				}, 600)
			}
			if (Math.abs(val - this.artTimerLast) > 1) {
				this.artTimerLast = 0
			}
			this.latestPoint = Math.max(val, this.latestPoint)
			if (this.share.capture_auth_point == 2 && this.share.capture_auth_value == val.toString() && this.shouldAuth) {
				this.pausePlayer("CTAuth")
				this.authDialog = true
			} 
			if (!this.authDialog && this.share.capture_payment_point == 2 && this.shouldPay) {
				this.pausePlayer("CTPAY")
				this.payDialog = true
			}
			// this.showStamps();
			if (val % 5 == 0) {
				this.prepareMedias()
			}

			if (!this.duration && !isNaN(this.player.duration())) {
				this.duration = Math.floor(this.player.duration())
			}
			if (this.markers.length == 0) {
				this.resetMarkers()
			}
			var distance = this.chunkStart + Math.max(this.duration / 20, 20)
			var within = this.currentTime - distance < 30
			if (this.currentTime > distance && within) {
				this.sayDev("🤍 CT SendChunk: " + this.currentTime.valueOf())
				this.sendChunk(this.currentTime.valueOf())
				this.saveChunks()
			} else if (!within) {
				this.chunkStart = this.currentTime
			}
      if (this.lastQuestionID && !this.questionDialog) {
        setTimeout(()=> {
          // this.sayDev("🎁 LQID now null")
          this.lastQuestionID = null
        }, 800)
      }
		},

		'recordVideoDialog': function () {
			if (this.recordVideoDialog) {
				this.pausePlayer("RVD");
				setTimeout(() => {
					if (this.$refs.recordVideoDialog) {
						return
					} else {
						setTimeout(() => {
							return
						}, 500);
					}
				}, 500);
			}
		},

		'recordAudioDialog': function () {
			this.audioResult = null
			this.isRecordingAudio = false
			this.wantsChooseAudio = false
			this.theAudioID = ''
		},
	},
  // Not mixins
	computed: {
    useNoBg() {
      return (this.introDialog && !this.showIntroBackground) || this.videoDialog || this.switchingVideo || this.navDialog || this.showMessageDialog || this.showPollDialog || this.chaptersDialog ||  this.divDialog || this.recordAudioDialog || this.recordVideoDialog || this.imageDialog || this.drawingDialog || this.questionDialog || this.audioDialog || this.questionCreationDialog
    },
    useMediumBg() {
      return this.afkDialog || this.continueDialog || this.resumeDialog || this.showCTA
    },
    useLightBg() {
      return (this.introDialog && this.showIntroBackground) || this.audioDialog
    },
    useCoverMode() {
      return this.shareDesign && this.shareDesign.cover_mode && !this.ownsPost
    },
    chapterAdjustedTimes() {
      var t = this.currentTime
      var e = this.duration
      var d = this.duration
      if (this.currentChapter) {
        t = this.currentTime - this.currentChapter.start
        e = this.currentChapter.finish
        d = this.currentChapter.duration
      } else if (this.chapters.length) {
        t = this.currentTime
        e = this.chapters[0].start
        d = this.chapters[0].start
      }
      return { time: t, finish: e, duration: d }
    },
    currentChapter() {
      var found = false
      if (this.chapters.length) {
        this.chapters.forEach((c,i)=> {
          if (!found && this.currentTime >= c.start) {
            if (!this.chapters[i+1] || this.chapters[i+1].start > this.currentTime) {
              this.sayDev("Found Chapter starting at " + c.start)
              found = c
            }
          }
        })
      }
      return found
    },
    videoColFull() {
      return this.isIpad && !this.canParticipate || !this.ownsPost
    },
    maxUploadCount() {
      return this.freeUser ? 1 : Math.min(gon.remainingSpace, 10)
    },
    activeDivIsTray() {
      return this.activeDiv && (this.activeDiv.tray_type || this.activeDiv.ctaTray || this.activeDiv.resourceTray)
    },
    qStylesNoBg() {
      var atts = Object.assign({}, this.safeStyles.question)
      atts.background = 'none'
      return atts

    },
    menuVisible() {
      return this.showMenu || this.activeSideItem.includes("menu")
    },
    ctaVisible() {
      return this.showCTA || this.activeSideItem.includes("screen")
    },
    orderedSentences() {
      if (this.transcript && this.transcript.sentences) {
        var entries = $.map(this.transcript.sentences, function(v) { return v; });
        return entries.sort(function (a, b) { return a.start - b.start })
      }
      return []
    },
    answerWrapperStyle() {
      var x = {
        width: '800px',
        maxWidth: '95%',
        margin: '0 auto'
      }
      if (this.questionAnnotation && this.questionChoices) {
        this.questionChoices.forEach((a)=> {
          if (a.text.length > 100) {
            x.width = '1200px'
          }
        })
      }

      return x
    },
    bp() {
      // Current breakpoint being used by Vuetify
      return {
        xs: this.screenWidth < 600,
        sm: this.screenWidth >= 600 && this.screenWidth < 960,
        md: this.screenWidth >= 960 && this.screenWidth < 1264,
        lg: this.screenWidth >= 1264 && this.screenWidth < 1904,
        xl: this.screenWidth >= 1904,
      }
    },
    maxAnswerLength() {
      var max = 0
      this.questionChoices.forEach((q)=> {
        max = Math.max(max, q.text.length)
      })
      // this.sayDev("Max Answer length: " + max)
      return max
    },
    hfields() {
      return {
        num: this.hubspotNumFields,
        date: this.hubspotDateFields,
        text: this.hubspotTextFields,
        all: this.hubspotAllFields,
      }
    },
    referVideos() {
      if (!this.referVideoStack || !this.videoItems) {
        return []
      }
      var x = []
      var used = []
      this.referVideoStack.forEach((r)=> {
        if (!used.includes(r)) {
          used.push(r)
          this.videoItems.forEach((v)=> {
            var token = v.value.split("---")[1]
            if (r == token) {
              v.token = token
              x.push(v)
            }
          })
        }
      })
      return x
    },
    userInteractionParents() {
      var x = []
      this.userInteractions.forEach((a)=> {
        x.push(a.parent_id)
      })
      return x
    },
    getWrapperStyle() {
			return {
        display: this.ownPost ? 'inline-block' : 'block',
        float:  this.ownsPost ? 'right' : 'none',
        height: !this.isMobile ? '100vh' : 'auto',
        width: this.isMobile || !this.ownsPost ? '100%' : 'calc(100% - 40px)'
      }
    },
    videoObject() {
      // this.sayDev("VideoObject", this.currentShare)
      if (!this.ownsPost || !this.currentShare) {
        // this.sayDev("Empty, returning...")
        return null
      }
      return {
        start_time: this.currentShare.start_time || 0,
        autoplay: this.currentShare.autoplay || false,
        fullscreen: this.currentShare.fullscreen || false,
        loop: this.currentShare.loop || false,
        block_pausing: this.currentShare.block_pausing,
        use_afk: this.currentShare.use_afk,
        afk_amount: this.currentShare.afk_amount,
        tags: this.currentShare.tags,
        title: this.currentShare.title,
        send_leads: true,
        leads_address: this.currentShare.leads_address,
        send_leads: false,
        is_resumable: this.currentShare.is_resumable,
        embed_only: this.currentShare.embed_only,
        pause_inactive: this.currentShare.pause_inactive,
        can_skip_clips: this.currentShare.can_skip_clips,
        resume_focus: this.currentShare.resume_focus,
        use_chapter_timing: this.currentShare.use_chapter_timing,
        tab_links: this.currentShare.tab_links,
        organization_access: this.currentShare.organization_access,
        organization_edit: this.currentShare.organization_edit,
        type_of: this.currentShare.private,
        access_list_id: this.currentShare.access_list_id,
        title: this.currentShare.title,
        message: this.currentShare.message,
        participate: this.currentShare.participate,
        observe: this.currentShare.observe,
        can_share: this.currentShare.share,
        show_branding: this.currentShare.showBranding,
        people: this.currentShare.people,
        align: this.currentShare.align,
        showTitle: this.currentShare.showTitle,
        showMessage: this.currentShare.showMessage,
        captureEmail: this.currentShare.captureEmail,
        capturePhone: this.currentShare.capturePhone,
        captureID: this.currentShare.captureID,
        captureName: this.currentShare.captureName,
        // captureCustomFields: this.currentShare.captureCustomFields,
        captureRequired: this.currentShare.captureRequired,
				requiredCaptureFields: this.currentShare.requiredCaptureFields,
        captureAuthStart: this.currentShare.captureAuthStart,
        cpatureAuthPoint: this.currentShare.capture_auth_point,
        navControls: this.currentShare.navControls,
        showMarkers: this.currentShare.showMarkers,
        group_id: this.currentShare.group_id || '',
        password: this.currentShare.password,
        published: this.currentShare.published || false,
        seekbar: this.currentShare.seekbar,
        interactions_guide: this.currentShare.interactions_guide,
        chapters_guide: this.currentShare.chapters_guide,
        transcript_guide: this.currentShare.transcript_guide,
        captureText: this.currentShare.captureText,
        gtag: this.currentShare.gtag,
        facebook_pixel_id: this.currentShare.facebook_pixel_id,
        send_webhook: this.currentShare.send_webhook,
        webhook_url: this.currentShare.webhook_url,
        send_interaction_webhook: this.currentShare.send_interaction_webhook,
        interaction_webhook_url: this.currentShare.interaction_webhook_url,
        send_lead_webhook: this.currentShare.send_lead_webhook,
        lead_webhook_url: this.currentShare.lead_webhook_url,
        send_hubspot_views: this.currentShare.send_hubspot_views,
        send_hubspot_questions: this.currentShare.send_hubspot_questions,
        send_hubspot_interactions: this.currentShare.send_hubspot_interactions,
        send_to_hs_list: this.currentShare.send_to_hs_list,
        send_to_cc_list: this.currentShare.send_to_cc_list,
        cc_lists: this.constantContactLists,
        hubspot_lists: this.hubspotLists,
        capture_auth_point: this.currentShare.capture_auth_point,
        capture_auth_value: this.currentShare.capture_auth_value,
        capture_payment_point: this.currentShare.capture_payment_point,
        capture_payment_value: this.currentShare.capture_payment_value,
        stripe_contact_email: this.currentShare.stripe_contact_email,
        capture_payment_amount: this.currentShare.capture_payment_amount,
        capture_payment_currency: this.currentShare.capture_payment_currency,
        stripe_expiration: this.currentShare.stripe_expiration,
        hubspot_interactions: this.currentShare.hubspot_interactions,
        force_session_reset: this.currentShare.force_session_reset,
        collect_geolocation: this.currentShare.collect_geolocation,
        tab_title: this.currentShare.tab_title,
        skip_amount: this.currentShare.skip_amount,
        shopify_site: this.currentShare.shopify_site,
        gpt_juice: this.currentShare.gpt_juice,
        default_captions: this.currentShare.default_captions,
        image: this.currentShare.image,
      }
    },
    latestUpdate() {
      if (this.currentShare && this.shareDesign) {
        var arg = this.currentShare.updated_at
        if (this.currentShare.updated_at <= this.shareDesign.updated_at) { 
          arg = this.shareDesign.updated_at
        }
        this.parentAnnotations.forEach((a)=> {
          if (a.updated_at > arg) {
            arg = a.updated_at
          }
        })
        return arg
      }
    },
    showSplitColumns() {
      return ['interactions', 'end screen', 'magic menu', 'transcript', 'chapters'].includes(this.activeSideItem)
    },
    promptColorStyle() {
      return {
        color: this.safeStyles.question.prompt_color + ' !important', 
        borderColor: this.safeStyles.question.prompt_color + " !important"
      }
    },  
    answersColorStyle() {
      return {
        color: this.safeStyles.question.answers_color + ' !important', 
        borderColor: this.safeStyles.question.answers_color + " !important"
      }
    },  
		showCardAction(){
			if (this.divDialog && this.activeDiv.action == 'show_card'){
				return true
			}
			if (this.navDialog && this.activeNav.action == 'show_card'){
				return true
			}
			return false
		},
    dataSetup() {
      var x = '{ "nativeAudioTracks": "false", "nativeVideoTracks": "false", "hls" : {"overrideNative" : "true"}, "playbackRates": [0.5, 1, 1.5, 2] }'
      if (this.isIOS) {
        if (this.isYoutube) {
          x = '{ "nativeAudioTracks": "false", "nativeVideoTracks": "false", "hls" : {"overrideNative" : "true"}, "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "'+ this.source.key+ '"?&controls=0&rel=0"}], "youtube": { "customVars": { "wmode": "transparent", "modestbranding": 1, "playsinline": 1 } }}'
        }
        x = '{ "nativeAudioTracks": "true", "nativeVideoTracks": "true", "hls" : {"overrideNative" : "false"}, "playbackRates": [0.5, 1, 1.5, 2] }'
      } 
      // this.sayDev("Data Setup: " + x)
      return x

    },
    mustAnswerQuestions() {
      var x = []
      this.orderedAnnotations.forEach((a)=> {
        if (a.type_of == 'question' && a.content.force_correct && !this.userInteractionParents.includes(a.id)) {
          this.sayDev("Required Q: " + a.exact_time)
          x.push(a)
        }
      })
      this.sayDev("MustAnswerQs Length: " + x.length + " of " + this.orderedAnnotations.length)
      return x
    },
    mustClickDivs() {
      // this.sayDev("GetMustClickDivs")
      var x = []
      this.visibleAnnotations.forEach((a)=> {
        if (!a.tray_type && !a.hidden && a.must_click && !a.clicked) {
          var found = false
          this.userInteractions.forEach((ui)=> {
            if (ui.parent_id == a.id) {
              this.sayDev("Found a parent click! Mark as clicked")
              a.clicked = true
              found = true
            }
          })
          if (!found) {
            this.sayDev("🍯 Must Click --> " +a.content)
            x.push(a)
          }
        } else if (a.must_click) {
          this.sayDev("👽 Already clicked --> " + a.content)
        }
      })
      // this.sayDev("MustClickDivs Length: " + x.length + " of " + this.visibleAnnotations.length)
      return x
    },
		getPostClickStates(){
			var states = [
				{ label: 'No Change', value: '' },
				{ label: 'Hidden', value: 'hidden' },
				{ label: 'Disabled', value: 'disabled' },
			]
			if (this.adi){
				return states
			}
			states.push({ label: 'Change Colors', value: 'color' })
			return states
		},
    videoCardStyle() {
      // TODO handle custom fonts 
      // class="<%= !@use_custom_font ? 'apply-font' : ''%>" style="<%= @use_custom_font ? ('font-family: ' + @font_family + ' !important') : ''%>"
      return {
        maxHeight: '100dvh',
        fontFamily: (this.freshFont || 'inherit') + ' !important'
      }
    },
		hasDiffTextColor(){
			var color = this.activeDivStyleHold.text_color
			if (!color){
				return false
			}
			var ss = this.safeStyles
			if (this.adb){
				return !(color.includes(ss.button.color) || ss.button.color.includes(color))
			}
			if (this.adt){
				return !(color.includes(ss.comment.color) || ss.comment.color.includes(color))
			}
		},
		hasDiffBgColor(){
			var color = this.activeDivStyleHold.background_color
			if (!color){
				return false
			}
			var ss = this.safeStyles
			if (this.adb){
				return !(color.includes(ss.button.color) || ss.button.background.includes(color))
			}
			if (this.adt){
				return !(color.includes(ss.comment.background) || ss.comment.background.includes(color))
			}
			if (this.adh){
				return !(color.includes(this.shareDesign.color) || this.shareDesign.color.includes(color))
			}
			if (this.adc){
				return !(color.includes(ss.card.background) || ss.card.background.includes(color))
			}
		},
		hasDiffBorderColor(){
			var color = this.activeDivStyleHold.border_color
			if (!color){
				return false
			}
			var ss = this.safeStyles
			if (this.adb){
				return !(color.includes(ss.button.border) || ss.button.border.includes(color))
			}
			if (this.adt){
				return !(color.includes(ss.comment.border) || ss.comment.border.includes(color))
			}
			if (this.adc){
				return !(color.includes(ss.card.border) || ss.card.border.includes(color))
			}
		},
		didUpdateCta(){
			var cta
			if (this.share.cta){
				cta = JSON.parse(this.share.cta)
			}
			if (this.endButton && cta){
				if (this.endButton.action !=  cta.action ||
					this.endButton.text !=  cta.text ||
					this.endButton.value !=  cta.value ||
					this.endButton.message !=  cta.message){
					return true
				}
			} else {
				return true
			}
			return false
		},
		hasCustomCta() {
			return this.endButton && this.endButton.action && this.endButton.action == 'custom'
		},
		showingAnyTrays(){
			return this.showMenu || this.showCTA
		},
    captionOptions() {
      if (!this.share) {
        return []
      }
      var x = ['Off']
      if (this.share.captions) {
        this.share.captions.forEach((c)=> {
          x.push(c.label)
        })
        }
      return x
    },
    hasFreeResponseQuestions() {
      var has = false
      this.annotations.forEach((a)=> {
        if (a.type_of == 'question' && a.content.style == 'free') {
          has = true
        }
      })
      return has
    },
    shiftOptions() {
      var arr = []
      var x = -10
      while (x < 10) {
        var count = " seconds"
        if (x != 0) {
           var str = x
           if (x>0) {
            str = "+"+str
           }
           if (x == 1 || x == -1) {
            count = " second"
           }
          arr.push({text: str + count, value: x})
        }
        x = Number(((x+ (this.starterUser ? 1 : 0.1)).toFixed(1)))
      }
      return arr
    },
    ctaString() {
      var x = ''
      if (this.share) {
        var cta = JSON.parse(this.share.cta)
        if (!cta) {
          return x
        }
        var a = cta.action
        var val = cta.value
        if (a == 'none') { 
					x += "Don't show CTA - pause on last frame"
					val = ''
				}
        else if (a == 'message') { 
          x += "Show the message" 
          val = cta.message
        }
        else if (a == 'video_auto') { 
          x += "Automatically <strong> switches video </strong> to  "
          val = val.split("---")[0]
          if (cta.switch_time) { 
            val += " @ time " + this.formattedSeconds(cta.switch_time)
          }
      }
        else if (a == 'video') { 
          x += "Show a button with text <strong>" + cta.text + "</strong> that <strong> switches video </strong> to "
          val = val.split("---")[0]
          if (cta.switch_time) { 
            val += " @ time " + this.formattedSeconds(cta.switch_time)
          }
      }
        else if (a == 'link') { x += "Show button with text <strong>" + cta.text + "</strong> that <strong> Opens a Link </strong> to "}
        else if (a == 'redirect') { x += "Automatically <strong> redirects viewer </strong> to link "}
        else if (a == 'jump') { 
          x += "Show button with text <strong>" + cta.text + "</strong> that <strong> Changes Time </strong> to "
          val = this.formattedSeconds(val)
        }
        else if (a == 'email') { x += "Show button with text <strong>" + cta.text + "</strong> that <strong> Starts an Email </strong> to "}
        else if (a == 'phone') { x += "Show button with text <strong>" + cta.text + "</strong> that <strong> Starts an Phone Call </strong> to "}
        else if (a == 'results') { x += "Show Viewer Results"}
        else if (a == 'download') { 
					x += "Show button with text <strong>" + cta.text + "</strong> that <strong> Starts an Download </strong> of "
					val = JSON.parse(cta.value).downloadName
				}
        else if (a == 'back_auto') { 
					x += "Show button with text <strong>" + cta.text + "</strong> that <strong> switches to the previous video"
					val = ''
				}
        else if (a == 'back') { 
          x += "Automatically <strong> switch to the previous video </strong>"
					val = ''
				}
				else if (a == 'custom') { 
					x += '<b>Show Custom Interactions</b>'
					val = ''
				} else if (a == 'question') { x += '<b>Ask a Question</b>'}
      }
      return { x: x, cta: '<span style="font-weight: bold">' + val + '</span>'}
    },
		getQuestionTypes() {
			if (this.starterUser) {
				return [
					{ label: 'Free Response', value: 'free' },
					{ label: 'Multiple Choice', value: 'multiple' },
					{ label: 'Choose All That Apply', value: 'all' },
					{ label: 'Date Response', value: 'date' },
					{ label: 'Rating (1-5)', value: 'rating' },
					{ label: 'Dropdown Select', value: 'select' },
					{ label: 'Likert Scale', value: 'likert' },
					{ label: 'Display Poll', value: 'poll'},
				]
			}
			return this.questionTypes
		},
		videoTypeOptions() { 
			var x = [
				{ key: 0, title: 'Upload Video', icon: 'https://resource-cdn.mindstamp.com/assets/images/icons/loop-blue.png'},
				{ key: 2, title: 'Vimeo', icon: 'https://resource-cdn.mindstamp.com/assets/images/vimeo.png'},
				{ key: 3, title: 'Wistia', icon: 'https://resource-cdn.mindstamp.com/assets/images/wistia.png'},
        { key: 1, title: 'File Link', icon: 'https://cdn.mindstamp.com/7471685.png'},
        { key: 5, title: 'Synthesia', icon: 'https://resource-cdn.mindstamp.com/assets/images/synthesia.png'},
        { key: 10, title: 'HeyGen', icon: 'https://cdn.mindstamp.com/heygen.png'},


			]
      var y = [ 

        { key: 7, title: 'Loom', icon: 'https://cdn.mindstamp.com/loom.png'},
        { key: 9, title: 'Panopto', icon: 'https://cdn.mindstamp.com/panop.png'},
        { key: 4, title: 'Kaltura', icon: 'https://inplayer.com/wp-content/uploads/2018/04/kaltura-icon.png'},
				{ key: 8, title: 'Stock Videos', icon: 'https://cdn.mindstamp.com/1e73e5cb95b89f1dce8b59c5236ca1fc28c7113b.png'},
				{ key: 11, title: 'JWPlayer', icon: 'https://cdn.theorg.com/91a26bb0-a507-4d8f-b5f8-538e89375ad1_medium.jpg'},
				{ key: 6, title: 'YouTube', icon: 'https://resource-cdn.mindstamp.com/assets/images/youtube.png'},
      ]
			if ( gon.blockUpload && !this.currentUser.organization.can_upload) { x.shift() }
			return { first: x, second: y}
		},
		sharePath() {
			if (!this.share || !this.currentUser) {
				return ""
			}
			/* Get the text field */
			var link = this.currentUserDomain + '/w/'
      if (this.currentUser && this.currentUser.organization.domain_is_active) {
        // don't use extra path if custom
			} else if (this.currentUser.organization.url_path) {
				link = this.currentUserDomain + '/go/' + this.currentUser.organization.url_path + "/"
			}
			return link
		},
		finalShareLink() {
			if (!this.currentUser) {
				return
			}
			var link = this.sharePath
			if (!this.share.url_title) {
				link += this.share.token
			} else {
				link += this.share.url_title.toLowerCase().replace(" ", "-")
			}
			var autoplay = ""
			if (this.currentShare.autoplay) {
				autoplay = "?autoplay=1"
				if (this.share.minimal) {
					autoplay += "&controls=0"
				}
			} else {
				if (this.share.minimal) {
					autoplay = "?controls=0"
				}
			}
			if (this.currentShare.fullscreen) {
				autoplay += autoplay.includes("?") ? "&fullscreen=1" : "?fullscreen=1"
			}
      if (this.currentShare.loop) {
				autoplay += autoplay.includes("?") ? "&loop=1" : "?loop=1"
			}
      if (this.currentShare.doStartTime && this.currentShare.start_time) {
				autoplay += autoplay.includes("?") ? "&t=" : "?t="
        autoplay += this.currentShare.start_time
			}
			link += autoplay

			return link
		},
    unbrandedShareLink() {
			if (!this.currentUser) {
				return
			}
      var link = "https://interact.video/w/"+this.share.token
			var autoplay = ""
			if (this.currentShare.autoplay) {
				autoplay = "?autoplay=1"
				if (this.share.minimal) {
					autoplay += "&controls=0"
				}
			} else {
				if (this.share.minimal) {
					autoplay = "?controls=0"
				}
			}
			if (this.currentShare.fullscreen) {
				autoplay += autoplay.includes("?") ? "&fullscreen=1" : "?fullscreen=1"
			}
      if (this.currentShare.loop) {
				autoplay += autoplay.includes("?") ? "&loop=1" : "?loop=1"
			}
      if (this.share.doStartTime && this.share.startTime) {
				autoplay += autoplay.includes("?") ? "&t=" : "?t="
        autoplay += this.share.startTime
			}
			link += autoplay
			// if (withParams) {
			// 	link += window.location.search
			// }
			return link
		},
		showStatic() {
			return this.dialogShowing 
			&& !this.afkDialog 
			&& !this.videoDialog 
      && !this.audioDialog 
			&& !this.viewImageDialog 
			&& !this.switchingVideo 
			&& !this.divDialog 
			&& !this.navDialog 
			&& !this.chaptersDialog 
			&& !this.showMessageDialog 
			&& !this.showCardDialog 
			&& !this.showPollDialog
			&& !this.isEditing 
			&& !this.introDialog 
			&& !this.drawingDialog 
			&& !this.authDialog 
			&& !this.questionAnnotation 
			&& !this.resumeDialog
			&& !this.tutorialDialog
		},
		getPlaceholder() {
      switch (this.activeType) {
        case 1:
          return "Paste Video File Link"
        case 2:
          return "Paste Vimeo Link"
        case 3:
          return "Paste Wistia Link"
        case 4:
          return "Paste Kaltura Link"
        case 5:
          return //"Paste JWPlayer Link"
        case 6:
          return "Paste YouTube Link"
        case 7:
          return "Paste Loom Link"
        case 8:
          return "Search for Videos on Pixabay"
        case 9:
          return "Paste Panopto Share Link"
        case 11:
          return "Paste JWPlayer Share Link"
        default:
          return "Paste Video Link"
      }
    },
		hubText() {
			var c = this.currentShare
			if (c.send_hubspot_questions && c.send_hubspot_interactions) {
				return "Questions + Interactions"
			} else if (c.send_hubspot_questions) {
				return 'Questions'
			} else if (c.send_hubspot_interactions) {
				return 'Interactions'
			}
		},
		showQuestionCorrect() {    
			if (this.questionAnnotation && !this.showPollDialog) {
				return this.shareDesign.question_correctness && (!this.questionAnnotation.content.force_correct || this.hasCorrectAnswer)
			}
			return false
		},
		canSkipQuestion() {
			if (this.questionAnnotation && this.questionAnnotation.content.skippable && !this.questionAnnotation.content.force_correct) {
        return true
      }
      return false
		},
    canRewindQuestion() {
			if (this.currentTime > 0 && this.questionAnnotation) {
				return this.questionAnnotation.content.can_rewind
			}
			return false
		},
		isRobot () {
			const robots = new RegExp([
				/bot/,/spider/,/crawl/,                            // GENERAL TERMS
				/APIs-Google/,/AdsBot/,/Googlebot/,                // GOOGLE ROBOTS
				/mediapartners/,/Google Favicon/,
				/FeedFetcher/,/Google-Read-Aloud/,
				/DuplexWeb-Google/,/googleweblight/,
				/bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,        // OTHER ENGINES
				/ecosia/,/ia_archiver/,
				/semrush/,                                         // OTHER
			].map((r) => r.source).join("|"),"i");               // BUILD REGEXP + "i" FLAG
		
			return robots.test(window.navigator.userAgent);
		},
		parentAnnotations() {
			if (!this.ownsPost) {
				return []
			}
			var x = []
			this.annotations.forEach((a)=> {
				if (!a.parent_id) {
					x.push({text: this.formattedSeconds(a.time) + " - " + this.capitalizeFirst(a.type_of) + " -- " + this.removeHTML(a.extra || a.content), value: a.id, type_of: a.type_of, updated_at: a.updated_at, type_of: this.capitalizeFirst(a.type_of), content: this.removeHTML(a.extra || a.content)})
				}
			})
			return x
		},
		hubAnnotations() {
			var results = []
			this.parentAnnotations.forEach((r)=> {
				if (r.type_of.toLowerCase() == 'question' && this.currentShare.send_hubspot_questions) {
					results.push(r)
				} else if (this.currentShare.send_hubspot_interactions && ['image', 'button', 'hotspot', 'text'].includes(r.type_of.toLowerCase())) {
					results.push(r)
				}
			})  
			return results
		},
		showIntroBackground() {
			var x = this.showCTA || (this.introDialog && this.currentShare && (this.currentShare.showMessage || this.currentShare.showTitle))
			return x
		},
		timeItems5() {
			var results = []
			var x = 5
			var z = this.duration
			if (z > 1000) {
				x = 30
			} else if (z > 500) {
				x = 10
			}
			this.timeItems.forEach((t)=> {
				if (t.value % 10 == 0) {
					results.push(t)
				}
			})
			return results
		},
		bindQuestionButtons() {
			return !this.isTouchDevice && this.questionAnnotation && this.questionAnnotation.content.correct_answer && !this.showQuestionCorrect && !this.shouldAuth
		},
		questionTimerProgress() {
			if (this.questionTimer && this.questionAnnotation.content.timer) {
				return Math.max(0, 10 * this.questionTimerAmount / this.questionAnnotation.content.timer) 
			}
			return 100
		},
		artTimerProgress() {
			var x = Math.max(0, 10 * this.artTimerAmount / this.artTimerOriginal) 
			// this.sayDev("ArtTimer: " + x)
			if (this.artTimer && this.artTimerOriginal > 0) {
				return x
			}
			return 100
		},
		dragOptions() {
			var that = this
			var mods = [
				interact.modifiers.restrictRect({
					restriction: 'parent',
					endOnly: true
				})
			]
			if (this.editGrid.snap) {
				mods.push(
					interact.modifiers.snap({
						targets: [
							interact.createSnapGrid(that.snapOptions)
						],
						relativePoints: [
							{ x: 0, y: 0 }
						]
					})
				)
			}
			return {
				origin: 'parent',
				listeners: {
					move(event) {
            if (that.isManualMode) {
              if (!that.pasting) {
								that.pasting = true
								that.$toasted.show("No moving in manual mode")
								setTimeout(() => {
									that.pasting = false
								}, 2000)
							}
              return
            }
						if (that.activeDiv.customStyle.angle != 0) {
							if (!that.pasting) {
								that.pasting = true
								that.$toasted.show("Rotation Angle must be 0 to move")
								setTimeout(() => {
									that.pasting = false
								}, 2000)
							}
							return
						}
						var target = event.target
						var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
						var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

						that.activeDivTransform.x = x
						that.activeDivTransform.y = y
						if (target) {
							target.setAttribute('data-x', x)
							target.setAttribute('data-y', y)

						} 
						that.setDivDimensions(x, y, event.rect.width, event.rect.height)
					}
				},
				inertia: true,
				modifiers: mods
			}	
		},
		snapOptions() {
			this.sayDev("Video WxH: " + this.canvasWidth + 'x' + this.canvasHeight)
			var x = {
				// x: this.canvasWidth / (4 * this.editGrid.cols) ,
				// y: this.canvasHeight / (4 * this.editGrid.rows),
				x: this.canvasWidth / 100 ,
				y: this.canvasHeight / 100,

				// limit to the container dimensions
				limits: {
					left: 0,
					top: 0,
					right: this.canvasWidth - 10,
					bottom: this.canvasHeight - 10,
				},
			}
			this.sayDev(x)
			return x
		},
		valueText() {
			switch (this.activeDiv.action) {
				case 'video':
					return 'Video to Switch To'
				case 'view':
					return 'Image to Show'
				case 'link':
					return 'URL to Open'
				case 'jump':
					return 'Time to Switch To'
				case 'collect':
					return 'Collect Prompt'
				case 'openai':
					return 'Prompt'
				case 'message':
					return 'Message to Show'
				case 'show_card':
					return 'Card to Show'
				case 'video':
					return 'Choose Video Clip'
				case 'audio':
					return 'Choose Audio Clip'
				case 'phone':
					return 'Phone Number'
				case 'email':
					return 'Email Address'
				case 'download':
					return 'File to Download'
				case 'cart':
					return ""
				case 'post':
					return 'Connector (Post Request)'
				case 'get':
					return 'Connector (Get Request)'
        case 'article':
          return 'Choose Article to Show'
				case 'chatGPT':
					return 'Ask ChatGPT'
				default:
					return 'Click Action Value'
			}
		},
		qs() {
			var x = {
				min_value: null,
				max_value: null,
				min_length: null,
				max_length: null
			}
			if (this.questionAnnotation) {
				var c = this.questionAnnotation.content
				x.min_value = c.min_value || x.min_value
				x.max_value = c.max_value || x.max_value
				x.min_length = c.min_length || x.min_length
				x.max_length = c.max_length || x.max_length
			}
			return x
		},
		openSameTab() {
			var x = (this.share && !this.share.tab_links || this.variables.tab && this.variables.tab == "0")
			return x
		},
		getGridStyle() {

			return {
				cols: {
					width: (100 / this.editGrid.cols) + "%",
					height: "100%"
				},
				rows: {
					height: (100 / this.editGrid.rows) + "%",
					width: "100%"
				}
			}
		},
		maxVideoLength() {
      if (this.coreUpUser) {
        return 600
      }
      return 30
		},
		activeDivSwitchOptions() {
      if (!this.showCTA) {
        if ((!this.activeDiv && !this.activeNav) || (!this.activeDiv.value && !this.activeNav.value) || !(this.activeDiv.action == 'video' || this.activeDiv.action == 'switch' || this.activeNav.action == 'video' || this.activeNav.action == 'switch')) {
          return []
        }
      }
			var duration = 0
			var x = this.activeDiv && this.activeDiv.value ? this.activeDiv.value : this.activeNav && this.activeNav.value ? this.activeNav.value :  this.endButton && this.endButton.value ? this.endButton.value : {}
			var z = null
      this.sayDev("SwitchOptions: " + x)
			this.videoItems.forEach((i) => {
				if (i.value == x) {
					duration = i.duration
					z = i
				}
			})
			var options = []
			for (var i = 0; i <= duration; i++) {
				var found = false
				if (z && z.chapters) {
					z.chapters.forEach((c) => {
						if (c.start == i) {
							options.push({
								text: this.formattedSeconds(i) + "  -- Chapter: " + c.title + "",
								value: i
							})
							found = true
						}
					})
				}
				if (!found) {
					options.push({
						text: this.formattedSeconds(i),
						value: i
					})
				}
			}
			return options
		},
		canvasDimensions() {
			return { position: 'absolute', top: this.canvasTop + 'px', left: this.canvasLeft + 'px', width: this.canvasWidth + 'px', height: this.canvasHeight + 'px', maxWidth: this.videoWidth + 'px', maxHeight: this.videoHeight + 'px' }
		},
    dynamicLayer() {
      if (this.useCoverMode) {
        return {
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          border: !this.isDebug ? 'none' : '2px solid red',
			  }
          
      } else {
        return this.fullLayer
      }
    },
		fullLayer() {
			var x =  {
				top: this.canvasTop - 1 + 'px',
				left: this.canvasLeft - 1 + 'px',
				width: this.canvasWidth + 2 + 'px',
				height: this.canvasHeight + 2 + 'px',
				maxWidth: this.canvasWidth + 2 + 'px',
				maxHeight: this.canvasHeight + 2 + 'px',
        border: !this.isDebug ? 'none' : '2px solid red',
			}
      // this.sayDev("FullLayer", x)
      return x
		},
		isRTL() {
			return gon.action == 'posts-show' && this.shareDesign && this.shareDesign.language == 'ar'
		},
		loggedOutView() {
			if (this.share && !this.share.observe) {
				return false
			}
			return (!this.currentUser || !this.ownsPost) && !this.isEmbed && gon.action == 'posts-show' && window.location.pathname != "/editor_status"
		},
		availableVariables() {
			var x = []
			if (this.share.variables){
				Object.keys(JSON.parse(this.share.variables)).forEach((key) =>{
					key = key.toLowerCase()
					if (x.indexOf(key) < 0 && !this.skipVars.includes(key)) {
						x.push(key)
					}
				})
			}
			if (this.orgVariables){
				Object.keys(JSON.parse(this.orgVariables)).forEach((key) =>{
					key = key.toLowerCase()
					if (x.indexOf(key) < 0 && !this.skipVars.includes(key)) {
						x.push(key)
					}
				})
			}
			Object.keys(this.variables).forEach((key)=> {
				key = key.toLowerCase()
				if (x.indexOf(key) < 0 && !this.skipVars.includes(key)) {
					x.push(key)
				}
			});
			this.annotations.forEach((a) => {
				if (a.variable && x.indexOf(a.variable.toLowerCase()) < 0) {
					x.push(a.variable.toLowerCase())
				}
			})
			if (x.length == 0){
				x = ['name']
			}
			return x
		},
		getContentStyle() {
			var ptop = !gon.currentUser ? '0px' : '60px'
			var pbackground = gon.action == 'posts-show' || gon.action == "posts-embed" ? this.safeStyles.branding.page_background_color || 'black' : 'white'
			if (this.isFullscreen) {
				pbackground = 'black'
			}
      if (pbackground != 'black') {
        pbackground = 'transparent'
      }
			return {
				background: pbackground
			}
		},
		color() {
			var c = this.shareDesign.color || this.groupDesign.color || this.userDesign.color || gon.color || '#282876'
			return c
		},
		commentNoBoxShadow() {
			return this.safeStyles.comment.background && this.safeStyles.comment.background.replace(/\s/g, '') == 'rgba(0,0,0,0)!important'
		},
		questionsWithCorrectness() {
			var x = []
			this.visibleAnnotations.forEach((a) => {
				if (a.type_of == 'question' && a.content.correct_answer && !a.hidden) {
					x.push(a)
				}
			})
			return x
		},
		adh() {
			return this.activeDiv && this.activeDiv.type_of == 'hotspot'
		},
		adb() {
			return this.activeDiv && this.activeDiv.type_of == 'button'
		},
		adi() {
			return this.activeDiv && this.activeDiv.type_of == 'image'
		},
		adv() {
			return this.activeDiv && this.activeDiv.type_of == 'video'
		},
		ada() {
			return this.activeDiv && this.activeDiv.type_of == 'audio'
		},
		adm(){
			return this.adv || this.ada
		},
		adt() {
			return this.activeDiv && this.activeDiv.type_of == 'text'
		},
		adc() {
			return this.activeDiv && this.activeDiv.type_of == 'card'
		},
		activeDivTransformStyle() {
			if (this.activeDiv) {
				if (this.activeDiv.type_of == 'button') {
					return `transform: translate(${this.activeDivTransform.x}px,${this.activeDivTransform.y}px) rotate(${this.activeDiv.customStyle.angle || 0}deg)`
				}
				else {
					var x = `; transform: translate(${this.activeDivTransform.x}px,${this.activeDivTransform.y}px) rotate(${this.activeDiv.customStyle.angle || 0}deg)`
          if (this.activeDiv.type_of == 'hotspot') {
            x = `border: 3px solid ` + this.color + x
          } else {
            x = `border: 3px solid ` + this.color + x
          }
          return x
        }
			}
			return {}
		},
		guideItems() {
			return {
				interactions: this.annotations && this.annotations.length > 0 && this.safeStyles.controls.interactions,
				transcript: (gon.has_transcript || gon.has_subtitles) && this.safeStyles.controls.transcript,
				chapters: this.chapters.length && this.safeStyles.controls.chapters,
			}
		},
		showGuide() {
			if (this.guideItems.chapters) {
				this.notesTab = 0
				return true
			} else if (this.guideItems.transcript) {
				this.notesTab = 2
				return true
			} else if (this.guideItems.interactions) {
				this.notesTab = 1
				return true
			} else
				return this.guideItems.interactions || this.guideItems.chapters || this.guideItems.transcript
		},
		socialLinks() {
			if (!this.share) {
				return ''
			}
			var link = this.finalShareLink
			if (!this.isProduction) {
				link = 'https://app.mindstamp.com/w/'+ this.share.token
			}
			var str = "Check out this interactive video '" + this.share.title + "' created with @UseMindstamp ✨ " + link 
			var x = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(link)
			var y = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(str)
			var l = 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(link + "?inline=1")
			var m = 'mailto:?body='+encodeURIComponent(link)
      var w = 'https://api.whatsapp.com/send?text='+encodeURIComponent(link)
      return { facebook: x, twitter: y, linkedin: l, mail: m, whatsapp: w }
		},
		ogShareLink() {
			var image = this.safeStyles.branding.thumbnail
			// var str = encodeURIComponent("I scored " + this.correctCount + "/" + this.interactionsByType.questions.length + " on " + this.share.title)
			var score = this.correctCount + "/" + this.interactionsByType.questions.length
			var link = this.share.link
			if (this.endButton && this.endButton.value) {
				link = this.formatLink(this.endButton.value, true)
			}
			// if (this.endButton && this.endButton.message) {
			//   str = encodeURIComponent(this.endButton.message + " " + this.correctCount + "/" + this.interactionsByType.questions.length)
			// }
			var c = "?"
			if (link.indexOf("?") > -1) {
				c = "&"
			}
			var l = link + c + "title=" + encodeURIComponent(this.share.title || "") + "&message=" + encodeURIComponent(this.share.message || "") + "&image=" + encodeURIComponent(image || "") + "&score=" + encodeURIComponent(score)
			var x = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(l)
			var y = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(l)
			return { facebook: x, twitter: y }
		},
		getSearchTooltip() {
			var str = this.strings.search + ' Video '
			var one = false
			if (this.guideItems.chapters) {
				str += this.strings.chapters
				one = true
			}
			if (this.guideItems.transcript) {
				if (one) {
					str += ' + '
				}
				str += this.strings.transcripts
				one = true
			}
			if (this.guideItems.interactions) {
				if (one) {
					str += ' + ' + this.strings.interactions
				} else {
					[
						str += this.strings.interactions
					]
				}

			}
			return str

		},
		textTrackOptions() {
			var x = []
			this.languageItems.forEach((label) => {
				if (this.textTracks.indexOf(label.text) == -1) {
					x.push(label.text)
				}
			})
      var extras = ['Portuguese (Brazilian)', 'Bulgarian', 'French (Canada)', 'Spanish (LATAM)', 'Spanish (Mexico)', 'Greek', 'Hungarian', 'Icelandic', 'Hebrew', 'Malay', 'Polish', 'Romanian', 'Tamil', 'Slovak', 'Afrikaans', 'Korean', 'Thai', 'Filipino']
			extras.forEach((item)=> {
        if (this.textTracks.indexOf(item) == -1) {
					x.push(item)
				}
      })
      return x.sort(  )
		},
		activePauses() {
			var pauses = []
			this.annotations.forEach((a) => {
				if (a.pause && a.id != this.activeDiv.id && a.exact_time == this.activeDiv.exact_time && !a.is_conditional) {
					pauses.push(a)
				}
			})
			return pauses
		},
		embedStyle() {
			var width = Math.min(100, 100 * this.videoContainerRatio / this.screenRatio)
			var left = 100 - width
			var str = "margin: 0px; padding: 0px; position: absolute; text-align: center; stop: 0; background: " + this.safeStyles.branding.page_background_color + "; "
			if (this.screenRatio > 1.79) {
				var w = Math.min(100, 100 * this.videoContainerRatio / this.screenRatio)
				return str + "width: " + w + "%; left: " + left / 2 + "%;"
			}
			return str + "width: 100vw; left: 0px; text-align: center;"
      
		},
		guestViewing() {
			return gon.action == 'posts-show' && !this.currentUser
		},
		min5time() {
			return parseInt(Math.min(this.currentTime + 5, this.duration - 1))
		},
		min1time() {
			return parseInt(Math.min(this.currentTime + 1, this.duration - 1))
		},
		videoPoster() {
			if (this.switchingVideo || gon.autoplay || gon.startTime) {
				return false
			}
			var x = this.safeStyles.branding.thumbnail
			if (!x || x == "") {
				return false
			}
			return x.replace("https://mindstamp-pub.s3.us-west-1.amazonaws.com", "https://pub-cdn.mindstamp.com")
		},
		layerMessageStyle() {
			var x = {
				fontSize: '12px',
				fontWeight: 'bold'
			}
			return x
		},
		fontSizes() {
			var x = {
				default: '12px',
				prompt: '14px'
			}
			if (this.videoWidth > 391) {
				x.default = '12px'
				x.prompt = '16px'
			}
			if (this.videoWidth > 500) {
				x.default = '14px'
				x.prompt = '18px'
			}
			if (this.videoWidth > 600) {
				x.default = '15px'
				x.prompt = '20px'
			}
			if (this.videoWidth > 700) {
				x.default = '16px'
				x.prompt = '22px'
			}
			if (this.videoWidth > 800) {
				x.default = '18px'
				x.prompt = '24px'
			}
			if (this.videoWidth > 900) {
				x.default = '19px'
				x.prompt = '28px'
			}
			if (this.videoWidth > 1000) {
				x.default = '20px'
				x.prompt = '32px'
			}
			if (this.videoWidth > 1200) {
				x.default = '22px'
				x.prompt = '32px'
			}
			if (this.videoWidth > 1400) {
				x.default = '24px'
				x.prompt = '36px'
			}
			if (this.videoWidth > 1600) {
				x.default = '26px'
				x.prompt = '40px'
			}
			if (this.questionAnnotation && this.questionAnnotation.prompt) {
				// this.sayDev("QA", this.questionAnnotation)
				var p = this.questionAnnotation.prompt_raw.length
				// this.sayDev("Prompt length: " + p)
				if (p > 1000) {
					x.prompt = this.isMobile ? '12px' : '14px'
				} else if (p > 800) {
					x.prompt = this.isMobile ? '13px' : '16px'
				} else if (p > 600) {
					x.prompt = this.isMobile ? '14px' : '18px'
				} else if (p > 400) {
					x.prompt = this.isMobile ? '15px' : '20px'
				} 
			}
			return x
		},
		fontSize() {
			var fontSize = '10px'
			if (this.videoWidth > 300) {
				fontSize = '12px'
			}
			if (this.videoWidth > 400) {
				fontSize = '14px'
			}
			if (this.videoWidth > 500) {
				fontSize = '16px'
			}
			if (this.videoWidth > 600) {
				fontSize = '18px'
			}
			if (this.videoWidth > 700) {
				fontSize = '20px'
			}
			if (this.videoWidth > 800) {
				fontSize = '22px'
			}
			if (this.videoWidth > 900) {
				fontSize = '24px'
			}
			if (this.videoWidth > 1000) {
				fontSize = '26px'
			}
			if (this.videoWidth > 1200) {
				fontSize = '28px'
			}
			if (this.videoWidth > 1400) {
				fontSize = '32px'
			}
			if (this.videoWidth > 1600) {
				fontSize = '36px'
			}
			return fontSize
		},
		// ssss
		safeStyles() {
			var video = {
				position: "absolute",
				left: '0%',
				top: '0%',
				height: '100%',
				maxWidth: '100%',
				maxHeight: '100%',
				objectFit: 'cover'
			}
			if (this.variables.videoTest == "1") {
				var video = {
					position: "absolute",
					left: '0%',
					top: '0%',
					height: '100%',
					width: '100%',
					maxWidth: '100%',
					maxHeight: '100%'
				}
			}
			var comment = {
				maxWidth: (3 * this.videoWidth / 4) + 'px',
				fontSize: this.fontSize,
				borderRadius: '3px',
				avatar: true
			}

			var card = {
				background: 'rgba(75,75,75,0.5) !important',
				border: '#ffffff',
				borderRadius: '10px',
			}

			var question = {
				height: 'auto',
				width: '50%',
				top: '0px',
				left: "0px",
				background: 'rgba(0,0,0,0.66)',
				// borderRadius: '0px, 10px, 10px, 0px',
			}

			var button = {
				background: 'white',
				color: 'black',
				borderRadius: '3px',
			}

			var controls = {
				visible: true,
				seekbar: true,
				time: true,
				rewind: false,
				forward: false,
				speed: false,
				chapters: true,
				interactions: false,
				transcript: true,
			}

			var intro = {
				playIcon: 'https://resource-cdn.mindstamp.com/assets/play-filled.png'
			}

			if (this.share && this.share.customStyle) {
				var sc = this.share.customStyle.comment || {}
				comment.borderRadius = sc.borderRadius || comment.borderRadius
				comment.avatar = sc.avatar != undefined ? sc.avatar : true
				if (sc.color) {
					comment.color = sc.color + "!important"
				}
				var sb = this.share.customStyle.button || {}
				button.borderRadius = sb.borderRadius || button.borderRadius
				button.color = sb.color || button.color
				button.background = sb.background || button.background
			}
			var general = {
				videoButton: {
					right: '5px'
				},
				transition: 'zoom'
			}

			var branding = {}

			var sd = this.shareDesign || {}
			var ud = this.shareDesign || {}
			var gd = this.shareDesign || {}

			if (sd || ud || gd) {
				general.transition = sd.interactions_transition
				controls.visible = !sd.hide_controls && !this.ownsPost
				this.isMinimal = sd.hide_controls && !this.ownsPost && !this.isDebug
				controls.seekbar = sd.show_seekbar || this.ownsPost || this.isDebug
				controls.time = sd.show_time || this.ownsPost || this.isDebug
				controls.rewind = sd.show_rewind || this.isDebug
				controls.speed = sd.show_speed || this.isDebug
				controls.forward = sd.show_fastforward || this.isDebug
				controls.markers = sd.show_markers || this.ownsPost || this.isDebug
				controls.chapters = sd.guide_chapters
				controls.transcript = sd.guide_transcript
				controls.interactions = sd.guide_interactions


				button.color = (sd.button_text_color || ud.button_text_color || gd.button_text_color || button.color) + "!important"
				button.background = (sd.button_background_color || ud.button_background_color || gd.button_background_color || button.background) + "!important"
				button.borderRadius = ((sd.button_border_radius || ud.button_border_radius || gd.button_border_radius || button.borderRadius || 0) / 10) + 'em !important'
				var str = "0.2em solid "
        if (this.isMobile) {
          str = "0.12em solid "
        }
        button.border = (str + (sd.button_border_color || ud.button_border_color || gd.button_border_color || button.border || 'transparent')) + " !important"
				comment.align = (sd.comments_align)
				comment.color = (sd.comment_text_color || ud.comment_text_color || gd.comment_text_color || comment.color) + "!important"
				comment.background = (sd.comment_background_color || ud.comment_background_color || gd.comment_background_color || comment.background) + "!important"
				comment.borderRadius = ((sd.comment_border_radius || ud.comment_border_radius || gd.comment_border_radius || comment.borderRadius || 0) / 10) + "em !important"
				comment.avatar = sd.comment_show_avatar
				card.background = (sd.card_background_color || ud.card_background_color || gd.card_background_color || card.background) + "!important"
				str = "0.2em solid "
        if (this.isMobile) {
          str = "0.12em solid "
        }
				card.border = (str + (sd.card_border_color || ud.card_border_color || gd.card_border_color || card.border)) + " !important"
				card.borderRadius = ((sd.card_border_radius || ud.card_border_radius || gd.card_border_radius || card.borderRadius || 0) / 10) + 'em !important'
				branding.avatar = sd.avatar || gd.avatar || ud.avatar || this.post && this.post.avatar
				branding.thumbnail = sd.thumbnail
				branding.logo = sd.logo || gd.logo || ud.logo || gon.brandingImage
				branding.icon = sd.icon || gd.icon || ud.icon
				branding.video_background_color = sd.video_background_color
				
				question.position = 'absolute'
        // if (this.isMobile && !this.isEmbed && !this.ownsPost) {
        //   question.position = 'fixed'
        // }
				var top, left, width, height, background_color, correct_color, incorrect_color, prompt_color, answers_color
				if(this.questionAnnotation && this.questionAnnotation.content && this.questionAnnotation.content.use_custom_design){
					top = this.questionAnnotation.content.top
					left = this.questionAnnotation.content.left
					width = this.questionAnnotation.content.width
					height = this.questionAnnotation.content.height
					background_color = this.questionAnnotation.content.background_color
					correct_color = this.questionAnnotation.content.correct_color
					incorrect_color = this.questionAnnotation.content.incorrect_color
					prompt_color = this.questionAnnotation.content.prompt_color
					answers_color = this.questionAnnotation.content.answers_color
				}
				if(!this.isMobile || !this.shareDesign.use_fullscreen_mobile){
					top = top || sd.question_top
					left = left || sd.question_left
					width = width || sd.question_width
					height = height || sd.question_height
					question.top = top + '%'
					question.height = height + '%'
					question.width = width + "%"
					question.left = left + "%"
					// question.borderRadius = this.getBorderRadius(left, width)
				}
				question.background = background_color || sd.question_background_color
				question.numbering = sd.show_question_numbering
				question.enter_continue = sd.enter_continue
        question.correct_color = correct_color ||  sd.correct_color
        question.incorrect_color = incorrect_color ||  sd.incorrect_color
        question.prompt_color = prompt_color ||  sd.prompt_color
        question.answers_color = answers_color ||  sd.answers_color
        

				intro.play_button_image = 'https://resource-cdn.mindstamp.com/assets/play-filled.png'
				if (sd.play_button_image) {
					intro.play_button_image = sd.play_button_image.replace('https://mindstamp-pub.s3.us-west-1.amazonaws.com', 'https://pub-cdn.mindstamp.com') + "?auto=compress,format"
				}
				intro.custom_play_button = intro.play_button_image != 'https://resource-cdn.mindstamp.com/assets/play-filled.png'
				if (this.ownsPost) {
					branding.page_background_color = this.darkMode ? 'grey' : 'white !important';
				}
        if (this.share && !this.share.observe) {
          branding.page_background_color = 'white !important'
        }
        if (this.isEmbed) {
          if (sd.video_background_color == "white" || sd.video_background_color == "#FFFFFF") {
            branding.video_background_color = 'transparent !important'
          } else {
            branding.video_background_color = 'black !important'
          }
        }

				// videos

				this.videoCover = sd.video_cover
				if (this.isMobile || this.questionDialog || this.questionAnnotation) {
					video.maxWidth = '100%'
					video.maxHeight = '100%'
					video.width = '100%'
					video.left = '0%'
					video.top = '0%'
					video.right = 'unset'
				} else if (this.videoAnnotation && this.videoAnnotation.pause) {
					video.maxWidth = this.canvasWidth + 'px'
					video.maxHeight = this.canvasHeight + 'px'
					video.width = '100%'
					video.height = '100%'
					video.right = 'unset'
				} else if (this.videoAnnotation) {
					video = this.getActiveDivStyle(this.videoAnnotation)
				} 
			}

			if (this.isMobile && this.shareDesign.use_fullscreen_mobile) {
				question.width = "100%"
				question.left = "0"
				question.top = "0"
				question.height = "100%"
			}
      if (this.isMobile) {
        question.height = "100%"
      }

      // if (question.width == '100%' && this.videoTrueRatio != 1.77) {
      //   // console.log("Irregular, adjust question layer")
      //   var amount = 100 - (this.videoTrueRatio / 1.77) * 100
      //   // console.log(amount, question.left, question.width)
      //   question.left = (amount / 2)+'%'
      //   question.width = (100 - amount) + "%"
      // }
      var message = Object.assign({}, question)
      if (this.shareDesign.message_left || this.shareDesign.message_top || this.shareDesign.message_width) {
        message.left = this.shareDesign.message_left+'%'
        message.top = this.shareDesign.message_top+'%'
        message.width = this.shareDesign.message_width+'%'
        message.height = this.shareDesign.message_height+'%'
      }
      message.background = this.shareDesign.message_background_color || question.background
      message.color = this.shareDesign.message_text_color || question.color

			var x = {
				comment: comment,
				button: button,
				branding: branding,
				question: question,
				intro: intro,
				controls: controls,
				video: video,
				general: general,
				card: card,
        message: message
			}
      // console.log(question)
			// this.sayDev("Styles", x)
			return x
		},
    
		showingCorrect() {		
			return (!!this.chosenAnswer || !!this.responseForRepeat) && !!this.correctAnswer && this.showQuestionCorrect
		},
		hasCorrectAnswer() {
			if (!this.questionAnnotation) {
				// this.sayDev("No ann, return false")
				return false
			}
			var answer = this.correctAnswer
			var response = this.chosenAnswer
			if(this.questionAnnotation.content.style != 'all'){
				answer = this.correctAnswer ? this.correctAnswer.toString().trim().toLowerCase() : ''
				response = this.chosenAnswer ?  this.chosenAnswer.toString().trim().toLowerCase() : ''
        this.sayDev("Answer: " + answer, typeof(answer))
        this.sayDev("Response: " + response, typeof(response))
			}
			if (response == ''){
				if(this.questionAnnotation.content.style == 'all'){
					response = this.responseForRepeat;
				} else {
					response = this.responseForRepeat.toString().toLowerCase();
				}
			}
			this.sayDev("🌕 HCA --> " + response, answer)
			try {
        if (!Number(answer)) {
          answer = JSON.parse(answer)
				  this.multiCorrect = answer.length > 0
        }
			} catch (e){
				this.multiCorrect = false
			}
			if (this.questionAnnotation.content.style == "free" && this.multiCorrect){
				// need to split each entry and remove leading and trailing space for exact match, not partial
				var correct = false
				for(var a of answer){
					a = a.trim()
					if(false && this.isRegex(a)){
						var exp = new RegExp(this.makeRegex(a))
						correct = exp.test(response)
					} else {
						correct = a == response
					}
					if (correct){
						break
					}
				}
				return correct
			} else {
				if(this.questionAnnotation.content.style == 'date'){
					var d = new Date(answer);
					answer = d.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
					var d = new Date(response);
					response = d.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
					// this.correctAnswer = answer 
					// this.chosenAnswer = response
					return response == answer; 
				} else if(this.questionAnnotation.content.style == 'number'){
					var min = parseFloat(answer[0])
					var max = parseFloat(answer[1])
					response = parseFloat(response)
					var inRange = response  >= min
					inRange = inRange && response <= max
					// this.chosenAnswer = response.toLocaleString('en-US');
					// this.correctAnswer = "Min: " + min.toLocaleString('en-US') + " - Max: " + max.toLocaleString('en-US');
					return inRange
				} else if (this.questionAnnotation.content.style == 'all'){
					response = response.sort()
					var i
					for(i = 0; i < answer.length; i++){
						if(answer[i] != response[i]){
							return false;
						}
					}
					return i == response.length;
				}
				this.sayDev("Answer A: " + answer)
				answer = answer != null ? answer.toString() : "null" // off chance the answer to the user's question is "null"
				this.sayDev("Answer B: " + answer)
				this.sayDev("Response: " + response)
				if(false && this.isRegex(answer)){
					var exp = new RegExp(this.makeRegex(answer))
					var match = exp.test(response)
					this.sayDev("response matches answer", match)
					return match
				} else {
					this.sayDev("response == answer", response == answer)
					return response == answer;
				}
			}
		},
		isCorrect() {
			return this.hasCorrectAnswer
		},
		drawButtonWidth() {
			return this.videoWidth / 9 - 2 + 'px'
		},
		hideCommentBox() {
			var x = this.activeHotspotPanels && this.activeHotspotPanels[7] && this.activeHotspotPanels[7].text
			return x
		},
		showMessageInputRow() {
			return this.isMobile && !this.isFullscreen && !this.isEmbed && ((this.ownsPost || this.participate) && this.played || this.isTour)
		},
		correctAnswerOptions() {
			if (!this.editingQuestion.answers || !Array.isArray(this.editingQuestion.answers)) {
				return []
			}
			let x = []
			if(this.editingQuestion.style != 'all'){
				x.push({
					label: 'Choose Correct Answer',
					value: '',
				})
			}
			this.editingQuestion.answers.forEach((a) => {
				if (a.text) {
					x.push({
						label: a.text,
						value: a.text
					})
				}
			})

			return x
		},
		activeHotspotPanels() {
			if (!this.activeHotspot || !this.activeHotspot.data) {
				return []
			}
			return JSON.parse(this.activeHotspot.data)
		},
		returnPath() {
			return window.location.pathname
		},
		buttonIsValid() {
			// return true
			return this.theButton.content.length > 1 && (this.theButton.extra || this.theButton.extra === 0)
		},
		buttonLabel() {
			if (this.theButton.action == 'link') {
				return 'Paste Link to Open'
			} else if (this.theButton.action == 'jump') {
				return 'Select time to Jump To'
			} else if (this.theButton.action == 'video') {
				return 'Select Video to Switch To'
			} else if (this.theButton.action == 'email') {
				return 'Enter Email Address'
			} else if (this.theButton.action == 'phone') {
				return 'Enter Phone Number'
			}
		},
		imageLabel() {
			if (this.theImage.action == 'link') {
				return 'Paste Link to Open'
			} else if (this.theImage.action == 'jump') {
				return 'Select time to Jump To'
			} else if (this.theImage.action == 'video') {
				return 'Select Video to Switch To'
			} else if (this.theImage.action == 'email') {
				return 'Enter Email Address'
			} else if (this.theImage.action == 'phone') {
				return 'Enter Phone Number'
			}
		},
		titleFontSize() {
			if (this.videoWidth > 1000) {
				return '32px'
			} else if (this.videoWidth > 800) {
				return '28px'
			} else if (this.videoWidth > 600) {
				return '26px'
			} else if (this.videoWidth > 500) {
				return '24px'
			} else if (this.videoWidth > 400) {
				return '20px'
			} else {
				return '18px'
			}
		},
		noteFontSize() {
			var min = 12;
			var size = 12
			var py = 4;
			var px = 8;
			if (this.videoWidth > 1300) {
				size = 28
				py = 10
				px = 18
			} else if (this.videoWidth > 1000) {
				size = 24
				py = 8
				px = 12
			} else if (this.videoWidth > 800) {
				size = 22
				py = 6
				px = 10
			} else if (this.videoWidth > 600) {
				size = 18
				py = 5
				px = 9
			} else if (this.videoWidth > 400) {
				size = 14
				py = 4
				px = 8
			} else if (this.videoWidth > 350) {
				size = 12
				py = 3
				px = 7
			} else {
				size = 10;
				py = 3
				px = 6
			}
			return {
				size: size + 'px',
				line: size + 6 + 'px',
				padding: py + 'px ' + px + 'px'
			}
		},
		seekbarTop() {
			var x = this.videoHeight - 70
			if (this.isMobile) {
				x = this.videoHeight - 65
			}

			if (this.isFullscreen) {
				x = this.videoHeight - 75
			}
			return x + 'px'
		},
		shapeHeight() {
			return this.isMobile ? this.videoWidth / 4 : this.videoWidth / 6
		},
		strokeColor() {
			return this.fillColor.hex ? this.fillColor.hex : this.color && this.color != '#000000' ? this.color : '#FFFF66'
		},
		searchStringTooltip() {
			return this.searchString ? 'Current Search: \'' + this.searchString + '\'' : 'View Video Guide'
		},
    isLandscape() {
      return this.screenWidth > this.screenHeight && !this.currentUser
    },
		isLandscapeIOS() {
			return this.isIOS && this.isLandscape
		},
		canvasTop() {
			if (this.isFullscreen && this.isVimeo) {
				return 0
			}
      if (this.useCoverMode) {
        return 0
      }
      var x = Math.max((this.videoHeight / 2) - (this.canvasHeight / 2), 0)
      // this.sayDev("CanvasTop: " + x)
			return x
		},
		canvasLeft() {
      if (this.useCoverMode) {
        return 0
      }
			return Math.max((this.videoWidth / 2) - (this.canvasWidth / 2), 0)
		},
		canvasWidth() {
      if (this.useCoverMode) {
        return this.screenWidth
      }
			return Number(Math.min(this.videoWidth, this.videoWidth * ((this.videoTrueRatio / this.videoContainerRatio))).toFixed(1))
		},
		canvasHeight() {
      if (this.useCoverMode) {
        return this.screenHeight
      }
      var x = 0
			if (this.isYoutube || this.isVimeo || this.isVimeo) {
				x = this.videoWidth / 1.77
			} else if (this.videoTrueRatio > this.videoContainerRatio) {
				x = this.videoHeight / (this.videoTrueRatio / this.videoContainerRatio)
			} else if (false && this.isLandscapeIOS) {
				this.sayDev("Inner Height: " + document.documentElement.clientHeight)
				 x = document.documentElement.clientHeight
			} else {
				x = this.videoHeight
			}
      return Number(x.toFixed(1))
		},
		screenRatio() {
			return this.screenWidth / this.screenHeight
		},
		videoTrueRatio() {
			if (this.isVimeo || this.isYoutube || this.isWistia) {
				return (this.videoWidth / this.videoHeight).toFixed(2)
			}
			if (!this.videoTrueWidth || this.videoTrueWidth < 10) {
				return 1.77
			}
			return (this.videoTrueWidth / this.videoTrueHeight).toFixed(2)
		},
		videoContainerRatio() {
			if (this.videoHeight == 0) {
				this.videoHeight = 1
			}
			return parseFloat((this.videoWidth / this.videoHeight).toFixed(2))
		},
		annotationsByParent() {
			this.annotationReplies.sort(function (b, a) {
				if (a.created_at < b.created_at) { return -1; }
				if (a.created_at > b.created_at) { return 1; }
			});
			var res = {}
			for (var i = 0; i < this.annotationReplies.length; i++) {
				var parent_id = this.annotationReplies[i]["parent_id"]
				if (res[parent_id]) {
					res[parent_id].push(this.annotationReplies[i])
				} else {
					res[parent_id] = []
					res[parent_id].push(this.annotationReplies[i])
				}
			}
			return res
		},
		filteredAnnotationsByParent() {
			if (!this.searchString) {
				return this.annotationsByParent
			}
			var res = {}
			for (var i = 0; i < this.annotationReplies.length; i++) {
				var parent_id = this.annotationReplies[i]["parent_id"]
				if (res[parent_id] && this.annotationReplies[i] && this.annotationReplies[i].search.includes(this.searchString)) {
					res[parent_id].push(this.annotationReplies[i])
				} else if (this.annotationReplies[i] && this.annotationReplies[i].search && this.annotationReplies[i].search.includes(this.searchString)) {
					res[parent_id] = []
					res[parent_id].push(this.annotationReplies[i])
				}
			}
			return res
		},
		showActionTip() {
      return false
			return !this.played && !this.dialogShowing && this.currentUser && !this.isMobile && !this.isEditing && (this.videoCount == 1 || this.currentTime < 4)
		},
		drawBottom() {
			if (this.isIOS) {
				return '10%'
			}
			return '0px'
		},
		shouldPay(){
			var doPay = this.needsToPay
			if (this.share.capture_payment_point == 0) {
				doPay = doPay && true
			} else if (this.share.capture_payment_point == 1) {
				doPay = doPay && true
			} else if (this.share.capture_payment_point == 2 && (this.deciTime >= parseFloat(this.share.capture_payment_value) || this.currentTime >= parseFloat(this.share.capture_payment_value))) {
				doPay = doPay && true
			} else if (this.share.capture_payment_point == 3 && this.replyAnnotationID == this.share.capture_payment_value) {
				doPay = doPay && true
			} else {
				doPay = false
			}
			return doPay
		},
		shouldAuth() {
			var timing = false
			var info = false
			if (this.currentUser || (this.skipAuth && this.share.capture_auth_point != 3)) {
				return false
			}
			if (this.share.captureID && !this.guestID) {
				info = true
			}
			if (this.share.captureEmail && !this.guestEmail) {
				info = true
			}
			if (this.share.captureName && !this.guestName) {
				info = true
			}
			if (this.share.capturePhone && !this.guestPhone) {
				info = true
			}
			info = this.setCustomViewerVariables(info)

			if (this.share.capture_auth_point == 0) {
				// this.sayDev("CAT: start")
				timing = true
			} else if (this.share.capture_auth_point == 1) {
				// this.sayDev("CAT: first ")
				timing = true
			} else if (this.share.capture_auth_point == 2 && [this.deciTime.toString(), this.currentTime.toString()].includes(this.share.capture_auth_value)) {
				// this.sayDev("CAT: time")
				timing = true
			} else if (this.played && this.share.capture_auth_point == 2 && (parseFloat(this.share.capture_auth_value) <= this.deciTime || parseFloat(this.share.capture_auth_value) <= this.currentTime) && this.share.captureRequired){
				// this.sayDev("CAT: time")
				timing = true
			} else if (this.share.capture_auth_point == 3 && this.replyAnnotationID == this.share.capture_auth_value) {
				// this.sayDev("CAT: interaction")
				timing = true
			} else if (this.share.capture_auth_point == -1){
				timing = false
			}
			// this.sayDev("ShouldAuth: " + info + " / " + timing + " ("+this.share.capture_auth_point+")")
			return info && timing
		},
		homeLink() {
			if (this.ownsPost) {
				return "/"
			}
			var x = this.baseUrl
			var link = gon.homelink ? gon.homelink : this.share && this.share.homelink ? this.share.homelink : this.currentUser ? x : 'https://mindstamp.io'
			if (link == 'null') {
				link = 'https://mindstamp.io'
			}
			return this.formatLink(link)
		},
		canParticipate() {
			return this.ownsPost || this.share && this.share.participate && !window.location.href.includes("noaction")
		},
		showInputRow() {
			return !this.isEmbed && !this.isFullscreen && this.canParticipate && !this.showSeekbar
		},
		links() {
			if (this.isProduction) {
				return {
					login: 'https://app.mindstamp.com/login',
					join: 'https://app.mindstamp.com/signup',
					demo: 'https://app.mindstamp.com/demo'
				}
			} else {
				return {
					login: 'http://video.localhost:3000/login',
					join: 'http://video.localhost:3000/signup',
					demo: 'http://video.localhost:3000/demo'
				}
			}
		},
		returnPath() {
			var x = window.location.pathname
			if (x.includes('t=')) {
				return x
			} else if (x.includes("?")) {
				return x + '&t=' + this.currentTime
			} else {
				return x + '?t=' + this.currentTime
			}
		},
		sanitizedMessage() {
			return this.sanitizeString(this.message)
		},
		dialogShowing() {
			return (
        this.articleDialog ? 'articleDialog'  :
        this.afkDialog ? 'afkDialog'  :
        this.helpDialog ? 'helpDialog'  :
        this.gptDialog ? 'gptDialog' :
        (this.deleteAnnDialog && !this.deleteAnnDialog) ? 'dast'  :
				this.multiDialog ? 'multiDialog'  :
				this.isEditing ? 'isEditing'  :
				this.moveDialog ? 'moveDialog'  :
				this.tutorialDialog ? 'tutorialDialog'  :
				this.phoneDialog ? 'phoneDialog'  :
				this.continueDialog ? 'continueDialog'  :
				this.editTranscriptDialog ? 'editTranscriptDialog'  :
				this.timeDialog ? 'timeDialog'  :
				this.touchPlayDialog ? 'touchPlayDialog'  :
				this.supportDialog ? 'supportDialog'  :
				this.recentDialog ? 'recentDialog'  :
				this.showResponseTable ? 'showResponseTable'  :
				this.importDialog ? 'importDialog'  :
				this.scormDialog ? 'scormDialog'  :
				this.openPreviewDialog ? 'openPreviewDialog'  :
				this.plinkDialog ? 'plinkDialog'  :
				this.backupDialog ? 'backupDialog'  :
				this.colorDialog ? 'colorDialog'  :
				this.copyTrayDialog ? 'copyTrayDialog'  :
				this.siteScraperDialog ? 'siteScraperDialog'  :
				this.branchingDialog ? 'branchingDialog'  :
				this.variableDialog ? 'variableDialog'  :
				this.productsDialog ? 'productsDialog'  : 
				this.confirmLink ? 'confirmLink'  :
				this.refreshDialog ? 'refreshDialog'  :
				this.navDialog ? 'navDialog'  :
				this.designDialog ? 'designDialog'  :
				this.switchingVideo ? 'switchingVideo'  :
				this.collectPrompt ? 'collectPrompt'  :
				this.captionsDialog ? 'captionsDialog'  :
				this.firstVideoDialog ? 'firstVideoDialog'  :
				this.divDialog ? 'divDialog'  :
				this.dupDialog ? 'dupDialog'  :
				this.stylesModal ? 'stylesModal'  :
				this.addingModalDialog ? 'addingModalDialog'  :
				this.showMessageDialog ? 'showMessageDialog'  :
				this.showCardDialog ? 'showCardDialog'  :
				this.showPollDialog ? 'showPollDialog'  :
				this.customVICDialog ? 'customVICDialog'  :
				this.publishDialog ? 'publishDialog'  :
				this.questionCreationDialog ? 'questionCreationDialog'  :
				this.imageDialog ? 'imageDialog'  :
				this.viewImageDialog ? 'viewImageDialog'  :
				this.drawingDialog ? 'drawingDialog'  :
				this.addNoteDialog ? 'addNoteDialog'  :
				this.videoDialog ? 'videoDialog'  :
				this.audioDialog ? 'audioDialog'  :
				this.signUpDialog ? 'signUpDialog'  :
				this.authDialog ? 'authDialog'  :
				this.payDialog ? 'payDialog'  :
				this.replyDialog ? 'replyDialog'  :
				this.questionDialog ? 'questionDialog'  :
				this.editingDialog ? 'editingDialog'  :
				this.editingItem ? 'editingItem'  :
				this.recordVideoDialog ? 'recordVideoDialog'  :
				this.recordAudioDialog ? 'recordAudioDialog'  : false
			)
		},
		isMobileEmbed() {
			return this.isMobile && this.isEmbed;
		},
		orderedAnnotations() {
			var ann = this.filteredAnnotations.sort(function (a, b) { 
        if (Number(a.exact_time) == Number(b.exact_time)) {
          return a.created_at < b.created_at ? -1 : 1
        } else {
          return Number(a.exact_time) < Number(b.exact_time) ? -1 : 1
        }
      });
			if (this.showCTA){
				this.filteredAnnotations.sort(function(a,b) {return a.type_of == 'question' && b.type_of == 'question' ? a.exact_time - b.exact_time || a.created_at < b.created_at ? -1 : 1 : a.type_of == 'question' ? -1 : 1})
			}
			return ann
		},
		orderedQuestions() {
			return this.interactionsByType.questions.sort(function (a, b) { return a.exact_time - b.exact_time || a.created_at - b.created_at });
		},
		resourceTrayAnnotations() {
			return this.filteredAnnotations.filter(x=>x.tray_type == 'resourceTray').sort(function (a, b) { return a.exact_time - b.exact_time || a.created_at - b.created_at });
		},
		ctaTrayAnnotations() {
			return this.filteredAnnotations.filter(x=>x.tray_type == 'ctaTray').sort(function (a, b) { return a.exact_time - b.exact_time || a.created_at - b.created_at });
		},
		firstCtaTrayQuestion() {
			return this.ctaTrayAnnotations.filter(x=>x.type_of == 'question')[0]
		},
		normalAnnotations() {
			return this.filteredAnnotations.filter(x=>!x.tray_type);
		},
		hasProductAnns(){
			return this.filteredAnnotations.filter(x=> x.click_action == 'cart').length > 0
		},
		bytesToSize() {
			return this.bytesToSize(this.video_size)
		},
		formattedCurrentTime() {
			var date = new Date(this.currentTime * 1000);
			var hh = date.getUTCHours();
			var mm = date.getUTCMinutes();
			var ss = date.getSeconds();
			if (hh > 0) {
				hh = hh + ":";
			} else {
				hh = "";
			}
			if (mm < 10 && mm != 0) {
				if (hh == "") {
					mm = mm + ":";
				} else {
					mm = "0" + mm + ":"
				}

			} else if (mm >= 10) {
				mm = mm + ":";
			} else if (mm == 0) {
				mm = mm + ":";
			}

			if (ss < 10) { ss = "0" + ss; }
			return hh + mm + ss;
		}
	},
	// mmmm
	methods: {
    setPlaybackSpeed(speed) {
      this.sayDev("SetPlaybackSpeed")
      this.playbackSpeed = Number(speed)
      this.sayDev("Playback Speed is now " + this.playbackSpeed)
      this.speedPopup = false
    },
    getSpeedPopoverStyle() {
      var x = document.getElementById('speed-button')
      var styles =  {
        position: 'fixed',
        top: (x.getBoundingClientRect().y - (this.isMobile && this.isEmbed ? 170 : 200)) + 'px',
        height: 'auto',
        width: '150px',
        zIndex: 20,
        left: (x.getBoundingClientRect().x - 75) + 'px',
        background: 'white'
      }
      this.sayDev("SpeedPopover styles", styles)

      return styles
    },
    getCaptionsPopoverStyle() {
      var x = document.getElementById('captions-button')
      var offset = (this.isMobile && this.isEmbed ? 80 : 100)
      offset += Math.max(0, this.textTracks.length - 1) * 25
      var styles =  {
        position: 'fixed',
        top: (x.getBoundingClientRect().y - offset) + 'px',
        height: 'auto',
        width: 'auto',
        minWidth: '120px',
        zIndex: 20,
        left: (x.getBoundingClientRect().x - 60) + 'px',
        background: 'white'
      }
      this.sayDev("Captions styles", styles)

      return styles
    },
    toggleSpeedPopup() {
      this.speedPopup = !this.speedPopup
      if (this.speedPopup) { this.captionsPopup = false}
      this.sayDev("Toggle Speed Popup: " + this.speedPopup)
    },
    toggleCaptionsPopup() {
      this.captionsPopup = !this.captionsPopup
      if (this.captionsPopup) { this.speedPopup = false}
      this.sayDev("Toggle Captions Popup: " + this.captionsPopup)
    },
    setChapterTiming() {
      this.chapters.forEach((c,i)=> {
        if (this.chapters[i+1]) {
          c.finish = this.chapters[i+1].start - 1
        } else {
          c.finish = this.duration || this.share.duration
        }
        c.duration = c.finish - c.start
      })
    },
    getResumablePlay(p) {
      this.fetchingResumable = true
      setTimeout(()=> {
        this.fetchingResumable = false
      }, 5000)
      this.sayDev("Get Resumable Play", p)
      var data = new FormData()
      data.append('id', p.id)
      data.append('token', p.token)
      data.append('created_at', p.created_at)
      data.append('session_id', p.session_id)
      data.append('viewer_id', p.viewer_id)
      Rails.ajax({
        url: "/resumable/"+p.id,
        type: "POST",
        data: data,
        dataType: "JSON",
        success: (data) => {
          this.sayDev("Resumable Response")
          this.sayDev(data)
          this.lastPlay = this.lastPlay || data.play
          this.lastPlayAnnotations = this.lastPlayAnnotations || data.anns
          this.referVideo = data.refer
          this.referTime = data.time || 0
          this.fetchingResumable = false
        },
        error: (data) => {
          this.$toasted.show('Something went wrong')
          this.fetchingResumable = false
        }
      })
    },
    getHotspotActiveItemStyle(item) {
      if (!item) {
        return {}
      }
      const cs = JSON.parse(item.customStyle)
      var x = { border: this.isDebug ? '3px solid green' : 'none'}
      if (this.divDialog) {
        x.border = '2px solid gold'
        x.borderRadius = (cs.borderRadius || 50)+'%'
        x.opacity = 0.5
      }
      return x
    },
    clickChapter(chapter, event) {
      event.stopPropagation();
      this.changeTime(chapter.start)
    },
    stopEditArticle(article) {
      this.sayDev("Stop Edit Article!")
      this.sayDev(article)
      this.editArticleDialog = false; 
      this.editArticle = null
      window.postMessage("refresh-assets")
      if (this.assets) {
        this.refreshAssets()
      }
      if (article && this.activeDiv && this.activeDiv.action == 'article') {
        this.sayDev("Set active article")
        this.activeDiv.value = article.id

      }
    },
    doEditArticle(article) {
      console.log("DoEdit", article)
      this.editArticle = article
      this.editArticleDialog = true
    },
    getArticle(id) {
      var result = false
      if (this.articles) {
        this.articles.forEach((a)=> {
          if (a.id == id) {
            result = a
          }
        })
      }
      return result
    },
    showArticle(id) {
      this.sayDev("Show Article", id)
      var a = this.getArticle(id)
      if (a) {
        this.activeArticle = a
        this.articleDialog = true
      }
    },
    tryParseJSON(str) {
      if (this.isJSON(str)) {
        return JSON.parse(str)
      }
      return str
    },
    clickImageCard() {
      this.sayDev("Click Image Card")
      if (this.replyAnnotation) {
        var action = this.replyAnnotation.second_action
        var value = this.tryParseJSON(this.replyAnnotation.second_value)
        this.sayDev("Value:")
        this.sayDev(value)
        if (action == 'link') {
          this.safeOpenLink(this.formatLink(value), true)
        } else if (action == 'video') {
          this.changeVideo(value.split("---")[1])
        } else if (action == 'jump') {
          this.changeTimeAndPlay(Number(value))
        } else if (action == 'download') {
          this.isDownloading = true
					this.finishDownloadFromUrl(value, value.split('/').pop().split('?')[0])
        } else if (action == 'cart') {
          this.addToCart(value);
        }
        this.hideAllDialogs();
      }

      // Link, Add To Cart, Download
      // if ()
      
    },
    setImageCloseCoords() {
      if (!this.viewImageDialog || !(this.imageAnnotation && this.imageAnnotation.content)) {
        return
      }
      this.sayDev("SetCloseCoords")
      var x = document.getElementById('theImage')
      var i = document.getElementById('skipImage')
      if (x && i) {
        var rect = x.getBoundingClientRect()
        i.style.top = rect.y - 30 + 'px'
        i.style.left = rect.x + rect.width - 30 +  'px'
        
        setTimeout(()=> {
          var x = document.getElementById('theImage')
          var i = document.getElementById('skipImage')
          if (x && i) {
            var rect = x.getBoundingClientRect()
            i.style.top = rect.y - 30 + 'px'
            i.style.left = rect.x + rect.width - 30 +  'px'
          }
          setTimeout(()=> {
            var x = document.getElementById('theImage')
            var i = document.getElementById('skipImage')
            if (x && i) {
              var rect = x.getBoundingClientRect()
              i.style.top = rect.y - 30 + 'px'
              i.style.left = rect.x + rect.width - 30 +  'px'
            }
          }, 300)
        }, 300)
      } else {
        setTimeout(()=> {
          this.setImageCloseCoords()
        }, 100)
      }
    },
    startAfkTimer() {
      if (this.share && this.share.use_afk && !this.ownsPost) {
        this.sayDev("⏰ Start AFK!: " + this.share.afk_amount)
        if (this.afkTimer) {
          clearTimeout(this.afkTimer)
        }
        this.afkTimer = setTimeout(()=> {
          this.showAfkDialog()
        }, this.share.afk_amount * 60000)
      }
    },
    showAfkDialog() {
      this.sayDev("ShowAFKDialog")
      if (!this.dialogShowing && !this.ownsPost) {
        this.pausePlayer("AFK")
        this.afkDialog = true
      }
    },
    // Prevent safari from auto showing native captions
    hideSafariCaptions() {
      setTimeout(()=> {
        if (this.isSafari || this.isIOS || this.isTouchDevice) {
          var ids = ['video-1_html5_api', 'video_dialog_video_html5_api', 'player-2_html5_api']
          ids.forEach((id)=> {
            var video = document.getElementById(id); // get the video element
            if (video) {
              this.sayDev("✅ Safari disable captions for player " + id)
              var i = 0
              while (i < video.textTracks.length) {
                this.sayDev(video.textTracks[i])
                if (true || video.textTracks[i].kind == 'forced') {
                  video.textTracks[i].mode = 'hidden'
                }
                i++
              }
            }
          })
        }
      }, 100)
    },
    doCaptionTranscript(language) {
      Rails.ajax({
        url: "/videos/"+this.share.id+"/capscript?label="+language,
        type: "POST",
        success: (data) => {
          this.$toasted.show("Done!")
          window.reload()
        },
        error: (data) => {
          this.$toasted.show('Something went wrong')
        }
      })
    },
    getLoomLink(id) {
      this.sayDev("GetLoom: " + id)
      fetch('https://www.loom.com/api/campaigns/sessions/' +id + '/transcoded-url', {
        method: 'POST',
        headers: {
            'authority': 'www.loom.com',
            'accept': 'application/json',
            'accept-language': 'en-US,en;q=0.9',
            'content-type': 'application/json',
            // 'cookie': 'AddYourCookieDataStringHere',
            'origin': 'https://www.loom.com',
            'referer': 'https://www.loom.com/share/'+id,
            'sec-ch-ua': '"Google Chrome";v="119", "Chromium";v="119", "Not?A_Brand";v="24"',
            'sec-ch-ua-mobile': '?0',
            'sec-ch-ua-platform': '"Windows"',
            'sec-fetch-dest': 'empty',
            'sec-fetch-mode': 'cors',
            'sec-fetch-site': 'same-origin',
            'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36',
            'x-loom-request-source': 'loom_web_a8fc659'
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // or response.text() if the data is in plain text
    })
    .then(data => {
        console.log("Success!")
        console.log(data.url); // Process your data here
        if (data.url && data.url.includes(".mp4")) {
          this.newVideo.og_link = data.url
          this.pasteLink = data.url
          this.sayDev("✅ Synthesia")
          this.vimeoKey = data.url
          this.video_type = 'MP4'
          this.setMP4Key()
          this.uploadProgress = 100
          this.newVideo.loom_id = id
          if (this.replacingVideo) {
            this.switchingVideo = true
            this.queueComplete()
          }
        }
    })
    .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
    });
    },
    submitSynthesiaToken(){
      Rails.ajax({
        url: "/synthesia",
        type: "POST",
        data: `token=${this.postsNewVars.synToken}`,
        success: (data) => {
          this.postsNewVars.synToken = "";
          this.currentUser.synthesia_connected = true
          this.fetchSynthesiaVideos()
        },
        error: (data) => {
          this.$toasted.show('Invalid Synthesia API Token. Please try again or contact support for help.')
          this.showToken = false;
        }
      })
    },
    submitHeygenToken(){
      Rails.ajax({
        url: "/heygen/connect",
        type: "POST",
        data: `token=${this.postsNewVars.heyToken}`,
        success: (data) => {
          this.postsNewVars.heyToken = "";
          this.currentUser.heygen_connected = true
          this.fetchHeygenVideos()
        },
        error: (data) => {
          this.$toasted.show('Invalid HeyGen API Token. Please try again or contact support for help.')
          this.showToken = false;
        }
      })
    },
    getTransitionName(ann) {
      var mm = !this.divDialog && ann.tray_type == 'resourceTray' && this.resourceTray.transition
      var ct = !this.divDialog && ann.tray_type == 'ctaTray' && this.shareDesign.ctaTray.transition
      var df = ann.transition || this.safeStyles.general.transition
      return mm || ct || df
    },
    toggleMenu(visible) {
      this.showMenu = visible
    },
    toggleCTA(visible) {
      this.showCTA = visible
    },
    setPUC(from) {
      this.sayDev("PUC from " + from)
      this.pausedUntilClick = true
    },
    toggleZapier(show) {
      this.showingZapier = show; 
      this.doSidebar(false);
    },
    getMultipleButtonSizing(i) {
      var choices = this.questionChoices
      var q = this.questionChoices[i]
      var len = q.text.length
      var vw = this.videoWidth
      var ss = Object.assign({}, this.safeStyles.button)
      ss.minWidth = '95% !important'
      ss.minHeight = this.isMobile ? 'none' : this.minAnswerHeight+'px !important'
      var fallback = { xs12: true, center: true, style: ss}


      if (this.shareDesign.break_answers || choices.length == 1) {
        return fallback
      }

      var full = this.bp.xs && this.maxAnswerLength > 40

      if (choices.length == 2) {
        return { xs12: full, sm6: true, center: full, left: !full && i % 2 == 1, right: !full && i % 2 != 1, style: ss}
      }

      if (choices.length == 3) {
        return { xs12: full, xs6: i < 2, left: i == 1, right: i == 0, center: i == 2, style: ss}
      }
      if (choices.length == 4) {
        return { xs12: full, xs6: !full, style: ss}
      }

      if (choices.length == 5) {
        var x = { xs12: full || i == 4, xs6: !full && i != 4, style: ss}
        return x
      } 
      
      // Select all only
      if (choices.length % 2  == 0) {
        return { xs12: full, xs6: !full, sm6: true, center: full, left: !full && i % 2 == 1, right: !full && i % 2 != 1, style: ss}
      }

      return fallback

    },
    startSelectAsset(index) {
      this.currentAnswer = index; 
      this.assetSelectDialog = true
      this.sayDev("SSA for " + index)
    },
    handleQuestionSaved(finished) {
      this.sayDev("HQS: " + finished)
      this.editingAnnotation = {}
      this.fetchInteractions()
      if (finished) {
        this.annotations = []
        this.questionCreationDialog = false
      } else {
        this.editingAnnotation = {}
      }
    },
    resetQuestionGPT() {
      this.questionGptDialog = false
      this.selectedGptQuestions = []
      this.gptQuestions = null
    },
    finishQuestionGPT() {
      var qs = []
      this.gptQuestions.forEach((q)=> {
        if (this.selectedGptQuestions.includes(q.id)) {
          qs.push(q)
        }
      })
      var data = new FormData()
			data.append('id', this.share.id)
			data.append('questions', JSON.stringify(qs))
			Rails.ajax({
				url: '/questions/gpt',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Created " + qs.length + " questions 🎉")
          this.resetQuestionGPT()
          this.fetchInteractions()
				},
				error: (e) =>{
					console.log(e)
				}
			})
    },
    handleOrientationChange() {
      this.drawSeekBounding("orientationChange");
      this.forceUpdate("OrientationChange")
      if (!this.questionAnnotation && this.played) {
        this.clearScreenInteractions()
        this.showStamps()
      }
    },
		searchVideoDrop(){
			this.all_vid_drop = []
			if (!this.vidDropDownSearch){
				this.searchingVideoDrop = false
				return
			}
			var url = '/posts/GetTrayVideos?q=' + this.vidDropDownSearch
			Rails.ajax({
				url: url,
				type: "GET",
				success: (data) => {
					this.all_vid_drop = data
					this.searchingVideoDrop = false
				},
				error: (e) => {
					this.$toasted.show('Could not load videos. Contact Support')
					this.searchingVideoDrop = false
				}
			});
		},
		searchCollectionDrop(){
			this.all_viall_collection_dropd_drop = []
			if (!this.collectionDropDownSearch){
				this.searchingCollectionDrop = false
				return
			}
			var url = '/posts/GetGroupSeries?q=' + this.collectionDropDownSearch
			Rails.ajax({
				url: url,
				type: "GET",
				success: (data) => {
					this.all_collection_drop = data
					this.searchingCollectionDrop = false
				},
				error: (e) => {
					this.$toasted.show('Could not load groups or series. Contact Support')
					this.searchingCollectionDrop = false
				}
			});
		},
    createTranscript() {
      if (this.ownsPost && this.share && !this.hasTranscript) {
        if (gon.sourceType == 'HLS') {
          alert("Cannot transcribe this HLS video. Transcription only available for MP4 links or videos uploaded directly to Mindstamp.")
          return
        }
        this.sayDev("Creating transcript...")
        Rails.ajax({
          url: '/transcribe/'+this.share.id,
          type: "POST",
          success: (data) => {
            this.$toasted.show("Transcript started! Estimated time to finish: " + Math.max(4, Math.floor(this.share.duration / 60)) + " minutes ")
            this.hasTranscript = true
            this.startTranscriptCheck();            

          },
          error: (e) => {
            if (this.currentUser){
              this.$toasted.show(`Could not create transcript`)
            }
          },
        })
      }
    },  
    startTranscriptCheck() {
      setTimeout(()=> {
        this.sayDev("Fetch Transcript 1...")
        this.fetchTranscript()
      }, 5000)
      setTimeout(()=> {
        this.sayDev("Fetch Transcript 2...")
        this.fetchTranscript()
      }, 20000)

      setTimeout(()=> {
        this.sayDev("Fetch Transcript 3...")
        this.fetchTranscript()
      }, 40000)

      setTimeout(()=> {
        this.sayDev("Fetch Transcript 4...")
        this.fetchTranscript()
      }, 60000)

      setTimeout(()=> {
        this.sayDev("Fetch Transcript 5...")
        this.fetchTranscript()
      }, 90000)

      setTimeout(()=> {
        this.sayDev("Fetch Transcript 6...")
        this.fetchTranscript()
      }, 120000)
    },
    toggleTray() {
      this.introDialog = false;
      this.sayDev("ToggleTray")
      if (this.activeSideItem == 'magic menu') {
        if (this.showMenu) { 
          this.closeMenu()
          setTimeout(()=> {
            this.toggleMenu(true)
          }, 500)
        } 
        else (this.showResourceTray())
      } else{
        if (this.showCTA) { 
          this.closeCtaTray()
          setTimeout(()=> {
            this.showCtaTray()
          }, 500)
        } 
        else (this.showCtaTray())
      }
    },
    doHelpSideItem(string) {
      var title = string.split("--")[0]
      var sub = string.split("--")[1]
      this.setSideItemTitle(title)
      this.sayDev("doHelpSide: " + string)
      if (string == 'share') {
        this.showMoreInfo = true
      } else if (string == 'numbercorrectness') {
        this.designTab = 6
      } else if (string == 'thumbnail' || string == 'controls') {
        this.designTab = 8
        this.sayDev("DT is now " + 8)
      } else if (sub == 'font') {
        this.designTab = 1
      } 
    },
    doAutoSave() {
			if (this.share.token == 'uQHkJdMEZwJO'){
				this.sayDev("❌ Crisp Status No autosave!")
				return
			}
      this.sayDev("Do Autosave!")
      if (this.blockAutoSave) {
        this.sayDev("❌ No autosave!")
      }
      if (this.saveTimer) {
        clearTimeout(this.saveTimer)
        this.saveTimer = null
      }
      this.saveTimer = setTimeout(()=> {
        this.updateShare()
      }, 1000)
    },
    doSidebar(show) {
      if (this.sidebarTimer) {
        // this.sayDev("Block")
        $('#sideMenu').animate({ width:'40px' }, 200, ()=>{ 
          this.showSidebarTitles = false
        })
        return
      }
      this.sidebarTimer = setTimeout(()=> {
        this.sidebarTimer = null
      }, 100)
      if (!this.showingZapier && (show || (!this.showSplitColumns && this.screenWidth >= 1200 && !['analytics', 'genie ai'].includes(this.activeSideItem)))) {
        $('#sideMenu').animate({ width:'150px' }, 200, ()=>{ 
          this.showSidebarTitles = true
        });
      } else {
        $('#sideMenu').animate({ width:'40px' }, 200, ()=>{ 
          this.showSidebarTitles = false
        })
      }
    },
    setSideItemTitle(str) {
      this.clickSideItem({title: str})
    },
    handleNewFont(font) {
      this.sayDev("Handle new font..." + font)
      this.freshFont = font
      document.getElementById('')
    },
    getSideItemStyle(item) {
      var isActive = this.activeSideItem == item.title.toLowerCase()
      if (isActive) { 
        return {
          // borderLeft: "1px solid rgba(0,0,0,0.1)",
          // boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
          // transform: 'scale(1.05)',
          // marginLeft: '2px',
          fontWeight: "bold",
          background: this.color,
          color: "white"

        }
      }
      return {}
    },
		doSpoof(id, spoof){
			this.setLocalStorageItem('spoof_from', encodeURI(window.location.href))
			window.location.href= `/donkey/${id}/${!spoof ? 'un' : ''}spoof`
		},
    clickSideItem(item) {
      if (!this.ownsPost) {
        return
      }
      if (!this.played) {
        this.playPlayer("ClickSideItem");
        this.pausePlayer("CSI")
      }
			if (item.title == 'Spoof'){
				this.doSpoof(this.currentShare.id, true)
			}
			if (this.share.token == 'uQHkJdMEZwJO'){
				this.sayDev('Crisp Status')
				return
			}
      // Check current is settings, save if so
      var oldItem = this.activeSideItem
      this.publishDialog = false
      // var shouldAuto = ['settings', 'integrations', 'lead capture', 'genie ai']
      // if (shouldAuto.includes(this.activeSideItem)) {
      //   this.updateShare()
      // }
      this.sayDev("CSI", item)
      this.showingZapier = false
      this.activeSideItem = item.title.toLowerCase()
      this.activeSideIcon = item.icon
      this.sayDev("Click Side Item: " + this.activeSideItem)
      if (this.showCTA) {
        this.closeCtaTray()
      }
      if (this.showMenu) {
        this.closeMenu()
      }
      switch(this.activeSideItem) {
        case 'end screen':
          // this.closeCtaTray()
          this.showCtaTray()
          break;
        case 'share':
          // this.closeCtaTray()
          if (oldItem != 'share') {
            this.qrCode = false
          }
          this.startSharing(this.share)
          break;
        case 'chapters':
          // this.closeCtaTray()
          this.pausePlayer("CSI2")
          break;
        case 'magic menu':
          // this.closeMenu()
          this.introDialog = false
          this.showResourceTray()
          break;
        case 'help':
          this.loadCrispChat()
      }
      if (!['interactions', 'end screen', 'magic menu', 'transcript', 'chapters'].includes(this.activeSideItem)) {
        this.sayDev("Full! Pause player....")
        this.pausePlayer("CSI3")
      } else {
        // setTimeout(() => {
        //   this.drawSeekBounding()
        // }, 250);
      }
      if(this.ownsPost && (!this.assets || !this.assets.videos)){
				this.refreshAssets(false, false)
			}
      this.doSidebar(false)
      setTimeout(() => {
          this.drawSeekBounding("SideItem")
        }, 500);
    },
		displayPasswords(pass){
			var ret = ''
			for(var i = 0; i < pass.length; i++){
				if (i > 0 && pass.length > 2) {
					ret += ', '
				}
				if (i == pass.length -1 && pass.length > 1){
					ret += ' or '
				} 
				ret += pass[i]
			}
			return ret
		},
    getWistiaAssets(wistia_id) {
      // https://fast.wistia.com/embed/medias/dcc1fmw8wv
      this.sayDev("Get Wistia Assets: " + wistia_id)
        $.ajax({
          type: "GET",
          url: "https://fast.wistia.net/embed/medias/"+wistia_id+".json",
          dataType:"json",
          success: (data)=> {
            try {
              console.log(data)
              var good = ''
              var better = ''
              var best = ''
              data.media.assets.forEach((a)=> {
                console.log(a.display_name)
                if (a.display_name == '720p') {
                  good = a.url.replace(".bin", '.m3u8')
                } else if (a.display_name == 'Original File') {
                  better = a.url.replace(".bin", '.m3u8')
                } else if (a.display_name == '1080p') {
                  best = a.url.replace(".bin", '.m3u8')
                }
              })
              console.log(good)
              console.log(better)
              console.log(best)
              this.video_type = 'HLS'
              this.video_key = best || better || good
              this.wistiaKey = this.wistiaVidId(this.pasteLink)
              this.sayDev("Key is: " + this.wistiaKey)
              this.uploadProgress = 100
              if (this.replacingVideo) {
                this.switchingVideo = true
                this.queueComplete()
              }
            } catch (error) {
              console.log("CAUGHT ERROR......")
              alert("Could not import video from Wistia. This is usually a domain whitlisting issue - please ensure that Mindstamp.com is on your domain whitelist. Contact support if you need help with this!")
            }
          }, error: (e)=> {
            console.log("Error")
            console.log(e)
          }
        });
    },
		updateConnector(){
			if(this.cardSelectDialog){
				if (this.activeCard.auth){
					var token = this.externalTokens.filter(x=> x.source == this.activeCard.auth)[0]
					this.activeCard.click_value = token.url
					this.activeCard.body = token.body
				} else {
					this.activeCard.click_value = ''
					this.activeCard.body = ''
				}
			} else if (this.divDialog){
				if (this.activeDiv.auth){
					var token = this.externalTokens.filter(x=> x.source == this.activeDiv.auth)[0]
					this.activeDiv.value = token.url
					this.activeDiv.body = token.body
				} else {
					this.activeDiv.value = ''
					this.activeDiv.body = ''
				}
			} else if(this.navDialog) {
				if (this.activeNav.auth){
					var token = this.externalTokens.filter(x=> x.source == this.activeNav.auth)[0]
					this.activeNav.value = token.url
					this.activeNav.body = token.body
				} else {
					this.activeNav.value = ''
					this.activeNav.body = ''
				}
			}
			this.updatedConnector++
		},
    promptSharing() {
      var last = this.getLocalStorageItem("share_prompt")
      this.sayDev("Last: " + last)
      if (!last || last < new Date().getTime()) {
        this.sayDev("Never shown, or is over a week old, do again")
        this.setLocalStorageItem("share_prompt", new Date().getTime() + 604800000)
        setTimeout(()=> {
          this.adShareDialog = true
        }, 8000)
      } else {
        this.sayDev("Time is currently" + new Date().getTime() + " but need to wait until " + last)
      }
    },
    getGptFontStyle(answer) {
      var x = {
        fontSize: this.isMobile ? '12px' : '16px',
        lineHeight: this.isMobile ? '16px' : '22px',
        color: 'white',
        textAlign: this.isMobile  || answer.length < 80? 'center' : 'left'
      }
      console.log(x)
      return x
    },
		closeImageSelectDialog(){
			this.imageSelectDialog = false
			this.stockAsset = null
			this.getMoreStockAssets = true
			this.stockSearch = null
			this.selectedStockType = 0
		},
		selectImageType(i){
			this.selectedStockType = i
			this.stockAssets = null
			this.getMoreStockAssets = true
		},
		getImageTitleStyle(i){
			var st = ''
			if (this.selectedStockType == i){
				st += 'background-color: rgba(40,40,118); color: white'
			}
			return st
		},
    startTour() {
      return
      var that = this
      this.hideAllDialogs()
      this.helpDialog = false
      this.cancelDiv();
      setTimeout(()=> {
        introJs().onbeforechange(function(el) {
          var index = this._currentStep
          console.log("Step: " + index)
          var step = this._options.steps[index]
          if (!step) {
            this.exit()
            return;
          }
          var donext = !this.annotations || this.annotations.length == 0
          if (step.elId == '#chapters_button') {
            that.showMoreInfo = true
            donext = true
          } else if (step.elId == '#design_button') {
            console.log("Design, cancel!")
            that.divDialog = false
            donext = true
          } else if (step.ck == 1) {
            if (that.annotations[0]) {
              that.editAnnotation(that.annotations[0])
            } else {
              that.changeTime(1)
              that.addInteraction('button')
              that.doPlaceInteraction()
              that.activeDiv.content = 'Open Website'
              that.activeDiv.action = "link"
              that.activeDiv.value = "https://mindstamp.com"
              setTimeout(()=> {
                that.activeDiv.content = 'Open Website'
                that.activeDiv.action = "link"
                setTimeout(()=> {
                  that.activeDiv.value = "https://mindstamp.com"
                }, 300)
                console.log("didset", that.activeDiv)
              }, 1000)
            }
            donext = true
          }
          if (donext && step.elId) {
            setTimeout(()=> {
              this._options.steps.forEach((x,i)=> {
                if (true || i >= index) {
                  if (document.querySelector(x.elId)) {
                    console.log("Set " + x.elId + " is currently ", this._introItems[i].element)
                    this._introItems[i].element = document.querySelector(x.elId);
                    this._introItems[i].position = x.position ? x.position : 'bottom';
                    this.refresh()
                  } else {
                    console.log("💔 Can't find " + x.elId)
                    setTimeout(()=> {
                      console.log("Later...", document.querySelector(x.elId))
                    }, 100)
                  }
                }
              })
            },100)
          }
  
          // if (step.elId && !step.element) {
          //   that.sayDev("Searching..." + step.elId)
          //   if (step.elId == '#times') {
          //     that.editAnnotation(that.annotations[0])
          //   }
          //   setTimeout(()=> {
          //     this._introItems[index].element = document.querySelector(step.elId);
          //     this._introItems[index].position = step.position ? step.position : 'bottom';
          //     this.refresh()
          //   }, 200)
          // }
  
        }).setOptions({
          disableInteraction: true,
          hidePrev: true,
          showBullets: false,
          showProgress: true,
          scrollToElement: false,
          overlayOpacity: 0.7,
          buttonClass: "v-btn theme--light secondary",
          // showStepNumbers: true,
          tooltipClass: "usefont",
          steps: 
          [
            {
              title: "Welcome!",
              intro: "This is the Mindstamp editor. Let's take a quick look around.",
            },
            {
              element: document.querySelector('#actionbar'),
              title: null,
              elId: '#actionbar',
              title: "Interaction Bar",
              position: "top",
              intro: "Each button here adds a different interaction. Click one while the video is playing to add it at that time.",
            },
            {
              element: document.querySelector('#anns_list'),
              elId: '#anns_list',
              title: "Interaction List",
              intro: "Interactions will show up here as you add them. You can see we've added a Button here that opens a link to Mindstamp.com when clicked.",
            },
            {
              ck: 1,
              element: document.querySelector('#showWrapper'),
              elId: '#showWrapper',
              title: "Let's edit the button",
              intro: "Most interactions on Mindstamp share this same editing interface. Let's take a look!",
            },
            {
              element: document.querySelector('#times'),
              elId: '#times',
              intro: "You can adjust the show and hide time of the button here. You can also make the button pause when it shows up. This button shows from 0:01 to 0:11",
              position: 'right'
            },
            {
              element: document.querySelector('#clickactionwrapper'),
              elId: '#clickactionwrapper',
              intro: "Each interaction has a Click Action, such as opening a link, switching video, changing time, and more which is set here",
              position: 'right'
            },
  
            {
              element: document.querySelector('#advanced'),
              elId: '#advanced',
              intro: "Change an interaction's individual appearance and behavior with these styling and settings. This includes colors, animations, conditional logic, variables, post-click state, click required, and more.",
              position: 'right'
            },
  
            {
              element: document.querySelector('#drag'),
              elId: '#drag',
              intro: "The button can be dragged and resized on screen to place it where you want",
              position: 'right'
            },
            {
              element: document.querySelector('#bottomprecise'),
              elId: '#bottomprecise',
              intro: "You can also use precise coordinates to set it exactly as you want it. ",
              position: 'top'
            },
            // {
            //   title: "Great job!",
            //   intro: "Now let's check out some more capabilities",
            //   position: 'top'
            // },
            {
              // element: document.querySelector('#design_button'),
              elId: '#design_button',
              intro: "Styles for this video, such as colors, font, player controls, and branding can be edited under the Design tab",
              position: 'right'
            },
            {
              // element: document.querySelector('#settings_button'),
              elId: '#settings_button',
              intro: "Adjust settings such as lead capture, security & access, monetization, and more",
              position: 'right'
            },
            {
              // element: document.querySelector('#previewButton'),
              elId: '#previewButton',
              intro: "To see how the video looks for your viewers, use Preview Mode here",
              position: 'right'
            },
            {
              // element: document.querySelector('#chapters_button'),
              elId: '#chapters_button',
              intro: "Add chapters to your video here",
              position: 'right'
            },
            {
              // element: document.querySelector('#captions_button'),
              elId: '#captions_button',
              intro: "Add closed-captions to your video here in multiple languages",
              position: 'right'
            },
            {
              // element: document.querySelector('#integrations_button'),
              elId: '#integrations_button',
              intro: "Integrate your data via HubSpot, Zapier, or Webhooks here",
              position: 'right'
            },
            {
              element: document.querySelector('#replace_button'),
              elId: '#replace_button',
              intro: "Need to upload a new version of the video? Replace it here while keeping interactions and data intact",
              position: 'right'
            },
            {
              element: document.querySelector('#import_button'),
              elId: '#import_button',
              intro: "Import recently-created interactions or interactions from another video",
              position: 'right'
            },
            {
              // element: document.querySelector('#magic_cta'),
              elId: '#magic_cta',
              intro: "Create a Magic Menu for this video and edit the End Call-To-Action",
              position: 'right'
            },
            {
              // element: document.querySelector('#publishButton'),
              elId: '#publishButton',
              intro: "When you're ready to share, publish the video to make it accessible to viewers",
              position: 'right'
            },
            {
              // element: document.querySelector('#sharing_button'),
              elId: '#sharing_button',
              intro: "Share your video via a direct link, or copy an embed code to use it in your own website",
              position: 'right'
            },
            {
              // element: document.querySelector('#sharing_button'),
              elId: '#help_button',
              intro: "Find quick answers for common questions about the Mindstamp editor",
              position: 'right'
            },
            {
              // element: document.querySelector('#report_button'),
              elId: '#report_button',
              intro: "Once you've shared the video, view and interaction data will show up here in the report section",
              position: 'right'
            },
            {
              element: document.querySelector('#new_button'),
              elId: '#new_button',
              intro: "Create another video by clicking here. You can upload a video, or use a video from Vimeo, Wistia, JWPlayer, and elsewhere.",
              position: 'bottom'
            },
            {
              element: document.querySelector('#org_button'),
              elId: '#org_button',
              intro: "Invite team members to collaborate with you within your Organization",
              position: 'bottom'
            },
            {
              element: document.querySelector('#support_button'),
              elId: '#support_button',
              intro: "Browse FAQs, see recent enhancements, and get support",
              position: 'bottom'
            },
            {
              element: document.querySelector('#more_button'),
              elId: '#more_button',
              intro: "Manage your account, billing, assets, and more",
              position: 'bottom'
            },
            {
              title: "You're ready to rock!",
              intro: "That's it for the tour! If you have questions, check out the help guide, or contact support. We're here to help!",
              position: 'bottom'
            },
          ]
        }).start()
      }, 100)
    },
    preloadSwitches() {
      this.sayDev("Preload switches: " + this.annotations.length)
      var tokens = []
      if (this.variables.gostamp) {
        this.sayDev("Skip preload")
        return
      }
      var i = 0;
      this.annotations.forEach((a)=> {
      var t = ''
      if (a.type_of == 'switch') {
        t = JSON.parse(a.data).value.split("---")[1]
        this.sayDev("---- Nav switch at " + a.exact_time +" to " + t)
      } else if (a.type_of == 'video') {
        setTimeout(()=> {
          this.sayDev("Preload clip " + a.id)
          this.getStreamingLinks('video', a.content, (data) => {
            this.earlySwitches[a.content] = data
          })
        }, i*1000)
        i++
      } else if (a.type_of == 'audio') {
        setTimeout(()=> {
          this.sayDev("Preload audio " + a.id)
          this.getStreamingLinks('audio', a.content, (data) => {
            this.earlySwitches[a.content] = data
          })
        }, i*1000)
        i++
      } else if (a.type_of == 'question') {
        var c = JSON.parse(a.content.answers)
        if (c) {
          c.forEach((x)=> {
            if (x.action == 'video') {
              if (!tokens.includes(x.value)) {
                this.sayDev("Question switch to " + x.value.split("---")[1])
                tokens.push(x.value.split("---")[1])
              }
            }
          })
        }
      } else if (a.data) {
        try {
          var x = JSON.parse(a.data)
          if (x.action == 'video') {
            t = x.value.split("---")[1]
            this.sayDev("---- " + a.type_of + " click switch at " + a.exact_time + " to " + t)
          }
        } catch (e) {
          this.sayDev("Error json parsing..")
        }
      }
        if (t && !tokens.includes(t)) {
          tokens.push(t)
        }
      })
      tokens.forEach((t,i)=> {
        setTimeout(()=> {
          // this.sayDev("Preload token " + t)
          Rails.ajax({
            url: `/w/${t}/info?` + this.getFullInteractionParams(),
            type: "GET",
            dataType: "JSON",
            success: (data) => {
              this.earlySwitches[t] = data
            }, error: (data) => {
              this.sayDev("Could not preload video " + t)
            }
          })
        }, i*500)
      })
    },
    setDurSize(args) {
      this.sayDev("Set Duration and Size: " + args.duration + " " + args.size)
      this.newVideo.duration = Math.floor(args.duration)
      this.newVideo.size = Math.floor(args.size)
    },
		getQuickStats(){
			if(!this.ownsPost){
				return 
			}
			Rails.ajax({
				url: '/get_stats/' + this.currentShare.token,
				type: "GET",
				dataType: "JSON",
				success: (data) => {
					this.sayDev("📊 Got Video Stats")
					this.stats = data
				},
				error: (e) => {
					this.$toasted.show('Could not get data. Contact Support')
				}
			})
		},
    resetViewerState() {
      this.sayDev("👽👽👽👽👽👽 Reset Viewer State 👽👽👽👽👽👽")
      this.userInteractions = []
      this.correctCount = 0
      this.incorrectCount = 0
      this.variables.cscore = 0
      this.variables.pscore = 0
      // console.log(this.variables)
      // this.skipAuth = false
      this.annotations.forEach((a)=> {
        a.clicked = false
        a.disabled = false
        a.invisible = false
      })
      this.fetchInteractions()
    },
    doNewCard(){
      this.activeCard.id = ""
      this.cardSelectStep = 1
      this.activeCard.title.value = ""
      this.activeCard.button.value = ""
      this.activeCard.text.value = ""
      this.activeCard.image = ""
      this.activeCard.name = ""
    },
		editCard(step){
      this.sayDev("EditCard: " + step)
      this.sayDev("ADC: " + this.adc)
      this.sayDev(this.activeDiv)
			if (step == 2){
				if (this.adc){
					var x = this.activeDiv
					this.suppressCardWatcher = true
					this.activeCard.click_action = x.action
					this.activeCard.click_value = x.value
					if (['get', 'post'].includes(x.action)){
						this.activeCard.auth = x.auth
						this.activeCard.body = x.body
						this.activeCard.field = x.field
						this.activeCard.head = x.head
						this.activeCard.httpVar = x.httpVar
					}
				} 
				this.cardSelectStep = step
				this.cardSelectDialog = true
			} else {
        this.sayDev("No ADC")
        if (step == 0) {
          this.cardSelectDialog = true
        }
      }
			setTimeout(() => {
				this.forceUpdate('editCard')
				this.suppressCardWatcher = false
			},500) 
		},
		finishCardBuilder(){
			var valid = this.validateActiveCard()
			if (valid != true) {
				this.$toasted.show(valid || "Please fill out required fields")
				return
			}
			this.suppressCardWatcher = true
			this.cardSelectDialog = false
			this.cardSelectStep = -1
			if (this.showCardAction){
				if (this.divDialog){
					this.activeDiv.value = JSON.stringify(this.activeCard)
				} else if (this.navDialog){
					this.activeNav.value = JSON.stringify(this.activeCard)
				}
			} 
			if (this.adc){
				this.activeDiv.action = this.activeCard.click_action
				if(['get','post'].includes(this.activeCard.click_action)){
					this.activeDiv.auth = this.activeCard.auth
					this.activeDiv.body = this.activeCard.body
					this.activeDiv.field = this.activeCard.field
					this.activeDiv.head = this.activeCard.head
					this.activeDiv.httpVar = this.activeCard.httpVar
				}
				setTimeout(() => {
					this.activeDiv.value = this.activeCard.click_value
					this.updatedConnector++
				},100) 
			}
			setTimeout(() => {
				this.suppressCardWatcher = false
			},500) 
		},
		validateActiveCard(){
			var ac = this.activeCard
			if(!ac.name || !ac.name.length){
				this.cardSelectStep = 2
				return 'A card name is required'
			}

			if (!ac.click_action) {
				this.flashFocusField('activeDivAction')
				return false
			} 
			if (!ac.click_value && ac.click_action == 'jump'){
				ac.click_value = 0
			}
			if (ac.click_action == 'cart' && !this.currentUser.shopify_connected) {
				this.flashFocusField('activeCardValue')
				return "Please connect to Shopify"
			}
			if (ac.click_action == 'openai' && !this.currentUser.openai_connected) {
				this.flashFocusField('activeCardValue')
				return "Please connect to OpenAI"
			}
			if (!['genie', 'track', 'pause', 'forward', 'rewind', 'none', 'resourceTray', 'back'].includes(ac.click_action) && !ac.click_value && ac.click_value != '0') {
				this.flashFocusField('activeCardValue')
				return false
			}
			if (['download'].includes(ac.click_action) && (!this.assetDownload[0].downloadName || !this.proUpUser)) {
				this.flashFocusField('activeCardValue')
				return false
			}
			if (ac.click_action == 'link' && !this.isValidUrl(ac.click_value)) {
				this.flashFocusField('activeCardValue')
				return false
			}
			return true
		},
		updateCardTemplate(i){
			this.activeCard.layout = i
			if (!this.adc){
				return
			}
			if (i < 4){
				this.coords.width='28'
				this.coords.height='63'
				this.setInteract(0.8)
			} else {
				this.coords.width='40'
				this.coords.height='52'
				this.setInteract(1.3)
			}
			var l = parseFloat(this.coords.left)
			var t = parseFloat(this.coords.top)
			var h = parseFloat(this.coords.height)
			var w = parseFloat(this.coords.width)
			if (l + w > 100){
				this.coords.left = (100 - w).toString()
			}
			if (t + h> 100){
				this.coords.top = (100 - h).toString()
			}
			this.doCoords()
			this.cardUpdated++
		},
    goPersonal() {
      window.location.href = window.location.href+"/personalize"
    },
		undoRedoAction(annotation, type){
			var url = `/annotations/undo_redo/${annotation.id}`
			Rails.ajax({
				url: url,
				type: "POST",
				success: (data) => {
					annotation.undo_redo = type * -1 // can undo = 1; can redo = -1
					this.fetchInteractions()
				},
				error: (e) => {
					if (this.currentUser){
            this.$toasted.show(`Could not ${type == 1 ? 'undo' : 'redo'}`)
					}
				},
			})
		},
		
		updateVideoShopifyStore(){
			var data = new FormData()
			data.append('store', this.currentShare.shopify_site)
			Rails.ajax({
				url: '/set_shopify/' + this.currentShare.id,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.share.shopify_site = this.currentShare.shopify_site
					// this.forceUpdate('updateVideoShopifyStore')
				},
				error: (data) =>{
					this.currentShare.shopify_site = ''
				}
			})
		},
		getShopifySession(){
			if (this.shopifySession){
				return
			}
			this.loadShopifyScripts()
			setTimeout(() => {
				Rails.ajax({
					url: '/shopify?token='  + this.currentShare.token,
					type: "GET",
					success: (data) => {
						if (data){
							this.shopifySession = ShopifyBuy.buildClient({
								domain: data.site,
								storefrontAccessToken: data.token
							});
							this.shopifySession.checkout.create().then((checkout) => {
								this.checkoutSession = checkout.id
								this.checkoutUrl = checkout.webUrl
								this.variables["checkout_url"] = this.checkoutUrl
							});
						}
					},
					error: (e) => {
						if (this.currentUser){
							this.$toasted.show("You are not connected to Shopify")
						}
					},
				})
			},this.scriptsLoaded.shopify ? '' : '2000') 
		},
		askChatGPT(prompt, variable){
			if (this.ownsPost) {
				this.$toasted.show("Skipping ChatGPT request as owner. Use Preview Mode to send this reqeust.")
				return
			}
			var data = new FormData()
			data.append('prompt', prompt)
			data.append('token', this.currentShare.token)
			Rails.ajax({
				url: '/annotations/openai',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
		      this.setVariable(variable, data)
				}
			})
		},
		sendConnectorRequest(x,type){
			if (this.ownsPost) {
				this.$toasted.show("Skipping Connector request as owner. Use Preview Mode to send this reqeust.")
				return
			}
			var data = new FormData()
			data.append('url', x.url)
			data.append('source', x.auth)
			data.append('field', x.field)
			data.append('body', x.body)
			data.append('head', x.head)
			data.append('token', this.currentShare.token)
			data.append('type', type)
			Rails.ajax({
        url: "/annotations/connector_click_action",
        type: "POST",
        data: data,
        dataType: "JSON",
        success: (data) => {
					this.setVariable(x.httpVar, data)
					this.sayDev('🧑🏼‍💻 Connector Get Message')
        },
        error: (e) => {
        },
      })
		},
		httpGetFields(x){
			var data = new FormData()
			data.append('url', x.value || x.click_value)
			data.append('source', x.auth)
			data.append('body', x.body)
			data.append('head', x.head)
			data.append('token', this.currentShare.token)
			data.append('type', x.action || x.click_action)
			Rails.ajax({
        url: "/annotations/get_connector_fields",
        type: "POST",
        data: data,
        dataType: "JSON",
        success: (data) => {
					this.httpResponseData = JSON.stringify(data.response, null, 2)
					this.httpFields = data.fields
					if (this.scriptsLoaded.html) {
						this.sayDev("Already loaded HTML")
					} else {
						this.sayDev("Do load HTML")
						this.loadHtmlScripts()
						setTimeout(()=> {
							this.scriptsLoaded.html = true
						}, 5000)
					}
        },
        error: (e) => {
					this.$toasted.show('Unsuccessful API call')
        },
      })
		},
		updateHttpReponseField(text){
			if (!text || !this.httpFields){
				return
			}
			var x
		 if (this.cardSelectDialog){
			x = this.activeCard
		 } else if (this.divDialog){
				x = this.activeDiv
			} else {
				x = this.activeNav
			}
			x.field = this.httpFields[text.line - 1]
			this.$forceUpdate('updateHttpReponseField')
		},
		addVariantToCart(){
			var title = this.selectedVariant.join(' / ')
			for(var i = 0; i < this.selectedProduct.variants.length; i++){
				if (this.selectedProduct.variants[i].title == title){
					this.waitingAction.value.variant = this.selectedProduct.variants[i]
					this.clickData(this.waitingAction, this.waitingAction.parent)
					this.closeVariants()
					return
				}
			}
		},
		addToCart(item){
      this.sayDev("Add To Cart")
      console.log(item)
			if (item.variant.id){
				try{
				var entry = [{variantId: item.variant.id, quantity: 1}]
				this.shopifySession.checkout.addLineItems(this.checkoutSession, entry).then((checkout) => {
					var found = false
					for (var i = 0; i < this.shoppingCart.length; i++){
						if (this.shoppingCart[i].item.variant.id == item.variant.id){
							this.shoppingCart[i].quantity++
							this.shoppingCart[i].checkoutId.push(checkout.lineItems[0].id)
							found = true
							break
						}
					}
					if (!found){
						this.shoppingCart.push({item: item, quantity: 1, checkoutId: [checkout.lineItems[0].id]})
					}
					this.shoppingCartCount++
					var el = document.getElementById('shoppingCartButton')
					if(el){
						el.classList.add('addCart')
						setTimeout(() => {
							el.classList.remove('addCart')
						},500) 
					}
				});
				} catch (e) {
          console.log(e)
					setTimeout(() => {
						this.addToCart(item)
					},1000) 
				}
			} else {
				this.showVariants()
				this.getProducts(item)
			}
		},
		removeFromCart(item){
			var line = null
			for (var i = 0; i < this.shoppingCart.length; i++){
				if (this.shoppingCart[i].item.variant.id == item.variant.id){
					var id = this.shoppingCart[i].checkoutId[0]
					var qty = this.shoppingCart[i].quantity - 1
					line = [{id: id, quantity: qty}];
					this.shoppingCart[i].quantity = qty
					this.shoppingCart[i].checkoutId.shift()
					if (this.shoppingCart[i].checkoutId.length == 0){
						this.shoppingCart.splice(i,1)
					}
					break
				}
			}
			if (line){
				this.shopifySession.checkout.updateLineItems(this.checkoutSession, line).then((checkout) => {
					this.shoppingCartCount--
				})
			}
		},
		selectProduct(i){
			if(this.products[i].variants.length == 1){
				this.productSearch = ''
        if (this.activeDiv.second_action == 'cart') {
          this.activeDiv.second_value = {variant: this.products[i].variants[0], product: this.products[i].title}
        } else {
          this.activeDiv.value = {variant: this.products[i].variants[0], product: this.products[i].title}
        }
				this.productsDialog = false
				return
			}
			this.selectedProduct = this.products[i] 
			this.variants = {}
			this.selectedVariant = []
			this.selectedProduct.variants.forEach(v => {
				v.selectedOptions.forEach(x => {
					if (this.variants[x.name]){
						if (!this.variants[x.name].includes(x.value)){
							this.variants[x.name].push(x.value)
						}
					} else {
						this.variants[x.name] = [x.value]
						this.selectedVariant.push('')
					}
				})
			})
		},
		selectVariant(selected){
			this.productSearch = ''
			if (selected){
				var title = this.selectedVariant.join(' / ')
				for(var i = 0; i < this.selectedProduct.variants.length; i++){
					if (this.selectedProduct.variants[i].title == title){
						this.activeDiv.value = {variant: this.selectedProduct.variants[i], product: this.selectedProduct.title}
						break
					}
				}
			} else {
				this.activeDiv.value = {
					variant: {
						image: this.selectedProduct.variants[0].image,
						product: this.selectedProduct.id,
						title: "Viewer's Choice",
					}, 
					product: this.selectedProduct.title
				}
			}
			if (this.cardSelectDialog) {
				this.activeCard.click_action = 'cart'
				this.activeCard.click_value = this.activeDiv.value
				if(this.cardSelectStep == 1){
					this.activeCard.title.value = this.selectedProduct.title
					this.activeCard.text.value = this.selectedProduct.description
					this.activeCard.image = this.selectedProduct.images[0].src
					this.activeCard.button.value = 'Buy Now!'
					this.suppressCardWatcher = true
					setTimeout(() => {
						this.suppressCardWatcher = false
					},500) 
				}
			}
			this.productsDialog = false
			return
		},
		getProducts(item = 0){
			if (item == 0){
				this.selectedProduct = ''
				this.selectedVariant = []
				this.productsDialog = true
				if (this.products.length){
					return
				}
			}
			if (!this.shopifySession || this.products.length == 0){
				if(!this.shopifySession){
					this.getShopifySession()
				}
				setTimeout(() => {
					if (this.shopifySession){
						this.shopifySession.product.fetchAll().then(products => {
							this.products = products
							if (item != 0 && item != 1){
								this.findProduct(item)
							}
						});
					}
				},2500) 
			} else {
				if (item != 0 && item != 1){
					this.findProduct(item)
				}
			}
		},
		findProduct(item){
			for (var i = 0; i < this.products.length; i++){
				if (this.products[i].id == item.variant.product){
					this.selectProduct(i)
					break
				}
			}
		},
    doHelp() {
      this.clickSideItem({title: 'Help'})
      this.flashFocusField('help_search', 2000, true, false)
    },
		addLeadsEmail() {
      if (!this.isValidEmail(this.newLeadsEmail)){
        this.$toasted.show("Invalid Email Address")
				return
      } else if(this.currentShare.leads_address.includes(this.newLeadsEmail)) {
        this.$toasted.show("Duplicate Email Address")
				return
      } else {
        this.currentShare.leads_address.push(this.newLeadsEmail)
        this.newLeadsEmail = ''
      }
    },
		removeLeadsEmail(em){
      this.currentShare.leads_address = this.currentShare.leads_address.filter(e => em != e)
		},
		addVideoPassword() {
      if(this.currentShare.password && this.currentShare.password.includes(this.newVideoPassword)) {
        this.$toasted.show("Duplicate Password")
				return
      } else if(this.newVideoPassword && this.newVideoPassword.length > 0) {
				if (this.currentShare.password){
					this.currentShare.password.push(this.newVideoPassword)
				} else {
					this.currentShare.password = [this.newVideoPassword]
				}
        this.newVideoPassword = ''
      }
    },
		removeVideoPassword(pw){
      this.currentShare.password = this.currentShare.password.filter(p => pw != p)
		},
		scrapeSite(){
			this.loadingSiteImages = true
			this.siteImages = null
			this.siteImageParents = null
			this.siteLinks = null
			Rails.ajax({
				url: '/posts/scrape_site?url=' + this.urlForImages,
				type: "GET",
				dataType: "JSON",
				success: (data) => {
					this.siteImages = data.images
					this.siteImageParents = data.imageParents
          this.siteLinks = data.links
					this.loadingSiteImages = false
					this.setLocalStorageItem('MS_SITE_URL', this.urlForImages)
				},
				error: (e) => {
					this.$toasted.show('Could not get data. Contact Support')
				}
			});
		},
    loadScript(FILE_URL) {
      let scriptEle = document.createElement("script");
    
      scriptEle.setAttribute("src", FILE_URL);
      scriptEle.setAttribute("type", "text/javascript");
    
      document.body.appendChild(scriptEle);
    
      // success event 
      scriptEle.addEventListener("load", () => {
       this.sayDev("File loaded: " + FILE_URL)
        if (FILE_URL == "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/addon/edit/closetag.min.js") {
          this.scriptsLoaded.html = true
        }else if (FILE_URL.includes('shopifycdn')){
					this.scriptsLoaded.shopify = true
				}
      });
			// error event
      scriptEle.addEventListener("error", (ev) => {
				this.sayDev("Error loading: " + FILE_URL)
      });
    },
    toggleHtml(type) {
      if (type == 0 && this.htmlEditorContent) {
        this.htmlEditorContent = false
			} else if (type == 1 && this.htmlEditorValue){
				this.htmlEditorValue = false
      } else {
        if (this.scriptsLoaded.html) {
          this.sayDev("Already loaded HTML")
          type == 0 ? this.htmlEditorContent = true : this.htmlEditorValue = true
        } else {
					this.sayDev("Do load HTML")
          this.loadHtmlScripts()
          setTimeout(()=> {
						this.scriptsLoaded.html = true
						type == 0 ? this.htmlEditorContent = true : this.htmlEditorValue = true
          }, 500)
        }
      }
    },
    loadChartScripts() {
      if (this.scriptsLoaded.chart) {
        return
      }
      this.sayDev("Load Chart scripts")
      var scripts = [
        "https://cdn.jsdelivr.net/npm/chartjs-plugin-datalabels@2.0.0",
      ]
      this.loadScript("https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.7.1/chart.min.js")
      setTimeout(()=> {
        scripts.forEach((s, index)=> {
          this.loadScript(s)
        })
      }, 500)
    },
    loadHtmlScripts() {
      if (this.scriptsLoaded.html) {
        return
      }
      this.sayDev("Load HTML scripts")
      var scripts = [
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/htmlmixed/htmlmixed.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/css/css.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/markdown/markdown.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/addon/selection/active-line.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/addon/edit/matchbrackets.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/xml/xml.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/addon/edit/closetag.min.js",
      ]
      this.loadScript( "https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/codemirror.min.js")
      setTimeout(()=> {
        scripts.forEach((s, index)=> {
          this.loadScript(s)
        })
      }, 500)
    },
		loadShopifyScripts(){
			if (this.scriptsLoaded.shopify) {
        return
      }
      this.sayDev("Load Shopify scripts")
      this.loadScript("https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js")
		},
		openCheckout(v){
			var amt = !v.capture_payment_amount || v.capture_payment_amount == 'null' ? 1 : v.capture_payment_amount
			var currency = !v.capture_payment_currency || v.capture_payment_currency == 'null' ? 'USD' : v.capture_payment_currency
			var url = `/stripe/collect?token=${v.token}&amount=${amt}&currency=${currency}&email=${this.currentUser.email}`
			lity(url)
		},
		sendPayperDashEmail(){
			Rails.ajax({
				url: "payper/send_dash?email=" + this.guestEmail,
				type: "POST",
				success: (data) => {
					this.$toasted.show('Please check your email for a link to your dashboard.')
				},
				error: (data) => {
					this.$toasted.show('Please check your email for a link to your dashboard.')
				}
			})
		},
		showPayDialog(){
      if (this.ownsPost) {
        this.payDialog = false
        return
      }
			if (this.currentShare.capture_payment_point >= 0){
        this.pausePlayer()
				this.introDialog = false
				this.payDialog = true
			} else {
				this.introDialog = true
				this.payDialog = false
			}
		},
    startPendingCheck() {
			if(!this.share) {
				return
			}
      var amounts = [43, 24, 30, 41, 45, 34]
      this.questionTimerAmount = amounts[Math.floor(Math.random() * 5)]
      setInterval(()=> {
        console.log("Check....")
        this.questionTimerAmount = Math.max(this.questionTimerAmount - Math.floor(Math.random() * 20), 0)
        Rails.ajax({
          url: "/pending/" + this.share.source_id,
          type: "GET",
          success: (data) => {
            console.log("Answer: " + data)
            if (data.toString() == "false") {
              this.$toasted.show("Finished! Get ready...")
              setTimeout(()=> {
                window.location.reload();
              }, 1500)
            } 
          },
          error: (data) => {
            console.log("Error getting videos. Please contact support.")
          },
        
        })
      }, 5000)
    },
    hideAnnotationForScreen(x) {
      if (this.ownsPost) {
        return false
      }
      if (x.hide_mobile && (this.browserInfo.isMobileDevice || this.isIOS || this.isAndroid)) {
        return true
      } else if (x.hide_desktop && !(this.browserInfo.isMobileDevice || this.isIOS || this.isAndroid)) {
        return true
      }
    },
		shouldShowAnnotation(x){
      if (!['button','comment','hotspot','text','card','image'].includes(x.type_of)) {
        return false
      }
      if (this.showMessageDialog) {
        return false
      }
      if (this.hideAnnotationForScreen(x)) {
        return false
      }
      if (this.menuVisible && x.tray_type == "resourceTray" && !(this.activeDiv && this.activeDiv.id == x.id)) {
        return true
      } 
      if (this.showCTA && x.tray_type == "ctaTray" && !(this.activeDiv && this.activeDiv.id == x.id)) {
        this.sayDev("Show CTA annn")
        return true
      }
      if (this.notesDialog || this.articleDialog) {
        return false
      }
			var show = !x.hidden && !x.invisible
			var trayAnn = !!x.tray_type 
			var dialogShowing = (this.dialogShowing && !this.divDialog)
			var isActive = this.divsActive.filter(a=>a.id == x.id).length > 0
			var regularState = !trayAnn && (!this.showingAnyTrays || (this.showCTA && this.endButton.action == 'none')) && !this.showingCheckout && !this.showingVariants
	 		var resourceState = x.tray_type == 'resourceTray' && this.showMenu
			var ctaState = x.tray_type == 'ctaTray' && this.showCTA && this.endButton && this.endButton.action == 'custom'
			var trayState = !!x.tray_type && !['resourceTray', 'ctaTray'].includes(x.tray_type) && this.divsActive.filter(a=>a.id == x.tray_type).length > 0 && !this.showingCheckout && !this.showingVariants && (!this.showingAnyTrays || (this.showCTA && this.endButton.action == 'none'))
			var editingState = this.editingAnnotation.id == x.id
			var openAi = this.collectPrompt && this.replyAnnotation && this.replyAnnotation.click_action == 'openai' 
			var ret = show && !(['resourceTray', 'ctaTray'].includes(x.tray_type) && dialogShowing) && ((isActive && regularState) || resourceState || ctaState || trayState) && !editingState && !openAi && !this.showCardDialog
			if (ret && !isActive){
				this.addToActive(x)
			}
			return ret
		},
		fetchSynthesiaVideos(){
			var offset = this.synthesiaVideos ? this.synthesiaVideos.length : 0
			Rails.ajax({
				url: "/synthesia?offset=" + offset,
				type: "GET",
				success: (data) => {
          this.sayDev("Got Videos")
          this.sayDev(data)
					if (data && data.length == 0 && this.synthesiaVideos){
						this.getMoreSynthesiaVideos = false
						this.$toasted.show('Loaded all ' + this.synthesiaVideos.length + ' videos ✅')
						return
					}
					if (this.synthesiaVideos){
						this.synthesiaVideos = this.synthesiaVideos.concat(data)
					} else {
						this.synthesiaVideos = data
					}
					if (data.length < 20){
						this.getMoreSynthesiaVideos = false
					}
				},
				error: (data) => {
					this.synthesiaVideos = []
					this.$toasted.show("Error getting videos. Please ensure your API Token is correct or contact support.")				},
			})
		},
    fetchHeygenVideos(){
      this.sayDev("Fetch HeyGen Videos")
			var offset = this.heygenVideos ? this.heygenVideos.length : 0
			Rails.ajax({
				url: "/heygen/list?offset=" + offset,
				type: "GET",
				success: (data) => {
          this.sayDev("Got Videos")
          this.sayDev(data)
					if (data && data.length == 0 && this.heygenVideos){
						this.getMoreHeygenVideos = false
						this.$toasted.show('Loaded all ' + this.heygenVideos.length + ' videos ✅')
						return
					}
					if (this.heygenVideos){
						this.heygenVideos = this.heygenVideos.concat(data)
					} else {
						this.heygenVideos = data
					}
					if (data.length < 20){
						this.getMoreHeygenVideos = false
					}
				},
				error: (data) => {
					this.heygenVideos = []
					this.$toasted.show("Error getting videos. Please ensure your API Token is correct or contact support.")				},
			})
		},
		fetchStockAssets(type, more, dest){
			if (dest != this.selectedStockType){
				this.stockAsset = []
				this.getMoreStockAssets = false
			}
			this.selectedStockType = dest
			if(!more){
				this.stockAssets = []
				this.getMoreStockAssets = true
			}
			if (!this.stockSearch){
				return
			}
			this.searchingStock = true
			var offset = this.stockAssets ? this.stockAssets.length : 0
			var q = this.stockSearch
			Rails.ajax({
				url: `/stock?q=${q}&offset=${offset}&type=${type}&dest=${dest}`,
				type: "GET",
				success: (data) => {
					var len = this.stockAssets ? this.stockAssets.length : 0
					if (data && data.length == 0 && this.stockAssets){
						this.getMoreStockAssets = false
						this.searchingStock = false
						this.$toasted.show('Loaded all ' + len + ' ' + type + (len == 1 ? '' : 's') + ' ✅')
						return
					}
					if (len > 0 && data){
						this.stockAssets = this.stockAssets.concat(data)
					} else {
						this.stockAssets = data
					}
					if (!data || data.length < 9){
						this.getMoreStockAssets = false
					}
					this.searchingStock = false
				},
				error: (data) => {
					this.$toasted.show(`Error getting ${type}s. Please contact support.`)
					this.searchingStock = false
				},
			})
		},
		fetchDalleAssets(getMore = false){
			this.searchingStock = true
			var q = this.stockSearch
			var now = Math.round(Date.now()/1000)
			Rails.ajax({
				url: `/annotations/dalle?q=${q}&user=${this.currentUser.id}&generate=${1}`,
				type: "GET",
				success: (data) => {
					var timer = setInterval(() => {
						Rails.ajax({
							url: `/annotations/fetch_dalle?q=${q}&time=${now}`,
							type: "GET",
							success: (data) => {
									if (data && data.length > 0){
										var len = this.stockAssets ? this.stockAssets.length : 0
										if (len > 0 && data && getMore){
											this.stockAssets = this.stockAssets.concat(data)
										} else {
											this.stockAssets = [data]
										}
										this.currentUser.openai_used++
										this.searchingStock = false
										clearInterval(timer);
									}
								}, error: (e) => {}
							})
					}, 5000);	
				},
				error: (data) => {
					this.$toasted.show(`Error getting images. Please contact support.`)
					this.searchingStock = false
				},
			})
		},
    setStartFinish(arg) {
      if (arg == 0) {
        this.setActiveDivTime(0.0)
      } else {
        this.setActiveDivFinish(this.player.duration().toFixed(1))
      }
    },
    cancelDelete() {
      this.pendingAnn = null
      this.deleteAnnDialog = false
    },
    startDeleteInteraction(ann) {
      this.pendingAnn = ann
      this.deleteAnnDialog = true
    },
    doPlaceInteraction() {
      if (!this.isTouchDevice && this.placingInteraction) {
        this.sayDev("DoPlace!")
        this.stopPlacingInteraction()
        this.setDivDimensions()
      }
    },
    stopPlacingInteraction() {
      this.placingInteraction = false
      window.onmousemove = null
      setTimeout(()=> {
        if (this.activeDiv.type_of == 'button' || this.activeDiv.type_of == 'text') {
          this.flashFocusField('activeDivEditCaption', 5000);
        }
      }, 200)
    },
		alignAnnotation(opt){
			opt = opt - 1
			var height, width, diff
			height = this.coords.height / 2.0
			width = this.coords.width / 2.0
			diff = 100 / 3.0 / 2
			
			if (opt < 3){
				this.coords.top = diff - height
			} else if (opt < 6){
				this.coords.top = 3 * diff - height
			} else {
				this.coords.top = 5 * diff - height
			}
			if (opt % 3 == 0){
				this.coords.left = diff - width
			} else if (opt % 3 == 1){
				this.coords.left = 3 * diff - width
			} else {
				this.coords.left = 5 * diff - width
			}
			this.doCoords()

		},
    adjustCoords(val, amount) {
      if (val == 1) { this.coords.left = parseInt(this.coords.left ? this.coords.left: 0) + (amount) }
      else if (val == 2) { this.coords.top = parseInt(this.coords.top ? this.coords.top : 0) + (amount) }
      else if (val == 3) { this.coords.width = parseInt(this.coords.width ? this.coords.width: 0) + (amount) }
      else if (val == 4) { this.coords.height = parseInt(this.coords.height ? this.coords.height: 0) + (amount) }
			if (this.adc){
				var ratio = this.activeCard.layout < 4 ? 0.8 : 1.3
				if (val == 3){
					this.coords.height = parseInt(this.coords.width ? this.coords.width : 20) / 100 * this.canvasWidth / ratio / this.canvasHeight * 100
				} else if (val == 4){
					this.coords.width = parseInt(this.coords.height ? this.coords.height : 20) / 100 * this.canvasHeight * ratio / this.canvasWidth * 100
				}
			}
      this.doCoords()
    },
    doDelayCoords() {
      setTimeout(()=> {
        this.doCoords()
      }, 1250)
    },
    doCoords(iterations = 0) {
			if(!this.divDialog){
				this.sayDev('return for bulk')
				return
			}
			if (this.coords.width && this.coords.height) {
        this.coords.left = Math.min(100, Math.max(parseFloat(this.coords.left || 0).toFixed(1), 0))
        this.coords.top = Math.min(100, Math.max(parseFloat(this.coords.top || 0).toFixed(1), 0))
        this.coords.width = Math.min(100, Math.max(parseFloat(this.coords.width || 2).toFixed(1), 2))
        this.coords.height = Math.min(100, Math.max(parseFloat(this.coords.height || 2).toFixed(1), 2))
      }
			var d = document.getElementById('drag')
      d.style.transform = 'none'
      d.style.left = this.coords.left + '%'
      d.style.top = this.coords.top + '%'
      d.style.width = this.coords.width + '%'
      d.style.height = this.coords.height + '%'
      this.activeDiv.customStyle.left = this.coords.left
      this.activeDiv.customStyle.top = this.coords.top
      this.activeDiv.customStyle.width = this.coords.width
      this.activeDiv.customStyle.height = this.coords.height
      drag.dataset.x = "0"
      drag.dataset.y = "0"
    },
		checkTrayButtonStyle(){
			var btn = document.getElementById('trayButton')
			if (btn && btn.clientHeight == 80){
				return 'top: calc(50% - 40px);'
			}
		},
		checkActiveDivZindex(ann){
      var style = ann.style
			style = style ? style : {}
			if (true || this.dialogShowing) {
				style['zIndex'] = 1
			}
      // console.log("Z: " + style['zIndex'])
      // if (ann.type_of == 'button') {
      //   style['borderRadius'] = this.getBorderRadiusString(ann)
      // }
			return style
		},
		showCheckout(){
			this.hideSeekbarImmediately()
			this.pausePlayer()
			this.showingCheckout = true
		},
		closeCheckout(){
			this.showingCheckout = false
			this.playPlayer("CloseCheckout")
		},
		showVariants(){
			this.hideSeekbarImmediately()
			this.pausePlayer()
			this.showingVariants = true
		},
		closeVariants(){
			this.selectedProduct = null
			this.variants = {}
			this.showingVariants = false
			this.playPlayer("CloseVariants")
		},
		showCtaTray: function () {
      if (this.showCTA) {
        return
      }
			if(!this.assets && this.ownsPost){
				this.refreshAssets()
			}
			this.toggleMenu(false)
			this.preCtaTime = this.currentTime || 0
      this.sayDev("PreCTA time: " + this.preCtaTime)
      this.selectedAnnotations = []
      // this.changeTimeAndPlay(this.player.duration()-0.3)
      this.pausePlayer();
      this.clearScreenInteractions();
      var d = this.player.duration()
      if (this.player.currentTime() != d) {
        this.player.currentTime(this.player.duration())
      } else {
        this.sayDev("Set to end time, current is " + this.player.currentTime() + " and target is " + this.player.duration())
      }
      // this.player.currentTime(this.player.duration()-0.1)
      this.showCTA = true
			// },500) 
		},
		closeCtaTray: function () {
			this.editingCtaTray = false
			this.showCTA = false
			this.selectedAnnotations = []
			if (this.preCtaTime + 5 >= this.share.duration){
				this.changeTime(this.share.duration - 5)
			} else {	
				this.changeTime(this.preCtaTime)
			}
		},
		replayCtaTray: function () {
			this.showCTA = false
			this.showCtaTray()
		},
		showResourceTray(from){
      this.sayDev("Show Magic Menu from " + from)
			if(!this.assets && this.ownsPost){
				this.refreshAssets()
			}
			this.hideSeekbarImmediately()
			if (!this.played){
				this.changeTime(0.1)
				this.playPlayer('Initialize Tray')
				this.played = true
			}
			setTimeout(() => {
				if (this.showCTA){
					// this.changeTime(this.preCtaTime)
					this.showCTA = false
				}
				this.selectedAnnotations = []
				this.toggleMenu(true); 
				if (this.resourceTray.pause && !this.player.paused()){
					this.pausePlayer()
				}
			},this.played ? 0 : 100) 
		},
		closeMenu(){
      this.sayDev("Close Menu")
      if (this.ownsPost && this.activeSideItem != "interactions") {
        this.toggleMenu(false)
        // this.setSideItemTitle("Interactions")
        this.hideAllDialogs()
        return
      }
			this.selectedAnnotations = []
			this.toggleMenu(false); 
			this.blockToggle = false
			this.pausedUntilClick = false
			var atEnd = Math.abs(this.deciTime - this.player.duration()) <= 0.1
			if ((this.resourceTray.pause || atEnd) && this.played && !(this.ownsPost && !this.showSplitColumns)){
				this.hideAllDialogsAndPlay()
			}
		},
		closeAllTrays(){
			this.closeMenu()
			this.closeCtaTray()
		},
		copyTrayToVideo(){
			this.copyTrayDialog = true
			this.copyTrayTo = true
		},
		copyTrayFromVideo(){
			this.copyTrayDialog = true
			this.copyTrayTo = false
		},
		createResourceTray(annotations, position){
			var h, w, t, l
			h = this.resourceTray.height
			w = this.resourceTray.width
			t = this.resourceTray.top
			l = this.resourceTray.left
			this.resourceTray.height = 100
			this.resourceTray.top = 0
			if (position == 0){
				this.resourceTray.width = 100
				this.resourceTray.left = 0
			} else if (position == 1){
				this.resourceTray.width = 50
				this.resourceTray.left = 0
			} else if (position == 2){
				this.resourceTray.width = 50
				this.resourceTray.left = 50
			}
			var makeVisible = this.resourceTrayAnnotations.length == 0 && !this.resourceTray.visible
			if (makeVisible){
				this.resourceTray.visible = true
			}
			if(makeVisible || h != this.resourceTray.height || w != this.resourceTray.width || t != this.resourceTray.top || l != this.resourceTray.left){
				var data = new FormData();
				data.append("resourceTray", JSON.stringify(this.resourceTray));
				Rails.ajax({
					url: `/design/resourceTray/${this.shareDesign.id}`,
					type: "POST",
					data: data,
					dataType: "JSON",
					success: (data) => {
						this.forceUpdate();
						if (makeVisible){
							this.$toasted.show('The Magic Menu icon is now visible! You can edit this in design settings.')
						}
					},
					error: (e) => {
						this.$toasted.show("Could not update");
						return
					},
				});
			}
			this.resourceTrayAnnotations.forEach(a => {
				this.destroyAnnotation(a)
			})
			var annData = new FormData()
			annotations.forEach(a => {
				annData.append("annotation[type_of]", a.type_of)
				annData.append("annotation[content]", a.content)
				annData.append("annotation[time]", 0)
				annData.append("annotation[exact_time]", 0.0)
				annData.append("annotation[finish]", a.finish)
				annData.append("annotation[exact_finish]", a.finish)
				annData.append("annotation[data]", a.data )
				annData.append("annotation[click_action]", a.click_action)
				annData.append("annotation[transition]", 'none')
				annData.append("annotation[customStyle]", a.customStyle)
				annData.append("annotation[token]", a.token)
				annData.append("annotation[tray_type]", 'resourceTray')
				this.createAnnotation(annData)
			})
			this.siteScraperDialog = false
			this.showResourceTray("CRT")
		},
    addListeners() {
      // this.sayDev("🎧 Add Listeners")
      // if (this.ownsPost) {
      //   window.addEventListener("beforeunload", (event) => {
      //     this.sayDev("Updating share before leaving")
      //     this.updateShare()
      //   });
      // }
      
      if (!this.ownsPost) {
        window.addEventListener("click", (event) => {
          this.touchCount++
        })
        window.addEventListener("beforeunload", (event) => {
          if (!this.played) { return }
          if (!this.isPlayerPaused()) {
            this.pausePlayer("BeforeUnload");
            this.sendChunk(this.currentTime.valueOf())
            this.saveChunks(true);
          }
          return undefined;
        });
        if (!this.isEmbed && !this.currentUser) {
          window.addEventListener("CookiebotOnAccept", (event)=> {
            this.initializeGtag()
          });
        }
				if (this.activeDiv){
					window.addEventListener("selectionchange", (event)=> {
            this.selectedText = window.getSelection().toString()
          });
				}
      }
        if (gon.action == 'pages-debug') {
          this.tryTrack("Debugged Browser", this.browserInfo)
        }


        // window.addEventListener("mousemove", (event) => {
        //   this.sayDev("MMMMM")
        //   if (this.timeDrag) {
        //     this.mouseMoveSeekbar(event)
        //   } else if (this.loggedOutView && !this.dialogShowing) {
        //     this.mouseMove()
        //   }
        // })

				if(this.ownsPost){
          var ds = new DragSelect({
           selectables: document.getElementsByClassName('activeDiv'),
           area: document.querySelector('#videoWrap'),
          });
          ds.subscribe('predragmove', ({ isDragging, isDraggingKeyboard }) => {
           if(isDragging){
            ds.break()
           }
           ds.removeSelectables(document.getElementsByClassName('activeDiv'))
           ds.addSelectables(document.getElementsByClassName('activeDiv'))
           var anns = document.getElementsByClassName('activeDiv')
           if (anns){
            for (var i = 0; i < anns.length; i++){
             anns[i].style.zIndex = ''
            }
           }
          })
          ds.subscribe('callback', (e) => {
           var init = ds.getInitialCursorPosition()
           var curr = ds.getCurrentCursorPosition()
           if (Math.abs(init.x - curr.x) < 30 && Math.abs(init.y - curr.y) < 30){
            return
           }
           var anns = document.getElementsByClassName('activeDiv')
           if (anns){
            for (var i = 0; i < anns.length; i++){
             anns[i].style.zIndex = ''
            }
           }
           this.selectedAnnotations = []
           e.items.forEach(item => {
            var ann = document.getElementById(item.id)
            if (ann) {
             var id = item.id.replace('adi-', '')
             var i = this.selectedAnnotations.indexOf(id)
             if (i < 0) {
              this.selectedAnnotations.push(id)
             }
            }
           });
          })
         }


        $(document).on('lity:close', (event, instance)=> {
					if (this.divDialog) {
            return
          }
          if (this.ownsPost && !this.showSplitColumns) {
            return
          }
          this.resumeDialog = false
          this.tryNextQuestion("lityclose")
        });
  
        window.addEventListener('resize', () => {
          this.screenWidth = window.innerWidth
			    this.screenHeight = this.windowInner
          // this.sayDev(this.screenWidth + " x " + this.screenHeight)
          if (this.screenWidth < 1200) {
            this.doSidebar(false)
          }
          this.drawSeekBounding("windowResize");
          if (this.useCoverMode) {
            this.divsActive.forEach((d)=> { 
              d.style = this.getActiveDivStyle(d)
            })
          }
          this.setImageCloseCoords()
        })
  
        window.addEventListener("orientationchange", ()=> {
          this.handleOrientationChange()
          setTimeout(()=> {
            this.handleOrientationChange()
            setTimeout(()=> {
              this.handleOrientationChange()
            }, 500)
          }, 200)
          // alert("Orientation")
        }, false);
  
  
  
        window.addEventListener("fullscreenchange", (event) => {
          if (!document.fullscreenElement && this.isFullscreen) {
            this.toggleFullscreen();
          }
        });
        document.body.onkeyup = (e) => {
          if ((e.keyCode == 8 || e.keyCode == 46) && this.activeObject) {
            if (!this.activeObject.isEditing) {
              this.removeObject()
            }
          }
          if (e.keyCode == 32) {
            this.handleSpacebar(e);
          }
          if (e.keyCode == 13) {
            // this.sayDev("Enter key!")
            if(!this.blockEnter && this.questionAnnotation && this.showingCorrect && this.safeStyles.question.enter_continue) {
              this.tryNextQuestion()
            }
          } else if (e.keyCode == 37) {
            if (this.ownsPost || !this.dialogShowing && !this.isMinimal && this.safeStyles.controls.visible  && (this.safeStyles.controls.seekbar || this.safeStyles.controls.rewind)) {
              if (!this.ownsPost && this.cannotClickSeekbar) {
                // this.$toasted.show("Navigation Disabled"); return 
              }
              if (this.player.paused()) {
                return
              }
              this.rewind(e)
            }
          }
          else if (e.keyCode == 39) {
            if (this.ownsPost || !this.dialogShowing && !this.isMinimal && this.safeStyles.controls.visible && (this.safeStyles.controls.seekbar || this.safeStyles.controls.forward)) {
              if (!this.ownsPost && this.cannotClickSeekbar) {
                // this.$toasted.show("Navigation Disabled"); return 
              }
              if (this.player.paused()) {
                return
              }
              this.fastForward(e)
            }
          }
          else if (e.which == 27) {
            if (this.isFullscreen) {
              this.toggleFullscreen()
            }
          } else if (e && !this.isSharing && !this.isEditing && !this.isSearching && !this.notesDialog && !this.dialogShowing && e.which <= 90 && e.which >= 48) {
            e.preventDefault()
            e.stopPropagation()
            this.mouseMove();
            this.focusInput()
          }
        }
  
        if (!this.isEmbed) {
          window.addEventListener('focus', ()=> {
            if (this.isIOS && this.played) {
              if (this.divsActive.filter(x=> x.pause || x.must_click).length == 0){
                this.sayDev("Jumping!")
                this.player.currentTime(this.currentTime + 0.1)
              }
            }
            if (this.share.resume_focus && (this.resumeDialog || (this.played && !this.dialogShowing && !this.pausedUntilClick && !this.showCTA && (!this.showMenu && this.resourceTray.visible)) && !this.ownsPost && !this.introDialog)) {
              this.resumeVideo()
            }
          })
          window.addEventListener('blur', ()=> {
            if (!this.isProduction || this.isEmbed) {
              return
            }
            if (this.isSafariOrIOSorIpad && this.played && this.isProduction) { 
              this.pausePlayer("blur")
            }
            if (this.ownsPost || this.share.pause_inactive) { this.pausePlayer("BLUR") }
          })
        } else {
          // Embed only
          window.addEventListener('message', (event)=> {
            if (this.variables.messagedebug) {
              console.log("postMessage Received")
              console.log(event)
            }
            if (event.data.event == "request_screenshot") {
              this.sayDev("✅ Screenshot")
              this.takeScreenshot()
              window.top.postMessage({event: "screenshot", info: { title: this.share.title, token: this.share.token, currentTime: this.currentTime, formattedTime: this.formattedSeconds(this.currentTime), image: document.getElementById('switchCanvas').toDataURL()}}, "*");
            } else if (event.data.event == "pause_player") {
              this.sayDev("✅ Pause")
              this.pausePlayer()
            } else if (event.data.event == "enter_fullscreen") {
              // Does not work due to security restrictions
              this.sayDev("✅ Enter Fullscreen")
              if (this.canFullscreen) {
                screenfull.request();
                this.isFullscreen = true
              } else {
                this.sayDev("Can't fullscreen...")
              }
            } else if (event.data.event == "exit_fullscreen") {
              this.sayDev("✅ Exit Fullscreen")
              if (screenfull && screenfull.isEnabled) {
                screenfull.exit();
                this.isFullscreen = false
              }
            } else if (event.data.event == "play_player") {
              this.sayDev("✅ Play")
              this.playPlayer("WindowMessage")
            } else if (event.data.event == "rewind") {
              this.sayDev("✅ Rewind")
              this.rewind()
            } else if (event.data.event == "fast_forward") {
              this.sayDev("✅ FastForward")
              this.fastForward()
            } else if (event.data.event == "change_time") {
              this.sayDev("✅ ChangeTime")
              this.changeTimeAndPlay(event.data.time || this.currentTime)
            } else if (event.data.event == "set_variable") {
              this.sayDev("✅ SetVariable")
              this.setVariable(event.data.variable_name, event.data.variable_value)
            } else if (event.data.event == "godoot") {
              this.sayDev("✅ GoDoot")
              this.isDebug = true
            }
            else if (event.data == "closeModal") {
              document.getElementsByClassName('lity-close')[0].click()
            }
          })
        }
    },
		searchAnything(search){
			Rails.ajax({
				url: '/donkey/search_everything?search=' + search,
				type: "GET",
				dataType: "JSON",
				success: (data) => {
					this.donkeyAnythingResults = data
				}, error: (e) => {
					this.$toasted.show('Search must be 5 characters')
				}
			})
		},
    doDonkeySearch() {
      if (!this.extra) {
        return
      }
      window.location.href = `/donkey?email=${this.extra}`
    },
    playDoot(number, url) {
      if (document.getElementById("dootmusic") && Math.floor(Math.random() * 10) > number) {
        document.getElementById("dootmusic").play();
        return
      }
			setTimeout(()=> {
				window.location.href = url
			}, 500)
    },
    getCarouselStyle(div) {
      var c = {}
      if (this.isJSON(div.customStyle)) {
        c = JSON.parse(div.customStyle)     
      } else {
        c = { height: c.height }
      }
      
      var x = { boxShadow: 'none', height: 'auto', maxHeight: '300px' }
      if (c && c.height) {
        x.height = (c.height * 4.4)+'px'
      }
      return x
    },
    toggleDebug() {
      if (!this.isDebug && this.isProduction) {
        return
      }
      if (this.debugDialog) {
        this.debugDialog = false
      } else {
        this.pausePlayer("TD")
        this.debugDialog = true
      }
    },
		makeSelectedAnnotation(annotation){
      this.pausePlayer();
			this.selectedAnnotations = [annotation.id]
		},
		doFresh(){
			this.selectedAnnotations = this.annotations.map(x=>x.id)
			this.bulkOption = 'delete'
			this.confirmBulkEdit()
		},
    confirmBulkEdit() {
      this.busy = true
      setTimeout(()=> {
        this.sayDev(this.bulkOption, this.bulkValue)
        var data = new FormData
        data.append("ids", this.selectedAnnotations)
        data.append("video_id", this.share.id)
        data.append("option", this.bulkOption)
        data.append("option2", this.bulkOption2)
        data.append("duration", this.playerDuration) 
				if (this.bulkOption == 'coords' || this.bulkOption == 'shiftCoords'){
					data.append("value", JSON.stringify(this.coords))
				} else if (this.bulkOption == 'undo' || this.bulkOption == 'redo'){
					this.bulkValue = {}
					this.annotations.forEach(x =>{
						if (this.selectedAnnotations.includes(x.id)){
							this.bulkValue[x.id] = x.undo_redo
						}
					})
					data.append("value", JSON.stringify(this.bulkValue))
				} else {
					data.append("value", this.bulkValue)
				}
        Rails.ajax({
          url: `/annotations/bulk`,
          type: "POST",
          data: data,
          dataType: "JSON",
          success: (data) => {
            this.$toasted.show("Success ✅")
            if (this.bulkOption == 'delete') {
							this.selectedAnnotations = []
              this.annotations = []
						}
            if (this.bulkOption == 'start') {
							this.changeTime(this.bulkValue)
						}
            // if (this.bulkOption == 'hidden') {
						// 	this.selectedAnnotations.forEach((a) => {
						// 		a.hidden = !a.hidden
						// 	})
						// }
            if (this.bulkOption == 'shiftCoords') {
							this.coords = {
								left: null,
								top: null,
								width: null,
								height: null
							}
						}
						if(['copyTo','moveTo'].includes(this.bulkOption)){
							this.annotations = []
							if (this.bulkOption == 'moveTo'){
								this.selectedAnnotations = []
							}
							if (this.bulkOption2 == 'cta'){
								this.showCtaTray()
							} else if (this.bulkOption2 == 'resource'){
								this.showResourceTray("CBE")
							} else if (this.bulkOption2 == 'standard'){
								if (this.showMenu){
									this.closeMenu()
								} else if (this.showCTA){
									this.closeCtaTray()
								}
							}
						}
						if (['start', 'end', 'coords', 'shift', 'tidy', 'place', 'shiftCoords', 
						'center_h', 'center_v', 'align_h', 'align_v', 'hidden', 'text_color', 
						'background_color', 'border_color', 'border_radius', 'transition', 'animation', 'fontSize', 'post_click_state', 
						'must_click', 'undo', 'redo'].includes(this.bulkOption)){
							this.annotations.forEach(x =>{
								if (this.selectedAnnotations.includes(x.id) && this.bulkChangedAnns(x.type_of, this.bulkOption, this.bulkOption2)){
									if (this.bulkOption == 'undo'){
										x.undo_redo = -1
									} else {
										x.undo_redo = 1
									}
								}
							})
						}
						this.bulkOption2 = ''
						this.fetchInteractions()
						setTimeout(() => {
							if (['start', 'end', 'coords', 'shift', 'tidy', 'place', 'shiftCoords', 
							'center_h', 'center_v', 'align_h', 'align_v', 'delete'].includes(this.bulkOption)){
								this.annotations.forEach(x =>{
									if (x.tray_type && !['resourceTray', 'ctaTray'].includes(x.tray_type)){
										this.placeAnnInCard(x)
									}
								})
							}
						},1000) 
            this.busy = false
          },
          error: (e) => {
            this.$toasted.show("Something went wrong. Please contact support if this continues!")
            this.busy = false
          },
        });
      }, 800)
    },
		bulkChangedAnns(type, option, option2){
      if(['start', 'end', 'undo', 'redo'].includes(option)){
				return true
			} else if (option == 'text_color'){
				return ['button', 'text'].includes(type)
			} else if (option == 'background_color' || (option == 'post_click_state' && option2 == 'color')) {
				return ['button', 'text', 'hotspot'].includes(type)
			} else if (option == 'cta'){
				return ['button', 'text', 'image', 'question'].includes(type)
			} else if (option == 'resource') {
				return ['button', 'text', 'image'].includes(type)
			} else {
        return ['button', 'text', 'hotspot', 'image'].includes(type)
			}
		},
    setBulkValue(args) {
      this.bulkValue = args
    },
    selectAnnotation(annotation) {
      var i = this.selectedAnnotations.indexOf(annotation.id)
      if (i < 0) {
        this.selectedAnnotations.push(annotation.id)
        this.refreshAssets()
      } else {
        this.selectedAnnotations.splice(i, 1)
      }
      if (this.selectedAnnotations.length > 1) {
        this.pausePlayer()
      }
    },
    editCta() {
      this.startEdit();
      this.activeEditPanel = 5
      this.showCtaTray();
    },
    editTrayDesign() {
      this.designTab = this.showMenu ? 2 : this.showCTA ? 3 : 8
      this.pausePlayer()
      this.setSideItemTitle('design')
      this.sayDev("Design Tab: " + 2)
      // this.designDialog = true
      // this.designTab = this.showMenu ? 2 : this.showCTA ? 3 : 8
    },
    checkActiveNavAction() {
      if (this.activeNav.action != 'jump') {
        this.activeNav.value = null
      }
    },
    setDeciTime(args) {
      this.sayDev("Set DeciTime: " +args)
      this.player.currentTime(args)
    },
    closePickers() {
      // window.postMessage({event: "close_pickers"})
    },
    setQuestionAnswerValue(index, args) {
      // console.log(index, args)
      // this.editingQuestion.answers[index].value = args
    },
    setActiveNavValue(args) {
      this.activeNav.value = args
    },
    setActiveNavTime(args) {
      this.activeNav.exact_time = args
    },
    setActiveNavEndTime(args) {
      this.activeNav.exact_finish = args
    },
    setEndButtonVal(args) {
      this.endButton.value = args
    },
    setCaptureAuthVal(args) {
      this.currentShare.capture_auth_value = args
    },
    setCapturePaymentVal(args) {
      this.currentShare.capture_payment_value = args
    },
    setEAt (args) {
      this.editingAnnotation.exact_time = parseFloat(args)
    },
    setEAf (args) {
      this.editingAnnotation.exact_finish = parseFloat(args)
    },
    setAdValue(args) {
      if (this.activeDiv.second_action == 'jump') {
        this.activeDiv.second_value = args
      } else {
        this.activeDiv.value = args
      }
      
    },
    setCardParentValue(args) {
      this.activeCard.click_value = args
    },
    setChapterTime(args) {
      this.sayDev("SetChapterTime " + Math.floor(args))
      this.pausePlayer()
      this.player.currentTime(Math.floor(args))
    },
    setEditingTime(args) {
      this.editingTime = args
    },
    setTestingVar(args) {
      console.log(args)
    },
    timeItemsBeforeAfter(time, isBefore) {
      var x = []
      this.timeItems.forEach((t)=> {
        if (isBefore && t.value < time) {
          x.push(t)
        } else if (!isBefore && t.value > time) {
          x.push(t)
        }
      })
      return x
    },
    questionStyleChanged() {
      this.correctAnswer = ''
      this.editingQuestion.answers.forEach((a)=> {
        a.variable = ''
        a.variable_value = ''
      })
      var s = this.editingQuestion.style
      if (s == 'multiple' || s == 'poll' || s == 'select') {
        this.correctAnswer = ''
      } else if (s == 'all' || s == 'number') {
        this.correctAnswer = []
      }
      else {
        this.correctAnswer = [{value: ''}]
      }
      this.handleQuestionUpdate()
    },
    questionTimeChanged() {
      this.editingQuestion.rewind_time = Math.floor(Math.max(this.editingTime - 5, 0))
    },
    handleQuestionUpdate() {
      var eq = this.editingQuestion
      if (['draw', 'video', 'voice', 'rating', 'likert', 'image'].includes(eq.style)) {
        eq.force_correct = false
        eq.has_correct_answers = false
        this.correctAnswer = ''
      }
      if (eq.force_correct) {
        this.sayDev("FC on")
        eq.skippable = false
        eq.has_time_limit = false
        eq.limit = "0"
      }
      if (!eq.stores_variable) {
        eq.variable = ''
        eq.variable_value = ''
      }
      if (eq.has_time_limit) {
        eq.skippable = false
      }
      if (eq.has_time_limit && eq.limit.toString() == "0") {
        eq.limit = "30"
      }
      if (!eq.has_time_limit) {
        eq.limit = "0"
      }
      if (!eq.has_time_limit) {
        eq.limit = "0"
      }
      if (!eq.has_correct_answers) {
        eq.correct = ''
      }
      if (!['multiple','select','all','poll'].includes(eq.style)) {
        this.doIndividualVariables = false
      }
      if (!this.doIndividualVariables && eq.answers && eq.answers.length) {
        eq.answers.forEach((a)=> {
          delete a.variable
          delete a.variable_value
        })
      }
    },
		showHint() {
			if (this.questionAnnotation) {
				this.hintMessage = this.questionAnnotation.content.hint_message
				this.sayDev("Show Hint: " + this.hintMessage)
			}
		},
		  doQuestionRewind(){
			if (this.questionAnnotation) {
        var t = this.questionAnnotation.content.rewind_time
        if (!t && t!=0) { t = Math.max(this.currentTime - 5, 0) }
				this.changeTimeAndPlay(t)
			}
		  },
		  setStrings() {
				this.strings = {
					email: this.translatedStrings.email_string || this.shareDesign.email_string || "Email Address",
					done: this.translatedStrings.done_string || this.shareDesign.done_string || "Done",
					name: this.translatedStrings.name_string || this.shareDesign.name_string || "Name",
					phone: this.translatedStrings.phone_string || this.shareDesign.phone_string || "Phone Number",
					custom_id: this.translatedStrings.custom_id_string || this.shareDesign.custom_id_string || "Your ID",
					correct: this.translatedStrings.correct_string || this.shareDesign.correct_string || "Correct",
					incorrect: this.translatedStrings.incorrect_string || this.shareDesign.incorrect_string || "Incorrect",
					correctAnswer: this.translatedStrings.correct_answer_string || this.shareDesign.correct_answer_string || "Correct Answer",
					yourAnswer: this.translatedStrings.your_answer_string || this.shareDesign.your_answer_string || "Your Answer",
					skip: this.translatedStrings.skip_string || this.shareDesign.skip_string || "Skip",
					continue: this.translatedStrings.continue_string || this.shareDesign.continue_string || "Continue",
					question: this.translatedStrings.question_string || this.shareDesign.question_string || "Question",
					of: this.translatedStrings.of_string || this.shareDesign.of_string || "of",
					add_comment: this.translatedStrings.add_comment_string || this.shareDesign.add_comment_string || "Add Comment",
					cancel: this.translatedStrings.cancel_string || this.shareDesign.cancel_string || "Cancel",
					done: this.translatedStrings.done_string || this.shareDesign.done_string || "Done",
					close: this.translatedStrings.done_string || this.shareDesign.close_string || "Close",
					chapters: this.translatedStrings.chapters_string || this.shareDesign.chapters_string || "Chapters",
					interactions: this.translatedStrings.interactions_string || this.shareDesign.interactions_string || "Interactions",
					transcripts: this.translatedStrings.transcripts_string || this.shareDesign.transcripts_string || "Transcripts",
					search: this.translatedStrings.search_string || this.shareDesign.search_string || "Search",
					resume_view: this.translatedStrings.resume_view_string || this.shareDesign.resume_view_string || "Resume Viewing",
					resume_text: this.translatedStrings.resume_text_string || this.shareDesign.resume_text_string || "It looks like you've watched this video before, but did not finish it. Would you like to resume or start over?",
					restart: this.translatedStrings.restart_string || this.shareDesign.restart_string || "Restart",
					try_again: this.translatedStrings.try_again_string || this.shareDesign.try_again_string || "Try Again",
          play: this.translatedStrings.play_string || this.shareDesign.play_string || "Play",
          pause: this.translatedStrings.pause_string || this.shareDesign.pause_string || "Pause",
          mute: this.translatedStrings.mute_string || this.shareDesign.mute_string || "Mute",
          unmute: this.translatedStrings.unmute_string || this.shareDesign.unmute_string || "Unmute",
          hint: this.translatedStrings.hint_string || this.shareDesign.hint_string || "Hint",
          rewind: this.translatedStrings.rewind_string || this.shareDesign.rewind_string || "Rewind",
          color: this.translatedStrings.color_string || this.shareDesign.color_string || "Color",
          draw: this.translatedStrings.draw_string || this.shareDesign.draw_string || "Draw",
          text: this.translatedStrings.text_string || this.shareDesign.text_string || "Text",
          line: this.translatedStrings.line_string || this.shareDesign.line_string || "Line",
          pointer: this.translatedStrings.pointer_string || this.shareDesign.pointer_string || "Pointer",
          circle: this.translatedStrings.circle_string || this.shareDesign.circle_string || "Circle",
          arrow: this.translatedStrings.arrow_string || this.shareDesign.arrow_string || "Arrow",
          reset: this.translatedStrings.reset_string || this.shareDesign.reset_string || "Reset",
          exit: this.translatedStrings.exit_string || this.shareDesign.exit_string || "Exit",
          tool: this.translatedStrings.tool_string || this.shareDesign.tool_string || "Tool",
          show_resource_tray: this.translatedStrings.show_resource_tray_string || this.shareDesign.show_resource_tray_string || "Show Magic Menu",
          leaving: this.translatedStrings.leaving_string || this.shareDesign.leaving_string || "Leaving video to open link",
          show_cart: this.translatedStrings.show_cart_string || this.shareDesign.show_cart_string || "Show Shopping Cart",
          checkout: this.translatedStrings.checkout_string || this.shareDesign.checkout_string || "Checkout",
          payment_message: this.translatedStrings.payment_message_string || this.shareDesign.payment_message_string || "Payment Required",
          buy_now: this.translatedStrings.buy_now_string || this.shareDesign.buy_now_string || "Buy Now",
          help: this.translatedStrings.help_string || this.shareDesign.help_string || "Help",
          have_purchased: this.translatedStrings.have_purchased_string || this.shareDesign.have_purchased_string || "Already Purchased",
					choose_from_library: this.translatedStrings.choose_from_library_string || this.shareDesign.choose_from_library_string || "Choose From Library",
					upload_new_video: this.translatedStrings.upload_new_video_string || this.shareDesign.upload_new_video_string || 'Upload New Video',
					record_new_video: this.translatedStrings.record_new_video_string || this.shareDesign.record_new_video_string || 'Record New Video',
					video_recording_not_supported: this.translatedStrings.video_recording_not_supported_string || this.shareDesign.video_recording_not_supported_string || 'Video recording is not supported in this browser. Please use a recent version of Chrome or Firefox',
					recording_finished: this.translatedStrings.recording_finished_string || this.shareDesign.recording_finished_string || 'Recording finished',
					preview_not_supported: this.translatedStrings.preview_not_supported_string || this.shareDesign.preview_not_supported_string || 'Preview not available on this device',
					save_video: this.translatedStrings.save_video_string || this.shareDesign.save_video_string || 'Save Video',
					discard: this.translatedStrings.discard_string || this.shareDesign.discard_string || 'Discard',
					recording_in_progress: this.translatedStrings.recording_in_progress_string || this.shareDesign.recording_in_progress_string || 'Recording in Progress',
					finish_recording: this.translatedStrings.finish_recording_string || this.shareDesign.finish_recording_string || 'Finish Recording',
					start_recording: this.translatedStrings.start_recording_string || this.shareDesign.start_recording_string || 'Start Recording',
					success: this.translatedStrings.success_string || this.shareDesign.success_string || 'Success',
					video_clip_saved: this.translatedStrings.video_clip_saved_string || this.shareDesign.video_clip_saved_string || 'Video Clip Saved',
					please_grant: this.translatedStrings.please_grant_string || this.shareDesign.please_grant_string || 'Please grant permission to record.',
					continue_and_allow: this.translatedStrings.continue_and_allow_string || this.shareDesign.continue_and_allow_string || 'Press continue and then click Allow when prompted',
					no_webcam: this.translatedStrings.no_webcam_string || this.shareDesign.no_webcam_string || 'No webcam detected.',
					no_microphone: this.translatedStrings.no_microphone_string || this.shareDesign.no_microphone_string || 'No microphone detected.',
					plug_in_devices: this.translatedStrings.plug_in_devices_string || this.shareDesign.plug_in_devices_string || 'Please plug in device(s) and try again',
					yes: this.translatedStrings.yes_string || this.shareDesign.yes_string || 'Yes',
					no: this.translatedStrings.no_string || this.shareDesign.no_string || 'No',
					add_clip_title: this.translatedStrings.add_clip_title_string || this.shareDesign.add_clip_title_string || 'Add Clip Title',
					discard_video: this.translatedStrings.discard_video_string || this.shareDesign.discard_video_string || 'Discard Video',
					add_audio: this.translatedStrings.add_audio_string || this.shareDesign.add_audio_string || 'Add Audio',
					choose_existing_clip: this.translatedStrings.choose_existing_clip_string || this.shareDesign.choose_existing_clip_string || 'Choose Existing Clip',
					record_voice_clip: this.translatedStrings.record_voice_clip_string || this.shareDesign.record_voice_clip_string || 'Record Voice Clip',
					use_external_link: this.translatedStrings.use_external_link_string || this.shareDesign.use_external_link_string || 'Use External Link',
					browser_cant_record_audio: this.translatedStrings.browser_cant_record_audio_string || this.shareDesign.browser_cant_record_audio_string || 'This browser cannot record audio.',
					choose_audio_clip: this.translatedStrings.choose_audio_clip_string || this.shareDesign.choose_audio_clip_string || 'Choose Audio Clip',
					enter_link: this.translatedStrings.enter_link_string || this.shareDesign.enter_link_string || 'Enter Link',
					save: this.translatedStrings.save_string || this.shareDesign.save_string || 'Save',
					upload_new_audio: this.translatedStrings.upload_new_audio || this.shareDesign.upload_new_audio_string || 'Upload New Audio Clip',
					save_audio: this.translatedStrings.save_audio || this.shareDesign.save_audio_string || 'Save Audio Clip',
					audio_clip_saved: this.translatedStrings.audio_clip_saved || this.shareDesign.audio_clip_saved_string || 'Audio Clip Saved',
					audio_recording_not_supported: this.translatedStrings.audio_recording_not_supported || this.shareDesign.audio_recording_not_supported_string || 'Audio recording is not supported in this browser. Please use a recent version of Chrome or Firefox',
					discard_audio: this.translatedStrings.discard_audio || this.shareDesign.discard_audio_string || 'Discard Audio Clip',
					dial: this.translatedStrings.dial_string || this.shareDesign.dial_string || 'Dial this number',
					afk: this.translatedStrings.afk_string || this.shareDesign.afk_string || 'Are you still watching?',
          and: this.translatedStrings.and_string || this.shareDesign.and_string || 'and',
          or: this.translatedStrings.or_string || this.shareDesign.or_string || 'or',
				}
		  },
		  setTranslations() {
        this.sayDev("SetTranslations!!!!")
        var x = this.getFirstBrowserLanguage()
        var language = "English"
        if (x) {
          var z = x.split("-")[0]
          this.languageItems.forEach((l)=> {
            if (l.value == z) {
              language = l.text
              this.sayDev("Got it.... " + language)
            }
          })
        } 
        this.translatedStrings = {}
          Papa.parse(this.strings_csv_url, {
          download: true,
          complete: (results) => {
            this.sayDev("Got strings")
            results.data.forEach((r)=> {
              if (r && r[0] == language) {
                this.translatedStrings.correct_string = r[2]
                this.translatedStrings.incorrect_string = r[3]
                this.translatedStrings.your_answer_string = r[4]
                this.translatedStrings.correct_answer_string = r[5]
                this.translatedStrings.continue_string = r[6]
                this.translatedStrings.skip_string = r[7]
                this.translatedStrings.cancel_string = r[8]
                this.translatedStrings.question_string = r[9]
                this.translatedStrings.of_string = r[10]
                this.translatedStrings.done_string = r[11]
                this.translatedStrings.close_string = r[12]
                this.translatedStrings.chapters_string = r[13]
                this.translatedStrings.interactions_string = r[14]
                this.translatedStrings.search_string = r[15]
                this.translatedStrings.add_comment_string = r[16]
                this.translatedStrings.transcripts_string = r[17]
                this.translatedStrings.name_string = r[18]
                this.translatedStrings.email_string = r[19]
                this.translatedStrings.phone_string = r[20]
                this.translatedStrings.custom_id_string = r[21]
                this.translatedStrings.resume_view_string = r[22]
                this.translatedStrings.resume_text_string = r[23]
                this.translatedStrings.restart_string = r[24]
                this.translatedStrings.try_again_string = r[25]
                this.translatedStrings.play_string = r[26]
                this.translatedStrings.pause_string = r[27]
                this.translatedStrings.mute_string = r[28]
                this.translatedStrings.unmute_string = r[29]
                this.translatedStrings.color_string = r[30]
                this.translatedStrings.draw_string = r[31]
                this.translatedStrings.text_string = r[32]
                this.translatedStrings.line_string = r[33]
                this.translatedStrings.pointer_string = r[34]
                this.translatedStrings.circle_string = r[35]
                this.translatedStrings.arrow_string = r[36]
                this.translatedStrings.hint_string = r[37]
                this.translatedStrings.rewind_string = r[38]
                this.translatedStrings.reset_string = r[39]
                this.translatedStrings.exit_string = r[40]
                this.translatedStrings.tool_string = r[41]
                this.translatedStrings.show_cart_string = r[43]
                this.translatedStrings.checkout_string = r[44]
                this.translatedStrings.leaving_string = r[45]
                this.translatedStrings.show_resource_tray_string = r[46]
                this.translatedStrings.payment_message_string = r[47]
                this.translatedStrings.buy_now_string = r[48]
                this.translatedStrings.help_string = r[49]
                this.translatedStrings.have_purchased_string = r[50]
                this.translatedStrings.choose_from_library_string = r[51]
                this.translatedStrings.upload_new_video_string = r[52]
                this.translatedStrings.record_new_video_string = r[53] 
                this.translatedStrings.video_recording_not_supported_string = r[54] 
                this.translatedStrings.recording_finished_string = r[55] 
                this.translatedStrings.preview_not_supported_string = r[56] 
                this.translatedStrings.save_video_string = r[57] 
                this.translatedStrings.discard_string = r[58] 
                this.translatedStrings.recording_in_progress_string = r[59] 
                this.translatedStrings.finish_recording_string = r[60] 
                this.translatedStrings.start_recording_string = r[61] 
                this.translatedStrings.success_string = r[62] 
                this.translatedStrings.video_clip_saved_string = r[63] 
                this.translatedStrings.please_grant_string = r[64] 
                this.translatedStrings.continue_and_allow_string = r[65] 
                this.translatedStrings.no_webcam_string = r[66] 
                this.translatedStrings.no_microphone_string = r[67] 
                this.translatedStrings.plug_in_devices_string = r[68] 
                this.translatedStrings.yes_string = r[69] 
                this.translatedStrings.no_string = r[70] 
                this.translatedStrings.add_clip_title_string = r[71] 
                this.translatedStrings.discard_video_string = r[72] 
                this.translatedStrings.add_audio_string = r[73] 
                this.translatedStrings.choose_existing_clip_string = r[74] 
                this.translatedStrings.record_voice_clip_string = r[75] 
                this.translatedStrings.use_external_link_string = r[76] 
                this.translatedStrings.browser_cant_record_audio_string = r[77] 
                this.translatedStrings.choose_audio_clip_string = r[78] 
                this.translatedStrings.enter_link_string = r[79] 
                this.translatedStrings.save_string = r[80]
                this.translatedStrings.upload_new_audio_string = r[81]
                this.translatedStrings.save_audio_string = r[82]
                this.translatedStrings.audio_clip_saved_string = r[83]
                this.translatedStrings.audio_recording_not_supported_string = r[84]
                this.translatedStrings.discard_audio_string = r[85]
                this.translatedStrings.dial_string = r[86]
                this.translatedStrings.afk_string = r[87]
                this.translatedStrings.and_string = r[88]
                this.translatedStrings.or_string = r[89]
                this.sayDev("Dial String: " + this.translatedStrings.dial_string)

              }
            })
            this.setStrings()
          }
			  })
		  },
      newSetSeekbar() {
        var x = Math.min(100, .94 * (100*this.deciTime/this.duration).toFixed(1))
        // this.sayDev("SSP: " + x)
        this.seekbarPercentage = x
      },
    // ttttt
		doTimeUpdate() {
			if (this.showMenu && this.resourceTray.pause){
				this.sayDev('Showing Magic Menu, return')
				return
			}
			var atEnd = Math.abs(this.deciTime - this.player.duration()) <= 0.1
			if(atEnd){
				if(this.endButton.action == 'none'){
					this.sayDev('Showing End CTA')
					// return
				}
			}
      if (this.ownsPost && this.selectedAnnotations.length) {
        this.pausePlayer("DTA");
        return
      }
			this.exactTime = this.player.currentTime().toFixed(3)
			// var nd = Number(this.exactTime.toString().split(".")[0] + "." + this.exactTime.toString().split(".")[1].substring(0,1))
      var nd = Math.floor(Number(this.exactTime)*10) / 10

      if (nd != this.deciTime) {
        var j = this.jumpTimes[nd.toString()] || this.jumpTimes[nd.toString() + '.0']
        if (j && !this.divDialog && !this.navDialog){
          this.pausePlayer("doTimeUpdate")
          this.stopTimeHandler();
          this.sayDev("💥 JUMP at " + nd)
          this.sayDev("Player paused at " + nd)
          this.pausedUntilClick = false
          this.sayDev("CT1: " + this.player.currentTime())
          this.changeTime(JSON.parse(j[0].data).value)
          // this.sayDev("CT2: " + this.player.currentTime())
          // this.player.play()
          return
        }
        this.mustClickDivs.forEach((d)=> {
          if (d.exact_finish == nd && !d.clicked && !d.pause && !d.hidden && !this.hideAnnotationForScreen(d)) {
            console.log("Stop for must click", d, nd)
            this.stopTimeHandler()
            this.player.currentTime(d.exact_finish)
            this.setPUC("doTimeUpdate " + d.id)
            this.pausePlayer("DTA2");
          }
        })
				this.deciTime = nd
        this.currentTime = Math.floor(this.deciTime)
        var arr = this.dactions[this.deciTime] || this.dactions[this.deciTime.toString() + '.0'] || []
        this.sayDev("🧩 " + this.exactTime + " --> " + nd + " (" + arr.length+")")
        this.doAllStamps(arr)
        if (this.pauseTimes.includes(nd+1)) {
          this.sayDev("Pause in one second from "  + this.deciTime)
          this.lowerVolume(1)
        }
       
        // Check auth capture
        if (this.share.capture_auth_point != 1 && this.shouldAuth && !this.forceAuthInteraction) {
          this.pausePlayer("DTA3")
					if (this.share.captureRequired){
						this.changeTime(this.share.capture_auth_value)
					}
          this.sayDev("TimeUpdate AuthDialog ShouldAuth")
          this.authDialog = true
        }
        // Check payment capture
        if (this.share.capture_payment_point == 2 && (this.share.capture_payment_value == this.deciTime.toString() || this.share.capture_payment_value == this.currentTime.toString()) && this.shouldPay) {
          this.showPayDialog()
        }
        this.lastPauseTime = -1
			}
      if (this.duration < 600) {  this.newSetSeekbar() }

		},
    // lfg
    doAllStamps(arr) {
      // this.sayDev("DaS " + this.deciTime, arr)
      if (this.pausedUntilClick && this.isSafari) {
        this.sayDev("PUC, returning")
        return
      }
      // this.sayDev("DAS at " + this.deciTime + " --> " + arr.length)
      var must_click = false
      var fresh = arr.filter(ann => ['button', 'hotspot', 'text', 'image', 'card'].includes(ann.type_of))
      this.divsActive.forEach((a) => {
        if (arr.includes(a) && !fresh.includes(a)) {
          fresh.push(a)
          // this.doScroll(a.id)
        } else if (a.hidden || a.invisible || this.hideAnnotationForScreen(a)) {
          // this.sayDev("Hidden!")
        } else if (a.must_click && !a.tray_type && !a.clicked && a.exact_finish == this.deciTime && !a.tray_type) {
          this.sayDev("MUST CLICK STOP NOW " + this.deciTime + " for " + a.exact_finish)
          this.stopTimeHandler()
          this.player.currentTime(a.exact_finish)
          fresh.push(a)
          this.pausePlayer("DAS");
          this.setPUC("DoAllStamps " + a.id)
          must_click = true;
          return
        } else if (this.isIOS && this.deciTime < 1) {
          fresh.push(a)
        }
      })
      this.divsActive = fresh
      // this.sayDev("DivsActive", this.divsActive)

      if (must_click) {
        this.sayDev("Must click, returning from DAS ...")
        return
      }
      
      var foundBlocker = false
      
      arr.forEach((x)=> {
        if (x.pointPath) {
          this.startPointAnimation(x)
        }
        if (!foundBlocker && !x.hidden && !x.invisible) {
          if (x.type_of == 'pause' && x.exact_time == this.deciTime) {
            this.sayDev("Found Pause", x)
            this.pausePlayer("DAS2");
            if (x.timer == -1 && !this.ownsPost && this.divsActive.length > 0) {
              this.setPUC("DAS ARR PAUSE")
              this.sayDev(x)
              this.hideSeekbarImmediately()
            }
            this.startArtTimer(x.timer)
          } else if (['reset_viewer', 'jump','switch', 'redirect', 'variable', 'resourceTray', 'post', 'get', 'chatGPT', 'show_card'].includes(x.type_of)) {
            this.showNavigation(x)
          } else if (x.type_of == 'question' && x.exact_time == this.deciTime) {
            this.sayDev("dasQ... qa? ", this.questionAnnotation)
            foundBlocker = true
						this.forceCorrectDone = false
            // this.sayDev("LFG SSQ")
            if (!this.questionAnnotation) {
							if(!x.tray_type)
              this.showSpecificQuestion(x)
            }
          } else if (x.type_of == 'drawing' && x.exact_time == this.deciTime) {
            foundBlocker = true
            this.showSpecificDrawing(x)
          } else if (x.type_of == 'comment') {
            if (!this.activeNotes.includes(x)) { this.activeNotes.push(x) }
          } else if (x.type_of == 'video' && x.exact_time == this.deciTime && !this.divDialog) {
            this.playVideoStamp(x)
            foundBlocker = true;
          } else if (x.type_of == 'audio' && x.exact_time == this.deciTime && !this.divDialog) {
						this.audioAnnotation = x
				    this.playAudioStamp(this.audioAnnotation)
            foundBlocker = true
          } else if(!x.invisible && x.pause && x.exact_time == this.deciTime && x.tray_type == null) {
            this.tryPause(x)
          }
        }
      })

      fresh = []
      this.activeNotes.forEach((d)=> { if (arr.includes(d) || this.isIOS && this.deciTime < 1) { fresh.push(d)} })
      this.activeNotes = fresh
    },
		killMove() {
			this.sayDev("KillMove")
			this.activeDiv.points = []
			this.movePoints = []
			this.clearLines()
			this.activePoint = 0
		},
		resetPoints() {
			this.clearLines()
			this.movePoints.forEach((p)=> {
				p.left = null
				p.top = null
			})
			this.activeDiv.points.forEach((p)=> {
				p.left = null
				p.top = null
			})
			this.activePoint = 0
			this.$toasted.show("Reset!")
		},
		updatePointsTime() {
			this.clearLines()
			if (this.activeDiv && this.activeDiv.points) {
				var x = []
				this.activeDiv.points.forEach((p,i)=> {
					if (p.time >= this.activeDiv.time && p.time <= this.activeDiv.finish) {
						x.push(p)
					} 
				})
				this.activeDiv.points = x
				this.drawPointsEditor()
			}
		},
		setActivePoint(e) {
			var offsetX = e.offsetX
			var offsetY = e.offsetY
			var p = e.target
			var width = p.width
			var height = p.height
			var left = offsetX*100 / width
			var top = offsetY*100 / height
			this.movePoints[this.activePoint].left =  left.toFixed(2)
			this.movePoints[this.activePoint].top = top.toFixed(2) 
			var ap = parseInt(this.activePoint)
			// this.doArrow(ap, this.movePoints)
			this.nextPoint()
			this.doArrows(this.movePoints)
		},
		doArrows(points) {
			// this.sayDev("Do Arrows", points)
			this.clearLines()
			setTimeout(()=> {
				points.forEach((p, index)=> {
					var o = points[index-1]
					if (o && o.left && p.left) {
						setTimeout(()=> {
							var start = document.getElementById('point-' + (o.time).toString())
							var end = document.getElementById('point-' + p.time.toString())
							if (start && end) {
								this.sayDev("")
								var z = new LeaderLine({start: start, end: end, endPlug: 'behind', color: 'yellow', size: 3, path: 'straight', dash: {animation: true}})
								this.leaderLines.push(z)
							}
						}, 100)
					} 
				})
			}, 50)
		},
		drawPointsEditor() {
			// this.sayDev("DrawPointsEditor")
			this.doArrows(this.activeDiv.points)
		},
		togglePreviewPoints(ann) {
			// this.sayDev("TPP!", ann)
			if (ann && ann.points) {
				this.previewPoints = JSON.parse(ann.points)
				this.doArrows(this.previewPoints)
			} else {
				this.clearLines()
				setTimeout(()=> {
					this.previewPoints = false
					this.clearLines()
				}, 100)
			}
		},
		startMove(start, end) {
			if (!this.freeUser && !this.coreUpUser) {
				alert("Trackable Hotspots are available on the CORE, PRO and ENTERPRISE plans. Head to the billing page to upgrade!")
				return
			}
			if (this.isSafari) {
				alert("Cannot add tracking using Safari - please use Chrome, Edge, or Firefox to design hotspot tracking. Once designed, tracking will indeed work on Safari and other browsers!")
				return
			}
			this.clearLines();
			this.movePoints = []
			this.activePoint = 0
			this.sayDev("Start Move from " + start + " to " + end)
			this.activePoint = 0
			this.moveDialog = true
			setTimeout(()=> {
				var i = start
				var count = 0
				while (i <= end) {
					if (this.moveDialog) {
						this.movePoints.push({ time: i, src: '', left: null, top: null})
						setTimeout(this.doTimePoint, count*1500, i, count)
						i++;
						count++
					}
				}
				this.activeDiv.points.forEach((p)=> {
					this.movePoints.forEach((m, i)=> {
						if (m.time == p.time) {
							m.left = p.left
							m.top = p.top
						}
						// this.doArrow(i, this.movePoints)
					})
				})
				// this.doTimePoint(start, 0)
				this.doArrows(this.movePoints)
			}, 250)

		},
		doTimePoint(z, index) {
			if (!this.moveDialog) {
				return
			}
			this.player.currentTime(z)
			setTimeout(()=> {
				this.sayDev("DoTimePoint: " + z + " (" + this.player.currentTime() + ")")
				this.takeScreenshot()
				var m = this.movePoints[index]
        try {
          m.src = document.getElementById('switchCanvas').toDataURL()
        } catch (e) {
          this.sayDev("TimePoint error")
          this.sayDev(e)
        }
			}, 200)
		},
		nextPoint() {
			if (this.activePoint == this.movePoints.length-1) {
				this.finishMove()
			} else {
				this.activePoint++
				var p = this.movePoints[this.activePoint]
				if (!p.src) {
					this.doTimePoint(p.time, this.activePoint)
				}
			}
		},
		finishMove() {
			var x = []
			this.movePoints.forEach((p) => {
				p.src = ''
				p.left = p.left
				p.top = p.top
				if (p.left && p.top) {
					this.sayDev("Pushing P: " + p)
					x.push(p)
				}
			})
			this.activeDiv.points = x
			this.player.currentTime(this.activeDiv.time)
			this.moveDialog = false
			this.drawPointsEditor()
		},
		doEditTitle() {
      this.pausePlayer();
			this.editTitle = true
			setTimeout(()=> {
				document.getElementById('editTitleTextField').select()
			}, 100)
		},
		setColor(c) {
			if (c.includes("linear-gradient")) {
				this.sayDev("Linear Gradient: " + c)
				var x = c.split(",")[1].split(",")[0]
				this.$vuetify.theme.primary = x.trim()
			} else {
				this.$vuetify.theme.primary = c
			}
		},
		savePosition(args) {
			this.sayDev("Geolocation Received")
			this.latitude = args.coords.latitude
			this.longitude = args.coords.longitude
			this.sayDev(this.latitude, this.longitude)
		},
		positionError(args) {
			console.log(args)
		},
		resetStates() {
			this.annotations.forEach((a)=> {
				a.disabled = false; a.invisible = false;
			})
			this.$toasted.show("Reset Interaction States ✅")
			this.showStamps();
		},
		resendConfirmation() {
			this.sayDev("Resend")
			Rails.ajax({
				url: `/resend_confirmation`,
				type: "POST",
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Sent! Please check your email inbox")
          setTimeout(()=> {
            document.getElementById('resend_confirmation').remove()
          }, 10)
				},
				error: (e) => {
					this.$toasted.show("Not able to send. Please contact support.")
				},
			});
		},	
		
		doInteractionMessage(version, object) {
			// this.sayDev("Do Interaction Message", object)
			this.emitDataMessage(object)
		},
		doMarketingConsent(consent) {
      if (typeof(Cookiebot) != 'undefined') {
        var gdpr = Cookiebot.regulations.gdprApplies;
        this.sayDev("gdpr applies:" + gdpr)
        Rails.ajax({
          url: `users/cookie?marketing_consent=` + consent + `&is_gdpr=` + gdpr,
          type: "POST",
          dataType: "JSON",
          success: (data) => {
            this.marketingConsentDialog = false
          },
          error: (e) => {
            this.marketingConsentDialog = false
          },
        });
      }
		},
		doPreventNavs() {
			// this.sayDev("Navs disabled")
			clearTimeout(this.preventNavs)
			this.preventNavs = true
			this.preventNavsTimer = setTimeout(()=> {
				this.preventNavs = false
				// this.sayDev(" - - - Navs enabled")
			}, 700)
		},
		activePausesAtTime(t) {
			var results = []
			this.annotations.forEach((a)=> {
				if (a.exact_time == t && a.pause && !a.is_conditional && a.id != this.activeDiv.id && a.tray_type == null) {
					results.push(a)
				}
			})
			return results
		},
		cancelLinkEdit() {
			this.share.url_title = gon.share.url_title
			this.titleDialog = false
		},
		fixUrlTitle() {
			this.share.url_title = this.share.url_title.replace(" ", "-").replace(/[^a-zA-Z0-9-_]/g, '').substring(0, 40);
		},
		editThumbnail() {
			this.designTab = 0
			this.setSideItemTitle('design')
		},
		throwError() {
			console.log("ThrowError")
			throw new Error('Sentry Error');
		},
		userChoseTextTrack(args) {
			this.sayDev("UCTT", args)
			this.userTextTrack = args
      this.activeTextTrack = args
      this.captionsPopup = false
		},
		fixFinish() {
			if (this.activeDiv.exact_finish && this.activeDiv.exact_finish < this.activeDiv.exact_time) {
				this.activeDiv.finish = Math.min(this.duration, this.min1time(this.activeDiv.exact_time))
			}
		},
		setActiveDivTime(args) {
			this.activeDiv.exact_time = args
			this.activeDiv.time = Math.floor(args)
      if (parseFloat(this.activeDiv.exact_finish) < parseFloat(this.activeDiv.exact_time)) {
        this.activeDiv.exact_finish = parseFloat(this.activeDiv.exact_time) + 1

      }
			this.updatePointsTime()
			this.player.currentTime(args)
			this.checkSeekbar(true)
			this.fixFinish()
      this.forceUpdate("SADT")
		},
		setActiveDivFinish(args) {
			this.activeDiv.exact_finish = parseFloat(args)	
			this.activeDiv.finish = Math.floor(args)		
			this.updatePointsTime()
      if (!this.busy) {
        this.sayDev("SetActiveDivFinish:" + args)
        this.player.currentTime(args)
      }
      this.forceUpdate("SADF")
		},
		setDivTimer() {
			if (this.activeDiv && this.activeDiv.timer < 0 && this.activeDiv.action == 'none') {
				this.activeDiv.action = 'track'
			}
      this.forceUpdate("DivTimer")
		},
		doActiveScrub(isStart, forward, amount) {
			this.sayDev("DoScrub " + isStart  + " " + forward)
      amount = parseFloat(amount)

			if (isStart) {
				var t = Number(parseFloat(this.activeDiv && this.activeDiv.exact_time ||  this.deciTime).toFixed(1))
				this.sayDev("Current T: " + t)
				if (forward) { t = Math.min(t+amount, this.duration-0.1) }
				else {t = Math.max(t-amount, 0)}
				if (this.activeDiv) {
					this.activeDiv.exact_time = this.toSingleFloat(t)
					this.fixFinish()
				}
			} else {
				var t = Number(parseFloat(this.activeDiv && this.activeDiv.exact_finish ||  this.deciTime).toFixed(1))
        this.sayDev("t: " + t, this.activeDiv)
				if (forward) { t = Math.min(t+amount, this.duration-0.1) }
				else {t = Math.max(t-amount, 0)}
        this.sayDev("DO active scrub EF!")
				this.activeDiv.exact_finish = this.toSingleFloat(t)
			}
			this.sayDev("New T  " + t)
			this.player.currentTime(this.toSingleFloat(t))
			this.doPreventNavs()
			this.checkSeekbar(true)
			this.updatePointsTime()
		},
		setActiveDivText(args) {
			this.sayDev("SADT", args)
			// var z = args.rgba	
			// var x = "rgba(" + z.r + ","+z.g+","+z.b+","+z.a+")"
			this.activeDivStyleHold.text_color = args
		},
		setActiveDivBackground(args) {
			this.sayDev("SADB", args)
			// var z = args.rgba	
			// var x = "rgba(" + z.r + ","+z.g+","+z.b+","+z.a+")"
			// this.activeDivStyleHold.background_color = x
			this.activeDivStyleHold.background_color = args
			if (this.adc){
				this.activeCard.background_color = args
				this.cardUpdated++
			}
		},
		setActiveDivBorder(args) {
			this.sayDev("SADBorder", args)
			this.activeDivStyleHold.border_color = args
			if (this.adc){
				this.activeCard.border_color = args
				this.cardUpdated++
			}
		},
		setPostClickColor(args) {
			this.sayDev("SPCC", args)
			this.activeDiv.post_click_color = args
			this.forceUpdate("SPCC")
		},
    setPostClickTextColor(args) {
			this.sayDev("SPCTC", args)
			this.activeDiv.post_click_text_color = args
			this.forceUpdate("SPCTC")
		},
		setActiveDivBorderRadius(){
      this.sayDev("ActiveDivBorderRadius: ", this.activeDivBorderRadius, this.activeDiv.borderRadius)
			this.activeDivBorderRadius = (this.activeDiv.borderRadius / 10 ) + "em"
			if (this.adc && this.activeCard){
				this.activeCard.border_radius = this.activeDivBorderRadius
				this.cardUpdated++
			}
		},
		setActiveCardTitleColor(args) {
			this.activeCard.title.style.color = args
			this.cardUpdated++
		},
		setActiveCardTextColor(args) {
			this.activeCard.text.style.color = args
			this.cardUpdated++
		},
		setActiveCardButtonTextColor(args) {
			this.activeCard.button.style.color = args
			this.cardUpdated++
		},
		setActiveCardButtonColor(args) {
			this.activeCard.button.style.background = args
			this.cardUpdated++
		},
		setActiveCardButtonBorderColor(args) {
			this.activeCard.button.style.border = args
			this.cardUpdated++
		},
		setTrayColor(args) {
			this.resourceTray.color = args
		},
		setTrayIconColor(args) {
			this.resourceTray.icon_color = args
		},
    getBorderRadiusString(ann) {
      var res = null
			if (ann && ann.customStyle && ann.borderRadius) {
				var cs = typeof ann.customStyle == "object" ? ann.customStyle : JSON.parse(ann.customStyle)
				var ratio = this.canvasWidth / cs.videoWidth
				var x = Number(ann.borderRadius) / 10 * ratio
        res = Number(x).toFixed(1) + 'em !important'
			}

      return res
    },
		getAnnotationStyle(annotation) {
			// this.sayDev("GAS", annotation)
			var x = {}
			if (annotation.type_of == 'button') {
				x = Object.assign({}, this.safeStyles.button)
			} else if (annotation.type_of == 'text') {
				x = Object.assign({}, this.safeStyles.comment)
			} else if (annotation.type_of == 'card') {
				x = Object.assign({}, this.safeStyles.card)
				x.cursor = "pointer"
			}
			x.background = annotation.post_click_state == 'color' && annotation.clicked ? annotation.post_click_color : (annotation.background_color || x.background)
			x.border = annotation.border_color ? (annotation.border_color + (this.isMobile ? ' 0.12em' : ' 0.2em') + ' solid !important') : x.border
			x.color = annotation.post_click_state == 'color' && annotation.clicked ? annotation.post_click_text_color : (annotation.text_color || x.color)
			x.borderRadius = this.getBorderRadiusString(annotation) || x.borderRadius
			if (annotation.click_action == 'none' && !annotation.content.includes("iframe")) {
				x.pointerEvents = 'none'
			}
			if (annotation.disabled) {
				x.opacity = 0.5
				x.filter = 'grayscale(0.5)'
				x.pointerEvents = 'none'
				x.cursor = 'not-allowed'
			} else if (annotation.invisible) {
				x.display = 'none'
			}
      // console.log(x)
			return x
		},
		getActiveAnnotationStyle(annotation) {
			var x = {}
			if (annotation.type_of == 'button') {
				x = Object.assign({}, this.safeStyles.button)
			} else if (annotation.type_of == 'text') {
				x = Object.assign({}, this.safeStyles.comment)
			} else if(annotation.type_of == 'card'){
				x = Object.assign({}, this.safeStyles.card)
			}
			x.borderRadius = this.activeDivBorderRadius
			x.background = this.activeDivStyleHold.background_color || x.background
			x.border = this.activeDivStyleHold.border_color ? (this.activeDivStyleHold.border_color + (this.isMobile ? ' 0.12em' : ' 0.2em') + ' solid !important') : x.border
			x.color = this.activeDivStyleHold.text_color || x.color
			// this.po(x)
			return x
		},
		selectCardTemplate(card,i){
			if (this.selectedCard && this.selectedCard.index == i){
				this.selectedCard = null
				return
			} else {
				this.selectedCard = Object.assign({},card)
				this.selectedCard.index = i
			}
			if (this.showCardAction){
				this.activeCard = Object.assign({},card)
				this.cardSelectDialog = false
				if (this.divDialog){
					this.activeDiv.value = card.id
				}else if (this.navDialog){
					this.activeNav.value = card.id
					this.showCardDialog = true
				}
			}
			setTimeout(() => {
				var el = document.getElementById('cardOption')
				if (el){ 
					el.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'center',
					})
					el.classList.add('rainbowBorderThin')
					setTimeout(()=>{
						el.classList.remove('rainbowBorderThin')
					},3000)
				}
			},100) 
		},
		hideCardDialog(){
			this.showCardDialog = false
			this.activeCard = null
			this.playPlayer("HideCardDialog")
		},
		doTransitionPreview() {
			return false
			setTimeout(()=>{
				this.doingTransitionPreview = true
				setTimeout(()=> {
					this.doingTransitionPreview = false
				}, 1000)
			}, 500)
		},
		initializeGtag() {
			if (typeof(Cookiebot) != 'undefined' && !this.isEmbed && Cookiebot.regulations.gdprApplies && !Cookiebot.consented) {
				this.sayDev("🇬🇧 GDPR applies, not loading GA")
				document.cookie = '_cioanonid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.mindstamp.com';
				return
			} 
			// if (!gon.ownsPost && gon.gtag) {
			// 	Vue.use(VueGtag, {
			// 		config: { 
			// 			id: gon.gtag,
			// 			params: {
			// 				cookieFlags: 'secure;samesite=none',
			// 				cookie_flags: 'secure;samesite=none',
              
			// 			}
			// 		}
			// 	});
			// }
			// this.sayDev("✅ GTAG initialized: " + gon.gtag)
		},
		updateGtag() {
			if (typeof(Cookiebot) != 'undefined' && !this.isEmbed && Cookiebot.regulations.gdprApplies && !Cookiebot.consented) {
				return
			}
			if (!this.ownsPost && gon.gtag && this.$gtag) {
				var x = {
					mindstamp_viewer_name: this.guestName || "",
					mindstamp_viewer_email: this.guestEmail || "",
					mindstamp_viewer_phone: this.guestPhone || "",
					mindstamp_viewer_id: this.guestID || "",
				}
				// this.sayDev("🎉 Update GTAG", x)
				this.$gtag.config(x)
			}
		},
		 
		printSummary() {
			if (!this.brandingImage) {
				this.doPrintSummary()
				return
			}
			this.toDataURL(this.brandingImage, (dataUrl)=> {
				var x = document.getElementById('brandingImage')
				x.src = dataUrl
				this.doPrintSummary()
			})
		},
		doPrintSummary() {
			this.isPrinting = true
			setTimeout(()=> {
				var element = document.getElementById('print')
				// html2pdf(element, {
				// 	useCORS: true
				// });
				var opt = {
					pagebreak: { mode: 'avoid-all' },
					allowTaint: true,
					margin:       [10, 0, 10, 0],
					html2canvas:  { scale: 2 },
					filename:     'Viewer Report.pdf',
				};
				html2pdf().from(element).set(opt).save().then(()=> {
					window.onfocus = function () { setTimeout(function () { console.log("CLOSE!"); window.close(); }, 500); }
				})
				this.isPrinting = false
			}, 250)
		},
		setVideoType(item) {
      this.playingSampleAsset = null
			// if (item.key == 7) {
      //   this.advancedStyling = true
      //   return
      // }
      // if (item.key == 8) {
        // this.useSampleVideo()
        // return
      // }
      this.activeType = item.key
      this.sayDev("ActiveType", this.activeType)
      if (![0, 5, 8].includes(this.activeType)) {
        setTimeout(()=> {
          // document.getElementById('linkBox').focus()
          this.flashFocusField('linkBox')
        }, 10)
      }
			if (this.activeType == 6) {
				this.youtubeWarnDialog = true
			}
			if (this.activeType == 5) {
				if (!this.synthesiaVideos) {
					this.fetchSynthesiaVideos()
				}
			}
      if (this.activeType == 10) {
        this.fetchHeygenVideos()
      }
    },
    getVideoButtonStyle(item) {
      if (this.activeType == item.key) {
        return { border: '2px solid ' + gon.color}
      }
    },
		toggleAllHub(all) {
			if (all) {
				this.currentShare.hubspot_interactions = []
			} else {
				this.hubAnnotations.forEach((a)=> {
					this.currentShare.hubspot_interactions.push(a.value)
				})
			}
			// if (!this.currentShare.hubspot_interactions.length) {
			// 	this.sayDev("Currently EMPTY")
			// 	this.hubAnnotations.forEach((a)=> {
			// 		this.currentShare.hubspot_interactions.push(a.value)
			// 	})
			// } else {
			// 	this.sayDev("Not EMPTY")
			// 	this.currentShare.hubspot_interactions = []
			// }
		},
		closeImageDialog() {
			setTimeout(()=> {
				this.tryNextQuestion("cid")
			}, 250)
		},
		removeSecondConditional() {
			this.activeDiv.is_conditional_2 = false
			this.activeDiv.conditional_value_2 = ''
			this.activeDiv.conditional_variable_2 = ''
			this.activeNav.is_conditional_2 = false
			this.activeNav.conditional_value_2 = ''
			this.activeNav.conditional_variable_2 = ''
		},
		resetDesign(num, thumb) {
			Rails.ajax({
				url: `/designs/${this.share.id}/reset/${num}?thumb=${thumb}`,
				type: "POST",
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Successfully set video to use" + (num == 0 ? ' Account Level design' : num == 1 ? ' Group Level design' : ' Organization Owner Design') + " ✅")
          window.location.href = window.location.href.split("?")[0] + "?vtab=design"
					this.sayDev(data)
					this.shareDesign = data.design
					this.shareDesign.resourceTray = data.resourceTray
					this.resourceTray = data.resourceTray
					this.shareDesign.ctaTray = data.ctaTray
					this.ctaTray = data.ctaTray

          this.forceUpdate("ResetDesign")
				}, error: (e) => {
					console.log("Error", e)
					this.$toasted.show("Could not update design")
				}
			})
		},
		takeScreenshot() {
      if (this.source.src.includes("youtube")) {
        this.sayDev("youtube, return no screenshot")
        return
      }
			// this.sayDev("Take Screenshot")
			var video = document.getElementById('video-1_html5_api');
    	var canvas = document.getElementById('switchCanvas')
			var ctx = canvas.getContext('2d');
      if (this.useCoverMode) {
        canvas.width = video.clientWidth;
        canvas.height = video.clientHeight;

        // Calculate the aspect ratios
        const videoAspectRatio = video.videoWidth / video.videoHeight;
        const canvasAspectRatio = canvas.width / canvas.height;

        let sx, sy, sWidth, sHeight;

        if (canvasAspectRatio > videoAspectRatio) {
            // The canvas is wider than the video
            sWidth = video.videoWidth;
            sHeight = video.videoWidth / canvasAspectRatio;
            sx = 0;
            sy = (video.videoHeight - sHeight) / 2;
        } else {
            // The canvas is taller than the video
            sWidth = video.videoHeight * canvasAspectRatio;
            sHeight = video.videoHeight;
            sx = (video.videoWidth - sWidth) / 2;
            sy = 0;
        }

        // Draw the video frame onto the canvas with correct scaling
        ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height);

      } else {
        var ratio = this.canvasWidth / this.canvasHeight;
        var w = this.canvasWidth - 100;
        var h = parseInt(w / ratio, 10);
        canvas.height = h
        canvas.width = w
        // canvas.width = w;
        // canvas.height = h;
        this.sayDev("📸 TSS " + this.player.currentTime())
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(video, 0, 0, w, h);
      }

		},
		variableList() {
      if (this.share.force_session_reset) {
        this.sayDev("🍑 Skip variables for reset")
        return {}
      }
			var doLs = this.lsTest()
			var v = {}
			if (doLs) {
				v = JSON.parse(localStorage.getItem('MS_Variables')) || {}
			}
      v = {}
			this.annotations.forEach((ann)=> {
				if (ann.variable) { 
					v[ann.variable.toLowerCase()] = this.variables[ann.variable.toLowerCase()] || ''
				}
			})
			Object.keys(this.variables).forEach((key)=> { 
				if (!this.skipVars.includes(key)) {
					v[key] = this.variables[key.toLowerCase()] !== null && this.variables[key.toLowerCase()] !== undefined ? this.variables[key.toLowerCase()].toString() || v[key] : ''
				}
			})
			if(doLs){
				var msVars = {}
				Object.keys(v).forEach(x => {
					if (!['viewer_id','pscore', 'cscore', 'doot', 'fullscreen', 'autoplay', 'noview', 'gostamp', 'qstamp', 'qdebug', 'timedebug'].includes(x)){
						msVars[x.toLowerCase()] = v[x]
					}
				})
				// localStorage.setItem('MS_Variables', JSON.stringify(msVars))
			}
			return v
		},
		continueView(shouldResume) {
			this.sayDev("ContinueView: " + shouldResume)
			if (shouldResume) {
				if (this.referVideo && this.referVideo != this.share.token){
          this.sayDev("Refer Video: " + this.referVideo)
					this.continueDialog = false
					this.isMuted = false
					this.tryUnmute()
					this.loadVideo(this.referVideo, this.referTime)
					return
				} else {
          this.sayDev("No refer video....")
        }
				this.sayDev("⭐️ Resume View ⭐️", this.lastPlay)
				this.playID = this.lastPlay.id
				// Set chunks watched and hashed
				this.chunksWatched = this.lastPlay.chunks || []
        this.seconds = this.lastPlay.seconds || []

				// Set seeks and pauses
				this.seeks = this.lastPlay.seeks
				this.pauses = this.lastPlay.pauses

				// Set variables and params
				if (this.lastPlay.params.length) {
					var searchParams = new URLSearchParams(this.lastPlay.params)
					searchParams.forEach((value, key) => {
						this.sayDev(key + " ---> " + value);
						this.variables[key.toLowerCase()] = value;
						this.emitVariableMessage(1, {
							name: key.toLowerCase(),
							value: this.variables[key.toLowerCase()]
						})
					})
				}

				// Set user interactions
				this.userInteractions = this.lastPlayAnnotations

				// Set pScore and cScore
				this.sayDev("🔥 Old Interactions")
				this.variables["pscore"] = this.getParticipationScore()
				this.variables["cscore"] = this.getCorrectnessScore()

				// Set start time
				var length = this.lastPlay && this.lastPlay.chunks.length
				if (length) {
					this.startTime = Math.max(0,Math.min(this.lastPlay.chunks[length-1][1] - 3, this.duration - 10))
          this.sayDev("Resume Start Time: " + this.startTime)
				}
				this.fetchInteractions()
			} else {
				this.sayDev("No resume....")
				this.lastPlay = null 
			}
			this.continueDialog = false

			this.isMuted = false
			this.tryUnmute()
			console.log('continue')
			this.playFromStart();
		},
	
		setCanvasBackgroundImage() {
      if (!this.canvas) {
        return
      }
      this.takeScreenshot()
      try {
        var bg = document.getElementById('switchCanvas').toDataURL()
        var img = new Image();
        img.crossOrigin = "anonymous";  // important - set crossOrigin before src!
        img.src = bg;
        img.onload = ()=> {
          var canvasAspect = this.canvasWidth / this.canvasHeight;
          var imgAspect = img.width / img.height;
          var left, top, scaleFactor;
          var scaleFactor = 1
  
          if (canvasAspect >= imgAspect) {
              scaleFactor = this.canvasWidth / img.width;
              left = 0;
              top = -((img.height * scaleFactor) - this.canvasHeight) / 2;
          } else {
              scaleFactor = this.canvasHeight / img.height;
              top = 0;
              left = -((img.width * scaleFactor) - this.canvasWidth) / 2;
          }
          if (this.canvas) {
            this.canvas.setBackgroundImage(new fabric.Image(img, {
              originX: 'left',
              originY: 'top',
              scaleX: scaleFactor,
              scaleY: scaleFactor,
              left: 0,
              top: 0
            }), this.canvas.renderAll.bind(this.canvas));
    
          }
        };
      } catch(e) {
        this.sayDev("Error settting bg")
        this.sayDev(e)
      }
		},
		toggleCircle(circular,e) {
			if (circular) {
				this.activeDiv.customStyle.borderRadius = 50
			} else {
				this.activeDiv.customStyle.borderRadius = 3
			}
			this.setDivDimensions()
		},
		finishTranscript(shouldSave) {
			if (shouldSave) {
				this.sayDev("Save Transcript")
				this.saveTranscript()
				setTimeout(() => {
					this.fetchSubtitles()
				},2000) 
			} else {
				this.fetchSubtitles()
				this.fetchTranscript()
				this.editTranscriptDialog = false
			}
		},
		startEditLink() {
			var x = window.confirm("Video links can only be set once. They will not be modifiable once set.")
			if (x) {
				this.titleDialog = true
			}
		},
    deleteTranscript() {
      var x = confirm("Are you sure? This cannot be undone.")
      if (x) {
        Rails.ajax({
          url: "/transcripts/" + this.transcript.id,
          type: "DELETE",
          success: (data) => {
            this.$toasted.show("👋🏼 Deleted Transcript")
            setTimeout(()=> {
              window.location.reload()
            }, 1000)
          },
          error: (e) => {
            console.log(e);
          },
        });
      }
    },
		startEditTranscript() {
			if (!this.searchString) {
				this.editTranscriptDialog = true
			} else {
				this.searchString = ''
				setTimeout(()=> {
					this.editTranscriptDialog = true
				}, 1000)
			}
		},
		doVimeoHLS() {
			setTimeout(()=> {
				window.location.reload()

			}, 500)
		},
		toggleClipFullscreen() {
			var c = this.activeDiv.customStyle
			var drag = document.getElementById('drag')
			var v = document.getElementById('divVideo')
			this.sayDev("TCFS", c)
			if (c.width != "100") {
				this.sayDev("Change to FS")
				c.width = "100"
				c.height = "100"
				c.left = "0"
				c.top = "0"
				if (drag) {
					drag.style.width = '100%'
					drag.style.height = '100%'
					drag.style.top = '0'
					drag.style.left = '0'
				}
				if (v) {
					v.style.objectFit = 'cover'
				}

			} else {
				this.sayDev("Change to Non-FS")
				c.width = "33"
				c.height = "33"
				c.left = "33"
				c.top = "25"
				if (drag) {
					drag.style.width = '33%'
					drag.style.height = '33%'
					drag.style.left = '33%'
					drag.style.top = '25%'
				}
				if (v) {
					v.style.objectFit = 'inherit'
				}

			}
			
			
		},
		playPlayers() {
			this.sayDev("PlayPlayers")
			if (this.audioDialog) {
				this.playAudioStampPlayer()
			} else {
				this.playVideoStampPlayer()
			}
		},
		appendHtml(el, str) {
			var div = document.createElement('div');
			div.innerHTML = str;
			while (div.children.length > 0) {
				el.appendChild(div.children[0]);
			}
		},
		setupDialogVideo() {
			this.sayDev("🎃 Setup Dialog Video " + this.currentTime)
			if (this.dialogVideo) {
				var player = videojs('video_dialog_video')
				player.dispose()
				this.sayDev("Disposed of old player")
				this.dialogVideo = false
			}
			if (!this.dialogVideo) {
				var html = '<video class="video-js dialogVideo vjs-fill" oncontextmenu="return false;"id="video_dialog_video" style="color: red" v-bind:style="safeStyles.video" playsinline ></video>'
				this.appendHtml(document.getElementById('dialogVideoWrapper'), html);
				this.sayDev("Appended!")
				var vid = document.createElement("video");
				vid.id = "video_dialog_video"
				var dialogVideo = videojs('video_dialog_video', {
					"controls" : this.isDebug,
				});
				// this.sayDev("Set up dialogvideo: " + this.dialogVideo)
				dialogVideo.on('ended', function () {
					this.dispose()
				})
				dialogVideo.on('ended', () => {
					this.dialogVideo = false
					this.sayDev("ended2")
					if (window.location.href.includes('noresumemedia')) { console.log("No resume media..."); return }
					this.closeVideoDialog();
				})
				dialogVideo.on('error', (e) => {
					if (this.currentUser && this.videoDialog) {
						this.$toasted.show('Could not play video clip')
            console.log(e)
					}
					if (this.isDebug) {
						alert(e)
					}
					this.closeVideoDialog();

				});
				dialogVideo.on('play', (e) => {
					// this.sayDev("DVP 🍄")
					this.loadingMedia = false
					this.touchPlayDialog = false
					this.videoNeedsTouchPlay = false;
          this.hideSafariCaptions()
          setTimeout(()=> {
            this.hideSafariCaptions()
            setTimeout(()=> {
              this.hideSafariCaptions()
              setTimeout(()=> {
                this.hideSafariCaptions()
                setTimeout(()=> {
                  this.hideSafariCaptions()
                  setTimeout(()=> {
                    this.hideSafariCaptions()
                    setTimeout(()=> {
                      this.hideSafariCaptions()
                    }, 200)
                  }, 200)
                  
                }, 500)
              }, 300)
            }, 200)
          }, 100)
					this.updateVideoClips()
					var z = this.safeStyles.video
					// this.sayDev("DVonP", z)
					setTimeout(()=> {
						this.updateVideoClips()
						setTimeout(()=> {
							// this.sayDev("DVonP final catch", z)
							this.updateVideoClips()
						}, 1000)
					}, 300)
				})
				this.dialogVideo = dialogVideo
				this.updateVideoClips()
			} 
      this.hideSafariCaptions()
		},
		doGtagPercentage() {
			var percentage = Math.round(100 * this.currentTime.toFixed(2) / this.duration.toFixed(2))
      // this.sayDev("💥 Gtag percentage: " + percentage)
			if (percentage >= 100) {
				
        this.doEndEvent()
				// this.progressPoints['100'] = true
				this.trackGtagEvent('Reached 100%', this.share.title, 'Video Player Events');
				this.lastProgressEvent = 100
			} else if (percentage > 90 ) {
				if (this.gaProgressLog.includes(90)) { return }
        this.gaProgressLog.push(90)
				// this.progressPoints['90'] = true
				this.trackGtagEvent('Reached 90%', this.share.title, 'Video Player Events');
				this.lastProgressEvent = 90
				this.emitMessage('progress_90')
			} else if (percentage > 75) {
				if (this.gaProgressLog.includes(75)) { return }
        this.gaProgressLog.push(75)
				// this.progressPoints['75'] = true
				this.trackGtagEvent('Reached 75%', this.share.title, 'Video Player Events');
				this.lastProgressEvent = 75
				this.emitMessage('progress_75')
			} else if (percentage > 50) {
				if (this.gaProgressLog.includes(50)) { return }
        this.gaProgressLog.push(50)
				// this.progressPoints['50'] = true
				this.trackGtagEvent('Reached 50%', this.share.title, 'Video Player Events');
				this.lastProgressEvent = 50
				this.emitMessage('progress_50')
			} else if (percentage > 25) {
				if (this.gaProgressLog.includes(25)) { return }
        this.gaProgressLog.push(25)
				// this.progressPoints['25'] = true
				this.trackGtagEvent('Reached 25%', this.share.title, 'Video Player Events');
				this.lastProgressEvent = 25
				this.emitMessage('progress_25')
			} else if (percentage > 10) {
				if (this.gaProgressLog.includes(10)) { return }
        this.gaProgressLog.push(10)
				// this.progressPoints['10'] = true
				this.trackGtagEvent('Reached 10%', this.share.title, 'Video Player Events');
				this.lastProgressEvent = 10
				this.emitMessage('progress_10')
			}
		},
		// gggggg
		trackGtagEvent(action, label, category, value) {
			var x = {
				'event_label': label,
				'event_category': category,
        'viewer_id': this.guestID || ""
			}
			if (value) {
				x['value'] = value
				// this.sayDev("🌕 Track GTAG Event: ", action + ", " + label + ", " + category + ", " + value || '')
			} else {
				// this.sayDev("🌕 Track GTAG Event: ", action + ", " + label + ", " + category)
			}
			x['page_location'] = this.variables.ga_page_location || document.referrer || this.parentDomain || window.location.origin
			if (!this.ownsPost && window.gtag) {
				// this.sayDev("🥰 GTAG Event: " + action, x)
				// this.$gtag.event(action, x);
        window.gtag('event', action, x)

			} else {
        // this.sayDev("No GTAG....")
      }
		},
		updateSnap() {
			this.editGrid.snap = !this.editGrid.snap
			this.startDiv()
		},
		startSupport() {
			if (!window.location.href.includes("testing")) {
				this.openModal('https://share.hsforms.com/13k1Ac4DMQCSD24veB07r6Qceyuk')
				return
			}
			// if (this.share) {
			// 	this.support.link = this.baseUrl + "/w/" + this.share.token
			// 	this.flashFocusField('issueBox')
			// } else {
			// 	this.flashFocusField('linkBox')
			// }
			this.supportDialog = true
		},
		submitSupport(data) {
			window.analytics.track('Submitted Support Request', { support: data })
			this.supportDialog = false
			setTimeout(()=> {
				alert("Your request has been recieved and one of our team members will be in touch shortly. Please don't create another request while this one is pending. Thank you!")
			}, 1000)
		},
		mouseLeaveSeekbar() {
			this.adjustingVolume = false
		},
		startAdjustVolume() {
			if (this.isTouchDevice) {
				return
			}
			this.adjustingVolume = true
		},
		enterToSubmit(e) {
			this.sayDev("EnterToSubmit", e)
			if (!this.blockEnter && this.safeStyles.question.enter_continue) {
        this.blockEnter = true
				e.preventDefault()
				if (this.questionAnnotation.type == 'number'){
					this.checkMinMax()
				} else {
					this.submitReplyMessage()
				}
        setTimeout(()=> {
          this.blockEnter = false
        }, 750)
       
			}
		},
		toggleRecentDialog(active) {
			this.importDialog = false
			this.sayDev("ToggleRecent: " + active)
			if (active && !this.recentInteractions.length) {
				this.getRecentInteractions()
				this.recentDialog = true
			} else {
				this.recentDialog = false
			}
		},
		getRecentInteractions() {
			if (!this.recentInteractions.length) {
				Rails.ajax({
					url: `/annotations/recent`,
					type: "GET",
					dataType: "JSON",
					success: (data) => {
						this.recentInteractions = data
					}, error: (e) => {
						console.log("Error", e)
					}
				})
			}
		},
		checkMinMax() {
			if (!this.replyMessage) {
				return
			}
			if (this.qs.max_value && this.replyMessage > parseInt(this.qs.max_value)) {
				if (this.isMobile) {
					alert("Max Value: " + this.qs.max_value)
				} else {
					this.$toasted.show("Max Value: " + this.qs.max_value)
				}
				this.replyMessage = this.qs.max_value
				this.flashFocusField("numberInput")
				return
			} else if (this.qs.min_value && this.replyMessage < parseInt(this.qs.min_value)) {
				this.replyMessage = this.qs.min_value
				if (this.isMobile) {
					alert("Min Value: " + this.qs.min_value)
				} else {
					this.$toasted.show("Min Value: " + this.qs.min_value)
				}
				this.flashFocusField("numberInput")
				return
			}
			this.submitReplyMessage()
		},
		getParticipationScore() {
			var pids = []
			this.interactionsByType.questions.forEach((q) => {
				if (pids.indexOf(q.id) < 0) {
					pids.push(q.id)
				}
			})
			var qCount = pids.length
			if (qCount == 0) {
				return 100
			}
			var aqs = []
			this.userInteractions.forEach((a) => {
				if (pids.indexOf(a.parent_id) > -1 && aqs.indexOf(a.parent_id) < 0) {
					aqs.push(a.parent_id)
				}
			})
			var aCount = aqs.length
			var sc = 100 * aCount / qCount
			return sc.toFixed(0)
		},
		getCorrectnessScore(forScorm = false) {
			// this.sayDev("Get Correctness Score", this.interactionsByType.questions.length + " / " + this.userInteractions.length)
			this.correctCount = 0
			this.incorrectCount = 0
			var aqs = []
			this.interactionsByType.questions.forEach((q) => {
				this.userInteractions.forEach((i)=> {
					if (i.parent_id == q.id && aqs.indexOf(i.parent_id) < 0 && !i.hidden) {
						var ca = q.content.correct_answer
						// this.sayDev("✅ Parent match", ca)
						aqs.push(i.parent_id)
						if (ca) {
							// this.sayDev("Has correct, now check.....")
							if (i.correct) {
								// this.sayDev("RIGHT!")
								this.correctCount++
							} else {
								// this.sayDev("not right")
								this.incorrectCount++
							}
						}
					}
				})
			})
			if (this.correctCount + this.incorrectCount == 0) {
				return forScorm ? 100 : 0
			} 
			var sc = 100 * this.correctCount / (this.correctCount + this.incorrectCount)
			this.sayDev(sc)
			return sc.toFixed(0)
		},
		showResponses(annotation) {
      this.sayDev("Show Responses")
			if (annotation.type_of == 'comment' || annotation.type_of == 'question' || (annotation.content && annotation.content.style == 'draw')) {
				this.loadReplies(annotation)
			}
			this.responseParent = annotation
			this.showResponseTable = true
		},
		importAnnotations() {
			Rails.ajax({
				url: `/copy/${this.importDetails.from_id}/${this.share.id}/${this.importDetails.children}`,
				type: "POST",
				success: (data) => {
					this.$toasted.show("Imported interactions ✅")
					this.fetchInteractions()
					this.importDialog = false
				},
				error: (e) => {
					console.log(e);
					this.$toasted.show("Could not import interactions")
				},
			});
		},
    importChapters() {
      console.log("Import Chapters")
			Rails.ajax({
				url: `/import/chapters/${this.importDetails.from_id}/${this.share.id}`,
				type: "POST",
				success: (data) => {
					this.$toasted.show("Imported " + data.fresh.length + " Chapters ✅")
          this.chapters = data.all
          this.setChapterTiming()
					this.fetchInteractions()
					this.importChaptersDialog = false
				},
				error: (e) => {
					console.log(e);
					this.$toasted.show("Could not import chapters")
					this.importChaptersDialog = false
				},
			});
		},
		hostName(url) {
			return new URL(url).host
		},
		toggleNavDialog() {
			this.clearReplyAnnotation()
			if (this.navDialog) {
				this.navDialog = false
			} else {
				this.pausePlayer("ToggleNav")
				this.navDialog = true
				this.activeNav = {
					type_of: 'jump',
					action: 'jump',
					time: this.currentTime,
          exact_time: this.deciTime,
          exact_finish: Math.min(this.duration, this.deciTime + 5),
					value: Math.min(this.duration, this.currentTime + 5),
					auth: '',
					field: '',
					body: '',
					head: '',
					httpVar: '',
					variable: '',
					pause: false,
					is_conditional: false,
					conditional_show: true,
					conditional_variable: '',
					conditional_assertion: 'equals',
					conditional_value: '',
          conditional_operator: 0,
					is_conditional_2: false,
					conditional_variable_2: '',
					conditional_assertion_2: 'equals',
					conditional_value_2: '',
					timer: 10,
					click_time: 0,
					ctaTray: this.showCTA,
				}
			}
		},
		updateShareDesign(design) {
			this.shareDesign = design
			this.ctaTray = design.cta_tray
			this.resourceTray = design.cta_tray
		},
		goToBilling() {
			Rails.ajax({
				url: "/charges/stripe_portal",
				type: "POST",
				success: (data) => {
					window.location.href = data.url
				},
				error: (e) => {
					console.log(e);
				},
			});
		},
		getFormattedDate(date) {
			if (!date) {
				return
			}
			date = new Date(date)
			return date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
		},
		getFormattedUTCDate(date) {
			if (!date) {
				return
			}
			date = new Date(date)
			var year = date.getUTCFullYear()
			var month = (1 + date.getUTCMonth()).toString();
			month = month.length > 1 ? month : '0' + month;
			var day = date.getUTCDate().toString();
			day = day.length > 1 ? day : '0' + day;
			var x = month + '/' + day + '/' + year;
			return x
		},
		getUnformattedUTCDate(date) {
			if (!date) {
				return
			}
			date = new Date(date)
			var year = date.getUTCFullYear()
			var month = (1 + date.getUTCMonth()).toString();
			month = month.length > 1 ? month : '0' + month;
			var day = date.getUTCDate().toString();
			day = day.length > 1 ? day : '0' + day;
			var x = year + '-' + month + '-' + day;
			return x
		},
		getFullInteractionParams() {
			this.interactionParams = ""
			for (var key in this.variables) {
				if (this.variables.hasOwnProperty(key) && (['cscore','pscore'].includes(key.toLowerCase()) || !this.skipVars.includes(key.toLowerCase()))) {
					var x = ""
					if (this.interactionParams.length) {
						x = "&"
					}
					this.interactionParams += x + key.toLowerCase() + "=" + encodeURIComponent(this.variables[key.toLowerCase()]);
				}
			}
			return this.interactionParams
		},
		loadDefaultVariables(){
			var variables
			if(this.orgVariables){
				variables = JSON.parse(this.orgVariables)
				Object.keys(variables).forEach(v => {
					if (variables[v] && !this.skipVars.includes(v))  {
            this.variables[v.toLowerCase()] = variables[v]
          }
				})
			} 
			if (this.share.variables){
				variables = JSON.parse(this.share.variables)
				Object.keys(variables).forEach(v => {
					if (variables[v] && !this.skipVars.includes(v))  {
            this.variables[v.toLowerCase()] = variables[v]
          }
				})
			} 
			// if(this.lsTest() && !this.share.force_session_reset){
			// 	variables  = JSON.parse(localStorage.getItem('MS_Variables')) || {}
			// 	Object.keys(variables).forEach(v => {
      //     if (variables[v] && !this.skipVars.includes(v))  {
      //       this.variables[v.toLowerCase()] = variables[v]
      //     }
			// 	})
			// }
		},
		processURLParams() {
			if (!this.ownsPost) {
				var pkey = this.getUrlParam("pkey")
				if (pkey) {
					this.variables["pkey"] = pkey
					var str = "?" + atob(pkey)
					this.sayDev("😳 Pkey --> " + str)
					var searchParams = new URLSearchParams(str);
					this.sayDev(str, searchParams)
				} else {
					this.setNameFromUrl()
					var searchParams = new URLSearchParams(this.windowOriginalSearch);
				}
				searchParams.forEach((value, key) => {
					this.sayDev(key + " ---> " + value)
					this.variables[key.toLowerCase()] = value
					this.emitVariableMessage(1, {
						name: key.toLowerCase(),
						value: this.variables[key.toLowerCase()]
					})
					if (key.toLowerCase() == "name") {
						this.guestName = value.trim()
					} else if (key.toLowerCase() == "email") {
						this.guestEmail = value.trim()
					} if (key.toLowerCase() == "phone") {
						this.guestPhone = value.trim()
					} if (key.toLowerCase() == "custom_id") {
						this.guestID = value.trim()
					}
					if (window.location.href.includes("noview")) {
						this.variables["noview"] = true
					}
				});
        if (this.isPreview) {
          this.variables["preview_mode"] = true
        }
				setTimeout(() => {
					if (!this.ownsPost && this.isProduction && !window.location.href.includes("noresumemedia") && !window.location.href.includes("plink_id") && !window.location.href.includes("pkey") && !this.isDebug && !this.variables.dologs && !this.variables.qdebug) {
						window.history.pushState({}, this.share.title, window.location.origin + window.location.pathname);
					}
					if (!this.isProduction && this.variables.vars) {
						this.po("Variables", this.variables)
					}
				}, 250)
				this.updateGtag()
			}
			this.fetchInteractions()
		},
    setDactions() {
      // this.sayDev("➡️ SetDactions")
      this.dactions = {}
      this.orderedAnnotations.concat(this.resourceTrayAnnotations).concat(this.ctaTrayAnnotations).forEach((a)=> {
        var start = parseFloat(Number(a.exact_time || a.time).toFixed(1))  
        var finish = parseFloat(Number(a.exact_finish || Math.min(a.time + 5.0, this.share.duration)).toFixed(1))
        if (!finish || finish <= start) {
          finish = start+0.1
        }
        if (a.pause && a.timer < 0) {
          finish = start
        }
        if (this.variables.vars) {
          this.sayDev(a.type_of, start + " --- " + finish)
        }
        var i = parseFloat(start.toFixed(1))
        if (['video', 'audio','drawing','question'].includes(a.type_of)) {
            i = i.toFixed(1)
            var x = this.dactions[i] || []
            if (a.type_of == 'drawing') {
              // Push drawings to front
              x.unshift(a)
            } else {
              x.push(a)
            }
            this.dactions[i] = x
        } else {
          while (i <= finish) {
            i = i.toFixed(1)
            var x = this.dactions[i] || []
            x.push(a)
            this.dactions[i] = x
            i = parseFloat((Number(i)+0.1).toFixed(1))
          }
        }
      })
    },
		setVisibleAnnotations(args) {
			// this.sayDev("🧬 SVA " + args)
      this.annotations.forEach((a)=> {
        if (a.type_of == 'image') {
          if ((a.content.includes("b-cdn") || a.content.includes('unsplash.com')) && !a.content.includes("width") && !a.content.includes(".gif")) {
            var w = JSON.parse(a.customStyle).width
            var r = "width="+Math.max(100,parseInt(((this.canvasWidth*1.3) * w/100)))
            a.content += a.content.includes("?") ? "&"+r : "?"+r
          }
				}
      })
      if (this.ownsPost) {
				this.visibleAnnotations = this.annotations
        this.setDactions()
				if (this.annotations && this.annotations.filter(a=>a.click_action =='cart').length){
					this.getShopifySession()
				} else if (this.annotations.filter(a=>a.second_action =='cart').length){
					this.getShopifySession()
				}
        this.resetMarkers()

				return
			}
      var hasPoll = false, hasCart = false, hasChooseProduct = false
			var visible = []
			this.dynamicCount = 0
      this.annotations.forEach((a)=> {
        if (a.dynamic) {
          this.dynamicCount++
        }
        if (a.type_of == 'question' && a.content && a.content.style == "poll") {
          hasPoll = true
        }
				if ((a.click_action == 'cart') && !hasChooseProduct){
					hasCart = true
					var d = JSON.parse(a.data)
					if (!d.value.variant.id){
						hasChooseProduct = true
					}
				}

        if ((a.second_action == 'cart')){
					hasCart = true
				}

				if (a.click_action == 'show_card'){
					if (this.isJSON(a.click_value)){
						var card = JSON.parse(a.click_value)
						if (card && card.parent && card.parent.click_action == 'cart'){
							hasCart = true
						}
					}
				}
        
				if(!this.ownsPost){
					if (a.is_conditional) {
						if (this.shouldShowConditional(a)) {
							visible.push(a)
						}
					} else if (!a.invisible) {
						visible.push(a)
					}
				}
      })
			this.visibleAnnotations = visible
			this.setDactions()
      // this.sayDev("Now resettting markers from sva")
      this.resetMarkers()
			if (hasCart){
				if (hasChooseProduct){
					this.getProducts(1)
				} else { 
					this.getShopifySession()
				}
			}
      if (hasPoll) {
        this.loadChartScripts();
      }
		},
		shouldShowConditional(a) {
			if (!a.is_conditional || this.ownsPost || !this.allowConditional) {
				return true
			}
			if (this.variables.cldebug == "1") {
				this.sayDev("SSC", a)
			}
			var variable_value = (this.variables[a.conditional_variable.toLowerCase()] || "").toString().replace(/\s/g, "").toLowerCase()
			if (!variable_value) {
				variable_value = false
			} else {
				variable_value = variable_value.toLowerCase()
			}
			var value = a.conditional_value.toLowerCase().replace(/\s/g, "").toLowerCase()
			value = value.toLowerCase()
			if (this.variables.cldebug == "1") {
				this.sayDev(value + " 👉🏼 " + variable_value)
			}
			var stringValue = a.conditional_value.toLowerCase().replace(/\s/g, "").toLowerCase()
			var equals = variable_value && value == variable_value

			var includes = variable_value && (value.indexOf(variable_value) > -1 || stringValue.indexOf(variable_value) > -1)
			var contains = variable_value && (variable_value.indexOf(value) > -1 || variable_value.indexOf(value) > -1)
			var hasValue = variable_value && (this.variables[a.conditional_variable.toLowerCase()] != undefined && this.variables[a.conditional_variable.toLowerCase()] != '')
			var valid = false
			if (a.conditional_assertion == 'equals' && equals) {
				valid = true
			} else if (a.conditional_assertion == 'notequals' && !includes) {
				valid = true
			} else if (a.conditional_assertion == 'contains' && contains) {
				valid = true
			} else if (a.conditional_assertion == 'regex') {
				var regex = a.conditional_value.toLowerCase()
				var exp = new RegExp(this.makeRegex(regex))
				valid = exp.test(variable_value)
			} else if (a.conditional_assertion == 'notcontains' && !contains) {
				valid = true
			} else if (a.conditional_assertion == 'exists' && hasValue) {
				valid = true
			} else if (a.conditional_assertion == 'notexists' && !hasValue) {
				valid = true
			} else if (a.conditional_assertion == 'lessthan' && !isNaN(this.variables[a.conditional_variable.toLowerCase()])) {
				var num = parseFloat(this.variables[a.conditional_variable.toLowerCase()])
				valid = (num || num == 0) && num < parseFloat(value)
			} else if (a.conditional_assertion == 'greaterthan' && !isNaN(this.variables[a.conditional_variable.toLowerCase()])) {
				var num = parseFloat(this.variables[a.conditional_variable.toLowerCase()])
				valid = (num || num == 0) && num > parseFloat(value)
			}
			if (this.variables.cldebug == "1") {
				this.sayDev("🐊 ShouldShow...." + a.content, a.id)
				this.sayDev(" - - - Look for " + variable_value + " in " + value)
				this.sayDev(" - - - Variable: " + a.conditional_variable)
				this.sayDev(" - - - Assertion: " + a.conditional_assertion)
				this.sayDev(" - - - Value: " + value)
				this.sayDev(" - - - Variable Value: " + variable_value)
				this.sayDev(" - - - Includes: " + includes)
				this.sayDev(" - - - Contains: " + contains)
				this.sayDev(" - - - Valid: " + valid, valid ? "✅" : "💔")
			}


			var valid2 = true

			if (a.is_conditional_2) {
				var variable_value_2 = (this.variables[a.conditional_variable_2.toLowerCase()] || "").toString().replace(/\s/g, "").toLowerCase()
				var value_2 = a.conditional_value_2.toString().replace(/\s/g, "").toLowerCase()
				var stringValue_2 = a.conditional_value_2.toLowerCase().replace(/\s/g, "")
				var equals_2 = value_2 == variable_value_2
				var includes_2 = value.indexOf(variable_value_2) > -1 || stringValue_2.indexOf(variable_value_2) > -1
				var contains_2 = variable_value_2.indexOf(value_2) > -1 || variable_value_2.indexOf(value_2) > -1
				var hasValue_2 = this.variables[a.conditional_variable_2.toLowerCase()] != undefined && this.variables[a.conditional_variable_2.toLowerCase()] != ''
				
				
				valid2 = false
				if (a.conditional_assertion_2 == 'equals' && equals_2) {
					valid2 = true
				} else if (a.conditional_assertion_2 == 'notequals' && !includes_2) {
					valid2 = true
				} else if (a.conditional_assertion_2 == 'contains' && contains_2) {
					valid2 = true
				} else if (a.conditional_assertion_2 == 'regex') {
					var regex = a.conditional_value_2.toLowerCase()
					var exp = new RegExp(this.makeRegex(regex))
					valid = exp.test(variable_value)
				} else if (a.conditional_assertion_2 == 'notcontains' && !contains_2) {
					valid2 = true
				} else if (a.conditional_assertion_2 == 'exists' && hasValue_2) {
					valid2 = true
				} else if (a.conditional_assertion_2 == 'notexists' && !hasValue_2) {
					valid2 = true
				} else if (a.conditional_assertion_2 == 'lessthan' && !isNaN(this.variables[a.conditional_variable_2.toLowerCase()])) {
					var num = parseFloat(this.variables[a.conditional_variable_2.toLowerCase()])
					valid2 = (num || num == 0) && num < parseFloat(value_2)
				} else if (a.conditional_assertion_2 == 'greaterthan' && !isNaN(this.variables[a.conditional_variable_2.toLowerCase()])) {
					var num = parseFloat(this.variables[a.conditional_variable_2.toLowerCase()])
					valid2 = (num || num == 0) && num > parseFloat(value_2)
				}
				
				if (this.variables.cldebug == "1") {
					this.sayDev("🐊 ShouldShow 2...." + a.id, a.content)
					this.sayDev(" - - - Look for " + variable_value_2 + " in " + value_2)
					this.sayDev(" - - - Variable: " + a.conditional_variable_2)
					this.sayDev(" - - - Assertion: " + a.conditional_assertion_2)
					this.sayDev(" - - - Value: " + value_2)
					this.sayDev(" - - - Variable Value: " + variable_value_2)
					this.sayDev(" - - - Includes: " + includes_2)
					this.sayDev(" - - - Contains: " + contains_2)
					this.sayDev(" - - - Valid: " + valid2, valid2 ? "✅" : "💔")
				}
			}

			var isValid = valid && valid2
			var str = "🍌 " + a.content + " ---> " + valid 
			if (a.is_conditional_2) {
				if (a.conditional_operator == 1) {
					isValid = valid || valid2
					str += " OR " + valid2	
				} else {
					str += " AND " + valid2	
				}
			}
			// this.sayDev(str, a)
			if (this.variables.cldebug == "1") { 
				this.sayDev("😀 Show / Valid: " + a.conditional_show + " / " + isValid)
			}
			if (a.conditional_show && isValid || (!a.conditional_show && !isValid)) {
				return true
			} 
			return false
		},
		setShareDesign(design) {
			this.sayDev("New Design", design)
			this.design = design
      this.shareDesign = design
      this.shareDesign.updated_at = design.updated_at
			this.resourceTray = design.resourceTray
			this.ctaTray = design.ctaTray
			this.designDialog = false
			if (this.player) {
				this.player.poster(this.videoPoster)
			}
      this.doingSave = false
			this.updateVideoClips()
			this.setStrings()
      this.forceUpdate("SetShareDesign")
		},
		updateVideoClips() {
			// this.sayDev("UVC", this.interactionsByType.videos.length + " , " + this.questionDialog + " , " + this.questionAnnotation)
			if (!this.interactionsByType.videos.length && !(this.questionDialog && !this.questionAnnotation)) {
				return
			}
			var z = this.safeStyles.video
			// this.sayDev("⭐️ ⭐️ ⭐️ UpdateVideoClips ⭐️ ⭐️ ⭐️", z)
			var v = document.getElementById('video_dialog_video')
			if (v) {
				v.style.position = "absolute"
				v.style.height = this.safeStyles.video.height
				v.style.width = this.safeStyles.video.width
				v.style.right = this.safeStyles.video.right
				v.style.left = this.safeStyles.video.left
				v.style.top = this.safeStyles.video.top
				v.style.maxWidth = this.safeStyles.video.maxWidth || "100%"
				v.style.maxHeight = this.safeStyles.video.maxHeight || "100%"
				// this.sayDev("✅ Updated", this.safeStyles.video)
				var x = document.getElementById('video_dialog_video_html5_api')
				if (x) {
					if (this.safeStyles.video.width == "100%" || this.questionDialog || this.questionAnnotation) {
						// this.sayDev("⚡️ COVER")
						x.style.objectFit = 'cover'
					} else {
						// this.sayDev("⚡️ Contain")
						x.style.objectFit = 'contain'
					}
				}
			}
		},
		choose: function (choiceNumber) {
			// this.isChoosingAnswer = true
			if (this.chosenAnswer && this.correctAnswer) {
				return
			}
			this.clickAnswer(choiceNumber)
			return
		},
		checkSeekbar(evenPaused) {
      // this.sayDev("🦷 CheckSeekbar")
			if (this.player && (!this.player.paused() || evenPaused)) {
				var t = this.player.currentTime()
				var d = this.player.duration()
				var p = t * 100 / d
				this.updateSeekbar(p, false)
				// this.currentTime = Math.floor(t)
				// this.sayDev("CSB! --> " + this.currentTime + " / " + this.deciTime)
			}
		},
		tryAutoplay() {
      this.sayDev("🧿🧿🧿 TryAutoplay")
      if (this.share.duration && this.startTime >= this.share.duration) {
        this.startTime = 0
      }
      this.player.currentTime(this.startTime || 0)
      this.player.muted(true)
      this.isMuted = true
			this.introDialog = false
			this.playFromStart();
			setTimeout(() => {
				if (this.played) {
					if (gon.startTime) {
						// this.changeTime(gon.startTime)
						this.playPlayer("TAP");
					}
				} else {
					setTimeout(() => {
						if (this.played) {
							if (gon.startTime) {
								// this.changeTime(gon.startTime)
								this.playPlayer("TAP");
							}
						} else {
							if (!this.authDialog) {
								this.sayDev("Resume from AutoPlay...")
								this.resumeDialog = true
							}
						}
					}, 5000);
				}
			}, 250);
		},
		startCollect(data, parentObj) {
      this.pausePlayer("startCollect")
			setTimeout(() => {
				this.setReplyAnnotation(parentObj)
			}, 500)
			this.collectPrompt = data.value
			this.flashFocusField('collectBox', 1500, true)
		},
    finishLoadVideo(data, time) {
      this.sayDev("FinishLoadVideo", data)
      this.source = data.videoSource
      this.startTime = time || 0
      this.sayDev("Start Time: " + this.startTime)
      this.sayDev("✨ Change Src: ", { type: this.source.type, src: this.source.src })
      this.player.src({ type: this.source.type, src: this.source.src })

      this.removeCaptions()
      this.sayDev(data)
      var url = window.location.origin + "/w/" + data.share.token
      if (this.isEmbed) {
        url =  window.location.origin + "/e/" + data.share.token
      }
      this.blockSaveChunks = false
      this.saveChunks(true)
      this.share = data.share
      this.backupSource = data.backupSource
      if (this.activeTextTrack != "Off") {
        var found = false
        this.share.captions.forEach((c)=> {
          if (c.label == this.activeTextTrack) {
            found = true
          }
        })
      }
      if (!found) {
        if (!this.userChoseTextTrack) {
          this.activeTextTrack = this.capitalizeFirstLetter(this.variables.captions_track || this.share.default_captions || this.activeTextTrack || "Off")
        }
      } else {
        this.sayDev("Found active track across sources: " + this.activeTextTrack)
      }
      this.sayDev("🎡 Loaded New Share", this.share)
      this.resetVariables()
      document.title = this.share.tab_title || this.share.title
      this.shareDesign = data.design
      this.resourceTray = data.design.resourceTray
      this.ctaTray = data.design.ctaTray
      this.setCurrentShare()
      this.questionDialog = false
      this.questionAnnotation = null
      this.annotations = data.anns
      this.sayDev("New Annotations: " + this.annotations.length)
      this.lastQuestionID = null

      // Preserve disabled / mustclick, also done in HNA
      var todo = []
      this.annotations.forEach((a)=> {
        if (a.exact_time == this.startTime) {
          todo.push(a)
        }
        if (a.must_click && (a.post_click_state == 'disabled' || a.post_click_state == 'hidden')) {
          this.userInteractions.forEach((i)=> {
            if (i.type_of == 'click' && i.parent_id == a.id) {
              this.sayDev("🍣 Mark " + a.content + " as clicked")
              a.clicked = true
              if (a.post_click_state == 'disabled') {
                this.disableInteraction(a)
              } else if (a.post_click_state == 'hidden') {
                this.hideInteraction(a)
              }
            }
          })
        }
      })
      this.sayDev("TODOS --> " + todo.length)
      if (todo.length) {
        this.questionSetIndex = 0
        this.player.currentTime(this.startTime + 0.01)
        this.doAllStamps(todo)
      }
      // this.doAllStamps(todo)
      this.setVisibleAnnotations()
      this.orderedAnnotations.forEach((a)=> {
        this.sayDev(a.exact_time + " --> " + a.type_of)
      })
      this.shareToken = this.share.token
      window.history.pushState({}, this.share.title, url + window.location.search);
      this.sayDev(this.source.type, data.videoSource.type)
      var shouldReset = !this.isIOS && !this.isIpad && this.source.type != "video/youtube" && data.videoSource.type != "video/youtube"
      this.sayDev("Should Reset: " + shouldReset)

      this.post_id = this.share.post_id
      
      this.fetchInteractions()
      if (this.isMuted) {
        this.player.muted(true)
      }
      // this.player.load()
      this.doCaptions()
      this.duration = Math.floor(this.player.duration())
      if (this.source.type == "video/youtube") {
        setTimeout(()=> {
          // console.log("CT: " + this.player.currentTime())
          if (this.player.currentTime() < 1) {
            this.sayDev("Resume from YouTube...")
            this.resumeDialog = true
          }
        }, 2500)
      }
      if (this.share.capture_auth_point != 1 && this.shouldAuth ) {
        this.sayDev("Should Auth!")
        this.authDialog = true
        this.stopSwitchVideo("FLS1")
        return
      }
      if (this.shouldPay) {
        this.sayDev("Should Pay!")
        this.showPayDialog()
        this.stopSwitchVideo("FLS2")
        return
      }
      if (this.pausedUntilClick) {
        this.sayDev("Paused until...")
        this.stopSwitchVideo("FLS2")
        return
      }
    },
    stopSwitchVideo(from) {
      this.sayDev("StopSwitchVideo from " + from)
      this.duration = Math.floor(this.player.duration())
      this.resetMarkers()
    },
    menuLoadVideo: function(token) {
			if (gon.action != 'posts-show'){
				window.location.href = '/w/' + token
				return
			}
      this.clickSideItem({title: 'Interactions'})
      if (token != this.share.token) {
        this.blockToggle = true
        this.loadVideo(token);
        this.referVideoStack.unshift(this.share.token)
        document.getElementById('vidMenuButton').click();
        setTimeout(()=> {
          this.blockToggle = false
        }, 2000)
      }
    },
		loadVideo: function(token, time) {
			this.sayDev("✨ Load Video: " + token + " at time " + time )
			this.switchingVideo = true
			if(token != this.share.token){
				this.referVideo = this.share.token
			} else{
				this.referVideo = false
			}
      this.sayDev("Refer Video: " + this.referVideo)
			this.takeScreenshot()
      if (this.variables.pauseshot == "yes") {
        return
      }
      if (this.earlySwitches[token]) {
        this.sayDev("Preloaded already, finishloadvideo now")
        this.finishLoadVideo(this.earlySwitches[token], time)
      } else {
        this.sayDev("Don't have it load then finishload")
        Rails.ajax({
          url: `/w/${token}/info?` + this.getFullInteractionParams(),
          type: "GET",
          dataType: "JSON",
          success: (data) => {
            this.finishLoadVideo(data, time)
          }, error: (data) => {
            // console.log(e)
            if (data.share && data.share.privacy != 0) {
              var url = window.location.origin + "/w/" + token
              if (this.isEmbed) {
                url =  window.location.origin + "/e/" + token
              }
              window.history.pushState({}, data.share.title, url + window.location.search);
              window.location.href = url + window.location.search
              return
            }
            this.stopSwitchVideo("LoadVideo")
            this.$toasted.show("Could not change video. Make sure the other video is published!")
            this.hideAllDialogsAndPlay("LoadVideo")
          }
        })
      }
		},
		resetVariables() {
			this.sayDev("💙 💙 💙 💙 💙 RESET VARIABLES 💙 💙 💙 💙 💙 ")
			// this.isMuted = false
			this.skipAuth = false
			this.annotations = []
			this.visibleAnnotations = []
			this.filteredAnnotations = []
			this.lastPauseTime = -1
			this.played = false
			this.playID = undefined
			this.chunkStart = 0
			this.currentTime = 0
      this.seconds = []
			this.chunksWatched = []
			this.chunksHashed = []
			this.pauses = []
			this.seeks = []
			this.correctCount = 0
			this.playCreated = false
			this.playError = false
			this.incorrectCount = 0
			this.chapters = []
			this.interactions = []
			this.divsActive = []
			this.clearScreenInteractions()
			this.markers = []
			this.duration = 0
			this.clearReplyAnnotation()
			this.captureName = this.share.captureName
			this.captureEmail = this.share.captureEmail
			this.capturePhone = this.share.capturePhone
			this.captureID = this.share.captureID
      this.blockSaveChunks = false
			this.sayDev("🍓 Reset Captures: " + this.captureName + " / " + this.captureEmail)
			// this.transcript = {}
		},
		changeVideo(token, time) {
			this.sayDev("Change Video to " + token + " at time " + time)
      this.blockSaveChunks = false
      this.pausedUntilClick = false
      this.saveProgress(this.currentTime.valueOf())
			this.toggleMenu(false)
			this.showCTA = false
			this.referTime = this.currentTime.valueOf()
      this.switchVideoTransition = this.shareDesign.switch_transition
			this.loadVideo(token, time)
		},
		getTimeItems(){
      // this.sayDev("💥 GET TIME ITEMS 💥")
			this.timeItems = []
			var chapterTimes = this.chapters.map(chap => {return chap.start})
			var chapterTitles = this.chapters.map(chap => {return chap.title})
			for (var i = 0; i <= this.duration; i+=1) {
				var ct = chapterTimes.indexOf(i)
				this.timeItems.push({
					text: this.formattedSeconds(i) + (ct != -1 ?  ' -- Chapter: ' + chapterTitles[ct]  : '' ),
					value: i
				})
			}
		},
		updateChapters(data) {
			this.chapters = data
      this.setChapterTiming()
			this.filteredChapters = data
			this.searchString = ''
			this.resetChapterObject()
		},
		startChapters() {
			if (!this.played) {
				console.log('chap')
				this.playFromStart()
			}
			setTimeout(() => {
				this.pausePlayer()
			}, 100)
			this.resetChapterObject()
			this.chapterObject.start = this.currentTime
			this.chaptersDialog = true
		},
		exitChapters() {
			this.resetChapterObject()
			this.chaptersDialog = false
		},
		editChapter(chapter) {
			this.chapterObject = chapter
		},
		resetChapterObject() {
			this.chapterObject = {
				id: '',
				title: '',
				description: '',
				start: this.currentTime,
				finish: '',
				source_id: gon.share.source_id,
			}
		},
		getChapterFormData() {
			var data = new FormData
			data.append("chapter[title]", this.chapterObject.title)
			data.append("chapter[description]", this.chapterObject.description)
			data.append("chapter[start]", this.currentTime)
			data.append("chapter[finish]", this.chapterObject.finish)
			data.append("chapter[source_id]", this.chapterObject.source_id)
      data.append("chapter[video_id]", this.share.id)
			data.append("chapter[user_id]", this.currentUser.id)
			return data
		},
		createChapter() {
			if (!this.chapterObject.title) {
				this.$toasted.show("Enter Chapter Title")
				this.flashFocusField('chapter_title')
				return
			}
			Rails.ajax({
				url: `/chapters`,
				type: "POST",
				data: this.getChapterFormData(),
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Created Chapter")
					this.updateChapters(data)
					this.getTimeItems()
				}
			});
		},
		updateChapter() {
			Rails.ajax({
				url: `/chapters/${this.chapterObject.id}`,
				type: "PATCH",
				data: this.getChapterFormData(),
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Updated Chapter")
					this.updateChapters(data)
					this.getTimeItems()
				}
			});
		},
		deleteChapter(chapter) {
			Rails.ajax({
				url: `/chapters/${this.chapterObject.id}`,
				type: "DELETE",
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Deleted Chapter")
					// console.log(data)
					this.updateChapters(data)
					this.getTimeItems()
				}
			});
		},
		focusIfButton() {
			if (this.activeDiv.type_of == 'button') {
				if (this.isMobile && this.activeDiv.content != '') {

				} else {
					this.flashFocusField('activeDivEditCaption')
				}
			}
		},
		playTutorial(t) {
			this.pausePlayer()
      var url = 'https://embed.mindstamp.com/e/'  + t.token + '?from=tutorial'
      if (this.currentUser) {
        url+="&name="+this.currentUser.full_name+"&email="+this.currentUser.email
      }
			if (true || this.isProduction) {
				this.openPopout(url)
			} else {
				this.openPopout('http://localhost:3000/embed/iqOCPSrhfKiC')
			}
			this.firstVideoDialog = false
			setTimeout(()=> {
				this.introDialog = true
			}, 1000)
			this.tryTrack("Played " + t.name + " Tutorial", {
				share: this.share
			})
		},
		openPopout(url, opt=0) {
			lity(url);
			if (opt){
				setTimeout(() => {
					var el = document.getElementsByClassName("lity-container")[0]
					if (opt == 1){
						el.style.transform = "scale(0.6)"
					} else if(opt == 2){
						el.style.transform = "scale(0.4)"
					}
					el = document.getElementsByClassName("lity-container")[0]
					el.style.textAlign = "-webkit-center"
					el.style.verticalAlign = "inherit"
				}, 1000)
			}
		},
		saveUrlTitle() {
			var data = new FormData
			data.append("title", this.share.url_title.toLowerCase())
			Rails.ajax({
				url: `/shares/${this.share.id}/title`,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (mData) => {
					this.$toasted.show("Updated URL ")
					this.titleDialog = false
				}, error: (e) => {
					this.$toasted.show("Could not update video URL. Please make sure it's unique amongst your videos.")
					this.share.url_title = ""
				}
			})
		},
		setDefaultCaption(captions) {
      this.sayDev("SetDefaultCaptions")
      this.sayDev(captions)
			this.share.default_captions = captions
			this.activeTextTrack = captions
			this.updateShare()
		},
		deleteCaption(label) {
			var data = new FormData
			data.append("caption[label]", label)
			Rails.ajax({
				url: `/shares/${this.share.id}/captions/delete`,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (mData) => {
					this.$toasted.show("Deleted Caption file")
					this.share = mData
          this.subtitles = false
          this.hasSubtitles = false
					this.uploadCaption = {
						label: null,
						url: null
					}
					this.updateCaptions()
					// this.tryTrack("Added Voice Note", { url: window.location.href, audio_id: mData.id, size: this.audioResult.size })
				}, error: (e) => {
					this.$toasted.show("Could not delete captions")
					this.uploadCaption = {
						label: null,
						url: null
					}
				}
			})
		},
		finishUploadCaption(url) {
			this.sayDev("Finish Upload Caption", url)
      this.sayDev("Share " + this.share)
      this.sayDev(this.share)
			this.uploadCaption.url = url
      var sid = this.share.id
      this.sayDev("SID: " + sid)
      sid = sid || this.currentShare.id
      this.sayDev("SID2: " + sid)
      sid = sid || gon.share.id
      this.sayDev("SID3: " + sid)
			var data = new FormData
			data.append("caption[label]", this.uploadCaption.label)
			data.append("caption[url]", this.uploadCaption.url)
			data.append("caption[share_id]", this.share.id)
			Rails.ajax({
				url: `/shares/${this.share.id}/captions`,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (mData) => {
          this.sayDev("Refreshing....")
          window.location.href = window.location.origin + window.location.pathname + "?vtab=captions"
          return
					this.share = mData
          this.setCurrentShare()
          if (this.isProduction) {
            this.hasTranscript = true
          }
          this.fetchTranscript()
          if (this.share.captions) {
            this.share.captions.forEach((c) => {
              if (c.label == this.uploadCaption.label) {
                this.player.addRemoteTextTrack({ label: c.label, src: c.url }, false)
              }
            })
            if (this.share.captions.length == 1) {
              this.activeTextTrack = this.share.captions[0].label
            }
          }
					this.uploadCaption = {
						label: null,
						url: null
					}
          this.fetchInteractions();
					this.updateCaptions()
          setTimeout(()=> {
            this.fetchSubtitles(true)
          }, 2000)
					// this.tryTrack("Added Voice Note", { url: window.location.href, audio_id: mData.id, size: this.audioResult.size })
				}, error: (e) => {
					this.$toasted.show("Could not create captions")
					this.uploadCaption = {
						label: null,
						url: null
					}
				}
			})
		},
		startUploadCaption(label) {
			this.uploadCaption.label = label
		},
		updateCaptions: function () {
			// this.sayDev("UpdateCaptions: " + this.activeTextTrack)
			setTimeout(() => {
				var found = false
				this.textTracks = ["Off"]
        if (this.share.captions) {
          this.share.captions.forEach((l) => {
            this.textTracks.push(l.label)
            if (l.label == this.activeTextTrack) {
              found = true
            }
          })
        }
				if (!found) {
					this.activeTextTrack = "Off"
				}
				if (!this.activeTextTrack) {
					return
				}

				var i = 0
				if (this.player) {
					var tracks = this.player.textTracks()
					while (i < tracks.length) {
						if (tracks[i].label == this.activeTextTrack) {
							tracks[i].mode = 'showing';
							this.fetchSubtitles()
						} else {
							tracks[i].mode = 'hidden'
						}
						i++
					}
				}
			}, 200)
		},
		makeTranscript(){
			var data = new FormData
			data.append("url", this.transcriptionUrl)
			Rails.ajax({
				url: `/video/transcribe/${this.share.id}`,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Transcript started!")
					this.doTranscribe = false
					this.captionsDialog = false
					this.fetchTranscript()
					window.location.reload()
				}, error: (e) => {
					this.$toasted.show("Could not transcribe video")
				}
			})
		},
		getShouldHover(adi) {
      if (adi.do_spotlight) {
        return false
      }
			var x = false
			if (adi.type_of == 'hotspot' && this.isJSON(adi.data)) {
				var y = JSON.parse(adi.customStyle)
				if (y.hover == undefined) {
					x = true
				} else {
					x = y.hover
				}
			}
			var z = x && !this.isMobile && !this.isExperimental
			return z
		},
		showHotspotRing(activeItem) {
			var x = JSON.parse(activeItem.customStyle)
			if (x.ring == undefined) {
				return true
			} else {
				return x.ring
			}
		},
		showCaption(activeItem) {
			var x = this.isJSON(activeItem.customStyle) ? JSON.parse(activeItem.customStyle) : activeItem.customStyle
			if (!x || !x.caption || x.caption == undefined) {
				return false
			} else {
				return x.caption
			}
		},
		useSampleVideo(title, length, annotations, first) {
			this.switchingVideo = true
			this.video_type = 'MP4'
			// this.video_key = 'https://player.vimeo.com/external/658776515.m3u8?s=626112817ab0fb26959ff45f23c5f74de5173c90'
			// this.video_key = 'https://player.vimeo.com/external/696158322.m3u8?s=bb32884cd47f084a8b1233a363b7ba8081b89c86'
			this.video_key = this.sampleVideoUrl
			this.newVideo.message = 'This interactive video was created on Mindstamp! This message is customizable'
			// this.newVideo.thumbnail = 'https://resource-cdn.mindstamp.com/assets/images/black_thumbnail.png'
			if(typeof title == "string"){
				this.newVideo.title = title
        this.newVideo.message = "This Shoppable Interactive Video was created using Mindstamp!"
        this.video_type = 'MP4'
        if (length == 30) {
          // this.video_key = 'https://player.vimeo.com/external/751409694.m3u8?s=cb51577fba6b514247c06509bdb7a135032d4031'
          this.video_key = 'https://player.vimeo.com/progressive_redirect/playback/751409694/rendition/1080p/file.mp4?loc=external&signature=e4bbe0ab775b6eb8b575aba1e0f8a28409849633808d7c9cd6b53580f2a79b23' 
        } else  if (length == 60) {
          // this.video_key = 'https://player.vimeo.com/external/751409686.m3u8?s=5093e7e3d45861e6d5e5498f6d9a6adac3e42394'
          this.video_key = 'https://player.vimeo.com/progressive_redirect/playback/751409686/rendition/1080p/file.mp4?loc=external&signature=dbc34369c84dbf13385929e6301d56dc0278b47a767b0f6247faa09e734e0bbf'
        } else  if (length == 90) {
          // this.video_key = 'https://player.vimeo.com/external/751409677.m3u8?s=ffc0566bc92bc985ad68e58c0528e76954298bed'
          this.video_key = 'https://player.vimeo.com/progressive_redirect/playback/751409677/rendition/1080p/file.mp4?loc=external&signature=f39a59262fc61fe8135acfe41b6ebf1130e314c971abcf41a6aa9d311241dfd7'
        }
			} 
			if (window.location.href.includes('?welcome=true')){
				this.newVideo.title = 'Interactive Video #1'
				annotations = this.makeBeginnerAnnotations()
			}
			this.queueComplete(annotations)
		},
		makeFirstVideo(){
			this.useSampleVideo(null,null,null,true)
		},
		makeShoppableVideo(title, length, annotations, src, token){
			this.shoppableSrc = src
			if (token){
				var annData = new FormData()
				annotations.forEach(a => {
					annData.append("annotation[type_of]", a.type_of )
					annData.append("annotation[content]", a.content)
					annData.append("annotation[time]", a.time)
					annData.append("annotation[exact_time]", a.time)
					annData.append("annotation[finish]",a.finish )
					annData.append("annotation[exact_finish]",a.finish )
					annData.append("annotation[data]", a.data )
					annData.append("annotation[click_action]", a.click_action)
					annData.append("annotation[transition]", a.transition)
					annData.append("annotation[animation]", a.animation != null ? a.animation : '')
					annData.append("annotation[customStyle]", a.customStyle)
					annData.append("annotation[token]", token)
					this.createAnnotation(annData, false)
				})
				setTimeout(() => {
					window.location = `/w/${token}`
				},1000) 
			} else{
				this.useSampleVideo(title, length, annotations)
			}
		},
		makeBeginnerAnnotations(){
			var annotations = []
			var gradient = 'linear-gradient(180deg, rgba(253,228,184,1) 0%, rgba(255,222,253,1) 35%, rgba(255,222,253,1) 65%, rgba(97,231,255,1) 100%)'
			annotations.push(
				{
					'type_of': 'text',
					'content': `<marquee scrollamount="8">Welcome to Mindstamp, ${gon.current_user.first_name}!</marquee>`,
					'time': 0,
					'exact_time': 0,
					'finish': 9,
					'exact_finish': 9,
					'background_color': 'rgba(0,0,0,0)',
					'text_color': '#000000',
					'animation': null,
					'transition': 'fade',
					'data': `{"action":"track","value":""}`,
					'click_action': 'track',
					'customStyle': '{"left":"0","top":"1.2","width":"100","height":"12","fontSize":"30.0","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
				{
					'type_of': 'image',
					'content': 'https://resource-cdn.mindstamp.com/assets/images/logos/Black.png',
					'time': 0,
					'exact_time': 0,
					'finish': 41,
					'exact_finish': 41,
					'data': `{"action":"link","value":"https://mindstamp.io/"}`,
					'click_action': 'view',
					'transition': 'fade' || 'fadeLeft',
					'animation' : null,
					'customStyle': '{"left":"0.5","top":"1.2","width":"15","height":"25","fontSize":"20.0","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
				{
					'type_of': 'hotspot',
					'content': '',
					'time': 1,
					'exact_time': 1,
					'finish': 41,
					'exact_finish': 41,
					'data': `{"action":"link","value":"https://mindstamp.io/"}`,
					'click_action': 'link',
					'transition': 'fade' || 'fadeLeft',
					'animation' : null,
					'customStyle': '{"left":"0.25","top":"0.00","width":"15.86","height":"8.65","fontSize":"20.0","videoWidth":"800.0","videoHeght":"450.0","ring":false,"hover":true,"caption":false,"angle":0,"borderRadius":3,"ringWidth":33}'
				},
				{
					'type_of': 'text',
					'content': `What would you like to explore?`,
					'time': 10,
					'exact_time': 10,
					'finish': 19,
					'exact_finish': 19,
					'background_color': 'rgba(0,0,0,0)',
					'text_color': '#000000',
					'animation': null,
					'transition': 'bounceDown',
					'data': `{"action":"track","value":""}`,
					'click_action': 'track',
					'customStyle': '{"left":"0","top":"1.2","width":"100","height":"12","fontSize":"30.0","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
				{
					'type_of': 'button',
					'content': "Create My Own Video",
					'time': 10,
					'exact_time': 10,
					'finish': 19,
					'exact_finish': 19,
					'data': `{"action":"link","value":"https://app.mindstamp.com/new"}`,
					'value': 'https://app.mindstamp.com/new',
					'click_action': 'link',
					'transition': 'bounceLeft',
					'animation' : 'pulse_v1',
					'background_color': gradient,
					'text_color': '#000000',
					'customStyle': '{"left":"0.5","top":"20","width":"22","height":"17","fontSize":"16","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
				{
					'type_of': 'button',
					'content': "Latest Features",
					'time': 10,
					'exact_time': 10,
					'finish': 19,
					'exact_finish': 19,
					'data': `{"action":"link","value":"https://app.mindstamp.com/enhancements"}`,
					'value': 'https://app.mindstamp.com/enhancements',
					'click_action': 'link',
					'transition': 'bounceLeft',
					'animation' : 'pulse_v1',
					'background_color': gradient,
					'text_color': '#000000',
					'customStyle': '{"left":"0.5","top":"40","width":"22","height":"17","fontSize":"16","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
				{
					'type_of': 'button',
					'content': "Examples",
					'time': 10,
					'exact_time': 10,
					'finish': 19,
					'exact_finish': 19,
					'data': `{"action":"link","value":"https://mindstamp.io/examples"}`,
					'value': 'https://mindstamp.io/examples',
					'click_action': 'link',
					'transition': 'bounceLeft',
					'animation' : 'pulse_v1',
					'background_color': gradient,
					'text_color': '#000000',
					'customStyle': '{"left":"0.5","top":"60","width":"22","height":"17","fontSize":"16","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},

				{
					'type_of': 'button',
					'content': "Create a Shoppable Video",
					'time': 10,
					'exact_time': 10,
					'finish': 19,
					'exact_finish': 19,
					'data': `{"action":"link","value":"https://mindstamp.io//integrations/woocommerce"}`,
					'value': 'https://mindstamp.io//integrations/woocommerce',
					'click_action': 'link',
					'transition': 'bounceRight',
					'animation' : 'pulse_v1',
					'background_color': gradient,
					'text_color': '#000000',
					'customStyle': '{"left":"77.5","top":"20","width":"22","height":"17","fontSize":"16","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
				{
					'type_of': 'button',
					'content': "Plans",
					'time': 10,
					'exact_time': 10,
					'finish': 19,
					'exact_finish': 19,
					'data': `{"action":"link","value":"https://mindstamp.io/pricing"}`,
					'value': 'https://mindstamp.io/pricing',
					'click_action': 'link',
					'transition': 'bounceRight',
					'animation' : 'pulse_v1',
					'background_color': gradient,
					'text_color': '#000000',
					'customStyle': '{"left":"77.5","top":"40","width":"22","height":"17","fontSize":"16","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
				{
					'type_of': 'button',
					'content': "Schedule a Demo",
					'time': 10,
					'exact_time': 10,
					'finish': 19,
					'exact_finish': 19,
					'data': `{"action":"modal","value":"https://meetings.hubspot.com/tyler-winter"}`,
					'value': "https://meetings.hubspot.com/tyler-winter",
					'click_action': 'link',
					'transition': 'bounceRight',
					'animation' : 'pulse_v1',
					'background_color': gradient,
					'text_color': '#000000',
					'customStyle': '{"left":"77.5","top":"60","width":"22","height":"17","fontSize":"16","videoWidth":"800.0","videoHeght":"450.0","ring":true,"hover":true,"caption":true,"angle":0}',
				},
			)
			return annotations
		},
		validateActiveDiv: function () {
			this.activeDiv.variable = this.activeDiv.variable && this.activeDiv.variable.replaceAll(/[^A-Za-z0-9_]/g,'_').replaceAll(/^[0-9]/g,'_'+this.activeDiv.variable[0])
			if (this.navDialog) {
				if (this.activeNav.action && ((this.activeNav.value || this.activeNav.value == 0) || (this.activeNav.action == 'pause' && this.activeNav.timer)) || ['resourceTray', 'reset_viewer', 'back'].includes(this.activeNav.action)) {
					if(['post', 'get'].includes(this.activeNav.action)){
						if (!this.isValidUrl(this.activeNav.value) || !this.activeNav.auth){
							return false
						}
						if (!!this.activeNav.field != !!this.activeNav.httpVar){
							return "Correct Connector Field and Variable"
						}
					}
					if (this.activeNav.action == 'chatGPT' && !this.activeNav.variable){
						return false
					} else {
						this.activeNav.variable = this.activeNav.variable && this.activeNav.variable.replaceAll(/[^A-Za-z0-9_]/g,'_').replaceAll(/^[0-9]/g,'_'+this.activeNav.variable[0])
					}
					return true
				} else {
					return false
				}
			}
			var ad = this.activeDiv
      this.sayDev(ad.exact_time, ad.exact_finish)
			if (ad.exact_finish && Number(ad.exact_time) > Number(ad.exact_finish)) {
				this.sayDev("Type: " + ad.type_of)
				if (!['video', 'audio', 'drawing'].includes(ad.type_of)) {
					this.flashFocusField("hideTime")
					return "Hide time must be after show time"
				}
			}
			if (ad.is_conditional) {
				if (!ad.conditional_variable) {
					this.flashFocusField("conditional_variable")
					return
				} else if ((ad.conditional_assertion == 'equals' || ad.conditional_assertion == 'notequals') && !ad.conditional_value) {
					this.flashFocusField("conditional_value")
					return
				} else if (ad.conditional_assertion == 'regex' && !this.isRegex(ad.conditional_value)) {
					this.flashFocusField("conditional_value")
					return 'Regualar Expressions must start and end with a /'
				} else if (ad.conditional_assertion_2 == 'regex'  && ad.conditional_value_2 && !this.isRegex(ad.conditional_value_2)) {
					this.flashFocusField("conditional_value_2")
					return 'Regualar Expressions must start and end with a /'
				}
			}
			if (['button','hotspot','image', 'text', 'card'].includes(ad.type_of)) {
				if (!ad.content && ad.type_of != 'hotspot' && !this.adc) {
					this.flashFocusField('activeDivContent')
					return false
				}
				if (!ad.action) {
					this.flashFocusField('activeDivAction')
					return false
				} 
				if (!ad.value && ad.action == 'jump'){
					ad.value = 0
				}
				if (ad.action == 'cart' && !this.currentUser.shopify_connected) {
					this.flashFocusField('activeDivValue')
					return "Please connect to Shopify"
				}
				if (ad.action == 'openai' && !this.currentUser.openai_connected) {
					this.flashFocusField('activeDivValue')
					return "Please connect to OpenAI"
				}
				if (!['genie', 'track', 'pause', 'forward', 'rewind', 'none', 'resourceTray', 'back'].includes(ad.action) && !ad.value && ad.value != '0' && (ad.type_of != 'image' && ad.action != 'view')) {
					this.flashFocusField('activeDivValue')
					return false
				}
				if (['download'].includes(ad.action) && (!this.assetDownload[0].downloadName || !this.proUpUser)) {
					this.flashFocusField('activeDivValue')
					return false
				}
			}
			if (ad.action == 'link' && !this.isValidUrl(ad.value)) {
				this.flashFocusField('activeDivValue')
				return false
			}
			if (ad.type_of == 'image' && ad.action && !['view','none','resourceTray', 'back', 'track'].includes(ad.action) && !ad.value && ad.value != '0') {
				// this.flashFocusField('activeDivValue')
				// return false
				this.activeDiv.action = null
			}
			
			



			// var val = ad.hubspotValue
			// var dt = ad.hubspotField.split(' --- ')[1];
			// if(dt == "datetime" && !/^\d{4}-\d{2}-\d{2}$/.test(val)){
			// 	this.flashFocusField('activeDivHubspotValue');
			// 	return "Please use the correct date format (MM/DD/YYYY)";
			// } 
			// if (dt == "number" && !isNumberic(val)){
			// 	this.flashFocusField('activeDivHubspotValue');
			// 	return "Please use a numberic value";
			// }
			return true;
		},
		
		getEditingFontStyle(ad) {
			var fs = ad.customStyle ? ad.customStyle.fontSize || 20 : 20
			var style = Object.assign({}, this.getActiveAnnotationStyle(ad))
			style.fontSize = fs + 'px'
			if (ad.background_color == "rgba(0,0,0,0)") {
				style.boxShadow = 'none !important'
			}
      style.maxWidth = 'unset !important'
			// style.lineHeight = fs * 1.10 + 'px'
			return style
		},
		getTextStyle(ad) {
			var style = this.getAnnotationStyle(ad) 
			style.fontSize = '16px'
			// style.lineHeight = '20px'
			if (ad && ad.customStyle) {
				var cs = JSON.parse(ad.customStyle)
				var ratio = this.canvasWidth / cs.videoWidth
				var min = 0.95
				if (this.isMobile) {
					min = 0.9
				}
				var x = Math.floor(JSON.parse(ad.customStyle).fontSize * ratio * min)
				style.fontSize = x + 'px'
				// style.lineHeight = x * 1.10 + 'px'
			}
      style.maxWidth = 'unset !important'
      if (ad.content.includes("iframe")) {
        style.width = '100%'
        style.height = '100%'
        style.pointerEvents = 'all !important'
      }

			if (ad.background_color == "rgba(0,0,0,0)") {
				style.boxShadow = 'none !important'
			}
			return style
		},
		getFontStyle(ad) {
			var style = {}
			style.fontSize = '16px'
			// style.lineHeight = '20px'
			if (ad && ad.customStyle) {
				var cs = JSON.parse(ad.customStyle)
				var x = Math.floor(JSON.parse(ad.customStyle).fontSize * this.canvasWidth / cs.videoWidth)
				style.fontSize = (Number(x)) + 'px'
			}
			return style
		},
		setVideoSrc(e) {
			var f = e.target.files[0]
			var x = window.URL.createObjectURL(f)
			this.source = {
				"type": 'video/mp4',
				"src": x,
			}
			this.player.src(this.source)
			this.player.play()
		},
		setDownloadFile(item){
      this.sayDev("SetDownloadFile")
      this.sayDev(item)
			if (!item) {
				if(this.questionCreationDialog){
					if(!this.assetDownload[this.currentAnswer].url) {
            this.sayDev("A --> " + this.currentAnswer)
						this.assetDownload[this.currentAnswer].url = ''
            this.sayDev("B")
					}
				}
				this.assetSelectDialog = false
				return
			}
			var url = item.content
			if(this.questionCreationDialog){
				// this.editingQuestion.answers[this.currentAnswer].value = url
			} else if (this.showCTA && this.endButton.action == 'download'){
				this.endButton.value = url
			} else if (this.cardSelectDialog) {
				this.activeCard.click_value = url
			} else if (this.activeDiv.action == 'download'){
				this.activeDiv.value = url
			} else if (this.activeDiv.second_action == 'download'){
				this.activeDiv.second_value = url
			} else {
				this.activeDiv.value = url
			} 
			this.assetSelectDialog = false
			this.assetDownload[this.currentAnswer] = {
				url: url,
				downloadName: item.safe_title || url.split('/')[url.split('/').length - 1].split('?')[0],
				id: item.id,
				type: item.type
			}

      // i deserve bad things for this
      window.top.postMessage({event: "aselect", index: this.currentAnswer, item: this.assetDownload[this.currentAnswer]} , "*");   

		},
		setQuestionResponseImage(url){
			this.replyMessage = url
		},
		setDivImage(url) {
      this.sayDev("Set Div Image: " + url)
			if (!url) {
				this.imageSelectDialog = false
				return
			}
			this.sayDev("SetDivImage", this.activeDiv.type_of)
			if (this.adi) {
				this.activeDiv.content = this.appendImages ? this.activeDiv.content + "---" + url :  url
        setTimeout(()=> {
          var rect = document.getElementById('activeDivEntry').getBoundingClientRect()
          var h = this.coords.width * (rect.height / rect.width) * (this.canvasWidth / this.canvasHeight)
          this.coords.height = h
          this.doCoords()
        }, 500)
			} else if (this.adc) {
				if(this.cardSelectStep == 2){
					this.activeCard.image = this.appendImages ? this.activeCard.content + "---" + url :  url
				} else {
					this.activeCard.click_value = url
				}
			} else if(this.showCardAction){
				if (this.cardSelectStep == 2){
					this.activeCard.image = this.appendImages ? this.activeCard.image + "---" + url :  url
				} else {
					this.activeCard.click_value = this.appendImages ? this.activeCard.click_value + "---" + url :  url
				}
			} else  {
				this.activeDiv.value = this.appendImages ? this.activeDiv.value + "---" + url : url
			}
			this.imageSelectDialog = false
			this.stockAssets = []
			this.stockSearch =''
			this.appendImages = false
			if (this.adi && this.activeDiv.action == 'reply'){
				var i = this.siteImages.indexOf(url)
				if (i >= 0){
					this.activeDiv.action = 'link'
					setTimeout(() => {
						this.activeDiv.value = this.siteImageParents[i]
					},100) 
				}
			}
			this.flashFocusField('activeDivEditCaption')
			setTimeout(() => {
				this.setDivDimensions()
			}, 500)
		},
		addMoreImages(){
			this.appendImages = true
			this.imageSelectDialog = true
		},
		removeImage(){
			var i = this.currentImageIndex
			var imgs = this.activeDiv.content.split('---')
			imgs.splice(i,1)
			this.activeDiv.content = imgs.join('---')
		},
		min5time(time) {
		},
		min1time(time) {
			return parseInt(Math.min(time + 1, this.duration - 1))
		},
		parseJSON(obj) {
			return JSON.parse(obj)
		},
		getActiveDivClass(div) {
			var x = ''
			if (div.type_of == 'comment') { x += ' promptOverlayContent'}
			if (div.type_of == 'button') { x += ' activeDivItem activeDivBtn'}
			if (div.type_of == 'image') { x += ''}
			if (div.type_of == 'text') { x += ''}
			if (div.type_of == 'hotspot') { x += ''}
			if (div.type_of == 'comment' || div.type_of == 'text') {
				if (this.commentNoBoxShadow) { x += 'noBoxShadow' }
			}
      if (div.type_of != 'hotspot' && div.type_of != 'button' && div.do_spotlight) {
        x+= ' spotlightMode '
      }
			// x += ' ' + div.animation || ""
			return x
		},
		getAnimationClass(div) {
			return div.animation || ''
		},
		// GADS
		getActiveDivStyle(div) {
      // this.sayDev("GADS " + div.id)
			var x = {}
			if (!div) {
				return x
			}
			if (div.customStyle) {
				var cs = JSON.parse(div.customStyle)
				var left = cs.left
				var top = cs.top
				if (div.pointPath && div.pointPath[this.deciTime]) {
					left = div.pointPath[this.deciTime].left
					top =  div.pointPath[this.deciTime].top
				}  else if (div.pointPath) {
					if (this.deciTime > div.exact_time) {
						left = div.pointPath["last"].left
						top =  div.pointPath["last"].top
					} else {
						left = div.pointPath["first"].left
						top =  div.pointPath["first"].top
					}
				}
        var height = cs.height
        var width = cs.width
        if (this.useCoverMode) {
          // this.sayDev("Screen Ratio: " + this.screenRatio)
          // this.sayDev("Video Ratio: " + this.videoTrueRatio)
          var ratio = this.videoTrueRatio / this.screenRatio
          // this.sayDev("Final Ratio: " + ratio)
          // if (ratio < 1) {
          //   // width = width * ratio
          // } else {
          //   height = height / ratio
          // }
          height = height / ratio
        }
				x = {
					top: top+ '%',
					left: left + '%',
					width: width + '%',
					height: height + '%',
					borderRadius: (cs.borderRadius || 0) + "%",
					textAlign: cs.textAlign || 'inherit',
				}
			} else if (div.type_of == 'button' || div.type_of == "image") {
				if (div.type_of == 'image' && div.pause) {
					x = {
						width: "100%",
						height: "100%",
					}
				}
				x =  {
					right: "10px",
					top: "10px",
					width: 'auto',
					height: 'auto',
					maxWidth: "300px",
					maxHeight: "300px"
				}
			} else if (div.type_of == 'video') {
				x = {
					left: "25%",
					top: "25%",
					width: '50%',
					height: '50%',
					maxWidth: "unset",
					maxHeight: "unset",
					right: 'unset'
				}
			}
			if (div.disabled) {
				x.filter = "grayscale(0.5)"
				x.opacity = 0.5
				x.cursor = 'not-allowed'
			}
			if (div.click_action == 'none') {
				x.cursor = 'auto'
				x.pointerEvents = 'none'
			}
			return x

		},
		toggleActiveTransition() {
			if (this.activeDiv.transition) {
				this.activeDiv.transition = null
			} else {
				if(!this.safeStyles.general.transition){
					this.activeDiv.transition = 'Zoom'
				} else {
					this.activeDiv.transition = this.safeStyles.general.transition
				}
			}
		},
		toggleActiveAnimation() {
			if (this.activeDiv.animation) {
				this.activeDiv.animation = ''
			} else {
				this.activeDiv.animation = "pulse_v1"
			}
		},
		toggleActiveBackgroundColor() {
			var color
			if (this.activeDivStyleHold.background_color) {
				if (this.adb){
					color =  this.safeStyles.button.background
				} else if (this.adt) {
					color = this.safeStyles.comment.background
				} else if (this.adc){
					color = this.safeStyles.card.background
					this.activeCard.background_color = color
					this.cardUpdated++
				} else {
					color = this.shareDesign.color
				}
				this.activeDivStyleHold.background_color = color
				this.activeDivStyleHold.hold_background_color = color
			} else {
				if (this.adh) {
					this.activeDivStyleHold.background_color = this.pColor
					this.activeDivStyleHold.hold_background_color = this.pColor
				} else {
					this.activeDivStyleHold.background_color = this.getAnnotationStyle(this.activeDiv).background
					this.activeDivStyleHold.hold_background_color = this.activeDivStyleHold.background_color
				}
			}
		},
		toggleActiveBorderColor() {
			this.sayDev("ToggleActiveBorderColor....")
			var color
			if (this.activeDivStyleHold.border_color) {
				if (this.adb){
					color =  this.safeStyles.button.border
				} else if (this.adt) {
					color = this.safeStyles.comment.border
				} else if (this.adc){
					color = this.safeStyles.card.border
					this.activeCard.border_color = color
          this.sayDev("ActiveCardBorderColor is now " + color)
					this.cardUpdated++
				} else {
					color = this.shareDesign.button_border_color
				}
			} else {
				color = this.getAnnotationStyle(this.activeDiv).border
			}
      this.sayDev("Initial Color: " + color)
      color = color.split("solid")[1].replace('!important','').replace(' ','')
      this.sayDev("New Color: " + color)
			// color = color.replace('0.12em','').replace('0.2em','').replace('solid','').replace('!important','').replace(' ','')
			this.activeDivStyleHold.border_color = color
			this.activeDivStyleHold.hold_border_color = color
      this.sayDev("ADSH border now to..." + this.activeDivStyleHold.border_color)

		},
		toggleActiveTextColor(){
			if (this.activeDivStyleHold.text_color) {
				var color = this.adb ? this.safeStyles.button.color : this.adt ? this.safeStyles.comment.color : null
				this.activeDivStyleHold.text_color = color
				this.activeDivStyleHold.hold_text_color = color
			} else {
				if (this.getAnnotationStyle(this.activeDiv)) {
					this.activeDivStyleHold.text_color = this.getAnnotationStyle(this.activeDiv).color.replace("!important", "")
				}
				if (this.activeDivStyleHold.background_color) {
					this.activeDivStyleHold.hold_background_color = this.activeDivStyleHold.background_color.replace("!important", "")
				}
			}
		},
		toggleAdvancedStyling(){
			this.advancedEditing = this.advancedEditing == 1 ? 0 : 1
			if (this.advancedEditing == 1) {
				this.activeDivStyleHold.text_color = this.activeDivStyleHold.text_color || this.getAnnotationStyle(this.activeDiv).color ? this.getAnnotationStyle(this.activeDiv).color.replace("!important", "") : null
				this.activeDivStyleHold.background_color = this.activeDivStyleHold.background_color || this.getAnnotationStyle(this.activeDiv).background
				if (this.adb || this.adc){
					this.activeDivStyleHold.border_color = this.activeDivStyleHold.border_color || this.getAnnotationStyle(this.activeDiv).border
					this.activeDivStyleHold.border_color = this.activeDivStyleHold.border_color.replace('0.12em','').replace('0.2em','').replace('solid','').replace('!important','').replace(' ','')
				}
			}
		},
		saveDiv(addAnother) {
			this.sayDev("SAVE DIV", this.activeDiv)
			this.sayDev(this.activeDiv.is_conditional_2, this.activeDiv.conditional_variable_2, this.activeDiv.conditional_value_2)
			var valid = this.validateActiveDiv()
			if (valid != true) {
				this.$toasted.show(valid || "Please fill out required fields")
				return false
			}
			// this.sayDev("proceeding")
			this.updatePointsTime()
			this.clearLines()
			this.selectedProduct = null
			this.httpResponseData = null
			this.httpFields = []
			var activeDiv = this.activeDiv
			if (this.navDialog) {
				activeDiv = this.activeNav
				activeDiv.type_of = this.activeNav.action
				if (this.activeNav.action == 'switch') {
					activeDiv.content = 'Switch Video to ' + this.activeNav.value.split("---")[0]
				} else if (this.activeNav.action == 'jump') {
					activeDiv.content = 'Change Time to ' + this.formattedSeconds(this.activeNav.value)
				} else if (this.activeNav.action == 'variable') {
					this.activeNav.variable = this.activeNav.variable.replaceAll(/[^A-Za-z0-9_]/g,'_').replaceAll(/^[0-9]/g,'_'+[0])
					activeDiv.content = 'Set Variable ' + this.activeNav.variable + ' to ' + this.activeNav.value
				} else if (this.activeNav.action == 'pause') {
					var str = 'Pause Video'
					if (this.activeNav.timer == -1) {
						str += " until Viewer Click"
					} else {
						str += ' for ' + this.activeNav.timer + " seconds"
					}
					activeDiv.content = str
				} else if(this.activeNav.action == 'resourceTray'){
					activeDiv.content = 'Open Magic Menu'
				} else if(this.activeNav.action == 'post'){
					activeDiv.content = 'Connector (Post Request)'
				} else if(this.activeNav.action == 'get'){
					activeDiv.content = 'Connector (Get Request)'
				} else if(this.activeNav.action == 'chatGPT'){
					activeDiv.content = 'Ask ChatGPT'
				}
				activeDiv.extra = activeDiv.content
			}
			if (['post', 'get'].includes(activeDiv.action)){
				activeDiv.value = {
					'url': activeDiv.value,
					'auth': activeDiv.auth,
					'field': activeDiv.field,
					'body': activeDiv.body,
					'head': activeDiv.head,
					'httpVar': activeDiv.httpVar,
				}
			}
			var data = this.getObject()
			var obj = { action: activeDiv.action, value: activeDiv.value }
			if (obj.value) {
				if (obj.action == 'download'){
					obj.value = JSON.stringify(this.assetDownload[0])
				} else if (['cart', 'post', 'get','chatGPT'].includes(obj.action)){
					obj.value = activeDiv.value
				} else {
					obj.value = obj.value.toString().trim()
				}
			}
			var time = activeDiv.time
			var finish = this.activeDiv.finish || activeDiv.time + 1
			if (finish && finish < time) {
				finish = Math.min(this.duration, this.min1time(this.activeDiv.time))
			}			
			if (!this.navDialog && !this.showVariables){
				activeDiv.variable = "";
				activeDiv.variableValue = "";
			}
			if (!this.showHubspot){
				activeDiv.hubspotField = "";
				activeDiv.hubspotValue = "";
			}
      if (activeDiv.type_of == 'hotspot') {
        activeDiv.customStyle.ringWidth = this.hotspotRingWidth
      }
      this.sayDev((activeDiv.exact_time || time))
			data.append("annotation[time]", this.showMenu|| this.showCTA ? 0 : Math.floor(activeDiv.exact_time || time));
      data.append("annotation[exact_time]", this.showMenu ||  this.showCTA? 0.0 :parseFloat(activeDiv.exact_time || time).toFixed(1));
      data.append("annotation[exact_finish]", this.showMenu || this.showCTA ? this.playerDuration : parseFloat(activeDiv.exact_finish || finish).toFixed(1));
			data.append("annotation[finish]", this.showMenu || this.showCTA ? this.playerDuration : parseInt(activeDiv.exact_finish));
			data.append("annotation[customStyle]", JSON.stringify(activeDiv.customStyle) || "");
			data.append("annotation[content]", (this.adc ? activeDiv.content.id : activeDiv.content) || "");
			data.append("annotation[extra]", activeDiv.extra || "");
			data.append("annotation[timer]", activeDiv.timer);
			data.append("annotation[click_time]", activeDiv.click_time || 0);
			data.append("annotation[variable]", activeDiv.variable || "");
			data.append("annotation[hubspot_field]", activeDiv.hubspotField);
			data.append("annotation[hubspot_value]", activeDiv.hubspotValue);
			data.append("annotation[pause]", activeDiv.pause);
			data.append("annotation[value]", activeDiv.variableValue || "");
			data.append("annotation[data]", JSON.stringify(obj));
			data.append("annotation[type_of]", activeDiv.type_of || "");
			data.append('annotation[is_conditional]', activeDiv.is_conditional)
			data.append('annotation[conditional_show]', activeDiv.conditional_show)
			data.append('annotation[conditional_variable]', activeDiv.conditional_variable)
			data.append('annotation[conditional_assertion]', activeDiv.conditional_assertion)
			data.append('annotation[conditional_value]', activeDiv.conditional_value)
			data.append('annotation[is_conditional_2]', activeDiv.is_conditional_2 && !!activeDiv.conditional_variable_2 && !!activeDiv.conditional_value_2)
			data.append('annotation[conditional_variable_2]', activeDiv.conditional_variable_2)
			data.append('annotation[conditional_assertion_2]', activeDiv.conditional_assertion_2)
			data.append('annotation[conditional_value_2]', activeDiv.conditional_value_2)
			data.append('annotation[conditional_operator]', activeDiv.conditional_operator)
			data.append("annotation[transition]", this.activeDiv.transition || "")
			data.append("annotation[animation]", this.activeDiv.animation || "")
			data.append("annotation[background_color]", this.activeDivStyleHold.background_color || "");
			data.append("annotation[border_color]", this.activeDivStyleHold.border_color ? this.activeDivStyleHold.border_color.replace('0.2em','').replace('0.12em','').replace('solid','').replace('!important','').replace(' ','') : "");
			data.append("annotation[text_color]", this.activeDivStyleHold.text_color || "");
			data.append("annotation[post_click_state]", this.activeDiv.post_click_state || "");
			data.append("annotation[post_click_color]", this.activeDiv.post_click_color || "");
			data.append("annotation[post_click_text_color]", this.activeDiv.post_click_text_color || "");
			data.append("annotation[background_image]", this.activeDiv.background_image || "");
			data.append("annotation[border_radius]", this.activeDiv.borderRadius || "");
			data.append("annotation[must_click]", this.activeDiv.must_click || false);
      data.append("annotation[internal_label]", this.activeDiv.internal_label || "");
      data.append("annotation[force_lead_capture]", this.activeDiv.force_lead_capture || "");
      data.append("annotation[hide_desktop]", this.activeDiv.hide_desktop || false);
      data.append("annotation[hide_mobile]", this.activeDiv.hide_mobile || false);
      data.append("annotation[do_spotlight]", this.activeDiv.do_spotlight || false);
      data.append("annotation[second_action]", this.activeDiv.second_action || "");
      data.append("annotation[second_value]", this.activeDiv.second_value && JSON.stringify(this.activeDiv.second_value) || "");
			if (this.adc || this.showCardAction){
				if (this.adc){
					this.activeCard.click_action = obj.action
					this.activeCard.click_value = obj.value
				}
				data.append("annotation[card]", JSON.stringify({
					id: this.activeCard.id,
					name: this.activeCard.name,
					title: this.activeCard.title,
					text: this.activeCard.text,
					image: this.activeCard.image,
					button: this.activeCard.button,
					click_action: this.activeCard.click_action || 'none',
					click_value: this.activeCard.click_value || '',
					layout: this.activeCard.layout,
					background_color: this.activeCard.background_color || '',
					border_color: this.activeCard.border_color || '',
					border_radius: this.activeCard.border_radius || '',
				}))
			}
			if (this.showingAnyTrays){
				data.append("annotation[tray_type]", this.activeSideItem == "interactions" ? "" : this.showMenu ? 'resourceTray' : this.showCTA ? 'ctaTray' : "");
			} else {
				if (!this.activeDiv.tray_type || this.activeSideItem == "interactions"){
					data.append("annotation[tray_type]", null);
				} else {	
					data.append("annotation[tray_type]", this.activeDiv.tray_type);
				}
			}
			if (this.activeDiv.points && this.activeDiv.points.length) {
				this.activeDiv.points.forEach((p)=> {
					p.src = ''
				})
				data.append("annotation[points]", JSON.stringify(this.activeDiv.points));
			} else {
				data.append("annotation[points]", []);
			}
			this.movePoints = []
			this.activePoint = 0
			this.addAnother = addAnother
			this.generatedContentResultsIndex = 0
			this.generatedContentResults = null
			this.generateContentPrompt = ''
			this.generateContentTones = []
			this.generatedValueResultsIndex = 0
			this.generatedValueResults = null
			this.generateValuePrompt = ''
			this.generateValueTones = []

      if (this.activeDiv.type_of == "hotspot") {
        this.sayDev("is hotspot, save settings...")
        this.sayDev(this.activeDiv.customStyle)
        if (this.lsTest()) {
          localStorage.setItem('MS_HOTSPOT', JSON.stringify(this.activeDiv.customStyle));
        }
      }


			if (activeDiv.id) {
				var id = activeDiv.id
				data.append("annotation[id]", id);
				this.updateAnnotation(id, data)
			} else {
				this.createAnnotation(data)
			}
			if (this.showMenu && this.resourceTrayAnnotations.length < 2 && !this.resourceTray.visible){
				this.resourceTray.visible = true
				var data = this.getObject()
				data.append("resourceTray", JSON.stringify(this.resourceTray));
				Rails.ajax({
					url: `/design/resourceTray/${this.shareDesign.id}`,
					type: "POST",
					data: data,
					dataType: "JSON",
					success: (data) => {
						this.$toasted.show('The Magic Menu icon is now visible! You can edit this in design settings.')
					},
					error: (e) => {
					},
				});
			}
			this.lastPauseTime = -1
			// this.divsActive = []
		},
		setActiveDiv(div) {
			this.sayDev("SetActiveDiv")
			if (div) {
				// Check if old div

				// 

			} else {
				this.activeDiv = {
					type_of: 'button',
					content: '',
					extra: "",
          time: 0, 
          exact_time: 0.0,
					action: null,
					value: null,
					auth: null,
					field: null,
					body: null,
					head: null,
					httpVar: null,
					pause: false,
					click_time: 0,
					variable_value: "",
					hubspotField: "",
					hubSpotValue: "",
					is_conditional: false,
					conditional_show: true,
					conditional_variable: '',
					conditional_assertion: 'equals',
					conditional_value: '',
					is_conditional_2: false,
					conditional_variable_2: '',
					conditional_assertion_2: 'equals',
					conditional_value_2: '',
					transition: this.shareDesign.interactions_transition || 'Zoom',
					animation: this.shareDesign.animation || 'None',
					text_color: null,
					background_color: null,
					border_color: null,
					borderRadius: '',
					customStyle: {
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						width: 0,
						height: 0,
						fontSize: 16,
						angle: 0
					},
					post_click_state: '',
					post_click_color: this.shareDesign.button_background_color,
          post_click_text_color: '',
					background_image: '',
					must_click: false,
					points: [],
          internal_label: '',
          force_lead_capture: '',
          hide_desktop: false,
          hide_mobile: false,
				}
				this.activeDivStyleHold = {
						background_color: null,
						text_color: null,
						border_color: null,
						hold_background_color: null,
						hold_text_color: null,
						hold_border_color: null,
				}
			}
			this.htmlEditorContent = false
			this.htmlEditorValue = false
			this.downladFile = ''
			this.advancedEditing = 0
		},
		setActiveCard(copy){
			this.cardSelectStep = -1
			this.activeCard = Object.assign({}, this.selectedCard)
      if (copy) {
        this.activeCard.id = ''
      }
			if (['cart', 'get','post'].includes(this.activeCard.click_action)){
				this.activeCard.click_value = JSON.parse(this.activeCard.click_value)
			}
			this.setInteract(this.activeCard.layout < 4 ? 0.8 : 1.3)
			this.finishCardBuilder()
			this.cardSelectDialog = false
			this.copy = false
			setTimeout(() => {
				this.selectedCard = null
			},1000) 
		},
		startDiv: function () {
			this.sayDev("Start Div")
      this.busy = true
			this.divDialog = true
			this.pausePlayer(0)			
			this.setActiveDivTime(this.activeDiv.exact_time) 
      this.busy = true
      this.hotspotRingWidth = 33
      if (this.activeDiv.type_of == 'audio') {
        return
      }
			setTimeout(() => {
				this.setDivDimensions()
				if (this.adc){
					this.setInteract(this.activeCard.layout < 4 ? 0.8 : 1.3)
				} else {
					this.setInteract()
				}
				if (!window.location.href.includes("tour")){
					setTimeout(() => {
						this.flashFocusField('activeDivEditCaption')
					}, 300)
				}
			}, 300)
      setTimeout(()=> {
        this.busy = false
      }, 1000)
		},
		setInteract(ratio = null){
			var that = this
			this.interact = interact('.resize-drag')
			.resizable({
				// resize from all edges and corners
				edges: { left: true, right: true, bottom: true, top: true },
				listeners: {
					move(event) {
						var target = event.target
						var x = (parseFloat(target.getAttribute('data-x')) || 0)
						var y = (parseFloat(target.getAttribute('data-y')) || 0)

						target.style.width = event.rect.width + 'px'
						target.style.height = event.rect.height + 'px'

						x += event.deltaRect.left
						y += event.deltaRect.top

						that.activeDivTransform.x = x
						that.activeDivTransform.y = y

						if (target) {
							target.setAttribute('data-x', x)
							target.setAttribute('data-y', y)
						}
						that.setDivDimensions(x, y, event.rect.width, event.rect.height)
					}
				},
				inertia: true,
				modifiers: 
					ratio 
					? 
					[interact.modifiers.restrictSize({min: { width: 20, height: 20 }}),interact.modifiers.aspectRatio({ratio: ratio})]
					:
					[interact.modifiers.restrictSize({min: { width: 20, height: 20 }})]
			})
			.draggable(that.dragOptions).gesturable({
				listeners: {
					move(event) {
						var arrow = document.getElementById('drag')

						angle += event.da

						arrow.style.webkitTransform =
							arrow.style.transform =
							'rotate(' + angle + 'deg)'

						document.getElementById('angle-info').textContent =
							angle.toFixed(2) + '\u00b0'
					}
				}
			})
		},
    // sdd
		setDivDimensions: function (x, y, w, h) {
			// this.sayDev("SetDivDimensions:  " + x + ", " + y + ", " + w + ", " + h)
			var omg = document.getElementById('divLayer')
			var drag = document.getElementById('drag')
			if (!drag || !omg) {
				this.sayDev("No Drag, return")
				return
			}
			var x = parseInt(x || parseInt(drag.dataset.x || 0))
			var y = parseInt(y || parseInt(drag.dataset.y || 0))
			var w = parseInt(w || drag.offsetWidth)
			var h = parseInt(h || drag.offsetHeight)
			var left = parseInt(drag.offsetLeft + x)
			var top = parseInt(drag.offsetTop + y)
			var fs = this.activeDiv.customStyle ? (this.activeDiv.customStyle.fontSize || 16) : 16
			var ll = (left * 100 / omg.offsetWidth).toFixed(1)
			var tt = (top * 100 / omg.offsetHeight).toFixed(1)
			var ww = (w * 100 / omg.offsetWidth).toFixed(1)
			var hh = (h * 100 / omg.offsetHeight).toFixed(1)
			this.activeDiv.customStyle = {
				left: ll,
				top: tt,
				width: ww,
				height: hh,
				fontSize: fs,
				videoWidth: this.canvasWidth.toFixed(1),
				videoHeght: this.canvasHeight.toFixed(1),
				align: this.activeDiv.customStyle.align,
				ring: this.activeDiv.customStyle.ring,
				hover: this.activeDiv.customStyle.hover,
				caption: this.activeDiv.customStyle.caption,
				angle: this.activeDiv.customStyle.angle,
        borderRadius: this.activeDiv.customStyle.borderRadius,
			}
      this.coords = {
        left: ll,
        top: tt, 
        width: ww,
        height: hh
      }
			// this.sayDev("ActiveDiv CS: ", this.activeDiv.customStyle)
			drag.style.borderRadius = this.activeDiv.customStyle.borderRadius + '%'
			// this.sayDev("SetBorder: " + this.activeDiv.customStyle.borderRadius)
			if (this.activeDiv.type_of == 'image' || this.activeDiv.type_of == 'hotspot') {
				var caption = document.getElementById('activeDivCaption')
				var img = document.getElementById('activeDivEntry') || drag
        if (caption) {
          caption.style.left = parseInt(left * 100 / omg.offsetWidth) + 0.5 + '%'
          caption.style.width = parseInt(w * 100 / omg.offsetWidth) + 0.5 + '%'
        }
			}
			if (caption) {
				if (this.activeDiv.type_of == 'hotspot') {
					caption.style.top = 0 + parseInt(top * 100 / omg.offsetHeight) + parseInt(img.offsetHeight * 100 / omg.offsetHeight) + 1 + '%'
				} else {
					caption.style.top = 4 + parseInt(top * 100 / omg.offsetHeight) + parseInt(img.offsetHeight * 100 / omg.offsetHeight) + '%'
				}

			}
			// parseInt(left * 100 / omg.offsetWidth) + parseInt(w * 100 / omg.offsetWidth) + '%'
			this.activeDiv.customStyle.fontSize = parseInt(this.activeDivFontSize).toFixed(1)
		},
		preloadAsset(url) {
			(new Image()).src = url;
			return
			var req = new XMLHttpRequest();
			req.open('GET', url, true);
			req.responseType = 'blob';
			req.onload = function () {
				// Onload is triggered even on 404
				// so we need to check the status code
				if (this.status === 200) {
					var videoBlob = this.response;
					var vid = URL.createObjectURL(videoBlob); // IE10+
					// Video is now downloaded
					// and we can set it as source on the video element
					// video.src = vid;
					// console.log("Downloaded: " + vid)
				}
			}
			req.onerror = function () {
				// Error
			}
			req.send();
		},
		hasAnsweredQuestion(ann) {
			// console.log("Check if answered ", ann)
			if (this.ownsPost || this.userInteractions.length == 0 || ann.type_of != 'question') {
				return false
			}
			var found = false
			this.userInteractions.forEach((a) => {
				// console.log("Checking if " + a.parent_id + " equals " + ann.id)
				if (a.parent_id == ann.id) {
					// console.log("TRUE!")
					found = true
				}
			})
			return found
		},		
		printResponses(parent, responses) {
			var x = []
			var answer = ""
			if (parent.content.correct_answer) {
				answer = parent.content.correct_answer
			}
			responses.forEach((a) => {
				var correct = "N/A"
				if (answer) {
					correct = a.content === answer ? 'Yes' : 'No'
				}
				x.push({
					PROMPT: this.wrap(parent.prompt),
					VIEWER_NAME: this.wrap(a.saidName),
					VIEWER_EMAIL: this.wrap(a.guest_email),
					VIEWER_PHONE: this.wrap(a.phone),
					VIEWER_CUSTOM_ID: this.wrap(a.custom_id),
					RESPONSE_TYPE: this.wrap(a.type_of),
					RESPONSE: this.wrap(a.content),
					CORRECT: this.wrap(correct),
					DATE: this.wrap(a.created_at),
				})
			})
			this.downloadCSV(parent.prompt || parent.extra + ' Responses ', x)
			this.tryTrack("Downloaded Responses", {
				prompt: parent.prompt,
				count: responses.length,
				link: 'https://app.mindstamp.com/w/' + parent.token + "?noview"
			})
		},
		wrap(str) {
			if (!str) {
				return ""
			}
			return '"' + str + '"'
		},
		addInteraction(str) {
			if(this.share.token == 'uQHkJdMEZwJO'){
        this.sayDev('Crisp Status')
        return
      }
      if (!this.showCTA && !this.showMenu) {
        if (['magic menu', 'end screen'].includes(this.activeSideItem)) {
          this.toggleTray()
        }
      }
			this.cardTemplate = 0
      this.placingInteraction = true
      var timesPerSecond = 60; // how many times to fire the event per second
      var wait = false;
      this.sayDev("TD: " + this.isTouchDevice)
      if (!this.isTouchDevice) {
        window.onmousemove = (e)=> {
          if (!wait) {
            // fire the event
            var obj = document.getElementById('divLayer')
            if (obj) {
              var rect = obj.getBoundingClientRect()
              if (e.pageX > rect.left && e.pageX < rect.right && e.pageY > rect.top && e.pageY < rect.bottom) {
                this.coords.left = (100 * (e.pageX - rect.left) / rect.width) - this.coords.width / 2
                this.coords.top = (100 * (e.pageY - rect.top) / rect.height) - this.coords.height / 2
                // console.log(e.pageX, rect.left, rect.width, this.coords)
                // this.coords.top = e.pageY
                this.doCoords()
              } 
              // stop any further events
              wait = true;
              // after a fraction of a second, allow events again
              setTimeout(function () {
                  wait = false;
              }, 1000 / timesPerSecond);
            }
          } 
        }
      }
			this.clearReplyAnnotation()
			this.editingAnnotation = {}
			var x = 2
			if (!this.played) {
				console.log('add int')
				this.playFromStart()
				x = 850
			} else {
				this.player.currentTime(this.deciTime)
			}
			setTimeout(() => {
        this.pausePlayer("AddInt")
				switch (str) {
					case 'drawing':
						this.createCanvas()
						break;
					case 'image':
						this.createDiv('image')
						break;
					case 'video':
						if (this.ownsPost) {
							this.createDiv('video')
						} else {
							this.showRecordVideoDialog()
						}
						break;
					case 'audio':
						if (this.ownsPost) {
							this.createDiv('audio')
						} else {
							this.showRecordAudioDialog()
						}
						break;
					case 'button':
						this.createDiv('button')
						break;
					case 'text':
						this.createDiv('text')
						break;
					case 'question':
						this.showQuestionDialog()
						break;
					case 'hotspot':
						this.createDiv('hotspot')
						break;
					case 'navigation':
						this.toggleNavDialog()
						break;
					case 'card':
						this.createCard()
						this.cardSelectDialog = true
						break;
				}
			}, x)

      if (!this.isTouchDevice && ['button', 'hotspot', 'image', 'text', 'card'].includes(str)) {
        this.placingInteraction = true
        var timesPerSecond = 60; // how many times to fire the event per second
        var wait = false;
        this.sayDev("TD: " + this.isTouchDevice)
        if (!this.isTouchDevice) {
          window.onmousemove = (e)=> {
            if (!wait) {
              // fire the event
              var obj = document.getElementById('divLayer')
              if (obj) {
                var rect = obj.getBoundingClientRect()
                if (e.pageX > rect.left && e.pageX < rect.right && e.pageY > rect.top && e.pageY < rect.bottom) {
                  this.coords.left = (100 * (e.pageX - rect.left) / rect.width) - this.coords.width / 2
                  this.coords.top = (100 * (e.pageY - rect.top) / rect.height) - this.coords.height / 2
                  // console.log(e.pageX, rect.left, rect.width, this.coords)
                  // this.coords.top = e.pageY
                  this.doCoords()
                } 
                // stop any further events
                wait = true;
                // after a fraction of a second, allow events again
                setTimeout(function () {
                    wait = false;
                }, 1000 / timesPerSecond);
              }
            } 
          }
        }
      }
		},
		tryPhoneCall(val) {
			this.pausePlayer()
			if (this.browserInfo.isMobileDevice || this.isIOS || this.isAndroid) {
				var str = 'tel:' + val
				this.safeOpenLink(str, false)
				this.sayDev("Mobile....")
			} else {
				this.sayDev("Not mobile, pd")
				this.phoneVal = val
				this.phoneDialog = true
			}
			// this.resumeDialog = true
		},
		openTrainingCalendar() {
			this.openModal('https://calendly.com/tyler-gd4/mindstamp-orientation')
		},
		openModal(url) {
			this.sayDev("👁 OpenModal: " + url)
			this.pausePlayer();
			this.resumeDialog = true
			lity(url)
			return
		},
		safeOpenLink(str, newTab) {
			this.pausePlayer()
			this.sayDev("🔗 SafeOpenLink: " + str, newTab)
			// this.sayDev("BrowserInfo", this.browserInfo)
			// alert("isIOS: " + this.isIOS + " --- isTouch: " + this.isTouchDevice)
			if (this.endButton && this.isMobile && this.showCTA) {
				window.top.location.href = str
				return
			}
			try {
				if (newTab && !this.isTouchDevice) {
					this.sayDev("Do Resume")
					this.resumeDialog = true
					window.open(str, "_blank")
					setTimeout(()=> {
						this.sayDev("Resume 2")
						this.pausePlayer();
						this.resumeDialog = true
            var t = this.deciTime == 0 ? '0.0' : this.deciTime
            this.doAllStamps(this.dactions[t] || [])
					}, 200)
				} else if (this.isTouchDevice && newTab) {
					this.confirmLink = str
				} else {
					if (this.isEmbed) {
						window.top.location.href = str
					} else {
						window.location.href = str
					}
				}
				this.doPreventNavs()
			}
			catch (err) {
				console.log(err)
				this.confirmLink = str
			}
		},
		getCookie(cname) {
			var name = cname + "=";
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		},
		clearValue(i) {
			this.editingQuestion.answers[i].value = ''
		},
		saveHTML(html, type){
			if (type == 'content') {	
				if (this.divDialog){
					this.activeDiv.content = html
				} else {
					this.activeNav.content = html
				}
			} else if (type =='value'){
				if (this.divDialog){
					if (this.cardSelectDialog){
						this.activeCard.click_value = html
					} else {	
						this.activeDiv.value = html
					}
				} else {
					this.activeNav.value = html
				}
			} else if (type == 'body'){
				if (this.divDialog){
					this.activeDiv.body = html
				} else {
					this.activeNav.body = html
				}
			} else if (type == 'head'){
				if (this.divDialog){
					this.activeDiv.head = html
				} else {
					this.activeNav.head = html
				}
			}
		},
		questionUsesMediaClips() {
			if (!this.editingQuestion || !this.editingQuestion.answers) {
				return false
			}
			if (!this.editingQuestion.answers || !Array.isArray(this.editingQuestion.answers)) {
				return false
			}
			var x = false
			this.editingQuestion.answers.forEach((a) => {
				if (a.action == 'clip' || a.action == 'audio') {
					x = true
				}
			})
			return x
		},
		refreshAssets(alert, type) {
      this.sayDev("Refresh attempt....")
			if (!this.ownsPost || !this.currentUser) {
				return
			}
			// this.sayDev("💎 refresh assets")
			Rails.ajax({
				url: '/my/assets',
				type: "GET",
				success: (data) => {
					this.assets = data
          this.articles = this.assets.articles
					this.videoItems = data.videoItems || []
					if (this.assets.cards && this.assets.cards.length > 0){
						this.assets.cards.forEach(c =>{
							c.title = JSON.parse(c.title)
							c.text = JSON.parse(c.text)
							c.button = JSON.parse(c.button)
						})
					}
					if (alert) {
						this.$toasted.show("Refreshed Assets ")
					}
					if (type){
						this.setDownloadFile(this.assets[type][0])
					}
					// this.sayDev("🈚️ Refreshed assets", this.assets)
				}
			});
		},
		refreshCards() {
			Rails.ajax({
				url: '/posts/cards?video_id=' + this.share.id,
				type: "GET",
				success: (data) => {
					if (!this.assets){
						this.assets = {}
					}
					this.assets.cards = data
					if (this.assets.cards && this.assets.cards.length > 0){
						this.assets.cards.forEach(c =>{
							c.title = JSON.parse(c.title)
							c.text = JSON.parse(c.text)
							c.button = JSON.parse(c.button)
						})
					}
					if (this.activeCard){
						var count = this.annotations.filter(x => x.content && x.content.id == this.activeCard.id).length
						if (count > 1){
							this.fetchInteractions()
						}
					}
					// this.sayDev("🃏🃏 Refreshed cards", this.assets.cards)
				}
			});
		},
		canEditFinish(ann) {
			let valid = ['button', 'image', 'comment', 'grid', 'hotspot']
			return valid.includes(ann.type_of)
		},
		getHotspotClass(spot) {
			if (spot.action && spot.value != undefined) {
				return 'promptOverlayContent breathing breathing-button'
			}
			return 'promptOverlayContent'
		},
		getHotspotCircleStyle(activeDivItem, isActiveDiv) {
			var x = {
				backgroundColor:  activeDivItem.disabled ? 'rgba(0,0,0,0.1)' : this.getHotspotColor(activeDivItem, isActiveDiv)
			}
			if (activeDivItem.type_of == 'hotspot' && activeDivItem.background_image) {
				x.backgroundImage = "url('" + activeDivItem.background_image + "')"
				x.backgroundSize = 'contain'
				x.backgroundPosition = 'center'
			}
			x.margin = '0 auto'
			var c = activeDivItem.customStyle
			if (this.isJSON(c)) {
				c = JSON.parse(c)
			}
      var rw = this.hotspotRingWidth / 100 + 0.1
      if (c.ringWidth) {
        rw = c.ringWidth / 100 + 0.1
        if (isActiveDiv) {
          this.hotspotRingWidth = c.ringWidth
        }
      }

			var circleWidth = 0
			var boxWidth = this.canvasWidth * c.width * 0.01
			var boxHeight = this.canvasHeight * c.height * 0.01
			if (boxWidth < boxHeight) {
				x.width = boxWidth * rw + 'px'
				x.maxHeight = boxWidth * rw + 'px'
				circleWidth = rw * boxWidth
			} else {
				x.height = boxHeight * rw + 'px'
				x.maxWidth = boxHeight * rw + 'px'
				circleWidth = rw * boxHeight
			}
			x.marginTop = this.canvasHeight * 0.5 * c.height * 0.01  - circleWidth/2 + 'px'					
			if (c.align == 0) {
				x.marginLeft = '0px'
				x.marginRight = 'auto'
			} else if (c.align == 2) {
				x.marginRight = '0px'
				x.marginLeft = 'auto'
			} else {
				x.marginLeft = 'auto'
				x.marginRight = 'auto'
			}
			x.borderWidth = 0.075 * circleWidth + 'px'
      if (activeDivItem.disabled) {
        x.borderColor = 'rgba(0,0,0,0.1)'  
      }
			return x
		},
		getHotspotRingStyle(activeDivItem, isActiveDiv) {
      var x = this.getHotspotCircleStyle(activeDivItem, isActiveDiv)
      x.background = 'none'
      x.backgroundColor = 'none'
			x.borderColor = activeDivItem.disabled ? 'rgba(0,0,0,0.1)' : this.getHotspotColor(activeDivItem, isActiveDiv)
      return x
		},
		getHotspotColor(div, isActiveDiv) {
			var c = this.activeDivStyleHold.background_color || div.background_color || this.pColor
			if (!isActiveDiv) {
				c = div.background_color || this.pColor
				c = div.post_click_state == 'color' && div.clicked ? div.post_click_color : c
			}
			return c + " !important"
		},
		showIntroDialog: function () {
			this.resetTimer()
			if (this.dialogShowing) {
				this.clearScreenInteractions()
			}
			this.introDialog = true
		},
    getEndLayerMessageStyle(message) {
      var x = this.getMessageSize(message)
      x.color = this.safeStyles.question.prompt_color
      return x
    },
		getMessageSize(msg) {
			let x = 28
			if (this.isMobile) {
				if (msg.length > 60) {
					x = 14
				} else {
					x = 20
				}
			} else {
				if (msg.length > 60) {
					x = 22
				} else {
					x = 32
          if (this.isXL) {
            x = 40
          }
				}
			}
			return { fontSize: x + 'px !important', padding: '5px 10px !important' }
		},
		clickData(data, parentObj, skipTracking) {
			this.sayDev("🎾 ClickData", data)

			// }
			if (data.action == 'redirect') {
				if (this.ownsPost) {
					this.$toasted.show("Skipping link redirect as owner. Use Preview Mode to see redirect occur.")
					return
				}
        this.saveProgress(this.currentTime.valueOf())
				setTimeout(()=> {
          this.doRedirect(data.value)
				}, 100)
				return
			}
			var newobj = Object.assign({}, parentObj);
			if (data.action == "link" || data.action == "video") {
				this.trackAction('click', data.text || data.value, newobj)
				skipTracking = true
			}
			this.resetTimer()
			this.doAction(data, parentObj, newobj, skipTracking)
			if (!skipTracking) {
				if (data.action != 'jump') {
					// this.sayDev("Not jump or skiptracking, track!")
					var x = ""
					this.trackAction('click', data.text || x, newobj)
				}
			} else if (['reply', 'track'].includes(data.action)) {
				if (this.correctAnswer && !this.showQuestionCorrect) {
					this.finishCorrect()
				} else {
					this.tryNextQuestion("clickdata")
				}
			}
			this.clearWaitingAction()
		},
		doAction(data, parentObj, newobj, skipTracking){
			this.sayDev("🥋 Do Action", data)
      this.sayDev("ParentObj", parentObj)
			switch (data.action) {
				case 'track':
          this.closeMenu()
					break;
				case 'rewind':
					this.rewind()
					break;
        case 'copy':
          this.$copyText(data.value)
          this.$toasted.show("Copied!")
          break;
				case 'lead':
					this.authDialog = true
					break;
				case 'forward':
					this.fastForward()
					break;
				case 'pause':
					if (this.player.paused()) {
						this.playPlayer("DoActionPause")
					} else {
						this.pausePlayer("DoAction")
					}
					return
					break;
				case 'collect':
					this.startCollect(data, parentObj)
					break;
        case 'genie':
					this.toggleGPT()
					break;
				case 'openai':
					this.startCollect(data, parentObj)
					break;
				case 'pause':
					this.tryPause(parentObj)
					return
					break;
				case 'view':
					this.viewImage(data.value || data.parent.content)
					break;
        case 'article':
          this.showArticle(data.value)
          break;
				case 'message':
					this.sayDev("💬 Show message --> " + data.value)
					var ca = parentObj.content ? parentObj.content.correct_answer : false
					if(!(ca && ca != '' && ca != '[]' && this.shareDesign.question_correctness) ){
						this.showDialogMessage(data.value)
					}	else {
						this.messageToShow = data.value
					}
					break;
				case 'clip':
					this.pausePlayer()
					this.playVideoClipFromId(data.value)
					break;
				case 'audio':
					this.pausePlayer()
					this.playAudioClipFromId(data.value)
					break;
				case 'modal':
					this.pausePlayer();
					this.openModal(this.formatLink(data.value))
					break;
				case 'link':
					this.pausePlayer();
					this.resumeDialog = true
					this.imageAnnotation = null
					this.safeOpenLink(this.formatLink(data.value), !this.openSameTab)
					break;
				case 'show_card':
					if (data && data.value){
						this.showCard(data.value, true)
            this.activeCard.parentStyle = parentObj.customStyle
					}
					break
				case 'phone':
					// code block
					this.pausePlayer();
					// if (!this.showCTA) {
					// 	this.resumeDialog = true
					// }
					this.tryPhoneCall(data.value)
					break;
				case 'jump':
					// code block
					this.pausePlayer();
					if (!skipTracking) {
						this.trackAction('click', data.text || data.value, newobj)
					}
          this.questionDialog = false
					this.showCTA = false
					this.toggleMenu(false)
					this.changeTimeAndPlay(data.value)
          this.pausedUntilClick = false
					
					// setTimeout(() => {
					//   this.changeTime(data.value, z'click', data.text || data.value, newobj)
					// }, 350)
					if (data.skipTrack) {
						return
					}
					break;
				case 'switch':
					if (this.ownsPost) {
						this.$toasted.show("Skipping switch video as owner. Use Preview Mode to see switching occur.")
						return
					}
          // this.sayDev("Switch.....", data)
					// var key = data.value.split("---")[1] + '?referrer=' + this.share.token
					// var x = window.location.origin + window.location.pathname + '?t=' + this.currentTime
					this.referVideoStack.unshift(this.share.token)
					this.referTimeStack.unshift(this.currentTime.valueOf())
          var t = parentObj.click_time || data.switch_time
          this.sayDev("Switch Time: " + t, data.switch_time)
					this.changeVideo(data.value.split("---")[1], t)
					break;
				case 'back':
					// var key = data.value.split("---")[1] + '?referrer=' + this.share.token
					// var x = window.location.origin + window.location.pathname + '?t=' + this.currentTime
					if (this.referVideoStack.length > 0){
						this.changeVideo(this.referVideoStack.shift(),this.referTimeStack.shift())
					} else if (this.ownsPost) {
						this.$toasted.show("No previous video to switch back to.")
						return
					}
					break;
				case 'video':
					this.referVideoStack.unshift(this.share.token)
					this.referTimeStack.unshift(this.currentTime.valueOf())
					var t = parentObj.click_time || data.switch_time
          this.sayDev("Switch Time: " + t, data.switch_time)
					this.changeVideo(data.value.split("---")[1], t)
					break;
				case 'email':
					this.pausePlayer();
					this.resumeDialog = true
					this.safeOpenLink('mailto:' + data.value + '?subject='+this.share.title)
					break;
				case 'download':
					if (!this.isMobile){
						this.pausePlayer();
						if (!this.dialogShowing && !this.showCTA && !this.showMenu){
							this.resumeDialog = true
						}
						this.isDownloading = true
						this.downloadFromUrl(data.value)
					}
					break
				case 'cart':
					this.addToCart(data.value);
					break
				case 'resourceTray':
					this.showResourceTray("Do Action")
					break
				case 'post':
				case 'get':
					this.sendConnectorRequest(data.value, data.action)
					break
				default:
          this.sayDev("Default action for " + data.action)
          this.clearWaitingAction()
				// this.togglePlayer()
				// code block
			}
			if (data.action!='show_card'){
				this.showCardDialog = false
			}
		},
		downloadFromUrl(value) {
			value = JSON.parse(value)
			if (['video', 'audio', 'file'].includes(value.type)) {
				this.getStreamingLinks(value.type, value.id, (data) => {
					this.finishDownloadFromUrl(data.aws, value.downloadName)
				})
			} else {
				this.finishDownloadFromUrl(value.url, value.downloadName)
			}
		},
		
		getSignedURL(filename) {
			$.getJSON("/signed_url?name=" + encodeURIComponent(filename), (data) => {
				return data
			});
    },
		getHref(hrefObject, jsonObject) {
			if (jsonObject && jsonObject.action == 'link') {
				return this.formatLink(jsonObject.value)
			} else if (hrefObject) {
				try {
					var data = JSON.parse(hrefObject.data)
					if (data.action == 'link') {
						return this.formatLink(data.value)
					}
				} catch (e) {
					return undefined
				}
			}
			return undefined
		},
		hideInteraction(a) {
			// if (this.ownsPost) {
			// 	this.$toasted.show("Skipping hide action as admin - use preview mode to see")
			// 	return
			// }
      this.sayDev("HideInteraction", a)
			a.invisible = true
			this.divsActive.forEach((d, i) => {
				if (a.id == d.id) {
					this.divsActive.splice(i, 1)
				}
			})
      this.setVisibleAnnotations()
		},
		disableInteraction(a) {
			// if (this.ownsPost) {
			// 	this.$toasted.show("Skipping disable action as admin - use preview mode to see")
			// 	return
			// }
      this.sayDev("DisableInteraction", a)
			a.disabled = true
		},
		touchActiveDiv(a) {
      this.sayDev("TouchActiveDiv", a)
			if (!this.isTouchDevice) {
				return
			}
			this.clickTouchActiveDiv(a)
		},
		clickActiveDiv(a) {
      this.sayDev("ClickActiveDiv", a)
			if (this.isTouchDevice) {
				return
			}
      if (this.isSafari && this.pausedUntilClick) {
        this.player.currentTime(this.player.currentTime() + 0.05)
      }
			this.clickTouchActiveDiv(a)
		},
		clickTouchActiveDiv(a) {
			this.sayDev("👔 CLICKDIV", a)
			// this.sayDev("PostClick: " + a.post_click_state)
			if (a.disabled || a.click_action == "none") {
				return
			}
			if (a.post_click_state == "hidden") {
				this.hideInteraction(a)
			} else if (a.post_click_state == "disabled") {
				this.disableInteraction(a)
			} 
      this.startAfkTimer()
      // else if (a.post_click_state == 'color'){
			// 	a.background_color = a.post_click_color
			// 	a.text_color = a.post_click_text_color
      //   this.sayDev("Set BG and Color to " + a.post_click_color + " .... " + a.post_click_text_color)
      //   console.log(a)
			// }
      if (this.audioDialog) {
        this.closeAudioDialog()
      }

			a.clicked = true


			if (this.variables.autoplay && this.isMuted) {
				this.toggleMute()
			}
			this.pausedUntilClick = false
			if (a.type_of == 'button') {
				this.clickButton(a)
				this.trackGtagEvent('Clicked Button', this.share.title, 'Mindstamp Interactions', a.content || "Button")
			} else if (a.type_of == 'image') {
				this.clickImage(a)
				this.trackGtagEvent('Clicked Image', this.share.title, 'Mindstamp Interactions', a.extra || 'Image')
			} else if (a.type_of == 'hotspot') {
				this.clickButton(a)
				this.trackGtagEvent('Clicked Hotspot', this.share.title, 'Mindstamp Interactions', a.extra || 'Hotspot')
			} else if (a.type_of == 'text') {
				this.clickText(a)
				this.trackGtagEvent('Clicked Text', this.share.title, 'Mindstamp Interactions', a.content || 'Text')
			} else if (a.type_of == 'card' || a.id == this.activeCard.id) {
				this.clickCard(a)
				this.trackGtagEvent('Clicked Card', this.share.title, 'Mindstamp Interactions', a.content || 'Card')
			}
      this.pausedUntilClick = false
		},
		getHubspotType(hsField) {
			for (var hs of this.hubspotAllFields)
				if (hs.prop_name == hsField)
					return hs.prop_type == 'datetime' ? 'date' : hs.prop_type == 'number' ? 'number' : 'text';
			return 'text';
		},
		clickEndButton() {
			if (this.endButton.action == 'jump' && this.endButton.value == "0") {
				this.restart()
			} else {
				this.clickButton(this.endButton)
			}
		},
		clickText(textAnnotation) {
			this.setReplyAnnotation(textAnnotation)
			var data = textAnnotation.data ? JSON.parse(textAnnotation.data) : {}
			this.setWaitingAction({
				type: 'text',
				parent: Object.assign({}, textAnnotation),
				text: textAnnotation.content,
				action: data.action,
				value: data.value || textAnnotation.content
			})
			if (this.shouldAuth || (textAnnotation && textAnnotation.force_lead_capture && !this.ownsPost)) {
				this.authDialog = true;
				return
			}
			if (this.shouldPay) {
				this.showPayDialog()
				returnd
			}
			this.finishAction()
		},
		clickCard(card) {
			this.sayDev("🃏 ClickCard", card)
			this.setReplyAnnotation(card)
			var data = JSON.parse(card.data)
			this.setWaitingAction({
				type: 'card',
				parent: card,
				text: card.name,
				action: data.action,
				value: data.value,
			})
			if (this.shouldAuth || (card.force_lead_capture && !this.ownsPost)) {
				this.authDialog = true;
				return
			}
			// if (this.shouldPay) {
			// 	this.payDialog = this.shouldPay;
			// 	return
			// }
			this.finishAction()
		},
		clickButton(buttonAnnotation) {
			this.sayDev("👌🏼 ClickButton", buttonAnnotation)
			if (buttonAnnotation.id || buttonAnnotation.data) {
				this.setReplyAnnotation(buttonAnnotation)
				if (buttonAnnotation.data) {
					var data = JSON.parse(buttonAnnotation.data)
					this.setWaitingAction({
						type: 'button',
						parent: Object.assign({}, buttonAnnotation),
						text: buttonAnnotation.content,
						action: data.action,
						value: data.value,
					})
				}
				if (this.shouldAuth || (buttonAnnotation.force_lead_capture && !this.ownsPost)) {
          this.authDialog = true;
          return
        }
				if (this.shouldPay) {
					this.payDialog = true
					return
				}
				this.finishAction()
			} else {
				this.clickData(buttonAnnotation, buttonAnnotation)
			}
		},
		clickAnswer(answerNumber) {
			this.authReturn = {
				type: 'choose',
				value: answerNumber
			}
			this.payReturn = {
				type: 'choose',
				value: answerNumber
			}
			var choice = this.questionChoices[answerNumber]
			this.chooseSelection(choice, this.questionAnnotation)
			return
		},
		chooseSelection(answer, parent) {
			if(!answer){
				return
			}
			this.questionAnnotation.response_time = new Date() - this.questionAnnotation.response_time
			this.sayDev('CS ⌚️⌚️⌚️', this.questionAnnotation.response_time)
			this.sayDev("CHOOSE SELECTION", answer)
			this.trackGtagEvent("Answered Question", this.questionAnnotation.prompt, "Mindstamp Interactions", answer.text)
			this.setReplyAnnotation(parent)
			if(parent.variable){
				this.setVariable(parent.variable, answer.text)
			}
			this.setWaitingAction({
				type: 'select',
				parent: parent,
				text: answer.text,
				action: answer.action,
				value: answer.value,
        switch_time: answer.switch_time
			})
			if (this.shouldAuth) {
				this.authDialog = this.shouldAuth;
				return
			}
			if (this.shouldPay) {
				this.payDialog = this.shouldPay;
				return
			}
			if (answer.variable && answer.variable_value) {
				this.setVariable(answer.variable.toLowerCase(), answer.variable_value)
			}
      this.sayDev("Action: " + answer.action)
			this.chosenAnswer = answer.text
			if (answer.action == 'message'){
				this.messageToShow = answer.value
        this.sayDev("message to show: " + answer.value)
			}
			var ca = this.questionAnnotation.content.correct_answer
			if (ca && !this.showPollDialogNext) {
				this.correctAnswer = ca
				if (!this.hasAnsweredQuestion(this.questionAnnotation)) {
					if (this.correctAnswer.toLowerCase() === this.chosenAnswer.toLowerCase()) {
						this.correctCount++;
					} else {
						this.incorrectCount++;
					}
				}
				if ((this.showQuestionCorrect || this.showMessageDialog || this.waitingAction) && !this.showPollDialogNext) {
					this.showPollDialogNext = (parent.content.style == 'poll');
					if (!this.showPollDialogNext || this.questionAnnotation.content.force_correct){
						// this.tryNextQuestion("chooseSelection1")
            if (this.waitingAction) {
              this.finishAction()
            } else {
              this.tryNextQuestion("chooseSelection1")
            }
						return
					}
				} else {
					this.sayDev("no correct, finish action")
				}
			} else if (parent.content.style == "poll" && !ca && !this.showPollDialogNext){
				if (this.waitingAction){
					this.showPollDialogNext = (parent.content.style == 'poll');
					if (!this.showPollDialogNext){
						this.tryNextQuestion("chooseSelection2")
						return
					}
				}
			}
			if (parent.content.style == "poll") {
				this.showPollDialogNext = false;
				if (this.shareDesign.question_correctness){
					this.chosenAnswer = answer.text
				}
				if (answer.action == 'message'){
					this.messageToShow = answer.value
					this.trackAction('click',answer.text, parent)
					this.clearWaitingAction()
				}
				this.showPollResults(parent, answer);
				return;
			}
			// Auth'd, do correctness
			this.finishAction()
		},
		clickImage(imageAnnotation) {
			this.setReplyAnnotation(imageAnnotation)
			var data = JSON.parse(imageAnnotation.data)
      var val = imageAnnotation.content
      if (data.value || data.value == 0) {
        val = data.value
      }
			this.setWaitingAction({
				type: 'image',
				parent: Object.assign({}, imageAnnotation),
				text: imageAnnotation.extra || "Image",
				action: data.action,
				value: val
			})
			if (this.shouldAuth || (imageAnnotation.force_lead_capture && !this.ownsPost)) {
				this.authDialog = true;
				return
			}
			if (this.shouldPay) {
				this.payDialog = this.shouldPay;
				return
			}
			this.finishAction()
		},
		focusSelect() {
			setTimeout(() => {
				var x = document.getElementById('selectInput')
				if (x) { x.focus() }
				if (this.$refs.mySelect) {
					this.$refs.mySelect.$refs.search.focus()
				}
			}, 150)

		},
		storeAudioAnnotationDetails(audioKey, audioId, title){
			this.activeDiv.content = audioId
			this.activeDiv.extra = title || ""
			this.activeDiv.videoKey =audioKey
			this.activeDiv.videoId = audioId
			this.activeDiv.title = title
			if(audioId){
				this.streamAudioAnnotation(audioId)
			} else {
				this.createMediaAnnotation(audioKey, audioId, title, 'audio')
			}
		},
		storeVideoAnnotationDetails(videoKey, videoId, title){
			this.activeDiv.content = videoId
			this.activeDiv.extra = title || ""
			this.activeDiv.videoKey = videoKey
			this.activeDiv.videoId = videoId
			this.activeDiv.title = title
			if(videoId){
				this.streamVideoAnnotation(videoId)
			} else {
				this.createMediaAnnotation(videoKey, videoId, title, 'video')
			}
		},
		createMediaAnnotation(key, id, title, type) {
			this.sayDev("CreateAnnotation", key +  " / " + id + " / " + title)
			var data = this.getObject()
			data.append("annotation[type_of]", type);
			if (key) {
				data.append("annotation[key]", key);
			} else {
				data.append("annotation[content]", id || this.activeDiv.content);
			}
			if (this.divDialog){
				data.append("annotation[id]", this.activeDiv.id);
				data.append("annotation[customStyle]", JSON.stringify(this.activeDiv.customStyle))
				data.append('annotation[is_conditional]', this.activeDiv.is_conditional)
				data.append('annotation[conditional_show]', this.activeDiv.conditional_show)
				data.append('annotation[conditional_variable]', this.activeDiv.conditional_variable)
				data.append('annotation[conditional_assertion]', this.activeDiv.conditional_assertion)
				data.append('annotation[conditional_value]', this.activeDiv.conditional_value)
				data.append('annotation[is_conditional_2]', this.activeDiv.is_conditional_2 && !!this.activeDiv.conditional_variable_2 && !!this.activeDiv.conditional_value_2)
				data.append('annotation[conditional_variable_2]', this.activeDiv.conditional_variable_2)
				data.append('annotation[conditional_assertion_2]', this.activeDiv.conditional_assertion_2)
				data.append('annotation[conditional_value_2]', this.activeDiv.conditional_value_2)
				data.append('annotation[conditional_operator]', this.activeDiv.conditional_operator)
			}

			if (title) {
				data.append("annotation[title]", title);
			}
			if (this.questionAnnotation){
				this.questionAnnotation.response_time = new Date() - this.questionAnnotation.response_time
				this.sayDev('MA ⌚️⌚️⌚️', this.questionAnnotation.response_time)
				data.append("annotation[response_time]", this.questionAnnotation.response_time)
			}

			Rails.ajax({
				url: '/annotations/media',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (mData) => {
					if (this.activeDiv.id){
						this.divDialog = false
						this.annotations.forEach((a, i) => {
							if (a.id == mData.id) {
								mData.undo_redo = 1 // undo = 1
								this.annotations.splice(i, 1, mData)
							}
						})
						setTimeout(() => {
							this.fetchInteractions()   
						},1000) 
						this.addToActive(mData)
						this.annotations.forEach((a) => {
							if (a.tray_type == mData.id) { 
								this.addToActive(mData)
							}
						})
					} else if(this.adm){
						this.handleNewAnnotation(mData)
						this.doScroll(mData)
					} else {
						this.handleVideoAnnotation(mData)
					}
					this.$toasted.show("Saved ✨")
				}, error: (e) => {
					this.$toasted.show("Could not save video", e)
				}
			})
		},
		handleVideoAnnotation(ann) {
			if (ann.parent_id || !this.ownsPost) {
				this.handleNewAnnotation(ann)
				return
			}
			var x = document.getElementById('divVideo')
			this.editAnnotation(ann)
		},
		changeTab(str) {
			this.pasteLink = ''
			this.video_key = ''
			this.video_type = ''
			setTimeout(() => {
				let x = document.getElementById(str)
				if (x) {
					x.focus()
				}
			}, 250);
		},
		hexToRgbA(hex, opacity) {
			var c;
			if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
				c = hex.substring(1).split('');
				if (c.length == 3) {
					c = [c[0], c[0], c[1], c[1], c[2], c[2]];
				}
				c = '0x' + c.join('');
				return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
			}
		},
		getActiveColor(c) {
			if (c && this.currentTime * 1000 > c.start && this.currentTime * 1000 < c.end) {
				return this.hexToRgbA(this.color, '0.1')
			}
			return 'none'
		},
		goBack() {
			if (window.history.length > 1) {
				window.history.back()
			} else {
				window.location.href = '/'
			}
		},

		getFirstBrowserLanguage () {
			var nav = window.navigator,
			browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
			i,
			language;
	
			// support for HTML 5.1 "navigator.languages"
			if (Array.isArray(nav.languages)) {
				for (i = 0; i < nav.languages.length; i++) {
					language = nav.languages[i];
					if (language && language.length) {
						return language;
					}
				}
			}
	
			// support for other well known properties in browsers
			for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
				language = nav[browserLanguagePropertyKeys[i]];
				if (language && language.length) {
					return language;
				}
			}
	
			return null;
		},
	
		closePage() {
			if (window.close()) {

			} else {
				this.$toasted.show("Could not close page - please close manually")
			}

		},
		reloadPage() {
			window.location.reload()
		},
		replyString(ann) {
			let replies = this.annotationsByParent[ann.id]
			let str = ''
			if (!replies) {
				return ''
			}
			replies.forEach((r) => {
				str += r.search
			})
			return str
		},
		setVideoInformation() {
			if (this.player) {
				this.duration = Math.floor(this.share.duration || this.player.duration())
				this.playerDuration = this.player.duration().toFixed(1)
				this.videoTrueWidth = this.player.videoWidth() || this.share.width 
				this.videoTrueHeight = this.player.videoHeight() || this.share.height
				// setTimeout(() => {
				//   // this.duration = this.player.duration();
				// }, 1500);
			}
		},
		createGroup() {
			var data = new FormData
			data.append("group[name]", this.groupName)
			data.append("group[desc]", this.groupDesc)
			data.append("group[all]", this.groupAllUsers)
			Rails.ajax({
				url: '/groups',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (mData) => {
					this.$toasted.show('Created Group')
					setTimeout(() => {
						window.location.href = '/groups/' + mData.id + "?adding=1"
					}, 1000);
					// this.tryTrack("Added Voice Note", { url: window.location.href, audio_id: mData.id, size: this.audioResult.size })
				}, error: (e) => {
				}
			})
		},
		createSeries(share) {
			var data = new FormData();
			data.append("series[name]", this.seriesName);
			data.append("series[description]", this.seriesDescription);
			Rails.ajax({
				url: `/series/new`,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Created Series ⭐️");
					setTimeout(() => {
						window.location.href = "/series/" + data.id + "?adding=1";
					});
				},
				error: (e) => {
					this.$toasted.show("Unable to create to series");
				},
			});
		},
		setVimeoKey() {
			// https://vimeo.com/manage/366150836/distribution#video-file-links
			this.video_key = this.vimeoKey
			this.newVideo.title = this.newVideo.title || 'Interactive Video #' + parseInt(gon.video_count + 1)
			// this.queueComplete()
		},
		setMP4Key() {
			this.video_name = "MP4 video " + this.vimeoKey
			this.newVideo.title = this.newVideo.title || 'Interactive Video #' + parseInt(gon.video_count + 1)

			this.video_key = this.vimeoKey
			setTimeout(() => {
        var x = document.getElementById('new_video_title')
        if (x) {
          x.focus()
        }
			}, 500);
			// this.queueComplete()
		},
		unhideReplies(id) {
			this.showReplies.push(id)
		},
		hideReplies(id) {
			this.showReplies.splice(this.showReplies.indexOf(id), 1)
		},
		clearReplies(id) {
			Rails.ajax({
				url: '/replies/clear/' + id,
				type: "POST",
				success: (data) => {
					this.$toasted.show("Cleared Replies 🎉")
					this.showReplies = []
					this.annotationsByParent[id] = []
					// this.fetchInteractions()
				},
				error: (e) => {
				}
			})
		},
		clearAllInteractions(share) {
			Rails.ajax({
				url: '/annotations/clear/' + share.id,
				type: "POST",
				success: (data) => {
					this.$toasted.show("Cleared all interactions 🎉")
					this.tryTrack("Cleared All Interactions", { share: share })
					this.annotations = []
				},
				error: (e) => {
				}
			})
		},
		clearAllActivity(share) {
			Rails.ajax({
				url: '/shares/reset/' + share.id,
				type: "POST",
				success: (data) => {
					this.$toasted.show("Request Submitted 🎉 Please allow for up to 30 minutes for clearing of activity depending on activity amount.")
					this.tryTrack("Cleared All Viewer Activity", { share: share })
				},
				error: (e) => {
				}
			})
		},
		convertArrayOfObjectsToCSV: function (arr) {
			var result, ctr, keys, columnDelimiter, lineDelimiter, data;

			data = arr;
			if (data == null || !data.length) {
				return null;
			}

			columnDelimiter = ",";
			lineDelimiter = "\n";

			keys = Object.keys(data[0]);

			result = "";
			result += keys.join(columnDelimiter);
			result += lineDelimiter;
			data.forEach(function (item) {
				ctr = 0;
				keys.forEach(function (key) {
					if (ctr > 0) result += columnDelimiter;

					result += item[key];
					ctr++;
				});
				result += lineDelimiter;
			});
			return result;
		},
		downloadCSV: function (title, obj) {

			var data, filename, link;
			var csv = this.convertArrayOfObjectsToCSV(obj);
			if (csv == null) return;

			filename = title.slice(0, 30).replace(/,/g, "") + "-" + new Date().toJSON().slice(0, 10) + '.csv'


			if (!csv.match(/^data:text\/csv/i)) {
				csv = "data:text/csv;charset=utf-8," + csv;
			}
			data = encodeURI(csv);

			link = document.createElement("a");
			document.body.appendChild(link);
			link.setAttribute("href", data);
			link.setAttribute("download", filename);
			link.click();
			document.body.removeChild(link);
		},
		isSearching() {
			return $('#searchStringInput').is(':focus') || $('#searchScriptInput').is(':focus') || $('#layerTranscriptSearch').is(':focus') || $('#layerInteractionSearch').is(':focus')
		},
    startIntroPlayer() {
      // this.sayDev("⚡️ Start intro player " + this.introClipDialog)
      setTimeout(()=> {
        if (document.getElementById('player-2')) {
          // this.sayDev("🚾 StartIntroPlayer")
          var config = {
            html5: {
              vhs: {
                overrideNative: !videojs.browser.IS_SAFARI,
              },
              nativeAudioTracks: videojs.browser.IS_SAFARI,
              nativeVideoTracks: videojs.browser.IS_SAFARI,
            },
            preload: 'auto',
            bandwidth: 20971520,
          }

          if (!gon.isPanopto && !(this.isSafari && this.source.src.includes('vimeo'))) {
            config.crossOrigin = 'anonymous'
          }
          var player2 = videojs("player-2", config)
          this.introPlayer = player2
          if (this.introClipUrl) {
            this.introPlayer.src({ src: this.introClipUrl})
          } else {
            this.introPlayer.src({ type: this.source.type, src: this.source.src })
            this.introPlayer.currentTime(this.startTime)
          }
          this.introPlayer.on('timeupdate', (event) => {
            if (this.introPlayer.currentTime() > this.startTime + 10 || this.introPlayer.currentTime() > this.introPlayer.duration() - 0.1) {
              // this.sayDev("Do time..." + this.introClipStarted)
              if (!this.introClipStarted) {
                this.introPlayer.currentTime(this.startTime)
              }
            }
          });
        } else {
          this.sayDev("Can't find player")
        }
        setTimeout(()=> {
          this.hideSafariCaptions()
          setTimeout(()=> {
            this.hideSafariCaptions()
            setTimeout(()=> {
              this.hideSafariCaptions()
              setTimeout(()=> {
                this.hideSafariCaptions()
                
              }, 200)
            }, 200)
          }, 200)
        }, 100)
      }, 25)
    },
    animateSwitchVideo() {
      const animation = this.switchVideoTransition
      this.sayDev("Switch Video Animation: " + animation)
      // animation = "bottom_left"
      if (animation == "left") {
        $('#switchCanvas').animate({left: "-=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else if (animation == "right") {
        $('#switchCanvas').animate({left: "+=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else if (animation == "up") {
        $('#switchCanvas').animate({top: "-=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else if (animation == "down") {
        $('#switchCanvas').animate({top: "+=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else if (animation == "top_right") {
        $('#switchCanvas').animate({top: "-=10000px", left: "+=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else if (animation == "top_left") {
        $('#switchCanvas').animate({top: "-=10000px", left: "-=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else if (animation == "bottom_right") {
        $('#switchCanvas').animate({top: "+=10000px", left: "+=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else if (animation == "bottom_left") {
        $('#switchCanvas').animate({top: "+=10000px", left: "-=10000px"}, 1000, ()=>{ 
          this.finishSwitchVideo()
        })
      } else {
        $('#switchCanvas').animate({opacity: 0}, 300, ()=>{ 
          this.finishSwitchVideo()
        })
      }
    },
    finishSwitchVideo() {
      // setTimeout(()=> {
      //   this.switchingVideo = false
      // }, 500)
      this.switchingVideo = false
      this.setSwitchCanvasStyle()
    },
    fadePlayerIn() {
      if (!document.getElementById('video-1_html5_api')) {
        setTimeout(()=> {
          this.fadePlayerIn()
        }, 100)
        return
      }
      else if (!window.location.href.includes("opacity=0")) {
        document.getElementById('video-1_html5_api').style.opacity = 1
        document.getElementById('video-1').style.opacity = 1
        if (document.getElementById('videoPoster')) {
          document.getElementById('videoPoster').style.opacity = 1
        }
      }
    },
    setSwitchCanvasStyle(){
      const sc = document.getElementById('switchCanvas')
      sc.style.top = this.fullLayer.top
      sc.style.left = this.fullLayer.left
      sc.style.width = this.fullLayer.width
      sc.style.height = this.fullLayer.height
      sc.style.maxWidth = this.fullLayer.maxWidth
      sc.style.maxheight = this.fullLayer.maxheight
      sc.style.opacity = 1
    },
		startVideo() {
			setTimeout(() => {
				// $('#layer').fadeIn(750)
				// $('#videoWrap').fadeTo(1, 750)
				this.drawSeekBounding("startVideo")
        if (!this.introClipDialog) {
          this.fadePlayerIn()
        }
        this.hideSafariCaptions()
			}, 250);

			if (player) {
				player.reset();
			} else {
				window.muxPlayerInitTime = Date.now();
        var config = {
					html5: {
						vhs: {
							overrideNative: !videojs.browser.IS_SAFARI,
						},
						nativeAudioTracks: videojs.browser.IS_SAFARI,
						nativeVideoTracks: videojs.browser.IS_SAFARI,
					},
					preload: 'auto',
					bandwidth: 20971520,
				}
        if (!gon.isPanopto && !(this.isSafari && this.source.src.includes('vimeo'))) {
          config.crossOrigin = 'anonymous'
        }
				player = videojs("video-1", config)

        this.isMuted = !(this.isIOS || this.isPad) || this.getCookie("muted").toString() == "1"
        this.isMuted = !!(this.isMuted && (this.autoplay))
        this.sayDev("Muted: " + this.isMuted)

				this.player = player
        window.stayer = player
				if (gon.sourceType != 'youtube' && gon.sourceType != 'wistia') {
					var src = { type: this.source.type, src: this.source.src, source: this.source }
          if (gon.force_quality) {
            this.sayDev("⭐️ Force Quality: " + gon.force_quality)
            var x = false
            x = gon.quality_path || gon.force_quality
            this.sayDev("Replace: ", x)
            if (x) {
              this.source.src = this.source.src.replace("playlist.m3u8", x+"/video.m3u8")
            }
            this.sayDev("Result: " + this.source.src)
          }
					this.player.src({ type: this.source.type, src: this.source.src })
          this.player.load()
          // this.sayDev(this.browserInfo)
          if (gon.intro_clip_url || (this.shareDesign && this.shareDesign.use_moving_preview) || (gon.autoplay && (this.isIOS || this.isIpad))) {
            this.startIntroPlayer()
            // this.sayDev("Start Intro")
          } else {
            // this.sayDev("No intro player...")
          }
				}
				// this.sayDev("💙 Player", this.player)
				// this.sayDev("⭐️ SRC", src)
				gon.player = player

				player.ready((event) => {
					this.loadDefaultVariables()
					this.processURLParams()
					player.tech_.off('dblclick');
					if (this.isMuted) {
            this.sayDev("isMuted, mute player")
						this.player.muted(true)
					}
					if (gon.autoplay) {
						// this.introDialog = false
						this.tryAutoplay()
					}
					// To stop playback if paused
					$('.content').on('click', function (event) {
						clearTimeout(this.timer)
					});

					setTimeout(() => {
            if (window.location.href.includes("editfirst=1")) {
              this.sayDev("Edit first...")
              setTimeout(()=> {
                this.editAnnotation(this.annotations[0])
              }, 500)
            }
						if (window.location.href.includes("add=")) {
							console.log('add')
							this.playFromStart()
							this.pausePlayer("StartVideo")
							// this.changeTime(10)
              this.player.currentTime(10)
              // this.advancedEditing = 1
							setTimeout(() => {
                if (window.location.href.includes("add=1")) {
                  this.addInteraction("button")
                } else if (window.location.href.includes("add=2")) {
                  this.addInteraction("text")
                } else if (window.location.href.includes("add=3")) {
                  this.addInteraction("question")
                  setTimeout(()=> {
                    this.editingQuestion.speak = true
                    this.editingQuestion.voice_number = 0
                  }, 1000)
                } else if (window.location.href.includes("add=4")) {
                  this.addInteraction("hotspot")
                }
                else if (window.location.href.includes("add=5")) {
                  this.addInteraction("audio")
                }
                else if (window.location.href.includes("add=6")) {
                  this.addInteraction("drawing")
                }
                else if (window.location.href.includes("add=7")) {
                  this.addInteraction("card")
                }
                else if (window.location.href.includes("add=8")) {
                  this.addInteraction("card")
                  this.cardSelectStep = 2
                }
								// setTimeout(()=> {
								// 	this.startMove(this.activeDiv.time, this.activeDiv.finish)
								// }, 1000)
								
							}, 20)
						} else if (window.location.href.includes("dovideo=1")) {
							console.log('start')
							this.playFromStart()
							this.pausePlayer("SV2")
							setTimeout(() => {
								this.addInteraction("video")
							}, 1000)
						} else if (window.location.href.includes("editing=1")) {
							this.startEdit()
						} else if (window.location.href.includes("sharing=1")) {
							this.startSharing()
              if (!this.isProduction) {
                setTimeout(()=> {
                  this.isPeople = true
                }, 1000)
              }
             
						} else if (window.location.href.includes("branching=1")) {
              this.activeSideItem = "more"
							this.branchingDialog = true
						}
					}, 200);

					if (this.isMuted) {
						player.volume(0);
					}
					if (this.variables.volume) {
						this.volumeLevel = this.variables.volume / 100
						this.sayDev("💔 Set volume from url " + this.volumeLevel)
						this.player.volume(this.volumeLevel)
					} else if (this.isMuted) {
						this.volumeLevel = 0
					}
				});

        this.player.on('touchstart', (event) => {
          // this.sayDev("TouchStart")
          if (this.showSeekbar) {
            this.togglePlayer()
          } else {
            this.mouseMove();
          }
          event.stopPropagation()
				})
        this.player.on('click', ()=> {
          // this.sayDev("Click")
          this.togglePlayer()
          event.stopPropagation()
        })
        this.player.on('mouseleave', ()=> {
          this.mouseLeaveVideo()
        })
        this.player.on('mousemove', ()=> {
          this.mouseMove()
        })

				this.player.on('timeupdate', (event) => {
					this.onPlayerTimeUpdate(event)
				});

				this.player.on('play', (event) => {
					this.onPlayerPlay(event)
				});
				this.player.on('waiting', (event) => {
					this.onPlayerWait(event)
				});

				this.player.on('pause', (event) => {
					this.onPlayerPause(event)
          this.stopTimeHandler()
				});

				this.player.on('loadedmetadata', (event) => {
					// this.sayDev("✅ LoadedMetadata --> Start at " + (this.startTime || 0))
          this.player.currentTime(this.startTime)
          this.duration = Math.floor(this.player.duration())
          this.resetMarkers()
          $('#videoCard').css({opacity: 1})
          setTimeout(()=> {
            $('#videoCard').css({opacity: 1})
          }, 1000)
          if (!this.ownsPost && this.share.collect_geolocation && navigator.geolocation) {
            // Can use geolocation, proceed with getting the location
            navigator.geolocation.getCurrentPosition(this.savePosition, this.positionError);
          }
					if (this.authDialog) { 
						return
					}
					if (this.switchingVideo) {
						// this.doPreventNavs()
						// this.sayDev("Resume video loaded metadata...")
						if (this.startTime){
              this.sayDev("Start Time: " + this.startTime)
							this.player.currentTime(this.startTime)
              this.doTimeUpdate()
						}
            this.player.playbackRate(this.playbackSpeed)
						if (this.played && this.shouldAuth && this.share.capture_auth_point != 1) {
							this.sayDev("Needs 2 auth....")
							this.authDialog = true
							this.stopSwitchVideo("StartVideo1")
							return
						}
						if (this.played && this.shouldPay) {
							this.sayDev("Needs 2 pay....")
							this.showPayDialog()
							this.stopSwitchVideo("StartVideo2")
							return
						}
						
						var wasSwitching = this.switchingVideo
            this.startTimeHandler()
            if (this.ownsPost && this.blockToggle) {
              this.stopSwitchVideo("StartVideo3")
              this.introDialog = true
              return
            }
            if (this.questionAnnotation || this.pausedUntilClick) {
              this.sayDev("Question or PUC,return!")
              return
            }
						this.player.play().then(() => {
							this.sayDev("Played...")
							}).catch((e) => {
								this.sayDev("Resume from VideoError...")
								console.log("VideoPlaybackError", e)
								if (gon.autoplay || wasSwitching) {
                  this.playPlayer("MetadataError");
									setTimeout(()=>{
										this.sayDev("Try playing agian...")
										this.playPlayer("MetadataError");
                    this.stopSwitchVideo("StartVideo4")
									}, 5000)
								}
							})
					} else {
            if (this.variables.vars) {
              this.sayDev("Variables List", this.variableList())
            }
						// this.player.currentTime(this.startTime || 0)
						this.setVideoInformation()
						this.updateCaptions()
					}
				})

				this.player.on('ended', (event) => {
          this.sayDev("❌❌❌❌ ENDED ❌❌❌❌")
          this.doEndEvent()
					this.onPlayerEnded(event)
					this.stopTimeHandler()
				});
        this.player.on('seeked', (event)=> {
          // this.sayDev("🧤 seeked")
          if (this.played && this.seeking && (!this.ownsPost || this.activeSideItem == "interactions")) {
            this.playPlayer("Seeked")
          } else {
            // this.sayDev("Not played or is paused, no resume")
          }
          this.seeking = false
        })
        this.player.on('seeking', (event)=> {
          // this.sayDev("🧤 seeking...")
        })
        this.player.on('waiting', (event)=> {
          // this.sayDev("🧤 waiting..")
        })
        this.player.on('loadeddata', (event)=> {
          this.sayDev("🧤 LoadedData")
          this.animateSwitchVideo()
        })

				this.player.on('error', (event) => {
          this.sayDev("ERROR", event)
          this.emitMessage("playback_error", { src: this.player.src(), error: this.player.error() })
					this.playerError = this.player.error()
          this.sayDev("BackupSource: ", this.backupSource)
          if (this.backupSource && this.player.src != this.backupSource.src) {
            this.source = this.backupSource
            this.player.src(this.source);
            if (this.played) {
              this.playPlayer("backup")
            }
            return

          }
          if (!this.isProduction) {
            return
          }
					console.log(this.playerError)
          $('#videoCard').css({opacity: 1})
          setTimeout(()=> {
            $('#videoCard').css({opacity: 1})
          }, 1000)
					let err = {
						link: window.location.href,
						share: this.share,
						error: this.player.error(),
						browserInfo: this.browserInfo,
						source: this.source
					}
					// this.player.dispose()
					// this.player = null
					this.tryTrack('Video Player Error', err)
          Sentry.captureException(event);
          this.sayDev("Tracked Sentry Event")
          this.hideAllDialogs()
          this.refreshDialog = true
					this.playPlayer("EC")
				})
				
			}
		},
		doCaptions() {
      if (this.share.captions) {
        this.share.captions.forEach((c)=> {
          // this.po("Caption", c)
          const trackEl = this.player.addRemoteTextTrack({src: c.url, label: c.label}, false);
        })
      }
		},
		removeCaptions() {
			this.player.remoteTextTrackEls().trackElements_.forEach((el)=> {
				this.player.removeRemoteTextTrack(el)
			})
		},
		fetchTranscript: function () {
      this.sayDev("FT")
			if (this.halt) {
				return
			}
			if (!gon.has_transcript && !this.hasTranscript) {
				return
			}
      this.sayDev("Proceed")
			var theUrl = `/transcript/${this.shareToken}?interactions_key=${this.share.key || ''}&query=${this.searchString || ''}`
      this.sayDev(theUrl)
			Rails.ajax({
				url: theUrl,
				type: "GET",
				success: (data) => {
          this.sayDev("Got Transcript")
          console.log(data)
					this.transcript = data.transcript
          if (this.transcript) {
            this.hasTranscript = true
          }
					if (!this.isProduction || window.location.href.includes("logs=1")) {
						this.po(data)
					}
				},
				error: (e) => {
				}
			})
		},
		loadReplies: function (annotation) {
			this.loadingReplies = annotation.id
			Rails.ajax({
				url: `/replies/get/${annotation.id}`,
				type: "GET",
				success: (data) => {
					// this.po(data)
					this.annotationReplies = this.annotationReplies || []
					data.forEach((d) => {
						this.annotationReplies.push(d)
					})
					this.showReplies.push(annotation.id)
					this.loadingReplies = false
				}, error: (e) => {
					this.loadingReplies = false
				}
			});

		},
		printInteractions: function (visible) {
			var x = this.annotations
			if (visible) {
				x = this.visibleAnnotations
			}
			x.forEach((a) => {
				var content = ''
				if (a.type_of == 'question') {
					content = a.content.prompt + " - " + a.content.style
				} else if (a.type_of == 'comment') {
					content = a.content
				} else if (a.type_of == 'button') {
					content = a.content + ' ' + a.data
				} else if (a.type_of == 'drawing') {
					content = 'drawing'
				}
			})
		},
		downloadTxt() {
			console.log("Download TXT")
			var a = document.createElement('a')
			var blob = new Blob([this.transcript.text], {type: 'text/plain'})
			var url = URL.createObjectURL(blob)
			a.setAttribute('href', url)
			a.setAttribute('download', 'Transcript '+this.share.title+".txt")
			a.click()
		},
		fetchInteractions: function (scrollTo) {
			if (this.share.token == 'uQHkJdMEZwJO'){
				this.sayDev('Crisp Status')
				return
			}
			if (this.switchingVideo) {
				this.sayDev("🌪  Switching, return FE")
				// return
			}
			if(this.viewerVariables && this.viewerVariables != {}){
				Object.keys(this.viewerVariables).forEach(v => {
					if (this.viewerVariables[v] != ''){
						this.variables[v.toLowerCase()] = this.viewerVariables[v]
						this.emitVariableMessage(1, {
							name: v.toLowerCase(),
							value: this.variables[v.toLowerCase()]
						})
					}
				})
			}
			this.sayDev("✨ ✨ ✨ Fetch ")
			var p = this.getFullInteractionParams()
			var x = `/interactions/${this.share.token}?interactions_key=${this.share.key || ''}&` + p
			if (this.halt) {
				return
			}
			this.gettingInteractions = true
			Rails.ajax({
				url: x,
				type: "GET",
				success: (data) => {
					this.sayDev("🔥 Got Interactions", data)
					this.transcript = data.transcript
					this.matchAnnotations(data.interactions)
					this.share = data.share
					this.chapters = data.chapters
          this.setChapterTiming()
					this.duration = this.duration * 1
					if (this.annotationReplies.length == 0) {
						this.annotationReplies = data.replies
					}
					this.interactionsError = false
					this.filteredAnnotations = this.visibleAnnotations
					this.filteredChapters = this.chapters
          this.resetMarkers()
					if (!this.isProduction && this.variables.vars) {
						this.printInteractions()
					}
					if (this.annotations.length > 0 && this.hasSubtitles && !this.isEmbed) {
						var t = document.getElementById('tab1')
						if (t && t.children[0]) {
							t.children[0].click()
						}
					} else {
						this.listIndex = 0
					}
					// this.showStamps()
					setTimeout(() => {
						this.gettingInteractions = false
						if(scrollTo){
							this.doScroll(scrollTo)
						}
					}, 100)
					
					// if (!this.isProduction) {
					// 	this.startEditDiv(this.annotations[0])
					// }
				},
				error: (e) => {
					this.interactionsError = true
					this.gettingInteractions = false
          setTimeout(()=> {
            this.fetchInteractions(item.id)
            this.errorCount++
          }, this.errorCount * 1000)
					if (!this.isEmbed) {
						this.$toasted.show('Something went wrong - interactions might not be loaded')
						this.tryTrack("Failed Interactions Load", {
							user: this.currentUser,
							share: this.share,
							url: window.location.href
						})
					}
				}
			})
		},
		matchAnnotations(anns) {
			// this.sayDev("👀 MA 👀")
			// Loop through all new ones
			// If find one in existing with current ID, update
			// Else, add

			anns.forEach((ann) => {
				var found = false
				var qid = null
				var cid = null
				if (this.questionAnnotation) {
					qid = this.questionAnnotation.id
				} 
        ann.style = this.getActiveDivStyle(ann)
				this.annotations.forEach((a) => {
					if (a.id == ann.id) {
						// this.sayDev("🧬 Match", a, ann)
						a.content = ann.content
						a.time = ann.time
            a.exact_time = ann.exact_time
            a.finish = ann.finish
            a.exact_finish = ann.exact_finish
						a.dynamic = ann.dynamic
						a.variable = ann.variable
						a.hubspot_field = ann.hubspot_field
						a.content = ann.content
						a.extra = ann.extra
						a.data = ann.data
						a.prompt = ann.prompt
						a.prompt_raw = ann.prompt_raw
						a.search = ann.search
						a.is_conditional = ann.is_conditional
            // this.sayDev("Conditional Show: " + ann.conditional_show)
						a.conditional_show = ann.conditional_show
						a.conditional_variable = ann.conditional_variable
						a.conditional_assertion = ann.conditional_assertion
						a.conditional_value = ann.conditional_value
						a.is_conditional_2 = ann.is_conditional_2
						a.conditional_variable_2 = ann.conditional_variable_2
						a.conditional_assertion_2 = ann.conditional_assertion_2
						a.conditional_value_2 = ann.conditional_value_2
						a.conditional_operator = ann.conditional_operator
						a.speak = ann.speak
						a.speak_answers = ann.speak_answers
						a.text_color = ann.text_color
						a.background_color = ann.background_color
						a.border_color = ann.border_color
						a.borderRadius = ann.borderRadius
						a.transition = ann.transition
						a.animation = ann.animation
						a.post_click_state = ann.post_click_state
						a.post_click_color = ann.post_click_color
						a.post_click_text_color = ann.post_click_text_color
						a.must_click = ann.must_click
						a.customStyle = ann.customStyle
            a.style = ann.style
						a.hidden = ann.hidden
            a.second_action = ann.second_action
            a.second_value = ann.second_value
						if (a.id == qid) {
							this.questionAnnotation = a
						}
						found = true
					}
				})
				if (ann.type_of == 'card' && ann.content){
					if (ann.content.title && typeof(ann.content.title) == 'string'){
						ann.content.title = JSON.parse(ann.content.title)
					}
					if (ann.content.text && typeof(ann.content.text) == 'string'){
						ann.content.text = JSON.parse(ann.content.text)
					}
					if (ann.content.button && typeof(ann.content.button) == 'string'){
						ann.content.button = JSON.parse(ann.content.button)
					}
				}
				if (!found) { 
					this.annotations.push(ann)
				}
			})

			this.setVisibleAnnotations("MatchAnnotations")
			this.updatePointPaths()
			this.filteredAnnotations = this.visibleAnnotations
      // this.forceUpdate("MatchAnnotations")
			this.resetMarkers()

		},
		updatePointPaths() {
			// this.sayDev("✅ ✅ ✅ ✅ Update point Paths!")
			this.annotations.forEach((a)=> {
				if (a.points) {
					this.setPointPath(a)
				} 
			})
		},
		setPointPath(div) {
			if (div.customStyle && div.points && this.isJSON(div.points)) {
				var cs = JSON.parse(div.customStyle)
				var points = JSON.parse(div.points)
				var results = {}
				points.forEach((p,i)=> {
					var pleft = Number(p.left - 0.5*cs.width).toFixed(1)
					var ptop = Number(p.top - 0.5*cs.height).toFixed(1)
					results[Number(p.time).toFixed(1)] = {left:  pleft, top: ptop, time: parseFloat(p.time.toFixed(1))}
					if (i == 0) {
						results["first"] = {left:  pleft, top: ptop, time: parseFloat(div.exact_time)}
						results["last"] = {left:  pleft, top: ptop, time: parseFloat(div.exact_finish)}
					}
					var next = points[i+1]
					if (next) {
						var t = p.time + 0.1
						while (t < points[i+1].time) {
							var floor = t - p.time
							var nextLeft = Number(next.left - 0.5*cs.width).toFixed(2)
							var nextTop = Number(next.top - 0.5*cs.height).toFixed(2)
							var left = Number(pleft) + Number((Number(nextLeft) - pleft)*(Number(floor)))
							var top = Number(ptop) + (Number(nextTop) - ptop)*(Number(floor)) 
							results[t.toFixed(1)] = {left: Number(left).toFixed(1), top: Number(top).toFixed(1), time: parseFloat(t.toFixed(1))}
							results["last"] = {left: Number(left).toFixed(1), top: Number(top).toFixed(1), time: parseFloat(t.toFixed(1))}
							t+=0.1
						}
					} 
				})
				div.pointPath = results
				this.sayDev("PP", results)
			}
		},
		viewImage: function (url) {
			this.sayDev("View Image....", url)
			this.imageAnnotation = {
				content: url
			}
			this.pausePlayer()
			this.tryTrack("Opened Image Full Size", { url: window.location.href, image: this.imageAnnotation })
			this.viewImageDialog = true
			setTimeout(()=> {
				this.viewImageDialog = true
			}, 200)
		},

		tryNavClick(time, override = false) {
			// if (!this.safeStyles.controls.seekbar || this.isMinimal) {
      //   if (!this.isDebug) {
      //     this.$toasted.show("Navigation disabled")
      //     return
      //   }
			// }
      if (!override && (!this.ownsPost && (this.cannotClickSeekbar || !this.safeStyles.controls))) {
        this.sayDev("No nav click")
        return
      }

			this.changeTimeAndPlay(time)
		},
		
		downloadQRCode() {
			let url = document.querySelector('#qrcodediv').querySelector('img').src;
			this.downloadURI(url, this.share.title.replace(/\s+/g, '') + '.png')
		},
		startSharing: function (s) {
      this.activeSideItem = 'share'
			this.publishDialog = true
      this.titleDialog = false
      this.showMoreInfo = false
			if (s && s.id) {
				this.share = s
				this.shareDialog = true
			}
      // if (this.share.published) {
      //   this.copyShareLink()
      // }
			if (!this.qrCode && this.share.published) {
				setTimeout(()=> {
					this.qrCode = new QRCode(document.getElementById("qrcodediv"), {
						text: this.sharePath + this.share.token + '?referrer=qrcode',
						width: 500,
						height: 500,
						colorDark : "#000000",
						colorLight : "#ffffff",
						correctLevel : QRCode.CorrectLevel.H
					}, 1000);
				});
			}
		},
		
		onAudioStream: function (data) {
			this.isRecordingAudio = true
		},
		onAudioResult: function (data) {
			this.audioResult = data
			this.isRecordingAudio = false
			setTimeout(() => {
				var x = document.getElementById('audioReview')
				x.src = window.URL.createObjectURL(data)
				setTimeout(() => {
					x.play()
				}, 200);
			}, 50);
		},
		saveAudio: function (result, title) {
			this.audioTitle = title
			// this.audioResult = this.audioResult.slice(0, this.audioResult.size, "audio/wav")
			this.audioResult = result
			var theName = "audio_" + new Date().getTime() + '.wav';
			$.getJSON('/signed_url?name=' + encodeURIComponent(theName), (data) => {
				this.uploadToS3(this.audioResult, data.signed_url, data.video_key)
			})
		},
		saveAudioFromClip: function () {
			var data = this.getObject()
			data.append("annotation[content]", this.theAudioID);
			data.append("annotation[type_of]", "audio");
			this.createAnnotation(data)
		},
    saveLinkAudio: function() {
      var data = this.getObject()
      data.append("annotation[content]", this.activeDiv.content);
      data.append("annotation[extra]", this.activeDiv.content);
      data.append("annotation[type_of]", "audio");
      this.createAnnotation(data)
    },
		serverSaveAudio: function (key) {
			var data = new FormData
			data.append("audio[type_of]", "audio/wav")
			data.append("audio[key]", key)
			data.append("audio[title]", this.audioTitle);

			Rails.ajax({
				url: '/audios',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (mData) => {
					var keys = key.split("/")
					var extra = ""
					if (keys.length > 0) {
						extra = key.split("/")[keys.length - 1]
					}
					var data = this.getObject()
					data.append("annotation[content]", mData.id);
					data.append("annotation[extra]", this.audioTitle);
					data.append("annotation[type_of]", "audio");
					this.createAnnotation(data)
					this.audioTitle = ''
				}, error: (e) => {
				}
			})
		},
		uploadToS3: function (obj, url, key) {
			var xhr = new XMLHttpRequest();
			xhr.open('PUT', url);
			xhr.setRequestHeader('Content-Type', obj.type);
			var done = false
			xhr.upload.addEventListener('progress', (oEvent) => {
				if (oEvent.lengthComputable) {
					var percentComplete = ((oEvent.loaded / oEvent.total) * 100).toFixed(0);
					if (percentComplete == 100 && !done) {
						done = true
						this.serverSaveAudio(key)
					}
				}
			})
			xhr.send(obj);
		},
		setInteractionsByTime: function () {
			var x = {
				notes: {},
				buttons: {},
				drawings: {},
				questions: {},
				videos: {},
				audios: {},
				replies: {},
				images: {},
				grids: {},
				hotspots: {},
				texts: {},
				navigations: {}
			}
			var y = {
				notes: [],
				buttons: [],
				drawings: [],
				questions: [],
				videos: [],
				audios: [],
				replies: [],
				images: [],
				grids: [],
				hotspots: [],
				texts: [],
				navigations: []
			}
			for (var i = 0; i < this.orderedAnnotations.length; i++) {
				var a = this.orderedAnnotations[i]
				if (!a.hidden){
					var t = a["time"]
					switch (a["type_of"]) {
						case 'button':
							x.buttons[t] = x.buttons[t] ? x.buttons[t].concat([a]) : [a]
							y.buttons.push(a)
							break;
						case 'comment':
							x.notes[t] = x.notes[t] ? x.notes[t].concat([a]) : [a]
							y.notes.push(a)
							break;
						case 'text':
							x.texts[t] = x.texts[t] ? x.texts[t].concat([a]) : [a]
							y.texts.push(a)
							break;
						case 'reply':
							x.replies[t] = x.replies[t] ? x.replies[t].concat([a]) : [a]
							y.replies.push(a)
							break;
						case 'drawing':
							x.drawings[t] = x.drawings[t] ? x.drawings[t].concat([a]) : [a]
							y.drawings.push(a)
							break;
						case 'hotspot':
							x.hotspots[t] = x.hotspots[t] ? x.hotspots[t].concat([a]) : [a]
							y.hotspots.push(a)
							break;
						case 'grid':
							x.grids[t] = x.grids[t] ? x.grids[t].concat([a]) : [a]
							y.grids.push(a)
							break;
						case 'question':
							x.questions[t] = x.questions[t] ? x.questions[t].concat([a]) : [a]
							y.questions.push(a)
							break;
						case 'video':
							x.videos[t] = x.videos[t] ? x.videos[t].concat([a]) : [a]
							y.videos.push(a)
							break;
						case 'audio':
							x.audios[t] = x.audios[t] ? x.audios[t].concat([a]) : [a]
							y.audios.push(a)
							break;
						case 'image':
							x.images[t] = x.images[t] ? x.images[t].concat([a]) : [a]
							y.images.push(a)
							break;
						case 'pause':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'lead':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'jump':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'switch':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'variable':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'redirect':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'resourceTray':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'post':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
						case 'get':
							x.navigations[t] = x.navigations[t] ? x.navigations[t].concat([a]) : [a]
							y.navigations.push(a)
							break;
					}
				}
			}
			this.interactionsByTime = x
			this.interactionsByType = y
		},
		setWaitingAction(obj) {
			this.sayDev("🐋 SetWaitingAction", obj)
			this.waitingAction = Object.assign({}, obj);
		},
		clearWaitingAction() {
      this.sayDev("🫁 waitingAction now cleared")
			this.waitingAction = null
		},
		exitConfirmLink() {
			this.confirmLink = null
			this.tryNextQuestion("ecl")
		},
		// fffff
		finishAction: function (skipTracking) {
			this.isChoosingAnswer = false
			this.showPollDialog = false
			this.multiCorrect = false
			this.messageToShow = ''
			if (this.waitingAction && this.waitingAction.action == 'cart' && !this.waitingAction.value.variant.id){
				this.addToCart(this.waitingAction.value)
				this.showCardDialog = false
				this.sayDev("🛍 Don't finish yet. Need to select variant")
				return
			}
			this.sayDev("🥙 Finish Action (" + skipTracking +")", this.waitingAction)
			if (this.waitingAction) {
				// this.sayDev("Try waiting action....")
				this.checkWaitingActionValue()
				this.clickData(this.waitingAction, this.waitingAction.parent, skipTracking || this.waitingAction.skip)
			} else {
				// this.sayDev("no waiting action, TNQ....")
				this.tryNextQuestion("finishaction")
			}
		},
		checkWaitingActionValue(){
			this.sayDev("🫣 Checking Action", this.waitingAction)
			if (!this.waitingAction || !this.waitingAction.parent || !this.waitingAction.parent.dynamic){
				return
			}
			if (this.waitingAction.action == 'cart'){
				return
			}
			var val = this.waitingAction.value 
			if (this.waitingAction.type == 'select'){
				if (this.waitingAction.parent.click_value){
					var answers = JSON.parse(this.waitingAction.parent.click_value)
					for(var a = 0; a < answers.length; a++){
						if (this.waitingAction.text == answers[a].text){
							val = answers[a].value
							break
						}
					}
				} else {
					return
				}
			} else {
				val = this.waitingAction.parent.click_value
				if (['get','set','cart', 'card'].includes(this.waitingAction.action)){
					val = JSON.parse(val)
				}
			}
			var regex = /{{(.*?)}}/g;
			var variables = val.toString().match(regex);
			var tokens 
			if (variables){
				tokens = variables.map(v => v.slice(2, -2).trim());
			}
			if(!tokens || tokens.length == 0){
				return
			}
			if (tokens.filter(t=> t.includes('+') || t.includes('-')).length >0){
				this.sayDev('🥏 Need to do Mindscript', tokens)
				return
			}
			tokens.forEach(t=>{
				if (this.variables[t.toLowerCase()] || this.variables[t.toLowerCase()] == 0){
					val = val.replace(`{{${t}}}`, this.variables[t.toLowerCase()])
				} else {
					val = val.replace(`{{${t}}}`, '')
				}
			})
			if (this.waitingAction.action =='message'){
				this.messageToShow = val
			}
			this.waitingAction.value = val
		},
		doActionTryNextQuestion() {
			this.chosenAnswer = false
			if (this.waitingAction && !['message', 'reply'].includes(this.waitingAction.action)){
				this.finishAction()
			} else {
				this.trackAction('click', this.responseForRepeat, this.questionAnnotation)
				this.messageToShow = ''
				this.tryNextQuestion("doactiontrynextquestion")
			}
		},
		finishCorrect: function () {
			if (this.audioDialog) {
				this.closeAudioDialog()
			} else if (this.videoDialog) {
				this.closeVideoDialog()
			}
			var qa = this.questionAnnotation
			this.sayDev("Do Finish Correct", qa)
			var wasCorrect = this.hasCorrectAnswer
      if (wasCorrect) {
        this.sayDev("WasCorrect...")
        if (this.waitingAction) {
          this.sayDev("Waiting action...")
          this.finishAction()
          this.forceCorrectDone = true
          return
        }
      }
			if (qa && qa.content && qa.content.force_correct){
				if(this.chosenAnswer != ''){
					this.showingRepeatResult = true;
					this.responseForRepeat = this.chosenAnswer
					this.showMessageDialog = false
					this.chosenAnswer = ''
					this.questionAnswer = ''
					this.replyMessage = ''
					this.replyDate = ''
				}
				if(!wasCorrect) {
					this.sayDev("Force correct!", wasCorrect)
          var s = qa.content.style
          if (s == "all" && qa.content.incorrect_action == 'message') {
            this.messageToShow = qa.content.incorrect_action_value
          }
					setTimeout(()=> {
						if (s == "select") {
							this.focusSelect()
						} else if (["free", "number", "date", "multiple", "all"].includes(s)) {
							var x = document.getElementById('reply_message_input')
							if (x) { x.focus() }
						}
					}, 200)				
					return
				}
				if (!this.forceCorrectDone){
          this.sayDev("ForceCorrectDone!")
					this.forceCorrectDone = true
				}
			}
			if (this.waitingAction) {
        this.sayDev("FinishCorrect waiting")
				this.finishAction()
			} else {
				this.tryNextQuestion("finishcorrect");
			}
		},
		finishChecks() {
			this.replyMessage = this.chosenAnswer = this.responseForRepeat = this.checkedItems.sort()
      this.sayDev("Checked Items")
      this.sayDev(this.checkedItems)

			if (this.questionAnnotation && this.questionAnnotation.content && this.questionAnnotation.content.answers && this.isJSON(this.questionAnnotation.content.answers)){
				var ans = JSON.parse(this.questionAnnotation.content.answers)
				ans.forEach(a=>{
					if (a.variable && (a.variable_value != null && a.variable_value != '') &&this.replyMessage.includes(a.text)){
						this.setVariable(a.variable.toLowerCase(), a.variable_value)
					}
				})
			}

			this.correctAnswer = this.questionAnnotation.content.correct_answer ? this.questionAnnotation.content.correct_answer : ''
      this.sayDev("CA: " + this.correctAnswer)
			// if (this.questionAnnotation && this.questionAnnotation.content && this.questionAnnotation.content.post_message) {
			// 	var pm = JSON.parse(this.questionAnnotation.content.post_message)
			// 	if (this.correctAnswer){
      //     this.sayDev("Z1")
			// 		if (this.hasCorrectAnswer) {
      //       this.sayDev("Z2")
			// 			pm = pm[0]
			// 		} else {
      //       this.sayDev("Z3")
			// 			pm = pm[1]
			// 		}
			// 	} else {
      //     this.sayDev("Z4")
			// 		pm = pm[0]
			// 	}
      //   this.sayDev("PM: " + pm)
			// 	this.messageToShow = pm || this.questin
      //   this.sayDev("PostMessage: " + pm)
      //   this.sayDev("Showing Correct: " + this.showingCheckout)
			// 	if (!this.showingCorrect && !this.questionAnnotation.content.force_correct){
			// 		this.questionAnnotation.response_time = new Date() - this.questionAnnotation.response_time
			// 		this.sayDev('FC ⌚️⌚️⌚️', this.questionAnnotation.response_time)		
			// 		this.trackAction('click',this.checkedItems.join('---'), this.questionAnnotation)
			// 		if (pm){
			// 			this.showDialogMessage(pm)
			// 		}
			// 		return
			// 	} else if (!this.hasCorrectAnswer & this.questionAnnotation.content.force_correct){
			// 		this.questionAnnotation.response_time = new Date() - this.questionAnnotation.response_time
			// 		this.sayDev('FC2 ⌚️⌚️⌚️', this.questionAnnotation.response_time)		
			// 		this.tryNextQuestion('finishChecks')
			// 		return
			// 	}
			// }
			this.submitReplyMessage()
		},
		skipQuestion() {
			this.questionAnnotation.response_time = new Date() - this.questionAnnotation.response_time
			this.sayDev('SkipQuestion ⌚️⌚️⌚️', this.questionAnnotation.response_time)

			if (this.questionAnnotation && this.questionAnnotation.content.force_correct) {
				var x = this.questionAnnotation
				this.questionAnnotation.content.force_correct = false
				setTimeout(()=> {
					x.content.force_correct = true
				}, 500)
			}
      this.sayDev("QuestionSet", this.questionSet)
			if (this.questionSet) {
				this.tryNextQuestion("skipquestionSet")
			} else {
				this.hideAllDialogsAndPlay("SkipQuestionNoSet")
			}
		},
		openPreview(opt = 0) {
			this.pausePlayer()
			if (!this.share.published) {
				this.$toasted.show("Video must be pubished before previewing")
				var x = document.getElementById('publishButton')
        if (x) {
          x.style.border = '2px solid red'
          setTimeout(() => {
            x.style.border = 'none'
          }, 3500);
        }
				return
			}
			var url = this.previewUrl + '/e/' + this.share.token + "?"
			if (this.guestEmail) {
				url += "email=" + this.guestEmail + "&"
			}
			if (this.opt != 0){
				url += "mobile=" + 1
			}

			this.openPopout(url, opt)
			// window.location.href = this.previewUrl + '/w/' + this.share.token
		},
		closeQuestion(){
			this.questionCreationDialog = false
			this.gptQuestion = -1
			this.gptQuestions = null
			this.correctAnswer = []
			this.maxCorrect = null
			this.minCorrect = null
		},
		skipGPTQuestion(){
			this.updateGptQuestion()
		},
		saveQuestion(addAnother) {
			this.busy = true
			this.addAnother = addAnother 
			if (this.editingQuestion && !this.editingQuestion.has_correct_answers){
				this.correctAnswer = ''
				this.minCorrect = ''
				this.maxCorrect = ''
			} else if(this.correctAnswer && this.correctAnswer.length > 0 && !['number','data'].includes(this.editingQuestion.style)){
				if (['all'].includes(this.editingQuestion.style)){
					this.correctAnswer.forEach(a => {
						a = a.replace(/\s+/g, ' ').trim()
					})
				} else if (this.editingQuestion.style == 'free') {
					this.correctAnswer.forEach(a => {
						a.value = a.value.replace(/\s+/g, ' ').trim()
					})
				} else {
          if (this.correctAnswer && typeof(this.correctAnswer) == "string") {
            this.correctAnswer = this.correctAnswer.replace(/\s+/g, ' ').trim()
          }
				}
			}
			var x = this.validateQuestion()
			if (!x) {
				this.tryTrack('Tried Invalid Question', this.editingQuestion)
				this.busy = false
				return
			}
			this.questionAnnotation = null
			// var data = this.getObject()
      var data = new FormData
			data.append("annotation[token]", this.share.token);
			data.append("annotation[time]", this.showCTA ? Math.floor(this.duration) : Math.floor(this.editingTime));
      data.append("annotation[exact_time]", this.showCTA ? this.playerDuration : this.editingTime);
			data.append('annotation[tray_type]', this.showCTA ? 'ctaTray' : null)
			data.append('annotation[internal_label]', this.editingQuestion.internal_label || "")

			if (this.editingQuestion.style == 'likert') {
				this.editingQuestion.answers = [
					{ "text": "Strongly Agree", "value": 5 },
					{ "text": "Agree", "value": 4 },
					{ "text": "Neither Agree Nor Disagree", "value": 4 },
					{ "text": "Disagree", "value": 2 },
					{ "text": "Strongly Disagree", "value": 1 },
				]
				this.editingQuestion.variable = 'LikertScore'
			}
			if(this.editingQuestion.is_hubspot){
				data.append('hubspot_field', this.editingQuestion.hubspot_field)
			}
      // console.log("CA:", this.correctAnswer, typeof(this.correctAnswer))
      this.editingQuestion.correct = null
			if (["date", "multiple", "poll", "select"].includes(this.editingQuestion.style)){
        if (this.correctAnswer && typeof(this.correctAnswer) == "string") {
          this.correctAnswer = this.correctAnswer.replace(/\s+/g, ' ').trim()
					this.editingQuestion.correct = this.correctAnswer
        }
			} else if (this.editingQuestion.style == "all"){
				if(this.correctAnswer && this.correctAnswer.length > 0){
					var answers = []
					this.editingQuestion.answers.forEach(a =>{
						answers.push(a.text.replace(/\s+/g, ' ').trim())
					})
					for(var i = 0; i < this.correctAnswer.length; i++){
						if (!answers.includes(this.correctAnswer[i])){
							this.correctAnswer.splice(i--,1);
						}
					}
					this.editingQuestion.correct = JSON.stringify(this.correctAnswer.sort())
				} else {
					this.editingQuestion.correct = null
				}
			} else {
				var correct = ""
				if (this.editingQuestion.style == 'number'){
					var min = this.minCorrect && this.minCorrect != '' ? this.minCorrect : this.maxCorrect
					var max = this.maxCorrect && this.maxCorrect != '' ? this.maxCorrect : this.minCorrect
					if(max != null && max != '' && min != null && min != ''){
						min = parseFloat(min);
						max = parseFloat(max);
						this.minCorrect =  Math.min(min,max)
						this.maxCorrect =  Math.max(min,max)
						this.editingQuestion.correct = JSON.stringify([this.minCorrect,this.maxCorrect]);
						this.minCorrect = null
						this.maxCorrect = null
					} else {
						this.editingQuestion.correct = null;
						this.minCorrect = null
						this.maxCorrect = null
					}
				} else {
					correct = []
					for(var c of this.correctAnswer){
						if (c.value != ''){
							correct.push(c.value)
						}
					}
					if (correct.length == 0){
						this.editingQuestion.correct = null;
					} else {
						this.editingQuestion.correct = JSON.stringify(correct)
					}
				}
			}
			this.correctAnswer = [{value: ''}]
			this.editingQuestion.prompt = this.sanitizeString(this.editingQuestion.prompt)
			this.editingQuestion.force_correct = this.editingQuestion.correct && this.editingQuestion.force_correct
			this.editingQuestion.skippable = this.editingQuestion.skippable && !this.editingQuestion.force_correct
			if (this.editingQuestion.answers){
				this.editingQuestion.answers.forEach((a,i) => {
					if (a.action == 'download'){
						this.editingQuestion.answers[i].value = JSON.stringify(this.assetDownload[i])
					}
				})
				this.editingQuestion.answers = JSON.stringify(this.editingQuestion.answers)
			} else {
				this.editingQuestion.answers = []
			}
			this.assetDownload = Array(5).fill({
				url: '',
				downloadName: '',
				id: '',
				type: '',
			}),
			data.append('editingQuestion', JSON.stringify(this.editingQuestion))
			data.append('variable', (this.editingQuestion.variable||'').replace("{{","").replace("}}", ""))
			data.append('is_conditional', this.editingQuestion.is_conditional)
			data.append('conditional_show', this.editingQuestion.conditional_show)
			data.append('conditional_variable', this.editingQuestion.conditional_variable)
			data.append('conditional_assertion', this.editingQuestion.conditional_assertion)
			data.append('conditional_value', this.editingQuestion.conditional_value)
			data.append('is_conditional_2', this.editingQuestion.is_conditional_2 && !!this.editingQuestion.conditional_variable_2 && !!this.editingQuestion.conditional_value_2)
			data.append('conditional_variable_2', this.editingQuestion.conditional_variable_2)
			data.append('conditional_assertion_2', this.editingQuestion.conditional_assertion_2)
			data.append('conditional_value_2', this.editingQuestion.conditional_value_2)
			data.append('conditional_operator', this.editingQuestion.conditional_operator)
			data.append('speak', this.editingQuestion.speak)
			data.append('speak_answers', this.editingQuestion.speak_answers)
			data.append('time', Math.floor(this.editingTime))
      data.append('exact_time', this.editingTime)
			data.append('can_rewind', this.editingQuestion.can_rewind || false)
			data.append('rewind_time', this.editingQuestion.rewind_time)
			data.append('show_hint', this.editingQuestion.show_hint || false)
			data.append('hint_message', this.editingQuestion.hint_message || "")
      data.append('voice_number', this.editingQuestion.voice_number || 0)
			data.append('top', JSON.stringify(this.editingQuestion.top || "0"))
			data.append('left', JSON.stringify(this.editingQuestion.left || "0"))
			data.append('width', JSON.stringify(this.editingQuestion.width || "100"))
			data.append('height', JSON.stringify(this.editingQuestion.height || "100"))
			data.append('background_color', JSON.stringify(this.editingQuestion.background_color || ""))
			data.append('correct_color', JSON.stringify(this.editingQuestion.correct_color || ""))
			data.append('incorrect_color', JSON.stringify(this.editingQuestion.incorrect_color || ""))
			data.append('prompt_color', JSON.stringify(this.editingQuestion.prompt_color || ""))
			data.append('answers_color', JSON.stringify(this.editingQuestion.answers_color || ""))
			data.append('transition', JSON.stringify(this.editingQuestion.transition || ""))

			if (this.editingAnnotation && this.editingAnnotation.id) {
				data.append('annotation_id', this.editingAnnotation.id)
			}
			Rails.ajax({
				url: '/questions/do',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					var variables = data.variables
					data = data.annotation
					this.busy = false
					if (this.editingAnnotation.id) {
						this.editingAnnotation.undo_redo = 1 // can undo
						this.$toasted.show("Updated Question 🎉")
						if (this.addAnother) {
							this.deciTime = data.exact_time
							this.editingAnnotation = {}
							this.showQuestionDialog()
						} else {
							this.questionCreationDialog = false
							this.questionDialog = false
							this.doScroll(data.id)
						}
						this.fetchInteractions(data.id)
					} else {
						this.$toasted.show("Created Question 🎉")
						if (!this.addAnother && !this.gptQuestions) {
              this.changeTime(Math.max(data.time - 1, 0))
							this.doScroll(data.id)
            }
						this.deciTime = this.editingTime
						this.handleNewAnnotation(data)
					}
					this.updateShareVariables(variables)
					setTimeout(() => {
						this.busy = false
						if (this.gptQuestions){
							this.updateGptQuestion()
						}
					}, 50)
				}, error: (e) => {
					this.$toasted.show('Something went wrong. If the problem persists, please refresh the page and try again.')
					this.resetEditingQuestion()
					this.busy = false
				}

			});
		},
		quickBorder: function (str) {
			document.getElementById(str).style.border = '1px solid red'
			setTimeout(() => {
				document.getElementById(str).style.border = '0px solid grey'
			}, 2000);
		},
		destroyCanvas() {
			this.resetCanvas()
			this.clearSelection()
			if (this.canvas) {
				this.canvas.clear()
				this.canvas.dispose()
				this.canvas = null
			}
		},
		resetCanvas: function () {
			this.clearSelection()
			if (this.canvas) {
				this.canvas.clear()
				this.canvasDirty = false
				this.startDrawing()
			}
		},
		saveDrawing: function (recordAudio=false) {
			if (this.questionAnnotation){
				this.questionAnnotation.response_time = new Date() - this.questionAnnotation.response_time
				this.sayDev('SD ⌚️⌚️⌚️', this.questionAnnotation.response_time)
			}
			this.sayDev("SaveDrawing!")
			this.drawSeekBounding("saveDrawing")
			this.canvas.renderAll()
			var canvasURL = this.canvas.toDataURL('image/jpeg', 1.0)
			if (!canvasURL) {
				canvasURL = this.canvas.toDataURL('image/png')
			}

			if (!canvasURL) {
				canvasURL = this.canvas.toDataURL()
			}

			if (this.canvas && !canvasURL) {
				this.adblockerDialog = true
				this.$toasted.show('Unable to Save Drawing')
				return
			}

			var data = this.getObject()
			data.append("annotation[exact_time]", this.deciTime);
			data.append("annotation[content]", canvasURL);
			data.append("annotation[data]", JSON.stringify(this.canvas.toJSON()))
			data.append("annotation[type_of]", "drawing");
			data.append("annotation[extra]", this.extra);
			if (this.questionAnnotation && this.questionAnnotation.response_time){
				data.append("annotation[response_time]", this.questionAnnotation.response_time)
			}
			this.createAnnotation(data)
      this.waitingToRecordAudio = this.ownsPost && recordAudio
      this.sayDev("RecordAudio: " + this.audioDialog)
			setTimeout(() => {
				this.destroyCanvas()
				this.tryTrack("Saved Screen Art", { url: window.location.href, image: canvasURL })
				// this.questionAnnotation = null
				// this.tryNextQuestion("savedrawing")
				this.drawingDialog = false
			}, 100);
		},
		startSaveDrawing() {
			this.drawSeekBounding("saveDrawing")
			this.canvas.discardActiveObject()
			this.canvas.renderAll();
		},
		stopDrawing: function () {
			if (true) {
				this.isDrawing = false
				this.isColoring = false
				this.drawingDialog = false
				this.tryNextQuestion("stopdrawing")
				this.tryTrack("Discarded Screen Art", { url: window.location.href })
			}
		},
		setCurrentShare() {
      this.blockAutoSave = true
			this.currentShare = Object.assign({}, this.share);
      setTimeout(()=> {
        this.blockAutoSave = false
      }, 500)
		},
		removeFromGroup() {
			this.currentShare.group_id = ''
			this.updateShare(false)
		},
		removeObject: function () {
			this.canvas.remove(this.canvas.getActiveObject());
			this.tryTrack("Removed Screen Art Object", { url: window.location.href })
		},
		textOffset(y) {
			if (y > this.canvasHeight / 2) {
				return 0 - this.shapeHeight / 2 - 35
			}
			return this.shapeHeight / 2 + 5
		},
		addText: function (x, y) {
			this.isDrawing = false
			var text = new fabric.IText(' ', {
				fontSize: this.isMobile ? 12 : 24,
				fontFamily: 'Arial',
				shadow: '2px 2px 10px rgba(0,0,0,0.5)',
				backgroundColor: this.canvasSettings.background ? 'white' : '',
				padding: 10,
				lineHeight: 1.25,
				// stroke: this.fillColor.hex ? this.fillColor.hex : this.color,
				fill: this.fillColor.hex ? this.fillColor.hex : this.color,
				left: Math.max(20, Math.min(this.canvasWidth - 20, x)),
				top: Math.max(20, Math.min(this.canvasHeight - 20, y)),
				textAlign: 'center',
				borderColor: '#FFD700',
			})
			this.canvas.add(text);
			// this.canvas.setActiveObject(x)
			// this.canvas.getActiveObject().isEditing = true

			this.tryTrack("Added Screen Art Text", { url: window.location.href })

			setTimeout(() => {
				text.enterEditing()
				text.selectAll()
				this.editObject = text
			}, 333);
		},
		stopEditingObject: function () {
			if (this.editObject && this.editObject.isEditing) {
				this.editObject.exitEditing()
			}
		},
		canvasClick(event) {
			this.stopEditingObject();
			this.editObject = false
			this.canvasDirty = true

			if (this.waitingObject) {
        this.resizeLayer("CanvasClick")
				var x = event.offsetX
				var y = event.offsetY

				if (!x && !y) {
					var rect = event.target.getBoundingClientRect();
					x = event.targetTouches[0].pageX - rect.left;
					y = event.targetTouches[0].pageY - rect.top;
				}
				// this.waitingObject = null
				if (this.waitingObject == 'Circle') {
					this.addCircle(x, y)
					if (this.canvasSettings.addText) {
						this.addText(x - 10 - this.shapeHeight / 5, y + this.textOffset(y))
					}
				} else if (this.waitingObject == 'Arrow') {
					// this.addLine(x,y)
					if (this.canvasSettings.addText) {
						this.addText(x - 10 - this.shapeHeight / 5, y + this.textOffset(y))
					}
					this.addNewArrow(x, y)
				} else if (this.waitingObject == 'Line') {
					// this.addLine(x,y)
					if (this.canvasSettings.addText) {
						this.addText(x + 50, y - 50)
					}
					this.addSimpleLine(x, y)
				} else if (this.waitingObject == 'LineArrow') {
					this.addLineArrow(x, y)
				} else if (this.waitingObject == 'Text') {
					this.addText(x, y)
				}
				this.waitingObject = null
				this.canvasDirty = true
				this.canvas.renderAll()
			}
		},
		wait(str) {
			if (str == "Text" && this.isTouchDevice) {
				this.$toasted.show("Text not available on this device")
				return
			}
			this.waitingObject = str
		},
		addSimpleLine: function (x, y) {
			var y2 = 10
			if (y > this.videoHeight) {
				y2 = this.videoHeight - 10
			}
			var triangle = new fabric.Line([x, y - 75, x, y + 75], {
				left: x,
				top: y - 75,
				stroke: this.strokeColor,
				strokeWidth: 3,
				padding: 15,
				cornerSize: 20, cornerStyle: 'circle', transparentCorners: false, cornerStrokeColor: '#FFD700',
				borderColor: '#FFD700',
				cornerColor: '#FFD700',
			});
			this.canvas.add(triangle)
			this.canvas.setActiveObject(triangle)
		},
		addCircle: function (x, y) {
			// var circle = new Circle(this.canvas);
			var object = new fabric.Circle({
				fill: '',
				stroke: this.strokeColor,
				strokeWidth: 2.5,
				opacity: 1,
				width: this.shapeHeight,
				height: this.shapeHeight,
				radius: this.shapeHeight / 2,
				left: x - this.shapeHeight / 2,
				top: y - this.shapeHeight / 2,
				padding: 10,
				cornerSize: 20, cornerStyle: 'circle', transparentCorners: false, cornerStrokeColor: '#FFD700',
				borderColor: '#FFD700',
				cornerColor: '#FFD700',

			});
			this.canvas.add(object);
			if (!this.canvasSettings.addText) {
				this.canvas.setActiveObject(object)
			}
			this.tryTrack("Added Circle To Screen Art", { url: window.location.href })
		},
		addLineArrow: function (x, y) {
			var fromx = 9 * this.canvasWidth / 10
			var fromy = y
			var texty = y + 5
			var textx = fromx - 50
			if (y < this.canvasHeight / 2) {
				texty = y - 45
			}
			if (x < this.canvasWidth / 2) {
				fromx = this.canvasWidth / 10
				textx = fromx
			}
			var tox = x
			var toy = y
			var angle = Math.atan2(toy - fromy, tox - fromx);
			var headlen = 20;
			tox = tox - (headlen) * Math.cos(angle);
			toy = toy - (headlen) * Math.sin(angle);

			var hv = 6

			var points = [
				{
					x: fromx,  // start point
					y: fromy
				}, {
					x: fromx - (headlen / hv) * Math.cos(angle - Math.PI / 3),
					y: fromy - (headlen / hv) * Math.sin(angle - Math.PI / 3)
				}, {
					x: tox - (headlen / hv) * Math.cos(angle - Math.PI / 3),
					y: toy - (headlen / hv) * Math.sin(angle - Math.PI / 3)
				}, {
					x: tox - (headlen) * Math.cos(angle - Math.PI / 3),
					y: toy - (headlen) * Math.sin(angle - Math.PI / 3)
				}, {
					x: tox + (headlen) * Math.cos(angle),  // tip
					y: toy + (headlen) * Math.sin(angle)
				}, {
					x: tox - (headlen) * Math.cos(angle + Math.PI / 3),
					y: toy - (headlen) * Math.sin(angle + Math.PI / 3)
				}, {
					x: tox - (headlen / hv) * Math.cos(angle + Math.PI / 3),
					y: toy - (headlen / hv) * Math.sin(angle + Math.PI / 3)
				}, {
					x: fromx - (headlen / hv) * Math.cos(angle + Math.PI / 3),
					y: fromy - (headlen / hv) * Math.sin(angle + Math.PI / 3)
				}, {
					x: fromx,
					y: fromy
				}
			];

			var object = new fabric.Polyline(points, {
				fill: this.fillColor.hex ? this.fillColor.hex : this.color,
				stroke: 'white',
				opacity: 1,
				strokeWidth: 2,
				stroke: 'white',
				originX: 'left',
				originY: 'top',
				selectable: true,
				padding: 10,
				shadow: '2px 2px 10px rgba(0,0,0,0.5)',
			});

			this.canvas.add(object);
			if (!this.isMobile) {
				this.addText(textx, texty)
			} else {
				this.canvas.setActiveObject(object)
			}
			this.tryTrack("Added Line To Screen Art", { url: window.location.href })
		},
		addNewArrow: function (x, y) {
			var tox = x
			var toy = y
			var fromx = x
			var fromy = y + 75

			var angle = Math.atan2(toy - fromy, tox - fromx);
			var headlen = 30;  // arrow head size
			tox = tox - (headlen) * Math.cos(angle);
			toy = toy - (headlen) * Math.sin(angle);

			var points = [
				{
					x: fromx,  // start point
					y: fromy
				}, {
					x: fromx - (headlen / 4) * Math.cos(angle - Math.PI / 2),
					y: fromy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
				}, {
					x: tox - (headlen / 4) * Math.cos(angle - Math.PI / 2),
					y: toy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
				}, {
					x: tox - (headlen) * Math.cos(angle - Math.PI / 2),
					y: toy - (headlen) * Math.sin(angle - Math.PI / 2)
				}, {
					x: tox + (headlen) * Math.cos(angle),  // tip
					y: toy + (headlen) * Math.sin(angle)
				}, {
					x: tox - (headlen) * Math.cos(angle + Math.PI / 2),
					y: toy - (headlen) * Math.sin(angle + Math.PI / 2)
				}, {
					x: tox - (headlen / 4) * Math.cos(angle + Math.PI / 2),
					y: toy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
				}, {
					x: fromx - (headlen / 4) * Math.cos(angle + Math.PI / 2),
					y: fromy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
				}, {
					x: fromx,
					y: fromy
				}
			];

			var pline = new fabric.Polyline(points, {
				fill: this.strokeColor,
				opacity: 1,
				originX: 'left',
				originY: 'top',
				selectable: true,
				padding: 15,
				borderColor: '#FFD700',
				cornerColor: '#FFD700',
			});

			this.canvas.add(pline);
			this.canvas.setActiveObject(pline)
			this.canvas.renderAll();
		},
		addLine: function (x, y) {
			var px = Math.round(x * 100 / this.canvasWidth)
			var fromx = 9 * this.canvasWidth / 10
			var fromy = y

			var texty = y + 15
			var textx = 8 * this.canvasWidth / 10
			if (y < this.canvasHeight / 2) {
				texty = y - 35
			}
			if (x < this.canvasWidth / 2) {
				fromx = this.canvasWidth / 10
				textx = fromx - this.canvasWidth / 10
			}
			var tox = x
			var toy = y
			var angle = Math.atan2(toy - fromy, tox - fromx);
			var headlen = 20;  // arrow head size
			tox = tox - (headlen) * Math.cos(angle);
			toy = toy - (headlen) * Math.sin(angle);
			var points = [
				{
					x: fromx,  // start point
					y: fromy
				}, {
					x: fromx,  // start point
					y: toy
				}, {
					x: tox,  // start point
					y: toy
				}, {
					x: tox,
					y: fromy
				}
			];
			var object = new fabric.Polyline(points, {
				opacity: 1,
				fill: this.strokeColor,
				stroke: 'white',
				strokeWidth: 2,
				originX: 'left',
				originY: 'top',
				selectable: true,
				shadow: '2px 2px 10px rgba(0,0,0,0.5)',
			});

			this.canvas.add(object);
			if (this.canvasSettings.addText) {
				this.addText(textx, texty)
			} else {
				this.canvas.setActiveObject(object)
			}
			this.tryTrack("Added Line To Screen Art", { url: window.location.href })
		},
		clearSelection: function () {
			this.isDrawing = false
			this.isColoring = false
			if (this.canvas) {
				this.canvas.discardActiveObject()
				this.canvas.renderAll()
			}
		},
		startDrawing: function () {
			this.isColoring = false
			this.isDrawing = true
			this.canvas.freeDrawingBrush.width = 5
			this.canvas.freeDrawingBrush.color = this.fillColor.hex || this.color;
		},
		createCanvas: function () {
      this.resizeLayer("CreateCanvas")
			if (this.shouldAuth && this.share.capture_auth_point != 1) { this.authDialog = this.shouldAuth; return }
			if (this.shouldPay) { this.payDialog = this.shouldPay; return }
			var time = 300
			var hasFabric = typeof fabric !== typeof undefined;

			if (hasFabric) {
				this.fabricLoaded = true
			} else {
				time = 750
			}
			this.drawingDialog = true
			this.isDrawing = false
			this.addingDialog = false
			this.editObject = false
			var canvas;
			var script = document.createElement("script")
			script.type = "text/javascript";
			script.onload = () => {
        this.resizeLayer("CanvasClick scriptOnLoad")
				setTimeout(() => {
					var vid = document.getElementById('videoWrap')
					this.drawSeekBounding("createCanvas")
					this.canvas = canvas = window._canvas = new fabric.Canvas('canvas', { position: 'absolute', width: this.canvasWidth + 'px', height: this.canvasHeight + 'px', top: this.canvasTop + 'px', left: this.canvasLeft + 'px' });
					this.fabricLoaded = true
          if (!this.isSafari) {
            this.setCanvasBackgroundImage()
          }
          canvas.on("text:editing:exited", (e) => {
						if (e.target.text.trim(" ") == '') {
							this.canvas.remove(e.target)
						}
					});
					canvas.on('selection:created', () => {
						this.activeObject = canvas.getActiveObject()
						this.activeObject.set({
							borderColor: '#FFD700',
							cornerColor: '#FFD700',
							cornerSize: 20, cornerStyle: 'circle', transparentCorners: false, cornerStrokeColor: '#FFD700',
							transparentCorners: true
						});
					});
					canvas.on('selection:updated', () => {
						this.activeObject = canvas.getActiveObject()
					});
					canvas.on('selection:cleared', () => {
						this.activeObject = null
					});

					fabric.IText.prototype.set({
						_getNonTransformedDimensions() { // Object dimensions
							return new fabric.Point(this.width, this.height).scalarAdd(this.padding);
						},
						_calculateCurrentDimensions() { // Controls dimensions
							return fabric.util.transformPoint(this._getTransformedDimensions(), this.getViewportTransform(), true);
						}
					});
					setTimeout(() => {
						// this.isDrawing = true
            this.resizeLayer("CanvasTimeout")
						canvas.renderAll();
						if (canvas) {
							this.canvasReady = true
							document.getElementsByClassName('upper-canvas')[0].addEventListener('click', (event) => {
								this.canvasClick(event)
							})
							document.getElementsByClassName('upper-canvas')[0].addEventListener('touchstart', (event) => {
								this.canvasClick(event)
							})
							this.startDrawing();
							// if (this.replyAnnotation) {
							// 	this.startDrawing()
							// }
						} else {
							this.createCanvas();
						}
						this.tryTrack("Entered Screen Art Mode", { url: window.location.href })
					}, 15)

					// canvas.style.position = 'absolute'
					// canvas.style.top = '0'
				}, 5);

			}

			if (!this.fabricLoaded) {
				script.src = "https://cdnjs.cloudflare.com/ajax/libs/fabric.js/3.4.0/fabric.min.js";
				document.getElementsByTagName("head")[0].appendChild(script);
			} else {
				setTimeout(() => {
					script.onload();
				}, time);
			}
		},
		formatPhone: function (phoneNumberString) {
			var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
			var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
			if (match) {
				return '(' + match[1] + ') ' + match[2] + '-' + match[3]
			}
			return phoneNumberString
		},
		toggleNotes: function () {
			this.showNotes = !this.showNotes
			if (this.showNotes) {
				this.tryTrack("Opened Video Guide", { url: window.location.href })
			} else {
				this.tryTrack("Hid Video Guide", { url: window.location.href })
			}
			setTimeout(() => {
				this.drawSeekBounding("toggleNotes")
			}, 100);
		},
		toggleFullscreen: function () {
			if (this.fullscreenBusy) {
				return
			} else {
				this.fullscreenBusy = true
				setTimeout(() => {
					this.fullscreenBusy = false
				}, 750);
			}
			this.isFullscreen = !this.isFullscreen
			if (this.isFullscreen) {
				this.showTooltip = false
				// if (this.isMobile) {
				//   screen.orientation.lock("landscape")
				if (this.canFullscreen) {
					screenfull.request();
				} else {
          this.isFullscreen = false
        }
			} else {
				// Not fullscreen
				if (screenfull && screenfull.isEnabled) {
					screenfull.exit();
          this.isFullscreen = false
				}
			}
			setTimeout(() => {
				this.drawSeekBounding("toggleFullscreen")
			}, 250);
		},
		getExactTime(annTime) {
			return annTime.toFixed(2)
		},
		setPlay: function () {
			this.playDialog = true
		},

		loadMoreContacts: function () {
			var that = this
			if (that.endOfPlays) {
				return;
			}
			var new_plays = []

			if (that.ajaxingActivities || that.ajaxHold) {
			} else {
				that.ajaxingActivities = true
				this.ajaxHold = true
				Rails.ajax({
					url: '/more_contacts?index=' + that.contacts.length,
					type: "GET",
					dataType: "JSON",
					success: (data) => {
						var old = that.contacts.length
						for (var i = 0; i < data.length; i++) {
							that.contacts.push(data[i])
						}
						if (that.contacts.length == old && that.contacts.length != 0) {
							// that.$toasted.show("No more contacts to display 🤔")
							this.$toasted.show("All Viewers Loaded ✅")
							that.endOfActivities = true
						} else {
							this.$toasted.show("Loaded " + data.length + " Viewers ✨")
						}
						that.ajaxingActivities = false
						that.ajaxHold = false
						this.tryTrack("Loaded More Contacts", { url: window.location.href })

					}
				});
			}
		},
		setReplyAnnotation(ann) {
			this.replyAnnotation = Object.assign({}, ann)
      if (this.replyAnnotation && this.replyAnnotation.force_lead_capture && !this.ownsPost) {
        this.pausePlayer()
        this.authDialog = true
        this.forceAuthInteraction = true
      }
			this.replyAnnotationID = ann.id
			this.replyVariable = this.replyAnnotation.variable
			this.replyVariableValue = this.replyAnnotation.value
      // this.sayDev("SetReplyAnnotation " + this.replyAnnotation.type_of + " -- variable --> " + this.replyVariable + " -- value --> " + this.replyVariableValue )
			this.setVariable(this.replyVariable, this.replyVariableValue)
		},
		clearReplyAnnotation() {
			// this.sayDev("🌈 ClearReplyAnnotation")
			this.replyAnnotation = null
			this.replyAnnotationID = null
			this.checkedItems = []
		},
		toggleListDialog: function () {
			var next = !this.notesDialog
			this.notesDialog = next
			this.showSearch = false
			if (this.hasSubtitles){
				this.fetchSubtitles()
			}
		},
		answerVideoQuestion: function (ann) {
			this.resetTimer()
			if (this.shouldAuth) { this.authDialog = this.shouldAuth; this.authReturn = { type: 'video' }; return }
			if (this.shouldPay) { this.payDialog = this.shouldPay; this.payReturn = { type: 'video' }; return }
			this.setReplyAnnotation(ann)
			this.recordVideoDialog = true
			this.tryTrack("Started to Answer Video Question", { url: window.location.href })
		},
		answerImageQuestion: function (ann) {
			this.resetTimer()
			if (this.shouldAuth) { this.authDialog = this.shouldAuth; this.authReturn = { type: 'image' }; return }
			this.setReplyAnnotation(ann)
			this.tryTrack("Started to Answer Image Question", { url: window.location.href })
		},
		answerDrawQuestion: function (ann) {
			this.sayDev("AnswerDraw", ann)
			this.resetTimer()
			if (this.shouldAuth) { this.authDialog = this.shouldAuth; this.authReturn = { type: 'draw' }; return }
			if (this.shouldPay) { this.payDialog = this.shouldPay; this.authReturn = { type: 'draw' }; return }
			this.setReplyAnnotation(ann)
			// this.questionDialog = false
			this.createCanvas()
			this.$toasted.show(this.isTouchDevice ? 'Touch the screen to draw!' : 'Click on the screen to draw!', {
				duration: 1000
			})
			this.tryTrack("Started to Answer Drawing Question", { url: window.location.href })
		},
		answerVoiceQuestion: function (ann) {
			this.resetTimer()
			if (this.shouldAuth) { this.authDialog = this.shouldAuth; this.authReturn = { type: 'voice' }; return }
			if (this.shouldPay) { this.payDialog = this.shouldPay; this.payReturn = { type: 'voice' }; return }
			this.setReplyAnnotation(ann)
			this.recordAudioDialog = true
			this.tryTrack("Started to Answer Voice Question", { url: window.location.href })
		},
		drawSeekBounding: function (args) {
      if (this.ownsPost && !this.showSplitColumns) {
        return
      }
      this.resizeLayer("DrawSeekBounding")
			this.setVideoInformation()
			var divRect = document.getElementById('custom-seekbar')
			if (divRect) {
				divRect = divRect.getBoundingClientRect();
			}
			if (this.isPopout) {
				var vid = document.getElementById('videoWrap')
				if (vid) {
					this.isMobile = this.videoWidth < 540 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
				} else {
					setTimeout(() => {
						if (document.getElementById('videoWrap')) {
							this.isMobile = this.videoWidth < 540 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
						} else {
						}
					}, 500);
				}
			}
		},
		sendSeek: function (seconds) {
			setTimeout(() => {
				this.seeks.push(Math.floor(parseInt(seconds)))
			}, 500)
		},
    saveProgress(time=null) {
      if (this.ownsPost) {
        return
      }
      this.sayDev("❤️‍🔥 Save Progress at " + (time || this.currentTime) + " (" + this.seconds.length + " watched)")
      this.sendChunk(time || this.currentTime)
      this.saveChunks(false)
    },
		sendChunk: function (chunkEnd) {
			if (chunkEnd - this.chunkStart > Math.max(this.duration / 20, 10) + 30) {
				this.chunkStart = Math.min(this.currentTime, this.duration)
				// this.sayDev("🧢 SendChunk too long")
				return
			}
			
			var ce = Math.min(chunkEnd, this.duration)
			this.chunkStart = Math.min(this.chunkStart, this.duration)
			
			if (this.variables.noview || this.isTour) { return }
			if (ce > this.chunkStart) {
				// this.sayDev("🧢 " + this.chunkStart + " --> " + ce)
				// this.sayDev("🧢 SendChunk: " + this.chunkStart + " - " + chunkEnd)
				this.chunksWatched.push([this.chunkStart, ce])
				var existing = this.chunksHashed[this.chunkStart]
				if (existing) {
					this.chunksHashed[this.chunkStart].push(ce)
				} else {
					this.chunksHashed[this.chunkStart] = [ce]
				}
				this.chunkStart = ce
			} else {
				// this.sayDev("🧢 SendChunk: Reset to " + this.chunkStart)
				this.chunkStart = ce
			}
		},

		saveChunks: function (finished) {
			if (this.ownsPost || this.variables.noview || this.blockSaveChunks || this.seconds.length < 3) {
        // this.sayDev("❌ ❌ ❌ OwnsPost or Noview, blocking save!")
        // this.sayDev("NoView: " + this.variables.noview)
        // this.sayDev("blockSaveChunks: " + this.blockSaveChunks)
				return
			} else {
        // this.sayDev("✅ ✅ ✅ Going to save ")
      }
			// this.sayDev("👛 SaveChunks", this.chunksWatched)
			var data = new FormData
      var viewer_id = this.viewerID || this.getLocalStorageItem("viewer_id") || ""
      this.sayDev("Viewer ID: " + viewer_id)
      if (viewer_id){
        data.append("info[viewer_id]", viewer_id)
      }
			data.append("info[track]", this.isEmbed || !this.ownsPost)
			data.append("info[seconds]", JSON.stringify(this.seconds))
			data.append("info[chunksWatchedJSON]", JSON.stringify(this.chunksWatched))
			data.append("info[chunksHashed]", this.chunksHashed)
			data.append("info[chunksWatched]", this.chunksHashed)
			data.append("info[seeks]", JSON.stringify(this.seeks.slice(0, 100)))
			data.append("info[guestName]", this.guestName || "")
			data.append("info[guestEmail]", this.guestEmail || "")
			data.append("info[guestPhone]", this.guestPhone || "")
			data.append("info[guestID]", this.guestID || "")
			data.append("info[duration]", this.duration)
			data.append("info[post_id]", this.post_id || "")
			data.append("info[video_id]", this.share.id || "")
			data.append("info[token]", this.shareToken || "")
			data.append("info[browser]", this.browser)
			data.append("info[user_agent]", window.navigator ? window.navigator.userAgent : '')
			data.append("info[screenWidth]", this.screenWidth)
			data.append("info[screenHeight]", this.screenHeight)
			data.append("info[group_id]", this.share.group_id)
			data.append("info[series_id]", this.getUrlParam("series_id"))
			data.append("info[finished]", finished || "false")
			data.append("info[latitude]", this.latitude)
			data.append("info[longitude]", this.longitude)
			data.append("info[preview]", this.isPreview)
      
			data.append("info[params]", this.getFullInteractionParams().length ? "?" + this.getFullInteractionParams() : "")
			data.append("info[url]", (window.location != window.parent.location)
				? document.referrer
				: this.isEmbed ? document.location.href + this.windowOriginalSearch : window.location.href)
			data.append("info[url_params]", "?" + this.getFullInteractionParams())
			if(this.referVideoStack.length > 0){
				data.append("info[refer_video]", this.referVideoStack[0])
			}
      if (this.activeTextTrack && this.activeTextTrack != "Off") {
        data.append("info[captions]", this.activeTextTrack)
      }
      try {
        var v = {}
        if (this.variables) {
          Object.keys(this.variables).forEach((key)=> { 
            if (!this.skipVars.includes(key)) {
              v[key] = this.variables[key] !== null && this.variables[key] !== undefined ? this.variables[key].toString() || v[key] : ''
            }
          })
        }
        data.append("info[variables]", JSON.stringify(v))
      } catch (e) {
        this.sayDev("Error parsing variables")
        this.sayDev(e)
      }
      
			this.blockSaveChunks = true
			setTimeout(() => {
				this.blockSaveChunks = false
			}, 2000)
			// this.sayDev("🔥 UpdatePlay")
			if (this.playID) {
				Rails.ajax({
					url: '/plays/update/' + this.playID,
					type: "POST",
					data: data,
					dataType: "JSON",
					success: (data) => {
            // this.sayDev("✅ Play updated (" + this.seconds.length+")")
            this.blockSaveChunks = false
            
					},
					error: (e, reason, body) => {
						if (body && body.status == 401 && !this.currentUser) {
							console.log("Blocked IP, redirect!")
							// window.location.href = "https://mindstamp.io?from=ip_block"
							return
						} else {
              this.blockSaveChunks = false
              setTimeout(()=> {
                this.tryTrack("Play Saving Error", {error: e})
                this.saveChunks()
              }, 3000)
            }
					}
				});
			} else {
				if (this.playCreated && this.playID) {
          this.sayDev("Play created already, return....play id is " + this.playID)
					return
				} else {
					this.playCreated = true
				}
        this.sayDev("Create new play...")
				Rails.ajax({
					url: '/plays/create',
					type: "POST",
					data: data,
					dataType: "JSON",
					success: (data) => {
						this.playID = data.id;
						this.sayDev("✅ Play created: " + data.id)
            if (this.lsTest()) {
              this.sayDev("Try Local...")
              const previous = localStorage.getItem("MS_PLAYS")
              var plays = []
              plays = this.tryParseJSON(previous) || []
              plays.unshift({
                id: data.id,
                token: data.token,
                created_at: data.created_at,
                viewer_id: data.viewer_id,
                session_id: data.session_id
              })
              this.viewerID = data.viewer_id
              this.sayDev("Set Plays", plays)
              localStorage.setItem("MS_PLAYS", JSON.stringify(plays))
              localStorage.setItem("viewer_id", this.viewerID)
            }
						this.playCreated = true
            if (data && data.viewer_id && !this.variables.viewer_id) {
              this.setVariable('viewer_id', data.viewer_id)
            }
					},
					error: (e) => {
						this.playCreated = false
            this.blockSaveChunks = false
            setTimeout(()=> {
              this.tryTrack("Play Saving Error", {error: e})
              this.saveChunks()
            }, 3000)
					}
				});
			}
		},
    hideTooltip() {
      this.showTooltip = false
    },
		setTooltip: function (str, e) {
			if (this.isTouchDevice) {
				return
			}
      
      this.tooltipText = this.removeHTML(str)
      this.showTooltip = true
			var b = document.getElementById('bg')
      var tooltipSpan = document.getElementById('tooltip-span');
      var seekbar = document.getElementById('new-seekbar')
      var left = this.tooltipText.length > 5 ? this.tooltipText.length * percentage * 10 : 25
      tooltipSpan.style.left = (e.clientX - left )+ 'px'
      tooltipSpan.style.top = seekbar.getBoundingClientRect().y - 50 + 'px'
      return
			if (b) {
				var width = b.offsetWidth
				var diff = e.clientX - b.getClientRects()[0]["x"]
				var percentage = diff / width
				this.tooltipText = str
				var divRect = document.getElementById('custom-seekbar').getBoundingClientRect();
				var tooltipSpan = document.getElementById('tooltip-span');
				var x = e.clientX,
					y = divRect.bottom;
				tooltipSpan.style.top = (y - 35) + 'px';
				var left = this.tooltipText.length > 5 ? this.tooltipText.length * percentage * 10 : 25
				tooltipSpan.style.left = (x - left) + 'px';
				this.showTooltip = true

			}
		},

		mouseLeaveVideo: function () {
			this.showTooltip = false
			this.timeDrag = false
			this.hideSeekbar("MLV");
		},
		markerHover: function (e, m) {
			this.setTooltip(m.markerString, e)
		},
		chapterHover: function (e, c) {
			this.setTooltip(c.title, e)
		},
		getPercentage: function (e) {
			var s = document.getElementById('custom-seekbar') || document.getElementById('new-seekbar')
			if (s) {
				var rect = s.getBoundingClientRect();
        var clientX = e.clientX
        if (e.type === 'touchmove') {
          if (e.touches && event.touches.length > 0) {
            clientX = e.touches[0].clientX;
          }
        }
				var x = clientX - rect.left; //x position within the element.
				var y = e.clientY - rect.top;  //y position within the element.
				var percentage = parseInt(x * 100 / rect.width)
				// console.log("Return percentage: " + percentage)
				return Math.min(Math.max(percentage, 0), 100)
			}
			return 0
		},
		updateSeekbar(percentage, changeTime) {
      if (isNaN(percentage)) {
        this.sayDev("🚨 updateSeekbar: " + percentage)
        return
      }
      
			this.seekbarPercentage = Math.min(100, Math.max(percentage, 0))
			if (changeTime) {
				this.sayDev("💕 updateSeekbar: " + percentage)
				var t = this.player.duration() * 0.01 * percentage
				if (Math.round(t) > this.currentTime.valueOf()){
          this.saveProgress(this.currentTime.valueOf())
				}
				this.showCTA = false
        this.pausedUntilClick = false
				this.player.currentTime(t.toFixed(1))
        this.doTimeUpdate()
        this.deciTime = t.toFixed(1)
        this.chunkStart = Math.round(t)
				setTimeout(() => {
					this.blockSaveChunks = false
					if (this.lastPauseTime != t) {
						this.lastPauseTime = -1
					}
					this.doGtagPercentage()
				}, 500)
			}
		},
		mouseUpSeekbar: function (e) {
			if (this.cannotClickSeekbar && !this.isDebug) { this.sayDev("Cannot click"); return }
      this.sayDev("MouseUpSeekbar")

			if (this.timeDrag) {
        this.sayDev("Was time dragging, update to " + this.getPercentage(e))
				this.timeDrag = false
				this.showStamps()
				this.updateSeekbar(this.getPercentage(e), true)
			}
			setTimeout(()=> {
				if (!this.timeDrag) {
					var percentage = this.getPercentage(e)
					var t = Math.round(this.duration * 0.01 * percentage)
					this.trackGtagEvent('Changed Video Time', this.share.title, 'Video Player Events', this.formattedSeconds(t));
					this.prepareMedias()
				}
			}, 200)
			// this.showTooltip = false
		},
		mouseDownSeekbar: function (e) {
      if (!this.ownsPost && this.cannotClickSeekbar) {
        return
      }
      this.sayDev("MouseDownSeekbar!")
			// if (this.cannotClickSeekbar && !this.isDebug) { this.$toasted.show("Navigation Disabled"); return }
			this.timeDrag = true
			var percentage = this.getPercentage(e)
      this.sayDev("Percentage: " + percentage)
			var t = Math.round(this.duration * 0.01 * percentage)
      this.sayDev("T: " + t)
			// this.updateSeekbar(percentage, true)
			if (e.stopPropagation) e.stopPropagation();
			if (e.preventDefault) e.preventDefault();
			e.cancelBubble = true;
			e.returnValue = false;
      this.hideSeekbar()
			this.tryUnmute();
			return false;
		},
		mouseMoveSeekbar: function (e) {
			// if (this.cannotClickSeekbar) { return }
			var ptime = parseInt(this.getPercentage(e) * this.duration * 0.01)
			this.setTooltip(this.formattedSeconds(ptime), e)
			if (this.timeDrag) {
				this.updateSeekbar(this.getPercentage(e), true)
				return
			}
		},
		mouseMove: function () {
      // this.sayDev("🌩 MouseMove")
			this.showCursor()
      this.showTooltip = false
      this.adjustingVolume = false;
			this.showSeekbar = true
			this.clearSeekbarTimer();
			this.hideSeekbar("MM");
			this.focusInput()
		},

		hideSeekbarImmediately: function () {
			if (this.speedPopup || this.captionsPopup) {
				return
			}
			this.showSeekbar = false
			this.hideCursor()
		},
		hideSeekbar: function (str) {
			if ( window.location.href.includes("nohideseekbar") || window.location.href.includes("doot") || this.message.length > 1 || this.speedPopup || this.captionsPopup || (this.mouseInSeekbar && !this.isTouchDevice)) {
        clearInterval(this.hoverTimer)
				return
			}
			clearInterval(this.hoverTimer)
			var x = 1500
			if (this.currentUser || !this.isPresentation) {
				x = 2500
			}
			if ((this.isMobile && !this.isEmbed) || this.showGuide || this.isTouchDevice) {
				x = x * 2
			}
			this.hoverTimer = setTimeout(() => {
				if (!this.variables.nofade && !this.chaptersDialog && !gon.nofade && !this.message && !this.speedPopup && !this.captionsPopup && (!this.mouseInSeekbar || this.isTouchDevice)) {
          // this.sayDev("HideSeekbar: " + str)
          this.showTooltip = false
          this.showSeekbar = false
          this.hideCursor()
				} else {
					this.hideCursor()
				}
			}, x)
		},
		clearSeekbarTimer: function () {
			// $('#seekWrap').css('opacity', 1.0)
			clearInterval(this.hoverTimer)
			this.hoverTimer = false
		},
		getIcon: function (actionType) {
			if (actionType == "reply") {

			} else if (actionType == "link") {
				return "open_in_new"
			} else if (actionType == "phone") {
				return "phone"
			} else if (actionType == "email") {
				return "email"
			} else if (actionType == "sms") {
				return "sms"
			} else if (actionType == "jump") {
				return "timelapse"
			} else {

			}
			return ""
		},


		doAdd: function () {
			if (!this.participate && !this.ownsPost) {
				this.$toasted.show("The video owner has disabled participation.")
				return;
			}
			this.addingDialog = true
			this.pausePlayer()
			this.authDialog = this.shouldAuth
			if (!this.authDialog) {
				this.payDialog = this.shouldPay
				this.addingDialog = true;
			}
		},
		say: function (str) {
			console.log(str)
		},
		updateInteractionTime(id, time) {
			this.annotations.forEach((x)=> {
				if (x.id == id) {
					x.time = time
					this.sayDev("Updated time")
					var data = new FormData
					data.append("annotation[id]", this.x)
					data.append("annotation[time]", time)
					this.updateAnnotation(id, data)
				}
			})
			this.orderedAnnotations.forEach((x)=> {
				if (x.id == id) {
					x.time = time
				}
			});
		},
		markerDown(m, e) {
			if (!this.ownsPost) {
				this.timeDrag = false
				this.annotationClick(m.annotation)
				return
			}
			
			// e.preventDefault()
		},
		markerRelease(m,e) {
			this.sayDev("✨ Marker Release at " + this.currentTime, e)
			this.annotationClick(m.annotation)
			// e.preventDefault()

		},
		saveCustomVIC(selected, name){
			if(this.share.token == 'uQHkJdMEZwJO'){
        this.sayDev('Crisp Status')
        return
      }
      this.sayDev("Save Custom Vic", name)
			this.currentShare.captureCustomFields = selected
			var req = this.currentShare.requiredCaptureFields
			if (this.currentShare.captureCustomFields.map(x=> x.name).includes(name)){
				req ? req.push(name): req = [name]
			}
			for(var i = 0; i < req.length; i++){
				if (req && !this.currentShare.captureCustomFields.map(x=>x.name).includes(req[i])){
					req.splice(i,1)
				}
			}
			this.currentShare.requiredCaptureFields = [...new Set(req)]
		},
		resizeLayer: function (args) {
			// this.sayDev("\n🔴🔴🔴 ResizeLayer from " + args)
			var video = document.getElementById('videoWrap')
			if (!video) {
				return
			}
			var h = video.offsetHeight
			var w = video.offsetWidth
      // this.windowInner = window.innerHeight
      this.windowInner = document.documentElement.clientHeight
			this.videoWidth = this.isIOS ? w : Math.min(w, window.innerWidth)
			this.videoHeight = this.isIOS ? h : Math.min(h, document.documentElement.clientHeight)
			this.isMini = this.videoWidth < 440
      this.width = window.innerWidth
      this.screenWidth = window.innerWidth
			this.screenHeight = this.windowInner
      this.isMobile = window.innerWidth < 540 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      this.isMini = this.videoWidth < 440
			
			// this.sayDev("WxH: " + this.screenWidth + " x " + this.screenHeight)
			if ((this.isLandscapeIOS && !this.isEmbed) || this.isIOS) {
				document.querySelector('.vjs-tech').style.maxHeight = '100% !important'
				document.querySelector('#toggleLayer').style.maxHeight = '100% !important'
			} else {
				// this.sayDev("Not landscape.... canvas is " + this.canvasHeight)
				document.querySelector('.vjs-tech').style.maxHeight = '100dvh'
				var l = document.querySelector('#toggleLayer')
				if (l) {
					l.style.maxHeight = '100dvh'
				}
			}
			if (this.isDebug) {
				this.debugMessages.push("Offset W x H: " + w + " x " + h)
			}
			this.isXL = this.videoWidth > 1200
			var layer = document.getElementById('video-layer')
			if (video && layer) {
				layer.style.height = h + "px"
				layer.style.width = w + "px"
			}
			if (video && this.canvas) {
				this.canvas.setWidth(this.canvasWidth)
				this.canvas.setHeight(this.canvasHeight)
				this.canvas.calcOffset();
				this.debugMessages.push("Offset W x H: " + this.canvasWidth + " x " + this.canvasHeight)
				document.getElementsByClassName('canvas-container')[0].style.left = this.canvasLeft + 'px'
				document.getElementsByClassName('canvas-container')[0].style.top = this.canvasTop + 'px'
			}
      this.doCataStyles()
			this.debugMessages.push(" - - - ")

      this.setSwitchCanvasStyle()
      // Why were we doing this?
			// this.setCanvasBackgroundImage()
		},
		copyEmbedLink(val) {
			/* Get the text field */
			var autoplay = ""
			if (this.share.autoplay) {
				autoplay = "?autoplay=1"
				if (this.share.minimal) {
					autoplay += "&controls=0"
				}
			} else {
				if (this.share.minimal) {
					autoplay = "?controls=0"
				}
			}
			if (this.share.fullscreen) {
				autoplay += autoplay.includes("?") ? "&fullscreen=1" : "?fullscreen=1"
			}
      if (this.currentShare.loop) {
				autoplay += autoplay.includes("?") ? "&loop=1" : "?loop=1"
			}
      if (this.share.doStartTime && this.currentShare.start_time) {
				autoplay += autoplay.includes("?") ? "&t=" : "?t="
        autoplay += this.currentShare.start_time
			}
			var code
			var src = this.currentUserEmbedDomain + "/e/" + this.share.token + autoplay
      var redirectListener = "<script>window.addEventListener('message', (event) => {if (event.data && event.data.event == 'redirect') {window.location.href = event.data.info.data.link}});</script>"
			if (val == 1){
				code = this.getLandscapeEmbed()
			} else if (val == 2){
        this.sayDev("is portrait")
				code = this.getPortraitEmbed()
			} else if (val == 3){
				code = this.getDynamicEmbed()
			} else if(val == 4) {
				code = this.getOEmbedCode()
			} else if(val == 5) {
				code = this.getIframeEmbedCode()
			}  else {
				if (this.shareDesign.embed){
					code = this.shareDesign.embed
				} else {
					code = "<div style='position: relative; overflow: hidden; padding-top: 56.25%;'><iframe id='ms_frame' loading='lazy' style='position: absolute;top: 0;left: 0; width: 100%; height: 100%; min-height:unset; min-width: unset; border: 0; max-height: 100vh;' src='" + src + "' allowFullscreen allow='encrypted-media; microphone; camera; geolocation' scrolling='no' referrerpolicy='no-referrer-when-downgrade'></iframe></div>"
				}
			}
			code = code.replace('{{URL}}', `${src}`)
      code+=redirectListener
      console.log("Code: " + code)
			this.$copyText(code).then(() => {
        var str = 'Landscape'
        if (val == 2) { str = 'Portrait'}
        else if (val == 3) { str = 'Dynamic'}
        else if (val == 4) { str = 'Custom'}
				this.$toasted.show("Copied " + str + " Embed Code to Clipboard 👍")
				this.isSharing = false
        this.copyEmbedDialog = false;
				this.tryTrack("Copied Inline Embed Code", { url: window.location.href })
			})
		},
		editEmbedLink: function(){
			this.shareDialog = false
			this.designTab = 0
			this.designDialog = true
		},
		copyPopoutLink: function () {
			var autoplay = ""
			if (this.share.autoplay) {
				autoplay = "?autoplay=1"
				if (this.share.minimal) {
					autoplay += "&controls=0"
				}
			} else {
				if (this.share.minimal) {
					autoplay = "?controls=0"
				}
			}
			if (this.share.fullscreen) {
				autoplay += autoplay.includes("?") ? "&fullscreen=1" : "?fullscreen=1"
			}
			var image = this.safeStyles.branding.thumbnail || this.share.image || 'https://resource-cdn.mindstamp.com/assets/images/thumb_play_blue.jpg'
			var code = '<link href="https://resource-cdn.mindstamp.com/lity.min.css" type="text/css" rel="stylesheet" /><script src="//code.jquery.com/jquery-latest.min.js"></script><script src="https://resource-cdn.mindstamp.com/lity.min.js" type="text/javascript" charset="utf-8"></script><a href="https://embed.mindstamp.com/e/' + this.share.token + autoplay + '" data-lity><img src="' + image + '" style="max-width: 90%; margin: 0 auto;max-height: 100%;height: auto;width: auto;display: block;position: absolute;top: 0;bottom: 0;left:0;right:0;box-sizing:border-box;margin:auto;"/></a>'

			this.$copyText(code).then(() => {
				this.$toasted.show("Copied Popout Code to Clipboard 👍")
				this.isSharing = false
				this.tryTrack("Copied Popout Embed Code", { url: window.location.href })
			})
			// setTimeout(() => {
			//   copyButton.innerHTML = "Copy Link"
			// }, 1500);
		},
		startDup(s) {
      this.sayDev("Main StartDup")
      this.sayDev(s)
			this.dupShare = s
			this.dupTitle= 'Copy of ' + s.title
			this.dupDialog = true
			this.group_id = s.group_id
		},
		duplicateVideo() {
			var data = new FormData
			data.append("share[id]", this.dupShare.id)
			data.append("share[title]", this.dupTitle)
			data.append("share[message]", this.dupShare.message)
			if (this.group_id) {
				data.append("share[group_id]", this.group_id)
			}
			if (this.dupNotes) {
				data.append("share[copyNotes]", this.dupNotes)
			}
			Rails.ajax({
				url: '/shares/duplicate',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.group_id = null
					this.videoSearch = ''
					this.$toasted.show('Created Duplicate 🎉')
					this.dupDialog = false

					window.location.href = data.link

					window.analytics.track('Duplicated Video', { includeNotes: this.dupNotes, result: data })
				},
				error: (data) => {
					this.$toasted.show(data)
				}
			});
		},
		getLSFeatureDate(){
			if (!this.lsTest()) {
				return
			}
			if (localStorage.getItem('MS_Feature')){
				return localStorage.getItem('MS_Feature')
			}
			return 0
		},
		setLSFeatureDate(){
			if (!this.lsTest()) {
				return
			}
			localStorage.setItem('MS_Feature', Date.now() / 1000)
			var x = document.querySelector('#newFeatures')
			if (x) {
				x.remove()
			}
		},
		createFeature(){
			var data = new FormData
			data.append("feature[title]", this.featureTitle)
			data.append("feature[description]", this.featureDescription)
			data.append("feature[link]", this.featureLink)
			data.append("feature[image]", this.featureImage)
			Rails.ajax({
				url: '/features',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show('Created New Feature 🎉')
					this.resetFeature()
					window.location.reload()
				},
				error: (data) => {
					this.$toasted.show("😵 Could Not Create Feature 😵")
				}
			});
		},
		
		copyVideoLink: function (token) {
			this.$copyText(this.shareUrl + '/w/' + token).then(() => {
				this.$toasted.show("Copied Share Link to Clipboard 👍")
				this.tryTrack("Copied Share Link", { url: window.location.href, video: token })
				this.shareDialog = false
        this.promptSharing();
			})
		},
		copyShareLink: function (track, exit) {
			this.$copyText(this.finalShareLink).then(() => {
				this.$toasted.show("Copied Share Link to Clipboard 👍")
        this.promptSharing();
				if (track) {
					this.tryTrack("Copied Share Link", { url: window.location.href })
				}
				if (exit) {
					this.isSharing = false
				}
			})
		},
    copyUnbrandedLink: function (share) {
			this.$copyText(this.unbrandedShareLink).then(() => {
				this.$toasted.show("Copied Unbranded Link to Clipboard 👍")
        this.tryTrack("Copied Unbranded Link", { url: window.location.href })
			})
		},

		deleteShare(item) {
			Rails.ajax({
				url: '/shares/' + item.id,
				type: "DELETE",
				success: (data) => {
					this.$toasted.show('Deleted Video 👋🏼')
					this.videos.splice(this.videos.indexOf(item), 1)
					this.tryTrack('Deleted Video', { video: item })
				}
			});
		},
		saveTranscript() {
			var data = new FormData
			data.append("transcript", JSON.stringify(this.transcript))
			data.append("video", JSON.stringify(this.share))
			Rails.ajax({
				url: '/transcripts/' + this.transcript.id,
				type: "POST",
				data: data,
				success: (data) => {
					this.$toasted.show('Saved Transcript 👋🏼 Reloading...')
          setTimeout(()=> {
            var str = ''
            if (!window.location.href.includes("?")) {
              str = "?vtab=transcript"
            }
            window.location.href=window.location.href+str
          }, 5000)
					// this.editTranscriptDialog = false
				}
			});
		},
		playAudioStampLinks: function (links) {
			this.sayDev("Play Audio Stamp Links: " + links.aws)
			this.audioDialog = true
			this.introDialog = false
			this.buttonAnnotation = null
			this.pausePlayer();
			setTimeout(() => {
				var that = this
				if (!this.surfer) {
					this.surfer = new Audio();
				}
				// this.surfer = WaveSurfer.create({
				// 	container: '#waveform',
				// 	waveColor: '#39375b',
				// 	height: this.isMobile ? 35 : 75,
				// 	progressColor: '#D3C598',
				// 	barHeight: this.isMobile ? 0.5 : 2,
				// });
				

				// this.surfer.on('ready', () => {
				// 	this.sayDev("Surfer Ready 🏄‍♂️")
				// 	this.surfer.play()
				// });

				this.surfer.src = links.aws
				this.surfer.addEventListener('play', () => {
					this.touchPlayDialog = false
					this.audioNeedsTouchPlay = false
				})
				this.surfer.addEventListener('ended', () => {
					this.sayDev("Audio Ended")
					if (window.location.href.includes('noresumemedia')) { console.log("No resume media..."); return }
					// if (this.isDebug || !this.isProduction) { return }
					that.closeAudioDialog();
				});
				this.surfer.addEventListener('error', (e) => {
					that.closeAudioDialog();
				});
				this.playAudioStampPlayer()
				// this.surfer.load(links.aws);
			}, 100)
		},
    hasActiveMustClicks() {
      var found = false
      this.mustClickDivs.forEach((d)=> {
        if (d.exact_finish == this.deciTime && !d.clicked && !d.pause && !d.hidden) {
          found = true
        }
      })
      return found
    },
		pauseAudioStamp: function () {
			this.surfer.pause();
		},

		getStreamingLinks: function (type, id, func) {
			if (type == 'video') {
				var path = '/videos/stream/' + id
			} else if (type == 'audio'){
				var path = '/audios/stream/' + id
			} else if (type == 'file'){
				var path = '/documents/stream/' + id
			}
			if(!path){
				return ''
			}

			Rails.ajax({
				url: path,
				type: "GET",
				success: (data) => {
					func(data)
				}
			});
		},

		showDialogMessage: function (str) {
			this.showMessageDialog = true
			this.messageToShow = str
		},

		showPollResults: function (parent, answer) {
			// console.log('📊 Showing Poll Results');
			answer = answer.text ? answer.text : answer;
			// this.pausePlayer();
			this.showPollDialog = true
			setTimeout(()=> {
				Chart.defaults.color = "white";
				Chart.defaults.borderColor = "white";
				var ctx = document.getElementById('pollChart_' + parent.id).getContext("2d");
				this.getResponses(ctx, parent, answer);
			} , 350) 
		},
		playVideoStampLinks: function (links) {
			this.sayDev("🚀 PVSL")
      this.sayDev(links)
			this.pausePlayer()
			this.loadingMedia = true
			this.introDialog = false
			this.videoDialog = true
			this.buttonAnnotation = null
			var videoSource = document.createElement("SOURCE")
			this.setupDialogVideo()
			
			setTimeout(() => {
				if (links.mux) {
					videoSource = {
						"type": 'application/x-mpegURL',
						"src": links.mux,
					}
				} else if (links.aws) {
					var ty = links.aws.includes('.mp4') || links.aws.includes('/original') ? 'video/mp4' : 'video/webm'
					videoSource = {
						"type": ty,
						"src": links.aws,
					}
				} else {
					if (this.currentUser) {
						this.$toasted.show("Could not play video clip")
					}
					if (!this.isProduction) {
						alert("Can't play clip")
					}
					this.closeVideoDialog();
					return;
				}

				this.pausePlayer();
				this.videoDialog = true
				if (!this.isIOS && this.dialogVideo) {
					this.dialogVideo.reset()
				} else {
					this.setupDialogVideo()
				}
				// this.sayDev("Dialog Video Source: ", videoSource)
				this.dialogVideo.src(videoSource);
				this.dialogVideo.load();
        this.hideSafariCaptions()
				this.playVideoStampPlayer()
				setTimeout(()=> {
					this.updateVideoClips()
				}, 200)
			}, 200);
		},

		playVideoClipFromId: function (id) {
			this.hideSeekbarImmediately()
      if (this.earlySwitches[id]) {
        this.sayDev("Preloaded, just use url")
        this.playVideoStampLinks(this.earlySwitches[id])
        return
      }
			this.getStreamingLinks('video', id, (data) => {
				if (!this.isProduction) {
				}
				this.playVideoStampLinks(data)
			})
		},
		playAudioClipFromId: function (id) {
			this.hideSeekbarImmediately()
      if (id.includes('http')) {
        this.playAudioStampLinks({
          aws: id
        })
      } else if (this.earlySwitches[id]) {
        this.sayDev("Preloaded, just use url")
        this.playAudioStampLinks(this.earlySwitches[id])
        return
      } else {
        this.getStreamingLinks('audio', id, (data) => {
          this.playAudioStampLinks(data)
        })
      }
		},

		playVideoStamp: function (videoAnnotation) {
			if (this.busy) {
				return
			}
			this.sayDev("⭐️ PlayVideoStamp!", videoAnnotation.time == this.currentTime)
			this.videoAnnotation = videoAnnotation
			if (this.nextMedias.video && this.nextMedias.video.id == videoAnnotation.id) {
				this.sayDev("AL ✅")
				this.pausePlayer()
				this.videoDialog = true
				this.playVideoStampPlayer()
				return
			}
			this.playVideoClipFromId(videoAnnotation.content)
		},
		playAudioStamp: function (audioAnnotation) {
			this.playAudioClipFromId(audioAnnotation.content)
		},
		playAudioStampPlayer: function () {
			this.sayDev("PlayAudioStampPlayer")
			this.touchPlayDialog = false
			this.audioNeedsTouchPlay = false;
			this.surfer.play().then(()=> {
				// this.sayDev("🥏 Able to play")
			}).catch((e)=> {
				this.sayDev("Can't Play Audio Stamp player!", e)
        this.resetVolume()
				if (this.audioDialog) {
					this.audioNeedsTouchPlay = true
					this.touchPlayDialog = true
					}
			})
			
		},
		playVideoStampPlayer: function () {
			this.sayDev("😈 PlayVideoStampPlayer")
			this.touchPlayDialog = false
			this.updateVideoClips()
			this.dialogVideo.play().then(()=> {
				this.sayDev("🥏 Able to play")
				this.updateVideoClips()
			}).catch((e)=> {
				this.sayDev("Can't Play Video Stamp player!", e)
        this.resetVolume()
				if (this.videoDialog) {
					this.videoNeedsTouchPlay = true
          if (this.isIOS) {
            this.touchPlayDialog = true
          } else {
            this.playVideoStampPlayer()
          }
				}
			})
		},
		startDataEdit() {
			if (!this.ownsPost && !this.isTour) {
				return false
			}
			this.stopEditing()
			this.pausePlayer();
			this.notesDialog = false
			this.stopSharing()
			this.currentItem = Object.assign({}, this.post);
      this.setCurrentShare()
			this.isEditing = false
			this.dataEditing = true
		},
		startEdit: function () {
			this.dataEditing = false
			if (!this.ownsPost && !this.isTour) {
				return false
			}
			this.pausePlayer()
			this.notesDialog = false
			this.collectPrompt = false
			this.stopSharing()
			this.showCTA = false
			this.currentItem = Object.assign({}, this.post);
      this.setCurrentShare()
			this.getStripeFeeStatement(this.currentShare.capture_payment_amount, this.currentShare.capture_payment_currency)
			this.endButton = this.share ? JSON.parse(this.share.cta) : {}
			if (this.endButton.value && this.endButton.action == 'download'){
				this.assetDownload[0] = JSON.parse(this.endButton.value)
				this.endButton.value = this.assetDownload[0].url
			}
			if (true || this.isDebug) {
				this.isEditing = true
				this.showIntroDialog()
				setTimeout(() => {
					var container = document.getElementById('editContainer')
					if (container) {
						container.classList.add('noMax')
					}
					this.resizeLayer("StartEdit")
					setTimeout(() => {
						this.loadTpicker++
					},1000) 
				}, 250);
			} else {
				this.shareDialog = true
			}
		},
		stopEditing: function () {
			setTimeout(() => {
				var container = document.getElementById('editContainer')
				if (container) {
					container.classList.remove('noMax')
				}
				var x = document.getElementById('post_info_card')
				if (x) {
					x.style.height = '0px'
					setTimeout(() => {
						x.style.height = '100%'
					}, 50);
				}
				this.drawSeekBounding("stopEditing")

			}, 250);
			this.editingItem = false
      this.setCurrentShare()

			this.currentItem = null
			this.isEditing = false
			this.dataEditing = false
			this.editTitle = false
			this.resourceTray = Object.assign({}, this.dupResoureTray);
			this.listIndex = 1
			this.clearScreenInteractions()
			this.payDialog = false
			this.showIntroDialog()
		},
		stopSharing: function () {
			this.editingPeople = false
			this.isSharing = false
			this.publishDialog = false
		},
		addPeople: function () {
			var p = this.viewerShareInfo.email
			if (!this.isValidEmail(p)) {
				return
			}
			if (this.share.people.indexOf(p) > -1) {
				this.$toasted.show("Already shared with " + p)
				return
			}
			this.share.people.push(p)
			this.updatePeople()
		},
		removePeople: function (p) {
			Rails.ajax({
				url: '/shares/' + this.share.id + '/remove_people?email='+p,
				type: "POST",
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Access Revoked for " + p)
          this.share.people = data.people
					this.tryTrack("Updated Invited Viewers", { url: window.location.href, viewers: ppl })
          this.viewerShareInfo.email = '';
				}
			});
		},
		updatePeople: function (ppl) {
			if (!this.ownsPost) {
				this.$toasted.show('Not allowed')
				return
			}
			var data = new FormData
			data.append("info", JSON.stringify(this.viewerShareInfo))
			Rails.ajax({
				url: '/shares/' + this.share.id + '/update_people',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.shareEmail = ''
					this.$toasted.show("Invite Sent 👍")
          this.share.people = data.people
					this.tryTrack("Updated Invited Viewers", { url: window.location.href, viewers: ppl })
          this.viewerShareInfo.email = '';
				}
			});
		},
		enterForAccess: function (type) {
			var arg = ''
			if (type == 1){
				this.submittingPassword = true
				arg = 'mspwt=' + encodeURIComponent(this.password)
			} else if (type == 2){
				this.submittingEmail = true
				arg = 'email=' + encodeURIComponent(this.guestEmail)
			} else if(type == 3){
				this.submittingPasscode = true
				arg = 'email=' + encodeURIComponent(this.guestEmail)
				arg += '&mspwa=' + encodeURIComponent(this.passcode)
			}
			Rails.ajax({
				url: '/request_access/?token=' + this.shareToken + "&" + arg,
				type: "POST",
				success: (data) => {
					if (data.success) {
						var url = window.location.href
						url = arg.includes("email=") ? url.replace("?email="+this.guestEmail,'') : url
						url += (url.includes("?") ? "&" : "?") + arg
						window.location.href = url
					} else {
						setTimeout(() => {
							if (type == 1){
								this.submittingPassword = false
								this.$toasted.show("Wrong password")
								$('#password_input').focus()
							} else if (type == 2){
								this.submittingEmail = false
								this.$toasted.show("Invalid email address")
								$('#password_input').focus()
							} else if(type == 3){
								this.submittingPasscode = false
								this.$toasted.show("Invalid email address or passcode")
								$('#password_input').focus()
							}
						}, 2000);
					}
				},
				error: (data) => {
					this.$toasted.show("Something went wrong")
				}
			});
		},
		togglePublished: function () {
			this.pausePlayer()
			this.share.published = !this.share.published
			this.updateShare(false)
		},
		makePublic: function () {
			this.setCurrentShare()
			this.currentShare.private = 'public'
			this.updateShare(false)
			this.tryTrack("Made Video Public", { url: window.location.href })
		},
		makePrivate: function () {
			this.setCurrentShare()
			this.currentShare.private = 'private'
			this.updateShare(false)
			this.tryTrack("Made Video Private", { url: window.location.href })
		},
		saveEndCta: function () {
			if (!this.ownsPost) {
				this.$toasted.show("Not allowed")
				this.setCurrentShare()
				this.stopEditing();
				return
			}
			if (!this.currentShare) {
				this.setCurrentShare()
			}

			var shareData = new FormData

			if (this.endButton && this.endButton.action == 'download'){
				if (!this.proUpUser){
          this.$toasted.show("Uploading files are available for users on the Pro and Enterprise plans.");
					return
				} else if (this.assetDownload[0].downloadName == ''){
					this.$toasted.show("Dowload name required for End Call-To-Action to save")
					return
				} else {
					this.endButton.value = JSON.stringify(this.assetDownload[0])
				}
			}
			let cta = this.endButton ? JSON.stringify(this.endButton) : this.share.cta
			shareData.append("share[cta]", cta)
			Rails.ajax({
				url: '/shares/' + this.currentShare.id + '/cta',
				type: "POST",
				data: shareData,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show("Saved 🎉")
					this.assetDownload = Array(5).fill({
						url: '',
						downloadName: '',
						id: '',
						type: '',
					})
					this.share.cta = cta
					this.editingCta = false
				}, error: (e) => {
					console.log(e)
					this.$toasted.show("Unable to save - please contact support if this continues. ")
				}
			});
		},
    turnOffEmbedOnly() {
      this.currentShare.embed_only = false
      this.updateShare()
    },
		// uuuuu
		updateShare: function (stopEdit) {
      this.doingSave = true
			if (!this.ownsPost) {
        return
				this.setCurrentShare()
				this.stopEditing();
				return
			}
			if (!this.currentShare) {
				this.setCurrentShare()
			}
			// this.currentShare.image = 'OMG OMG OMG'
			var shareData = new FormData
			var privacyNumber = 0
			if (this.currentShare.private == 'private') {
				privacyNumber = 1
			} else if (this.currentShare.private == 'password') {
				privacyNumber = 2
				if (this.newVideoPassword && !this.currentShare.password.includes(this.newVideoPassword)){
					this.currentShare.password.push(this.newVideoPassword)
				}
				this.newVideoPassword = ''
				if (!this.currentShare.password || this.currentShare.password == '') {
					this.$toasted.show("Video password required to save")
					return
				}
			} else if (this.currentShare.private == 'access'){
				privacyNumber = 3
				if (!this.currentShare.access_list_id || this.currentShare.access_list_id == '') {
					this.$toasted.show("Access List required to save")
					return
				}
			}
			var vnumber = 0
			if (this.currentShare.private == 'public') {
				vnumber = 0
			} else if (this.currentShare.private == 'private') {
				vnumber = 1
			} else if (this.currentShare.private == 'password') {
				vnumber = 2
			} else if (this.currentShare.private == 'access') {
				vnumber = 3
			}

      console.log("Published: " + this.currentShare.published, this.currentShare)

			if (!this.editTitle && this.endButton && this.endButton.action == 'download'){
				if (!this.proUpUser){
          this.$toasted.show("Uploading files are available for users on the Pro and Enterprise plans.");
					return
				} else if (this.assetDownload[0].downloadName == ''){
					this.$toasted.show("Dowload name required for End Call-To-Action to save")
					return
				} else {
					this.endButton.value = JSON.stringify(this.assetDownload[0])
				}
			}
      if (this.currentShare.send_leads && this.newLeadsEmail && !this.isValidEmail(this.newLeadsEmail)) {
        this.$toasted.show("Invalid Lead Email Address!")
        this.flashFocusField('lead_email_address')
				return
      } 

			if(this.currentShare.capture_payment_point != -1 && this.currentShare.capture_payment_amount - this.monetizationFee < 0){
				this.$toasted.show("Final payment amount must be greater than 0 " + this.currentShare.capture_payment_currency)
				this.flashFocusField('capture_payment_amount')
				return
			}

      if (this.currentShare.send_leads) {
				if (this.newLeadsEmail && !this.currentShare.leads_address.includes(this.newLeadsEmail)){
					this.currentShare.leads_address.push(this.newLeadsEmail)
				}
				this.newLeadsEmail = ''
        shareData.append("share[send_leads]", true)
        shareData.append("share[leads_address]", this.currentShare.leads_address.join(';'))
      } else {
        shareData.append("share[send_leads]", false)
      }
			
			if (this.currentShare.captureCustomFields && this.currentShare.captureCustomFields){
				this.currentShare.captureName = false
				this.currentShare.captureEmail = false
				this.currentShare.capturePhone = false
				this.currentShare.captureID = false
				for(var i = 0; i < this.currentShare.captureCustomFields.length; i++){
					var x = this.currentShare.captureCustomFields[i]
					if (x.name == 'name'){
						this.currentShare.captureName = true
						x.label = this.shareDesign.name_string || 'Name'
            
					}
					if (x.name == 'email'){
						this.currentShare.captureEmail = true
						x.label = this.shareDesign.email_string || 'Email Address'
					}
					if (x.name == 'phone'){
						this.currentShare.capturePhone = true
						x.label = this.shareDesign.phone_string || 'Phone Number'
					}
					if (x.name == 'custom_id'){
						this.currentShare.captureID = true
						x.label = this.shareDesign.custom_id_string || 'Your ID'
					}
					this.currentShare.captureCustomFields[i].label = x.label
				}
			}


			shareData.append("share[loop]", this.currentShare.loop)
			shareData.append("share[start_time]", this.currentShare.start_time)
			shareData.append("share[autoplay]", this.currentShare.autoplay)
			shareData.append("share[fullscreen]", this.currentShare.fullscreen)
			shareData.append("share[is_resumable]", this.currentShare.is_resumable)
			shareData.append("share[embed_only]", this.currentShare.embed_only)
			shareData.append("share[pause_inactive]", this.currentShare.pause_inactive)
			shareData.append("share[can_skip_clips]", this.currentShare.can_skip_clips)
			shareData.append("share[use_chapter_timing]", this.currentShare.use_chapter_timing)
			shareData.append("share[resume_focus]", this.currentShare.resume_focus)
			shareData.append("share[tab_links]", this.currentShare.tab_links)
			shareData.append("share[organization_edit]", this.currentShare.organization_access && this.currentShare.organization_edit)
			shareData.append("share[organization_access]", this.currentShare.organization_access)
			shareData.append("share[type_of]", this.currentShare.private)
			shareData.append("share[privacy]", vnumber)
			shareData.append("share[access_list_id]", this.currentShare.access_list_id)
			shareData.append("share[title]", this.currentShare.title || "")
			shareData.append("share[message]", this.currentShare.message || "")
			shareData.append("share[participate]", this.currentShare.participate)
			shareData.append("share[observe]", this.currentShare.observe)
			shareData.append("share[can_share]", this.currentShare.share)
			shareData.append("share[show_branding]", this.currentShare.showBranding)
			shareData.append("share[people]", this.currentShare.people.toString().split(","))
			shareData.append("share[align]", this.currentShare.align)
			shareData.append("share[showTitle]", this.currentShare.showTitle)
			shareData.append("share[showMessage]", this.currentShare.showMessage)
			shareData.append("share[captureEmail]", this.currentShare.captureEmail)
			shareData.append("share[capturePhone]", this.currentShare.capturePhone)
			shareData.append("share[captureID]", this.currentShare.captureID)
			shareData.append("share[captureName]", this.currentShare.captureName)
			shareData.append("share[captureCustomFields]", JSON.stringify(this.currentShare.captureCustomFields) || "[]")
			shareData.append("share[captureRequired]", this.currentShare.captureRequired)
			shareData.append("share[requiredCaptureFields]", this.currentShare.requiredCaptureFields && this.currentShare.requiredCaptureFields.length > 0 ? JSON.stringify(this.currentShare.requiredCaptureFields) : '')
			shareData.append("share[captureAuthStart]", this.currentShare.captureAuthStart && this.currentShare.capture_auth_point == 0)
			shareData.append("share[navControls]", this.currentShare.navControls)
			shareData.append("share[showMarkers]", this.currentShare.showMarkers)
			shareData.append("share[group_id]", this.currentShare.group_id || '')
			shareData.append("share[password]", JSON.stringify(this.currentShare.password) || '')
			shareData.append("share[published]", this.share.published || false)
			shareData.append("share[seekbar]", this.currentShare.seekbar)
			shareData.append("share[interactions_guide]", this.currentShare.interactions_guide)
			shareData.append("share[chapters_guide]", this.currentShare.chapters_guide)
			shareData.append("share[transcript_guide]", this.currentShare.transcript_guide)
			shareData.append("share[captureText]", this.currentShare.captureText)
			shareData.append("share[gtag]", (this.currentShare.gtag || "").trim())
			shareData.append("share[facebook_pixel_id]", (this.currentShare.facebook_pixel_id || "").trim())
			shareData.append("share[send_webhook]", this.currentShare.send_webhook)
			shareData.append("share[webhook_url]", (this.currentShare.webhook_url || "").trim())
			shareData.append("share[send_interaction_webhook]", this.currentShare.send_interaction_webhook)
			shareData.append("share[interaction_webhook_url]", (this.currentShare.interaction_webhook_url || "").trim())
			shareData.append("share[send_lead_webhook]", this.currentShare.send_lead_webhook)
			shareData.append("share[lead_webhook_url]", (this.currentShare.lead_webhook_url || "").trim())
			shareData.append("share[send_hubspot_views]", (this.currentShare.send_hubspot_views))
			shareData.append("share[send_hubspot_questions]", (this.currentShare.send_hubspot_questions))
			shareData.append("share[send_hubspot_interactions]", (this.currentShare.send_hubspot_interactions))
			shareData.append("share[send_to_hs_list]", (this.currentShare.send_to_hs_list))
			shareData.append("share[send_to_cc_list]", (this.currentShare.send_to_cc_list))
			shareData.append("share[gpt_juice]", (this.currentShare.gpt_juice ))
			if (this.currentShare.send_to_cc_list) {
				shareData.append("share[lists_add]", JSON.stringify(this.constantContactLists.map(l => this.currentShare.lists_add.includes(l.list_id) ? l.list_id : null).filter(l => l)))
				shareData.append("share[lists_remove]", JSON.stringify(this.constantContactLists.map(l => this.currentShare.lists_remove.includes(l.list_id) ? l.list_id : null).filter(l => l)))
			}else if (this.currentShare.send_to_hs_list) {
				shareData.append("share[lists_add]", JSON.stringify(this.hubspotLists.map(l => this.currentShare.lists_add.includes(l.list_id) ? l.list_id : null).filter(l => l)))
				shareData.append("share[lists_remove]", JSON.stringify(this.hubspotLists.map(l => this.currentShare.lists_remove.includes(l.list_id) ? l.list_id : null).filter(l => l)))
			}
			shareData.append("share[tags]", JSON.stringify(this.currentShare.tags) || '[]')
			shareData.append("share[capture_auth_point]", this.currentShare.capture_auth_point)
			shareData.append("share[capture_auth_value]", this.currentShare.capture_auth_value)
			shareData.append("share[capture_payment_point]", this.currentShare.capture_payment_point)
			shareData.append("share[capture_payment_value]", this.currentShare.capture_payment_value)
			shareData.append("share[stripe_contact_email]", this.currentShare.stripe_contact_email)
			shareData.append("share[capture_payment_amount]", this.currentShare.capture_payment_amount)
			shareData.append("share[capture_payment_currency]", this.currentShare.capture_payment_currency)
			shareData.append("share[stripe_expiration]", this.currentShare.stripe_expiration)
			shareData.append("share[hubspot_interactions]", this.currentShare.hubspot_interactions.toString().split(","))
			shareData.append("share[force_session_reset]", this.currentShare.force_session_reset)
			shareData.append("share[collect_geolocation]", this.currentShare.collect_geolocation || false)
			shareData.append("share[block_pausing]", this.currentShare.block_pausing || false)
      shareData.append("share[tab_title]", this.currentShare.tab_title || "")
      shareData.append("share[skip_amount]", this.currentShare.skip_amount || 5)
      shareData.append("share[shopify_site]", this.currentShare.shopify_site || "")
      shareData.append("share[use_afk]", this.currentShare.use_afk || false)
      shareData.append("share[afk_amount]", this.currentShare.afk_amount || 0)

			if (this.isEditing) {
				shareData.append("share[default_captions]", this.currentShare.default_captions || '')
			} else {
				shareData.append("share[default_captions]", this.share.default_captions || '')
			}

			if (this.currentShare.image != null) {
				shareData.append("share[image]", this.currentShare.image)
			}
			var shareVariables = this.share.variables 
			Rails.ajax({
				url: '/shares/' + this.currentShare.id,
				type: "POST",
				data: shareData,
				dataType: "JSON",
				success: (data) => {
					this.share = data
					// this.$toasted.show("Saved 🎉")
          this.sayDev("✅ Autosaved at " + new Date().toLocaleTimeString())
          // this.currentShare.updated_at = new Date()
					this.outputAlign = this.share.align
					this.navControls = this.share.navControls
					this.captureEmail = this.share.captureEmail
					this.captureName = this.share.captureName
					this.captureCustomFields = this.share.captureCustomFields
					this.isPresentation = this.share.observe
					this.dupTray =  Object.assign({}, this.resourceTray);
					this.listIndex = 1
					this.tryTrack("Updated Video Settings", { url: window.location.href, share: this.share })
					this.dataEditing = false
					this.editTitle = false
          this.setCurrentShare()
					
					this.clearScreenInteractions()
					this.activeTextTrack = this.userTextTrack || this.share.default_captions || this.activeTextTrack || "Off"
					this.updateCaptions()
					this.payDialog = false
					this.showIntroDialog()
					this.assetDownload = Array(5).fill({
						url: '',
						downloadName: '',
						id: '',
						type: '',
					}),
          this.doingSave = false
          clearTimeout(this.saveTimer)
          this.saveTimer = null
					setTimeout(() => {
						if (this.player) {
							if (this.videoPoster) {
								this.player.poster(this.videoPoster)
							} else {
								this.player.posterImage.hide()
							}
						}
					}, 1000)
				}, error: (e) => {
					console.log(e)
					this.$toasted.show("Unable to save - please contact support if this continues. ")
				}
			});
		},
		updateContact: function () {
			var data = new FormData
			data.append("contact[name]", this.contact.name)
			data.append("contact[email]", this.contact.email)

			Rails.ajax({
				url: '/viewers/update/' + this.contact.id,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show('Saved')
					this.isEditing = false
					this.tryTrack("Saved Contact Edit", { url: window.location.href, contact: this.contact })
				}
			});
		},
		closeRecordAudioDialog: function () {
			this.recordAudioDialog = false
			var that = this
			setTimeout(function () {
				if (!that.questionAnnotation) {
					that.hideAllDialogsAndPlay("CAD")
				}
			}, 600)
		},
		closeRecordVideoDialog: function () {
			this.recordVideoDialog = false
			var that = this
			setTimeout(function () {
				if (!that.questionAnnotation) {
					that.hideAllDialogsAndPlay("CVD")
				}
			}, 600)
		},
    parseAnswers(answers){
			this.assetDownload = Array(5).fill({
				url: '',
				downloadName: '',
				id: '',
				type: '',
			}),
			answers = JSON.parse(answers) 
			answers.forEach((a,i) => {
				if (a.action == 'download'){
					var val = JSON.parse(a.value)
					this.assetDownload[i] = val
					a.value = val.url
				}
			})
			return answers
		},
		setEditingQuestion: function (a) {
			var q = a.content
			this.editingTime = a.exact_time
			this.editingQuestion = {
				id: q.id,
				time: this.editingTime,
        exact_time: this.editingTime,
				prompt: q.prompt,
				limit: q.timer ? q.timer.toString() : "0",
        has_time_limit: q.timer && q.timer.toString() != '0',
        has_correct_answers: !!q.correct_answer,
        stores_variable: !!a.variable,
				style: q.style,
				skippable: q.skippable,
				can_rewind: q.can_rewind || false,
				rewind_time: Math.floor(q.rewind_time || Math.max(this.editingTime - 5, 0)),
				show_hint: q.show_hint || false,
				hint_message: q.hint_message || "",
				correct: q.style == 'all' ? (JSON.parse(q.correct_answer) || '') : (q.correct_answer || ''),
				answers: q.answers ? this.parseAnswers(q.answers): null,
				is_conditional: a.is_conditional,
				conditional_show: a.conditional_show,
				conditional_variable: a.conditional_variable,
				conditional_assertion: a.conditional_assertion,
				conditional_value: a.conditional_value,
				is_conditional_2: a.is_conditional_2,
				conditional_variable_2: a.conditional_variable_2,
				conditional_assertion_2: a.conditional_assertion_2,
				conditional_value_2: a.conditional_value_2,
				conditional_operator: a.conditonal_operator,
				is_hubspot: q.hubspot_field != null,
				hubspot_field: q.hubspot_field,
				variable: a.variable,
				speak: a.speak || false,
				speak_answers: a.speak_answers || false,
				force_correct: q.force_correct,
				min_value: q.min_value,
				max_value: q.max_value,
        voice_number: q.voice_number || 0,
				post_message: JSON.parse(q.post_message) || ['',''],
				top: q.top || this.shareDesign.question_top,
				left: q.left || this.shareDesign.question_left,
				height: q.height || this.shareDesign.question_height,
				width: q.width || this.shareDesign.question_width,
				background_color: q.background_color || this.shareDesign.question_background_color,
				correct_color: q.correct_color || this.shareDesign.correct_color,
				incorrect_color: q.incorrect_color || this.shareDesign.incorrect_color,
				prompt_color: q.prompt_color || this.shareDesign.prompt_color,
				answers_color: q.answers_color || this.shareDesign.answers_color,
				transition: q.transition || this.shareDesign.question_transition,
				use_custom_design: q.use_custom_design,
				allow_upload: q.allow_upload || true,
        internal_label: a.internal_label,
        has_internal_label: !!a.internal_label,
			}
			if ((q.style == 'multiple' || q.style == 'poll') && !q.answers) {
				this.editingQuestion.answers = [{
					text: q.aText,
					action: q.aAction,
					value: q.aVal,
					variable: q.variable || '',
					variable_value: q.variable_value || ''
				}]

				if (q.bText) {
					this.editingQuestion.answers.push({
						text: q.bText,
						action: q.bAction,
						value: q.bVal, 
						variable: q.variable || '',
						variable_value: q.variable_value || ''
					})
				}
				if (q.cText) {
					this.editingQuestion.answers.push({
						text: q.cText,
						action: q.cAction,
						value: q.cVal, 
						variable: q.variable || '',
						variable_value: q.variable_value || ''
					})
				}
				if (q.dText) {
					this.editingQuestion.answers.push({
						text: q.dText,
						action: q.dAction,
						value: q.dVal, 
						variable: q.variable || '',
						variable_value: q.variable_value || ''
					})
				}
			}
			this.editingQuestion.answers.forEach((a)=> {
				a.variable = a.variable || ''
				a.variable_value = a.variable_value || ''
				if (a.variable || a.variable_value) {
					this.doIndividualVariables = true
				}
			})
			try{
				var correct = JSON.parse(this.editingQuestion.correct)
				if (this.editingQuestion.style == 'number'){
					this.minCorrect = correct[0]
					this.maxCorrect = correct[1]
				}
				this.correctAnswer = []
				for(val of correct) {
					this.correctAnswer.push({value: val})
				}
			} catch(e) {
				if(this.editingQuestion.style == 'free'){
					this.correctAnswer = [{value: this.editingQuestion.correct}];
				} else {
					this.correctAnswer = this.editingQuestion.correct;
				}
			}
		},
		resetEditingQuestion: function () {
			this.editingTime = this.deciTime
			this.editingQuestion = {
				id: false,
				speak: false,
        speak_answers: false,
				is_conditional: false,
				conditional_show: true,
				conditional_variable: '',
				conditional_assertion: 'equals',
				conditional_value: '',
				id_hubspot: false,
				hubspot_field: "",
				limit: "0",
        has_time_limit: false,
        has_correct_answers: false,
        stores_variable: false,
				style: this.isProduction ? 'free' : 'multiple',
				allow_rewind: false,
				rewind_time: Math.floor(Math.max(this.editingTime - 5, 0)),
				show_hint: false,
				hint_message: "",
				skippable: false,
				force_correct: false,
				prompt: this.isProduction ? '' : 'Random prompt at ' + new Date().toLocaleString(),
				correct: '',
				variable: '',
        voice_number: 0,
				post_message: ['',''],
				answers: [
					{
						text: this.isProduction ? '' : "Answer 1",
						action: 'reply',
						value: '', 
						variable: '',
						variable_value: ''
					},
					{
						text: this.isProduction ? '' : "Answer 2",
						action: 'reply',
						value: '', 
						variable: '',
						variable_value: ''
					},
				],
				top: this.shareDesign.question_top,
				left: this.shareDesign.question_left,
				height: this.shareDesign.question_height,
				width: this.shareDesign.question_width,
				background_color: this.shareDesign.question_background_color,
				correct_color: this.shareDesign.correct_color,
				incorrect_color: this.shareDesign.incorrect_color,
				prompt_color: this.shareDesign.prompt_color,
				answers_color: this.shareDesign.answers_color,
				transition: this.shareDesign.question_transition,
				allow_upload: true,
        internal_label: ''
			}
		},
		addAnswer() {
			this.editingQuestion.answers.push({
				text: '',
				action: 'reply',
				value: '',
				variable: '',
				variable_value: ''
			})

			setTimeout(() => {
				var l = this.editingQuestion.answers.length - 1
				var y = `qtext-${l}-input`
				var x = document.getElementById(y)
				if (x) {
					x.focus()
				}
			}, 200)
		},
		removeAnswer(index) {
			this.editingQuestion.answers.splice(index, 1)
		},
		
		checkForCorrect(){
      if (this.questionCreationDialog) {
        this.handleQuestionUpdate()
      }
			if (this.editingQuestion.style == 'free'){
				for(var c of this.correctAnswer){
					if (c.value != ''){
						return true 
					}
				}
			} else if (this.editingQuestion.style == 'number'){
				if ((this.minCorrect != null && this.minCorrect != '') || (this.maxCorrect != null && this.maxCorrect != '')){
					return true;
				}	
			} else{
				if(this.correctAnswer != '' && this.correctAnswer != null){
					return true
				}
			}
			// this.editingQuestion.force_correct = false;
			return false;
		},
		showQuestionDialog: function () {
			this.resetEditingQuestion()
			this.pausePlayer();
			this.authDialog = this.shouldAuth
			if (!this.authDialog) {
				this.payDialog = this.shouldPay
			}
			this.questionCreationDialog = true
			this.tryTrack("Opened Add Question Screen", { url: window.location.href })
			setTimeout(() => {
				document.getElementById('promptBox').focus()
			}, 250)
		},
		showRecordVideoDialog: function () {
			this.tryTrack("Opened Record Video Dialog", { url: window.location.href })
			this.authDialog = this.shouldAuth
			if (!this.authDialog) {
				this.payDialog = this.shouldPay
			}
			if (!this.authDialog) {
				this.pausePlayer();
				setTimeout(() => {
					this.recordVideoDialog = true
					this.addingDialog = false
				}, 100);
			}
		},
		showRecordAudioDialog: function () {
			this.tryTrack("Opened Record Audio Dialog", { url: window.location.href })
			this.authDialog = this.shouldAuth
			if (!this.authDialog) {
				this.payDialog = this.shouldPay
			}
			if (!this.authDialog && !this.shouldPay) {
				this.pausePlayer();
				setTimeout(() => {
					this.recordAudioDialog = true
					this.addingDialog = false
				}, 100);
			}
		},
		rewind: function (e) {
			if (this.dialogShowing || this.message.length || this.pausedUntilClick) {
				return
			}
			if (e) {
				e.stopPropagation();
			}
			if (!this.ownsPost && this.share.use_chapter_timing && this.currentChapter) {
				this.changeTime(Math.max(this.deciTime - (this.share.skip_amount || 5), this.currentChapter.start))
			} else {
				this.changeTime(Math.max(this.deciTime - (this.share.skip_amount || 5), 0))
			}	this.checkSeekbar(true)
		},
		fastForward: function (e) {
			if (this.dialogShowing || this.message.length || this.pausedUntilClick) {
				return
			}
			if (e) {
				e.stopPropagation();
			}
			if (!this.ownsPost && this.share.use_chapter_timing && this.currentChapter) {
				this.changeTime(Math.min(this.deciTime + (this.share.skip_amount || 5), this.currentChapter.finish))
			} else {
				this.changeTime(Math.min(Number(this.deciTime) + (this.share.skip_amount || 5), this.duration))
			}
			this.checkSeekbar(true)
		},
		skipAudioStamp: function () {
      this.sayDev("Skip Audio Stamp")
      if (!this.share.can_skip_clips) {
        return
      }

			this.closeAudioDialog();
		},
		prepareMedias() {
			if (!this.interactionsByType.videos.length) {
				return
			}
			var trueNextVideo = this.nextMedias.video && Math.abs(this.nextMedias.video.time - this.currentTime) < 5
			if (!this.dialogVideo || this.videoDialog || trueNextVideo || !this.interactionsByType.videos.length) {
				return
			}
			this.sayDev("📸 PrepareMedias at " + this.formattedSeconds(this.currentTime))
			var videos = this.interactionsByType.videos
			var oldVideo = this.nextMedias.video || {}
			var theVideo = this.nextMedias.video || {}
			var found = false
			videos.forEach((v) => {
				if (found) {
					
				} else if (v.time > this.currentTime + 1) {
					if (v.id == this.nextMedias.video.id) {
						found = true
					} else {
						this.sayDev("🎾 New NextVideo at " + v.time + " --> " + v.extra)
						theVideo = v
						found = true
	
					}
				}
			})

			if (theVideo && theVideo.id != oldVideo.id) {
				this.nextMedias.video = theVideo
				var links = theVideo.data
				if (links.mux) {
					var source = {
						"type": 'application/x-mpegURL',
						"src": links.mux,
					}
				} else if (links.aws) {
					var ty = links.aws.includes('.mp4') ? 'video/mp4' : 'video/webm'
					var source = {
						"type": ty,
						"src": links.aws,
					}
				}
				if (!this.isIOS) {
					this.dialogVideo.reset()
				}
				this.dialogVideo.src()
				setTimeout(()=> {
					this.dialogVideo.src({ type: source.type, src: source.src })
				}, 100)
				this.sayDev("⭐️ LV", source.src)
				this.dialogVideo.load()

			}
		},
		tryPause(x) {
      this.sayDev("🥏 TryPause: " + x.type_of)
			var isLPT = x.time == this.lastPauseTime && this.lastPauseTime != 0
			if (isLPT) { return false }
      var t = Number(Number((x.exact_time || x.time)).toFixed(1))
      if (t != this.deciTime) {
        return false
      }
			if (!x.hidden && (x.pause || x.type_of == 'pause' || x.type_of == 'drawing') && !isLPT) {
        var paused = true
				this.divsActive.forEach((d) => {
					if (d.pause && d.id != x.id) {
						paused = true
					}
				})
				if (paused || this.currentTime == 0) {
					this.pausePlayer("TryPause0");
					if (x.timer == -1 && !this.ownsPost && this.divsActive.length > 0) {
            this.setPUC("TryPause 1")
						this.hideSeekbarImmediately()
					}
          if (x.timer == -1) {
            this.setPUC("TryPause 2")
          } else {
            this.startArtTimer(x.timer)
          }
          if (x.exact_time == 0.0) {
            this.player.currentTime(0.01)
            this.played = true
          }
					return true
				} 
			}
			return false
		},
		capitalizeFirst: function (str) {
			if(!str){
				return ""
			}
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
		cancelSpeech(text) {
      // this.sayDev("🙈 CancelSpeech")
			if ('speechSynthesis' in window) {
				window.speechSynthesis.cancel()
			}
		},
		speakMessage(message, PAUSE_MS = 500) {
			this.sayDev("SpeakMessage", message)
			this.cancelSpeech()
			if ('speechSynthesis' in window) {
				try {
					const messageParts = message.split('yyyyy')
				
					let currentIndex = 0
					const speak = (textToSpeak) => {
						const msg = new SpeechSynthesisUtterance();
						const voices = window.speechSynthesis.getVoices();
            if (this.questionAnnotation) {
              // this.sayDev("Use QA")
              console.log(this.questionAnnotation.content)
              msg.voice = voices[this.questionAnnotation.content.voice_number || 0];
            } else if (this.editingQuestion && this.currentUser) {
              // this.sayDev("Editing Q......")
              msg.voice = voices[this.editingQuestion.voice_number || 0];
            } else {
              return
            }
						// msg.volume = 1; // 0 to 1
						// msg.rate = 0.8; // 0.1 to 10
						// msg.pitch = 1; // 0 to 2
						msg.text = textToSpeak;
						msg.lang = 'en-US';
				
						msg.onend = function() {
						currentIndex++;
						if (currentIndex < messageParts.length) {
							setTimeout(() => {
                // this.sayDev("Speaking")
								speak(messageParts[currentIndex])
								this.activeSpeech = messageParts[currentIndex]
							}, PAUSE_MS)
						}
						};
						speechSynthesis.speak(msg);
					}
          // this.sayDev("Speaking...")
					speak(messageParts[0])
				} catch (e) {
          // this.sayDev("Error...")
				console.error(e)
				}
			} else {
        this.sayDev("No speech")
      }
		},
    // ssq
		showSpecificQuestion: function (annotation) {
			this.sayDev("SSQ " + annotation.id + " --> " + annotation.time, annotation)
      if (!this.playID && !this.ownsPost) {
        this.sayDev("No play ID yet, save!")
        this.saveProgress(this.currentTime.valueOf())
      }
			if (!annotation || annotation.hidden) {
				this.hideAllDialogsAndPlay("SSQ")
				return
			}
			this.hintMessage = ""
			var proceed = true
			
			if (this.divDialog || this.isDraggingMarker) {
				return
			}
			if (!annotation) {
        this.sayDev("No proceed annotation")
				proceed = false
			}
      if (annotation.id == this.lastQuestionID) {
        this.sayDev("No proceed LQID")
        proceed = false
      }
			if (this.lastPauseTime == annotation.time) {
        this.sayDev("No proceed LPT")
				if (!this.player.paused()) {
					proceed = false
				} else {
				}
				if (proceed && this.pauseAnnotationID == annotation.id) {
					this.sayDev("🏀 PAID == AID, no proceed")
					proceed = false
				}
			}
			if(annotation.tray_type == 'ctaTray') {
        this.sayDev("No proceed ctaTray")
				proceed = this.hasCustomCta
			}
			if (!proceed) {
				this.hideAllDialogsAndPlay("SSQ2")
				return
			}
			if (!this.player.paused()) {
				this.pausePlayer("SSQ")
				this.pauseAnnotationID = annotation.id
			} 
			if (this.editingAnnotation.id || this.drawingAnnotations) {
				return
			}
			this.activeHotspot = null
			this.chosenAnswer = ''
			this.correctAnswer = ''
			this.questionAnswer = ''
			this.questionChoices = []
			this.replyMessage = ''
      this.showingRepeatResult = false
      this.responseForRepeat = false
      this.replyDate = null
			this.clearReplyAnnotation()
			this.setReplyAnnotation(annotation)
			this.currentQuestionIndex = Math.max(this.orderedQuestions.indexOf(annotation), 0)
      this.sayDev("CurrentQuestionIndex: " + this.currentQuestionIndex)
			this.questionAnnotation = annotation
      this.lastQuestionID = this.lastQuestionID || annotation.id
			var x = [] 
      if (this.dactions[annotation.exact_time]) {
        this.dactions[annotation.exact_time].forEach((a)=> {
          if (a.type_of == 'question' && annotation.tray_type != 'ctaTray') { x.push(a)}
        })
      }
			if (annotation.tray_type == 'ctaTray'){
				this.ctaTrayAnnotations.forEach((a)=> {
					if (a.type_of == 'question') { x.push(a)}
				})
			}
			if (x) {
				if (x.length > 1) { this.questionSet = x } 
        else { this.questionSet = [x] }
				var i = this.questionSet.indexOf(annotation)
				this.questionSetIndex = i > -1 ? i : this.questionSetIndex
        this.questionSetIndex = Math.max(i, 0)
				this.sayDev("New QSI: " + this.questionSetIndex)
        this.sayDev("QuestionSet Length: " + this.questionSet.length)
			}
			this.replyMessage = ''
			var c = this.questionAnnotation.content
			if (c.style == 'multiple' || c.style == 'all' || c.style == 'likert' || c.style == 'poll') {
				if (c.answers) { this.questionChoices = JSON.parse(c.answers) }
				else {
					if (c.aText) {
						this.questionChoices.push({ "text": c.aText, "action": c.aAction, "value": c.aVal })
						if (c.bText) {
							this.questionChoices.push({ "text": c.bText, "action": c.bAction, "value": c.bVal })
							if (c.cText) {
								this.questionChoices.push({ "text": c.cText, "action": c.cAction, "value": c.cVal })
								if (c.dText) {
									this.questionChoices.push({ "text": c.dText, "action": c.dAction, "value": c.dVal })
								}
							}
						}
					}
				}
			} else if (c.style == 'select') {
				this.questionChoices = JSON.parse(c.answers)
			}
			if (this.notesDialog) { this.toggleListDialog() }
			this.showSeekbar = false
			this.questionDialog = true
			this.questionAnnotation.response_time = new Date()
			this.sayDev('Initial ⌚️⌚️⌚️', this.questionAnnotation.response_time)

			if (!this.showCTA){
				this.sayDev("Set to exact time...." + annotation.exact_time)
				this.player.currentTime(annotation.exact_time)
			}
			if (['free', 'number', 'date'].includes(this.questionAnnotation.content.style)) {
				setTimeout(() => {
					var x = document.getElementById('reply_message_input')
					if (x) { x.focus() }
				}, 200);
			} else if (this.questionAnnotation.content.style == 'select') { 
        this.focusSelect()
      }
      // this.sayDev("Can skip: " + this.canSkipQuestion)
			if (!this.canSkipQuestion) { this.startQuestionTimer(this.questionAnnotation.content.timer);}
			if (this.questionAnnotation.speak) {
				setTimeout(() => {
					this.sayDev(this.questionAnnotation.content.style)
					var str = document.getElementById('prompt_content').innerText
          console.log(this.questionAnnotation)
					if (this.questionAnnotation.speak_answers) {
						if (['multiple','all', 'likert'].includes(this.questionAnnotation.content.style) && this.questionChoices) {
							this.questionChoices.forEach((c)=> {
								str += ' yyyyy ' + c.text
							})
						}
					}
					this.speakMessage(str)
				}, 100)
			}
      this.doCataStyles()

		},
    doCataStyles() {
      this.minAnswerWidth = 70
      this.minAnswerHeight = 80
      setTimeout(()=> {
        if (this.questionAnnotation && this.questionAnnotation.content.style == 'all') {
          var x = $('.v-icon.far.fa-square.theme--dark')[0]
          if (x) {
            x.style.color = this.safeStyles.question.answers_color
          }
          var x = $('.v-icon.far.fa-square.theme--dark')[1]
          if (x) {
            x.style.color = this.safeStyles.question.answers_color
          }
          var x = $('.v-icon.far.fa-square.theme--dark')[2]
          if (x) {
            x.style.color = this.safeStyles.question.answers_color
          }
          var x = $('.v-icon.far.fa-square.theme--dark')[3]
          if (x) {
            x.style.color = this.safeStyles.question.answers_color
          }
          var x = $('.v-icon.far.fa-square.theme--dark')[4]
          if (x) {
            x.style.color = this.safeStyles.question.answers_color
          }
        }
        if (this.questionAnnotation && this.questionAnnotation.content.style == 'multiple') {
          var min = Math.max(this.minAnswerWidth, 40)
          var hin = Math.max(this.minAnswerHeight, 40)
          var i = 0
          while (i < 5) {
            var x = document.getElementById('multibutton-'+i)
            if (x) {
              var w = x.getBoundingClientRect().width
              var h = x.getBoundingClientRect().height
              if (w > min) {
                min = w
                this.minAnswerWidth = parseInt(w)
              }
              if (h > hin) {
                hin = h
                this.minAnswerHeight = parseInt(h)
              }
            }
            i++;
          }
        }
      }, 10)

    },
		showSpecificDrawing(annotation) {
			this.sayDev("👨‍🎨 SSD")
			this.introDialog = false

			this.clearScreenInteractions()
			this.drawingAnnotations = [annotation]
			this.pausePlayer("SSD")
			this.sayDev("SSD PROCEED")
			this.questionAnnotation = null
			this.questionDialog = false
			if (this.notesDialog) {
				this.toggleListDialog()
			}
			this.showSeekbar = false
			if (annotation.extra) {
				this.caption = {
					id: annotation.id,
					content: annotation.extra,
					avatar: annotation.avatar
				}
			}
			this.sayDev("Set CT to " + annotation.exact_time)
      this.player.currentTime(parseFloat(annotation.exact_time).toFixed(3))
			$('#drawingAnnotation').fadeIn(1000)
      var found = false
      var arr = this.dactions[this.deciTime] || this.dactions[this.deciTime.toString() + '.0'] || []
      arr.forEach((a)=> {
        if (!found && a.type_of == 'audio') {
          console.log("Found audio...")
          found = true
          this.audioAnnotation = a
				  this.playAudioStamp(this.audioAnnotation)
        }
      })
      // Only do art timer if we don't have an audio
      if (!found && !annotation.parent_id) {
        this.sayDev("TryPause from SSD")
        var x = this.tryPause(annotation)
        if (!x) {
          return
        }
      }
		},
		startPointAnimation(a) {
      this.sayDev("Start Point Animation at " + this.deciTime, a)
			var last = this.deciTime == this.lastAnimationPoint
      var pp = a.pointPath[this.deciTime] || a.pointPath[this.deciTime.toFixed(1)]
			var nope = !pp
			if (nope){
        this.sayDev("Nope at " + this.deciTime)
				if (this.deciTime >= a.pointPath['last'].time){
					var z = $('#adi-'+a.id).animate({
						left: a.pointPath['last'].left + '%',
						top: a.pointPath['last'].top + '%',
					}, 95, ()=> {
					});
					a.pointAnimation = z
				} else{
					var z = $('#adi-'+a.id).animate({
						left: a.pointPath['first'].left + '%',
						top: a.pointPath['first'].top + '%',
					}, 95, ()=> {
					});
					a.pointAnimation = z
				}
			}
			if (last || nope) {
				if (this.isPlayerPaused()) {
					a.pointAnimation = null
					return
				}
				// if (this.deciTime >= a.time && this.deciTime < a.finish && !a.pause) {
				// 	setTimeout(()=> {
				// 		this.startPointAnimation(a);
				// 	}, 80)
				// } else { a.pointAnimation = null }
				return
			} 
			this.lastAnimationPoint = this.deciTime
			var z = $('#adi-'+a.id).animate({
				left: pp.left + '%',
				top: pp.top + '%',
			}, 95)
			a.pointAnimation = z
		},
		addToActive: function (a) {
			if (this.divsActive.indexOf(a) < 0) {
				a.style = this.getActiveDivStyle(a)
				if (a.pointPath && a.pointPath[this.deciTime]) {
					a.style.left = a.pointPath[this.deciTime].left + '%'
					a.style.top = a.pointPath[this.deciTime].top + '%'
				}
				this.sayDev("AddToActive:", a)
				this.divsActive.push(a)
				if (a.pointPath) {
					setTimeout(()=> {
						this.startPointAnimation(a)
					}, 1)
				}
			}
		},
		showStamps: function () {
      // this.doAllStamps(this.dactions[this.deciTime] || [])
		},
    doRedirect(url) {
      this.sayDev("Do redirect")
      setTimeout(()=> {
        if (this.isEmbed) {
          this.emitMessage("redirect", { link: this.formatLink(url)})
        }
        window.location.href = this.formatLink(url)
      }, 50)
    },
    showNavigation(x) {
      if ((this.ownsPost && this.dialogShowing) || this.preventNavs) { return }
      if (this.deciTime == x.exact_time) {
        this.sayDev("😸 Navigation", x.type_of)
        var t = x.type_of
        var d = JSON.parse(x.data)
        if (t == 'jump') {
          this.pausePlayer("ShowNav")
          this.stopTimeHandler()
          this.divsActive = []
          this.pausedUntilClick = false
          this.sayDev("CT1: " + this.player.currentTime())
          this.player.currentTime(d.value)
          this.sayDev("CT2: " + this.player.currentTime())
          this.player.play()
        } else if (t == 'redirect') {
          if (this.ownsPost) {
            this.$toasted.show("Skipping link redirect as owner. Use Preview Mode to see redirect occur.")
            return
          }
          this.saveProgress(this.currentTime.valueOf())
          this.doRedirect(d.value)
          
        } else if (t == 'switch') {
          if (this.isProduction && this.ownsPost) {
						this.$toasted.show("Skipping switch video as owner. Use Preview Mode to see switching occur.")
						return
					}
					// var key = data.value.split("---")[1] + '?referrer=' + this.share.token
					// var x = window.location.origin + window.location.pathname + '?t=' + this.currentTime
          this.sayDev(x)
					this.referVideoStack.unshift(this.share.token)
					this.referTimeStack.unshift(this.currentTime.valueOf())
          this.pausedUntilClick = false
          var t = d.switch_time || x.click_time || 0
          this.sayDev("Switch Time: " + t)
					this.changeVideo(d.value.split("---")[1], t)
        } else if (t == 'variable') {
          if (x.value) {
            this.setVariable(x.variable, x.value)
          } else {
            var z = JSON.parse(x.data)
            this.setVariable(x.variable, z.value)
          }
        } else if (t == 'pause') {
          this.tryPause(x)
				} else if (t == 'reset_viewer') {
          this.resetViewerState()
				} else if (t == 'show_card') {
					this.showCard(x.content.id, true)
				} else if (t == 'resourceTray'){
					this.showResourceTray("ShowNavigation")
				} else if (x.type_of == 'chatGPT'){
					this.askChatGPT(x.data, x.variable)
				} else  if (['post','get'].includes(x.type_of)){
					this.sendConnectorRequest(d.value,x.type_of)
        } else {
          this.clickData(JSON.parse(x.data), x, true)
        }
      }
      return
    },
		showCard(x){
 			this.findActiveCard(x, true)
		},
		findActiveCard(x, show){
			if (!this.assets.cards){
				setTimeout(() => {
					this.findActiveCard(x)
				},100) 
				return
			}
			for (var i = 0; i < this.assets.cards.length; i++){
				if (this.assets.cards[i].id == x){
					this.suppressCardWatcher = true
					this.activeCard =  Object.assign({},this.assets.cards[i])
					this.activeCard.title = typeof this.activeCard.title == 'string' ? JSON.parse(this.activeCard.title) : this.activeCard.title
					this.activeCard.text = typeof this.activeCard.text == 'string' ? JSON.parse(this.activeCard.text) : this.activeCard.text
					this.activeCard.button = typeof this.activeCard.button == 'string' ? JSON.parse(this.activeCard.button) : this.activeCard.button
					
          if(['get','post'].includes(this.activeCard.click_action)){
						var data = JSON.parse(this.activeCard.click_value)
						this.loadHtmlScripts()
						this.activeCard.click_value = data.url
						this.activeCard.auth = data.auth
						this.activeCard.field = data.field 
						this.activeCard.body = data.body
						this.activeCard.head = data.head
						this.activeCard.httpVar = data.httpVar 
					} else if (this.activeCard.click_action == 'download'){
						this.assetDownload[0] = JSON.parse(this.activeCard.click_value)
						this.activeCard.click_value = this.assetDownload[0].url
					} else if (['cart', 'chatGPT'].includes(this.activeCard.click_action)){
						this.activeCard.click_value = JSON.parse(this.activeCard.click_value)
					}
					this.sayDev('💳 Found Card', this.activeCard)
					this.suppressCardWatcher = false
					if (show){
						this.pausePlayer("FindActiveCard");
						this.showCardDialog = true
					}
					return
				}
			}
			this.sayDev("❌ Didn't Find Card", x)
		},
		resetPassword: function () {
			var email = $('#password_reset_email').val().trim()
			if (!email) {
				alert("Please enter your email")
				return
			}
			Rails.ajax({
				url: '/reset_password?email=' + encodeURIComponent(email),
				type: "POST",
				success: (data) => {
					$('#reset_password_form').hide();
					$('#reset_password_message').show();
				},

				error: (data) => {
					alert("Something went wrong, please refresh and try again")
				}
			});
		},
		handleSpacebar: function (e) {
			if (this.ownsPost || this.selectedAnnotations.length || this.editTitle || this.chaptersDialog || (this.message && $("#message_input").is(":focus")) || this.dialogShowing || this.message || this.isSearching() || this.isEditing || this.isSharing || this.introDialog || this.showingAnyTrays) {
        this.sayDev("Prevent Spacebar")
        e.preventDefault()
        e.stopPropagation()
        return false;
      } else {
				this.togglePlayer();
			}
		},

		goHome: function () {
			window.location.href = this.homeLink
		},

		resetVimeoWarn() {
			this.pasteLink = ''
		},
		jwPlayerLink(embedCode) {
			// <div itemscope itemtype="https://schema.org/VideoObject"><meta itemprop="uploadDate" content="Tue Jan 26 2021 13:01:09 GMT-0500 (Eastern Standard Time)"/><meta itemprop="name" content="Chameleon"/><meta itemprop="duration" content="PT3.434S" /><meta itemprop="thumbnailUrl" content="https://content.jwplatform.com/thumbs/HI4d7kCf-1920.jpg"/><meta itemprop="contentUrl" content="https://content.jwplatform.com/videos/HI4d7kCf-KccuPHat.mp4"/><script src="https://cdn.jwplayer.com/players/HI4d7kCf-fjB2OiF6.js"></script></div>
			var link = false
			var thumb = false
			var name = false
			var v = embedCode.split('itemprop="contentUrl" content="')
			if (v[1]) {
				link = v[1].split('"')[0]
				var c = embedCode.split('itemprop="thumbnailUrl" content="')
				if (c[1]) {
					thumb = c[1].split('"')[0]
				}
				var n = embedCode.split('itemprop="name" content="')
				if (n[1]) {
					name = n[1].split('"')[0]
				}
			}

			if (this.UrlExists(link)) {
				this.switchingVideo = true
				this.video_type = 'MP4'
				this.video_key = link
				this.newVideo.message = 'Created on Mindstamp! This message is customizable'
				if (thumb && this.UrlExists(thumb)) {
					this.newVideo.thumbnail = thumb
				}
				if (name) {
					this.newVideo.title = name
				}
				return true
			}

			return false

		},
		kalturaLink(embedCode) {
			var quality_id = '0'
			var partner_id = ''
			var entry_id = ''
			var kbase = 'https://cdnapisec.kaltura.com/p/'
			if (embedCode.includes("embedIframeJs" && !embedCode.includes("<script") && !embedCode.includes(".php"))) {
				var x = embedCode
				entry_id = (x.split("&entry_id=")[1]).split("&")[0]
				partner_id = x.split("https://cdnapisec.kaltura.com/p/")[1].split("/sp")[0]
			}

			if (embedCode.includes('mwEmbedFrame')) {

				// Example 1
				var e1 = '<script src="https://cdnapisec.kaltura.com/p/3027751/sp/302775100/embedIframeJs/uiconf_id/46344601/partner_id/3027751"></script> <div id="kaltura_player_1600798483" style="width: 400px; height: 333px;"></div> <script> kWidget.embed({ "targetId": "kaltura_player_1600798483", "wid": "_3027751", "uiconf_id": 46344601, "flashvars": {}, "cache_st": 1600798483, "entry_id": "1_7ctjc7xp" }); </script>'
				// Example 2
				var e2 = 'https://cdnapisec.kaltura.com/html5/html5lib/v2.75.1/mwEmbedFrame.php/p/1971712/uiconf_id/33145471/entry_id/1_zrkykoge?wid=_1971712&iframeembed=true&playerId=kaltura_player_1585324384&entry_id=1_zrkykoge'
				// Example 3
				var e3 = 'https://cdnapisec.kaltura.com/p/1971712/sp/197171200/embedIframeJs/uiconf_id/33145471/partner_id/1971712?iframeembed=true&playerId=kaltura_player_1585324384&entry_id=1_zrkykoge'

				var x = embedCode
				var y = x.split('https://cdnapisec.kaltura.com/html5/html5lib/v2.75.1/mwEmbedFrame.php/p/')
				if (!y[1]) {
					y = x.split('https://cdnapisec.kaltura.com/html5/html5lib/v2.75.1/mwEmbedFrame.php/p/')
				}
				if (y[1]) {
					var z = y[1].split("/uiconf_id/")
					if (z[1]) {
						var partner_id = z[0]
						var w = z[1].split("/entry_id/")
						if (w && w[1]) {
							var yy = w[1].split('?wid')
							var entry_id = yy[0]
						}
					}
				}
			}
			try {
				var y = JSON.parse(embedCode.split('kWidget.embed(')[1].split('); <')[0])
				partner_id = y.wid.replace("_", "")
				entry_id = y.entry_id
			} catch (err) {
			}

			if (partner_id && entry_id) {
				var example = 'https://cfvod.kaltura.com/pd/p/811441/sp/81144100/serveFlavor/entryId/0_khge3cfm/clipTo/2000/v/2/ev/8/flavorId/0_e656e82l/name/a.mp4'
				var correct = 'https://cdnapisec.kaltura.com/p/' + partner_id + '/sp/0/playManifest/entryId/' + entry_id + '/format/url/protocol/https/flavorParamId/0/video.mp4'
				var old = 'https://www.kaltura.com/p/' + partner_id + '/sp/0/playManifest/entryId/' + entry_id + '/format/url/flavorParamId/0/video.mp4'
				var results = 'https://cfvod.kaltura.com/pd/p/' + partner_id + '/sp/302775100/serveFlavor/entryId/' + entry_id + '/flavorId/0_e656e82l/name/video.mp4'
				var x = this.UrlExists(correct)
				if (x) {
					this.vimeoKey = correct
					this.video_type = 'MP4'
					this.setMP4Key()
					return true
				}
			}

		},
		UrlExists: function (url) {
			if (!url || !url.includes("http")) {
				return false
			}
			try {
				var http = new XMLHttpRequest();
				http.open('HEAD', url, false);
				http.send();
				if (http.status == 200) {
					return true
				} else {
					return false
				}
			} catch (err) {
				return false
			}
		},
		goSynthesia(video){
			if (video.status != 'complete'){
				this.$toasted.show('Please wait for this video to be completed by Synthesia')
				return
			}
			this.pauseSampleAsset()
			this.synthesiaVideo = video
			this.pasteLink = video.url
			this.checkLink()
		},
    goHeygen(video){
      console.log("GoHeyGen", video)
			this.pauseSampleAsset()
			this.pasteLink = video.video
      this.newVideo.title = video.title + " (Interactive)"
      console.log("Title: " + this.newVideo.title)
			this.checkLink()
		},
		goPixabay(video){
			this.pauseSampleAsset()
			this.pasteLink = video.url
			this.checkLink()
		},
		playSampleAsset(id){
			this.pauseSampleAsset()
			this.playingSampleAsset = id
			setTimeout(() => {
				var obj = document.getElementById(id);
				if (obj) obj.play()
			},500) 
		},
		pauseSampleAsset(){
			var obj = document.getElementById(this.playingSampleAsset);
			if (obj) obj.pause()
			this.playingSampleAsset = null
		},
		checkLink: function () {
      this.sayDev("Check link: " + this.pasteLink)
      window.analytics.track("Pasted Link", { link: this.pasteLink })
			setTimeout(() => {
        if (this.pasteLink.includes("loom")) {
          var id = this.pasteLink.split("/share/")[1]
          console.log("ID is now " + id)
          if (id) {
            id = id.split("?")[0]
            this.getLoomLink(id)
          }
        }
        else if (this.pasteLink.includes("heygen") && !this.pasteLink.includes("files")) {
          this.sayDev("Check heygen....")
          Rails.ajax({
            url: '/heygen?url='+this.pasteLink,
            type: "GET",
            success: (data) => {
              console.log(data)
              if (data && data.link) {
                this.videoKey = data.link
                this.sayDev("✅ HeyGen")
                this.vimeoKey = data.link
                this.video_type = 'MP4'
                this.setMP4Key()
					      this.uploadProgress = 100
                this.newVideo.title = data.title
                if (this.replacingVideo) {
                  this.switchingVideo = true
                  this.queueComplete()
                }
              } else {
                this.sayDev("❌ No")
                this.$toasted.show("The link is not valid. Please try again and contact support if the issue persists.")
              }
            }
          });
        }
        else if (this.pasteLink.includes("jwplayer")) {
          this.sayDev("Check jwplayer....")
          Rails.ajax({
            url: '/jwplayer?url='+this.pasteLink,
            type: "GET",
            success: (data) => {
              console.log(data)
              if (data && data.link) {
                this.videoKey = data.link
                this.sayDev("✅ isJW")
                this.vimeoKey = data.link
                this.video_type = 'MP4'
                this.setMP4Key()
					      this.uploadProgress = 100
                this.newVideo.title = data.title
                if (this.replacingVideo) {
                  this.switchingVideo = true
                  this.queueComplete()
                }
              } else {
                this.sayDev("❌ No")
                this.$toasted.show("The link is not valid. Please try again and contact support if the issue persists.")
              }
            }
          });
        }
        else if (this.pasteLink.includes("panopto")) {
          this.pasteLink = this.pasteLink.replace("Viewer.aspx", "Embed.aspx")
          this.sayDev("Check panopto....")
          Rails.ajax({
            url: '/panopto?url='+this.pasteLink,
            type: "GET",
            success: (data) => {
              console.log(data)
              if (data && data.link) {
                this.newVideo.panoptoLink = this.pasteLink
                this.videoKey = data.link
                this.sayDev("✅ Panopto")
                this.vimeoKey = data.link
                this.video_type = 'MP4'
                this.setMP4Key()
					      this.uploadProgress = 100
                this.newVideo.title = data.title
                if (this.replacingVideo) {
                  this.switchingVideo = true
                  this.queueComplete()
                }
              } else {
                this.sayDev("❌ No")
                this.$toasted.show("The link is not valid. Please try again and contact support if the issue persists.")
              }
            }
          });
        }
        else if (this.pasteLink.includes("synthesia.io")) {
          this.sayDev("Check synth....")
          if (this.pasteLink.includes("app.synthesia")) {
            this.pasteLink = this.pasteLink.replace("app.synthesia.io/#/video/", "share.synthesia.io/")
          }
          Rails.ajax({
            url: '/link/synthesia?url='+this.pasteLink,
            type: "GET",
            success: (data) => {
              console.log(data)
              if (data && data.link) {
                this.newVideo.og_link = this.pasteLink
                this.sayDev("✅ Synthesia")
                this.vimeoKey = data.link
                this.video_type = 'MP4'
                this.newVideo.title = data.title
                this.setMP4Key()
					      this.uploadProgress = 100
                this.newVideo.title = data.title
                if (this.replacingVideo) {
                  this.switchingVideo = true
                  this.queueComplete()
                }
              } else {
                this.sayDev("❌ No")
                this.$toasted.show("The link is not valid. Please try again and contact support if the issue persists.")
              }
            }
          });

        }
				else if (this.ytVidId(this.pasteLink)) {
					this.video_type = 'youtube'
					this.youtubeKey = this.pasteLink
					this.uploadProgress = 100
					if (this.replacingVideo) {
						this.switchingVideo = true
						this.queueComplete()
					}
				} else if (this.kalturaLink(this.pasteLink)) {
					this.uploadProgress = 100
					if (this.replacingVideo) {
						this.switchingVideo = true
						this.queueComplete()
					}
				} else if (false && this.jwPlayerLink(this.pasteLink)) {
					if (this.video_key.includes("m3u8") > -1) {
						this.video_type = 'HLS'
					} else {
						this.video_type = "MP4"
					}
					this.uploadProgress = 100
					if (true || this.replacingVideo) {
						this.switchingVideo = true
						this.queueComplete()
					}
				} else if (this.vimeoDirectLink(this.pasteLink) || this.pasteLink.indexOf('.m3u8') > -1) {
					this.video_type = 'HLS'
					this.vimeoKey = this.pasteLink
					this.setVimeoKey()
					this.uploadProgress = 100
					if (this.replacingVideo) {
						this.switchingVideo = true
						this.queueComplete()
					}
				} else if (this.vimeoVidId(this.pasteLink)) {
					this.vimeoWarnKey = this.vimeoVidId(this.pasteLink)
					this.vimeoWarnDialog = true
					return false
					// this.video_type = 'vimeo'
					// this.vimeoKey = this.pasteLink
					// this.uploadProgress = 100
				} else if (this.wistiaVidId(this.pasteLink)) {
          this.sayDev("Wistia! : " + this.wistiaVidId(this.pasteLink))
          try {
            this.getWistiaAssets(this.wistiaVidId(this.pasteLink))
          } catch(e) {
            console.log("Error")
            console.log(e)
            this.$toasted.show("Unable to use this video. Please ensure your domain whitelist includes Mindstamp.com. Contact support if you need help!")
          }
				} else if (this.isMP4Link(this.pasteLink) || this.pasteLink.includes(".m4v") || this.pasteLink.slice(-9) == '/original') {
					if (this.pasteLink.includes("player.vimeo.com/external/")) {
						this.vimeoID = this.pasteLink.split(".hd")[0].split("/external/")[1]
						if (!this.selectedStockType){
							this.vimeoHLSDialog = true
						}
					}
					this.vimeoKey = this.pasteLink.replace("dl=0", "dl=1").replace("www.dropbox.com", "dl.dropbox.com").replace("dl.dropbox.com", "dl.dropboxusercontent.com")
					this.video_type = 'MP4'
					this.setMP4Key()
					this.uploadProgress = 100
					if (this.replacingVideo || this.activeType == 5) {
						if(this.synthesiaVideo){
							this.newVideo.title = this.synthesiaVideo.title
						}
						this.switchingVideo = true
						this.queueComplete()
					} else if (this.activeType == 10) {
            this.sayDev("HeyGen qc")
            this.queueComplete()
          }
          
				} else {
					this.$toasted.show("Sorry, that link is not valid. Please try another!")
					this.pasteLink = ''
				}
			}, 50);
		},

		ytVidId: function (url) {
			var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
			return (url.match(p)) ? RegExp.$1 : false;
		},

		wistiaVidId: function (url) {
			// https://fast.wistia.com/embed/medias/act0po2d0o
			var regex = /^.*(wistia\.com\/)(embed\/iframe\/|medias\/)([A-z0-9]+)/;
			var match = url.match(regex);
			
			if (match && match[3]) {
				return (match) ? match[3] : false;
			} else if (url.includes("https://fast.wistia.com/embed/medias/")) {
				return url.split("https://fast.wistia.com/embed/medias/")[1] || false
			} else if (url.includes("edit?wvideo=")) {
				return url.split("wvideo=")[1].split('">')[0]
			}
      this.sayDev("NOt wistia...")
			return false
		},

		vimeoVidId: function (url) {
			const p = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
			return (url && url.match(p)) ? url.match(p)[1] : false;
		},
		vimeoDirectLink: function (url) {
			return url.includes("https://player.vimeo.com/external") && url.includes(".m3u8?")
		},
		isMP4Link: function (url) {
			return this.isValidUrl(url) && url.split("?")[0].includes(".mp4") && !url.includes("dropbox")
		},
		formatAnswerDialog: function(arg, type){
			var hasMinMax = false
      var data = arg
			try {
        if (isNaN(arg)) {
          data = JSON.parse(arg)
          hasMinMax = true
        }
			} catch (e){}
			if(type == 'date'){
				if (!data.includes('-')){
					data = this.prettyDate(this.plainDate)
				} else {
					data = this.prettyDate(data)
				}
			} else if(type == 'number' && hasMinMax){
				var min = parseFloat(data[0])
				var max = parseFloat(data[1])
				if(min == max){
					data = min.toLocaleString('en-US')
				} else {
					data = "Min: " + min.toLocaleString('en-US') + " - Max: " + max.toLocaleString('en-US');
				}
			} else if(type == 'all' || type == 'free'){
				var conj = type == 'all' ? ' ' + this.strings.and.toLowerCase() + ' ' : ' ' + this.strings.or.toLowerCase() + ' ' 
				try {
					if (data.length == 1){
						return data[0]
					}
					return data.map(a => a.trim()).slice(0, -1).join(', ')  +  conj + data[data.length-1].trim()
				} catch(e){}
			}
			return data;
		},
		prettyDate(date){
			date = date.split('-')
			var yr = date[0]
			var mo = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date[1]-1]
			var dy = date[2]
			return `${mo} ${dy}, ${yr}`
		},
		tryQC: function (type_of) {
			if (this.uploadVideos.length < 2 && !this.newVideo.title && !this.replacingVideo) {
				this.$toasted.show("Please add a video title 👇🏼")
				document.getElementById('new_video_title').focus()
			} else {
				this.queueComplete()
			}
		},
		queueComplete: function (annotations) {
      this.sayDev("🌈🌈🌈 Queue Complete")
			if (this.queueCompleted) {
        this.sayDev("Already")
				return
			}
			if (this.videoInfoDialog) {
        this.sayDev("VID")
				return
			}
      this.sayDev("DoQC --> " + this.video_key)
			this.queueCompleted = true
			var data = new FormData
			data.append("video[type_of]", this.video_type)
			data.append("video[key]", this.video_key)
			data.append("video[title]", this.newVideo.title || this.video_name)
			data.append("video[mb_size]", this.newVideo.size)
			data.append("video[duration]", this.newVideo.duration)
			data.append("video[transcode]", this.newVideo.transcode)
			data.append("video[transcribe]", this.newVideo.transcribe)
			data.append("video[wistia_id]", this.wistiaKey || '')
			data.append("video[loom_id]", this.newVideo.loom_id || '')
			data.append("video[panopto_link]", this.newVideo.panoptoLink || '')
      data.append("multiple", JSON.stringify(this.uploadVideos))
      data.append("group_id", gon.group_id)
      data.append("series_id", gon.series_id)
			if (this.video_key != "") {
        this.sayDev("Do the ting")
				Rails.ajax({
					url: '/sources',
					type: "POST",
					data: data,
					dataType: "JSON",
					success: (data) => {
            if (this.uploadVideos.length > 1) {
              this.sayDev("🐺 Multiple uploads, go to library!")
              window.location.href = '/videos'
              return
            } else if (window.location.href.includes("replace_asset")) {
              this.$toasted.show("Success!")
              setTimeout(()=> {
                window.location.href = '/assets'
              }, 1500)
            }
						this.videoId = data.id
            this.sayDev("Move to form...")
						this.moveToForm(annotations)
					}
				});
			}
		},
		moveToForm: function (annotations) {
      this.sayDev("moveToForm start")
			var data = new FormData
			data.append("video[title]", this.newVideo.title || 'Interactive Video #' + parseInt(gon.video_count + 1))
			data.append("video[source_id]", this.videoId)
			if (this.replacingVideo) {
				var x = this.getUrlParam("replace")
				data.append("replace", x)
        var z = this.getUrlParam("replace_asset")
				data.append("replace_asset", z)
			}
      this.sayDev("Check 1")
			data.append("video[private]", true)
			data.append("video[image]", this.newVideo.thumbnail || '')
			data.append("video[observe]", true)
			data.append("video[participate]", false)
			data.append("video[message]", this.newVideo.message || 'Press play to start interactive video')
			if (gon.group_id) {
				data.append("video[group_id]", gon.group_id)
			}
			if (gon.series_id) {
				data.append("video[series_id]", gon.series_id)
			}
      var theUrl = '/posts'
      if (this.shoppableSrc) {
        theUrl += "?shoppableSrc=" + this.shoppableSrc
      }
      this.sayDev("Check 2 pre ajax")

			Rails.ajax({
				url: theUrl,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
          this.sayDev("Success, response")
          this.sayDev(data)

					var post_url = window.location.origin + data
					if(annotations && annotations.length){
            this.sayDev("Annotations has length, do another form...")
						var annData = new FormData()
						annotations.forEach(a => {
							annData.append("annotation[type_of]", a.type_of )
							annData.append("annotation[content]", a.content)
							annData.append("annotation[time]", a.time)
							annData.append("annotation[exact_time]", a.time)
							annData.append("annotation[finish]",a.finish )
							annData.append("annotation[exact_finish]",a.finish )
							annData.append("annotation[data]", a.data )
							annData.append("annotation[click_action]", a.click_action)
              annData.append("annotation[text_color]", a.text_color || "")
              annData.append("annotation[background_color]", a.background_color || "")
							annData.append("annotation[transition]", a.transition)
							annData.append("annotation[animation]", a.animation != null ? a.animation : '')
							annData.append("annotation[customStyle]", a.customStyle)
							annData.append("annotation[token]", data.split('/')[3] || data.split('/')[2] )
              this.sayDev("Create ann")
							this.createAnnotation(annData)
						})
					}
          if (annotations && annotations.length) {
            this.sayDev("Anns has length, timeout to post url")
            setTimeout(()=> {
              window.location.href = post_url
            }, 1500)
          } else {
            this.sayDev("No anns, go to post immediately")
						window.location.href = post_url
					}
				}
			});
		},
		bytesToSize: function (bytes) {
			var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			if (bytes == 0) return '0 Byte';
			var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
		},
		processQueue: function (file) {
			let validTypes = ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/mp4', 'video/mov', 'video/ms4v', 'video/quicktime']
			if (validTypes.indexOf(file.type) < 0) {
				this.$toasted.show("Please use a valid video file")
				setTimeout(() => {
					window.location.reload()
				}, 2000);
				return
			}
			this.uploading = 1
			this.video_type = "upload"
			this.video_name = file.name
			this.newVideo.title = file.name.split('.')[0]
			this.newVideo.title = 'Interactive Video #' + parseInt(gon.video_count + 1)
			this.video_size = file.size

			setTimeout(() => {
				document.getElementById('new_video_title').focus()
			}, 500);
		},
		setFileKey: function (videoKey) {
      this.sayDev("SetFileKey: " + videoKey)
			// this.optimizeDialog = gon.freeUser
			this.video_key = videoKey
			this.video_type = "upload"
			this.newVideo.transcode = true
			this.newVideo.transcribe = this.coreUpUser
			this.uploading = 1
      this.uploadVideos.push({
        key: videoKey,
        type_of: 'upload',
        transcode:  !this.educationUser,
        transcribe:  !this.educationUser,
      })
			this.flashFocusField('new_video_title')
		},

		startArtTimer: function (amount) {
			this.sayDev("🐙🐙🐙🐙 StartArtTimer: " + amount)
			if (this.divDialog) {
				return
			}
			if (!!this.artTimer) {
				return
			}
			if (amount < 0) {
				this.resetTimer()
				return
			}
			this.artTimer = true
			this.artTimerAmount = null
			setTimeout(() => {
				var am = amount ? amount * 10 : 50
				this.artTimerAmount = am;
				this.artTimerOriginal = amount || 5
				this.sayDev("🍎 Set Art Timer")
				var x = setInterval(() => {
          this.sayDev("ArtInterval")
					if (!this.artTimer) {
						clearInterval(x)
						this.sayDev("🍓 No art timer, done")
						this.resetTimer()
						return
					}
					this.artTimerAmount -= 2
					if (this.artTimerAmount <= -10) {
						this.resetTimer()
            this.doIOSdrawingSave()
						this.drawingAnnotations = []
						if (!this.dialogShowing) {
							this.sayDev("🧀  Drawing HADP and RT")
							this.hideAllDialogsAndPlay("SAT")
							this.resetTimer()
							return
						}
					}
				}, 200);
				this.artTimer = x
			}, 250)
		},
    doIOSdrawingSave() {
      if (this.isIOS && this.drawingAnnotations && this.drawingAnnotations[0]) {
        this.player.currentTime(parseFloat(this.drawingAnnotations[0].exact_time) + 0.5)
      }
    },
		startQuestionTimer: function (amount) {
			if (!amount || amount < 1) {
        return
      } else if (this.questionTimer || this.questionTimerAmount > 0) {
				this.sayDev("Block New Timer", this.questionTimerAmount)
				return
			}
			this.sayDev("⭐️ SQT: " + amount)
			this.questionTimerAmount = amount * 10;
			this.questionTimer = setInterval(() => {
				this.sayDev("QuestionInterval: " + this.questionTimerAmount)
				this.questionTimerAmount -= 2
				if (this.questionTimerAmount <= 0) {
					this.resetTimer()
					this.tryNextQuestion("sqt")
				}
			}, 200);
			// setTimeout(()=> {
			// 	this.sayDev("💎 Clear from Timer", amount * 1000)
			// 	clearInterval(this.questionTimer)
			// }, amount * 1000)
		},

		resetTimer: function () {
			// this.sayDev("🍐 RT", this.questionTimer)
			clearInterval(this.questionTimer);
			clearInterval(this.artTimer);
      this.questionTimerAmount = 0
      this.questionTimer = null
      this.artTimer = null
			// setTimeout(()=> {
			// 	this.questionTimer = null
			// 	this.artTimer = null
			// }, 10)
		},

		resetVolume: function () {
			clearInterval(this.volumeTimer)
			this.volumeTimer = null
			var v = Math.max(this.volumeLevel, 0.1)
			if (this.isMuted) {
        // this.sayDev("Reset Volume BUT MUTED SO NO: " + v)
				return
			} else {
        // this.sayDev("Reset Volume: " + v)
				this.player.volume(v)
				this.volumeLevel = v
			}
		},

		isPlayerPaused: function () {
			if (!this.player) {
				this.tryTrack("No player", this.share)
				return true
			}
			return this.player.paused();
		},

		// Player actions
		pausePlayer: function (from) {
      if (!this.player || this.player.paused() || this.switchingVideo) {
        this.sayDev("Block PausePlayer: " + from)
        return
      }
      this.sayDev("PausePlayer: " + from)
      this.player.pause();
      setTimeout(() => {
        if (this.resourceTray.show_on_pause && !this.dialogShowing && !this.notesDialog && !this.showingCheckout && !this.showingVariants && !this.drawingAnnotations && !this.ownsPost && !this.pausedUntilClick){
          this.showResourceTray("PausePlayer")
        }
      },100) 
		},
		playPlayer: function (source) {
      this.startTimeHandler()
			this.sayDev("👉🏼 PlayPlayer from " +source + " , DT: "+this.deciTime)
			var good = true
			var atEnd = !this.switchingVideo && this.played && this.player.currentTime >= this.player.duration()
			if(atEnd && !this.showMenu){
        this.sayDev("❌ PlayPlayer CTA " + this.player.currentTime() + " / " + this.player.duration())
        this.doEndEvent()
				this.showCTA = true
			}
			if ((this.showMenu && (this.resourceTray.pause || atEnd)) || this.showCTA || (this.ownsPost && (['end screen', 'magic menu'].includes(this.activeSideItem)))) {
				this.sayDev("Tray Showing")
        this.sayDev("CTA: " + this.showCTA)
				this.pausePlayer("PlayPlayer")
				return
			}
			this.selectedAnnotations = []
      if (this.ownsPost) {
        this.pausedUntilClick = false
      } else {
        this.divsActive.forEach((d)=> {
          if (!d.hidden && d.must_click && !d.clicked && this.exactTime >= Number(d.exact_finish) && !d.tray_type) {
            good = false
            this.setPUC("PlayPlayer " + d.id)
            this.sayDev(this.exactTime)
            this.sayDev(Number(d.exact_finish))
            this.sayDev("Must Click Annotation-->")
            this.sayDev(d)
          }
        })
        if (!good) {
          this.sayDev("Must click to continue!!")
          this.pausePlayer("PlayPlayer2")
          return
        }
      }
			if (this.player && !this.dialogShowing  && !this.showingCheckout && !this.showingVariants) {
        this.sayDev("1")
				try {
					var ct = this.player.currentTime()
          this.sayDev("2 --> " + ct)
					this.player.play().then(() => {
						this.sayDev("PlaySuccess")
						setTimeout(()=> {
							if (this.isIOS && (this.player.paused() || this.player.currentTime() == ct)) {
								// alert("Try force play!")
                this.sayDev("loop")
								// this.loadVideo(this.share.token, this.currentTime)
								if (!this.pausedUntilClick) {
									this.playPlayer("PlayPlayer ios !puc")
								}
							} else {
								// this.sayDev("Not paused....")
							}
              if (!this.isPlayerPaused()) {
                this.editingAnnotation = {}
              }
						}, 800)
					}).catch((e)=> {
						console.log("Video Playback error", e)
						if (gon.autoplay) {
							setTimeout(()=> {
                this.startTimeHandler()
								this.player.play();
								this.resumeDialog = false
							}, 100)
						}
					})
					this.resetTimer();
					this.resetVolume();
				} catch (e) {
					if (this.played) {
						this.resumeDialog = true
						this.$toasted.show('Having trouble playing - please try clicking the video. Contact support of this problem persists.')
					}
				}
			} else {
        this.sayDev(this.player)
        this.sayDev(this.dialogShowing)
				this.sayDev("No player or dialog showing")
				// if (!this.isProduction) {
				// 	alert("No player or dialog showing")
				// }
			}
		},
		tryUnmute() {
			if (this.isMuted && this.startTime && this.startTime != 0) {
        this.sayDev("Volume unmute")
				this.toggleMute()
				this.startTime = 0
				return true
			}
      // this.sayDev("Tried unmute volume, but can't")
			return false
		},
		togglePlayer: function () {
      this.sayDev("👉🏼 🐊 TogglePlayer " + this.deciTime)
      if (this.blockToggle || (this.showMenu && this.resourceTray.pause)) {
        // this.sayDev("----- 🔴 Block")
        this.sayDev(this.blockToggle)
        this.sayDev(this.showMenu + " , " + this.resourceTray.pause)
        setTimeout(()=> {
          this.blockToggle = false
        }, 100)
        return
      }
      this.speedPopup = false
      this.captionsPopup = false
			this.showCTA = false 
      this.blockToggle = true
      this.hideLoader = true
      this.startAfkTimer()
      // this.setSideItemTitle("Interactions")
      if (this.pausedUntilClick) {
        this.sayDev("Paused Until Click, return!")
        if (this.ownsPost) {
          this.$toasted.show("Interaction pausing until click - use SKIP button to advance", { duration: 1000})
        }
        return
      }
			this.showCursor()
			if (this.isMuted) {
				this.tryUnmute()
			}
			this.touchCount++

			if (this.variables.autoplay && this.isMuted) {
        this.sayDev("Autiplay and isMuted....return!")
				this.variables.autoplay = false
				this.toggleMute()
				return
			}
			if (this.isTouchDevice && !this.showSeekbar) {
        this.sayDev("Touch Device and no show seekbar....return!")
				this.mouseMove()
				return
			}
			if (this.toggling) {
        this.sayDev("Toggling....return!")
				return
			}
			this.mouseLeaveSeekbar()
			this.toggling = true
			setTimeout(() => {
				this.toggling = false
			}, 250)
			if (!this.played) {
        this.startTimeHandler()
				console.log('toggle')
				this.playFromStart()
				return
			}
			if (this.player.paused()) {
        this.startTimeHandler()
        this.doIOSdrawingSave()
				this.playPlayer("TogglePlayer")
			} else if (!this.share.block_pausing || this.ownsPost) {
				this.pausePlayer("Toggle");
        // if (this.ownsPost) {
        //   this.player.currentTime(this.deciTime)
        // }
        this.showSeekbar = true
        this.hideSeekbar("TP")
			}
			this.resetTimer();
      setTimeout(()=> {
        this.hideLoader = false
        this.blockToggle = false
      }, 500)
		},
		replyClick: function (reply) {
			if (reply.type_of === 'video') {
				this.playVideoStamp(reply)
			} else if (reply.type_of === 'audio') {
				this.playAudioStamp(reply)
			} else if (reply.type_of === 'drawing') {
				this.showSpecificDrawing(reply)
			} else {
				this.annotationClick(reply.time)
			}
		},
		annotationClick: function (annotation) {
      if (!this.ownsPost && this.cannotClickSeekbar) {
        // this.$toasted.show("Navigation Disabled")
				return 
      }
			if (this.showingAnyTrays){
				this.sayDev('Showing a tray, return')
				return
			}
			this.sayDev("📀 AC", annotation)
			if (this.showMenu){
				this.sayDev("Showing Magic Menu, return")
				return
			}
			this.lastPauseTime = -1
			var seconds = annotation.exact_time || annotation.time
			this.sayDev("Seconds: " + seconds)
			this.artTimerLast = 0
			this.clearScreenInteractions()
			if (!this.played) {
				this.hideAllDialogsAndPlay("AC")
				this.pausePlayer("AC")
			}

			this.resumeDialog = false
			setTimeout(() => {
				if (annotation.type_of == 'question') {
					this.sayDev("Question")
					this.lastQuestionID = null
					this.pausePlayer("ACQ")
					this.showSpecificQuestion(annotation)
					// this.changeTime(annotation.time)
					// this.playPlayer("AnnClick Q")
				} else if (annotation.type_of == 'drawing') {
					this.showSpecificDrawing(annotation)
				} else if (this.isPlayerPaused()) {
					setTimeout(() => {
						if (this.currentTime == annotation.time) {
							this.sayDev("ShowStamps...")
							this.showStamps();
						} else {
              this.sayDev("Nav to stamp")
              this.divsActive = []
							var p = annotation.exact_time / this.duration
							// this.sayDev("Percentage..." + this.share.duration + " ... " + annotation.time + " ... " + p)
							this.updateSeekbar(p * 100, true)
              this.deciTime = -1
              this.doTimeUpdate()
							// setTimeout(() => {
							// 	// this.showStamps()
							// }, 100);
						}
					}, 100);
				} else {
					this.changeTime(Math.max(parseFloat(annotation.exact_time) - 0.1, 0))
					this.hideAllDialogsAndPlay("AC2")
					this.lastPauseTime = -1
				}

				if (this.isMobile) {
					this.notesDialog = false
				}
			}, 0);

		},
		lowerVolume: function (sec) {
			this.sayDev("🤫  LowerVolume")
			if (this.volumeTimer || this.isMuted) {
				return
			}
      if (this.shareDesign && !this.shareDesign.fade_audio) {
        this.sayDev("No fade...return")
        return
      }
			this.volumeLevel = this.player.volume();
			// this.sayDev("VL: " + this.volumeLevel)
			var count, rate;
			if (sec == 2) {
				count = this.volumeLevel / 20
				rate = 100
			} else {
				count = this.volumeLevel / 10
				rate = 100
			}
			this.volumeTimer = setInterval(() => {
        // this.sayDev("VolumeInterval")
				if (player.volume() > 0) {
					// var newVol = Math.max(player.volume().toFixed(1) - 0.1, 0.0)
					var newVol = Math.max(player.volume() - count, 0.0)
					this.changeVolume(newVol)
				} else {
					this.stopVolumeInterval()
				}
			}, rate);
		},

		stopVolumeInterval: function () {
			clearInterval(this.volumeTimer)
			this.volumeTimer = null
		},
		changeVolume: function (vol) {
			// this.sayDev("ChangeVolume: " + vol)
			this.player.volume(vol)
		},
		changeTimeAndPlay(seconds) {
			this.sayDev("🔥 CTAP " + seconds)
      if (!this.played) {
        this.startTime = seconds
        this.playFromStart()
        return
      }
			var x = this.changeTime(Math.min(this.playerDuration, Math.max(seconds,0)))
			this.toggleMenu(false)
			this.showCTA = false
			this.notesDialog = false
      this.pausedUntilClick = false
			this.doPreventNavs()
		},
		changeTimeThenTrack: function (seconds, type, value, object) {
			this.sayDev("🔥 CTTT")
			this.changeTime(seconds)
			let x = 0
			let tracked = false
			let timer = setInterval(() => {
        this.sayDev("CTTT Interval")
				if (this.currentTime == seconds) {
					clearInterval(timer)
					if (!tracked) {
						this.trackAction(type, value, object)
						tracked = true
						this.playPlayer("CTTT")
					}
				} else {
					x += 50
					if (x >= 10000) {
						clearInterval(timer)
					}
				}
			}, 50);
		},
		// ccsi
		clearScreenInteractions: function () {
			this.sayDev("💧💧💧 CSI")
			if (this.showMenu){
				this.sayDev('Showing Magic Menu, return')
				return
			}
			// this.resetTimer()
			this.caption = ''
			this.clearLines()
			this.introDialog = false
			this.resumeDialog = false
			this.imageAnnotation = null
			this.drawingAnnotations = null
			this.questionSet = null
			this.questionSetIndex = 0
			this.activeNotes = []
			this.activeHotspot = null
			this.buttonAnnotation = null
			if (!this.isEditing) {
				this.endButton = this.share && this.share.cta && this.isJSON(this.share.cta) ? JSON.parse(this.share.cta) : {}
			}
			this.questionAnnotation = null
		},
		yell(str) {
			if (!this.isProduction) {
				alert(str)
			}
		},
		changeTime: function (seconds) {
      this.seeking = true
			if (!this.player || !this.played) {
				return
			}
			this.sayDev("💘 ChangeTime: " + seconds)
			this.sendChunk(this.currentTime.valueOf())
			this.chunkStart = seconds
			var fixedSeconds = Math.max(seconds, 0)
			this.sendSeek(fixedSeconds);
      this.pausedUntilClick = false
			if (seconds == null) {
				return;
			} else {
        this.sayDev("PUC setting to false in change time")
        this.pausedUntilClick = false
				this.player.currentTime(fixedSeconds);
				if (!this.isMobile) {
					this.focusInput()
				}
				// this.showTooltip = false
			}
			this.clearScreenInteractions()
			if (fixedSeconds == 0 && this.interactionsByTime.questions[0]) {
				var q = this.interactionsByTime.questions[0][0]
				if(q.tray_type != 'ctaTray'){
					this.showSpecificQuestion(q)
				}
				return
			} else if (this.activePausesAtTime(fixedSeconds).length) {
        this.sayDev("Pauses at " + fixedSeconds + " .... try")
        this.tryPause(this.activePausesAtTime(fixedSeconds)[0])
        return false;
      } else if (fixedSeconds == 0 && (!this.ownsPost || this.activeSideItem == 'interactions')) {
				this.hideAllDialogsAndPlay("CT")
			} else {
				this.hideAllDialogs();
			}

      // setTimeout(()=> {
      //   this.sayDev("PUC FALSE changeTime")
      //   this.pausedUntilClick = false
      // }, 1000)


			if (this.isMobile && this.notesDialog) {
				this.notesDialog = false;
				this.playPlayer("CT");
			}

			this.resetVolume();
			if (this.isMobile) {
				$("body").animate({ scrollTop: 0 }, "slow");
			}
			this.prepareMedias()
			this.activeNotes = []
			setTimeout(() => {
				if (this.currentTime == fixedSeconds) {
					this.showStamps()
				} else {
					setTimeout(() => {
						if (this.currentTime == fixedSeconds) {
							this.showStamps()
						}
					}, 300)
				}
			}, 250)
			setTimeout(()=> {
				if (Math.abs(seconds - this.lastPauseTime) > 1) {
					this.lastPauseTime = -1
				}
			}, 1500)
			return true
		},
		closeAudioDialog: function () {
			if (this.audioDialog) {
        this.sayDev("Close Audio Dialog, but must clicks? " + this.hasActiveMustClicks())
				this.audioDialog = false
				if (!this.showingCorrect && !this.pausedUntilClick && !this.hasActiveMustClicks()) {
					this.tryNextQuestion("cad")
				}
			}
			if (this.surfer) {
				this.surfer.pause();
			}
		},
		emitTimeMessage(version) {
			// this.sayDev("🐛 EmitTimeMessage", this.currentTime )
			window.top.postMessage({event: "time_update", version: version, info: { data: {}, title: this.share.title, token: this.share.token, currentTime: this.currentTime, formattedTime: this.formattedSeconds(this.currentTime)}, duration: this.duration, formattedDuration: this.formattedSeconds(this.duration)}, "*");   
		},
		emitDataMessage(data) {
			// this.sayDev("🐛 EmitDataMessage")
      const x = { 
        data: data, 
        title: this.share.title, 
        token: this.share.token, 
        currentTime: this.currentTime, 
        formattedTime: this.formattedSeconds(this.currentTime), 
        duration: this.duration, 
        formattedDuration: this.formattedSeconds(this.duration)
      }
			window.top.postMessage({event: "viewer_interaction", version: 1, info: x}, "*");   

      var z = Object.assign({}, data)
      z.title = this.share.title
      z.token = this.share.token, 
      z.currentTime = this.currentTime, 
      z.formattedTime = this.formattedSeconds(this.currentTime), 
      z.duration = this.duration, 
      z.formattedDuration = this.formattedSeconds(this.duration)
      if (window.fbq) {
        window.fbq('trackCustom', 'Video Interaction', z)
        this.sayDev("TrackCustom", z)
      }
    },
		emitVariableMessage(version, data) {
			this.sayDev("🐛 EmitVariableMessage", data)
			window.top.postMessage({event: "set_variable", version: 1, info: { data: data, title: this.share.title, token: this.share.token, currentTime: this.currentTime, formattedTime: this.formattedSeconds(this.currentTime), duration: this.duration, formattedDuration: this.formattedSeconds(this.duration)}}, "*");   
		},
		emitMessage(str, data={}) {
      var obj = {event: str, version: 1, info: { data: data, title: this.share.title, token: this.share.token, currentTime: this.currentTime, formattedTime: this.formattedSeconds(this.currentTime), duration: this.duration, formattedDuration: this.formattedSeconds(this.duration), variables: this.variables, currentScore: this.getCorrectnessScore(true), viewer: { id: this.viewer_id, name: this.guestName, email: this.guestEmail, custom_id: this.guestID} }}
			this.sayDev("🎩 EmitMessage:")
      this.sayDev(obj)
      // this.sayDev(obj)
      window.top.postMessage(obj, "*");   
		},
		closeVideoDialog: function () {
      // this.sayDev("CloseVideoDialog")
			this.nextMedias.video = {}
			if (this.dialogVideo) {
				this.dialogVideo.dispose()
				this.dialogVideo = null
				this.sayDev("Disposed of video player")
			}
			// this.dialogVideo.src({})
			// this.dialogVideo.load()

			if (this.videoDialog) {
				// var x = document.getElementById('video_dialog_video_html5_api')
				// this.sayDev("⭐️ CloseVideoDialog --> " + x.src)
				// this.dialogVideo.pause()
				// if (!this.isIOS) {
				// 	this.dialogVideo.reset()
				// }
				this.nextMedias.video = {}
				this.videoDialog = false
        if (!this.pausedUntilClick && !this.hasActiveMustClicks()) {
          this.tryNextQuestion("cvd")
        }
			}
			// this.setupDialogVideo()
			this.busy = false
			this.dialogVideoReady = false

		},
		onPlayerWait: function (event) {
			this.playerWaiting = true
		},
    stopTimeHandler() {
      // this.sayDev("🔴 TimeHandler Stopped!")
      clearInterval(this.timeHandler);
      this.timeHandler = null
    },
    startTimeHandler() {
      // this.sayDev("🚔 TimeHandler Started!")
      this.doTimeUpdate()
      clearInterval(this.timeHandler)
      this.timeHandler = setInterval(() => {
        // this.sayDev("TimeHandler")
        if (this.isPlayerPaused()) {
          this.stopTimeHandler()
        }
				this.doTimeUpdate()
			}, Number(this.getUrlParam("tinterval") || 20));
    },
		onPlayerPlay: function (event) {
			// this.sayDev("OPP.....")
      this.cancelSpeech()
      document.getElementById('video-1').style.opacity = 1
      if (document.getElementById('video-1_html5_api')) {
        document.getElementById('video-1_html5_api').style.opacity = 1
      }

			if (this.played && !this.isEmbed && !document.hasFocus()) {
				return
			}
      this.played = true
      
			this.clearLines()

			this.stopTimeHandler()
      this.startTimeHandler()

			this.divsActive.forEach((n)=> {
				if (n.pointPath) {
					this.startPointAnimation(n)
				}
			})

			this.hideSeekbar("OPP")
			this.resetTimer();
			this.resetVolume();
			this.isChoosingAnswer = false
			this.drawingAnnotations = null
			this.playerPaused = false
			if (!this.editingDialog) {
				this.hideAllDialogs();
			} else {
				this.pausePlayer("OPP");
				return
			}
			if (!this.isMobile) {
				this.focusInput()
			}
			setTimeout(()=> {
				this.prepareMedias()
        this.hideLoader = false
        this.startAfkTimer()
			}, 500)
			this.emitMessage('video_play')
      this.playerPaused = false
			
		},
		onPlayerPause: function (event) {
			this.hideLoader = true

			this.playerPaused = true
			// this.sayDev(this.currentTime + " ---- " + this.player.currentTime())
      if (this.currentTime % this.progressInterval != 0) {
        this.saveProgress(this.currentTime.valueOf())
      }
			this.stopVolumeInterval();
			this.playerWaiting = false
			if (this.authDialog) {
				if (this.isProduction) {
					this.player.currentTime(this.currentTime)
				}
			}
			setTimeout(() => {
				if (this.questionAnnotation) {
					this.lastPauseTime = this.questionAnnotation.time
				} else {
					this.lastPauseTime = this.currentTime
				}
				this.hideLoader = false
			}, 500)
			this.emitMessage('video_pause')
			// this.trackGtagEvent('Paused Player', this.share.title, 'Video Player Events');
		},
		onPlayerEnded: function (event) {
			this.sayDev("🔴 ON PLAYER ENDED", this.dialogShowing)
			this.sendChunk(this.currentTime.valueOf())
			this.saveChunks(true)
      this.pausePlayer("onEnded")
			this.trackGtagEvent('Finished Video', this.share.title, 'Video Player Events');
			this.lastPauseTime = -1
			
			this.emitMessage('video_end')
			if (this.dialogShowing || (this.showCTA && this.endButton.action != 'custom')) {
				this.sayDev("✨ DialogShowing, return")
				return
			}

			if (this.interactionsByTime.questions[this.duration] && !this.showCtaTrayNext && (this.firstCtaTrayQuestion && this.interactionsByTime.questions[this.duration][0].id != this.firstCtaTrayQuestion.id)) {
				this.showCtaTrayNext = true
				this.showSpecificQuestion(this.interactionsByTime.questions[this.duration][0])
				return
			} else {
				this.showCtaTrayNext = false
			}
      this.sayDev("stop1")
			var navs = this.interactionsByTime.navigations[this.duration]
			if (navs && navs.length) {
				this.sayDev("navigations to show at duration!")
				navs.forEach((x)=> {
					if (x.type_of == 'variable') {
						var z = JSON.parse(x.data)
						this.setVariable(x.variable, x.value)
					} else if (x.type_of == 'pause') {
						this.tryPause(x)
					} else {
						this.clickData(JSON.parse(x.data), x, true)
					}
				})
				return
			}
      this.sayDev("stop2")
			if (this.share.cta && this.isJSON(this.share.cta)) {
				this.selectedAnnotations = []
				var x = JSON.parse(this.share.cta)
				if (x && x.action == 'none') {
          this.sayDev("stop3 " + this.deciTime)
          this.hideSeekbarImmediately()
					this.pausePlayer("OPE1")
					return
				}
				if (x) {
					if (x.action == 'redirect') {
						this.sayDev("Redirect: " + x.value)
						if (this.currentUser) {
							this.$toasted.show("Skipping end link redirect for video owner - use preview mode to see!")
              this.sayDev("❌ Showing ENDCTA from redirect")
							this.showCTA = true
              this.pausePlayer("OPE2")
							return
						} 
            this.doRedirect(x.value)
						return
					} else if (x.action == 'video_auto') {
						this.sayDev("Switch: " + x.value)
						if (this.currentUser) {
							this.$toasted.show("Skipping end video switch for video owner - use preview mode to see!")
              this.sayDev("❌ Showing ENDCTA from switch")
							this.showCTA = true
              this.pausePlayer("OPE3")
							return
						}
						this.referVideoStack.unshift(this.share.token)
						this.referTimeStack.unshift(this.currentTime.valueOf())
						this.changeVideo(x.value.split("---")[1], x.switch_time || 0)
						return
					} else if (x.action == 'back_auto') {
						this.sayDev("Switch: " + x.value)
						if (this.currentUser) {
							this.$toasted.show("Skipping end video switch for video owner - use preview mode to see!")
              this.sayDev("❌ Showing ENDCTA from back_auto")
							this.showCTA = true
              this.pausePlayer("OPE4")
							return
						}
						if (this.referVideoStack.length > 0){
							this.changeVideo(this.referVideoStack.shift(), this.referTimeStack.shift())
						}
						return
					}
				}
				if (x.text || x.message) {
					this.endButton = {
						message: x.message,
						content: x.text,
						text: x.text,
						value: x.value,
						type_of: 'button',
						action: x.action,
						extra: x.value,
						time: this.currentTime,
            switch_time: x.switch_time || 0
					}
					if (x.action == 'custom' && this.firstCtaTrayQuestion) {
						setTimeout(() => {
							this.showSpecificQuestion(this.firstCtaTrayQuestion)		
              this.sayDev("❌ Showing ENDCTA from sssq")
              if (this.ownsPost && !this.showCTA) {
                this.setSideItemTitle("End Screen")
              }
              this.showCTA = true
						}, 100)
					} else {
            this.sayDev("❌ Showing ENDCTA from custom")
            if (this.ownsPost && !this.showCTA) {
              this.setSideItemTitle("End Screen")
            }
						this.showCTA = true
					}
          
				}
			} else {
			}
		},
		onPlayerWaiting: function (event) {
		},
		onPlayerLoadedData: function (event) {
		},
		onPlayerTimeUpdate: function (event) {
			if (this.player) {
				this.playerWaiting = false
        if (!this.timeHandler) {
          this.startTimeHandler()
        }
				// if (this.isPlayerPaused()) {
				// 	this.doTimeUpdate()
				// }
			}
		},
    doEndEvent(){
      setTimeout(()=> {
        if (this.gaProgressLog.includes(100)) { return }
        if (!this.showCTA && this.player.currentTime() < this.player.duration()) {
          this.sayDev("Not fully ended, return..." + this.player.currentTime() + " / " + this.player.duration())
          return
        } else {
          this.sayDev("CT: " + this.player.currentTime() + " --> D: " + this.player.duration() )
        }
        this.sayDev("🧿 DoEndEvent")

        this.emitMessage("progress_100")
        this.gaProgressLog.push(100)
        // this.progressPoints['100'] = true
        this.trackGtagEvent('Reached 100%', this.share.title, 'Video Player Events');
      }, 500)
    },

		focusExtra: function () {
			this.popoverBusy = true
			setTimeout(() => {
				$('#extraInput').focus()
			}, 250)
		},
		focusInput: function () {
			if (!this.isMobile && !this.isEmbed && !this.chaptersDialog && !this.isFullscreen && !this.notesDialog && !this.isEditing) {
				if (this.isSearching()) {
					return
				}
				setTimeout(function () {
					$('#message_input').focus();
				}, 250);
			}

			if (this.isMobile && this.isTouchDevice) {
				setTimeout(() => {
				}, 500);
			}
		},
		getAnnotationText: function (stamp) {
			var text = ""
			var stampAv = this.safeStyles.branding.avatar
			if (!stamp.isVideoOwner) {
				stampAv = stamp.avatar || this.guestAvatar
			}
			if (stamp.type_of == "video") {
				text = "Video" 
				// text = ""
				if (stamp.extra && stamp.extra != "Video Clip") {
					text = stamp.extra
				}
			} else if (stamp.type_of == "button") {
				text = 'Button: ' + stamp.content
				text = stamp.content
			}
			else if (stamp.type_of == "audio") {
				text = stamp.extra || "Audio"
			} else if (stamp.type_of == "question") {
				text = this.trimmedContent(stamp.extra ? stamp.extra : stamp.content ? stamp.content.prompt : '', 8)
				let x = this.interactionsByTime.questions[stamp.time]
				if (x && x.length > 1) {
					text = x.length + " Questions"
				}
			} else if (stamp.type_of == "question") {
				text = " Question: "
			} else if (stamp.type_of == "drawing") {
				text = "Drawing"
				text += stamp.extra ? ': ' + stamp.extra : ''
			} else if (stamp.type_of == "hotspot") {
				text = stamp.extra || stamp.value || "Hotspot"
			} else if (stamp.type_of == "card" && stamp.content) {
				text = stamp.content.title.value || "Card"
			} else if (stamp.type_of == "show_card" && stamp.content) {
				text = stamp.content.name || "Card"
			} else if (stamp.type_of == "image") {
				text = "Image"
				if (stamp.extra || stamp.value) {
					text += " - "
					text = stamp.extra || stamp.value
				}
			} else if (stamp.type_of == "pause" || stamp.type_of == "jump" || stamp.type_of == "switch" || stamp.type_of == "redirect") {
				text = stamp.type_of == 'redirect' ? 'Redirect' : stamp.content
			} else if (stamp.type_of == "text" || stamp.type_of == "comment") {
				text = stamp.content
			}

			else {
				text = this.trimmedContent(stamp.content, 8)
			}
			var str = ''
			if (this.hasAnsweredQuestion(stamp)) {
				str = "Answered: "
			}

			stampAv = this.getInteractionIcon(stamp.type_of, 'white')

      var percent = Math.round(100*Number(stamp.exact_time) / Number(this.duration))
			var result = {
				type_of: stamp.type_of,
				text: "<span class='marker-" + stamp.exact_time + "'><img class='marker_avatar' src='" + stampAv + "'></img>" + text + "</span>",
				markerString: this.formattedSeconds(stamp.exact_time) + " - " + text,
				time: stamp.time,
				offset: Math.max(0, Math.min(99, percent)),
				avatar: this.hasAnsweredQuestion(stamp) ? 'https://resource-cdn.mindstamp.com/assets/streamline-icons/check-badge-white.png' : stampAv,
				annotation: stamp,
				isNote: stamp.type_of == 'comment',
				isUserImage: stampAv == stamp.avatar,
				completed: this.hasAnsweredQuestion(stamp)
			}
			return result
		},


		resetMarkers: function () {
      // this.sayDev("Reset Markers")
			gon.markers = []
			this.markers = [];
			var usedSeconds = []
			for (var i = this.filteredAnnotations.length - 1; i >= 0; i--) {
				var x = this.filteredAnnotations[i]
				if (!x.hidden && !x.invisible && usedSeconds.indexOf(x["time"]) < 0 && x["type_of"] != 'click' && x["type_of"] != 'variable' && !["resourceTray", "ctaTray"].includes(x["tray_type"])) {
					gon.markers.push(this.getAnnotationText(this.filteredAnnotations[i]))
					// usedSeconds.push(x["time"])
				} 
			}
			this.markers = gon.markers
      this.checkSeekbar(true)
		},
		toggleMute: function () {
			this.soundButton = false
			this.sayDev("🤐 TM: " + this.isMuted + " --> " + !this.isMuted)
			this.showTooltip = false
			this.isMuted = !this.isMuted
			if (this.isMuted && this.player) {
				this.tryTrack("Muted Player", { url: window.location.href })
				this.volumeLevel = 0
				document.cookie = "muted=1";
			} else {
				this.tryTrack("Un-Muted Player", { url: window.location.href })
				this.volumeLevel = this.isTouchDevice ? 1 : 0.5
				document.cookie = "muted=0";
			}
		},

		restart: function () {
      this.setSideItemTitle("Interactions")
      this.sayDev("💥 Restart ")
			if (!this.ownsPost) {
				this.resetVariables()
				this.fetchInteractions()
			}
			this.clearScreenInteractions()
			this.showCTA = false
			this.toggleMenu(false)
			this.deciTime = 0
			this.changeTime(0)
			if (this.share.force_session_reset) {
				this.clearSessionStorage()
			}
			// this.playPlayer("RESTART");
			console.log('restart')
			this.playFromStart()
			// this.tryTrack("Restarted Player from End Screen", { url: window.location.href })
		},
    playIntroClip: function() {
      console.log("playIntroClip")
      this.introPlayer.currentTime(0)
      this.introPlayer.muted(false)
      this.introPlayer.play();
      this.introClipStarted = true
      this.introPlayer.on('ended', () => {
        if (!window.location.href.includes("opacity=0")) {
          document.getElementById('video-1_html5_api').style.opacity = 1
          document.getElementById('video-1').style.opacity = 1
          if (document.getElementById('videoPoster')) {
            document.getElementById('videoPoster').style.opacity = 1
          }
        }
        console.log("Ended!")
        this.introClipDialog = false
        this.playFromStart()
      })
    },
		playFromStart: function () {
			this.sayDev("PlayFromStart")
      if (this.fetchingResumable) {
        return
      }
      if (!this.isProduction && window.location.href.includes("doerror")) {
        setTimeout(()=> {
          this.sayDev("Trigger Error")
          if (this.player) {
            this.player.src("frefrefefe")
          }
        }, 2000)
      }
      if (this.introClipDialog) {
        this.introDialog = false;
        this.playIntroClip()
        return
      }
      this.startTimeHandler()
			if (this.lastPlay && !this.playID && !this.isPreview) {
				// this.sayDev("Do continue dialog......")
				this.introDialog = false
				this.continueDialog = true
			}
			if (this.isEditing) {
				this.$toasted.show("Save your changes before playing!")
				this.flashFocusField('saveEdit')
				return
			}
			// this.sayDev("Should Auth: " + this.shouldAuth)
			if (this.shouldAuth && !this.skipAuth && (this.share.capture_auth_point == 0 || this.captureAuthStart)) {
				this.sayDev("Should capture beginning")
				this.introDialog = false
				this.authDialog = true
				this.pausePlayer("PFS")
        this.deciTime = 0.0
        this.currentTime = 0
				return
			}
			// this.sayDev("Should Pay: " + this.shouldPay)
			if (this.shouldPay && (this.share.capture_payment_point == 0)) {
				this.sayDev("Should capture beginning")
				this.introDialog = false
				this.payDialog = true
				this.pausePlayer("PFS2")
				return
			}
			// this.sayDev("FS: " + this.variables.fullscreen)
			if (this.variables.fullscreen && !this.ownsPost) {
				this.toggleFullscreen()
			}
      if (this.isMuted && this.getCookie("muted").toString() == "0") {
        this.toggleMute()
      }
			this.introDialog = false
			this.firstVideoDialog = false
      if (this.pausedUntilClick) {
        this.sayDev("paused until click, return")
        return
      }
			var t = this.deciTime == 0 ? '0.0' : this.deciTime
      this.sayDev("T: " + t)
      this.doAllStamps(this.dactions[t] || [])
      if (this.startTime && this.startTime > 0) {
        this.sayDev("StartTime: " + this.startTime)
        this.player.currentTime(this.startTime)
        this.deciTime = this.startTime
      }
      if (!this.pausedUntilClick) {
        // if (this.isSafari && !this.isSafariOrIOSorIpad) {
        //   this.player.currentTime(0.1)
        // }
        // if (!this.startTime) {
        //   this.player.currentTime(0.1)
        // }
        this.playPlayer("PlayFromStart");
        setTimeout(() => {
          this.showStamps()
          if (!this.showGuide) {
            this.hideSeekbarImmediately()
          } else {
            this.mouseMove()
          }
          if (this.isIOS) {
            setTimeout(() => {
              this.handleOrientationChange()
            }, 500);
          }
        }, 10);
			}
      if (this.variables.fullscreen) {
        this.toggleFullscreen()
      }
			if (!window.already) {
				setTimeout(() => {
					if (this.isVimeo && this.currentTime == 0) {
						this.$toasted.show('Please click video again to play 👇🏼')
						window.already = true
					}
				}, 7500);
			}
			setTimeout(() => {
				if (this.currentTime == 0) {
					if (this.isYoutube) {
						let x = $('.video-stream')
						if (x && x[0]) {
							x[0].play()
						} else {
						}
					}
				}
			}, 2500);
		},


		trimmedContent: function (desc, words) {
			if (desc && desc != "") {
				var chunks = (desc + " ").split(/\s+/)
				if (chunks.length > words) {
					return chunks.slice(0, words).join(" ") + "..."
				} else {
					return desc
				}
			}
			return ""
		},


		passAuth: function () {
			this.skipAuth = true
			this.replyAnnotationID = null
			this.finishAuth()
		},
		setNameFromUrl: function () {
			if (this.getUrlParam("name")) {
				this.guestName = this.getUrlParam("name").trim()
			}
		},
		updateCustomVICVariables(){
			var types = this.getCustomVICTypes();
			Object.keys(this.viewerVariables).forEach(v =>{
				if(this.viewerVariables[v.toLowerCase()] != null){
					var value = this.viewerVariables[v]
					var type = types[v] 
					if (type == "date"){
						value = this.getFormattedUTCDate(value)
					}
					this.setVariable(v, value)
          if (!this.share.force_session_reset) {
            this.setLocalStorageItem(v, value)
          }
				}
			})
		},
		getCustomVICTypes(){
			return this.share.captureCustomFields.reduce((obj, item) => (obj[item.name] = item.type, obj) ,{});
		},
		authReady(){
			var pass = true
			this.updateCustomVICVariables()
      this.sayDev("REQUIRED CAPTURE FIELDS")
      this.sayDev(this.currentShare.requiredCaptureFields)
			this.currentShare.captureCustomFields.forEach(x=>{
        this.sayDev("Check field: " + x.name + " (Skip --> " + this.skipAuth)
        this.sayDev("VV")
        this.sayDev(this.viewerVariables)
				if (x.name == 'name'){
					this.guestName = this.viewerVariables[x.name.toLowerCase()] || this.variables[x.name.toLowerCase()]
				} else if (x.name == 'email'){
					this.guestEmail = this.viewerVariables[x.name.toLowerCase()] || this.variables[x.name.toLowerCase()]
          this.sayDev("Check email " + this.guestEmail)
          if (this.guestEmail.length && !this.isValidEmail(this.guestEmail)) {
            pass = false
            this.makeFieldRed('auth' + x.name)
          }
					if (this.share.captureRequired && !this.isValidEmail(this.guestEmail) && this.currentShare.requiredCaptureFields.includes("email")){
						pass = false
						this.makeFieldRed('auth' + x.name)
					}
				} else if (x.name == 'phone'){
					this.guestPhone = this.viewerVariables[x.name.toLowerCase()] || this.variables[x.name.toLowerCase()]
					if (this.share.captureRequired && !this.isValidPhone(this.guestPhone) && this.currentShare.requiredCaptureFields.includes("phone")){
						pass = false
						this.makeFieldRed('auth' + x.name)
					}
				} else if (x.name =='custom_id'){
					this.guestID = this.viewerVariables[x.name] || this.variables[x.name.toLowerCase()]
				}
        const isRequired = this.currentShare.requiredCaptureFields.some(item => item.toLowerCase() === x.name.toLowerCase());
        const haveVar = this.viewerVariables[x.name.toLowerCase()] || this.variables[x.name.toLowerCase()] 

        if (this.share.captureRequired && !this.skipAuth && isRequired && !haveVar){
					this.makeFieldRed('auth' + x.name)
					this.sayDev('🛑 Need', x.name)
					pass = false
				}
			})
			this.sayDev("Auth Ready: " + pass)
			return pass
		},
		makeFieldRed(id){
			var el = document.getElementById(id)
			el.style.setProperty('border', 'solid red 3px', 'important');
			setTimeout(()=>{
				el.style.removeProperty('border');
			},2500)
		},
		setCustomViewerVariables(info=false){
      if (!this.share) {
        this.sayDev("No share for SCVV")
        return
      }
			if (this.share.captureCustomFields && this.share.captureCustomFields.length > 0) {
        // this.sayDev("Doing custom fields.....")
				if(!this.viewerVariables){
					this.viewerVariables = {}
				}
				if (!this.captureName && !this.captureEmail && !this.capturePhone && !this.captureID &&
					 this.share.captureCustomFields.length == 1 && this.share.captureCustomFields[0].type == 'checkbox'){
					info = true
				}
				this.share.captureCustomFields.forEach(f =>{
					if(this.getUrlParam(f.name)){
						this.viewerVariables[f.name.toLowerCase()] = this.getUrlParam(f.name)
					} else if(this.lsTest() && localStorage && localStorage[f.name] && localStorage[f.name] != 'undefined'){
						this.viewerVariables[f.name.toLowerCase()] = localStorage[f.name]
					}
					if (f.type == 'date' && this.viewerVariables[f.name]){
						this.viewerVariables[f.name.toLowerCase()] = this.getUnformattedUTCDate(this.viewerVariables[f.name])
					}
					if (!this.viewerVariables[f.name.toLowerCase()]){
						this.viewerVariables[f.name.toLowerCase()] = ''
						if(f.type != 'checkbox'){
							info = true
						}
					}
					if (this.lsTest()) {
						this.setLocalStorageItem(f.name, this.viewerVariables[f.name])
					}
				})
				// this.sayDev("🔭 Viewer Variables", this.viewerVariables)
				// this.sayDev("🪐 All Variables", this.variables)
			}
			return info;
		},
		finishAuth: function () {
			// this.sayDev("Finish Auth --> " + this.skipAuth + " / " + this.authReady())
			if (!this.authReady()) {
        // this.sayDev("not ready, return...")
				return
			}
			if (this.skipAuth || !this.share.captureRequired) {
				this.authDialog = false
        
        if (!this.pausedUntilClick) {
          if (this.played) {
            this.hideAllDialogsAndPlay("FA")
          } else {
            this.playFromStart()
          }
        }
			}
      if (this.variables.autoplay && this.isMuted) {
        this.toggleMute()
      }
			this.skipAuth = true
			this.guestName = this.makeHtmlSafe(this.guestName).trim()
      if (!this.guestName.length && this.share.captureName) {
        // this.$toasted.show("Please enter your name")
        return
      }
			this.sayDev(this.guestName + " .... " + this.guestEmail + " ..... " + this.guestID + " ..... " + this.guestPhone)
			this.authDialog = false
			var ls = this.lsTest()
      if (!this.guestName && this.variables["first_name"]) {
        this.sayDev("No guestname, do combo!")
        this.guestName = this.variables["first_name"] + " " + this.variables["last_name"]
        this.guestName = this.guestName.trim()
      }
      if (!this.guestName && this.variables["full_name"]) {
        this.sayDev("No guestname, do full_name!")
        this.guestName = this.variables["full_name"]
        this.guestName = this.guestName.trim()
      }
      this.sayDev("GN: " + this.guestName)
			if (this.guestName) {
				if (ls) {
					localStorage.setItem('guestName', this.guestName);
				}
				this.variables["name"] = this.guestName
			} 
			if (this.guestEmail) {
				this.guestEmail = this.guestEmail.trim()
				if (ls){
					localStorage.setItem('guestEmail', this.guestEmail.trim());
				}
				this.variables["email"] = this.guestEmail.replace("mailto:", "").trim()
			}
			if (this.guestID) {
				if (ls){
					localStorage.setItem('guestID', this.guestID.trim());
				}
				this.variables["custom_id"] = this.guestID.trim()
			}
			if (this.guestPhone) {
				if(ls){
					localStorage.setItem('guestPhone', this.guestPhone.trim());
				}
				this.variables["phone"] = this.guestPhone.trim()
			}
      if ((this.guestName && !gon.guestName) || (this.guestEmail && !gon.guestEmail) || (this.guestPhone && !gon.guestPhone)) {
        this.sayDev("New User, track")
        if (window.fbq) {
          const z = {
            // name: this.guestName,
            // email: this.guestEmail,
            // phone: this.guestPhone
            viewer_id: this.viewerID,
          }
          this.sayDev("TrackCustom Lead Capture",)
          window.fbq('trackCustom', 'Lead Captured')
        }
      }
			if (this.isPreview && ls) {
				this.sayDev("Remove names preview")
				localStorage.removeItem("guestName");
				localStorage.removeItem("guestEmail");
				localStorage.removeItem("guestID");
				localStorage.removeItem("guestPhone");
				if (this.viewerVariables && this.viewerVariables.length > 0){
					Object.keys(this.viewerVariables).forEach(v => {
						localStorage.removeItem(v)
					})
				}
			}
			this.updateGtag()
      this.sayDev("FinishAuth fetchInteractions...")
			this.fetchInteractions()
      setTimeout(()=> {
        this.saveProgress()
      }, 2000)


			if (this.questionAnnotation) {
        this.sayDev("QA after auth....")
				if (this.authReturn) {
					// this.po(this.authReturn)
          this.sayDev(this.authReturn)
					if (this.authReturn.type == 'choose') {
						this.clickAnswer(this.authReturn.value)
					} else if (this.authReturn.type == 'reply') {
						this.submitReplyMessage()
					} else if (this.authReturn.type == 'draw') {
						this.answerDrawQuestion(this.questionAnnotation)
					} else if (this.authReturn.type == 'video') {
						this.answerVideoQuestion(this.questionAnnotation)
					} else if (this.authReturn.type == 'voice') {
						this.answerVoiceQuestion(this.questionAnnotation)
					} else if (this.authReturn.type == 'image') {
						this.submitReplyMessage()
					}
				}
			} else if (this.message) {
        this.sayDev("post auth message")
				this.saveGuestStamp();
			} else if (this.waitingAction) {
        this.sayDev("post auth waiting action")
				this.finishAction()
			} else if (!this.played || this.currentTime == 0) {
				this.sayDev("FA-1")
				this.playFromStart()
				return
			} else if (!this.dialogShowing) {
				this.sayDev("FA-2")
				this.hideAllDialogsAndPlay("FA2")
			}


			this.tryTrack("Captured New Contact", {
				url: window.location.href, contact: {
					name: this.guestName || '',
					email: this.guestEmail || '',
					phone: this.guestPhone || ''
				}
			})
			if(this.shouldPay){
				this.showPayDialog()
			}
		},

		saveGuestStamp: function () {
			if (this.replyDialog || this.questionDialog) {
				this.submitReplyMessage();
			} else {
				this.submitMessage();
			}
		},

		doShallowStamp: function () {
			this.skipAuth = true
			var co
			ntent = this.sanitizedMessage

			if (this.replyDialog || this.questionDialog) {
				content = this.sanitizeString(this.replyMessage)
			}

			if (content.length === 0) {
				this.hideAllDialogsAndPlay("DSS")
				return
			}

			var theObject = {
				content: content,
				type_of: "comment",
				time: this.currentTime,
				avatar: "https://resource-cdn.mindstamp.com/assets/color_whitebg.jpeg",
				username: "You (Guest)",
				guest_name: "You",
				id: Math.floor(Math.random() * 900000) + 100000, // Some uniqueness sheeet,
				owner: true
			}
			// this.guestName = "You"
			this.handleNewAnnotation(theObject)
			this.questionAnnotation = null;
			this.replyMessage = ""
			this.message = ""
			this.hideAllDialogs()
		},

		handleMediaAnnotation: function (a) {
			this.closeVideoDialog();
			this.closeAudioDialog();
			this.handleNewAnnotation(a);
		},
		handleLikert(val) {
			var x = this.variables.LikertScore || 0
			x = parseInt(x)
			switch (val) {
				case 'strongly agree':
					x += 5
					break;
				case 'agree':
					x += 4
					break;
				case 'neither agree nor disagree':
					x += 3
					break;
				case 'disagree':
					x += 2
					break;
				case 'strongly disagree':
					x += 1
					break;
				default:
					break
			}
			this.setVariable("LikertScore", x)
		},
		clearSessionStorage() {
			this.sayDev("Clear Session Storage")
			this.sayDev("Remove names preview")
			this.skipAuth = false
			this.guestName = null
			this.guestEmail = null
			this.guestID = null
			this.guestPhone = null
			localStorage.removeItem("guestName");
			localStorage.removeItem("guestEmail");
			localStorage.removeItem("guestID");
			localStorage.removeItem("guestPhone");
      localStorage.removeItem("viewer_id")
      localStorage.removeItem("MS_Variables")
      localStorage.removeItem("MS_Plays")
			this.share.captureCustomFields.forEach(f => {
				localStorage.removeItem(f.name);
				if (f.name in this.variables){
					delete this.variables[f.name]
				}
				if (f.name in this.viewerVariables) {
					delete this.viewerVariables[f.name]
				}
			})
			this.variables = {}
		},
		setVariable(variable, value) {
      if (!variable || value == null) {
        // this.sayDev("Empty var, return....")
        return
      }
      if (this.variables[variable.toLowerCase()] == value.toString()) {
        this.sayDev("Variable already set, skip! (" +variable+" = " + value+")")
        return
      }
			this.sayDev("\n🌟🌟🌟 SetVariable: " + variable.toLowerCase() + " --> " + value)
			if ((value && !value.toString().includes("{{")) || value == 0) {
				this.variables[variable.toLowerCase()] = value.toString()
				this.emitVariableMessage(1, {
					name: variable.toLowerCase(),
					value: this.variables[variable.toLowerCase()]
				})
			} else if (value) {
				value = value.replaceAll('{{', '').replaceAll('}}','')
				var tokens = value.match(/['+\-]|[^+\-\s]+/g)
				var complex = 0
				var result = ''
				tokens.forEach(t => {
					if (!['+','-','"'].includes(t[0]) && !parseInt(t)){
						complex++
						result = this.variables[variable.toLowerCase()]
						if (!isNaN(result)){
							result = Number (result)
						}
					}
				})
				if (complex <= 1){
					var op = ''
					tokens.forEach(t => {
						var mathToken = t.replaceAll(" ", "")
						if (['+','-'].includes(mathToken)){
							op = mathToken
						} else if(!isNaN(mathToken) && (!isNaN(result) || result == '' || result == null)){
							if (op == '+'){
								if (result == '' || result == null ){
									result = 0
								}
								result = parseFloat(result) + parseFloat(mathToken)
							} else if(op =='-'){
								if (result == '' || result == null ){
									result = 0
								}
								result = parseFloat(result) - parseFloat(mathToken)
							}
						} else if(op == '+'){
							if (!result){
								result = ""
							}
							result += t.replaceAll('"','')
						}
					})
					this.variables[variable.toLowerCase()] = result.toString()
					this.emitVariableMessage(1, {
						name: variable.toLowerCase(),
						value: this.variables[variable.toLowerCase()]
					})
					this.finishSetVariable(variable, result)
				} else {
					// Get new value of variable
          this.sayDev("🥏 Do Mindscript", tokens) 
					Rails.ajax({
						url: '/mindscript?mindscript_value=' + encodeURIComponent(value)+"&"+this.getFullInteractionParams(),
						type: "GET",
						dataType: "JSON",
						success: (response) => {
							this.variables[variable.toLowerCase()] = response.data.toString()
							this.emitVariableMessage(1, {
								name: variable.toLowerCase(),
								value: this.variables[variable.toLowerCase()]
							})
							this.finishSetVariable(variable, value)
						}
					});
				}
				return
			}
			if (variable == "name" && value) {
				this.guestName = value
				if (this.lsTest()) {
					localStorage.setItem('guestName', this.guestName);
				}
			} else if (variable == "email" && value) {
				this.guestEmail = value
				if (this.lsTest()) {
					localStorage.setItem('guestEmail', this.guestEmail);
				}
			} else if (variable == "custom_id" && value) {
				this.guestID = value
				this.sayDev("Set guest id...")
				if (this.lsTest()) {
					localStorage.setItem('guestID', this.guestID);
				}
			} else if (variable == "phone" && value) {
				this.guestPhone = value
				if (this.lsTest()) {
					localStorage.setItem('guestPhone', this.guestPhone);
				}
			} 
			this.finishSetVariable(variable, value)
		},
		finishSetVariable(variable, value) {
      this.sayDev("Finish Set Variable: " + variable + " --> " + value + " (" + this.dynamicCount + " Dynamic)")
			if(false && this.lsTest()){
				var v = JSON.parse(localStorage.getItem('MS_Variables')) || {}
				v[variable] = value
				var msVars = {}
				Object.keys(v).forEach(x => {
					if (!this.skipVars.includes(x)){
						msVars[x] = v[x]
					}
				})
				localStorage.setItem('MS_Variables', JSON.stringify(msVars))
			}
      if (!this.skipVars.includes(variable)){
        this.setVisibleAnnotations("FinishSetVariable")
        this.filteredAnnotations = this.visibleAnnotations
			  this.setInteractionsByTime()
        this.preloadSwitches()
        setTimeout(()=> {
          if (this.dynamicCount > 0 && !this.ownsPost) {
            this.sayDev("👍🏼 Has dynamic, do interactions")
            this.fetchInteractions()
          } else {
            // this.sayDev("❄️ No dynamic, no interactions", this.switchingVideo)
          }
        }, this.switchingVideo ? 1500 : 10)
        this.setDactions()
      }
			if (!this.isProduction) {
				this.po(this.variables)
			}
			// if (this.questionSet) {
			// 	this.questionSet = this.interactionsByTime.questions[this.currentTime]
      //   this.sayDev("Updated Question Set in FinishSetVariable", this.questionSet)
			// }
		},
		handleNewAnnotation: function (ann) {
			this.sayDev("✨ HNA", ann)
			var ra = this.replyAnnotation && this.replyAnnotation.id ? Object.assign({}, this.replyAnnotation) : false
      if (ra && this.replyAnnotation) {
        ra.content = Object.assign({}, this.replyAnnotation.content)
      }
			var isc = false
			var card = null
			if (ann.content && this.isJSON(ann.content)){
				isc = true
				card = JSON.parse(ann.content)
			}
			var isq = ra && ra.type_of == 'question'
			var isc = ann.type_of == 'card'
			var hca = isq && ra && ra.content.correct_answer && ra.content.correct_answer != ''
      
			var theObject = {
				interaction_id: ann.id,
				interaction_type: ann.type_of, 
				interaction_value: ann.content,
				parent_id: ann.parent_id,
				parent_type: isc ? 'card' : ra && ra.type_of,
				parent_value: this.removeHTML(ann.parent_content || (isq && ra && ra.content ? ra.content.prompt : isc ? ann.content.name : ra && ra.content)),
				question_style: isq && ra && ra.content.style || null,
				has_correct_answer: hca ? true : false,
				answered_correctly: !hca ? null : ann.correct,
        internal_label: ra ? ra.internal_label : ann.parent ? ann.parent.internal_label : ""
			}
			this.po(theObject, "🎾 TheObject")
			this.doInteractionMessage(1, theObject)
      this.startAfkTimer()

      this.sayDev(ann.token, this.share.token)
      if (ann.token != this.share.token) {
        this.sayDev("🥂 OLD ANNOTATION")
        return
      }
      
      if (!this.ownsPost) {
        this.userInteractions.push(ann)
        this.sayDev("New Must Click Divs: ", this.mustClickDivs)
        this.sayDev("UserInteractions", this.userInteractions)
        if (this.correctCount + this.incorrectCount > 0) {
          this.sayDev("Correct / Current: " + this.getCorrectnessScore() + " / "  + this.variables["cscore"])
          if (this.getCorrectnessScore() != this.variables["cscore"]) {
            this.setVariable("cscore", this.getCorrectnessScore())
            this.sayDev("cscore --> ", this.variables["cscore"])
          }
          
          if (this.getParticipationScore() != this.variables["pscore"]) {
            this.setVariable("pscore", this.getParticipationScore())
            this.sayDev("pscore --> ", this.variables["pscore"])
          }
				}
        // why not allow all to be marked as clicked?
        if (true || ann.type_of == 'click') {
          this.annotations.forEach((a)=> {
            if (ann.parent_id == a.id && a.must_click && (a.post_click_state == 'disabled' || a.post_click_state == 'hidden')) {
              this.sayDev("Found must click with PC " + a.post_click_state)
              if (a.post_click_state == 'disabled') {
                this.disableInteraction(a)
              } else if (a.post_click_state == 'hidden') {
                this.hideInteraction(a)
              }
            }
          })
        }
      }
			// this.sayDev("RA!!!!", this.replyAnnotation)
			// this.sayDev("ReplyAnnotation....", this.replyAnnotation)
			if (this.ownsPost && (ann.dynamic || ann.variable || ann.conditional_variable) && !this.warnedDynamic) {
				setTimeout(() => {
					this.$toasted.show("Nice! You're using personalization, variables, or conditional logic. Remember that you'll need to use <span style='color:lightgreen; display: contents'>Preview Mode</span> to see replacement take place.", { duration: 8000 })
				}, 1500)
				this.warnedDynamic = true
			}
			this.replyVariable = null
			this.replyVariableValue = null

			let data = ann
			switch (data.type_of) {
				case 'comment':
					this.tryTrack('Added Text Note', data)
					break;
				case 'reply':
					break;
				case 'click':
					this.tryTrack('Clicked Button', data)
					// this.flashBrain();
					break;
				case 'image':
					this.tryTrack('Added Image', data)
					this.$toasted.show('Image Saved ✔')
					this.imageAnnotation = data
					break;
				case 'video':
					this.tryTrack('Added Video Note', data)
					this.$toasted.show('Video Saved ✔')
					this.closeRecordVideoDialog()
					break;
				case 'button':
					this.tryTrack('Added Button Note', data)
					this.$toasted.show('Button Saved ✔')
					break;
				case 'card':
					this.tryTrack('Added Card Note', data)
					this.$toasted.show('Card Saved ✔')
					break;
				case 'drawing':
					this.tryTrack('Added Drawing', data)
					this.$toasted.show('Drawing Saved ✔')
					break;
				// case 'drawing':
				// 	this.tryTrack('Added Text', data)
				// 	this.$toasted.show('Text Saved ✔')
				// 	break;
				case 'audio':
					this.tryTrack('Added Voice Note', data)
					this.$toasted.show('Voice Saved ✔')
					this.closeRecordAudioDialog()
					break;
				case 'question':
					this.tryTrack('Added Question', data)
					// this.$toasted.show('Question Saved ✔')
					break;
				default:
					this.$toasted.show('Saved ✔')
			}

			this.sendChunk(this.currentTime.valueOf())


			if (ann.parent_id) {
				// this.sayDev("Push to replies")
				this.annotationReplies.push(ann)
				this.replyDialog = false
				if (this.collectPrompt && ann.type_of != 'click') {
					this.collectPrompt = null
					this.clearReplyAnnotation()
					this.playPlayer("HNA")
				}
			} else if (ann.type_of != 'click') {
        ann.style = this.getActiveDivStyle(ann)
				// this.sayDev("Push to parent interactions")
				this.annotations.push(ann)
				this.clearReplyAnnotation()
			}

			this.resetMarkers()
			this.updatePointPaths()


			if (ann.type_of != 'question' && this.addAnother) {
        if (this.navDialog) {
          this.activeNav.id = ''
          this.activeNav.variable = ""
          this.activeNav.value = ""
          return
        }
				this.addToActive(ann)
				setTimeout(() => {
					this.createDiv(ann.type_of)
				}, 10)
				return
			}
      if (this.switchingVideo) {
        return
      }

			// this.sayDev("AnnTypeOf: " + ann.type_of)
			if (ann.type_of == "comment") {
        this.playPlayer("HNA Comm")
			} else if (ann.type_of == 'hotspot') {
			} else if (['audio','video', 'card', 'show_card'].includes(ann.type_of)) {
				this.refreshAssets()
			} else if (ann.type_of == "reply") {
			} else if (ann.type_of == "image") {
				this.refreshAssets()
				this.preloadAsset(data.content)
			} else if (ann.type_of == "drawing") {
				// this.tryNextQuestion("drawing hna")
			} else if (ann.type_of == "question") {
				if (this.addAnother || this.gptQuestions) {
					this.showQuestionDialog()
					if (!this.gptQuestions){
						setTimeout(() => {
							this.editingTime = this.deciTime
						}, 500)
					}
					return
				} else {
					this.hideAllDialogsAndPlay("HNA1")
				}
			} else {
				this.addingDialog = false
			}

			if (['resourceTray','ctaTray'].includes(ann.tray_type)){
				this.addToActive(ann)
				this.hideAllDialogs()
				return
			} else if (ann.type_of == 'comment') {
				this.activeNotes.push(ann)
				this.message = ''
				return
			} else if ((ann.type_of == 'text') || (ann.type_of == 'hotspot' || ann.type_of == 'drawing' || ann.type_of == 'button' ||  ann.type_of == 'card' || ann.type_of == 'image' || ann.type_of == 'audio' || ann.type_of == 'video') && !ann.parent_id) {
        if (ann.type_of == 'drawing' && this.ownsPost && this.waitingToRecordAudio) {
          // this.showSpecificDrawing(ann)
          this.drawingAnnotations = [ann]
          this.addInteraction('audio')
          return
        }
        this.hideAllDialogs()
        this.changeTimeAndPlay(ann.time - 1)
				return
			} else if (ann.type_of == 'question') {
				this.hideAllDialogsAndPlay("HNA2")
				return
			} 

			this.replyMessage = ""
			this.message = ""
      if (this.pausedUntilClick) {
        this.sayDev("PUC, return")
      }
			else if (this.questionAnnotation) {
				var r = this.videoDialog || this.audioDialog || this.showingCorrect || this.showMessageDialog || this.phoneDialog || this.showPollDialog || this.authDialog || this.payDialog
				if (r) {
					return
				}
				this.tryNextQuestion("hna")
			} else if (!this.dialogShowing) {
				// this.sayDev("No dialog showing, HADP")
				this.hideAllDialogsAndPlay("HNA3")
			} else if (this.navDialog) {
				this.hideAllDialogsAndPlay("HNA4")
			}

			if (this.filterKey != '') {
				var temp = this.filterKey
				this.filterKey = ''
				setTimeout(() => {
					this.filterKey = temp
				}, 100)
			}

		},
		formatLink: function (val, skipTag) {
			if (!val.toLowerCase().includes("http://") && !val.toLowerCase().includes("https://")) {
				val = "https://" + val
			}
			// if (!skipTag && this.shareToken && !val.includes("#")) {
			// 	var from = 'ms_video=' + this.shareToken
			// 	if (!val.includes("?")) {
			// 		val += "?" + from
			// 	} else {
			// 		val += "&" + from
			// 	}
			// }
			return val
		},

		getObject: function () {
      this.sayDev("GetObject at time " + this.deciTime)
			var pid = this.replyAnnotationID ? this.replyAnnotationID : this.replyAnnotation ? this.replyAnnotation.id : ''
			var data = new FormData
			data.append("annotation[post_id]", this.post_id)
			data.append("annotation[guest_name]", this.guestName || "");
			data.append("annotation[guest_email]", this.guestEmail || "");
			data.append("annotation[parent_id]", pid);
			data.append("annotation[token]", this.share.token);
			data.append("annotation[play_id]", this.playID || '');
      if (this.divDialog && this.activeDiv && !this.recordAudioDialog) {
        data.append("annotation[time]", Math.floor(this.activeDiv.exact_time));
			  data.append("annotation[exact_time]", this.activeDiv.exact_time);
      } else {
        data.append("annotation[time]", this.currentTime);
			  data.append("annotation[exact_time]", this.deciTime);
      }

			// this.po(data)
			return data
		},

		submitMessage: function (parent) {
			if (this.share.token == 'uQHkJdMEZwJO'){
				this.sayDev('Crisp Status')
				return
			}
			this.clearReplyAnnotation()
			if (this.currentUserId) {
			} else if (this.shouldAuth) {
				this.authDialog = true
				return;
			}

			if(this.shouldPay){
				this.showPayDialog()
				return
			}

			if (this.sanitizedMessage == "") { return; }

			var data = this.getObject()
			data.append("annotation[content]", this.sanitizeString(this.sanitizedMessage));
			data.append("annotation[type_of]", "comment");
      data.append("annotation[exact_finish]", Math.min(parseFloat(this.deciTime) + 5.0, this.duration-0.1));
			this.createAnnotation(data)
			this.hideAllDialogs();
		},
		createAnnotation: function (data, doHNA = true) {
			Rails.ajax({
				url: '/annotations',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					var variables = data.variables
					data = data.annotation
					if (data.type_of == 'card' || data.click_action =='show_card'){
						setTimeout(() => {
							this.refreshCards()
						},1000) 
					}
					if(doHNA) {
						this.handleNewAnnotation(data)
						this.updateShareVariables(variables)
						this.doScroll(data.id)
					}
				}
			});
		},
		updateAnnotation: function (id, data) {
			Rails.ajax({
				url: '/annotations/' + id,
				type: "PATCH",
				data: data,
				dataType: "JSON",
				success: (data) => {
					var variables = data.variables
          var ann = data.annotation
          console.log("Updated", ann)
          if (ann.type_of == 'card') {
            this.annotations = []
            this.fetchInteractions()
          }
          this.sayDev("Update has tray type " + ann.tray_type)
          if (ann.tray_type == "ctaTray") {
            this.setSideItemTitle('End Screen')
          } else if (ann.tray_type == "resourceTray") {
            this.setSideItemTitle('Magic Menu')
          }
					// this.handleNewAnnotation(data)
					this.$toasted.show("Saved ✨")
					var found = false
					this.annotations.forEach((a, i) => {
						if (a.id == ann.id) {
							ann.undo_redo = 1 // undo = 1
							this.annotations.splice(i, 1, ann)
							found = true
						}
					})
					if (this.isJSON(ann.data)){
						var obj = JSON.parse(ann.data)
						if (ann.type_of == 'card' || obj.action =='show_card'){
							setTimeout(() => {
								this.refreshCards()
							},1000) 
						}
					}
					// if (!found) {
					// 	this.handleNewAnnotation(data)
					// } 
					this.addToActive(ann)
					this.annotations.forEach((a) => {
						if (a.tray_type == ann.id) { 
							this.addToActive(ann)
						}
					})
					if (this.addAnother) {
            if (this.navDialog) {
              this.activeNav.id = null
              this.activeNav.variable = ""
              this.activeNav.value = ""
              return
            }
						setTimeout(() => {
							this.createDiv(ann.type_of)
						}, 100)
						return
					}
					this.doPreventNavs()
					if (ann.type_of == 'question') {
						this.changeTime(ann.time + 1)
					} 
					setTimeout(() => {
						this.hideAllDialogsDoSeekbar()
						this.editingAnnotation = {}
						this.showStamps()
						this.updatePointPaths()
            this.forceUpdate("UpdateAnnotation")
						this.updateShareVariables(variables)
						this.doScroll(ann.id)
					}, 200);
				}, error: (e) => {
					this.$toasted.show("Something went wrong")
				}
			});
		},
		doScroll(id) {
      return
      setTimeout(()=> {
        this.sayDev('🖱 Scroll to', id)
        var list = document.getElementById("anns_list")
        var targetLi = document.getElementById('scroll-stampid-'+id); // id tag of the <li> element
        console.log(list, targetLi)
        list.scrollTop = (targetLi.offsetTop - 50);
        targetLi.scrollIntoView({
          behavior: 'instant',
          block: 'center',
          inline: 'center',
        })
      }, 100)
			// setTimeout(()=>{
			// 	var el = document.getElementById('stampid-' + id)
			// 	if (el){ 
			// 		if (!this.isMobile){
			// 			el.scrollIntoView({
			// 				behavior: 'smooth',
			// 				block: 'center',
			// 				inline: 'center',
			// 			})
			// 		}
			// 		el.classList.add('rainbowBorderThin')
			// 		setTimeout(()=>{
			// 			el.classList.remove('rainbowBorderThin')
			// 		},5000)
			// 	}
			// },1000)
		},
		destroyAnnotation: function (item) {
			Rails.ajax({
				url: '/annotations/' + item.id,
				type: "DELETE",
				success: (data) => {
          this.pendingAnn = null
          this.deleteAnnDialog = false
					var index = this.annotations.indexOf(item)
					if (index > -1) {
						this.annotations.splice(index, 1)
					} else {
						index = this.annotationReplies.indexOf(item)
						this.annotationReplies.splice(index, 1)
					}
					if (item.type_of == "comment") {
						this.$toasted.show("Deleted Comment ✔")
					} else {
						this.$toasted.show("Deleted " + this.capitalizeFirst(item.type_of).toString() + ' ✔')
						this.questionAnnotation = null
						if (this.currentTime == 0 && !this.showingAnyTrays) {
							this.introDialog = true
						}
					}
					this.tryTrack("Deleted Interaction", { url: window.location.href, interaction: item })
					if (item.type_of == 'card' && this.deleteCardChildren){
						this.annotations.forEach(a=>{
							if (a.tray_type == item.id){
								this.destroyAnnotation(a)
							}
						})
					} else if(item.type_of == 'card'){
						this.annotations.forEach(a=>{
							if (a.tray_type == item.id){
								var data = new FormData()
								data.append("annotation[tray_type]", null)
								this.updateAnnotation(a.id, data)
							}
						})
					}
					this.showCardAnns = this.showCardAnns == item.id ? null: this.showCardAnns
					this.deleteCardChildren = false
				}, error: (e) => {
					this.tryTrack("Error deleting interaction", { id: item.id, error: e })
					this.$toasted.show("Could not delete interaction - please contact support if this persists")
          setTimeout(()=> {
            this.fetchInteractions(item.id)
            this.errorCount++
          }, this.errorCount * 1000)
				}
			});
			this.hideAllDialogs()
		},
		updateShareVariables(variables){
			if (this.variables.viewer_id){
				return
			}
			if (variables){
				var share_variables = {}
				if (this.share.variables){
					share_variables = JSON.parse(this.share.variables)
				}
				var update = false
				variables = JSON.parse(variables)
				var orgVariables = JSON.parse(this.orgVariables)
				Object.keys(variables).forEach(v => {
					if (!(v in share_variables)){
						if(orgVariables[v]) {
							share_variables[v] = orgVariables[v]
						} else {
							share_variables[v] = ''
						}
						update = true
					}
				})
				if(update){
					this.share.variables = JSON.stringify(share_variables)
					// this.variableDialog = !this.starterUser && this.getLocalStorageItem('MS_Hide_Variable_Dialog') != 'true'
					this.pausePlayer("USV")
				}
			}
		},
		closeVariableDialog(vars){
			if (vars){
				this.share.variables = JSON.stringify(vars[0])
				this.orgVariables = JSON.stringify(vars[1])
			}
			this.variableDialog = false
		},
		upAnnotation: function(ann){
			this.busy = true
			var i = this.orderedAnnotations.filter(a=> a.type_of == 'question' && a.exact_time == ann.exact_time).indexOf(ann)
      this.sayDev("Index: " + i)
			if(i == 0){
				return
			}
			this.updateQuestionSetOrder(ann, i, -1)
		},
		downAnnotation: function(ann){
			this.busy = true
			var i = this.orderedAnnotations.filter(a=> a.type_of == 'question' && a.exact_time == ann.exact_time).indexOf(ann)
      this.sayDev("Index: " + i)
			if(i == this.orderedAnnotations.length - 1){
				return
			}
			this.updateQuestionSetOrder(ann, i, 1)
		},
		updateQuestionSetOrder(ann, index, direction){
			var data = new FormData()
			var questionSetIds = this.annotations.map(a => {
				if (a.type_of == "question" && a.exact_time == ann.exact_time){
					return a.id
				}
			})
			data.append("ids", JSON.stringify(questionSetIds))
			data.append("direction", direction)
			data.append("index", index)
			data.append("video_id", this.share.id)
			Rails.ajax({
				url: '/annotations/order',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.annotations = []
					this.fetchInteractions(ann.id)
					this.currentQuestionIndex += direction
					this.busy = false
				}, error: (e) => {
					this.$toasted.show("Something went wrong")
				}

			})
		},
		saveAnnotationTime(ann, time, i, j){
			var data = new FormData()
			data.append("annotation[time]", time);
			var created = new Date(this.annotations[j].created_at).getTime()
			data.append("annotation[created_at]", (created + 1 * (i < j ? 1 : -1))/1000)
			Rails.ajax({
				url: '/annotations/' + ann.id,
				type: "PATCH",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.annotations.splice(i, 1, this.annotations[j])
					this.annotations.splice(j, 1, data)
				}, error: (e) => {
					this.$toasted.show("Something went wrong")
				}
			});
		},
		hideAnnotation: function(ann){
			var data = new FormData()
			data.append("annotation[hidden]", !ann.hidden);
			Rails.ajax({
				url: '/annotations/' + ann.id,
				type: "PATCH",
				data: data,
				dataType: "JSON",
				success: (data) => {
					this.$toasted.show((ann.type_of ? this.capitalizeFirstLetter(ann.type_of) : 'Interaction') + " " + (ann.hidden ? "Visible" : "Hidden"))
					for(var i = 0; i < this.annotations.length; i++){
						if (this.annotations[i].id == ann.id){
							this.annotations[i].hidden = !ann.hidden
							break
						}
					}
					this.resetMarkers()
				}, error: (e) => {
					this.$toasted.show("Something went wrong")
				}
			});
		},
		replyToAnnotation: function (ann, isFeedback) {
			if (!this.played) {
				this.changeTime(ann.time)
				setTimeout(() => {
					this.setReplyAnnotation(ann)
					this.replyDialog = true
				}, 500);
			}
			this.setReplyAnnotation(ann)
			if (ann.type_of == "comment" || ann.type_of == 'reply') {
				this.replyContentText = ann.content
			} else if (ann.type_of == "video") {
				this.replyContentText = "Video Clip"
			} else if (ann.type_of == "audio") {
				this.replyContentText = "Audio Clip"
			} else if (ann.type_of == "drawing") {
				this.replyContentText = "Art"
			}

			if (isFeedback) {

				var alreadyReplied = this.checkIfUserReplied(ann, this.currentUserId)

				if (ann.user_id == this.currentUserId || alreadyReplied) {
					this.playPlayer("ReplyTo");
					return;
				}
				else {
					this.replyText = ann.username + " requests your feedback: "
					this.pausePlayer("RTA")
					this.replyDialog = true
					if (ann.type_of == "comment") {
						this.replyContentText = ann.content
					} else if (ann.type_of == "video") {
						this.replyContentText = "Video Note"
					} else if (ann.type_of == "audio") {
						this.replyContentText = "Audio Note"
					}
				}
			} else {
				this.replyText = "Replying to " + ann.username
				if (ann.type_of == "comment" || ann.type_of == "reply") {
					this.replyContentText = ann.content
				} else if (ann.type_of == "video") {
					this.replyContentText = "Video Note"
				} else if (ann.type_of == "audio") {
					this.replyContentText = "Audio Note"
				}

				this.pausePlayer("RTA2")
				this.replyDialog = true
			}
		},
		copyAnnotation: function (ann) {
			this.recentDialog = false
			this.$toasted.show("Started Copy " + this.capitalizeFirst(ann.type_of))
			var t = ann.time || this.currentTime
			if (!this.starterUser) {
				t = ann.exact_time || t
			}
			var ea = Object.assign({}, ann)
      ea.id = null
			if (ann.type_of == 'question') {
        this.editingAnnotation = Object.assign({}, ann);
        this.editingAnnotation.content = Object.assign({}, ann.content);
        this.editingAnnotation.id = null
        this.editingAnnotation.content.id = null
				this.editingQuestion.id = null
        console.log(this.editingAnnotation)
        console.log(this.editingAnnotation.content)
				this.showQuestionDialog()
        this.setEditingQuestion(this.editingAnnotation)
        return
			} else if (this.isNav(ea.type_of)) { 
        this.startEditNav(ea)
        this.activeNav.id = null
      } else {
				this.startEditDiv(ea)
				this.activeDiv.id = null
      }
      ea.time = t
      ea.id = null
      this.editingAnnotation = ea
      this.editingTime = t
		},
		editAnnotation: function (ann) {
      this.sayDev("Edit Annotation " + ann.id)
			this.clearScreenInteractions()
			this.hideSeekbarImmediately()
      if (ann.tray_type != 'ctaTray') {
        this.closeCtaTray()
      } 
      if (ann.tray_type != 'resourceTray') {
        this.closeMenu()
      }
			this.clearReplyAnnotation()
			this.bulkOption = ''
			this.bulkOption2 = ''
			this.editingAnnotation = ann
			this.editingMessage = ann.content
			this.editingTime = ann.time
			// this.introDialog = false
			this.showSeekbar = false
			
			if (ann.type_of == 'question') {
				setTimeout(() => {
					this.editingAnnotation = ann
					this.showQuestionDialog()
					this.setEditingQuestion(ann)
				}, this.played ? 0 : 800);
			} else if (['audio','video', 'button', 'image', 'hotspot', 'text', 'card'].includes(ann.type_of)) {
				this.busy = true				
				this.startEditDiv(ann)
				return
			} else if (ann.type_of == 'image') {
				this.imageDialog = true
				this.theImage = JSON.parse(ann.data)
				this.theImage.extra = this.theImage.value
				this.extra = ann.extra
			} else if (['jump','switch', 'pause', 'variable', 'redirect', 'resourceTray', 'post', 'get', 'chatGPT', 'show_card'].includes(ann.type_of)) {
				this.startEditNav(ann)
			} else {
				this.editingDialog = true
			}
			this.pausePlayer("EditAnnotation")
			this.tryTrack("Started Edit Interaction", { url: window.location.href, interaction: ann })
			this.introDialog = false
			setTimeout(() => {
				this.showSeekbar = false
				this.hideSeekbarImmediately()
			}, 10);

		},
		createCard(){
			this.refreshCards()
			this.cardUpdated = 0
			this.resetCard()
			this.createDiv('card')
		},
		resetCard(){
			this.selectedCard = null
			var btn = this.safeStyles.button
			var card = this.safeStyles.card
			this.activeCard = {
				name: '',
				image: '',
				title: {
					value: '',
					style: {
						fontSize: '30',
						color: '#FFFFFF'
					},
				},
				text: {
					value: '',
					style: {
						fontSize: '20',
						color: '#FFFFFF',
					},
				},
				button: {
					value: '',
					style: {
						fontSize: '20',
						color: btn.color,
						background: btn.background,
						border: btn.border.replace('0.2em','').replace('0.12em','').replace('solid','').replace('!important','').trim(),
						borderRadius: btn.borderRadius,
					},
				},
				layout: 1,
				click_action: '',
				click_value: '',
				background: card.background, 
				border: card.border.replace('0.2em','').replace('0.12em','').replace('solid','').replace('!important','').trim(),
				borderRadius: card.borderRadius,
				auth: null,
				field: null,
				body: null,
				head: null,
				httpVar: null,
			}
		},
		createDiv: function (type_of) {
			this.sayDev("Create Div", type_of)
			var ad = this.activeDiv
			this.activeDiv.id = null
			this.hideSeekbarImmediately()
			this.activeDiv.pause = false
			this.activeDiv.timer = 5
			this.resetCard()
			if (this.addAnother) {
				var drag = document.getElementById('drag')
				var plusOrMinus = Math.random() < 0.5 ? -1 : 1
				drag.dataset.x = "0"
				drag.dataset.y = "0"
				drag.style.left = this.videoWidth / 2 - parseInt(Math.floor(Math.random() * 50 * plusOrMinus)) + 'px'
				drag.style.top = this.videoHeight / 2 + parseInt(Math.floor(Math.random() * 50 * plusOrMinus)) + 'px'
				this.activeDiv = Object.assign({}, ad);
				this.activeDiv.points = []
        var coords = Object.assign({}, this.coords)
        this.coords = coords
        var pTop = parseFloat(this.coords.top) + parseFloat(this.coords.height) + 2
        var pLeft = parseFloat(this.coords.left) + parseFloat(this.coords.width) + 2
        if (pTop < 85) {
          this.coords.top = pTop
        } else{
          this.coords.left = pLeft
        } 
        this.doCoords()
			} else {
				this.showVariables = false
				this.activeDiv.variable = ""
				this.activeDiv.variableValue = ""
				this.showHubspot = false
				this.activeDiv.hubspotField = null
				this.activeDiv.hubspotValue = null
				this.activeDivFontSize = Math.round(this.activeDivFontSize || 20)
				this.activeDiv.type_of = type_of
        this.activeDiv.exact_time = this.starterUser ? Math.floor(this.deciTime) : this.deciTime
        this.activeDiv.exact_finish = this.toSingleFloat(Math.min(this.duration-0.1, Number(this.deciTime) + Number(5.0)))
        this.sayDev("ET and EF: ")
        this.sayDev(this.activeDiv.exact_time)
        this.sayDev(this.activeDiv.exact_finish)
				this.activeDiv.time = Math.min(this.currentTime, this.duration-1)
				this.activeDiv.finish = Math.min(this.duration, this.min5time(this.currentTime))
				this.activeDiv.customStyle.fontSize = 20
				this.activeDiv.timer = 5
				this.activeDiv.customStyle.angle = 0
				this.activeDiv.customStyle.caption = true
				this.activeDiv.customStyle.ring = true
				this.activeDiv.customStyle.caption = true
				this.activeDiv.customStyle.hover = true
				if (type_of == 'text'){
					this.activeDiv.borderRadius = this.shareDesign.comment_border_radius
				} else if (type_of == 'button'){
					this.activeDiv.borderRadius = this.shareDesign.button_border_radius
				} else if (type_of == 'card'){
					this.activeDiv.borderRadius = this.shareDesign.card_border_radius
				}
        this.activeDiv.borderRadius = parseInt(this.activeDiv.borderRadius)
        if (['button', 'text', 'card'].includes(type_of)) {
					this.setActiveDivBorderRadius()
        }


				this.activeDiv.action = this.isProduction ? 'link' : 'track'
				this.activeDiv.is_conditional = false
				this.activeDiv.conditional_show = true
				this.activeDiv.conditional_variable = ''
				this.activeDiv.conditional_assertion = 'equals'
				this.activeDiv.conditional_value = ''
				this.activeDiv.is_conditional_2 = false
				this.activeDiv.conditional_variable_2 = ''
				this.activeDiv.conditional_assertion_2 = 'equals'
				this.activeDiv.conditional_value_2 = ''
				this.activeDiv.conditional_operator = 0
				this.activeDiv.post_click_state = ''
				this.activeDiv.post_click_color = this.adt ? this.shareDesign.comment_background_color : this.shareDesign.button_background_color
				this.activeDiv.post_click_text_color = this.adt ? this.shareDesign.comment_text_color : this.shareDesign.button_text_color
				this.activeDiv.background_image = ''
				this.activeDiv.points = []
				this.activeDiv.transition = this.shareDesign.interactions_transition
				if(this.showingAnyTrays){
					this.activeDiv.transition = 'None'
					this.activeDiv.resourceTray = this.showMenu
					this.activeDiv.ctaTray = this.showCTA
				}
			}

			if (type_of == 'text') {
					this.activeDiv.content = ''
			}
			if (type_of != 'button') {
				var w = '200px'
				var h = '200px'
				var l = '25%'
				var t = '40%'
				if (type_of == 'text') {
					h = '4em'
				}
				if (type_of == 'video') {
					w = '33%'
					h = '33%'
					t = '33%'
					l = '33%'
				}
				if (type_of == 'card'){
					l="10%"
					t="15%"
					w='28%'
					h='63%'
				}
				if (type_of == 'audio'){ 
					this.stopPlacingInteraction()
				} else {
					setTimeout(() => {
						var drag = document.getElementById('drag')
						if (drag) {
							if (type_of == 'hotspot') {
								var z = Math.min(200, this.videoWidth * 0.15)
								drag.style.height = z + 'px'
								drag.style.width = z + 'px'
								drag.style.left = (this.canvasWidth / 2) - z*0.5 + 'px' 
								drag.style.top = (this.canvasHeight / 2) - z*0.5 + 'px' 
							} else {
								drag.style.width = w
								drag.style.height = h
								drag.style.top = t
								drag.style.left = l
							}
						}
					}, 20)
				}
			} 
			this.activeDivTransform = {
				x: 0,
				y: 0,
				angle: 0
			}
			this.activeDiv.customStyle.top = t
			this.activeDiv.value = ''
			this.activeDiv.extra = ''
			this.activeDiv.variable = ''
			this.activeDiv.variableValue = ''
			
			if (type_of == 'button') {
				this.activeDiv.content = ''
				if (!this.addAnother) {
					this.activeDiv.action = 'link'
				}
			} else if (type_of == 'image') {
				this.imageSelectDialog = true
				if (!this.addAnother) {
					this.activeDiv.action = 'view'
				}
        setTimeout(()=> {
          this.flashFocusField('#imageSelectDialog')
        }, 500)
			} else if (type_of == 'video') {
				this.videoSelectDialog = true
				this.advancedEditing = 2
			} else if (type_of == 'text') {
				this.activeDiv.action = 'track'
			} else if (type_of == 'hotspot') {
				this.activeDiv.customStyle.borderRadius = 50
        if (this.lsTest()) {
          const hs = this.tryParseJSON(localStorage.getItem("MS_HOTSPOT"))
          if (hs) {
            this.activeDiv.customStyle.caption = hs.caption
            this.activeDiv.customStyle.ring = hs.ring
            this.activeDiv.customStyle.hover = hs.hover
            this.activeDiv.customStyle.borderRadius = hs.borderRadius
            this.activeDiv.customStyle.ringWidth = hs.ringWidth
          }
        }
			} else if (type_of == 'text') {
				this.activeDiv.customStyle.fontSize = this.fontSize;
				this.activeDiv.customStyle.padding = this.noteFontSize.padding
			} else if (type_of == 'card'){
				this.toggleAdvancedStyling()
			}
      if (!this.isProduction) {
        this.activeDiv.action = 'track'
      }
			this.addAnother = false
      
      
			if (this.activePausesAtTime(this.activeDiv.exact_time).length) {
				this.sayDev("Master pause, do same")
				this.activeDiv.finish = this.activeDiv.time
        this.activeDiv.exact_finish = this.activeDiv.exact_time
			}
			this.startDiv()
			if (type_of == 'button' || type_of == 'text') {
				this.flashFocusField('divInput');

			}
		},
		startEditNav(ann) {
			this.startNavEdit = true
			var x = ann.data ? JSON.parse(ann.data) : {}
			var val = null
			var auth = null
			var field = null
			var body = null
			var head = null
			var httpVar = null
			if(['post', 'get'].includes(ann.type_of)){
				var h = x.value
				val = h.url
				auth = h.auth
				field = h.field
				body = h.body
				head = h.head
				httpVar = h.httpVar
				if (!this.scriptsLoaded.html) {
					this.loadHtmlScripts() 
					setTimeout(() => {
						this.updatedConnector++
					},3000) 
				} else {
					this.updatedConnector++
				}
			}
			this.activeNav = {
				id: ann.id,
				type_of: ann.type_of,
				content: ann.content,
				time: ann.time,
        exact_time: ann.exact_time,
				finish: ann.finish,
        exact_finish: ann.exact_finish,
				extra: ann.extra,
				pause: ann.pause,
				timer: ann.timer || 5,
				click_time: ann.click_time,
				action: x.action,
				value: val ? val : x.value,
				auth: auth ? auth : "",
				field: field ? field : "",
				body: body ? body: "",
				head: head ? head: "",
				httpVar: httpVar ? httpVar: "",
				customStyle: ann.customStyle,
				variable: ann.variable,
				variable_value: ann.value,
				is_conditional: ann.is_conditional,
				conditional_show: ann.conditional_show,
				conditional_variable: ann.conditional_variable,
				conditional_assertion: ann.conditional_assertion,
				conditional_value: ann.conditional_value,
        conditional_operator: ann.conditional_operator,
				is_conditional_2: ann.is_conditional_2,
				conditional_variable_2: ann.conditional_variable_2,
				conditional_assertion_2: ann.conditional_assertion_2,
				conditional_value_2: ann.conditional_value_2,
				conditional_operator: ann.conditional_operator
			}
			this.navDialog = true
			if (ann.type_of =='show_card'){
				this.suppressCardWatcher = true
				this.showCard(ann.content.id, true)
			}
			setTimeout(() => {
				this.startNavEdit = false
			},1000) 
		},
		initializeDiv(ann){
			this.movePoints = []
			this.activePoint = 0
			this.advancedEditing = 0
			this.sayDev("Start Edit Div", ann)
			// this.changeTime(ann.time)
			this.hideAllDialogs()
			var x = {}
			if (ann.type_of != 'video' && ann.type_of != 'audio') {
				x = ann.data ? JSON.parse(ann.data) : {}
			}
			var c = ann.customStyle ? JSON.parse(ann.customStyle) : {}
			c.angle = c.angle || 0
			this.activeDivTransform = {
				x: 0,
				y: 0,
				angle: c.angle
			}
			this.activeDivFontSize = Math.round(c.fontSize * (this.canvasWidth / c.videoWidth))
			if (x.action == 'download') {
				this.assetDownload[0] = JSON.parse(x.value)
			}
			if (x.action == 'show_card'){
				this.findActiveCard(x.value)
			} else if(ann.type_of == 'card'){
				this.findActiveCard(ann.content.id)
			}
			// if (this.activeCard.click_action == 'download') {
			// 	var data = JSON.parse(this.activeCard.parent.data)
			// 	this.assetDownload[0] = JSON.parse(data.value)
			// 	this.activeCard.click_value = this.assetDownload[0].url
			// }
			var br = parseInt(ann.borderRadius)
			var bc = ann.background_color
			var tc = ann.text_color
			var bdc = ann.border_color
			var doAdvanced = false
			if (['button', 'text', 'card', 'hotspot'].includes(ann.type_of)){
				if (ann.type_of == 'button'){
					if (br != (this.safeStyles.button.borderRadius.split('em')[0] * 10) || !this.safeStyles.button.background.includes(bc) || !this.safeStyles.button.color.includes(tc)  || !this.safeStyles.button.border.includes(bdc)){
						doAdvanced = true
					}					
					br = br || this.safeStyles.button.borderRadius.split('em')[0] * 10
					bc = bc || this.safeStyles.button.background
					tc = tc || this.safeStyles.button.color
					bdc = bdc || this.safeStyles.button.border
				} else if (ann.type_of == 'text'){
					if (br != (this.safeStyles.comment.borderRadius.split('em')[0] * 10) || !this.safeStyles.comment.background.includes(bc) || !this.safeStyles.comment.color.includes(tc)){
						doAdvanced = true
					}	
					br = br || this.safeStyles.comment.borderRadius.split('em')[0] * 10
					bc = bc || this.safeStyles.comment.background
					tc = tc || this.safeStyles.comment.color
				} else if (ann.type_of == 'card'){
					br = br || this.safeStyles.card.borderRadius.split('em')[0] * 10
					bc = bc || this.safeStyles.card.background
					bdc = bdc || this.safeStyles.card.border
				} else if (ann.type_of == 'hotspot'){
					if (this.shareDesign.color && !this.shareDesign.color.includes(bc)){
						doAdvanced = true
					}
				} else if (ann.type_of == 'card'){
					this.activeCard = Object.assign({},ann.content)
				}
			} 
			var val = null
			var auth = null
			var field = null
			var body = null
			var head = null
			var httpVar = null
			if(['post','get'].includes(x.action)){
				var h = x.value
				val = h.url
				auth = h.auth
				field = h.field
				body = h.body
				head = h.head
				httpVar = h.httpVar
				if (!this.scriptsLoaded.html) {
					this.loadHtmlScripts() 
					setTimeout(() => {
						this.updatedConnector++
					},3000) 
				} else {
					this.updatedConnector++
				}
			}
			this.activeDiv = {
				click_time: ann.click_time || 0,
				id: this.activeDiv.id || ann.id,
				type_of: ann.type_of,
				content: ann.content,
				time: Number(ann.time),
        exact_time: Number(ann.exact_time),
        exact_finish: Number(ann.exact_finish),
				finish: Number(ann.finish || Math.min(this.duration, this.min5time(ann.time))),
				extra: ann.extra,
				pause: ann.pause,
				timer: ann.timer || 5,
				action: x.action,
				value: val ? val : x.action == 'download' ? this.assetDownload[0].url : x.action == 'cart' ? x.value : x.value,
				auth: auth ? auth : "",				
				field: field ? field : "",
				body: body ? body : "",
				head: head ? head : "",
				httpVar: httpVar ? httpVar: "",
				customStyle: c,
				variable: ann.variable || "",
				variableValue: ann.value,
				hubspotField: ann.hubspot_field || "",
				hubspotValue: ann.hubspot_value || "",
				is_conditional: ann.is_conditional || false,
				conditional_show: ann.conditional_show || "",
				conditional_variable: ann.conditional_variable || "",
				conditional_assertion: ann.conditional_assertion || "",
				conditional_value: ann.conditional_value || "",
				is_conditional_2: ann.is_conditional_2 || false,
				conditional_variable_2: ann.conditional_variable_2 || "",
				conditional_assertion_2: ann.conditional_assertion_2 || "",
				conditional_value_2: ann.conditional_value_2 || "",
				conditional_operator: ann.conditional_operator,
				transition: ann.transition,
				animation: ann.animation,
				background_color: bc,
				borderRadius: br,
				text_color: tc,
				border_color: bdc ? bdc.replace('solid', '',).replace('0.2em', '').replace('0.12em','').replace('!important', '').replace(' ','') : "",
				post_click_state: ann.post_click_state || '',
				post_click_color: ann.post_click_color || bc,
				post_click_text_color: ann.post_click_text_color || tc,
				background_image: ann.background_image || '',
				must_click: ann.must_click,
				points: this.isJSON(ann.points) ? JSON.parse(ann.points) : [],
				resourceTray: ann.tray_type == 'resourceTray',
				ctaTray: ann.tray_type == 'ctaTray',
				tray_type: ann.tray_type,
        internal_label: ann.internal_label,
        force_lead_capture: ann.force_lead_capture,
        hide_desktop: ann.hide_desktop,
        hide_mobile: ann.hide_mobile,
        do_spotlight: ann.do_spotlight,
        second_action: ann.second_action,
        second_value: this.isJSON(ann.second_value) ? JSON.parse(ann.second_value) : ann.second_value,
			}
			this.setActiveDivBorderRadius()
			this.forceUpdate("initializeDiv")
			
			var content = this.activeDiv.content ? this.activeDiv.content.toString().replaceAll(' ','') : '' 
			var value = this.activeDiv.value ? this.activeDiv.value.toString().replaceAll(' ','') : ''
      if (this.scriptsLoaded.html) {
				this.htmlEditorContent = content.includes("<") && (content.includes("</") || content.includes("/>"))
        this.htmlEditorValue = value.includes("<") && (value.includes("</") || value.includes("/>"))
      } else if (content.includes("<") && (content.includes("</") || content.includes("/>"))) {
				this.loadHtmlScripts()
      }
			if (doAdvanced || ann.transition != 'zoom' || !['','None'].includes(ann.animation) || ann.post_click_state){
				if (this.isProduction) {
          this.toggleAdvancedStyling()
        }
			}

			if (ann.is_conditional || ann.variable || this.adm){
				this.advancedEditing = 2
			}
			
			if (this.activeDiv.variable) {
				this.showVariables = true
			} else {
				this.showVariables = false
			}
			
			if (this.activeDiv.hubspotField) {
				this.showHubspot = true
			} else {
				this.showHubspot = false
			}
			
			if (this.activeDiv.customStyle.hover == undefined) {
				this.activeDiv.customStyle.hover = true
			}

			if (this.activeDiv.customStyle.ring == undefined) {
				this.activeDiv.customStyle.ring = true
			}

			if (this.activeDiv.customStyle.caption == undefined) {
				this.activeDiv.customStyle.caption = true
			}
			
			this.activeDivStyleHold = {
				background_color: this.activeDiv.background_color,
				text_color: this.activeDiv.text_color,
				border_color: this.activeDiv.border_color,
				hold_background_color: this.activeDiv.background_color,
				hold_text_color: this.activeDiv.text_color,
				hold_border_color: this.activeDiv.border_color,
			}
		},
		startEditDiv(ann) {
			this.sayDev("Start Edit Div", ann)
			this.selectedCard = null
			this.suppressCardWatcher = true
			// this.changeTime(ann.time)
			this.hideAllDialogs()
			this.initializeDiv(ann)
			this.divDialog = true
			this.startDiv()
      if (ann.tray_type == "resourceTray") {
        this.toggleMenu(true)
      } 
      if (ann.tray_type == "ctaTray") {
        this.toggleCTA(true)
      } 
			setTimeout(() => {
				var d = document.getElementById('drag')
				var c = ann.customStyle ? JSON.parse(ann.customStyle) : {}
				if (c) {
					d.style.width = c.width + '%'
					d.style.height = c.height + '%'
					d.style.left = c.left + '%'
					d.style.top = c.top + '%'
					if (c.borderRadius){
						d.style.borderRadius = (c.borderRadius || 0) + '%'
					} else {
						d.style.borderRadius = ann.type_of == 'text' ? this.shareDesign.comment_border_radius : this.shareDesign.button_border_radius
					}
				} else if (d) {
					d.style.width = 'auto'
					d.style.height = 'auto'
					d.style.top = '10px'
					d.style.left = this.videoWidth - d.offsetWidth - 10 + 'px'
					d.style.fontSize = '16px'
					d.style.borderRadius = ann.type_of == 'text' ? this.shareDesign.comment_border_radius : this.shareDesign.button_border_radius
					this.activeDiv.customStyle = {
						width: 'auto',
						maxWidth: "300px",
						height: 'auto',
						maxHeight: "300px",
						top: '10px',
						left: this.videoWidth - d.offsetWidth - 10 + 'px',
						fontSize: 20,
					}
					this.activeDivBorderRadius = d.style.borderRadius
					this.setDivDimensions()
				} else {
					setTimeout(() => {
						this.startEdit(ann)
					}, 50)
				}
				if (ann.type_of == 'video') {
					this.sayDev("Update Video!")
					this.streamVideoAnnotation(ann.content)
				}
				if (ann.type_of == 'audio') {
					this.sayDev("Update Audio!")
					this.streamAudioAnnotation(ann.content)
				}
				if (this.activeDiv.points && this.activeDiv.points.length) {
					this.drawPointsEditor()
				}
        this.busy = true
        setTimeout(()=> {
          this.busy = false
        }, 500)
        
			}, 50)
		},
		streamVideoAnnotation(id){
			Rails.ajax({
				url: '/videos/stream/' + id,
				type: "GET",
				success: (data) => {
					var x = document.getElementById('divVideo')
					x.src = data.aws
					this.videoSelectDialog = false
					setTimeout(()=> {
						if (this.activeDiv && this.activeDiv.customStyle.width == "100") {
							this.sayDev("Make Cover...")
							x.style.objectFit = 'cover'
						} else {
							this.sayDev("No cover")
						}
					}, 200)
				}, error: (e) => {
				}
			})
		},
		streamAudioAnnotation(id){
			Rails.ajax({
				url: '/audios/stream/' + id,
				type: "GET",
				success: (data) => {
					this.audioSelectDialog = false
					var a = document.getElementById("asset_audio");
					// console.log("Do audio ", a);
					a.src = data.aws;
					a.play();
				}, error: (e) => {
				}
			})
		},
		clearMediaSelection(){
			this.activeDiv.content = ''
			var a = document.getElementById("asset_audio");
			if (a){
				a.pause()
			}
		},
		saveAnnotation: function () {
			var data = new FormData
			data.append("annotation[time]", this.showMenu || this.showCTA  ? 0 : Math.floor(this.editingAnnotation.exact_time != null ? this.editingAnnotation.exact_time :  this.getExactTime(this.editingAnnotation.time)));
			data.append("annotation[exact_time]", this.showMenu || this.showCTA ? 0.0 : this.editingAnnotation.exact_time != null ? this.editingAnnotation.exact_time :  this.getExactTime(this.editingAnnotation.time));
			data.append("annotation[finish]", this.showMenu || this.showCTA ? this.playerDuration : Math.floor(this.editingAnnotation.exact_finish != null ? this.editingAnnotation.exact_finish :  this.getExactTime(this.editingAnnotation.finish)));
			data.append("annotation[exact_finish]", this.showMenu|| this.showCTA  ? this.playerDuration : this.editingAnnotation.exact_finish != null ? this.editingAnnotation.exact_finish :  this.getExactTime(this.editingAnnotation.finish));
			if (this.showingAnyTrays){
				data.append("annotation[tray_type]", this.showMenu ? 'resourceTray' : this.showCTA ? 'ctaTray' : "");
			} else if (this.editingAnnotation.tray_type){
				data.append("annotation[tray_type]", this.editingAnnotation.tray_type);
			}
			// "2a556db8-e109-4efe-ae85-293f48b2ef8d"
			if (this.editingAnnotation.type_of == 'comment' || this.editingAnnotation.type_of == 'reply') {
				data.append("annotation[content]", this.editingMessage || '')
			}
			if (this.editingAnnotation.type_of == 'button' || this.editingAnnotation.type_of == 'image' || this.editingAnnotation.type_of == 'audio' || this.editingAnnotation.type_of == 'video') {
				data.append("annotation[extra]", this.editingAnnotation.extra || '');
			}
			if (this.editingAnnotation.type_of == 'button') {
				data.append("annotation[content]", this.editingAnnotation.content || '');
			}
			// if (this.editingAnnotation.finish) {
			// 	data.append("annotation[finish]", this.editingAnnotation.finish);
			// }
			if (this.editingAnnotation.timer) {
				data.append("annotation[timer]", this.editingAnnotation.timer);
			}
			Rails.ajax({
				url: '/annotations/' + this.editingAnnotation.id,
				type: "PATCH",
				data: data,
				success: (data) => {
					var variables = data.variables
					data = data.annotation
					this.$toasted.show("Saved 👍")
					// this.editingAnnotation = {}
				
					if (data.type_of != 'reply') {
						var index = this.annotations.indexOf(this.editingAnnotation)
						this.sayDev("Index: " + index)
						this.annotations.splice(index, 1, data)
						this.doPreventNavs()
						this.forceUpdate()
						this.updateShareVariables(variables)
						} else {
						var x = document.getElementById("reply-" + data.id)
						if (x) {
							x.getElementsByClassName("replyContent")[0].getElementsByClassName("replyText")[0].innerHTML = data.content;
						}
					}

					this.editingDialog = false
					this.editingMessage = ""
					this.clearScreenInteractions()
					var t = this.currentTime
					this.tryTrack("Edited An Interaction", { url: window.location.href, interaction: data })
					if (this.currentTime == 0) {
						this.played = false
						this.introDialog = true
					}

					this.editingAnnotation = {}
					this.updatePointPaths()


				}, error: (e) => {
				}
			});
		},


		checkIfUserReplied: function (annotation, user_id) {
			var replies = this.annotationsByParent[annotation.id]
			if (!replies || replies.length == 0) {
				return false
			} else {
				for (var i = 0; i < replies.length; i++) {
					if (replies[i].user_id == this.currentUserId) {
						return true
					}
				}
				return false
			}
		},

		resumeVideo: function () {
			this.sayDev("ResumeVideo")
			if (!this.played) {
				this.playFromStart()
			}
      if (this.afkDialog) {
        this.afkDialog = false
        this.playPlayer()
        return
      }
			if (this.recordAudioDialog) {
				this.recordAudioDialog = false
				return
			}
			if (this.recordVideoDialog) {
				this.recordVideoDialog = false
				return
			}

			if (this.drawingDialog) {
				this.drawingDialog = false
				return
			}

			if (this.resumeDialog) {
				this.resumeDialog = false
			} else if (this.videoDialog) {
				this.closeVideoDialog()
			} else if (this.audioDialog) {
				this.closeAudioDialog()
			} else {
				this.tryNextQuestion("resumeVideo")
			}
			if (this.lastPauseTime != this.currentTime) {
				// this.sayDev("LPT is not CT...show stamps!!!!")
				this.showStamps()
			}
			if (this.showCardDialog){
				return
			}

			if (!this.questionSet) {
				this.hideAllDialogsAndPlay("RV")
			}
		},

		hideAllDialogsAndPlay: function (source) {
			if (this.resumeDialog || this.confirmLink) {
				// this.sayDev("🌕 HADP resume")
				return
			} 
			if (!this.waitingAction) {
				// this.sayDev("🚨 HADP")
				this.hideAllDialogs()
			}
			setTimeout(() => {
        var good = true
        this.divsActive.forEach((d)=> {
          if (!d.hidden && d.must_click && !d.clicked && this.exactTime >= Number(d.exact_finish)) {
            good = false
            this.setPUC("HADP " + d.id)
            this.sayDev(this.exactTime)
            this.sayDev(Number(d.exact_finish))
            this.sayDev("Must Click Annotation-->")
            this.sayDev(d)
          }
        })
        if (good) {
          this.playPlayer("HADP --- " + source);
        }
			}, 50);
		},
		clearQuestionSetup() {
			this.sayDev("💧 CQS")
			this.replyDate = null
			this.questionAnnotation = null;
			this.questionChoices = []
			this.questionSetIndex = 0
			this.questionSet = null
			this.currentQuestionIndex = 0
			this.chosenAnswer = ''
			this.correctAnswer = ''
			this.questionAnswer = ''
			setTimeout(() => {
				if (!this.questionAnnotation) {
					this.clearReplyAnnotation()
				}
			}, 1250)
		},
		tryNextQuestion: function (source) {
			this.sayDev("💥💥💥 TNQ " + this.questionSetIndex + "("+source+")")
      this.cancelSpeech()
      this.resetTimer()
			
			// this.sayDev("QuestionSet: ", this.questionSet)
			this.showingRepeatResult = false
			if (this.videoDialog) {
				this.videoDialog = false
			}
			
			if (this.ownsPost && this.payDialog) {
				this.payDialog = false
				this.needsToPay = false
			}
		
			if (this.audioDialog) {
				this.closeAudioDialog()
				this.audioDialog = false
				return
			}
      

			if(this.phoneDialog){
				this.phoneDialog = false;
			}

			if(this.showPollDialog){
				this.showPollDialog = false;
				this.messageToShow = ''
			}

			if (this.showPollDialogNext){
				this.chooseSelection(this.waitingAction, this.questionAnnotation);
				return;
			}
			if (this.questionAnnotation && this.questionAnnotation.content.force_correct && !this.forceCorrectDone) {
				this.sayDev("⭐️ FORCE CORRECT CHECK")
				if (this.chosenAnswer) {
					this.finishCorrect()
				} else {
					this.questionAnnotation.response_time = new Date()
					this.sayDev('Initial ⌚️⌚️⌚️', this.questionAnnotation.response_time)
				}
				this.setReplyAnnotation(this.questionAnnotation)
				return
			}
			this.forceCorrectDone = false;
      this.showMessageDialog = false
			this.messageToShow = ''
			if (this.questionAnnotation && this.questionSet) {
				// this.sayDev("Questionset!", this.questionSet, this.questionSetIndex)
				var index = this.questionSet.indexOf(this.questionAnnotation)
				// var str = "💎 Question Set: " + this.questionSetIndex + " / " + (this.questionSet.length - 1) +  " --- Index: " + index
				// this.sayDev(str)
				if (index == this.questionSet.length) {
					// this.sayDev("Last Question, next")
					this.questionSet = null
          this.sayDev("Cleared QuestionSet from TNQ", this.questionSet)
					this.questionAnnotation = null
					this.questionDialog = false
					if(this.waitingAction){
						this.finishAction()
					}
          if (this.isSafariOrIOSorIpad) {
            this.player.currentTime(this.deciTime + 0.25)
          }
					this.hideAllDialogsAndPlay("TNQ")
				} else {
					// this.sayDev("NOT finished, increase index")
					if(this.waitingAction && !['select'].includes(this.waitingAction.action)){	
						this.finishAction()
						return
					}
					if (index > 0) {
						this.questionSetIndex = index + 1
					} else {
						this.questionSetIndex = this.questionSetIndex + 1
					}
					if (this.questionSetIndex >= this.questionSet.length) {
						// this.sayDev("Index over length, clear")
            if (this.isSafari) {
              this.player.currentTime(Number(this.deciTime) + 0.2)
            }
						this.clearScreenInteractions()
						if(this.waitingAction){
							this.finishAction()
						}
						if (this.showCtaTrayNext){
							this.hideAllDialogs()
							this.sayDev("Show CTA Tray at Player End")
							this.onPlayerEnded()
						} else if (!this.resumeDialog) {
							this.hideAllDialogsAndPlay("TNQ no resumedialog")
						}
						return
					}
					var q = this.questionSet[this.questionSetIndex]
					if (this.questionSet[this.questionSetIndex - 1].tray_type != 'ctaTray' && q.tray_type == 'ctaTray') {
						this.hideAllDialogs()
						this.sayDev("Going into end CTA Qs")
						this.onPlayerEnded()
					}
          this.sayDev("TNQ SSQ....")
					this.showSpecificQuestion(q)
					return
				}
			} else {
				this.hideAllDialogsAndPlay("TNQ 3")
			}
		},
		doConfirmLink() {
			setTimeout(() => {
				this.resumeDialog = true
				this.confirmLink = null
			})
		},
		cancelDiv(){
			this.httpResponseData = null
			this.httpFields = []
			this.generatedContentResultsIndex = 0
			this.generatedContentResults = null
			this.generateContentPrompt = ''
			this.generateContentTones = []
			this.generatedValueResultsIndex = 0
			this.generatedValueResults = null
			this.generateValuePrompt = ''
			this.generateValueTones = []
			this.hideAllDialogsDoSeekbar()
			this.doScroll(0)
			this.divsActive.forEach((a, i) => {
				if (a.tray_type && !['resourceTray', 'ctaTray'].includes(a.tray_type)){
					this.divsActive.splice(i, 1)
				}
			})
			this.annotations.forEach((a) => {
				if (a.tray_type && !['resourceTray', 'ctaTray'].includes(a.tray_type)) { 
					this.addToActive(a)
				}
			})
		},
		hideAllDialogsDoSeekbar() {
			this.httpResponseData = null
			this.hideAllDialogs()
			setTimeout(()=> {
				this.mouseMove()

			}, 100)
		},
		clearLines() {
			setTimeout(()=> {
				// this.sayDev("Clear Lines: " + this.leaderLines.length)
				this.leaderLines.forEach((l)=> {
					l.remove()
				})
				this.leaderLines = []
			}, 50)
		},
		// xxxx hhhhhh
		hideAllDialogs: function () {
			// this.sayDev("🔥 HAD")
      this.gptDialog = false
			this.clearLines()
      this.coords = {
        left: null,
        top: null,
        width: null,
        height: null
      }
      this.articleDialog = false
      this.activeArticle = null
      this.afkDialog = false
      this.waitingToRecordAudio = false
      this.importDialog = false;
      this.helpDialog = false
      this.pendingAnn = null
      this.deleteAnnDialog = false
			this.htmlEditorContent = false
			this.htmlEditorValue = false
			this.viewImageDialog = false
			this.imageAnnotation = null
			this.timeDialog = false
			this.touchPlayDialog = false
			this.navDialog = false
			this.busy = false
			this.collectPrompt = null
			this.firstVideoDialog = false
			this.divDialog = false
			this.showMessageDialog = false
			this.showCardDialog = false
			this.showPollDialog = false
			this.multiCorrect = false
			this.messageToShow = ''
			this.questionCreationDialog = false
			this.phoneDialog = false
			this.closeVideoDialog()
			this.closeAudioDialog()
			this.resetTimer();
			this.imageDialog = false
			this.addNoteDialog = false
			this.drawingDialog = false
			this.loadedFabric = false
			this.addingDialog = false
			this.videoDialog = false
			this.introDialog = false
			this.audioDialog = false
      // if (this.played) {
      //   this.introDialog = false
      // }
			this.signUpDialog = false
			this.authDialog = false
			this.replyDialog = false
			this.questionDialog = false
			this.editingDialog = false
			this.editingItem = false
			this.shareDialog = false
			this.recordVideoDialog = false
			this.recordAudioDialog = false
			this.authDialog = false
			this.payDialog = false
			// this.showCTA = false
			clearInterval(this.questionTimer)
			this.popoverBusy = false
			this.extra = ''
			this.caption = ''
			this.questionMinimized = false
			this.questionTimerAmount = 0
			this.askingOpenAi = false
			this.openAiResponse = ''
			this.cancelSpeech()
		},

		makeSnackbar: function (type, message) {
			this.$toasted.show(message)
		},
		questionDialogBusy() {
			return this.gptDialog || this.videoDialog || this.audioDialog || this.showingCorrect || this.showMessageDialog || this.phoneDialog || this.collectPrompt || this.showPollDialog
		},
		trackAction: function (type, value, object) {
			this.sayDev("🎽 Track Action: " + type + " -> " + value, object)
      if (!this.ownsPost) {
        if (this.replyAnnotation && this.replyAnnotation.content && this.replyAnnotation.content.style == 'likert') {
          this.handleLikert(value.toLowerCase())
        // } else if (this.replyVariable) {
        //   var x = this.replyVariableValue ? this.replyVariableValue : this.replyAnnotation ? (this.replyAnnotation.value || value) : value
        //   this.sayDev("Reply Variable --> ", x)
        //   this.setVariable(this.replyVariable, x)
        }
      }
			if (this.ownsPost && !this.isEmbed) {
				setTimeout(()=> {
					if (this.questionDialogBusy() || this.viewImageDialog || this.showCardDialog || this.articleDialog) {
						this.sayDev("QD busy....")
						return
					} else if (this.questionAnnotation) {
            if (this.switchingVideo) {
              return
            }
            this.tryNextQuestion("trackaction")
          } else if (!this.pausedUntilClick) {
            this.sayDev("No PUC....HADP")
            this.hideAllDialogsAndPlay("TrackAction")
          }
				}, 200)
				return
			}
			if (this.variables.noview) {
				return
			}
			if (object) {
				delete object.points
				delete object.pointPath
				delete object.pointAnimation
			}
			var data = this.getObject()
			data.append("annotation[content]", value);
			data.append("annotation[type_of]", type);
			data.append("annotation[data]", object ? JSON.stringify(object) : '');
			data.append("annotation[correct]", this.hasCorrectAnswer);


			// TODO: CHECK the else if conditionals
			if (object && object.prompt) {
				// Set transformed content for dynamic questions
				data.append("annotation[parent_content]", object.prompt);
			} else if (object && object.content && object.content.id){
				data.append("annotation[parent_content]", JSON.stringify(object.content));
			} else if(object && object.parent) {
				data.append("annotation[parent_content]", JSON.stringify(object.parent));
			}

			if (object.response_time){
				data.append("annotation[response_time]", object.response_time)
			}

			if (type != 'click' && object && object.time) {
				data.set("annotation[time]", object.time)
				data.set("annotation[exact_time]", object.exact_time)
			}
      this.sayDev("Do Create " + this.replyVariable + " --> " + this.replyVariableValue)
      // CreateAnnotation
      this.sayDev("DoCreateAnnotation")
			Rails.ajax({
				url: '/annotations',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					var variables = data.variables
					data = data.annotation
					this.handleNewAnnotation(data)
					this.isChoosingAnswer = false
				}
			});
		},
    toggleGPT() {
      this.gptDialog = !this.gptDialog
      if (this.gptDialog) {
        this.pausePlayer("TGPT")
      }
      this.replyMessage = ''
      if (!this.isProduction) {
        this.replyMessage = "Does it work with the app store?" 
      }
      console.log("GPT Dialog now " + this.gptDialog)
    },
    arrayInteraction (a, b) {
        const setA = new Set(a);
        return b.filter(value => setA.has(value));
    },
    getKeywords(str) {
      str = (str||"").toString().toLowerCase()
      var fillers = [
        " in ", 
        " my ", 
        " about ", 
        " there ", 
        " a ", 
        " new ", 
        " much ", 
        "?", 
        ".", 
        ",", 
        "-", 
        "/", 
        " have ", 
        " video ", 
        " does ", 
        " with ", 
        " tell ", 
        " many ", 
        " will ", 
        " be ",
        " what ",
        " where ",
        " why ",
        " when ", 
        " who ",
        " tell ",
        " me ",
        " has ",
        " an ",
        " it ",
        " explain ",
        " this ",
        " for ",
        " how ",
        " me ",
        " about ", 
        " the ",
        " is ",
        " and ",
        " if ",
        " because ",
      ]
      fillers.forEach(w => str = str.replace(w, ''));
      return str.trim()
    },

    // stampgpt
    queryGPT(){
      if (this.busy) {
        return
      }
      this.busy = true
			var data = new FormData()
      var query = this.replyMessage.trim()
			data.append('query', query)
      Rails.ajax({
				url: '/stampgpt/'+this.share.id,
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
          console.log("Response: ", data)
		      this.gptAnswer = data
					this.busy = false
				}, error: (e)=>{
					this.$toasted.show("Something went wrong")
          this.busy = false
				}
			})
      var keywords = this.getKeywords(query)
      console.log("Keywords", keywords)
      var sentences = JSON.parse(JSON.stringify(this.transcript)).sentences
      var options = { high: [], medium: [], low: []}
      for (var [key, val] of Object.entries(sentences)){
        if (val) {
          var tkeys = this.getKeywords(val.text)
          var intersection = this.arrayInteraction(keywords.split(" "), tkeys.split(" ")).filter(v => v)
          var count = intersection.length
          if (count >= 3) {
            options.high.push(val)
            console.log(count+" : " + val.text)
            console.log(intersection)
          } else if (count == 2) {
            options.medium.push(val)
            console.log(count+" : " + val.text)
            console.log(intersection)
          } else if (count == 1) {
            options.low.push(val)
          }
        }
      }
      console.log(options)
      this.gptJumps = options.high.concat(options.medium).concat(options.low).slice(0,5)
      console.log(this.gptJumps)
		},
		questionGPT(){
			if (this.gettingGptQuestions) {
        return
      }				
      if (!this.transcript) {
        alert("Transcript required to use AI questions. Please create one or upload captions.")
        this.setSideItemTitle('transcript')
      }
			this.gptQuestion = -1
			this.gptQuestions = null
			this.updateQuestionGptText()
      this.gettingGptQuestions = true
			this.selectedGptQuestions = []
			var data = new FormData()
			data.append('id', this.share.id)
			data.append('count', this.questionGptCount)
      Rails.ajax({
				url: '/questiongpt',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
          this.sayDev("Response: ", data)
		      this.gptQuestions = data.questions
          this.gptQuestions.sort(function (b, a) {
            if (a.time < b.time) { return 1; }
            if (a.time > b.time) { return -1; }
          });
          this.gptQuestions.forEach((q)=> {
            q.id = Math.round(Math.random() * 100000)
            this.selectedGptQuestions.push(q.id)
            this.sayDev(q.id)
          })
					this.updateGptQuestion()
					this.gettingGptQuestions = false
				}, error: (e)=>{
					this.$toasted.show("Something went wrong")
          this.gettingGptQuestions = false
				}
			})
		},
		updateGptQuestion(){
			this.gptQuestion++
			if (!this.gptQuestions){
				this.$toasted.show('🚨 Please Try Again')
			}
			if (!this.gptQuestions || this.gptQuestion >= this.gptQuestions.length){
				this.questionCreationDialog = false
				this.questionDialog = false
				this.fetchInteractions()
				this.gptQuestion = -1
				this.gptQuestions = null
				return
			}
			var x = this.gptQuestions[this.gptQuestion]
			this.resetEditingQuestion()
			this.editingQuestion.style = 'multiple'
			this.editingQuestion.prompt = x.prompt
			this.correctAnswer = x.correct
			this.editingQuestion.answers = x.answers.map(i => {return {text:i, action: 'reply', value: 'a'}})
			this.editingQuestion.width = '100'
			this.editingQuestion.height = '100'
			this.editingQuestion.top = '0'
			this.editingQuestion.left = '0'
			this.editingTime = x.time
			this.editingQuestion.has_correct_answers = true
		},
		updateQuestionGptText(){
			this.questionGptText = 'Anaylzing Transcript...'
			setTimeout(() => {
				this.questionGptText = ''
				this.questionGptText = 'Finding Key Points...'
				setTimeout(() => {
					this.questionGptText = ''
					this.questionGptText = 'Finding Key Points...'
					setTimeout(() => {
						this.questionGptText = ''
						this.questionGptText = 'Creating Questions...'
					},3000) 
				},3000) 
			},3000) 
		},
		getOpenAI(){
			this.askingOpenAi = true
			var data = new FormData()
			data.append('prompt', this.replyMessage.trim())
			data.append('token', this.currentShare.token)
			Rails.ajax({
				url: '/annotations/openai',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
		      this.openAiResponse = data
					this.askingOpenAi = false
				}
			})
		},
		getGeneratedText(content){
			var prompt = ''
			if (content){
				if (this.generateContentTones && this.generateContentTones.length > 0) {
					prompt = "Using the following tones: " + this.generateContentTones.join(',')
				}
				prompt += " Return a the value for a concise text label with the following prompt giving 5 options: " + this.generateContentPrompt + "\n\nReturn the 5 results in a JS const array called 'results' where each element is wrapped in quotes and not apostrophes."
				this.gettingGeneratedContent = true
			} else {
				if (this.generateValueTones && this.generateValueTones.length > 0) {
					prompt = "Using the following tones: " + this.generateValueTones.join(',')
				}
				prompt += " Return a the value for a concise text label with the following prompt giving 5 options: " + this.generateValuePrompt + "\n\nReturn the 5 results in a JS const array called 'results' where each element is wrapped in quotes and not apostrophes."
				this.gettingGeneratedValue = true
			}
			this.askingOpenAi = true
			var data = new FormData()
			data.append('prompt', prompt)
			data.append('token', this.currentShare.token)
			data.append('user', this.currentUser.id)
			data.append('generate', 1)
			Rails.ajax({
				url: '/annotations/openai',
				type: "POST",
				data: data,
				dataType: "JSON",
				success: (data) => {
					if (content){
						this.generatedContentResults = data
						this.generatedContentResultsIndex = 0
						this.gettingGeneratedContent = false
					} else {
						this.generatedValueResults = data
						this.generatedValueResultsIndex = 0
						this.gettingGeneratedContent = false
					}
					this.nextGenerated(0, content)
					this.currentUser.openai_used++
					this.gettingGeneratedValue = false
				}, error: (e)=>{
					this.activeDiv.content = "An Error Occurred. Please Try Again."
					this.gettingGeneratedValue = false
					this.gettingGeneratedContent = false
				}
			})
		},
		nextGenerated(i,prompt){
			if (this.cardSelectStep > 0){
				this.generatedValueResultsIndex += i
				this.activeCard.click_value = this.generatedValueResults[this.generatedValueResultsIndex].replace('&#39;', "'")
			} else if (prompt){
				this.generatedContentResultsIndex += i
				this.activeDiv.content = this.generatedContentResults[this.generatedContentResultsIndex].replace('&#39;', "'")
			} else {
				this.generatedValueResultsIndex += i
				this.activeDiv.value = this.generatedValueResults[this.generatedValueResultsIndex].replace('&#39;', "'")
			}
		},
		submitReplyMessage: function () {
      if (this.questionAnnotation) {
        this.questionAnnotation.response_time = new Date() - this.questionAnnotation.response_time
			  this.sayDev('SRM ⌚️⌚️⌚️', this.questionAnnotation.response_time)
      }

      this.resetTimer()
			var ca = this.questionAnnotation && this.questionAnnotation.content.correct_answer;
			if(!ca){
				ca  = null;
			}
			if(this.questionAnnotation && !['number', 'all'].includes(this.questionAnnotation.content.style)){
				this.replyMessage = this.replyMessage.toString()
				if (!this.replyMessage.trim()) {
					return
				}
			}
			if (this.shouldAuth) {
				this.authDialog = true
				this.authReturn = {
					type: 'reply',
					value: this.replyMessage.toString().trim()
				}
				return;
			}
			if (this.shouldPay) {
				this.showPayDialog()
				this.payReturn = {
					type: 'reply',
					value: this.replyMessage.toString().trim()
				}
				return;
			}
      this.sayDev("SubmitReplyMessage")
      if (!this.ownsPost && this.replyAnnotation && this.replyVariable) {
          this.sayDev("Reply Variable " + this.replyVariable + "--> " + this.replyMessage)
          this.setVariable(this.replyVariable, this.replyMessage)
      }

			if(this.questionAnnotation && ['all'].includes(this.questionAnnotation.content.style)){
				this.questionChoices.forEach(c =>{
					if (c.variable && this.checkedItems.includes(c.text)){
						this.setVariable(c.variable.toLowerCase(), c.variable_value)
					}
				})
			}

			if (this.questionAnnotation && ca) {
				this.chosenAnswer = this.questionAnnotation.content.style == 'all' ? this.replyMessage : this.replyMessage.trim()
				this.correctAnswer = ca
				if (!this.hasAnsweredQuestion(this.questionAnnotation)) {
					if (this.hasCorrectAnswer) {
						this.correctCount++;
					} else {
						this.incorrectCount++;
					}
				}
			} else if(this.questionAnnotation && this.questionAnnotation.content.style == 'number' && this.minCorrect && this.maxCorrect){
				if (!this.hasAnsweredQuestion(this.questionAnnotation)) {
					this.chosenAnswer = this.replyMessage;
					if (this.hasCorrectAnswer) {
						this.correctCount++;
					} else {
						this.incorrectCount++;
						correct = false;
					}
				} 
			} else if (!this.replyDialog && (this.replyMessage.toString().trim() == "" || this.ownsPost)) {
				this.collectPrompt = null
				this.openAiResponse = ''
				this.tryNextQuestion("SRM")
				return
			}
      if (this.questionAnnotation && this.questionAnnotation.content.style == 'all') {
        if (this.hasCorrectAnswer && this.questionAnnotation.content.correct_action && this.questionAnnotation.content.correct_action_value ) {
          this.sayDev("Do All correct")
          this.sayDev("ShowCorrect: " + this.showQuestionCorrect)
          this.sayDev("Action: " + this.questionAnnotation.content.correct_action)
          if (this.showQuestionCorrect && this.questionAnnotation.content.correct_action == "message") {
            this.messageToShow = this.questionAnnotation.content.correct_action_value
            this.clearWaitingAction()
          } else if(this.questionAnnotation.content.correct_action) {
            this.setWaitingAction({
              type: 'select',
              parent: Object.assign({}, this.questionAnnotation),
              text: this.questionAnnotation.content.prompt,
              action: this.questionAnnotation.content.correct_action,
              value:this.questionAnnotation.content.correct_action_value,
              skip: true
            })
          } else if (false) {
            this.sayDev("SWA: C")
            this.setWaitingAction({
              type: 'select',
              parent: Object.assign({}, this.questionAnnotation),
              text: this.questionAnnotation.content.prompt,
              action: this.questionAnnotation.content.correct_action,
              value:this.questionAnnotation.content.correct_action_value
            })
          }
        } else if (ca && !this.hasCorrectAnswer) {
          this.sayDev("Do All incorrect")
          if (this.showQuestionCorrect && this.questionAnnotation.content.incorrect_action == "message") {
            this.sayDev("Doin...")
            this.messageToShow = this.questionAnnotation.content.incorrect_action_value
            if (!this.shareDesign.question_correctness) {
              this.showMessageDialog = true
            }
            this.clearWaitingAction()
          } else if(this.questionAnnotation.content.incorrect_action) {
            this.setWaitingAction({
              type: 'select',
              parent: Object.assign({}, this.questionAnnotation),
              text: this.questionAnnotation.content.prompt,
              action: this.questionAnnotation.content.incorrect_action,
              value:this.questionAnnotation.content.incorrect_action_value,
              skip: true
            })
          } else if (false){
            this.sayDev("SWA: IC")
            this.setWaitingAction({
              type: 'select',
              parent: Object.assign({}, this.questionAnnotation),
              text: this.questionAnnotation.content.prompt,
              action: this.questionAnnotation.content.incorrect_action,
              value:this.questionAnnotation.content.incorrect_action_value
            })
          }
        }
      }
			var data = this.getObject()
			this.sayDev("RA", this.replyAnnotation)
			var pc = ''
			if (this.replyAnnotation && ['button', 'hotspot', 'image', 'text'].includes(this.replyAnnotation.type_of)) {
				var r = this.replyAnnotation
				if (r && r.data) {
					var j = JSON.parse(r.data) 
					if (j && j.action == 'collect' || j.action == 'robot') {
						pc = j.value
					}
				}
			}
			var theParentContent = pc ? pc : this.questionAnnotation ? this.removeHTML(this.questionAnnotation.prompt) : this.replyAnnotation ? this.removeHTML(this.replyAnnotation.extra || this.replyAnnotation.content) : ''
			data.append("annotation[content]", this.replyMessage.toString());
			data.append("annotation[type_of]", this.questionAnnotation && this.questionAnnotation.content.style == 'image' ? 'image' : "reply");
			data.append("annotation[parent_content]", theParentContent)
			data.append("annotation[correct]", ca ? this.hasCorrectAnswer : false);
			if (this.questionAnnotation && this.questionAnnotation.response_time){
				data.append("annotation[response_time]", this.questionAnnotation.response_time)
			}
      if (!this.replyDialog) {
        if (this.replyMessage.toString().trim() == "" || (this.ownsPost && !this.showingCorrect)) {
          this.collectPrompt = null
					this.openAiResponse = ''
          this.tryNextQuestion("SRM2")
          return
        }
      }
			if (!this.ownsPost || this.replyDialog) {
				Rails.ajax({
					url: '/annotations',
					type: "POST",
					data: data,
					dataType: "JSON",
					success: (data) => {
						data = data.annotation
						this.collectPrompt = null
						this.openAiResponse = ''
						var theObject = {
							interaction_id: data.id,
							interaction_type: "reply", 
							interaction_value: data.content,
							parent_id: data.parent_id,
							parent_type: "question",
							parent_value: theParentContent,
						}
						
						this.po(theObject, "🎾 TheObject")
						// this.doInteractionMessage(1, theObject)
						this.handleNewAnnotation(data)
						this.tryTrack("Replied to Note", { url: window.location.href, parent: this.replyAnnotation, reply: data })
					}
				});
			}
		},
	},
	beforeMount: function () {
		this.width = window.innerWidth;
		this.isMobile = window.innerWidth < 540 || window.innerHeight < 240 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		this.isXL = this.width > 1200
		this.isMini = false
		this.isEmbed = gon.action == "posts-embed"
		this.isPopout = window.location.href.includes("popout")
		if (gon.current_user) {
			this.currentUserId = gon.current_user.id
			this.currentUser = gon.current_user
			this.showScroll = gon.current_user.showScroll
		}
    DetectRTC.load(() => {
      DetectRTC.hasWebcam; // (has webcam device!)
      DetectRTC.hasMicrophone; // (has microphone device!)
      DetectRTC.isWebsiteHasWebcamPermissions; // getUserMedia allowed for HTTPs domain in Chrome?
      DetectRTC.isWebsiteHasMicrophonePermissions; // getUserMedia allowed for HTTPs domain in Chrome?
      DetectRTC.audioInputDevices; // microphones
      DetectRTC.audioOutputDevices; // speakers
      DetectRTC.videoInputDevices; // cameras
      DetectRTC.osName;
      DetectRTC.osVersion;
      DetectRTC.browser.name === 'Edge' || 'Chrome' || 'Firefox';
      DetectRTC.browser.version;
      DetectRTC.browser.isChrome;
      DetectRTC.browser.isFirefox;
      DetectRTC.browser.isOpera;
      DetectRTC.browser.isIE;
      DetectRTC.browser.isSafari;
      DetectRTC.browser.isEdge;
    });
    this.browserInfo = DetectRTC
    if (this.isDebug) {
      console.log("⚡️ BrowserInfo: ", this.browserInfo)
    }
	},
	mounted: function () {
    if (this.isProduction && location.protocol !== 'https:') {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }
    // this.sayDev("🐴 MOUNT 🐴")
		if (this.getLocalStorageItem('spoof_from')){
			var url = this.getLocalStorageItem('spoof_from')
			localStorage.removeItem('spoof_from')
			window.location.href = url
		}
		if (this.halt) {
      var info = gon.share
      this.sayDev("Emit unavailable message", info)
      this.emitMessage('video_unavailable', info)
			return
		}

    if (this.isAdmin()) {
      this.afkOptions.unshift({ text: '10 seconds', value: 0.16})
    }
    // this.loadHtmlScripts()
    if (gon.pending) {
      this.startPendingCheck()
      return
    }
    if (gon.action == 'posts-new' && window.location.href.includes("sample=1")) {
      this.useSampleVideo();
      return
    } 
		if (gon.action == 'posts-new' && window.location.href.includes('chrome_src')){
			setTimeout(() => {
				var player = videojs("chromeVideo", {
					html5: {
						vhs: {     
							overrideNative: !videojs.browser.IS_SAFARI,
						},
						nativeAudioTracks: videojs.browser.IS_SAFARI,
						nativeVideoTracks: videojs.browser.IS_SAFARI,
					},
					preload: 'auto',
					bandwidth: 41943040,
          useDevicePixelRatio: true
				})
				player.on('error', (event) => {
					this.$toasted.show("🚨 Video is not supported")
					this.pasteLink = ''
				})
				player.on('loadedmetadata', (event) => {
					if (player.duration() / 60 > this.maxVideoLength){
						this.invalidChromeSrc = true
						this.$toasted.show("🚨 Your current plan has a max individual video length of 30 minutes")
					}
				})
			},0) 
		}
		if (this.isEmbed) { 
			this.parentDomain = document.referrer 
			this.sayDev("👒 PD: " + this.parentDomain)
		}
		if (this.isPreview) {
			this.guestName = null
			this.guestID = null
			this.guestEmail = null
			this.guestPhone = null
		}
		
		if (this.isDebug) {
			console.log("⭐️ ⭐️ ⭐️ DEBUG ⭐️ ⭐️ ⭐️")
		}
		if (this.isAdmin()){
			if (!this.getLocalStorageItem('doFresh') || window.location.href.includes("freshBtn=1")){
				this.setLocalStorageItem('doFresh', 1)
				this.freshBtn = true
			} else if (window.location.href.includes("freshBtn=0")){
				this.setLocalStorageItem('doFresh', 0)
				this.freshBtn = false
			} else {
				this.freshBtn = this.getLocalStorageItem('doFresh') == "1"
			}
		}
		if (this.baseUrl == 'https://app.mindstamp.com') {
			this.shareUrl = 'https://share.mindstamp.com'
		}
		if (this.currentShare && this.currentShare.capture_payment_point > -1){
			this.shareUrl = 'https://go.payper.video'
		}
		if (this.currentUser && this.currentUser.organization) {
			if (this.currentUser.organization.custom_domain && this.currentUser.organization.domain_is_active) {
				this.shareUrl = this.currentUser.organization.custom_domain
			}
		}
		// this.sayDev("Share URL: " + this.shareUrl)
		var c = this.color || gon.color
    // this.sayDev("Color: " + c)
    if (c[0] == "#") {
      c = c.substring(0, 7);
    }
    this.sayDev("Color: " + c)
		this.setColor(c)
		var that = this
		this.showBack = !this.isMobile && this.currentUser && window.location.pathname != '/' && window.location.pathname != '/welcome' && window.location.pathname != '/new'
		if (gon.action == 'posts-embed') {
			this.showBack = false
		}
    if (gon.action == 'pages-debug') {
      this.addListeners()
    }
    // if (gon.action == 'registrations-new') {
    //   setInterval(()=> {
    //     if (this.extra == 2) {
    //       this.extra = 0
    //     } else {
    //       this.extra++
    //     }
    //   }, 3000)
    // }
		if (gon.action == "posts-show" || gon.action == "posts-embed") {
			if(this.isAdmin() && this.share.token != 'uQHkJdMEZwJO' && this.showAdminButtons){
				this.sideMenuItems.push({
          title: 'Spoof',
          id: 'spoof_button',
          icon: 'ghost',
          tooltip: 'Spoof video owner'
        })
			}
			// this.sayDev("💭 ", this.getFirstBrowserLanguage())
			this.initializeGtag()
			this.refreshCards()
      if (this.share && this.share.is_resumable && this.getLocalStorageItem("MS_PLAYS")) {
        const plays = this.tryParseJSON(this.getLocalStorageItem("MS_PLAYS")) || []
        this.sayDev("Got Resume Plays: ", plays)
        var done = false
        plays.forEach((p)=> {
          if (p.token == this.share.token && !done) {
            this.sayDev("Found resumable!", p)
            done = true
            this.getResumablePlay(p)
          }
        })
      }
      if (this.ownsPost) {
        this.refreshAssets() 
      } 
    
      setTimeout(()=> {
        this.setStrings()
        if (gon.shareDesign && gon.shareDesign.auto_translate) {
          this.setTranslations()
        } 
      }, 1000)

			if (!this.ownsPost && this.viewerID && this.lastPlay) {
				this.sayDev("🎾 Recognized Viewer: " + this.viewerID, this.lastPlay)
				// Prompt Confirmation
			}
			this.setCurrentShare()
			this.shareVariables = {
				title: this.getUrlParam('title') || '',
				image: this.getUrlParam('image') || '',
			}
			var standalone = window.navigator.standalone,
				userAgent = window.navigator.userAgent.toLowerCase(),
				safari = /safari/.test(userAgent),
				ios = /iphone|ipod|ipad/.test(userAgent);

			if (gon.no_access) {
				return
			}

			this.browser = (function () {
				var ua = navigator.userAgent, tem,
					M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
				if (/trident/i.test(M[1])) {
					tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
					return 'IE ' + (tem[1] || '');
				}
				if (M[1] === 'Chrome') {
					tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
					if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
				}
				M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
				if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
				return M.join(' ');
			})();

			if (this.variables.noview) {
				alert("NoView")
			}


			if (!this.currentUser && this.lsTest() && !this.isPreview && !this.share.force_session_reset) {
				// this.sayDev("⭐️ Grab from local")
				this.guestName = this.guestName || this.getUrlParam("name") || localStorage.getItem('guestName') || ''
				this.guestEmail = (this.guestEmail || this.getUrlParam("email") || localStorage.getItem('guestEmail') || '').trim()
				this.guestID = (this.guestID || this.getUrlParam("custom_id") || localStorage.getItem('guestID') || '').trim()
				this.guestPhone = (this.guestPhone || this.getUrlParam("phone") || localStorage.getItem('guestPhone') || '').trim()
				
				this.setCustomViewerVariables()

				// this.sayDev("GUEST ID!", this.guestID)
				if (this.guestName) {
					this.variables["name"] = this.guestName.trim()
					localStorage.setItem('guestName', this.variables["name"]);
				}

				if (this.guestEmail) {
					this.variables["email"] = this.guestEmail.trim()
					localStorage.setItem('guestEmail', this.variables["email"]);
				}

				if (this.guestPhone) {
					this.variables["phone"] = this.guestPhone.trim()
					localStorage.setItem('guestPhone', this.variables["phone"]);
				}

				if (this.guestID) {
					this.variables["custom_id"] = this.guestID.trim()
					localStorage.setItem('guestID', this.variables["custom_id"]);
				}
				
			}

			if (window.location.href.includes("sharing=1")) {
				this.firstVideoDialog = false
				setTimeout(() => {
					if (this.ownsPost) {
						this.introDialog = true
						this.startSharing(this.share)
					}
				}, 150);
			}

      // All listeners now in here
      this.addListeners()

      this.startVideo()
			this.doCaptions()
			if (this.isPreview || this.share.force_session_reset) {
				this.clearSessionStorage()
			}

      if (gon.action == 'posts-show' && window.location.href.includes("tour=1")) {
        setTimeout(()=> {
          this.startTour()
        }, 1000)
      }

			this.endButton = gon.share && gon.share.cta ? this.tryParseJSON(gon.share.cta) : {}
		} else if (gon.action == 'pages-summary') {
			setTimeout(()=> {
				this.reportColor = gon.color
				this.printSummary()
			}, 500)
		} else if (gon.action == 'pages-finals') {
			if (this.currentUser.is_gdpr == null && typeof(Cookiebot) != 'undefined' && Cookiebot.regulations.gdprApplies) {
				this.marketingConsentDialog = true
			} 

      if (this.latestFeatures && !window.location.href.includes("enhancements")) {
        var x = []
        this.latestFeatures.forEach((f)=> {
          // this.sayDev(new Date(f.created_at), new Date(parseInt(this.getLSFeatureDate()) * 1000))
          if (new Date(f.created_at) >= new Date(parseInt(this.getLSFeatureDate()) * 1000)){
            x.push(f)
          }
        })
        this.latestFeatures = x
      }
		} else if (gon.action == 'posts-new') {
      this.newVideo.transcribe = !this.starterUser
      this.newVideo.transcode = !this.starterUser
			document.body.onkeyup = (e) => {
				if (e.keyCode == 13 && this.activeType == 8 && this.stockSearch.length == 0) {
					this.fetchStockAssets('video', false, 5)
				}
			}
		}


		setTimeout(() => {
			if (this.currentUser && window.analytics) {
        // this.sayDev("Identify: " + this.currentUser.email)
				window.analytics.identify(this.currentUser.id);
				if (!this.isEmbed) {
					window.analytics.page();
				}
			}
		}, 5000);

		var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

		var mq = function (query) {
			return window.matchMedia(query).matches;
		}
		var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
		this.isTouchDevice = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) || mq(query) || this.isIOS || this.isAndroid
		if (this.isTouchDevice) {
			this.volumeLevel = 1
		}


		if (gon.action == 'pages-month' || gon.action == 'pages-week') {
			var ctx = document.getElementById("myChart");
			var myChart = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: gon.keys,
					datasets: [{
						label: '# of Tomatoes',
						data: gon.counts,
						borderWidth: 1
					}]
				},
				options: {
					responsive: false,
					scales: {
						xAxes: [{
							ticks: {
								maxRotation: 90,
								minRotation: 80
							},
							gridLines: {
								offsetGridLines: true // à rajouter
							}
						},
						{
							position: "top",
							ticks: {
								maxRotation: 90,
								minRotation: 80
							},
							gridLines: {
								offsetGridLines: true // et matcher pareil ici
							}
						}],
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
				}
			});

			var ctx1 = document.getElementById("myConv");
			var myChart1 = new Chart(ctx1, {
				type: 'bar',
				data: {
					labels: gon.conv_keys,
					datasets: [{
						label: '# of Tomatoes',
						data: gon.conv_counts,
						borderWidth: 1
					}]
				},
				options: {
					responsive: false,
					scales: {
						xAxes: [{
							ticks: {
								maxRotation: 90,
								minRotation: 80
							},
							gridLines: {
								offsetGridLines: true // à rajouter
							}
						},
						{
							position: "top",
							ticks: {
								maxRotation: 90,
								minRotation: 80
							},
							gridLines: {
								offsetGridLines: true // et matcher pareil ici
							}
						}],
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
				}
			});
		}


	},
	created: function () {
		this.screenWidth = window.innerWidth
		this.screenHeight = document.documentElement.clientHeight

		for (var i = 0; i < gon.flash.length; i++) {
			var t = gon.flash[i][0]
			var m = gon.flash[i][1]
			this.$toasted.show(gon.flash[i][1])

		}

		if (gon.post) {
			this.post_id = gon.post.id
		}
		$('.v-cloak').css('display', 'block');
	}
});


